import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {fmtYYYYMM} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_MONTHLY_REPORT} from '_logics/LFCPageId';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsWeldingMonthBarLine from '../_charts/LFCChartsWeldingMonthBarLine';
import {useTranslation} from 'react-i18next';
import DateTimePicker from '_components/inputs/DateTimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';

const MonthlyReportPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);

  const [startProcess, setStartProcess] = useState(false);

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_MONTHLY_REPORT,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>({
    select_month: fmtYYYYMM(dayjs()),
    working_time: 8,
    work: [],
    ...defaultCondition
  });

  const [data001, setData001] = useState([]);
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState([]);
  const [data004, setData004] = useState([]);

  const [data001Total, setData001Total] = useState(0);
  const [data002Total, setData002Total] = useState(0);
  const [data003Total, setData003Total] = useState(0);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, select_month: event.format('YYYY-MM')});
  };

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  const apiFetchSub = async () => {
    setStartProcess(true);

    Promise.allSettled([
      // 機種情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 41001,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds
          .filter((e: any) => {
            return e.work !== '' && e.work !== null;
          })
          .map((item: any) => {
            return {label: item['work']};
          });
        setWork(tmp);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const apiFetch = async () => {
    const lwork = !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null;

    setStartProcess(true);

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3115,
        parameters: {
          select_month: searchValue.select_month,
          work: lwork
        },
        ds_state: setData001,
        name: `${t('生産数推移')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3116,
        parameters: {
          select_month: searchValue.select_month,
          work: lwork
        },
        ds_state: setData002,
        name: `${t('溶接長さ')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3117,
        parameters: {
          select_month: searchValue.select_month,
          work: lwork
        },
        ds_state: setData003,
        name: `${t('電極交換履歴')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3118,
        parameters: {
          select_month: searchValue.select_month,
          work: lwork,
          working_time: searchValue.working_time
        },
        ds_state: setData004,
        name: `${t('設備稼働率')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetchSub();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setData001Total(data001.reduce((a: number, b: any) => a + Number(b.exec_count), 0));
  }, [data001]);

  useEffect(() => {
    setData002Total(data002.reduce((a: number, b: any) => a + Number(b.value_y), 0));
  }, [data002]);

  useEffect(() => {
    setData003Total(data003.reduce((a: number, b: any) => a + Number(b.value_y), 0));
  }, [data003]);

  /**
   * 検索イベント
   * @returns
   */
  const doSearch = () => {
    setData001([]);
    setData002([]);
    setData003([]);
    setData004([]);

    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   * 検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_month',
        value: searchValue.select_month,
        valueLabel: searchValue.select_month,
        colName: t('年月'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.月報')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_month)}
            format="YYYY/MM"
            views={['year', 'month']}
            onChange={event => {
              handleChange(event);
            }}
          />
          <LFCSelectForm
            name={'working_time'}
            label={t('1日の稼働時間(h)')}
            value={searchValue.working_time}
            id={'working_time'}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            selectItem={[...Array(24)].map((_, i) => ({name: String(i + 1), value: i + 1}))}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_MONTHLY_REPORT}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('生産数合計')} val={data001Total} />
          </Grid>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('溶接長さ合計')} val={round(data002Total, 1)} unit="mm" />
          </Grid>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('電極交換回数合計')} val={data003Total} unit="回" />
          </Grid>
          <Grid item xs={3} lg={6} textAlign="right"></Grid>

          <Grid item xs={12} xl={6}>
            <LFCChartsWeldingMonthBarLine
              chartType="bar"
              title={t('生産数推移')}
              source={data001}
              sourceKey={{x: 'exec_date', y: 'exec_count', series: 'work'}}
              exportFields={{
                exec_date: t('年月日'),
                work: t('機種'),
                exec_count: t('個数')
              }}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <LFCChartsWeldingMonthBarLine
              chartType="bar"
              title={t('溶接長さ')}
              source={data002}
              sourceKey={{x: 'exec_date', y: 'value_y', series: ''}}
              yUnit="mm"
              yDigit={1}
              exportFields={{
                exec_date: t('年月日'),
                value_y: `${t('溶接長さ')}(mm)`
              }}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <LFCChartsWeldingMonthBarLine
              chartType="bar"
              title={t('電極交換履歴')}
              source={data003}
              sourceKey={{x: 'exec_date', y: 'value_y', series: ''}}
              yUnit={`${t('回')}`}
              yDigit={0}
              exportFields={{
                exec_date: t('年月日'),
                value_y: t('交換回数')
              }}
            />
          </Grid>
          <Grid item xs={12} xl={6}>
            <LFCChartsWeldingMonthBarLine
              chartType="line"
              title={t('設備稼働率')}
              source={data004}
              sourceKey={{x: 'exec_date', y: 'value_y', series: ''}}
              yUnit="%"
              yDigit={1}
              exportFields={{
                exec_date: t('年月日'),
                value_y: t('稼働率')
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default MonthlyReportPage;
