import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';
import {ResultsHistorySearchBar} from './components/ResultsHistorySearchBar';
import {useResultsHistory} from './hooks/useResultsHistory';
import {ResultsHistoryDisplay} from './components/ResultsHistoryDisplay';
export const ResultsHistoryPage = () => {
  const {
    t,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    work,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    deviceid,
    doSearch,
    resetSearch,
    openSavedCondition,
    setOpenSavedCondition,
    condition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    onChangeShowLatestOnly,
    onChangeAutoReload,
    onSelectAutoReloadInterval
  } = useResultsHistory();
  return (
    <GenericTemplate title={t('menu.page_name.検査結果一覧履歴')}>
      <ProgressBar startProcess={startProcess} />
      <ResultsHistorySearchBar
        formRef={formRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        work={work}
        autoOnChange={autoOnChange}
        autoCompleteReset={autoCompleteReset}
        setAutoCompleteReset={setAutoCompleteReset}
        deviceid={deviceid}
        doSearch={doSearch}
        resetSearch={resetSearch}
        openSavedCondition={openSavedCondition}
        setOpenSavedCondition={setOpenSavedCondition}
        condition={condition}
        onLoadSavedCondition={onLoadSavedCondition}
        onSelectSavedCondition={onSelectSavedCondition}
        onChangeShowLatestOnly={onChangeShowLatestOnly}
        onChangeAutoReload={onChangeAutoReload}
        onSelectAutoReloadInterval={onSelectAutoReloadInterval}
        t={t}
      />
      <ResultsHistoryDisplay data={[]} />
    </GenericTemplate>
  );
};
