import {Box, Dialog, DialogContent, Grid, Typography} from '@mui/material';
import {useRef, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {handleInputChange} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

export interface ScaleValue {
  // X1
  x1AxisMin?: number | null;
  x1AxisMax?: number | null;
  x1AxisInterval?: number | null;
  // Y1
  y1AxisMin?: number | null;
  y1AxisMax?: number | null;
  y1AxisInterval?: number | null;
  // Y2
  y2AxisMin?: number | null;
  y2AxisMax?: number | null;
  y2AxisInterval?: number | null;
}

export const initialScaleValue = {
  // X1
  x1AxisMin: null,
  x1AxisMax: null,
  x1AxisInterval: null,
  // Y1
  y1AxisMin: null,
  y1AxisMax: null,
  y1AxisInterval: null,
  // Y2
  y2AxisMin: null,
  y2AxisMax: null,
  y2AxisInterval: null
};

interface Props {
  open: boolean;
  onApply: (scaleValue: ScaleValue) => void;
  onClose: () => void;
  inputX1Axis?: boolean;
  inputY1Axis?: boolean;
  inputY2Axis?: boolean;
  x1AxisName?: string;
  y1AxisName?: string;
  y2AxisName?: string;
}

const LFCChartsInputScaleDialog = (props: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);

  const [formValue, setFormValue] = useState({
    x1AxisMin: '',
    x1AxisMax: '',
    x1AxisInterval: '',
    y1AxisMin: '',
    y1AxisMax: '',
    y1AxisInterval: '',
    y2AxisMin: '',
    y2AxisMax: '',
    y2AxisInterval: ''
  });

  const onApply = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    let scaleValue: ScaleValue = initialScaleValue;

    formValue.x1AxisMin !== '' &&
      (scaleValue = {...scaleValue, x1AxisMin: Number(formValue.x1AxisMin)});
    formValue.x1AxisMax !== '' &&
      (scaleValue = {...scaleValue, x1AxisMax: Number(formValue.x1AxisMax)});
    formValue.x1AxisInterval !== '' &&
      (scaleValue = {...scaleValue, x1AxisInterval: Number(formValue.x1AxisInterval)});
    formValue.y1AxisMin !== '' &&
      (scaleValue = {...scaleValue, y1AxisMin: Number(formValue.y1AxisMin)});
    formValue.y1AxisMax !== '' &&
      (scaleValue = {...scaleValue, y1AxisMax: Number(formValue.y1AxisMax)});
    formValue.y1AxisInterval !== '' &&
      (scaleValue = {...scaleValue, y1AxisInterval: Number(formValue.y1AxisInterval)});
    formValue.y2AxisMin !== '' &&
      (scaleValue = {...scaleValue, y2AxisMin: Number(formValue.y2AxisMin)});
    formValue.y2AxisMax !== '' &&
      (scaleValue = {...scaleValue, y2AxisMax: Number(formValue.y2AxisMax)});
    formValue.y2AxisInterval !== '' &&
      (scaleValue = {...scaleValue, y2AxisInterval: Number(formValue.y2AxisInterval)});

    props.onApply(scaleValue);
    props.onClose();
  };

  const inputCheckPattern = '^[-]?([1-9]\\d*|0)(\\.\\d+)?$';

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <LFCDialogTitle onClose={props.onClose}>{t('スケール変更')}</LFCDialogTitle>
      <DialogContent>
        <form ref={formRef}>
          <Box>
            <Grid container rowSpacing={2}>
              <Grid item xs={12}>
                <Typography>{t('message.各軸の最小値、最大値を入力してください。')}</Typography>
                <Typography>{t('message.項目を空白にすると自動調整します。')}</Typography>
              </Grid>
              {props.inputX1Axis !== undefined && props.inputX1Axis ? (
                <Grid item xs={12}>
                  <LFCTitledCard
                    title={'Ｘ軸 - ' + (props.x1AxisName !== undefined ? props.x1AxisName : '')}
                  >
                    <LFCFormRowGroup>
                      <LFCTextField
                        name="x1AxisMin"
                        label={t('最小値')}
                        value={formValue.x1AxisMin}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="x1AxisMax"
                        label={t('最大値')}
                        value={formValue.x1AxisMax}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="x1AxisInterval"
                        label={t('軸間隔')}
                        value={formValue.x1AxisInterval}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                    </LFCFormRowGroup>
                  </LFCTitledCard>
                </Grid>
              ) : (
                <></>
              )}
              {props.inputY1Axis !== undefined && props.inputY1Axis ? (
                <Grid item xs={12}>
                  <LFCTitledCard
                    title={
                      `${t('Y軸')} - ` + (props.y1AxisName !== undefined ? props.y1AxisName : '')
                    }
                  >
                    <LFCFormRowGroup>
                      <LFCTextField
                        name="y1AxisMin"
                        label={t('最小値')}
                        value={formValue.y1AxisMin}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="y1AxisMax"
                        label={t('最大値')}
                        value={formValue.y1AxisMax}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="y1AxisInterval"
                        label={t('軸間隔')}
                        value={formValue.y1AxisInterval}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                    </LFCFormRowGroup>
                  </LFCTitledCard>
                </Grid>
              ) : (
                <></>
              )}
              {props.inputY2Axis !== undefined && props.inputY2Axis ? (
                <Grid item xs={12}>
                  <LFCTitledCard
                    title={
                      `${t('Y軸')}2 - ` + (props.y2AxisName !== undefined ? props.y2AxisName : '')
                    }
                  >
                    <LFCFormRowGroup>
                      <LFCTextField
                        name="y2AxisMin"
                        label={t('最小値')}
                        value={formValue.y2AxisMin}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="y2AxisMax"
                        label={t('最大値')}
                        value={formValue.y2AxisMax}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                      <LFCTextField
                        name="y2AxisInterval"
                        label={t('軸間隔')}
                        value={formValue.y2AxisInterval}
                        onChange={event => {
                          handleInputChange(event, formValue, setFormValue);
                        }}
                        inputProps={{
                          pattern: inputCheckPattern
                        }}
                      />
                    </LFCFormRowGroup>
                  </LFCTitledCard>
                </Grid>
              ) : (
                <></>
              )}
              <Grid item xs={12} textAlign="right">
                <LFCButton
                  onClick={() =>
                    setFormValue({
                      // X1
                      x1AxisMin: '',
                      x1AxisMax: '',
                      x1AxisInterval: '',
                      // Y1
                      y1AxisMin: '',
                      y1AxisMax: '',
                      y1AxisInterval: '',
                      // Y2
                      y2AxisMin: '',
                      y2AxisMax: '',
                      y2AxisInterval: ''
                    })
                  }
                >
                  {t('入力項目を全てクリア')}
                </LFCButton>
              </Grid>
              <Grid item xs={12}>
                <LFCFormRowGroup>
                  <LFCButton onClick={onApply}>{t('変更する')}</LFCButton>
                  <LFCButton onClick={props.onClose}>{t('キャンセル')}</LFCButton>
                </LFCFormRowGroup>
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
};
export default LFCChartsInputScaleDialog;
