import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCChartsLineLRobotCompare from '_components/charts/LFCChartsLineLrobotCompare';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_PRODUCTION_STATUS} from '_logics/LFCPageId';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsBarWeldingProductionStatus from '../_charts/LFCChartsBarWeldingProductionStatus';
import LFCChartsWeldingFreeLine from '../_charts/LFCChartsWeldingFreeLine';
import {useTranslation} from 'react-i18next';

const ProductionStatusPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_PRODUCTION_STATUS,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: fmtYYYYMMDDHHmmss(dayjs().add(-7, 'd')),
    select_date_to: fmtYYYYMMDDHHmmss(dayjs()),
    work: [],
    machine: [],
    serialno: '',
    ...defaultCondition
  });
  const [searchPrams, setSearchPrams] = useState({});
  useEffect(() => {
    setSearchPrams({
      select_date_from: searchValue.select_date_from,
      select_date_to: searchValue.select_date_to,
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      machine: !isEmpty(searchValue.machine) ? '{' + searchValue.machine.join(',') + '}' : null,
      serial: !isEmpty(searchValue.serialno) ? '%' + String(searchValue.serialno) + '%' : null
    });
  }, [searchValue]);

  const [data001, setData001] = useState([]);
  const [data002, setData002] = useState({});
  const [data003, setData003] = useState([]);
  const [data004, setData004] = useState({
    min: '',
    average: '',
    middle: '',
    max: ''
  });
  const [data005, setData005] = useState([]);
  const [data006, setData006] = useState({
    min: '',
    average: '',
    middle: '',
    max: ''
  });
  const [data007, setData007] = useState([]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  //平均値の取得
  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  //標準偏差の取得
  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    setData002({countOK: data001.reduce((sum: number, a: any) => sum + Number(a.ok), 0)});
  }, [data001]);

  useEffect(() => {
    const tmp_list = data003.map((a: any) => Number(a.welding_length));
    setData004({
      min:
        Math.min(...tmp_list) !== Infinity && Math.min(...tmp_list) !== -Infinity
          ? String(Math.min(...tmp_list))
          : '-',
      average: average(tmp_list) !== 0 ? average(tmp_list).toFixed(3) : '-',
      middle: stdev(tmp_list) !== 0 ? stdev(tmp_list).toFixed(3) : '-',
      max:
        Math.max(...tmp_list) !== Infinity && Math.max(...tmp_list) !== -Infinity
          ? String(Math.max(...tmp_list))
          : '-'
    });
  }, [data003]);

  useEffect(() => {
    const tmp_list = data005.map((a: any) => Number(a.time_length));
    setData006({
      min:
        Math.min(...tmp_list) !== Infinity && Math.min(...tmp_list) !== -Infinity
          ? String(Math.min(...tmp_list))
          : '-',
      average: average(tmp_list) !== 0 ? average(tmp_list).toFixed(3) : '-',
      middle: stdev(tmp_list) !== 0 ? stdev(tmp_list).toFixed(3) : '-',
      max:
        Math.max(...tmp_list) !== Infinity && Math.max(...tmp_list) !== -Infinity
          ? String(Math.max(...tmp_list))
          : '-'
    });
  }, [data005]);

  const apiFetchSub = async () => {
    setStartProcess(true);

    Promise.allSettled([
      // 機種情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 41001,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      }),
      // マシン情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42018,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['machine']};
        });
        setMachine(tmp);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const apiFetch = async () => {
    setStartProcess(true);

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3120,
        parameters: searchPrams,
        ds_state: setData001,
        name: `${t('実績数')}`,
        cancelToken: source.token,
        t
      }),
      // 3121,板金溶接：生産中の変化：溶接の長さ
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3121,
        parameters: searchPrams,
        ds_state: setData003,
        name: `${t('溶接長さ')}`,
        cancelToken: source.token,
        t
      }),
      // 3122,板金溶接：生産中の変化：作業時間
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3122,
        parameters: searchPrams,
        ds_state: setData005,
        name: `${t('作業時間')}`,
        cancelToken: source.token,
        t
      }),
      // 3123: 板金溶接：生産中の変化：溶接経路の始点・終点位置,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3123,
        parameters: searchPrams,
        ds_state: setData007,
        name: `${t('溶接経路')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetchSub();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 検索イベント
   * @returns
   */
  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  const date_calc = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);
    for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
      datelist.push(
        d.getFullYear() +
          '-' +
          ('00' + (d.getMonth() + 1)).slice(-2) +
          '-' +
          ('00' + d.getDate()).slice(-2)
      );
    }
    return datelist.sort();
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [machine, setMachine] = useState<{label: string}[]>([]);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, machine]);

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   * 検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel: searchValue.select_date_from,
        colName: t('対象期間From'),
        colWidth: 300
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel: searchValue.select_date_to,
        colName: t('対象期間To'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'machine',
        value: searchValue.machine,
        valueLabel: searchValue.machine[0] === t('すべて') ? ALL : searchValue.machine.join(','),
        colName: t('マシン'),
        colWidth: 300
      },
      {
        name: 'serialno',
        value: searchValue.serialno,
        valueLabel: searchValue.serialno,
        colName: t('シリアルNo'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.生産中の変化')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCAutocomplete
            name={'machine'}
            label={t('マシン')}
            id={'machine'}
            size="small" // small/medium
            value={multiSelectData(machine, searchValue.machine)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={machine}
          />
          <LFCTextField
            name="serialno"
            label={t('シリアルNo')}
            value={searchValue.serialno}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_PRODUCTION_STATUS}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} lg={11}>
            <LFCChartsBarWeldingProductionStatus
              title={t('実績数')}
              source={data001}
              xList={date_calc()}
              exportData={data001}
              exportFields={{
                datelist: t('日'),
                ng: t('OK数'),
                ok: t('NG数')
              }}
              exportFilename={`${t('実績数')}`}
              yAxisName={`${t('実績数')}`}
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <LFCSingleValues
              columns={[{field: 'countOK', headerName: t('生産数(OK)'), width: '100px'}]}
              source={data002}
            />
          </Grid>

          <Grid item xs={12} sm={10} lg={11}>
            <LFCChartsWeldingFreeLine
              title={t('溶接長さ')}
              source={data003}
              sourceKey={{x: 'exec_time', y: 'welding_length', series: 'work'}}
              yUnit="mm"
              yDigit={1}
              exportFields={{
                exec_time: t('日時'),
                work: t('機種'),
                welding_length: t('溶接長さ')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <LFCSingleValues
              columns={[
                {
                  field: 'min',
                  headerName: `${t('最小')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'average',
                  headerName: `${t('平均')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'middle',
                  headerName: t('中央'),
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'max',
                  headerName: `${t('最大')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                }
              ]}
              source={data004}
            />
          </Grid>

          <Grid item xs={12} sm={10} lg={11}>
            <LFCChartsWeldingFreeLine
              title={t('作業時間推移')}
              source={data005}
              sourceKey={{x: 'exec_time', y: 'time_length', series: 'work'}}
              yUnit={`${t('秒')}`}
              yDigit={1}
              exportFields={{
                exec_time: t('日時'),
                work: t('機種'),
                time_length: t('作業時間')
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2} lg={1}>
            <LFCSingleValues
              columns={[
                {
                  field: 'min',
                  headerName: `${t('最小')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'average',
                  headerName: `${t('平均')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'middle',
                  headerName: t('中央'),
                  formatter: v => round(v, 1),
                  width: '100px'
                },
                {
                  field: 'max',
                  headerName: `${t('最大')}`,
                  formatter: v => round(v, 1),
                  width: '100px'
                }
              ]}
              source={data006}
            />
          </Grid>

          <Grid item xs={12}>
            <LFCChartsLineLRobotCompare
              title={t('溶接経路の始点')}
              source={data007}
              series={''}
              color={['rgb(106,113,255)']}
              exportData={data007}
              exportFields={{
                exec_time: 'exec_time',
                welding_start_path_x: 'welding_start_path_x',
                welding_start_path_y: 'welding_start_path_y',
                welding_start_path_z: 'welding_start_path_z',
                welding_end_path_x: 'welding_end_path_x',
                welding_end_path_y: 'welding_end_path_y',
                welding_end_path_z: 'welding_end_path_z'
              }}
              exportFilename={`${t('溶接経路の始点')}`}
              typeName={`${t('始点')}`}
            />
          </Grid>
          <Grid item xs={12}>
            <LFCChartsLineLRobotCompare
              title={t('溶接経路の終点')}
              source={data007}
              series={''}
              color={['rgb(106,113,255)']}
              exportData={data007}
              exportFields={{
                exec_time: 'exec_time',
                welding_start_path_x: 'welding_start_path_x',
                welding_start_path_y: 'welding_start_path_y',
                welding_start_path_z: 'welding_start_path_z',
                welding_end_path_x: 'welding_end_path_x',
                welding_end_path_y: 'welding_end_path_y',
                welding_end_path_z: 'welding_end_path_z'
              }}
              exportFilename={`${t('溶接経路の終点')}`}
              typeName={`${t('終点')}`}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default ProductionStatusPage;
