import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {SEARCH_TYPE} from 'constants/weldingDemo';
import {Dispatch} from 'react';

type Props = {
  searchValue: any;
  setSearchValue: Dispatch<any>;
};

const WeldingDemoRadioGroup = (props: Props) => {
  const {searchValue, setSearchValue} = props;
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };
  return (
    <>
      <div style={{float: 'left', margin: 10, paddingRight: 10}}>Aggregation Unit:</div>
      <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
        {SEARCH_TYPE.map((item: any, index: number) => (
          <FormControlLabel
            value={item.id}
            key={'search_type-' + item.id}
            control={
              <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
            }
            label={
              <Typography display="block" key={'search_type-body-' + item.id}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>
    </>
  );
};

export default WeldingDemoRadioGroup;
