import {Box} from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridToolbarContainer,
  MuiEvent
} from '@mui/x-data-grid-pro';
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {useMySettings} from '_contexts/MySettingsProvider';
import {ExportCsv, ExportExcel} from '../ExportExcelCsv';

const CustomToolbar = (prpps: {
  rows: string | any[];
  fieldname: any;
  filename: string | undefined;
}) => {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer} style={{justifyContent: 'end'}}>
      {/* <GridToolbarExport /> */}
      {prpps.rows.length > 0 ? (
        <>
          <ExportCsv
            variant={'outlined'}
            data={prpps.rows}
            fields={prpps.fieldname}
            filename={prpps.filename}
          />
          <ExportExcel
            variant={'outlined'}
            data={prpps.rows}
            fields={prpps.fieldname}
            filename={prpps.filename}
          />
        </>
      ) : (
        <></>
      )}
    </GridToolbarContainer>
  );
};

/**
 * 引数
 */
interface Props {
  apiRef?: MutableRefObject<GridApiPro>;
  columns: GridColDef[];
  rows: Array<any>;
  height?: string;
  exportFilename?: string;
  headerText?: string;
  treeData?: boolean;
  group?: any;
  groupingColDef?: any;
  columnOrder?: any;
  rowCount?: number;
  rowHeight?: number;
  onCellClick?: (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => void;
}

/**
 * LFCDataGridSimple
 */
const LFCDataGridSimple = (props: Props) => {
  const mySettings = useMySettings();
  const [exportFileName, setExportFileName] = useState('');
  const [exportField, setExportField] = useState([]);

  useEffect(() => {
    if (props.rows.length > 0) {
      setExportFileName(props.exportFilename !== undefined ? props.exportFilename : 'LFC');

      let exField: any = [];
      props.columns.forEach((items: GridColDef) => {
        if (items.headerName) {
          exField.push({
            columnOrder: null,
            columnField: items.field,
            columnName: items.headerName
          });
        }
      });

      setExportField(exField);
    }
  }, [props]);

  return (
    <Box
      sx={{
        height: props.height ? props.height : null,
        color: 'white',
        // @ts-ignore
        '& .cell--line_productivity': {
          color: mySettings.isDark ? '#e2ef32' : '#7e8603'
        },
        '& .header--line_productivity': {
          color: mySettings.isDark ? '#e2ef32' : '#7e8603'
        },
        '& .cell--pph': {
          color: mySettings.isDark ? '#26c53f' : '#047c27'
        },
        '& .header--pph': {
          color: mySettings.isDark ? '#26c53f' : '#047c27'
        },
        '& .cell--aim_line_productivity': {
          color: mySettings.isDark ? '#e33857' : '#7c0429'
        },
        '& .header--aim_line_productivity': {
          color: mySettings.isDark ? '#e33857' : '#7c0429'
        },
        '& .cell--aim_pph': {
          color: mySettings.isDark ? '#4079ef' : '#05215e'
        },
        '& .header--aim_pph': {
          color: mySettings.isDark ? '#4079ef' : '#05215e'
        }
      }}
    >
      <DataGridPro
        apiRef={props.apiRef}
        columns={props.columns}
        rows={props.rows}
        density={'compact'}
        hideFooter
        showCellRightBorder
        showColumnRightBorder
        disableColumnMenu
        disableExtendRowFullWidth
        components={{
          Toolbar: CustomToolbar
        }}
        rowCount={props.rowCount ? props.rowCount : undefined}
        rowHeight={props.rowHeight ? props.rowHeight : 46}
        componentsProps={{
          toolbar: {
            rows: props.rows,
            fieldname: exportField,
            filename: exportFileName
          }
        }}
        treeData={props.treeData}
        getTreeDataPath={props.treeData ? props.group : undefined}
        groupingColDef={props.groupingColDef ? props.groupingColDef : undefined}
        // @ts-ignore
        defaultGroupingExpansionDepth={0}
        onCellClick={props.onCellClick}
      />
    </Box>
  );
};
export default LFCDataGridSimple;
