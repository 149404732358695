import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  xlist: any;
  searchType: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBox4 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);
  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp: any = [];
    let tmpOutPut: any = [];
    if (!isEmpty(props.xlist)) {
      props.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp.push(props.source.data_value[index]);
            }
          });
        } else {
          tmp.push([]);
        }
      });

      tmp.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut.push(CalcQuartile(a));
        } else {
          tmpOutPut.push([]);
        }
      });
    } else {
      props.source.xlist.find((b: string, index: number) => {
        props.searchType === 0
          ? tmpOutPut.push(props.source.data_value[index])
          : tmpOutPut.push(CalcQuartile([props.source.data_value[index]]));
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(boxData) && !isEmpty(props.xlist)) {
      props.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(
            Array.from(
              new Set(
                props.exportData.filter((a: any) => a.map === props.mapName).map((b: any) => b.work)
              )
            )
          ),
          deviceid: String(
            Array.from(
              new Set(
                props.exportData
                  .filter((a: any) => a.map === props.mapName)
                  .map((b: any) => b.deviceid)
              )
            )
          ),
          map: props.mapName,
          for_export: a,
          d0: boxData[index][4],
          d1: boxData[index][3],
          d2: boxData[index][2],
          d3: boxData[index][1],
          d4: boxData[index][0]
        });
      });
      setExportData(tmp);
    } else if (!isEmpty(boxData) && isEmpty(props.xlist)) {
      props.source.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(
            Array.from(
              new Set(
                props.exportData.filter((a: any) => a.map === props.mapName).map((b: any) => b.work)
              )
            )
          ),
          deviceid: String(
            Array.from(
              new Set(
                props.exportData
                  .filter((a: any) => a.map === props.mapName)
                  .map((b: any) => b.deviceid)
              )
            )
          ),
          map: props.mapName,
          for_export: props.exportData.filter((b: any) => b.idx === a)[0].lq_time,
          work_radius: props.exportData.filter((b: any) => b.idx === a)[0].work_radius,
          serial: props.exportData.filter((b: any) => b.idx === a)[0].serial
        });
      });
      setExportData(tmp);
    }
  }, [boxData]);

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
              if (params.seriesType === 'boxplot' || 'effectScatter') {
                if (props.searchType === 0) {
                  const targetIndex: number = params.dataIndex ?? 0;
                  const data: Props['exportData'] = exportData[targetIndex];
                  return (
                    t('連番') +
                    ': ' +
                    props.source.xlist[targetIndex] +
                    '<br/>' +
                    t('シリアルナンバー') +
                    ': ' +
                    data.serial +
                    '<br/>' +
                    t('検査日時') +
                    ': ' +
                    dayjs(data.for_export).format('YYYY-MM-DD HH:mm:ss') +
                    '<br/>' +
                    t('計測値') +
                    ': ' +
                    data.work_radius
                  );
                } else {
                  return (
                    params.name +
                    '<br/>' +
                    t('最大') +
                    ':' +
                    Number(params.value[5]).toFixed(2) +
                    '<br/>' +
                    'Q3: ' +
                    Number(params.value[4]).toFixed(2) +
                    '<br/>' +
                    t('中央') +
                    ' (or Q2): ' +
                    Number(params.value[3]).toFixed(2) +
                    '<br/>' +
                    'Q1: ' +
                    Number(params.value[2]).toFixed(2) +
                    '<br/>' +
                    t('最小') +
                    ':' +
                    Number(params.value[1]).toFixed(2)
                  );
                }
              } else if (params.seriesType === 'scatter') {
                return (
                  params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
                );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '8%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: !isEmpty(props.xlist) ? props.xlist : props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            splitLine: {
              show: props.searchType === 0 ? true : false
            },
            axisLabel: {
              rotate: '30',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            max: (value: any) => {
              return Math.ceil(value.max);
            },
            min: (value: any) => {
              return Math.floor(value.min);
            },
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              colorBy: 'series',
              name: props.searchType === 0 ? 'scatter' : 'boxplot',
              type: props.searchType === 0 ? 'effectScatter' : 'boxplot',
              data: boxData,
              rippleEffect: {
                number: 0,
                scale: 0,
                period: 0
              }
            }
          ]
        }}
        exportData={exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBox4;
