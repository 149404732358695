import React, {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useMySettings} from '_contexts/MySettingsProvider';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  xData?: any;
  lineData?: any;
  x: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  yAxisName: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

type LINE_DATA_TYPE = {
  name: string;
  type: string;
  color: string;
  yAxisIndex: number;
  data: any;
};

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarLineSearchByYear = (props: Props) => {
  const {t} = useTranslation();
  const renderFlgRef = useRef(false);
  const mySettings = useMySettings();

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState<any>([]);
  const [line, setLine] = useState([]);
  const [tmpData, setTmpData] = useState([]);
  const [mergeData, setMergeData] = useState([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // データソースクリア
    if (props.source.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});

    if (props.series) {
      // シリーズ指定あり
      let strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.source.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.series, value: grp}
          }
        });
        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          encode: {x: props.x.dsColumn, y: props.y.dsColumn},
          datasetIndex: i + 1
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        name: props.yAxisName,
        encode: {x: props.x.dsColumn, y: props.y.dsColumn}
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
  }, [props.source]);

  useEffect(() => {
    if (props.lineData.length > 0) {
      setLine(props.lineData.map((item: any) => item.不良率));
    } else {
      setLine([]);
    }
  }, [props.lineData]);

  useEffect(() => {
    let line_data: LINE_DATA_TYPE = {
      name: t('不良率'),
      type: 'line',
      color: mySettings.isDark ? 'white' : 'black',
      yAxisIndex: 1,
      data: line
    };
    setTmpData(series.concat(line_data));
  }, [line, series]);

  useEffect(() => {
    setMergeData(tmpData);
  }, [tmpData]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            left: '5%',
            right: 0,
            bottom: '10%',
            top: '20%',
            containLabel: true
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'axis'
          },
          color: props.color ? props.color : null,
          dataset: dataset,
          xAxis: [
            {
              type: 'category',
              data: props.xData,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                rotate: 45
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: props.yAxisName,
              nameTextStyle: {
                fontSize: 10
              },
              axisLabel: {
                interval: 0,
                fontSize: 10
              }
            },
            {
              type: 'value',
              name: t('不良率'),
              nameTextStyle: {
                fontSize: 10
              },
              axisLabel: {
                interval: 0,
                fontSize: 10
              }
            }
          ],
          series: mergeData
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height !== undefined ? props.height : '300px'}
      />
    </div>
  );
};
export default LFCChartsBarLineSearchByYear;
