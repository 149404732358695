import {Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {distinct, getLFCData, handleInputChange} from '_logics/LFCUtil';
import PathGenerateDig from './PathGenerateDig';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {
  PAGEID_LR_JOBTRENDANALYSIS,
  LOCATIONID_LR_JOBTRENDANALYSIS_PATHGENERATE
} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsBoxPath from '_components/charts/LFCChartsBoxPath';
import {useTranslation} from 'react-i18next';

const JobTrendAnalysisFittingPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  const [open, setOpen] = React.useState(false);

  const initialSearchValue = {
    map: '',
    page_type: '',
    selectType: '0',
    select_datetime_from: dayjs().add(-7, 'd').format('YYYY-MM-DDT00:00'),
    select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm')
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LR_JOBTRENDANALYSIS,
    LOCATIONID_LR_JOBTRENDANALYSIS_PATHGENERATE
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const [openData, setOpenData] = React.useState({
    x: '',
    y: 'Gap',
    gapno: 0,
    searchValue: {
      select_datetime_from: searchValue.select_datetime_from,
      select_datetime_to: searchValue.select_datetime_to
    }
  });
  const handleClose = () => {
    setOpenData({
      x: '',
      y: 'Gap',
      gapno: 0,
      searchValue: {
        select_datetime_from: searchValue.select_datetime_from,
        select_datetime_to: searchValue.select_datetime_to
      }
    });
    setOpen(false);
  };

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, selectType: event.target.value});
  };

  let selectType: any = [
    {name: t('ALL'), value: 0},
    {name: t('月毎'), value: 1},
    {name: t('日毎'), value: 2},
    {name: t('時間毎'), value: 3}
  ];

  const [data60026, setData60026] = useState([]);
  const [map_list, setMap_list] = useState<any>([]);
  const [path_address_list, setPath_address_list] = useState<any>([]);
  const [graphBaseData, setGraphBaseData] = useState([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setGraphBaseData([]);
    setData60026([]);
    await setStartProcess(true);
    switch (searchValue.selectType) {
      case 0:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60026,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60026,
          name: `LROBOT_${t('ジョブ比較分析')}_PathGenerate:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 1:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60126,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60026,
          name: `LROBOT_${t('ジョブ比較分析')}_PathGenerate:${t('月単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 2:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60226,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60026,
          name: `LROBOT_${t('ジョブ比較分析')}_PathGenerate:${t('日単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 3:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60326,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60026,
          name: `LROBOT_${t('ジョブ比較分析')}_PathGenerate:${t('時間単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      default:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60026,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60026,
          name: `LROBOT_${t('ジョブ比較分析')}_PathGenerate:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
    }
    await setStartProcess(false);
  };

  useEffect(() => {
    setMap_list(distinct(data60026, 'map'));
    setPath_address_list(distinct(data60026, 'path_address'));
  }, [data60026]);

  useEffect(() => {
    let tmp: any = [];
    map_list.forEach((map_string: string) => {
      let tmp_path: any = [];
      path_address_list.forEach((path_string: string, index: number) => {
        let data_value: any = [];
        data60026.forEach((item: any) => {
          if (
            item['map'] === map_string &&
            item['path_address'] === path_string &&
            item['gap'] !== null
          ) {
            data_value.push(Number(item['gap']));
          }
        });
        tmp_path.push(data_value);
      });
      tmp.push({
        map_string: map_string,
        data_value: tmp_path,
        x_list: path_address_list
      });
    });
    setGraphBaseData(tmp);
  }, [path_address_list]);

  let data60026_column = {
    lq_time: t('日付'),
    map: t('マップ'),
    path_address: 'path_address',
    gap: t('ギャップ')
  };

  const graphOnClick = (prams: any) => {
    setOpenData({
      x: prams.mapName,
      y: 'Gap',
      gapno: prams.name,
      searchValue: {
        select_datetime_from: searchValue.select_datetime_from,
        select_datetime_to: searchValue.select_datetime_to
      }
    });
    setOpen(true);
  };

  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'selectType',
        value: searchValue.selectType,
        valueLabel:
          String(searchValue.selectType) === '0'
            ? ALL
            : String(searchValue.selectType) === '1'
              ? t('月毎')
              : String(searchValue.selectType) === '2'
                ? t('日毎')
                : String(searchValue.selectType) === '3'
                  ? t('時間毎')
                  : '',
        colName: t('集計単位'),
        colWidth: 150
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.L-Rジョブ傾向分析')}:Path Generate`}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form ref={formRef}>
            <LFCFormRowGroup>
              <LFCDatetimePicker
                name={'select_datetime_from'}
                label={t('対象期間From')}
                value={searchValue.select_datetime_from}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCDatetimePicker
                name={'select_datetime_to'}
                label={t('対象期間To')}
                value={searchValue.select_datetime_to}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCSelectForm
                name={'selectType'}
                label={t('集計単位')}
                value={searchValue.selectType}
                id={'sl1'}
                onChange={handleChange}
                selectItem={selectType}
              />
              <LFCButton color="primary" onClick={() => doSearch()}>
                {t('検索')}
              </LFCButton>
              <LFCSavedCondition
                open={openSavedCondition}
                pageId={PAGEID_LR_JOBTRENDANALYSIS}
                locationNo={LOCATIONID_LR_JOBTRENDANALYSIS_PATHGENERATE}
                onLoad={onLoadSavedCondition}
                onSelect={onSelectSavedCondition}
                onClose={() => setOpenSavedCondition(false)}
                conditions={condition}
              />
            </LFCFormRowGroup>
          </form>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {graphBaseData.length > 0
              ? map_list.map((map_string: string, index: number) => {
                  return (
                    <Grid item xs={12} md={6} key={index}>
                      {map_string}
                      <LFCChartsBoxPath
                        title=""
                        source={
                          graphBaseData.filter((item: any) => item.map_string === map_string)[0]
                        }
                        x={{dsColumn: '', type: 'category'}}
                        y={{dsColumn: '', type: 'category'}}
                        xAxisName={'GapNo'}
                        exportData={data60026}
                        exportFields={data60026_column}
                        exportFilename={`${t('ジョブ傾向分析')}_PathGenerate`}
                        height={'20vh'}
                        bottom={'25'}
                        top={'20'}
                        onClick={graphOnClick}
                        mapName={map_string}
                      />
                    </Grid>
                  );
                })
              : false}
          </Grid>
        </Grid>
      </Grid>
      <PathGenerateDig open={open} onClose={handleClose} datas={openData} />
    </GenericTemplate>
  );
};

export default JobTrendAnalysisFittingPage;
