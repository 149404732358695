import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import Decimal from 'decimal.js-light';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  xAxisName?: string;
  onClick?: (prm: {data: any}) => void;
  limit?: any;
  limitRow?: any;
  extraDatas?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  zLineMin?: number;
  zLineMax?: number;
  zRenge?: number;
  zoomedChartId?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarZGap = (props: Props) => {
  const {t} = useTranslation();
  let y_value: any =
    props.source !== undefined ? props.source.map((item: any) => item['カウント数']) : [];

  let showData: any = [];

  let x_start = new Decimal(0);
  if (props.source[0] !== undefined && props.source[0].idx !== undefined) {
    x_start = new Decimal(props.zRenge as number);
    x_start = x_start.times(props.source[0].idx);
    for (
      x_start;
      x_start.toNumber() > Number(props.zLineMin);
      x_start.minus(props.zRenge as number)
    ) {
      x_start = x_start.minus(props.zRenge as number);
    }
  }

  let x_list: any = [];
  let i = new Decimal(x_start.toNumber());
  let r = !isNaN(props.zRenge as number) ? new Decimal(Number(props.zRenge)) : new Decimal(0);
  if (props.zRenge !== undefined && !isEmpty(i)) {
    for (i; i.toNumber() < Number(props.zLineMax); i = i.plus(r.toNumber())) {
      x_list.push(String(i + '_' + i.plus(r.toNumber()).toString()));
    }
  }
  let x_max: number = 1;
  x_list.forEach((a: any, index: number) => {
    let tmp: any = a.split('_');
    let tmp_a = new Decimal(tmp[0]);
    if (props.source.filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber()).length !== 0) {
      showData.push(
        Number(
          props.source.filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber())[0]?.カウント数
        )
      );
    } else {
      showData.push(null);
    }
  });

  let x_max_tmp = Math.max(...showData);
  if (x_max_tmp > 0) {
    let tmp: any = new Decimal(x_max_tmp);
    x_max = Math.ceil(tmp.toNumber());
  }

  const makeMin = () => {
    if (x_list[0] !== undefined) {
      let tmp: any = x_list[0].split('_');
      return Number(tmp[0]);
    } else {
      return null;
    }
  };

  const makeMax = () => {
    if (x_list[x_list.length - 1] !== undefined) {
      let tmp: any = x_list[x_list.length - 1].split('_');
      return Number(tmp[1]);
    } else {
      return null;
    }
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: [
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 0
            },
            {
              type: 'value',
              min: makeMin(),
              max: makeMax(),
              axisLabel: {
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 1,
              show: false,
              axisPointer: {
                show: true
              }
            }
          ],
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            },
            yAxisIndex: 0,
            max: x_max
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            selected: {
              目標: true,
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均-3σ': false,
              '平均+3σ': false
            },
            formatter: (prm: any) => {
              return t(prm);
            },
            data: [
              {
                name: '目標',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[0]
                }
              },
              {
                name: '上限',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[7]
                }
              },
              {
                name: '下限',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[8]
                }
              },
              {
                name: '中央',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[2]
                }
              },
              {
                name: '平均',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[4]
                }
              },
              {
                name: '平均-3σ',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[6]
                }
              },
              {
                name: '平均+3σ',
                icon: 'circle',
                itemStyle: {
                  color: colorPallet[5]
                }
              }
            ],
            top: props.zoomStatus ? '95%' : '90%',
            textStyle: {
              fontSize: 10
            }
          },
          series: [
            {
              name: t('個'),
              data: showData,
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 0
            },
            {
              name: '目標',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('目標'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[0],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('目標'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d1 : ''
                  }
                ]
              }
            },
            {
              name: '上限',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('上限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[7],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('上限'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d3 : ''
                  }
                ]
              }
            },
            {
              name: '下限',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('下限'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[8],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('下限'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d5 : ''
                  }
                ]
              }
            },
            {
              name: '中央',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('中央'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[2],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('中央'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d9 : ''
                  }
                ]
              }
            },
            {
              name: '平均',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[4],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d8 : ''
                  }
                ]
              }
            },
            {
              name: '平均+3σ',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均+3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[5],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均+3σ'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d12 : ''
                  }
                ]
              }
            },
            {
              name: '平均-3σ',
              type: 'line',
              xAxisIndex: 1,
              markLine: {
                label: {
                  formatter: t('平均-3σ'),
                  show: true,
                  position: 'end'
                },
                symbol: false,
                lineStyle: {
                  color: colorPallet[6],
                  width: 2,
                  type: 'solid'
                },
                data: [
                  {
                    type: 'guide',
                    name: t('平均-3σ'),
                    xAxis: x_list.length > 0 ? props.extraDatas.d13 : ''
                  }
                ]
              }
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              if (prm.seriesName === t('個')) {
                return (
                  prm.name +
                  'mm<br>' +
                  t('検査数') +
                  ':' +
                  prm.value +
                  t('件') +
                  '<br>' +
                  t('構成比') +
                  ':' +
                  (
                    (prm.value /
                      y_value.reduce((sum: number, item: any) => sum + Number(item), 0)) *
                    100
                  ).toFixed(1) +
                  '%'
                );
              } else {
                return prm.data.name + ':  ' + prm.data.value + 'mm';
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '5%',
            right: '0%',
            bottom: '22%'
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params.seriesName === t('個') && props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
};
export default LFCChartsBarZGap;
