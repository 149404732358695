import {useEffect, useState} from 'react';
import GenericTemplate from '_templates/GenericTemplate';
import {Box, Grid} from '@mui/material';
import {useSnackbar} from 'notistack';
import {getLFCData} from '_logics/LFCUtil';

import YieldCard from './components/YieldCard';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

const Yield = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [listData, setListData] = useState<any>([
    {
      リペア数: 0,
      加工完了数: 0,
      完成品: 0,
      工程内不良数: 0,
      廃棄品: 0,
      検査工程廃棄数: 0,
      溶接工程廃棄数: 0,
      直行数: 0,
      総投入数: 0
    }
  ]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    (async () => {
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50038,
        parameters: {},
        ds_state: setListData,
        name: `POC_${t('歩留まり')}`,
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    })();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <GenericTemplate title={t('menu.page_name.歩留まり')}>
      <ProgressBar startProcess={startProcess} />
      <Box mt={1}>
        <Grid container spacing={2}>
          <YieldCard
            cardTitle={t('投入')}
            title={[t('総投入数')]}
            value={listData}
            imageName={`${t('カート')}.png`}
          />
          <YieldCard
            cardTitle={t('加工')}
            title={[t('加工完了数'), t('溶接工程廃棄数')]}
            value={listData}
            imageName={`${t('マシーン')}.png`}
          />
          <YieldCard
            cardTitle={t('検査')}
            title={[t('直行数'), t('工程内不良数'), t('検査工程廃棄数')]}
            value={listData}
            imageName={`${t('タスクボード')}.png`}
          />
          <YieldCard
            cardTitle={t('完成品')}
            title={[t('完成品'), t('リペア数')]}
            value={listData}
            imageName={`${t('箱')}_${t('グレー')}.png`}
          />
          <Grid item xs={9}>
            &nbsp;
          </Grid>
          <YieldCard
            cardTitle={t('廃棄品')}
            title={[t('廃棄品')]}
            value={listData}
            imageName={`${t('ゴミ箱')}_${t('グレー')}.png`}
          />
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default Yield;
