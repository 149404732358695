/**
 * すべて(プルダウン等で利用)
 */
export const ALL = 'すべて';

/**
 * 時分
 * ja: 08:02
 * en: 8:02 PM
 */
export const FORMAT_HHmm = 'LT';

/**
 * 時分秒
 * ja: 08:02:18
 * en: 8:02:18 PM
 */
export const FORMAT_HHmmSS = 'LTS';

/**
 * 年月日
 * ja: 2018/08/16
 * en: 08/16/2018
 */
export const FORMAT_YYYYMMDD = 'L';

/**
 * 年月日　読み付き
 * ja: 2022年7月11日
 * en: August 16, 2018
 */
export const FORMAT_YYYYMMMMDD = 'LL';

/**
 * 年月日時分秒　読み付き
 * ja: 2022年7月11日 13:00
 * en: August 16, 2018 8:02 PM
 */
export const FORMAT_YYYYMMDDHHmmSS = 'LLL';

/**
 * 年月日時分秒　読み付き　曜日付き
 * ja: 2022年7月11日 月曜日 13:00
 * en: Thursday, August 16, 2018 8:02 PM
 */
export const FORMAT_YYYYMMMMDDddddHHmmSS = 'LLLL';
