import React, {useEffect, useState} from 'react';
import * as echarts from 'echarts';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import dayjs from 'dayjs';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {useTranslation} from 'react-i18next';

interface SourceType {
  machine: string;
  judge: number;
  detection_time_from: string;
  detection_time_to: string;
  work: string;
}

interface ExportFieldsType {
  machine: string;
  judge: string;
  detection_time_from: string;
  detection_time_to: string;
}

interface Props {
  title: string;
  source: SourceType[];
  exportFields: ExportFieldsType;
  exportFilename?: string;
}

/**
 * LFCChartsGanttChartWelding
 * 稼働状況ガントチャート用
 * @param props
 * @returns
 */
const LFCChartsGanttChartWelding = (props: Props) => {
  const {t} = useTranslation();
  const [dataY, setDataY] = useState<string[]>([]);
  const [seriesData, setSeriesData] = useState([]);

  useEffect(() => {
    //Y軸データ設定
    const deviceList = Array.from(
      new Set(props.source.map((item: SourceType) => item.machine))
    ).sort();
    //シリーズデータ設定
    const seriesDataTmp: any = props.source.map((item: SourceType, index: number) => {
      return {
        name: item.machine,
        value: [
          deviceList.findIndex((device_name: string) => device_name === item.machine),
          dayjs(item.detection_time_from).valueOf(),
          dayjs(item.detection_time_to).valueOf(),
          item.judge,
          item.work
        ],
        itemStyle: {
          color: item.judge === 0 ? '#4CAF50' : '#F44336'
        }
      };
    });

    setDataY(deviceList);
    setSeriesData(seriesDataTmp);
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        tooltip: {
          formatter: (params: any) => {
            const judge = `<br/>${t('判定')}:` + (params.value[3] === 0 ? 'OK' : 'NG');
            const start_timestamp = fmtYMD(dayjs(params.value[1]));
            const end_timestamp = fmtYMD(dayjs(params.value[2]));
            const workTimedifferenceInSeconds = dayjs(params.value[2]).diff(
              dayjs(params.value[1]),
              'second'
            );

            const timeshow = `<div><span>${t(
              '作業時間'
            )}(sec) : ${workTimedifferenceInSeconds}</span><br/><span>${t(
              '開始'
            )} : ${start_timestamp}</span><br/><span>${t('終了')} : ${end_timestamp}</span></div>`;
            return `${params.marker}${t('機種')}:${params.value[4]}${judge}${timeshow}`;
          }
        },
        grid: {
          top: 60,
          left: 120,
          right: 10
        },
        dataZoom: [
          {
            type: 'slider'
          },
          {
            type: 'inside'
          }
        ],
        xAxis: {
          type: 'time',
          min: dayjs().add(-12, 'hour').valueOf(),
          max: dayjs().valueOf(),
          position: 'top'
        },
        yAxis: {
          inverse: true,
          data: dataY
        },
        series: [
          {
            type: 'custom',
            renderItem: (params: any, api: any) => {
              const categoryIndex = api.value(0);
              const start = api.coord([api.value(1), categoryIndex]);
              const end = api.coord([api.value(2), categoryIndex]);
              const height = api.size([0, 1])[1] * 0.9;
              const rectShape = echarts.graphic.clipRectByRect(
                {
                  x: start[0],
                  y: start[1] - height / 2,
                  width: end[0] - start[0],
                  height: height
                },
                {
                  x: params.coordSys.x,
                  y: params.coordSys.y,
                  width: params.coordSys.width,
                  height: params.coordSys.height
                }
              );
              return (
                rectShape && {
                  type: 'rect',
                  shape: rectShape,
                  style: api.style()
                }
              );
            },
            encode: {
              x: [1, 2],
              y: 0
            },
            data: seriesData
          }
        ]
      }}
      exportData={props.source}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
    />
  );
};

export default LFCChartsGanttChartWelding;
