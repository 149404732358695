import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import {getNewCognitoUser} from '_logics/LFCCognito';
import {handleInputChange} from '_logics/LFCUtil';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
}

/**
 * パスワードリセット依頼ダイアログ
 * @returns
 */
const ResetPassDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const formRefPassResetPop = useRef<HTMLFormElement>(null!);
  const [values, setValues] = useState({
    userId: ''
  });

  /**
   * パスワードリセット依頼処理
   * @returns
   */
  const passReset = () => {
    // input check
    if (!formRefPassResetPop.current?.reportValidity()) {
      return;
    }

    const cognitoUser = getNewCognitoUser(values.userId);

    cognitoUser.forgotPassword({
      onSuccess: data => {
        enqueueSnackbar(t('message.登録されているアドレスにメールを送りました。ご確認下さい。'), {
          variant: 'info'
        });
        props.onClose();
      },
      onFailure: err => {
        enqueueSnackbar(`${t('message.ユーザIDが存在しません。')}(${err.message})`, {
          variant: 'error'
        });
      }
    });
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle id="form-dialog-title">{t('パスワードリセット')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans i18nKey={'message.パスワードリセット依頼文章'} />
        </DialogContentText>
        <form ref={formRefPassResetPop}>
          <LFCTextField
            name="userId"
            label={t('ユーザID')}
            value={values.userId}
            onChange={event => {
              handleInputChange(event, values, setValues);
            }}
            required
            margin="normal"
            fullWidth
            autoFocus
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LFCButton
          onClick={() => {
            props.onClose();
          }}
        >
          {t('キャンセル')}
        </LFCButton>
        <LFCButton color="primary" onClick={passReset}>
          {t('実行')}
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};
export default ResetPassDig;
