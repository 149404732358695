import {SelectChangeEvent} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import {getLFCData} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  allowAll?: boolean;
  companyId: string;
}

/**
 * ハートビートデバイスプルダウン
 * @param props
 * @returns
 */
const LFCSelectFormHeartBeatDevice = React.memo((props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [items, setItems] = useState<{name: string; value?: string}[]>([{name: t('すべて')}]);

  useEffect(() => {
    // マウント時処理
    if (!props.companyId) return;

    // 会社情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 302,
      parameters: {},
      cancelToken: source.token,
      t,
      rep_company_id: props.companyId
    }).then(ds => {
      const tmp: {name: string; value?: string}[] = ds.map((item: any, index: number) => {
        return {name: String(item['device']), value: String(item['device'])};
      });

      // 選択候補に「全て」を追加
      if (props.allowAll ?? false) {
        tmp.unshift({name: t('すべて'), value: `${t('すべて')}`});
      }

      setItems(tmp);
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, [props.companyId]);

  return (
    <LFCSelectForm
      name={props.name}
      label={props.label ? props.label : t('検査装置')}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      margin={props.margin}
      required={props.required}
      size={props.size}
      multiple={props.multiple}
      selectItem={items}
      id={'sf_device'}
    />
  );
});
export default LFCSelectFormHeartBeatDevice;
