import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {LOCATIONID_WELDING_SHOW_MAIN, PAGEID_WELDING_SHOW} from '_logics/LFCPageId';
import {handleInputChange} from '_logics/LFCUtil';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {
  MenuItem,
  Select,
  Switch,
  FormControl,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';

interface WeldingShowSearchBarProps {
  formRef: any;
  searchValue: any;
  t: any;
  setSearchValue: any;
  work: any;
  autoOnChange: any;
  autoCompleteReset: any;
  setAutoCompleteReset: any;
  deviceid: any;
  doSearch: any;
  resetSearch: any;
  openSavedCondition: any;
  setOpenSavedCondition: any;
  condition: any;
  onLoadSavedCondition: any;
  onSelectSavedCondition: any;
  onChangeShowLatestOnly: any;
  onChangeAutoReload: any;
  onSelectAutoReloadInterval: any;
}

export const WeldingShowSearchBar = ({
  formRef,
  searchValue,
  setSearchValue,
  work,
  autoOnChange,
  autoCompleteReset,
  setAutoCompleteReset,
  deviceid,
  doSearch,
  resetSearch,
  openSavedCondition,
  setOpenSavedCondition,
  condition,
  onLoadSavedCondition,
  onSelectSavedCondition,
  onChangeShowLatestOnly,
  onChangeAutoReload,
  onSelectAutoReloadInterval,
  t
}: WeldingShowSearchBarProps) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <form ref={formRef}>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name={'select_datetime_from'}
              label={t('対象期間From')}
              value={searchValue.select_datetime_from}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
              required
            />
            <LFCDatetimePicker
              name={'select_datetime_to'}
              label={t('対象期間To')}
              value={searchValue.select_datetime_to}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
              required
            />
            <LFCAutocomplete
              name={t('work')}
              label={t('機種')}
              id={'work'}
              size="small"
              value={multiSelectData(work, searchValue.work)}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={work}
            />
            <LFCAutocomplete
              name={'deviceid'}
              label={t('検査装置')}
              id={'deviceid'}
              size="small"
              value={multiSelectData(deviceid, searchValue.deviceid)}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={deviceid}
            />
            <LFCButton color="primary" onClick={() => doSearch()}>
              {t('検索')}
            </LFCButton>
            <LFCButton onClick={() => resetSearch()}>{t('リセット')}</LFCButton>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={PAGEID_WELDING_SHOW}
              locationNo={LOCATIONID_WELDING_SHOW_MAIN}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
            <div style={{display: 'flex', justifyContent: 'flex-end', height: '40px'}}>
              <FormControlLabel
                label="最新のみ"
                labelPlacement="start"
                control={
                  <Switch
                    checked={searchValue.show_latest_only}
                    onChange={onChangeShowLatestOnly}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                }
              />
            </div>
            <div style={{display: 'flex', justifyContent: 'flex-end', height: '40px'}}>
              <FormControlLabel
                label="自動更新"
                labelPlacement="start"
                control={
                  <Switch
                    checked={searchValue.auto_reload}
                    onChange={onChangeAutoReload}
                    inputProps={{'aria-label': 'controlled'}}
                  />
                }
              />
              <FormControl style={{marginLeft: 10}}>
                <Select
                  value={searchValue.auto_reload_interval}
                  onChange={onSelectAutoReloadInterval}
                  displayEmpty
                  inputProps={{'aria-label': 'auto-reload-interval'}}
                  style={{height: '40px', width: '80px'}}
                  disabled={!searchValue.auto_reload}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={60}>60</MenuItem>
                </Select>
              </FormControl>
              <Typography
                variant="body1"
                style={{
                  marginLeft: 5,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex'
                }}
              >
                秒
              </Typography>
            </div>
          </LFCFormRowGroup>
        </form>
      </Grid>
    </Grid>
  );
};
