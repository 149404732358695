import {Box} from '@mui/material';
import {
  DataGridPro,
  GridCallbackDetails,
  GridCellParams,
  gridClasses,
  GridColDef,
  GridToolbarContainer,
  MuiEvent
} from '@mui/x-data-grid-pro';
import {GridApiPro} from '@mui/x-data-grid-pro/models/gridApiPro';
import React, {MutableRefObject, useEffect, useState} from 'react';
import {ExportCsv, ExportExcel} from '../ExportExcelCsv';

const CustomToolbar = (prpps: {
  rows: string | any[];
  fieldname: any;
  filename: string | undefined;
}) => {
  return (
    <GridToolbarContainer className={gridClasses.toolbarContainer} style={{justifyContent: 'end'}}>
      {/* <GridToolbarExport /> */}
      {prpps.rows.length > 0 ? (
        <>
          <ExportCsv
            variant={'outlined'}
            data={prpps.rows}
            fields={prpps.fieldname}
            filename={prpps.filename}
          />
          <ExportExcel
            variant={'outlined'}
            data={prpps.rows}
            fields={prpps.fieldname}
            filename={prpps.filename}
          />
        </>
      ) : (
        <></>
      )}
    </GridToolbarContainer>
  );
};

/**
 * 引数
 */
interface Props {
  apiRef?: MutableRefObject<GridApiPro>;
  columns: GridColDef[];
  rows: Array<any>;
  height?: string;
  onCellClick?: (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => void;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  initialState?: any;
  columnOrder?: any;
  style?: any;
  rowHeight?: number;
  pageSize?: number;
  getRowClassName?: any;
  hideFooter?: boolean;
  autoHeight?: boolean;
  pagination?: boolean;
}

/**
 * LFCDataGrid
 */
const LFCDataGrid = (props: Props) => {
  const [exportFileName, setExportFileName] = useState('');
  const [exportField, setExportField] = useState([]);

  useEffect(() => {
    if (props.rows.length > 0) {
      setExportFileName(
        (props.exportFilename !== undefined ? props.exportFilename : 'LFC') +
          '_' +
          new Date().getFullYear() +
          '_' +
          (new Date().getMonth() + 1) +
          '_' +
          new Date().getDate()
      );

      // ファイル出力項目定義
      let exField: any = [];
      props.columns.forEach((items: GridColDef) => {
        // 出力対象判定
        if (items.headerName && !items.hide) {
          exField.push({
            columnOrder: props.columnOrder.find((itemkey: any) => itemkey.name === items.field)
              ? props.columnOrder.find((itemkey: any) => itemkey.name === items.field).order
              : null,
            columnField: items.field,
            columnName: items.headerName
          });
        }
      });

      setExportField(exField);
    }
  }, [props]);

  return (
    <Box style={{height: props.height ?? '200px'}}>
      <DataGridPro
        apiRef={props.apiRef}
        columns={props.columns}
        rows={props.rows}
        density={'compact'}
        pagination={props.pagination ?? true}
        hideFooter={props.hideFooter ?? false}
        autoPageSize
        pageSize={props.pageSize !== undefined ? props.pageSize : undefined}
        components={{
          Toolbar: CustomToolbar
        }}
        componentsProps={{
          toolbar: {
            rows: props.rows,
            fieldname: exportField,
            filename: exportFileName
          }
        }}
        onCellClick={props.onCellClick}
        disableSelectionOnClick
        showCellRightBorder
        showColumnRightBorder
        initialState={
          props.initialState
            ? {pinnedColumns: props.initialState}
            : {
                pinnedColumns: {
                  left: [],
                  right: []
                }
              }
        }
        style={props.style}
        rowHeight={props.rowHeight !== undefined ? props.rowHeight : 50}
        rowsPerPageOptions={[10]}
        getRowClassName={props.getRowClassName !== undefined ? props.getRowClassName : undefined}
        autoHeight={props.autoHeight ?? false}
      />
    </Box>
  );
};
export default LFCDataGrid;
