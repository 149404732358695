import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any | {};
  xData?: any;
  yAxisName?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  onClick?: (prm: {data: any}) => void;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  type?: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineMultiAxis = (props: Props) => {
  const {t} = useTranslation();
  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          left: '8%',
          right: '5%',
          bottom: '20%',
          top: '15%'
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 11,
          textStyle: {
            fontSize: 10
          },
          icon: 'circle'
          // selected: legendSelected,
        },
        tooltip: {
          trigger: 'axis',
          position: (point: any, params: any, dom: any, rect: any, size: any) => {
            return [point[0] + 20, '10%'];
          },
          formatter: (prm: any) => {
            let tmp: any;
            if (props.type !== 0) {
              tmp = t('計測日時') + ':' + prm[0].axisValue + '<br/>';
              prm.forEach((a: any) => {
                tmp +=
                  a.marker +
                  t(a.seriesName) +
                  ':' +
                  (a.value !== undefined ? a.value : t('データなし')) +
                  '<br/>';
              });
            } else {
              tmp = t('計測日時') + ':' + props.source[prm[0].dataIndex].lq_time + '<br/>';
              prm.forEach((a: any) => {
                tmp +=
                  a.marker +
                  t(a.seriesName) +
                  ':' +
                  (a.value !== undefined ? a.value : t('データなし')) +
                  '<br/>';
              });
            }
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color ? props.color : null,
        xAxis: {
          type: 'category',
          data: props.xData !== null ? props.xData : null,
          axisLabel: {
            rotate: '15',
            fontSize: 10
          }
        },
        yAxis: [
          {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            axisLabel: {
              fontSize: 10
            }
          }
        ],
        series: [
          {
            name: 'x_gap',
            data: props.source !== undefined ? props.source.map((a: any) => a.x_gap) : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: 'rgba(0, 200, 400)'
          },
          {
            name: 'y_gap',
            data: props.source !== undefined ? props.source.map((a: any) => a.y_gap) : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: 'rgba(600, 200, 100)'
          },
          {
            name: 'z_gap',
            data: props.source !== undefined ? props.source.map((a: any) => a.z_gap) : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: 'rgba(200, 90, 200)'
          }
        ]
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height !== undefined ? props.height : '300px'}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
    />
  );
};
export default LFCChartsLineMultiAxis;
