import {Box, Divider, Grid} from '@mui/material';
import {green} from '@mui/material/colors';
import {
  GridCallbackDetails,
  GridCellParams,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import axios from 'axios';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {useAuthUser} from '_contexts/AuthUserProvider';
import {ImageView, rendJudgeRedDisp} from '_logics/LFCRenderUtil';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import DefectiveProcedureDetailDig from './components/DefectiveProcedureDetailDig';
import {useTranslation} from 'react-i18next';

const DefectiveProcedure = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const {enqueueSnackbar} = useSnackbar();
  const authUser = useAuthUser();
  const [startProcess, setStartProcess] = useState(true);
  const [searchValue, setSearchValue] = useState({
    judgestatus: 1,
    serialno: ''
  });

  const [listData, setListData] = useState([]);
  const [uuidListData, setUuidListData] = useState([]);
  const [selectSerial, setSelectSerial] = useState('');
  const [selectUuid, setSelectUuid] = useState('');
  const apiRef = useGridApiRef();
  const apiRef_0 = useGridApiRef();

  const [userData, setUserData] = useState([]);
  const [operationUser, setOperationUser] = useState<any>([]);

  interface SELECTUSER {
    id: string | null;
    name: string | null;
  }

  const [selectUser, setSelectUser] = useState<SELECTUSER>({
    id: '',
    name: ''
  });

  const [open, setOpen] = React.useState(false);
  const [openUuid, setOpenUuid] = React.useState('');
  const handleClose = () => {
    afterUpdate();
    setOpen(false);
  };

  const [tmpDefectType, setTmpDefectType] = useState<any>([]);
  const [tmpDefectCauseType, setTmpDefectCauseType] = useState<any>([]);

  const [imagePath, setImagePath] = useState<any>([]);

  const thisNendo = () => {
    let today: any = new Date();
    if (today.getMonth() + 1 < 4) {
      return today.getFullYear() - 1;
    } else {
      return today.getFullYear();
    }
  };

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setStartProcess(true);
    setListData([]);
    setUuidListData([]);
    setSelectSerial('');
    setSelectUuid('');
    thisNendo();
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });

    apiFetch().then(() => setStartProcess(false));
  }, [searchValue]);

  const [uuidListDataSort, setUuidListDataSort] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (uuidListData.length > 0) {
      tmp = [...uuidListData].sort((a: any, b: any) => {
        return a.map < b.map ? -1 : 1;
      });
    }
    setUuidListDataSort(tmp);
  }, [uuidListData]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const apiFetch = async () => {
    if (!isEmpty(apiRef.current)) {
      apiRef.current.setPage(0);
    }
    if (!isEmpty(apiRef_0.current)) {
      apiRef_0.current.setPage(0);
    }
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50024,
      parameters: {
        judge: searchValue.judgestatus !== 9 ? searchValue.judgestatus : null,
        serial: searchValue.serialno !== '' ? `%${searchValue.serialno}%` : null
      },
      ds_state: setListData,
      name: 'POC_不良処置_一覧',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50026,
      parameters: {},
      ds_state: setUserData,
      name: 'POC_処置者_一覧',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50027,
      parameters: {},
      ds_state: setTmpDefectType,
      name: `POC_${t('不良処置')}_${t('不良内容')}_${t('プルダウン')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50028,
      parameters: {},
      ds_state: setTmpDefectCauseType,
      name: `POC_${t('不良処置')}_${t('発生形態')}_${t('プルダウン')}`,
      cancelToken: source.token,
      t
    });
  };

  const searchUuid = async (uuid: string) => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50025,
      parameters: {
        uuid: uuid
      },
      ds_state: setUuidListData,
      name: 'POC_不良処置_UUID選択',
      t
    });
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    setUuidListData([]);
    setSelectSerial(params.row.serial);
    setSelectUuid(params.row.uuid);
    searchUuid(params.row.uuid).then(() => true);
  };

  const onClickImage = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    if (selectUser.id !== '') {
      setOpen(true);
      setOpenUuid(params.row);
    } else {
      formRef.current?.reportValidity();
      // enqueueSnackbar(`処置者を選択してください。`, {variant: "warning"});
    }
  };

  const afterUpdate = async () => {
    if (!isEmpty(apiRef.current)) {
      apiRef.current.setPage(0);
    }
    if (!isEmpty(apiRef_0.current)) {
      apiRef_0.current.setPage(0);
    }
    setUuidListData([]);
    setSelectSerial('');
    setSelectUuid('');
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50024,
      parameters: {
        judge: searchValue.judgestatus !== 9 ? searchValue.judgestatus : null,
        serial: searchValue.serialno !== '' ? `%${searchValue.serialno}%` : null
      },
      ds_state: setListData,
      name: 'POC_不良処置_一覧',
      cancelToken: source.token,
      t
    });
  };

  useEffect(() => {
    let tmp: any = [{name: '選択してください', value: ''}];
    let tmp2: any = tmp.concat(
      userData.map((item: any) => {
        return {name: item['user_name'], value: item['user_id']};
      })
    );
    // setOperationUser(userData.map((item: any) => {return {name: item["user_disp_name"], value: item["user_id"]}}));
    setOperationUser(tmp2);
  }, [userData]);

  const setUser = (event: any) => {
    if (event.target.value !== '') {
      setSelectUser({
        id: event.target.value,
        name: userData.filter((item: any) => item['user_id'] === event.target.value)[0]['user_name']
      });
    }
  };

  const defectTypeShow = (prams: any) => {
    let tmp = tmpDefectType.filter((item: any) => item['defect_type'] === Number(prams.value));
    return tmp.length > 0 ? tmp[0]['defect_type_name'] : '';
  };
  const defectCauseTypeShow = (prams: any) => {
    let tmp = tmpDefectCauseType.filter(
      (item: any) => item['defect_cause_type'] === Number(prams.value)
    );
    return tmp.length > 0 ? tmp[0]['defect_cause_type_name'] : '';
  };

  let COLUMN_ORDER = [{name: 'id', order: 1}];

  let columns = [
    {
      field: 'serial',
      headerName: '刻印No',
      description: '刻印No',
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: 'lq_time',
      headerName: `${t('日時')}`,
      description: `${t('日時')}`,
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: 'work',
      headerName: 'ワーク',
      description: 'ワーク',
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: 'judge',
      headerName: `${t('総合判定')}`,
      description: '総合判定',
      width: 80,
      sortable: false,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeRedDisp(prms.value)
    }
  ];

  let uuidList_columns = [
    {
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位'),
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: 'judge',
      headerName: `${t('判定')}`,
      description: `${t('判定')}`,
      width: 120,
      sortable: false,
      hide: false
    },
    {
      field: 'img_filename',
      headerName: t('画像'),
      description: '画像',
      width: 120,
      sortable: false,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => ImageView(prms, imagePath[0].svalue)
    },
    {
      field: 'defect_type',
      headerName: '不良内容',
      description: '不良内容',
      width: 120,
      sortable: false,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => defectTypeShow(prms)
    },
    {
      field: 'defect_cause_type',
      headerName: '発生形態',
      description: '発生形態',
      width: 120,
      sortable: false,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => defectCauseTypeShow(prms)
    },
    {
      field: 'memo',
      headerName: '備考',
      description: '備考',
      width: 120,
      sortable: false,
      hide: false
    }
  ];

  // 50032,POC_チップ交換_リペア不要,"CALL poc.recovery_work(cast(:uuid as varchar), cast(:userid as integer))"
  const notRepair = async () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    setStartProcess(false);
  };

  // 50033,POC_チップ交換_廃棄,"CALL poc.dispose_work(cast(:uuid as varchar), cast(:userid as integer))"
  const toDispose = async () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    setStartProcess(false);
  };

  const restSearch = () => {
    setStartProcess(true);
    setSelectUser({
      id: '',
      name: ''
    });
    setListData([]);
    setUuidListData([]);
    setSelectSerial('');
    setSelectUuid('');
    setSearchValue({
      judgestatus: 1,
      serialno: ''
    });
    thisNendo();
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    }).then(() => true);
    apiFetch().then(() => setStartProcess(false));
    apiRef_0.current.setPage(0);
  };

  return (
    <GenericTemplate title={t('menu.page_name.不良処置')}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCSelectFormJudge
            name={'judgestatus'}
            value={searchValue.judgestatus}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCTextField
            name="serialno"
            label={t('刻印No')}
            value={searchValue.serialno}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCButton onClick={() => restSearch()}>{t('リセット')}</LFCButton>
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={5}>
            <LFCDataGrid
              apiRef={apiRef_0}
              columns={columns}
              rows={listData}
              height="80vh"
              exportFilename={`${t('不良処置一覧')}`}
              onCellClick={onCellClick}
              columnOrder={COLUMN_ORDER}
              style={{cursor: 'pointer'}}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <LFCSingleValue title={t('刻印No')} val={selectSerial} fontColor={green[500]} />
            <img
              src={`${process.env.PUBLIC_URL}images/defective-procedure/work写真.png`}
              alt=""
              style={{width: '200px'}}
            />
            <form ref={formRef}>
              <LFCFormRowGroup>
                <LFCSelectForm
                  name={'operationUser'}
                  label={t('処置者')}
                  value={selectUser.id}
                  id={'sl2'}
                  onChange={setUser}
                  selectItem={operationUser}
                  required
                />
                <LFCButton
                  onClick={() => notRepair()}
                  disabled={selectUuid === '' || selectUser.id === ''}
                >
                  {t('リペア不要')}
                </LFCButton>
                <LFCButton
                  onClick={() => toDispose()}
                  disabled={selectUuid === '' || selectUser.id === ''}
                >
                  {t('廃棄')}
                </LFCButton>
              </LFCFormRowGroup>
            </form>
            <LFCDataGrid
              apiRef={apiRef}
              columns={uuidList_columns}
              rows={uuidListDataSort}
              height="46vh"
              exportFilename={`${t('不良処理')}_${t('選択一覧')}`}
              onCellClick={onClickImage}
              columnOrder={COLUMN_ORDER}
              style={{cursor: 'pointer'}}
            />
          </Grid>
        </Grid>
      </Box>
      <DefectiveProcedureDetailDig
        open={open}
        onClose={handleClose}
        selectData={openUuid}
        selectUser={selectUser}
        selectSerial={selectSerial}
      />
    </GenericTemplate>
  );
};

export default DefectiveProcedure;
