import dayjs from 'dayjs';
import React from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
    data?: any;
  };
  y: {
    type: string;
    name: any;
    dsColumn: any;
    series: any;
    max?: number | string;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineProductivityAnalysis = React.memo((props: Props) => {
  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            top: '40',
            left: '40',
            right: '40',
            bottom: '30',
            containLabel: true
          },
          // legend: {
          //   type: "scroll",
          //   bottom: 0,
          //   itemWidth: 10,
          // },

          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                formatter: (prm: any) => {
                  if (prm.seriesData[0] !== undefined) {
                    return dayjs(prm.seriesData[0].value.working_date).format('YYYY/MM/DD');
                  } else {
                    return true;
                  }
                }
              }
            }
          },
          dataZoom: [
            {
              type: 'inside',
              minValueSpan: 3600 * 24 * 1000 * 7 // 最小ZOOM 7日分
            }
          ],
          color: props.color ? props.color : null,
          dataset: {
            source: props.source
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                interval: 0,
                rotate: '45',
                fontSize: 10,
                formatter: (prm: any) => {
                  return (
                    ('0' + prm.split('/')[0]).slice(-2) + '/' + ('0' + prm.split('/')[1]).slice(-2)
                  );
                }
              },
              data: props.x.data
            }
          ],
          yAxis: [
            {
              type: 'value',
              // name: props.y.name,
              axisLine: {
                show: false
              },
              splitLine: {
                show: true,
                color: '#778560'
              },
              max: props.y.max ? props.y.max : null
            }
          ],
          series: [
            {
              type: props.y.type,
              name: props.y.name,
              encode: {
                x: props.x.dsColumn,
                y: props.y.dsColumn
              }
              // color: props.color[0]
            }
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        graphic={props.graphic}
      />
    </div>
  );
});
export default LFCChartsLineProductivityAnalysis;
