import {Variant} from '@mui/material/styles/createTypography';
import LFCSingleValue2 from '../_components/LFCSingleValue2';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';

export interface LFCSingleValuesColDef {
  headerName: string;
  field: string;
  width?: string;
  titleVariant?: 'inherit' | Variant;
  valVariant?: 'inherit' | Variant;
  formatter?: (value: any) => any;
  unit?: string;
  info?: string;
  fontColor: string;
}

/**
 * パラメータ
 */
interface Props {
  columns: LFCSingleValuesColDef[];
  source: any; // 1行のJSON
}

/**
 * LFCSingleValues
 */
const LFCSingleValues2 = (props: Props) => {
  if (props.source !== undefined) {
    return (
      <LFCFormRowGroup>
        {props.columns.length > 0 &&
          props.columns.map((r, index: number) => {
            return (
              <LFCSingleValue2
                title={r.headerName}
                val={props.source[r.field]}
                width={r.width}
                titleVariant={r.titleVariant}
                valVariant={r.valVariant}
                formatter={r.formatter}
                key={index}
                unit={r.unit}
                info={r.info}
                fontColor={r.fontColor}
              />

              // <FormControl style={{ marginRight: "5px", marginBottom: "5px", maxWidth: r.width }}>
              //   <Box fontSize="subtitle2.fontSize">{r.headerName}</Box>
              //   <Box overflow="scroll" style={{ whiteSpace: "nowrap" }}>
              //     {row1[r.field]}
              //   </Box>
              // </FormControl>

              // <TextField
              //   label={r.headerName}
              //   value={row1[r.field]}
              //   InputProps={{
              //     readOnly: true,
              //   }}
              //   InputLabelProps={{
              //     shrink: true,
              //   }}
              //   style={{ marginRight: "5px", marginBottom: "5px", maxWidth: r.width }}
              // />
            );
          })}
      </LFCFormRowGroup>
    );
  } else {
    return <></>;
  }
};
export default LFCSingleValues2;
