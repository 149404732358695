import {useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import ProgressBar from '../../_components/ProgressBar';
import {Grid, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import useInterval from '../../_reacts/useInterval';
import LFCButton from '../../_components/inputs/LFCButton';
import {getLFCData} from '../../_logics/LFCUtil';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    rightLine: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderTop: '100px solid transparent',
      borderBottom: '100px solid transparent',
      borderLeft: '30px solid #46a6eb',
      borderRight: 0
    }
  };
});

export type ExecutionProcessProps = {
  setRecommendedConditionsFlag: any;
  setExecutionProcessFlag: any;
  setWeldingDataFlag: any;
  searchValue: any;
};

const ExecutionProcess = (props: ExecutionProcessProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const {classes} = useStyles();
  const {t} = useTranslation();
  const [startProcess, setStartProcess] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [state, setState] = useState(2);
  const delay = 3000;

  const netxStatus = () => {
    if (state < 6) {
      setState(state + 1);
    } else {
      props.setWeldingDataFlag(true);
      props.setExecutionProcessFlag(false);
    }
  };

  const goTop = () => {
    props.setRecommendedConditionsFlag(true);
    props.setExecutionProcessFlag(false);
  };

  const checkStatus = () => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 907,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(data => {
        if (!isNaN(data[0].status)) {
          setState(data[0].status);
        }
        setStartProcess(false);
      })
      .catch(e => {
        setStartProcess(false);
      });
  };

  useEffect(() => {
    if (state === 7) {
      props.setWeldingDataFlag(true);
      props.setExecutionProcessFlag(false);
    }
    setStartProcess(false);
  }, [state]);

  useInterval(checkStatus, delay);

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <>
      <ProgressBar startProcess={startProcess} />

      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sx={{
            fontSize: 42,
            marginBottom: 3,
            padding: 1,
            backgroundColor: 'rgb(0, 102, 204)',
            color: 'white'
          }}
          justifyItems={'center'}
          textAlign={'center'}
        >
          Build in quality through automated processes using robots
        </Grid>

        <Grid
          item
          sm={3.5}
          style={
            state !== 2 && state !== 3
              ? {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative'
                }
              : {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative',
                  boxSizing: 'content-box',
                  border: '20px solid',
                  borderImage: 'linear-gradient(to bottom, orange, skyblue)',
                  borderImageSlice: 1
                }
          }
        >
          <Typography
            variant="h2"
            component="h2"
            style={{fontSize: 24, paddingTop: 20, textAlign: 'center'}}
          >
            Scanning
          </Typography>
          <div
            style={{
              backgroundImage:
                'url(' + process.env.PUBLIC_URL + 'images/demo/status2-6_scaning_R.jpg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '300px',
              width: '300px',
              backgroundColor: 'white',
              position: 'absolute',
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
              margin: 'auto'
            }}
          ></div>
        </Grid>

        <Grid
          item
          xs={0.3}
          sm={0.3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className={classes.rightLine} />
        </Grid>

        <Grid
          item
          sm={3.5}
          style={
            state !== 4 && state !== 5
              ? {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative'
                }
              : {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative',
                  boxSizing: 'content-box',
                  border: '20px solid',
                  borderImage: 'linear-gradient(to bottom, orange, skyblue)',
                  borderImageSlice: 1
                }
          }
        >
          <Typography
            variant="h2"
            component="h2"
            style={{fontSize: 24, paddingTop: 20, textAlign: 'center'}}
          >
            Sending to ROBOT
          </Typography>
          <div
            style={{
              backgroundImage:
                'url(' + process.env.PUBLIC_URL + 'images/demo/status2-6_sending2robot_R.jpg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '300px',
              width: '300px',
              backgroundColor: 'white',
              position: 'absolute',
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
              margin: 'auto'
            }}
          ></div>
        </Grid>

        <Grid
          item
          xs={0.3}
          sm={0.3}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className={classes.rightLine} />
        </Grid>

        <Grid
          item
          sm={3.5}
          style={
            state !== 6
              ? {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative'
                }
              : {
                  height: '400px',
                  width: '400px',
                  backgroundColor: 'white',
                  position: 'relative',
                  boxSizing: 'content-box',
                  border: '20px solid',
                  borderImage: 'linear-gradient(to bottom, orange, skyblue)',
                  borderImageSlice: 1
                }
          }
        >
          <Typography
            variant="h2"
            component="h2"
            style={{fontSize: 24, paddingTop: 20, textAlign: 'center'}}
          >
            Welding
          </Typography>
          <div
            style={{
              backgroundImage:
                'url(' + process.env.PUBLIC_URL + 'images/demo/status2-6_welding_R.jpg)',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: '300px',
              width: '300px',
              backgroundColor: 'white',
              position: 'absolute',
              top: 20,
              right: 0,
              bottom: 0,
              left: 0,
              margin: 'auto'
            }}
          ></div>
        </Grid>

        <Grid item sm={4} justifyContent={'center'} style={{textAlign: 'center'}}>
          {state > 2 ? (
            <div
              style={{
                borderRadius: 10,
                backgroundColor: 'red',
                width: '300px',
                fontSize: 36,
                fontWeight: 900,
                color: 'white',
                margin: 'auto'
              }}
            >
              SCAN OK
            </div>
          ) : (
            <></>
          )}
        </Grid>

        <Grid item sm={4} justifyContent={'center'} style={{textAlign: 'center'}}>
          {state > 4 ? (
            <div
              style={{
                borderRadius: 10,
                backgroundColor: 'red',
                width: '300px',
                fontSize: 36,
                fontWeight: 900,
                color: 'white',
                margin: 'auto'
              }}
            >
              SEND OK
            </div>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item sm={4} justifyContent={'center'} style={{textAlign: 'center'}}></Grid>

        <Grid
          item
          xs={12}
          justifyContent={'end'}
          sx={{width: '150px', margin: '20px 0', display: 'flex'}}
        >
          <LFCButton onClick={() => goTop()}>EXIT</LFCButton>
        </Grid>
        <Grid
          item
          xs={12}
          justifyContent={'end'}
          sx={{width: '150px', margin: '20px 0', display: 'flex'}}
        >
          <LFCButton onClick={() => netxStatus()}>NEXT</LFCButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ExecutionProcess;
