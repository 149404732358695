import CloseIcon from '@mui/icons-material/Close';
import {DialogTitle, IconButton, Theme, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';

/**
 * スタイル
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    marginRight: '0'
  }
}));

/**
 * 引数
 */
export interface DialogTitleProps {
  children: React.ReactNode;
  onClose?: () => void;
}

/**
 * LFCDialogTitle
 * MuiのDialogTitleにクローズボタンを付けた拡張コンポーネントです。
 */
const LFCDialogTitle = (props: DialogTitleProps) => {
  const classes = useStyles();
  return (
    <DialogTitle className={classes.root}>
      <Typography variant="body1">{props.children}</Typography>
      {props.onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
export default LFCDialogTitle;
