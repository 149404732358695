import dayjs from 'dayjs';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  yLeft: {
    type: string;
    name: any;
    dsColumn: any;
  };
  yRight: {
    type: string;
    name: any;
    dsColumn: any;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineYearlyResults = (props: Props) => {
  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: [
            {
              text: ' \nPPH目標\n達成結果',
              bottom: 50,
              top: 230,
              left: 10,
              textStyle: {
                lineHeight: 15,
                fontSize: 10,
                fontWeight: 'normal',
                formatter: function (value: any) {
                  return '{table|' + value + '}';
                },
                rich: {
                  table: {
                    align: 'center'
                  }
                }
              }
            }
          ],
          grid: props.grid
            ? props.grid
            : {
                top: '60',
                left: '60',
                right: '60'
              },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                formatter: (prm: any) => {
                  return dayjs(prm.value).format('YYYY/MM/DD');
                }
              }
            }
          },
          dataZoom: [
            {
              type: 'inside',
              minValueSpan: 3600 * 24 * 1000 * 7 // 最小ZOOM 7日分
            }
          ],
          color: props.color ? props.color : null,
          dataset: {
            source: props.source
          },
          xAxis: [
            {
              type: 'time',
              axisLabel: {
                formatter: function (value: any, index: number) {
                  let day_tmp = new Date(value);
                  let year_tmp = day_tmp.getFullYear();
                  let month_tmp = ('0' + (day_tmp.getMonth() + 1)).slice(-2);
                  let this_year_month_source = props.source.filter(
                    (item: any) => item.id === year_tmp + '/' + month_tmp
                  );

                  let pph_judge: string = '';
                  if (this_year_month_source !== undefined) {
                    pph_judge = '';
                  }
                  if (
                    this_year_month_source[0] !== undefined &&
                    this_year_month_source[0].pph_judge === null
                  ) {
                    pph_judge = '';
                  }
                  if (
                    this_year_month_source[0] !== undefined &&
                    this_year_month_source[0].pph_judge !== null
                  ) {
                    pph_judge = this_year_month_source[0].pph_judge;
                  }

                  return '{table|' + year_tmp + '/' + month_tmp + '}\n{table|' + pph_judge + '}';
                },
                rich: {
                  table: {
                    top: 50,
                    lineHeight: 20,
                    align: 'center'
                  }
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: props.yLeft.name[0]
            },
            {
              type: 'value',
              name: props.yRight.name[0]
            }
          ],
          series: [
            {
              type: props.yLeft.type,
              name: props.yLeft.name[0],
              xAxisIndex: 0,
              yAxisIndex: 0,
              encode: {
                x: props.x.dsColumn,
                y: props.yLeft.dsColumn[0]
              }
            },
            {
              type: props.yLeft.type,
              name: props.yLeft.name[1],
              xAxisIndex: 0,
              yAxisIndex: 0,
              encode: {
                x: props.x.dsColumn,
                y: props.yLeft.dsColumn[1]
              }
            },
            {
              type: props.yRight.type,
              name: props.yRight.name[0],
              xAxisIndex: 0,
              yAxisIndex: 1,
              encode: {
                x: props.x.dsColumn,
                y: props.yRight.dsColumn[0]
              }
            },
            {
              type: props.yRight.type,
              name: props.yRight.name[1],
              xAxisIndex: 0,
              yAxisIndex: 1,
              encode: {
                x: props.x.dsColumn,
                y: props.yRight.dsColumn[1]
              }
            }
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
      />
    </div>
  );
};
export default LFCChartsLineYearlyResults;
