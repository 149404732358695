import {Box, ImageListItem, ImageListItemBar, Tooltip} from '@mui/material';
import {useEffect, useState} from 'react';
import {FixedSizeGrid as Grid2} from 'react-window';
import ImageDialog from './ImageDialog';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';

interface ImgData {
  img_filename: string;
  judge: number;
  serial: string;
  lq_time: string;
  work: string;
  map: string;
}
export interface ShowOption {
  colPicCount: number;
  isShowDatail: boolean;
}

interface LFCImageListProps {
  iDatas: ImgData[];
  imageRootPath: string;
  showOptions: ShowOption;
}

const Cell = (
  cellProps: {columnIndex: number; rowIndex: number; style: React.CSSProperties},
  iDatas: ImgData[],
  showOptions: ShowOption,
  imageRootPath: string,
  colW: number,
  rowH: number,
  setPopTitle: (title: string) => void,
  setPopImgPath: (path: string) => void,
  setPopOpen: (open: boolean) => void
) => {
  const index = cellProps.rowIndex * showOptions.colPicCount + cellProps.columnIndex;
  if (index >= iDatas.length) return null;

  const item = iDatas[index];

  return (
    <div style={cellProps.style}>
      <ImageListItem key={`imgl_${index}`}>
        <Tooltip
          arrow
          placement="bottom"
          title={
            <span>
              Time : {fmtYYYYMMDDHHmmss(item.lq_time)}
              <br />
              Serial : {item.serial}
              <br />
              Work : {item.work}
              <br />
              Map : {item.map}
            </span>
          }
        >
          <Box
            width={colW}
            height={rowH}
            sx={{
              border: '8px solid',
              borderColor: item.judge === 0 ? 'green' : 'red'
            }}
            onClick={() => {
              setPopTitle(item.img_filename);
              setPopImgPath(`${imageRootPath}${item.img_filename}`);
              setPopOpen(true);
            }}
          >
            <img
              src={`${imageRootPath}${item.img_filename}`}
              alt={'not found'}
              loading="lazy"
              style={{
                objectFit: 'fill',
                width: '100%',
                height: '100%'
              }}
            />
            {showOptions.isShowDatail && (
              <ImageListItemBar title={fmtYYYYMMDDHHmmss(item.lq_time)} subtitle={item.serial} />
            )}
          </Box>
        </Tooltip>
      </ImageListItem>
    </div>
  );
};

/**
 * ImageGrid
 * @returns
 */
const ImageGrid = (prop: LFCImageListProps) => {
  const SPACE_W = 25;
  const SPACE_H = 250;

  const [frameSize, setFrameSize] = useState({
    frameW: window.innerWidth - SPACE_W,
    frameH: window.innerHeight - SPACE_H
  });

  // フレームサイズを計算する関数
  const calculateFrameSize = () => {
    setFrameSize({
      frameW: window.innerWidth - SPACE_W,
      frameH: window.innerHeight - SPACE_H
    });
  };

  const colW = Math.floor((frameSize.frameW - 15) / prop.showOptions.colPicCount);
  const rowH = Math.floor((2 / 3) * colW); // 3:2の比率になるようにする

  // ウィンドウのリサイズイベントを監視
  useEffect(() => {
    window.addEventListener('resize', calculateFrameSize);

    return () => window.removeEventListener('resize', calculateFrameSize);
  }, []);

  // ImageDialog用の状態管理
  const [popOpen, setPopOpen] = useState(false);
  const [popTitle, setPopTitle] = useState<string>('');
  const [popImgPath, setPopImgPath] = useState<string>('');
  const handleClose = () => {
    setPopOpen(false);
  };

  return (
    <>
      <Grid2
        columnCount={prop.showOptions.colPicCount}
        columnWidth={colW}
        rowCount={Math.ceil(prop.iDatas.length / prop.showOptions.colPicCount)}
        rowHeight={rowH}
        width={frameSize.frameW}
        height={frameSize.frameH}
      >
        {cellProps =>
          Cell(
            cellProps,
            prop.iDatas,
            prop.showOptions,
            prop.imageRootPath,
            colW,
            rowH,
            setPopTitle,
            setPopImgPath,
            setPopOpen
          )
        }
      </Grid2>
      <ImageDialog
        open={popOpen}
        onClose={handleClose}
        title={popTitle}
        imgPath={popImgPath}
      ></ImageDialog>
    </>
  );
};

export default ImageGrid;
