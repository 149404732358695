import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {red} from '@mui/material/colors';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  xlist: any;
  searchType?: number;
  cameraName: string;
  frameNo: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxPerson1 = (props: Props) => {
  const {t} = useTranslation();
  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  let show_data_value: any = props.source.map((a: any) => a.data_value);
  const [show_data_value_avg, setShow_data_value_avg] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    makeBoxData();
    let tmp: any = [];
    show_data_value.forEach((a: any) => {
      if (!isEmpty(a)) {
        tmp.push(average(a.map((b: string) => Number(b))));
      } else {
        tmp.push(null);
      }
    });
    setShow_data_value_avg(tmp);
  }, [props]);

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      if (!isEmpty(data)) {
        let max = Math.max(...data.map((a: any) => a));
        let min = Math.min(...data.map((a: any) => a));

        const dataQ1 = () => {
          let data_s: any = data.sort((a: number, b: number) => a - b);
          let pos = (data_s.length - 1) * 0.25;
          let base = Math.floor(pos);
          let rest = pos - base;
          if (data_s[base + 1] !== undefined) {
            return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
          } else {
            return data_s[base];
          }
        };

        const dataQ2 = () => {
          let data_s: any = data.sort((a: number, b: number) => a - b);
          let pos = (data_s.length - 1) * 0.5;
          let base = Math.floor(pos);
          let rest = pos - base;
          if (data_s[base + 1] !== undefined) {
            return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
          } else {
            return data_s[base];
          }
        };

        const dataQ3 = () => {
          let data_s: any = data.sort((a: number, b: number) => a - b);
          let pos = (data_s.length - 1) * 0.75;
          let base = Math.floor(pos);
          let rest = pos - base;
          if (data_s[base + 1] !== undefined) {
            return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
          } else {
            return data_s[base];
          }
        };
        // [min,  Q1,  median (or Q2),  Q3,  max]
        return [min, dataQ1(), dataQ2(), dataQ3(), max];
      }
    };

    let tmp: any = [];
    props.xlist.forEach((a: string) => {
      if (props.source.find((k: any) => k.x_list === a)) {
        props.source.find((b: any, index: number) => {
          if (b.x_list === a) {
            tmp.push(props.source[index].data_value.map((e: string) => Number(e)));
          }
        });
      } else {
        tmp.push([]);
      }
    });

    let tmpOutPut: any = [];
    tmp.forEach((a: any) => {
      if (!isEmpty(a)) {
        tmpOutPut.push(CalcQuartile(a));
      } else {
        tmpOutPut.push([]);
      }
    });
    setBoxData(tmpOutPut);
  };

  const makeExportData = () => {
    let tmp: any = [];
    if (!isEmpty(boxData) && !isEmpty(props.source)) {
      // 出力内容：　カメラ名、枠名、日時(yyyy/m)、最大、Q3、中央値、平均、Q1、最小
      props.xlist.forEach((a: string, index: number) => {
        if (props.source[index]['start_time'] !== undefined) {
          tmp.push({
            カメラ名: props.cameraName,
            枠名: props.frameNo,
            作業開始時間: props.source[index]['start_time'],
            最大: boxData[index][4] !== undefined ? boxData[index][4] : '',
            Q3: boxData[index][3] !== undefined ? boxData[index][3] : '',
            中央値: boxData[index][2] !== undefined ? boxData[index][2] : '',
            平均: show_data_value_avg[index] !== null ? show_data_value_avg[index] : '',
            Q1: boxData[index][1] !== undefined ? boxData[index][1] : '',
            最小: boxData[index][0] !== undefined ? boxData[index][0] : ''
          });
        } else {
          tmp.push({
            カメラ名: props.cameraName,
            枠名: props.frameNo,
            日時: a,
            最大: boxData[index][4] !== undefined ? boxData[index][4] : '',
            Q3: boxData[index][3] !== undefined ? boxData[index][3] : '',
            中央値: boxData[index][2] !== undefined ? boxData[index][2] : '',
            平均: show_data_value_avg[index] !== null ? show_data_value_avg[index] : '',
            Q1: boxData[index][1] !== undefined ? boxData[index][1] : '',
            最小: boxData[index][0] !== undefined ? boxData[index][0] : ''
          });
        }
      });
    }
    setExportData(tmp);
  };

  useEffect(() => {
    makeExportData();
  }, [boxData]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        dataset: [
          {
            source: show_data_value
          }
        ],
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            if (params.seriesType === 'boxplot' && props.searchType !== 0) {
              // [min,  Q1,  median (or Q2),  Q3,  max]
              return (
                params.name +
                '<br/>' +
                t('最大') +
                ':' +
                Number(params.value[5]).toFixed(2) +
                '<br/>' +
                'Q3：' +
                Number(params.value[4]).toFixed(2) +
                '<br/>' +
                t('中央') +
                ' (or Q2)：' +
                Number(params.value[3]).toFixed(2) +
                '<br/>' +
                'Q1：' +
                Number(params.value[2]).toFixed(2) +
                '<br/>' +
                t('最小') +
                ':' +
                Number(params.value[1]).toFixed(2)
              );
            } else if (params.seriesType === 'boxplot' && props.searchType === 0) {
              return (
                t('日時') +
                '：(' +
                t('作業開始時間') +
                ':' +
                props.source[params.dataIndex]['start_time'] +
                ')' +
                '<br/>' +
                t('X軸') +
                ':' +
                params.name +
                '<br/>' +
                t('値') +
                ':' +
                params.value[1]
              );
            } else if (params.seriesType === 'scatter') {
              return (
                params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
              );
            } else if (params.seriesType === 'line') {
              return params.name + '<br/>' + t('平均') + ':' + Number(params.value).toFixed(2);
            }
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        grid: {
          left: props.grid !== undefined ? props.grid.left : 30,
          right: props.grid !== undefined ? props.grid.right : 30,
          bottom: props.grid !== undefined ? props.grid.bottom : 30,
          top: props.grid !== undefined ? props.grid.top : 30
        },
        xAxis: {
          type: 'category',
          name: props.xAxisName !== undefined ? props.xAxisName : '',
          data: props.xlist !== undefined ? props.xlist : [],
          nameTextStyle: {
            fontSize: 10
          },
          nameLocation: 'end',
          // boundaryGap: true,
          // nameGap: 10,
          splitLine: {
            show: false
          },
          axisLabel: {
            // interval: 0,
            rotate: '20',
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value',
          name: props.yAxisName !== undefined ? props.yAxisName : '',
          nameTextStyle: {
            fontSize: 10
          },
          axisLabel: {
            interval: 0,
            fontSize: 10
          }
        },
        series: [
          {
            name: 'boxplot',
            type: 'boxplot',
            data: boxData
          },
          {
            name: t('平均'),
            type: 'line',
            datasetIndex: 3,
            data: props.searchType !== 0 ? show_data_value_avg : undefined,
            color: red[500]
          }
        ]
      }}
      // exportData={props.exportData}
      exportData={exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename + props.cameraName + props.frameNo}
      height={props.height ? props.height : '100px'}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            params.mapName = props.mapName;
            params.refJobTitle = props.refJobTitle;
            params.parameterName = props.parameterName;
            props.onClick(params);
          }
        }
      }}
    />
  );
};
export default LFCChartsBoxPerson1;
