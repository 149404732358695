import {Box, TextField} from '@mui/material';
import {useRef, useState} from 'react';

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  value: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
}

/**
 * LFCDatetimePicker
 */
const LFCDatetimePicker = (props: Props) => {
  const myRef = useRef<HTMLInputElement>(null!);
  const [err, setErr] = useState(false);

  /**
   * Text入力時
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErr(!event?.target.checkValidity());
    if (props.onChange) {
      props.onChange(event);
    }
  };
  /**
   * フォーカスアウト時
   * @param event
   */
  const handleFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    setErr(!event?.target?.checkValidity());

    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <Box style={{...props.style}}>
      <TextField
        type="datetime-local"
        name={props.name}
        label={props.label}
        value={props.value}
        inputRef={myRef}
        error={err}
        helperText={err && myRef.current?.validationMessage}
        onChange={handleInputChange}
        onBlur={handleFocusOut}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        size={props.size !== undefined ? props.size : 'small'}
        fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
        disabled={props.disabled !== undefined ? props.disabled : false}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : false}
        required={props.required !== undefined ? props.required : false}
        margin={props.margin}
        //TODO: ↓checkValidityしてればいらないかも。マルチロケーションの時にフォーマットが動的になるので問題になりそう
        inputProps={{
          pattern:
            '^[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01]) ^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$'
        }}
      />
    </Box>
  );
};
export default LFCDatetimePicker;
