import {TextField} from '@mui/material';

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: any;
  other?: any;
  multiline?: boolean;
  rows?: number;
  fullWidth?: boolean;
  id: string;
}

/**
 * LFCTextField
 */
const LFCFileField: React.VFC<Props> = props => {
  return (
    <div style={props.style}>
      <TextField
        id={props.id}
        type={'file'}
        name={props.name}
        label={props.label}
        onChange={props.onChange}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        size="small"
        fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
        {...props.other}
      />
    </div>
  );
};
export default LFCFileField;
