import 'dayjs/locale/ja';
import './index.css';

import enJson from '_locales/en.json';
import jaJson from '_locales/ja.json';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import * as echarts from 'echarts/core';
import langJA from 'echarts/lib/i18n/langJA';
import i18n from 'i18next';
// import reportWebVitals from './reportWebVitals';
import {SnackbarProvider, useSnackbar} from 'notistack';
import {SWSuccessDialog} from 'pages/_sw/SWSuccessDialog';
import {SWUpdateDialog} from 'pages/_sw/SWUpdateDialog';
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import {initReactI18next} from 'react-i18next';

import CancelIcon from '@mui/icons-material/Cancel';
import {IconButton} from '@mui/material';
import {LicenseInfo} from '@mui/x-data-grid-pro';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

//x-data-grid-pro
LicenseInfo.setLicenseKey(
  'ef83cd96b48474f3232650f3050147a9T1JERVI6Mzg0ODYsRVhQSVJZPTE2NzcyOTg2NzMwMDAsS0VZVkVSU0lPTj0x'
); //ラインセンス登録

//echarts
echarts.registerLocale('JA', langJA); //日本語辞書の登録

//dayjs
dayjs.extend(localizedFormat); //ローカライズされた形式を利用可能にする

//初期langはブラウザのlanguagesを取得
const blrLang = (
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language ||
  'en'
).slice(0, 2);

//i18n 初期設定
i18n.use(initReactI18next).init({
  resources: {
    ja: {
      translation: jaJson
    },
    en: {
      translation: enJson
    }
  },
  lng: blrLang, //初期表示する言語
  // fallbackLng: "en", //選択した言語に関する辞書情報がない場合に、かわりに表示する言語
  // returnNull: false,
  // returnEmptyString: false,
  interpolation: {escapeValue: false}
  // react: {
  //   transKeepBasicHtmlNodesFor: ["wbr"],
  // },
});

const DismissAction = ({id}: any) => {
  const {closeSnackbar} = useSnackbar();
  return (
    <IconButton color="inherit" size="small" onClick={() => closeSnackbar(id)}>
      <CancelIcon fontSize="inherit" />
    </IconButton>
  );
};

ReactDOM.render(
  <SnackbarProvider dense hideIconVariant maxSnack={10} action={key => <DismissAction id={key} />}>
    <App />
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration: ServiceWorkerRegistration) => {
    ReactDOM.render(
      <SWUpdateDialog registration={registration} />,
      document.querySelector('.sw-dialog')
    );
  },
  onSuccess: (registration: ServiceWorkerRegistration) => {
    ReactDOM.render(<SWSuccessDialog />, document.querySelector('.sw-dialog'));
  }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
