import React from 'react';
import * as echarts from 'echarts';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

interface Props {
  datas: any;
  deviceIndex?: any;
  onEvents?: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  startDay?: string;
}

const LFCChartsGanttChartOnsiteMonitor: React.VFC<Props> = (props: Props) => {
  // let today = new Date();
  let start_day = new Date(String(props.startDay));
  let tmp_daty = new Date(String(props.startDay));
  let end_day = new Date(tmp_daty.setDate(tmp_daty.getDate() + 1));

  // let today_string = today.toLocaleDateString().replaceAll('/', '-') + ' ' + today.toLocaleTimeString().padStart(2, '0');
  let start_ =
    start_day.toLocaleDateString().replaceAll('/', '-') +
    ' ' +
    start_day.toLocaleTimeString().padStart(2, '0');
  let end_ =
    end_day.toLocaleDateString().replaceAll('/', '-') +
    ' ' +
    end_day.toLocaleTimeString().padStart(2, '0');

  const seriesData: any = [];

  //machine_status
  let status_key = ['Working', 'Waiting', 'Caution'];

  props.datas.forEach((item: any, index: number) => {
    let bgColor;
    switch (
      status_key.findIndex((machine_status: string) => machine_status === item.machine_status)
    ) {
      case 0: //'Working'
        bgColor = 'rgb(0,187,255)';
        break;
      case 1: //'Waiting'
        bgColor = 'rgb(255,211,115)';
        break;
      case 2: //'Caution'
        bgColor = 'rgb(243,6,6)';
        break;
    }

    let startTime = new Date(item.detection_time_from).getTime();
    let endTime = new Date(item.detection_time_to).getTime();
    seriesData.push({
      name: item.machine_id,
      value: [
        props.deviceIndex.findIndex((device_name: string) => device_name === item.device),
        startTime,
        endTime,
        item.machine_status
      ],
      itemStyle: {
        color: bgColor
      }
    });
  });

  const option = {
    tooltip: {
      formatter: function (params: any) {
        let date1 = new Date(params.value[1]);
        let start_timestamp =
          [date1.getFullYear(), date1.getMonth() + 1, date1.getDate()].join('/') +
          ' ' +
          [date1.getHours(), date1.getMinutes(), date1.getSeconds()].join(':');
        let date2 = new Date(params.value[2]);
        let end_timestamp =
          [date2.getFullYear(), date2.getMonth() + 1, date2.getDate()].join('/') +
          ' ' +
          [date2.getHours(), date2.getMinutes(), date2.getSeconds()].join(':');
        let timeshow =
          '<div><span>Start Time : ' +
          start_timestamp +
          '</span><br/><span>End Time : ' +
          end_timestamp +
          '</span></div>';
        return params.marker + params.name + ':' + params.value[3] + timeshow;
      }
    },
    grid: {
      top: 60,
      left: 100,
      right: 20,
      bottom: 0,
      height: 100
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        filterMode: 'none',
        realtime: false,
        height: 10,
        top: 160,
        startValue: new Date(start_).getTime() + 1000 * 3600 * 9,
        endValue: new Date(end_).getTime() - 1000 * 3600 * 12,
        minValueSpan: 10,
        handleIcon: 'path://path://M100, 100m -75, 0a75,75 0 1,0 150,0a75,75 0 1,0 -150,0',
        handleSize: '120%',
        handleStyle: {
          color: '#fff',
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowBlur: 4
        },
        textStyle: {
          color: 'transparent'
        },
        dataBackground: {
          lineStyle: {
            width: 1
          },
          areaStyle: {
            color: 'transparent'
          }
        },
        fillerColor: '#b8eef3',
        labelFormatter: ''
      },
      {
        type: 'inside',
        show: true,
        zoomOnMouseWheel: true,
        moveOnMouseWheel: true,
        moveOnMouseMove: true,
        preventDefaultMouseMove: true
      }
    ],
    xAxis: {
      type: 'time',
      min: new Date(start_day).getTime(),
      max: new Date(end_).getTime(),
      scale: true,
      position: 'top',
      splitNumber: 7,
      axisLabel: {
        show: true,
        interval: 0,
        margin: 15,
        fontSize: 14,
        formatter: function (value: any, index: number) {
          let date = new Date(value);
          return [
            ('0' + date.getHours()).slice(-2),
            ('0' + date.getMinutes()).slice(-2)
            // ('0' + date.getSeconds()).slice(-2)
          ].join(':');
        }
      },
      axisLine: {show: false},
      splitLine: {
        show: true
        // lineStyle: {color: 'rgba(233,233,233,0.1)'}
      }
      // axisTick: {
      //   lineStyle: {
      //     color: '#fff'
      //   }
      // }
    },
    yAxis: {
      axisLine: {
        onZero: false,
        show: false
      },
      axisLabel: {
        show: true,
        fontSize: 14
      },
      splitLine: {
        show: true,
        lineStyle: {color: 'rgba(233,233,233,0.1)'}
      },
      inverse: true,
      data: props.deviceIndex
    },
    series: [
      {
        type: 'custom',
        renderItem: function (params: any, api: any) {
          let categoryIndex = api.value(0);
          let start = api.coord([api.value(1), categoryIndex]);
          let end = api.coord([api.value(2), categoryIndex]);
          let rectShape = echarts.graphic.clipRectByRect(
            {
              x: start[0],
              // y: start[1],
              y: start[1] - 46,
              width: end[0] - start[0],
              // height: api.size([0, 1])[1] * 0.7
              height: 90
            },
            {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height
            }
          );

          return (
            rectShape && {
              type: 'rect',
              shape: rectShape,
              style: api.style()
            }
          );
        },
        encode: {
          x: [1, 2],
          y: 0
        },
        data: seriesData
      }
    ]
  };
  return (
    <>
      <LFCBaseCharts
        option={option}
        onEvents={props.onEvents}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
      />
    </>
  );
};

export default LFCChartsGanttChartOnsiteMonitor;
