import {Autocomplete, FormControl, TextField} from '@mui/material';
import {isArray, isEmpty} from 'lodash';
import React, {useEffect} from 'react';

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  value?: any;
  onChange: any;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple: boolean;
  selectItem: any;
  id: string;
  onReset: any;
  doneReset: any;
}

/**
 * LFCtAutocomplete
 */
const LFCAutocomplete = (props: Props) => {
  const [valueMultiple, setValueMultiple] = React.useState<any>([]);
  const [valueSingle, setValueSingle] = React.useState<string | null>('');
  useEffect(() => {
    if (props.onReset) {
      props.doneReset(false);
      if (isEmpty(props.value)) {
        setValueMultiple([]);
        setValueSingle('');
      } else {
        if (isArray(props.value)) {
          setValueMultiple(props.value);
        } else {
          setValueSingle(props.value);
        }
      }
    }
  }, [props.onReset]);

  useEffect(() => {
    if (!isEmpty(props.value)) {
      if (props.multiple !== undefined && props.multiple) {
        if (isArray(props.value)) {
          setValueMultiple(props.value);
          props.onChange(props.value);
        } else {
          setValueMultiple([props.value]);
          props.onChange([props.value]);
        }
      } else {
        setValueSingle(props.value);
        props.onChange(props.value);
      }
    }
  }, []);

  return (
    <FormControl
      variant="outlined"
      style={{minWidth: 200, ...props.style}}
      size={props.size !== undefined ? props.size : 'small'}
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
      disabled={props.disabled !== undefined ? props.disabled : false}
      required={props.required !== undefined ? props.required : false}
      margin={props.margin}
    >
      <Autocomplete
        disabled={props.disabled !== undefined ? props.disabled : false}
        value={props.multiple ? valueMultiple : valueSingle}
        onChange={(event: any, newValue: any) => {
          if (props.multiple) {
            props.onChange({name: props.name, data: newValue.map((a: any) => a.label)});
            setValueMultiple(newValue);
          } else {
            if (newValue !== null) {
              props.onChange({name: props.name, data: newValue.label});
              setValueSingle(newValue);
            } else {
              props.onChange('');
              setValueSingle(null);
            }
          }
        }}
        multiple={props.multiple === undefined ? false : props.multiple}
        id={'combo-box-lfc-auto-' + props.id}
        options={props.selectItem}
        sx={props.style !== undefined ? {...props.style, maxHeight: 500} : {minWidth: 250}}
        size={props.size !== undefined ? props.size : 'small'}
        renderInput={params => {
          return (
            <TextField
              {...params}
              label={props.label}
              style={{wordBreak: 'break-all'}}
              InputLabelProps={{
                shrink: true
              }}
            />
          );
        }}
      />
    </FormControl>
  );
};
export default LFCAutocomplete;

export const multiSelectData = (listData: any, selectList: any) => {
  if (selectList !== undefined && listData.length > 0 && selectList.length > 0) {
    let tmp: any = [];
    if (isArray(selectList)) {
      selectList.forEach((a: string) => {
        if (listData[listData.findIndex((b: any) => b.label === a)] !== undefined) {
          tmp.push(listData[listData.findIndex((b: any) => b.label === a)]);
        }
      });
      return tmp;
    } else {
      if (listData[listData.findIndex((b: any) => b.label === selectList)] !== undefined) {
        tmp.push(listData[listData.findIndex((b: any) => b.label === selectList)]);
      }
      return tmp[0];
    }
  }
};
