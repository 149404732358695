import {SelectChangeEvent} from '@mui/material';

import {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_WELDING_SHOW_MAIN, PAGEID_WELDING_SHOW} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {extractAndFormatWeldingWorks} from 'utils/weldingDemo';
import {ArcStatus, RobotPaths, SearchValue, WorkGaps} from '../types';
import {ALL} from '_logics/LFCConst';

export const useWeldingShow = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState<boolean>(false);
  const defaultCondition = getDefaultCondition(PAGEID_WELDING_SHOW, LOCATIONID_WELDING_SHOW_MAIN);
  const initialSearchValue = {
    select_datetime_from: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
    select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
    work: [],
    deviceid: [],
    auto_reload: false,
    auto_reload_interval: 10,
    show_latest_only: false
  };
  const [searchValue, setSearchValue] = useState<SearchValue>(
    defaultCondition ?? initialSearchValue
  );
  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState<boolean>(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const [dataArcStatus, setDataArcStatus] = useState<ArcStatus[][]>([]);
  const [dataWorkGaps, setDataWorkGaps] = useState<WorkGaps>({
    lq_time: '',
    gaps: [],
    path_addresses: []
  });
  const [dataRobotPaths, setDataRobotPaths] = useState<RobotPaths[]>([
    {
      lq_time: '',
      nodes: [],
      path_addresses: []
    }
  ]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid.join(','),
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'auto_reload',
        value: searchValue.auto_reload,
        valueLabel: searchValue.auto_reload ? 'する' : 'しない',
        colName: t('自動更新'),
        colWidth: 200
      },
      {
        name: 'auto_reload_interval',
        value: searchValue.auto_reload_interval,
        valueLabel: searchValue.auto_reload ? `${searchValue.auto_reload_interval}秒` : '',
        colName: t('自動更新の間隔'),
        colWidth: 200
      },
      {
        name: 'show_latest_only',
        value: searchValue.show_latest_only,
        valueLabel: searchValue.show_latest_only ? '最新のみ表示' : '',
        colName: t('最新のみ'),
        colWidth: 200
      }
    ]);

    setOpenSavedCondition(true);
  };
  const resetSearch = () => {
    setSearchValue({
      select_datetime_from: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
      select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
      work: [],
      deviceid: [],
      auto_reload: false,
      auto_reload_interval: 10,
      show_latest_only: false
    });
    setAutoCompleteReset(true);
  };
  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  const apiFetch = () => {
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 306,
        parameters: {
          date01: searchValue.select_datetime_from,
          date02: searchValue.select_datetime_to
        },
        name: 'WeldingShow/AllData',
        cancelToken: source.token,
        t
      })
        .then(datas => {
          if (datas.length > 0) {
            const works = extractAndFormatWeldingWorks(datas, searchValue.show_latest_only);
            setDataArcStatus(works);
          } else {
            setDataArcStatus([]);
          }
        })
        .catch(e => e),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 304,
        parameters: {
          select_datetime_from: searchValue.select_datetime_from,
          select_datetime_to: searchValue.select_datetime_to
        },
        name: 'WeldingShow/WorkGaps',
        cancelToken: source.token,
        t
      }).then(datas => {
        if (searchValue.show_latest_only) {
          setDataWorkGaps(datas.slice(-1));
        } else {
          setDataWorkGaps(datas);
        }
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 305,
        parameters: {
          select_datetime_from: searchValue.select_datetime_from,
          select_datetime_to: searchValue.select_datetime_to
        },
        name: 'WeldingShow/Nodes',
        cancelToken: source.token,
        t
      }).then(datas => {
        if (datas.length === 0) {
          return;
        } else if (searchValue.show_latest_only) {
          setDataRobotPaths(datas.slice(-1));
        } else {
          setDataRobotPaths(datas);
        }
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const onChangeShowLatestOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, show_latest_only: event.target.checked});
  };
  const onChangeAutoReload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, auto_reload: event.target.checked});
  };
  const onSelectAutoReloadInterval = (event: SelectChangeEvent<any>) => {
    setSearchValue({
      ...searchValue,
      auto_reload_interval: parseInt(event.target.value as string, 10)
    });
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  /**
   * 自動更新処理
   */
  useInterval(doSearch, searchValue.auto_reload ? searchValue.auto_reload_interval * 1000 : null);
  return {
    t,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    work,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    deviceid,
    doSearch,
    resetSearch,
    openSavedCondition,
    setOpenSavedCondition,
    condition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    onChangeShowLatestOnly,
    onChangeAutoReload,
    onSelectAutoReloadInterval,
    dataArcStatus,
    dataWorkGaps,
    dataRobotPaths
  };
};
