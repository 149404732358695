import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import GenericTemplate from '_templates/GenericTemplate';

import MasterEditPanel from '_components/master-edit-panel/MasterEditPanel';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

const MapEntryPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [listUpdate, setListUpdate] = useState(String(new Date().getTime()));
  const [editButtonStatus, setEditButtonStatus] = useState(false);
  const [map_image_master, setMap_image_master] = useState([]);

  let fields_map_image_master = [
    {
      columnField: 'id',
      columnName: 'id',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: false
    },
    {
      columnField: 'mapname',
      columnName: 'mapname',
      columnOrder: 1,
      hide: false,
      edit: false,
      add: false,
      dateType: false
    },
    {
      columnField: 'filename',
      columnName: 'filename',
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true,
      dateType: false,
      fileType: true
    }
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    (async () => {
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 70001,
        parameters: {},
        ds_state: setMap_image_master,
        name: `${t('Mapマスタ画像')}`,
        cancelToken: source.token,
        t
      });
    })();
  }, [listUpdate]);

  const thisSaveObject = async (id_key: string, editInput: any) => {
    setStartProcess(true);
    if (id_key.indexOf('m_map_image_master_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70002,
        parameters: {
          id: editInput.id,
          map: editInput.fileData.tmp_filename.split('.')[0],
          filename: editInput.fileData.tmp_filename,
          filesize: editInput.fileData.filesize
        },
        name: `${t('map画像更新')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const newSaveObject = async (master_name: string, input_data: any) => {
    setStartProcess(true);
    if (master_name === 'm_map_image_master') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70003,
        parameters: {
          map: input_data.fileData.tmp_filename.split('.')[0],
          filename: input_data.fileData.tmp_filename,
          filesize: input_data.fileData.filesize
        },
        name: `${t('map画像新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const deleteObject = async (id_key: string) => {
    setStartProcess(true);
    if (id_key.indexOf('m_map_image_master') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70004,
        parameters: {id: Number(id_key.replace('m_map_image_master_', ''))},
        name: `${t('map画像削除')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  return (
    <GenericTemplate title={`L-ROBOT:${t('menu.page_name.MAP登録')}`}>
      <ProgressBar startProcess={startProcess} />
      <MasterEditPanel
        ComponentTitle={t('マップマスター画像')}
        // CategoryNo={1}
        DataObject={map_image_master}
        DataKeyName={'m_map_image_master'}
        FieldObject={fields_map_image_master}
        ExportFileName={'map_image_master'}
        SaveObject={thisSaveObject}
        AddNewObject={newSaveObject}
        DeleteObject={deleteObject}
      />
    </GenericTemplate>
  );
};

export default MapEntryPage;
