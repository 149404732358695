import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {useMySettings} from '_contexts/MySettingsProvider';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {distinct, getLFCData} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import LFCChartsBarCenterRangeGap2 from '_components/charts/LFCChartsBarCenterRangeGap2';
import {isArrayLikeObject, isEmpty} from 'lodash';
import LFCChartsLineHoleCompareWithToleranceType from '_components/charts/LFCChartsLineHoleCompareWithToleranceType';
import LFCChartsBoxWithLineSphere from '_components/charts/LFCChartsBoxWithLineSphere';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import ProgressBar from '_components/ProgressBar';
import LFCChartsLineMulti3 from '../../_components/charts/LFCChartsLineMulti3';
import LFCChartsLineMultiBySearch3 from '../../_components/charts/LFCChartsLineMultiBySearch3';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    typeTitle: {
      marginLeft: '-16px',
      fontWeight: 'bold',
      fontSize: '16px'
    },
    mrgLeft: {
      marginLeft: '8px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  open: boolean;
  searchValue: any;
  typeKey: any;
  graphData0: any;
  startProcess: boolean;
  setStartProcess: Dispatch<boolean>;
}

const PartsSphereComponent = (props: Props) => {
  const {startProcess, setStartProcess} = props;
  const {t} = useTranslation();
  const exportData3_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('移動範囲（前結果差）'),
    serial: t('シリアルナンバー')
  };

  const exportData3_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('標準偏差'),
    d1: t('範囲（最大最小差）'),
    d2: 'Cp',
    d3: 'Cpk'
  };

  const export_detail_all_list: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100,
      hide: false
    },
    {
      order: 1,
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: true,
      width: 140
    },
    {
      order: 2,
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: true
    },
    {
      order: 3,
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 180,
      sortable: true
    },
    {
      order: 10,
      field: 'master_x',
      headerName: t('マスタX座標'),
      description: t('マスタX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 11,
      field: 'master_y',
      headerName: t('マスタY座標'),
      description: t('マスタY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 12,
      field: 'master_z',
      headerName: t('マスタZ座標'),
      description: t('マスタZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 13,
      field: 'work_x',
      headerName: t('ワークX座標'),
      description: t('ワークX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 14,
      field: 'work_y',
      headerName: t('ワークY座標'),
      description: t('ワークY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 15,
      field: 'work_z',
      headerName: t('ワークZ座標'),
      description: t('ワークZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 16,
      field: 'x_gap',
      headerName: t('X座標誤差'),
      description: t('X座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 17,
      field: 'y_gap',
      headerName: t('Y座標誤差'),
      description: t('Y座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 18,
      field: 'z_gap',
      headerName: t('Z座標誤差'),
      description: t('Z座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 30,
      field: 'center_range_limit',
      headerName: t('球体半径誤差閾値'),
      description: t('球体半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 31,
      field: 'center_range_gap',
      headerName: t('球体半径誤差'),
      description: t('球体半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 50,
      field: 'x_lower_limit',
      headerName: t('X座標誤差下限閾値'),
      description: t('X座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 51,
      field: 'x_upper_limit',
      headerName: t('X座標誤差上限閾値'),
      description: t('X座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 52,
      field: 'y_lower_limit',
      headerName: t('Y座標誤差下限閾値'),
      description: t('Y座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 53,
      field: 'y_upper_limit',
      headerName: t('Y座標誤差上限閾値'),
      description: t('Y座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 54,
      field: 'z_lower_limit',
      headerName: t('Z座標誤差下限閾値'),
      description: t('Z座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 55,
      field: 'z_upper_limit',
      headerName: t('Z座標誤差上限閾値'),
      description: t('Z座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 60,
      field: 'cylinder_radius_gap',
      headerName: t('円筒半径誤差'),
      description: t('円筒半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 61,
      field: 'cylinder_radius_limit',
      headerName: t('円筒半径誤差閾値'),
      description: t('円筒半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 62,
      field: 'height_gap',
      headerName: t('円筒高さ誤差'),
      description: t('円筒高さ誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 63,
      field: 'height_upper_limit',
      headerName: t('円筒高さ誤差上限閾値'),
      description: t('円筒高さ誤差上限閾値'),
      width: 180,
      sortable: true
    },
    {
      order: 64,
      field: 'height_lower_limit',
      headerName: t('円筒高さ誤差下限閾値'),
      description: t('円筒高さ誤差下限閾値'),
      width: 180,
      sortable: true
    }
  ];
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const graphData0_column_type0: {[key: string]: string} = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    center_range_gap: t('半径誤差'),
    serial: t('シリアルナンバー')
  };

  const data209_column: {[key: string]: string} = {
    center_range_gap: t('計測値'),
    カウント数: t('カウント数')
  };

  //平均値の取得
  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  //標準偏差の取得
  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  //中央値の取得
  const Quartile = (data: any, q: number) => {
    let data_s: any = data.sort((a: number, b: number) => a - b);
    let pos = (data_s.length - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if (data_s[base + 1] !== undefined) {
      return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
    } else {
      return data_s[base];
    }
  };

  // 表示数字を桁区切り
  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  // const [typeKey, setTypeKey] = useState<any>([]);
  const data0MaxAvgMin_init: any = {
    d1: 0, //目標
    d2: '', //目標info
    d3: 0, //上限
    d4: '', //上限info
    d5: 0, //下限
    d6: '', //下限info
    d7: 0, //最大
    d8: 0, //平均
    d9: 0, //中央
    d10: 0, //最小
    d11: 0, //標準偏差
    d12: 0, //管理限界線1 平均+3σ
    d13: 0, //管理限界線2 平均-3σ
    d14: 0, //工程能力1
    d15: '', //工程能力1info
    d16: 0, //工程能力2
    d17: '', //工程能力2info
    d18: 0, //仕様限界LSL
    d19: 0 //仕様限界USL
  };

  const graphData0_column: {[key: string]: string} = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  //スフィアの計算データ
  const [data0MaxAvgMin, setData0MaxAvgMin] = useState(data0MaxAvgMin_init);

  // スフィアのグラフデータ
  // const [graphData0, setGraphData0] = useState([]);

  //スフィア
  const [data28003, setData28003] = useState([]);
  const [data280013, setData280013] = useState([]);
  const [data28003_show, setData28003_show] = useState([]);
  const [data28003_r_show, setData28003_r_show] = useState([]);
  const [data208XYZ, setData208XYZ] = useState<any>([]);
  const [data209, setData209] = useState([]);
  const [graphData0SelectDate, setGraphData0SelectDate] = useState('');
  const [xLineMin, setXLineMin] = useState(0);
  const [xLineMax, setXLineMax] = useState(0);
  const [xRenge, setXRenge] = useState(0);

  //日付リストの生成
  const date_calc: any = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(props.searchValue.select_date_from);
    let to_day = new Date(props.searchValue.select_date_to);

    switch (props.searchValue.searchType) {
      case 0:
        return return_type0_byid();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  }, [startProcess]);

  const return_type0_byid = () => {
    if (props.typeKey[0] === undefined) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 28100,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge
        },
        name: 'id-28100',
        cancelToken: source.token,
        t
      }).then((data: any) => {
        switch (data[0]['tolerance_type']) {
          case 0:
          case null:
            return Array.from(new Set(props.graphData0.map((a: any) => a.lq_time))).sort();
          case 1:
        }
        return [];
      });
    } else {
      switch (props.typeKey[0]['tolerance_type']) {
        case 0:
        case null:
          return Array.from(new Set(props.graphData0.map((a: any) => a.lq_time))).sort();
      }
      return [];
    }
  };

  //推移グラフ
  //スフィア
  const [graphBaseData0, setGraphBaseData0] = useState<any>({});

  let tmp_data: any = [];
  let tmp: any = [];

  const checkZero = (a: string | null) => {
    if (a !== null) {
      return Number(a);
    } else {
      return null;
    }
  };

  // ---------- スフィア：tolerance_type = 0 or tolerance_type = null ----------
  // スフィア：ばらつき推移のデータを取得
  useEffect(() => {
    setStartProcess(true);
    if (props.typeKey.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 280009,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: !isEmpty(props.searchValue.map)
                ? '{' + props.searchValue.map.join(',') + '}'
                : '{' +
                  props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                  '}'
            },
            ds_state: setData28003,
            name: 'id-280009',
            cancelToken: source.token,
            t
          });
          break;
        case 1: // 時間ごと
          if (props.graphData0.map((a: any) => a.year_month_day_hour).length > 0) {
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280011,
              parameters: {
                year_month_day_hour:
                  '{' +
                  Array.from(new Set(props.graphData0.map((a: any) => a.year_month_day_hour))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 0)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setData28003,
              name: 'id-280011',
              cancelToken: source.token,
              t
            }).then(() => {
              // 範囲Rを全権取得のため
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 280024,
                parameters: {
                  select_date_from: props.searchValue.select_date_from,
                  select_date_to: props.searchValue.select_date_to,
                  work: !isEmpty(props.searchValue.work)
                    ? '{' + props.searchValue.work.join(',') + '}'
                    : null,
                  deviceid: !isEmpty(props.searchValue.deviceid)
                    ? '{' + props.searchValue.deviceid.join(',') + '}'
                    : null,
                  judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                  map: !isEmpty(props.searchValue.map)
                    ? '{' + props.searchValue.map.join(',') + '}'
                    : '{' +
                      props.typeKey
                        .filter((a: any) => a.tolerance_type === 0)[0]
                        .map_array.join(',') +
                      '}'
                },
                ds_state: setData280013,
                name: 'id-280024',
                cancelToken: source.token,
                t
              });
            });
          }
          break;
        case 2: // 日ごと
          if (props.graphData0.map((a: any) => a.year_month_day).length > 0) {
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280010,
              parameters: {
                year_month_day:
                  '{' +
                  Array.from(new Set(props.graphData0.map((a: any) => a.year_month_day))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 0)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setData28003,
              name: 'id-280010',
              cancelToken: source.token,
              t
            }).then(() => {
              // 範囲Rを全権取得のため
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 280023,
                parameters: {
                  select_date_from: props.searchValue.select_date_from,
                  select_date_to: props.searchValue.select_date_to,
                  work: !isEmpty(props.searchValue.work)
                    ? '{' + props.searchValue.work.join(',') + '}'
                    : null,
                  deviceid: !isEmpty(props.searchValue.deviceid)
                    ? '{' + props.searchValue.deviceid.join(',') + '}'
                    : null,
                  judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                  map: !isEmpty(props.searchValue.map)
                    ? '{' + props.searchValue.map.join(',') + '}'
                    : '{' +
                      props.typeKey
                        .filter((a: any) => a.tolerance_type === 0)[0]
                        .map_array.join(',') +
                      '}'
                },
                ds_state: setData280013,
                name: 'id-280023',
                cancelToken: source.token,
                t
              });
            });
          }
          break;
        case 3: // 月ごと
          if (props.graphData0.map((a: any) => a.year_month).length > 0) {
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280012,
              parameters: {
                year_month:
                  '{' +
                  Array.from(new Set(props.graphData0.map((a: any) => a.year_month))).join(',') +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 0)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setData28003,
              name: 'id-280012',
              cancelToken: source.token,
              t
            }).then(() => {
              // 範囲Rを全権取得のため
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 280025,
                parameters: {
                  select_date_from: props.searchValue.select_date_from,
                  select_date_to: props.searchValue.select_date_to,
                  work: !isEmpty(props.searchValue.work)
                    ? '{' + props.searchValue.work.join(',') + '}'
                    : null,
                  deviceid: !isEmpty(props.searchValue.deviceid)
                    ? '{' + props.searchValue.deviceid.join(',') + '}'
                    : null,
                  judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                  map: !isEmpty(props.searchValue.map)
                    ? '{' + props.searchValue.map.join(',') + '}'
                    : '{' +
                      props.typeKey
                        .filter((a: any) => a.tolerance_type === 0)[0]
                        .map_array.join(',') +
                      '}'
                },
                ds_state: setData280013,
                name: 'id-280025',
                cancelToken: source.token,
                t
              });
            });
          }
          break;
      }

      //　スフィアのエクストラ：データ計算
      if (props.graphData0.length > 0) {
        let center_range_limit: any = distinct(props.graphData0, 'center_range_limit').map(
          (item: any) => Number(item)
        );

        let d8: number = Number(
          average(props.graphData0.map((item: any) => item.center_range_gap))
        );

        let d9: number = Quartile(
          props.graphData0.map((a: any) => Number(a.center_range_gap)),
          0.5
        );

        let d16: number =
          Math.abs(
            (center_range_limit.length > 1 ? average(center_range_limit) : center_range_limit[0]) -
              d8
          ) /
          (3 * stdev(props.graphData0.map((item: any) => Number(item.center_range_gap))));

        setData0MaxAvgMin({
          d3: center_range_limit.length > 1 ? average(center_range_limit) : center_range_limit[0], //上限
          d4: center_range_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: '-', //下限
          d6: '', //下限info
          d7: Math.max(...props.graphData0.map((item: any) => item.center_range_gap)), //最大
          d8: d8, //平均
          d9: d9, //中央
          d10: Math.min(...props.graphData0.map((item: any) => item.center_range_gap)), //最小
          d11: stdev(props.graphData0.map((item: any) => Number(item.center_range_gap))), //標準偏差
          d12: d8 + 3 * stdev(props.graphData0.map((item: any) => Number(item.center_range_gap))), //管理限界線1 平均+3σ
          d13: '-', //管理限界線2 平均-3σ
          d14: '-', //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        });
      } else {
        setData0MaxAvgMin(data0MaxAvgMin_init);
      }
    }
  }, [props.graphData0]);

  // １集計単位ごとに複数のマスター値、リミット値がある場合はnullとする。
  const checkNotSingleMasterAndLimit = (
    targetName: string,
    checkType: string,
    checkData: string
  ) => {
    return (
      Array.from(
        new Set(
          props.graphData0
            .filter((a: any) => a[checkType] === checkData)
            .map((b: any) => b[targetName])
        )
      ).length === 1
    );
  };

  // スフィア：ばらつき推移グラフの範囲Rと他のデータをまとめる
  useEffect(() => {
    let tmp_list: any = [];
    let tmp_list_r: any = [];
    setData28003_show([]);
    setData28003_r_show([]);

    if (data28003.length > 0 && isArrayLikeObject(date_calc())) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setData28003_show(data28003);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              data28003.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('center_range_limit', 'year_month_day_hour', a)
            ) {
              tmp_list.push(data28003.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
            // 範囲Rを全権取得のため
            if (
              data280013.find((d: any) => d.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('center_range_limit', 'year_month_day_hour', a)
            ) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              data28003.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('center_range_limit', 'year_month_day', a)
            ) {
              tmp_list.push(data28003.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (
              data280013.find((d: any) => d.year_month_day === a) &&
              checkNotSingleMasterAndLimit('center_range_limit', 'year_month_day', a)
            ) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              data28003.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('center_range_limit', 'year_month', a)
            ) {
              tmp_list.push(data28003.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data280013.find((d: any) => d.year_month === a)) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
      }
    }
  }, [data28003, data280013]);

  // スフィア：ばらつき推移のグラフ用データ生成
  useEffect(() => {
    //　１集計単位ごとに10件のデータがない場合はnullとする。
    let show_limit: number = 10;

    if (props.searchValue.searchType !== 0) {
      setData208XYZ([
        {
          data:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            data28003_r_show.filter((a: any) => a.範囲r).length > 0
              ? data28003_r_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: '範囲R',
          map:
            data28003_r_show.filter((a: any) => a.範囲r).length > 0
              ? data28003_r_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            data28003_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? data28003_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setData208XYZ([
        {
          data:
            data28003_show.filter((a: any) => a.移動範囲rs).length > 0
              ? data28003_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          map: data28003_show.map((a: any) => a.map),
          lq_time: data28003_show.map((a: any) => a.lq_time),
          name: t('移動範囲Rs')
        }
      ]);
    }
    setStartProcess(false);
  }, [data28003_show, data28003_r_show]);

  const [exportData3, setExportData3] = useState([]);
  useEffect(() => {
    setExportData3(makeExportData3(graphBaseData0.x_list, data208XYZ));
  }, [data208XYZ]);

  // スフィアの分布グラフリミット計算
  useEffect(() => {
    let tmp_n: number = props.graphData0.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }
    // 「最大値」「上限(仕様)」「管理限界線(+3σ)」
    let tmp_max: number = Math.max(data0MaxAvgMin.d7, data0MaxAvgMin.d3, data0MaxAvgMin.d12);
    setXLineMax(tmp_max);
    // 最小値」「下限(仕様)」「管理限界線(-3σ)」
    let tmp_min: number = Math.min(data0MaxAvgMin.d10);
    setXLineMin(tmp_min);

    let tmp_h: number = (tmp_max - tmp_min) / tmp_k;
    let under_zero: number = Math.floor(1 / tmp_h);
    let zero: string = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }
    let tmp_dot: number = String(tmp_h).indexOf('.');
    let tmp_round: number;
    if (tmp_dot > 0) {
      tmp_round = Math.floor(tmp_h * (tmp_dot * Number(zero))) / (tmp_dot * Number(zero));
    } else {
      tmp_round = Math.floor(tmp_h);
    }
    setXRenge(tmp_round);

    // スフィア：誤差　推移のグラフのデータを作成
    if (props.graphData0.length > 0 && isArrayLikeObject(date_calc())) {
      date_calc().forEach((lq_time_string: string) => {
        switch (props.searchValue.searchType) {
          case 0: //検査ごと
            break;
          case 1: //時間ごと
            tmp = [];
            props.graphData0.forEach((item: any) => {
              if (String(item.year_month_day_hour) === lq_time_string) {
                tmp.push(Number(item['center_range_gap']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 2: //日ごと
            tmp = [];
            props.graphData0.forEach((item: any) => {
              if (String(item.year_month_day) === lq_time_string) {
                tmp.push(Number(item['center_range_gap']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 3: //月ごと
            tmp = [];
            props.graphData0.forEach((item: any) => {
              if (String(item.year_month) === lq_time_string) {
                tmp.push(Number(item['center_range_gap']));
              }
            });
            tmp_data.push(tmp);
            break;
        }
      });

      if (props.searchValue.searchType !== 0) {
        setGraphBaseData0({
          id: tmp_data.map((a: any, index: number) => index + 1),
          map: props.graphData0.map((a: any) => a.map),
          data_value: tmp_data,
          data_avg: tmp_data.map((a: any) => (!isEmpty(a) ? average(a) : '')),
          data_middle: tmp_data.map((a: any) => data0MaxAvgMin.d8),
          data_avg_cl: tmp_data.map((a: any) => data0MaxAvgMin.d9),
          data_middle_mel: tmp_data.map((a: any) => (!isEmpty(a) ? Quartile(a, 0.5) : '')),
          data_lcl: tmp_data.map((a: any) => data0MaxAvgMin.d13),
          data_ucl: tmp_data.map((a: any) => data0MaxAvgMin.d12),
          data_target: tmp_data.map((a: any) => data0MaxAvgMin.d1),
          data_lsl: tmp_data.map((a: any) => data0MaxAvgMin.d3),
          data_usl: tmp_data.map((a: any) => Quartile(a, 0.5)),
          x_list: date_calc(),
          upper_limit: tmp_data.map((a: any) => data0MaxAvgMin.d3),
          lower_limit: ''
        });
      } else {
        setGraphBaseData0({
          id: props.graphData0.map((a: any, index: number) => index + 1),
          serial: props.graphData0.map((a: any) => a.serial),
          map: props.graphData0.map((a: any) => a.map),
          lq_time: props.graphData0.map((a: any) => a.lq_time),
          data_value: props.graphData0.map((a: any) => a.center_range_gap),
          data_avg: tmp_data.map((a: any) => average(a)),
          data_middle: tmp_data.map((a: any) => data0MaxAvgMin.d8),
          data_avg_cl: tmp_data.map((a: any) => data0MaxAvgMin.d9),
          data_middle_mel: tmp_data.map((a: any) => data0MaxAvgMin.d8),
          data_lcl: tmp_data.map((a: any) => data0MaxAvgMin.d13),
          data_ucl: tmp_data.map((a: any) => data0MaxAvgMin.d12),
          data_target: tmp_data.map((a: any) => data0MaxAvgMin.d1),
          data_lsl: tmp_data.map((a: any) => data0MaxAvgMin.d3),
          data_usl: tmp_data.map((a: any) => Quartile(a, 0.5)),
          x_list: date_calc(),
          upper_limit: props.graphData0[0]['radius_upper_limit'],
          lower_limit: props.graphData0[0]['radius_lower_limit']
        });
      }
    }
  }, [data0MaxAvgMin]);

  const getRengeData0 = () => {
    setData209([]);
    if (xRenge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29002,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          renge: xRenge
        },
        ds_state: setData209,
        name: 'id-29002',
        cancelToken: source.token,
        t
      });
    }
  };

  // スフィアの分布グラフデータ取得
  useEffect(() => {
    getRengeData0();
  }, [xRenge]);

  //スフィア：グラフクリック
  const getDataWithDate0 = (datas: string) => {
    setGraphData0SelectDate(datas);
  };
  const getDataByData0 = (datas: string) => {
    setGraphData0SelectDate(datas);
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  const zoomChartsCheck = (idString: string) => {
    return zoomChartOpen === idString;
  };

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  // ---------分布グラフ
  // サーフェス：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData0SelectDate === '') {
      getRengeData0();
      return;
    }

    // 検査ごと：検索
    if (props.searchValue.searchType === 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29031,
        parameters: {
          select_serial: graphData0SelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          renge: xRenge
        },
        ds_state: setData209,
        name: 'id-29031',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29032,
        parameters: {
          select_lq_date_hour: graphData0SelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          renge: xRenge
        },
        ds_state: setData209,
        name: 'id-2131',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29033,
        parameters: {
          select_date: graphData0SelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          renge: xRenge
        },
        ds_state: setData209,
        name: 'id-29033',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29034,
        parameters: {
          select_date_month: graphData0SelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          renge: xRenge
        },
        ds_state: setData209,
        name: 'id-29034',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData0SelectDate]);

  // 穴分布のグラフクリックして、クリックした位置の一覧表を出力
  const [g2clickData, setG2clickData] = useState([]);

  const g2click = (datas: any) => {
    setStartProcess(true);
    let center_range_gap_min: number | null = null;
    let center_range_gap_max: number | null = null;

    if (datas.name !== undefined) {
      center_range_gap_min = datas.name.split('_')[0];
      center_range_gap_max = datas.name.split('_')[1];
    }

    if (graphData0SelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29030,
          parameters: {
            select_serial: graphData0SelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            center_range_gap_min: center_range_gap_min,
            center_range_gap_max: center_range_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29030',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 時間ごと：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29029,
          parameters: {
            select_lq_date_hour: graphData0SelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            center_range_gap_min: center_range_gap_min,
            center_range_gap_max: center_range_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29029',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 日ごと：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29028,
          parameters: {
            select_date: graphData0SelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            center_range_gap_min: center_range_gap_min,
            center_range_gap_max: center_range_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29028',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 月ごと：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29035,
          parameters: {
            select_date_month: graphData0SelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            center_range_gap_min: center_range_gap_min,
            center_range_gap_max: center_range_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29035',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29036,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          center_range_gap_min: center_range_gap_min,
          center_range_gap_max: center_range_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29036',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  };

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  //ばらつき推移グラフのダウンロードデータ生成
  const makeExportData3 = (x_list: any, baseData: any) => {
    let tmp: any = [];
    if (x_list !== undefined && baseData.length > 0) {
      if (props.searchValue.searchType !== 0) {
        x_list.forEach((a: string, index: number) => {
          tmp.push({
            date: a,
            map: String(Array.from(new Set(props.graphData0.map((c: any) => c.map)))),
            work: String(Array.from(new Set(props.graphData0.map((c: any) => c.work)))),
            deviceid: String(Array.from(new Set(props.graphData0.map((c: any) => c.deviceid)))),
            標準偏差S: baseData[0].data !== null ? baseData[0].data[index] : '',
            範囲R:
              baseData[1].data !== null
                ? !isNaN(baseData[1].data[index])
                  ? baseData[1].data[index]
                  : ''
                : '',
            工程能力Cp:
              baseData[2].data !== null && !isNaN(baseData[2].data[index])
                ? baseData[2].data[index]
                : '',
            工程能力Cpk: baseData[3].data !== null ? baseData[3].data[index] : ''
          });
        });
      } else {
        props.graphData0.forEach((a: any, index: number) => {
          tmp.push({
            work: a.work,
            deviceid: a.deviceid,
            map: a.map,
            for_export: a.lq_time,
            lq_time: a.lq_time,
            移動範囲Rs: baseData[0].data !== null ? baseData[0].data[index] : null,
            serial: a.serial
          });
        });
      }
    }
    return tmp;
  };

  useEffect(() => {
    if (data209.length > 0) {
      setStartProcess(false);
    }
  }, [data209]);

  const [listHeaders, setListHeaders] = useState(export_detail_all_list);
  useEffect(() => {
    if (g2clickData.length > 0) {
      let tmp_listHeaders: any = Object.keys(g2clickData[0]);
      g2clickData.forEach((a: any) => {
        for (let k in a) {
          if (a[k] === '' || a[k] === null) {
            delete tmp_listHeaders[k];
          }
        }
      });
      setListHeaders(
        export_detail_all_list.filter((a: any) => tmp_listHeaders.indexOf(a.field) !== -1)
      );
      setDetailListOpen(true);
    }
  }, [g2clickData]);

  //　スフィアのグラフ・リスト出力
  return (
    <>
      <Grid container spacing={2} key={'graphSetType0'}>
        <ProgressBar startProcess={startProcess} />
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLineSphere
              title={`${t('球体半径誤差')} ${t('推移')}`}
              source={graphBaseData0}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={props.graphData0}
              exportFields={graphData0_column}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('スフィア')}`}
              height={'33vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={searchValue.map}
              onClick={getDataWithDate0}
              zoomChartId={'zoom-chart-1'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-1')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareWithToleranceType
              title={`${t('球体半径誤差')} ${t('推移')}`}
              source={graphBaseData0.data_value !== undefined ? graphBaseData0 : undefined}
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値X'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={props.graphData0}
              exportFields={graphData0_column_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('スフィア')}`}
              // mapName={searchValue.map}
              onClick={getDataByData0}
              zoomChartId={'zoom-chart-1'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-1')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {graphData0SelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData0SelectDate
                : `${t('選択シリアル')}:` + graphData0SelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData0SelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <></>
          )}
          <LFCChartsBarCenterRangeGap2
            title={`${t('球体半径誤差')} ${t('分布')}`}
            source={data209}
            extraDatas={data0MaxAvgMin}
            exportData={data209}
            exportFields={data209_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('スフィア')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2click}
            zoomChartId={'zoom-chart-2'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-2')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-2')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMin={xLineMin}
            xLineMax={xLineMax}
            xRenge={xRenge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-3'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti3
                title={`${t('球体半径誤差')} ${t('ばらつき推移')}`}
                source={data208XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : data28003.map((a: any) => a.idx)
                }
                color={['rgb(106,113,255)']}
                exportData={exportData3}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('スフィア')}`}
                zoomChartId={'zoom-chart-3'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-3')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch3
                title={`${t('球体半径誤差')} ${t('ばらつき推移')}`}
                source={data208XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : data28003.map((a: any) => a.idx)
                }
                color={['rgb(106,113,255)']}
                exportData={exportData3}
                exportFields={exportData3_column_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('スフィア')}`}
                zoomChartId={'zoom-chart-3'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-3')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data0MaxAvgMin.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data0MaxAvgMin.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d3)} mm</TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d7)} mm</TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d12)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    --
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data0MaxAvgMin.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d8)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data0MaxAvgMin.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d9)} mm</TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data0MaxAvgMin.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">--</TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">{toLocalStringEx(data0MaxAvgMin.d10)} mm</TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">--</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/*分布グラフクリックした際の一覧表*/}
      <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Hole Compare 穴位置')}:${t(
          '詳細データ'
        )}`}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={listHeaders}
            rows={g2clickData}
            height="80vh"
            exportFilename={`${t('menu.page_name.Hole Compare 穴位置')}_${t('詳細データ')}_${t(
              'スフィア'
            )}`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PartsSphereComponent;
