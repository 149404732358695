import {Box, Grid} from '@mui/material';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';

/**
 * 引数
 */
interface Props {
  cardTitle: string;
  title: any;
  value: any;
  imageName: string;
}

const YieldCard = (props: Props) => {
  let titles: any = props.title;

  return (
    <Grid
      item
      xs={3}
      md={3}
      style={{
        padding: '5px',
        backgroundImage: 'url(' + process.env.PUBLIC_URL + 'images/yield/' + props.imageName + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '50px'
      }}
    >
      <h2 style={{textAlign: 'center'}}>{props.cardTitle}</h2>
      {titles.map((title: string, index: number) => {
        return (
          <Box m={2}>
            <LFCSingleValue
              title={title}
              val={String(props.value[0][title])}
              key={title + '_' + index}
              titleVariant={'h5'}
              valVariant={'h3'}
            />
          </Box>
        );
      })}
    </Grid>
  );
};
export default YieldCard;
