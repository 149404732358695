import {AppBar, Tabs} from '@mui/material';
import CustomTab from './WeldingDemoCustomTab';
import {Dispatch} from 'react';

type Props = {
  value: number;
  setValue: Dispatch<number>;
};

const WeldingDemoTabs = (props: Props) => {
  const {value, setValue} = props;
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    };
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
  };
  return (
    <AppBar position="static" sx={{border: 'none'}}>
      <Tabs value={value} onChange={handleChange}>
        <CustomTab
          label="Workpiece Displacement"
          {...a11yProps(0)}
          onClick={() => {}}
          sx={{border: 'none'}}
        />
        <CustomTab label="Welding Path" {...a11yProps(1)} onClick={() => {}} />
        <CustomTab label="Welding Conditions" {...a11yProps(2)} onClick={() => {}} />
        <CustomTab label="Quality Analisys" {...a11yProps(3)} onClick={() => {}} />
      </Tabs>
    </AppBar>
  );
};
export default WeldingDemoTabs;
