import React, {useEffect, useState} from 'react';
import GenericTemplate from '_templates/GenericTemplate';
import {Box, Tab, Tabs} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import {getLFCData, setLFCData} from '_logics/LFCUtil';

import MasterEditPanel from '_components/master-edit-panel/MasterEditPanel';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
      flexGrow: 1,
      display: 'flex'
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: '200px'
      // width:'200px'
    },
    table: {
      minWidth: 650
    },
    mgt20_padin10: {
      marginTop: 20,
      padding: 10
    },
    title: {
      fontWeight: 800,
      marginRight: 20
    },
    mgr5: {
      display: 'inline-flex',
      marginRight: 5
    }
  };
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const MasterMaintenance = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(true);
  const [tabidx, setTabidx] = React.useState(0);
  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabidx(newValue);
  };

  const [listUpdate, setListUpdate] = useState(String(new Date().getTime()));
  const [m_user, setM_user] = useState([]);
  const [m_rfid, setM_rfid] = useState([]);
  const [m_defect_type, setM_defect_type] = useState([]);
  const [m_defect_causes_type, setM_defect_causes_type] = useState([]);
  const [editButtonStatus, setEditButtonStatus] = useState(false);

  let fields_m_user = [
    {
      columnField: 'user_id',
      columnName: t('ユーザーID'),
      columnOrder: 0,
      hide: false,
      edit: false,
      add: false
    },
    {
      columnField: 'user_name',
      columnName: t('ユーザー名'),
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true,
      other: {
        required: true
      }
    },
    {
      columnField: 'user_disp_name',
      columnName: t('ユーザー表示名'),
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true,
      other: {
        required: true
      }
    }
  ];

  let fields_m_rfid = [
    {
      columnField: 'rfid',
      columnName: 'RFID',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: true,
      other: {
        required: true
      }
    },
    {
      columnField: 'rfid_name',
      columnName: t('RFID名称'),
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true,
      other: {
        required: true
      }
    },
    {
      columnField: 'user_id',
      columnName: t('ユーザーID'),
      columnOrder: 2,
      hide: false,
      edit: false,
      add: true,
      other: {
        required: true,
        inputProps: {maxLength: 50, pattern: '[0-9]*'}
      }
    },
    {
      columnField: 'user_disp_name',
      columnName: t('ユーザー表示名'),
      columnOrder: 3,
      hide: false,
      edit: false,
      add: false,
      other: {
        required: true
      }
    }
  ];

  let fields_m_defect_type = [
    {
      columnField: 'defect_type',
      columnName: 'defect_type',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: true,
      other: {
        required: true,
        inputProps: {maxLength: 20, pattern: '[0-9]*'}
      }
    },
    {
      columnField: 'defect_type_name',
      columnName: 'defect_type_name',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true,
      other: {
        required: true,
        inputProps: {maxLength: 20}
      }
    }
  ];

  let fields_m_defect_causes_type = [
    {
      columnField: 'defect_cause_type',
      columnName: 'defect_cause_type',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: true,
      other: {
        required: true,
        inputProps: {maxLength: 20, pattern: '[0-9]*'}
      }
    },
    {
      columnField: 'defect_cause_type_name',
      columnName: 'defect_cause_type_name',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true,
      other: {
        required: true,
        inputProps: {maxLength: 20}
      }
    }
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50039,
        parameters: {},
        ds_state: setM_user,
        name: `POC_${t('マスタ管理')}_${t('ユーザマスタ')}_${t('一覧')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50043,
        parameters: {},
        ds_state: setM_rfid,
        name: `POC_${t('マスタ管理')}_${t('RFIDマスタ')}_${t('一覧')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50047,
        parameters: {},
        ds_state: setM_defect_type,
        name: `POC_${t('マスタ管理')}_${t('不良内容マスタ')}_${t('一覧')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50051,
        parameters: {},
        ds_state: setM_defect_causes_type,
        name: `POC_${t('マスタ管理')}_${t('発生形態マスタ')}_${t('一覧')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  }, [listUpdate]);

  const thisSaveObject = async (id_key: string, editInput: any) => {
    if (id_key.indexOf('m_user') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50040,
        parameters: {
          p_user_id: editInput.user_id,
          user_name: editInput.user_name,
          user_disp_name: editInput.user_disp_name
        },
        name: `POC_${t('マスタ管理')}_${t('ユーザマスタ')}_${t('更新')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_rfid') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50044,
        parameters: {
          rfid: editInput.rfid,
          rfid_name: editInput.rfid_name,
          p_user_id: editInput.user_id
        },
        name: `POC_${t('マスタ管理')}_${t('RFIDマスタ')}_${t('更新')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_defect_type') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50048,
        parameters: {
          defect_type: editInput.defect_type,
          defect_type_name: editInput.defect_type_name
        },
        name: `POC_${t('マスタ管理')}_${t('不良内容マスタ')}_${t('更新')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_defect_causes_type') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50052,
        parameters: {
          defect_cause_type: editInput.defect_cause_type,
          defect_cause_type_name: editInput.defect_cause_type_name
        },
        name: `POC_${t('マスタ管理')}_${t('発生形態マスタ')}_${t('更新')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const newSaveObject = async (master_name: string, input_data: any) => {
    if (master_name === 'm_user') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50042,
        parameters: {
          user_name: input_data.user_name,
          user_disp_name: input_data.user_disp_name
        },
        name: `POC_${t('マスタ管理')}_${t('ユーザマスタ')}_${t('追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_rfid') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50046,
        parameters: {
          rfid: input_data.rfid,
          rfid_name: input_data.rfid_name,
          p_user_id: input_data.user_id
        },
        name: `POC_${t('マスタ管理')}_${t('RFIDマスタ')}_${t('追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_defect_type') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50050,
        parameters: {
          defect_type: input_data.defect_type,
          defect_type_name: input_data.defect_type_name
        },
        name: `POC_${t('マスタ管理')}_${t('不良内容マスタ')}_${t('追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_defect_causes_type') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50054,
        parameters: {
          defect_cause_type: input_data.defect_cause_type,
          defect_cause_type_name: input_data.defect_cause_type_name
        },
        name: `POC_${t('マスタ管理')}_${t('発生形態マスタ')}_${t('追加')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const deleteObject = async (id_key: string) => {
    if (id_key.indexOf('m_user') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50041,
        parameters: {
          p_user_id: m_user[Number(id_key.replace('m_user_', ''))]['user_id']
        },
        name: `POC_${t('マスタ管理')}_${t('ユーザマスタ')}_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_rfid') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50045,
        parameters: {rfid: m_rfid[Number(id_key.replace('m_rfid_', ''))]['rfid']},
        name: `POC_${t('マスタ管理')}_${t('RFIDマスタ')}_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_defect_type') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50049,
        parameters: {
          defect_type: m_defect_type[Number(id_key.replace('m_defect_type_', ''))]['defect_type']
        },
        name: `POC_${t('マスタ管理')}_${t('不良内容マスタ')}_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_defect_causes_type') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50053,
        parameters: {
          defect_cause_type:
            m_defect_causes_type[Number(id_key.replace('m_defect_causes_type_', ''))][
              'defect_cause_type'
            ]
        },
        name: `POC_${t('マスタ管理')}_${t('発生形態マスタ')}_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  return (
    <GenericTemplate title={t('menu.page_name.マスタ管理')}>
      <ProgressBar startProcess={startProcess} />
      <Box className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabidx}
          onChange={tabChange}
          className={classes.tabs}
        >
          <Tab label="M_USER" {...a11yProps(0)} />
          <Tab label="M_RFID" {...a11yProps(1)} />
          <Tab label="M_DEFECT_TYPE" {...a11yProps(2)} />
          <Tab label="M_DEFECT_CAUSES_TYPE" {...a11yProps(3)} />
        </Tabs>
        <TabPanel value={tabidx} index={0}>
          <MasterEditPanel
            ComponentTitle={'m_user'}
            // CategoryNo={1}
            DataObject={m_user}
            DataKeyName={'m_user'}
            FieldObject={fields_m_user}
            ExportFileName={'m_user'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={1}>
          <MasterEditPanel
            ComponentTitle={'m_rfid'}
            // CategoryNo={1}
            DataObject={m_rfid}
            DataKeyName={'m_rfid'}
            FieldObject={fields_m_rfid}
            ExportFileName={'m_rfid'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={2}>
          <MasterEditPanel
            ComponentTitle={'m_defect_type'}
            // CategoryNo={1}
            DataObject={m_defect_type}
            DataKeyName={'m_defect_type'}
            FieldObject={fields_m_defect_type}
            ExportFileName={'m_defect_type'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={3}>
          <MasterEditPanel
            ComponentTitle={'m_defect_causes_type'}
            // CategoryNo={1}
            DataObject={m_defect_causes_type}
            DataKeyName={'m_defect_causes_type'}
            FieldObject={fields_m_defect_causes_type}
            ExportFileName={'m_defect_causes_type'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
      </Box>
    </GenericTemplate>
  );
};

export default MasterMaintenance;
