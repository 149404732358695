import {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarLrobot = (props: Props) => {
  const renderFlgRef = useRef(false);

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // データソースクリア
    if (props.source.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});

    if (props.series) {
      // シリーズ指定あり
      let strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.source.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.series, value: grp}
          }
        });
        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          encode: {x: props.x.dsColumn, y: props.y.dsColumn},
          datasetIndex: i + 1,
          label: {
            show: true,
            formatter: (param: any) => {
              return param.value.diff;
            }
          }
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        encode: {x: props.x.dsColumn, y: props.y.dsColumn}
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '40',
              left: '60',
              right: '10'
            },
        ...(props.series
          ? {
              legend: {type: 'scroll', bottom: 0, itemWidth: 10}
            }
          : {
              legend: {
                show: false
              }
            }),
        tooltip: {
          //横軸グラフなので目盛りは出さない
          trigger: 'item',
          axisPointer: {
            type: 'shadow'
          },
          formatter: (prm: any) => {
            return (
              '' +
              prm.value.job_type +
              ' / ' +
              prm.value.job_type_name +
              '<br/>sec [' +
              prm.value.diff +
              ']<br/>'
            );
          }
        },
        dataZoom: [
          {
            type: 'inside',
            ...(props.x.type === 'time'
              ? {
                  minValueSpan: 3600 * 24 * 1000 * 7 // 最小ZOOM 7日分
                }
              : null)
          }
        ],
        color: props.color ? props.color : null,
        dataset: dataset,
        xAxis: [
          {
            type: props.x.type,
            axisLabel: {
              rotate: '45',
              rich: {
                boldStyle: {
                  fontWeight: 'bold'
                }
              },
              ...(props.x.type === 'time'
                ? {
                    formatter: {
                      year: '{boldStyle|{yyyy}}',
                      month: '{boldStyle|{MMM}}',
                      day: '{d}',
                      hour: '{d}',
                      none: '{d}'
                    },
                    minInterval: 3600 * 24 * 1000 * 1
                  }
                : null)
            }
          }
        ],
        yAxis: [
          {
            type: props.y.type
          }
        ],
        series: series
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
};
export default LFCChartsBarLrobot;
