import {Box, Divider, Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBoxPerson1 from '_components/charts/LFCChartsBoxPerson1';
import LFCChartsComboXList from '_components/charts/LFCChartsComboXList';
import LFCChartsLinePerson02 from '_components/charts/LFCChartsLinePerson02';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {
  LOCATIONID_PERSONS_MAIN,
  PAGEID_PERSONS_DAILY_REPORT_WORKER,
  PAGEID_PERSONS_MONTHLY_REPORT_WORKER
} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsBoxPerson2 from '_components/charts/LFCChartsBoxPerson2';
import {useTranslation} from 'react-i18next';
import DateTimePicker from '_components/inputs/DateTimePicker';

const MonthlyReportWorkerWithFacilityPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [data001, setData001] = useState([]);
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState([]);
  const [data004, setData004] = useState([]);
  const [data002_box, setData002_box] = useState([]);
  const [data002_export, setData002_export] = useState([]);

  const [data4018, setData4018] = useState([]);
  const [data4018_box, setData4018_box] = useState([]);
  const [data4019, setData4019] = useState([]);

  const [machine_id_index, setMachine_id_index] = React.useState<any>([]);

  const initialSearchValue = {
    select_year_month: dayjs().format('YYYY-MM'),
    select_datetime_from: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    select_datetime_to: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    work: []
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_PERSONS_DAILY_REPORT_WORKER,
    LOCATIONID_PERSONS_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, select_year_month: event.format('YYYY-MM')});
  };

  const makeDaysList = () => {
    let tmp_day = new Date(searchValue.select_year_month);
    let tmp_days_list: any = [];
    for (let i = 1; i <= 31; i++) {
      tmp_day.setDate(+i);
      if (tmp_day.getMonth() + 1 === new Date(searchValue.select_year_month).getMonth() + 1) {
        tmp_days_list.push(
          searchValue.select_year_month + '-' + ('0' + tmp_day.getDate()).slice(-2)
        );
      }
    }
    return tmp_days_list;
  };

  const doSearch = async () => {
    setStartProcess(true);
    setData001([]);
    setData002([]);
    setData003([]);
    setData002_box([]);
    setData002_export([]);
    setMachineList([]);

    const conditionsDate = {
      select_year_month: searchValue.select_year_month,
      select_datetime_from: dayjs(searchValue.select_year_month)
        .startOf('month')
        .format('YYYY-MM-DD HH:mm:ss'),
      select_datetime_to: dayjs(searchValue.select_year_month)
        .endOf('month')
        .format('YYYY-MM-DD HH:mm:ss')
    };
    const conditionsOther = {
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
    };

    // ボックスチャート：設備稼働時間
    // 日ごと
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4018,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData4018,
      name: 'sql-id:4018',
      cancelToken: source.token,
      t
    });
    // 設備稼働時間：計算値
    // 日ごと
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4019,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData4019,
      name: 'sql-id:4019',
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4013,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData001,
      name: 'sql-id:4013',
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4014,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData002,
      name: 'sql-id:4014',
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4016,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData004,
      name: 'sql-id:4016',
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4015,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      name: 'sql-id:4015',
      cancelToken: source.token,
      t
    }).then((a: any) => {
      if (isEmpty(a)) {
        setStartProcess(false);
      } else {
        setData003(a);
      }
    });
  };

  const [infoData, setInfoData] = useState({
    総合計: 0,
    総合計平均日: 0,
    良品: 0,
    良品平均日: 0,
    不良品: 0,
    不良品平均日: 0,
    不良率: 0,
    平均生産間隔: 0
  });

  useEffect(() => {
    let tmp: any;
    let tmp_all: number = 0;
    let tmp_allByDay: number = 0;
    let tmp_ok: number = 0;
    let tmp_okByDay: number = 0;
    let tmp_ng: number = 0;
    let tmp_ngByDay: number = 0;
    let tmp_ngPar: number = 0;
    if (!isEmpty(data001)) {
      //総合計
      tmp = data001.map((a: any) => a.total_count);
      tmp_all = tmp.reduce((a: number, b: number) => a + b, 0);
      //総合計平均日
      tmp_allByDay = tmp_all / data001.length;
      //良品
      tmp = data001.map((a: any) => a.ok);
      tmp_ok = tmp.reduce((a: number, b: number) => a + b, 0);
      //良品平均日
      tmp_okByDay = tmp_ok / data001.length;
      //不良品
      tmp = data001.map((a: any) => a.ng);
      tmp_ng = tmp.reduce((a: number, b: number) => a + b, 0);
      //不良品平均日
      tmp_ngByDay = tmp_ng / data001.length;
      //不良栗
      tmp = data001.map((a: any) => Number(a.ng_par));
      tmp_ngPar = round(tmp.reduce((a: number, b: number) => a + b, 0) / data001.length, 2);
    }

    setInfoData({
      総合計: tmp_all,
      総合計平均日: tmp_allByDay,
      良品: tmp_ok,
      良品平均日: tmp_okByDay,
      不良品: tmp_ng,
      不良品平均日: tmp_ngByDay,
      不良率: tmp_ngPar,
      平均生産間隔: 0
    });
  }, [data001]);

  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  useEffect(() => {
    if (!isEmpty(data004)) {
      setInfoData({...infoData, 平均生産間隔: average(data004.map((a: any) => a.cycle_time))});
    }
  }, [data004]);

  const [machineList, setMachineList] = useState([]);

  useEffect(() => {
    let tmp_machine: any = [];
    if (data002.length > 0) {
      setStartProcess(true);
      tmp_machine = Array.from(new Set(data002.map((a: any) => a.machine))).sort();
      setMachineList(tmp_machine);

      let tmp_x_list: any = makeDaysList();
      let tmp_data: any = [];
      let tmp_data_export: any = [];
      tmp_machine.forEach((a: string) => {
        tmp_x_list.forEach((b: string) => {
          if (data002.filter((c: any) => c.machine === a && c['year_month_day'] === b).length > 0) {
            tmp_data.push({
              machine: a,
              x_list: b,
              data_value: data002.filter(
                (c: any) => c.machine === a && c['year_month_day'] === b
              )[0]['data_value']
            });
            let tmp_dv: any = data002.filter(
              (c: any) => c.machine === a && c['year_month_day'] === b
            )[0]['data_value'];
            for (const v of tmp_dv) {
              tmp_data_export.push({
                machine: a,
                x_list: b,
                data_value: v
              });
            }
          } else {
            tmp_data.push({
              machine: a,
              x_list: b,
              data_value: []
            });
          }
        });
      });
      setData002_box(tmp_data);
      setData002_export(tmp_data_export);
      setStartProcess(false);
    }
  }, [data002]);

  useEffect(() => {
    let tmp_data: any = [];
    let tmp_machine: any = [];
    if (data4018.length > 0) {
      tmp_machine = Array.from(new Set(data4018.map((item: any) => item.machine_id)));
      setMachine_id_index(tmp_machine.sort());

      if (searchValue.searchType !== 0) {
        tmp_machine.forEach((a: string) => {
          makeDaysList().forEach((b: string) => {
            if (
              data4018.filter((c: any) => c.machine_id === a && c['year_month_day'] === b).length >
              0
            ) {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: data4018
                  .filter((c: any) => c.machine_id === a && c['year_month_day'] === b)
                  .map((data: any) => data.time_diff)
              });
            } else {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: []
              });
            }
          });
        });
      } else {
        tmp_machine.forEach((a: string) => {
          data4018
            .filter((b: any) => b.machine_id === a)
            .forEach((c: any) => {
              tmp_data.push({
                machine: a,
                x_list: c.idx,
                data_value: [c.time_diff]
              });
            });
        });
      }
      setData4018_box(tmp_data);
    }
  }, [data4018]);

  let data002_column: any = {
    カメラ名: t('カメラ名'),
    枠名: t('枠名'),
    日時: t('日時'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };
  let data004_column: any = {
    年月日: t('年月日'),
    cycle_time: 'cycle_time',
    work: 'work'
  };
  let data4018_column: any = {
    設備名: t('設備名'),
    日時: t('日時'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_year_month',
        value: searchValue.select_year_month,
        valueLabel: searchValue.select_year_month,
        colName: t('年月'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.人ログ分析')}:${t('月間集計')}(${t('人ログ')})(${t(
        '設備データあり'
      )})`}
    >
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_year_month)}
            format="YYYY/MM"
            views={['year', 'month']}
            onChange={event => {
              handleChange(event);
            }}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_PERSONS_MONTHLY_REPORT_WORKER}
            locationNo={LOCATIONID_PERSONS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={2.5}>
            <LFCSingleValues
              columns={[
                {
                  field: '総合計',
                  headerName: t('総合計'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '総合計平均日',
                  headerName: t('総合計平均日'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '良品',
                  headerName: t('良品'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '良品平均日',
                  headerName: t('良品平均日'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '不良品',
                  headerName: t('不良品'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '不良品平均日',
                  headerName: t('不良品平均日'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '不良率',
                  headerName: `${t('不良率')}`,
                  width: '100px',
                  unit: ' %',
                  formatter: v => round(v, 1).toLocaleString()
                },
                {
                  field: '平均生産間隔',
                  headerName: t('平均生産間隔'),
                  width: '100px',
                  unit: `${t('秒')}`,
                  formatter: v => round(v, 1).toLocaleString()
                }
              ]}
              source={infoData}
            />
          </Grid>
          <Grid item xs={12} md={9.5}>
            <LFCChartsComboXList
              title={`${t('実績数と不良率')}`}
              source={data001}
              xList={makeDaysList()}
              yLeft={{
                type: 'bar',
                name: `${t('実績数')}`,
                dsColumn: 'total_count'
              }}
              yRight={{
                type: 'line',
                name: `${t('不良率')}(%)`,
                dsColumn: 'ng_par',
                markPoint: {
                  data: [
                    {type: 'min', itemStyle: {color: '#E91E63'}},
                    {type: 'max', itemStyle: {color: '#2196F3'}}
                  ]
                },
                markLine: {
                  symbol: 'arrow',
                  data: [
                    {
                      type: 'average',
                      label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                    }
                  ]
                }
              }}
              color={[green[500], red[500]]}
              height={'310px'}
              exportData={data001}
              exportFields={{
                lq_time: t('日付'),
                total_count: t('実績数'),
                ng: t('不良数'),
                ng_par: t('不良率')
              }}
              exportFilename={`${t('実績数と不良率')}`}
            />
          </Grid>
          <Grid item xs={12} md={2.5}></Grid>
          <Grid item xs={12} md={9.5}>
            <LFCChartsLinePerson02
              title={t('平均生産間隔推移')}
              source={data004}
              exportData={data004}
              exportFields={data004_column}
              exportFilename={`${t('平均生産間隔推移')}`}
              yAxisName={''}
              height={'280px'}
              grid={{top: 50, bottom: 80, left: 70, right: 60}}
            />
          </Grid>

          {machine_id_index.map((a: string, index: number) => {
            return (
              <Grid container key={'base_0' + index} spacing={1}>
                {index === 0 ? (
                  <Grid item xs={12} style={{fontWeight: 'bold', marginBottom: 10}}>
                    {t('設備稼働時間')}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} lg={2.5} key={'index_' + index}>
                  {a}
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'data_max',
                        headerName: `${t('最大')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_avg',
                        headerName: `${t('平均')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_min',
                        headerName: `${t('最小')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_stddev',
                        headerName: `${t('標準偏差')}`,
                        width: '100px',
                        unit: '',
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_total',
                        headerName: t('総作業時間'),
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      }
                    ]}
                    source={data4019.filter((b: any) => b.machine_id === a)[0]}
                  />
                </Grid>
                <Grid item xs={12} lg={9.5} key={'graph_0_' + index}>
                  <LFCChartsBoxPerson2
                    // 生産間隔_時間推移
                    title=""
                    source={data4018_box.filter((b: any) => b.machine === a)}
                    exportData={data4018}
                    exportFields={data4018_column}
                    machineName={a.split('..')[0]}
                    exportFilename={`${t('設備稼働時間')}`}
                    height={'200px'}
                    grid={{top: 50, bottom: 50, left: 70, right: 60}}
                    bottom={'80'}
                    yAxisName={`${t('設備稼働時間')}(${t('秒')})`}
                    xlist={makeDaysList()}
                  />
                </Grid>
              </Grid>
            );
          })}

          {machineList.map((a: string, index: number) => {
            return (
              <Grid container key={'base_' + index} spacing={1} style={{marginTop: 10}}>
                {index === 0 ? (
                  <Grid item xs={12} style={{fontWeight: 'bold', marginBottom: 10}}>
                    {t('人の作業時間')}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={12} lg={2.5} key={'index_' + index}>
                  【{t('カメラ')}】{a.split('..')[0]}【{t('枠')}】{a.split('..')[1]}
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'data_max',
                        headerName: `${t('最大')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_avg',
                        headerName: `${t('平均')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_min',
                        headerName: `${t('最小')}`,
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_stddev',
                        headerName: `${t('標準偏差')}`,
                        width: '100px',
                        unit: '',
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_total',
                        headerName: t('総作業時間'),
                        width: '100px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      }
                    ]}
                    source={data003.filter((b: any) => b.key === a)[0]}
                  />
                </Grid>
                <Grid item xs={12} lg={9.5} key={'graph_' + index}>
                  <LFCChartsBoxPerson1
                    title=""
                    source={data002_box.filter((b: any) => b.machine === a)}
                    exportData={data002_export}
                    exportFields={data002_column}
                    cameraName={a.split('..')[0]}
                    frameNo={a.split('..')[1]}
                    exportFilename={`${t('作業時間チャート')}`}
                    height={'200px'}
                    grid={{top: 50, bottom: 50, left: 75, right: 60}}
                    yAxisName={`${t('作業時間チャート')}(${t('秒')})`}
                    xlist={makeDaysList()}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default MonthlyReportWorkerWithFacilityPage;
