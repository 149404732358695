import {Link, Typography} from '@mui/material';

/**
 * Copyright
 * @returns
 */
const Copyright = () => {
  return (
    <Typography variant="caption" color="textSecondary">
      {'© '}
      {new Date().getFullYear()}{' '}
      <Link color="inherit" href="https://linkwiz.co.jp/">
        LINKWIZ, Inc. All rights reserved.
      </Link>
    </Typography>
  );
};
export default Copyright;
