import {AuthenticationDetails, CognitoUserAttribute} from 'amazon-cognito-identity-js';
import React, {createContext, useContext, useState} from 'react';
import AuthUser from '_contexts/types/AuthUser';
import {getCognitoUser, getNewCognitoUser} from '_logics/LFCCognito';
import {getLFCData} from '_logics/LFCUtil';

type OperationType = {
  login: (
    userId: string,
    password: string,
    callbackError: (err: Error) => void,
    callbackPassCng: (userAttributes: any, requiredAttributes: any) => void,
    newPass: string,
    t?: any
  ) => void;

  logout: () => void;
  regetAuthUser: () => void;
};

/**
 * OperationContext
 */
const AuthOperationContext = createContext<OperationType>({
  login: () => console.error('Providerが設定されていません'),
  logout: () => console.error('Providerが設定されていません'),
  regetAuthUser: () => console.error('Providerが設定されていません')
});

/**
 * Context
 */
const AuthUserContext = createContext<AuthUser | null>(null);

/**
 * 認証プロバイダ
 * @param param0
 * @returns
 */
const AuthUserProvider: React.FC = ({children}) => {
  const [authUser, setAuthUser] = useState<AuthUser | null>(null);

  /**
   * ログイン認証
   * @param userId
   * @param password
   * @param callbackError
   * @param callbackPassCng
   * @param newPass
   */
  const login = (
    userId: string,
    password: string,
    callbackError: (err: Error) => void,
    callbackPassCng: (userAttributes: any, requiredAttributes: any) => void,
    newPass: string,
    t?: any
  ) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: userId,
      Password: password
    });

    const cognitoUser = getNewCognitoUser(userId);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: result => {
        // cognito認証成功

        // 認証check (m_userにレコードがあるか)
        getLFCData({
          sql_id: 0,
          parameters: {},
          t
        })
          .then(datas => {
            if (datas.length === 0) {
              callbackError(
                new Error('本サイトへのアクセス権限がありません。ドメインを確認してください。')
              );
              return;
            }
            // 属性取得
            cognitoUser.getUserAttributes((err, res2) => {
              if (err) {
                console.log(err);
                callbackError(err);
                return;
              }
              // 全認証成功
              const fixUser = transferAuthUser(res2);
              setAuthUser(fixUser);
            });
          })
          .catch(err => {
            console.log(err);
            callbackError(
              new Error(
                'ただいまシステムメンテナンス中です。しばらくたってから再度アクセスしてください'
              )
            );
            return;
          });
      },
      onFailure: err => {
        // cognito認証失敗
        console.log(err);
        callbackError(err);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // cognito認証仮成功(要パスワード変更)

        // delete userAttributes.email_verified;

        if (newPass === '') {
          // 新パスワード指定なしの場合、CallBack
          callbackPassCng(userAttributes, requiredAttributes);
        } else {
          // 新パスワード指定ありの場合、登録
          cognitoUser.completeNewPasswordChallenge(newPass, requiredAttributes, {
            onSuccess: result => {
              // 全認証成功
              const fixUser = {
                userId: userAttributes['name'],
                companyId: Number(userAttributes['custom:company_id']),
                email: userAttributes['email']
              };
              // 全認証成功
              setAuthUser(fixUser);

              callbackPassCng(userAttributes, requiredAttributes);
            },
            onFailure: err => {
              // 失敗
              console.log(err);
              callbackError(err);
            }
          });
        }
      }
    });
  };

  /**
   * ユーザ情報をコンテキストにセット
   * @param cuas
   */
  const transferAuthUser = (cuas: CognitoUserAttribute[] | undefined): AuthUser => {
    let userinfo: AuthUser = {};
    cuas?.forEach(element => {
      switch (element.Name) {
        case 'name':
          userinfo = {...userinfo, userId: element.Value};
          break;
        case 'custom:company_id':
          userinfo = {...userinfo, companyId: Number(element.Value)};
          break;
        case 'email':
          userinfo = {...userinfo, email: element.Value};
          break;
      }
    });
    return userinfo;
  };

  /**
   * ログアウト
   */
  const logout = () => {
    const cognitoUser = getCognitoUser();
    if (cognitoUser == null) {
      setAuthUser(null);
      return;
    }

    cognitoUser.globalSignOut({
      onSuccess: result => {
        setAuthUser(null);
      },
      onFailure: err => {
        console.error('Error during global sign out:', err);
        cognitoUser.signOut(() => {
          setAuthUser(null);
        });
      }
    });
  };

  /**
   * ユーザ情報再取得
   */
  const regetAuthUser = async () => {
    const cognitoUser = getCognitoUser();
    // 属性取得
    cognitoUser?.getUserAttributes((err, res2) => {
      if (err) {
        console.log(err);
        return;
      }
      // コンテキストに移送
      setAuthUser(transferAuthUser(res2));
    });
  };

  return (
    <AuthOperationContext.Provider value={{login, logout, regetAuthUser}}>
      <AuthUserContext.Provider value={authUser}>{children}</AuthUserContext.Provider>
    </AuthOperationContext.Provider>
  );
};

export const useAuthUser = () => useContext(AuthUserContext);
export const useLogin = () => useContext(AuthOperationContext).login;
export const useLogout = () => useContext(AuthOperationContext).logout;
export const useRegetAuthUser = () => useContext(AuthOperationContext).regetAuthUser;

export default AuthUserProvider;
