import ClearIcon from '@mui/icons-material/Clear';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import {default as ThumbDownIcon} from '@mui/icons-material/ThumbDown';
import {default as ThumbUpIcon} from '@mui/icons-material/ThumbUp';
import {green, red} from '@mui/material/colors';
import {GridRenderCellParams} from '@mui/x-data-grid-pro';
import GridCellExpand from '_components/datagrid/renderCell/GridCellExpand';
import LFCButton from '_components/inputs/LFCButton';
import {useTranslation} from 'react-i18next';

/**
 * 総合判定
 * @returns
 * @param value
 */
export const rendTotalJudgeDisp = (value: any) => {
  switch (String(value)) {
    case '0': {
      return <ThumbUpIcon style={{color: green[500], cursor: 'pointer'}} />;
    }
    case '1': {
      return <ThumbDownIcon style={{color: red[500], cursor: 'pointer'}} />;
    }
    default: {
      return '';
    }
  }
};

/**
 * 判定
 * @returns
 * @param value
 */
export const rendJudgeDisp = (value: any) => {
  switch (String(value)) {
    case '0': {
      return <PanoramaFishEyeIcon style={{color: green[500], cursor: 'pointer'}} />;
    }
    case '1': {
      return <ClearIcon style={{color: red[500], cursor: 'pointer'}} />;
    }
    default: {
      return '';
    }
  }
};

/**
 * 判定：赤色ブリンク
 * @returns
 * @param value
 */
export const rendJudgeRedDisp = (value: any) => {
  switch (String(value)) {
    case 'NG': {
      return (
        <>
          <style>
            {`
        @keyframes border-color-change {
        0% { border: solid 1px #303030; color: white;}
        50% { border: solid 1px red; color: red; background-color: rgb(116 16 16); }
        100% { border: solid 1px #303030; color: white;}
      }`}
          </style>
          <div
            style={{
              animation: `border-color-change 3s ease 1s infinite`,
              color: 'white',
              border: 'solid 1px #303030',
              cursor: 'pointer'
            }}
          >
            NG
          </div>
        </>
      );
    }
    case 'OK': {
      return (
        <span
          style={{
            border: 'solid 1px #303030',
            cursor: 'pointer'
          }}
        >
          OK
        </span>
      );
    }
    default: {
      return '';
    }
  }
};

/**
 *
 * ダウンロードボタン：マニュアル
 * @param prms
 * @returns
 */
export const RendDownLoad = (value: any, onclickEvent: any) => {
  const {t} = useTranslation();
  return (
    <LFCButton color="primary" onClick={() => onclickEvent(value.row.manual_name)}>
      {t('ダウンロード')}
    </LFCButton>
  );
};

/**
 *
 * ダウンロードボタン：サポート
 * @param prms
 * @returns
 */
export const SupportRendDownLoad = (value: any, onclickEvent: any) => {
  return (
    <LFCButton onClick={() => onclickEvent(value.row.ticket_id, value.row.tmp_filename)}>
      ダウンロード
    </LFCButton>
  );
};

/**
 *
 * 不良処置：不良画像表示
 * @param params
 * @constructor
 */
export const ImageView = (params: any, imagePath: string) => {
  return (
    <>
      <img
        src={imagePath + params.formattedValue}
        width="100%"
        style={{cursor: 'pointer'}}
        alt=""
      />
    </>
  );
};

/**
 * Datagridにて、省略された文字列をツールチップで表示する
 * @param params
 * @returns
 */
export const renderCellExpand = (params: GridRenderCellParams) => {
  return <GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />;
};
