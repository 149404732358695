import dayjs from 'dayjs';
import {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
export const LFCChartsBarForHeartBeat = (props: Props) => {
  const COLOR_OK = '#4CAF50';
  const COLOR_NG = '#F44336';
  const renderFlgRef = useRef(false);

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // データソースクリア
    if (props.source === undefined) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});

    if (props.series) {
      // シリーズ指定あり
      let strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.source.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.series, value: grp}
          }
        });
        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          encode: {x: props.x.dsColumn, y: props.y.dsColumn},
          datasetIndex: i + 1,
          itemStyle: {
            color: (data: any) => {
              return data['is_success'] === 'true' ? COLOR_OK : COLOR_OK;
            }
          }
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        encode: {x: props.x.dsColumn, y: props.y.dsColumn},
        barWidth: '20px',
        itemStyle: {
          color: (data: any) => {
            return data['data']['is_success'] === 'true' ? COLOR_OK : COLOR_NG;
          }
        }
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          top: '40',
          left: '30',
          bottom: '100'
        },
        ...(props.series
          ? {
              legend: {type: 'scroll', bottom: 0, itemWidth: 10}
            }
          : {
              legend: {
                show: false
              }
            }),
        color: props.color ? props.color : null,
        dataset: dataset,
        xAxis: [
          {
            type: props.x.type,
            axisLabel: {
              fontSize: 11,
              boundaryGap: '5px',
              width: 100,
              align: 'center',
              overflow: 'truncate',
              formatter: (prm: any) => {
                return dayjs(prm).format('MM/DD');
              }
            }
          }
        ],
        yAxis: [
          {
            type: props.y.type,
            nameTextStyle: {
              fontSize: 11
            },
            axisLabel: {
              fontSize: 11
            }
          }
        ],
        series: series
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
};
