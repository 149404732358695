import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {useSystemSettings} from '_contexts/SystemSettingsProvider';
import {FORMAT_YYYYMMDD} from '_logics/LFCConst';
import {fmtmmss, fmtYMD} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_MONITOR} from '_logics/LFCPageId';
import {rendJudgeDisp} from '_logics/LFCRenderUtil';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsGanttChartWelding from '../_charts/LFCChartsGanttChartWelding';
import LFCChartsWeldingDayBarLine from '../_charts/LFCChartsWeldingDayBarLine';
import {useTranslation} from 'react-i18next';

const MonitorPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const systemSettings = useSystemSettings();

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(PAGEID_WELDING_MONITOR, LOCATIONID_WELDING_MAIN);
  const [searchValue, setSearchValue] = useState({working_time: 8, ...defaultCondition});

  const [responseData001, setData001] = useState([{total_count: '0', ng_count: '0'}]);
  const [responseData002, setData002] = useState([
    {
      min_time: 0,
      average_time: 0,
      max_time: 0,
      work_rate: 0
    }
  ]);
  const [responseData003, setData003] = useState([
    {
      start_time: '',
      serial: '',
      work: '',
      machine: ''
    }
  ]);

  const [responseData004, setData004] = useState([]);

  const [responseData005, setData005] = useState([]);
  const [responseData006, setData006] = useState([]);
  const [responseData007, setData007] = useState([]);
  const [dispDate, setDispDate] = useState('');

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  const apiFetch = () => {
    setStartProcess(true);

    const dtnow = dayjs();
    setDispDate(dtnow.format(FORMAT_YYYYMMDD));

    Promise.allSettled([
      // 3101,板金溶接：本日の生産状況
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3101,
        parameters: {select_date: dtnow},
        ds_state: setData001,
        name: `${t('本日の生産状況')}`,
        cancelToken: source.token,
        t
      }),
      // 3102,板金溶接：作業時間
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3102,
        parameters: {
          select_date: dtnow,
          working_time: searchValue.working_time
        },
        ds_state: setData002,
        name: `${t('作業時間')}`,
        cancelToken: source.token,
        t
      }),
      // 3103,板金溶接：最終実行結果,
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3103,
        parameters: {},
        ds_state: setData003,
        name: `${t('最終実行結果')}`,
        cancelToken: source.token,
        t
      }),
      // 3104,板金溶接：稼働状況,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3104,
        parameters: {select_date: dtnow},
        ds_state: setData004,
        name: `${t('稼働状況')}`,
        cancelToken: source.token,
        t
      }),
      // 3205,板金溶接：生産数推移,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3205,
        parameters: {select_date: dtnow, work: null},
        ds_state: setData005,
        name: `${t('生産数推移')}`,
        cancelToken: source.token,
        t
      }),
      // 3209,板金溶接：溶接長さ,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3209,
        parameters: {select_date: dtnow, work: null},
        ds_state: setData006,
        name: `${t('溶接長さ')}`,
        cancelToken: source.token,
        t
      }),
      // 3207,板金溶接：電極交換履歴,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3207,
        parameters: {select_date: dtnow},
        ds_state: setData007,
        name: `${t('電極交換履歴')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetch();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 自動更新処理
   */
  useInterval(apiFetch, systemSettings.RELOAD_TIME * 1000);

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false); //検索条件保存画面の開閉状態を表すフラグ
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   *　検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'working_time',
        value: searchValue.working_time,
        valueLabel: searchValue.working_time,
        colName: t('1日の稼働時間(h)'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.生産進捗モニタ')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCSelectForm
            name={'working_time'}
            label={t('1日の稼働時間(h)')}
            value={searchValue.working_time}
            id={'working_time'}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            selectItem={[...Array(24)].map((_, i) => ({name: String(i + 1), value: i + 1}))}
            // fullWidth
          />
          <LFCButton color="primary" onClick={() => apiFetch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_MONITOR}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4}>
            <LFCTitledCard title={t('本日')} titleSub={`${dispDate}`}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LFCOkNgPie source={responseData001[0]} height="300px" />
                </Grid>
                <Grid item xs={12} sm={9} lg={8}>
                  <LFCTitledCard title={t('作業時間')} transparent>
                    <LFCSingleValues
                      columns={[
                        {
                          field: 'min_time',
                          headerName: `${t('最小')}`,
                          formatter: fmtmmss,
                          width: '29%'
                        },
                        {
                          field: 'average_time',
                          headerName: `${t('平均')}`,
                          formatter: fmtmmss,
                          width: '29%'
                        },
                        {
                          field: 'max_time',
                          headerName: `${t('最大')}`,
                          formatter: fmtmmss,
                          width: '29%'
                        }
                      ]}
                      source={responseData002[0]}
                    />
                  </LFCTitledCard>
                </Grid>
                <Grid item xs={12} sm={3} lg={4}>
                  <LFCTitledCard title={t('稼働率')} transparent>
                    <LFCSingleValues
                      columns={[
                        {
                          field: 'work_rate',
                          headerName: `${t('稼働率')}(%)`,
                          formatter: v => round(v * 100, 1),
                          width: '100%'
                        }
                      ]}
                      source={responseData002[0]}
                    />
                  </LFCTitledCard>
                </Grid>{' '}
              </Grid>
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Grid container>
              <Grid item xs={12}>
                <LFCTitledCard title={t('最終実行結果')} transparent>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <LFCSingleValues
                        columns={[
                          {field: 'end_time', headerName: `${t('日時')}`, formatter: fmtYMD},
                          {field: 'machine', headerName: `${t('マシン')}`},
                          {field: 'work', headerName: `${t('機種')}`},
                          {field: 'serial', headerName: `${t('シリアルNo')}`},
                          {field: 'ct', headerName: `${t('作業時間')}`, formatter: fmtmmss},
                          {field: 'judge', headerName: `${t('判定')}`, formatter: rendJudgeDisp}
                        ]}
                        source={responseData003[0]}
                      />
                    </Grid>
                  </Grid>
                </LFCTitledCard>
              </Grid>
              <Grid item xs={12} mt={1}>
                <LFCChartsGanttChartWelding
                  title={t('稼働状況')}
                  source={responseData004}
                  exportFields={{
                    machine: t('マシン'),
                    judge: `${t('判定')}(0:${t('code.judge.1')}/1:${t('code.judge.2')})`,
                    detection_time_from: t('開始時間'),
                    detection_time_to: t('終了時間')
                  }}
                  exportFilename={`${t('稼働状況')}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={4}>
            <LFCChartsWeldingDayBarLine
              chartType="bar"
              title={t('生産数推移')}
              source={responseData005}
              sourceKey={{x: 'exec_date', y: 'exec_count', series: 'work'}}
              exportFields={{
                exec_date: t('時刻'),
                work: t('機種'),
                exec_count: t('個数')
              }}
              showNowArea
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <LFCChartsWeldingDayBarLine
              chartType="line"
              title={t('溶接長さ')}
              source={responseData006}
              sourceKey={{x: 'exec_date', y: 'welding_length', series: ''}}
              yUnit="mm"
              yDigit={1}
              exportFields={{
                exec_date: t('時刻'),
                welding_length: t('長さ累計')
              }}
              addition
              showNowArea
            />
          </Grid>
          <Grid item xs={12} xl={4}>
            <LFCChartsWeldingDayBarLine
              chartType="bar"
              title={t('電極交換履歴')}
              source={responseData007}
              sourceKey={{x: 'exec_hour', y: 'exec_count', series: ''}}
              yUnit={`${t('回')}`}
              yDigit={0}
              exportFields={{
                exec_hour: t('時刻'),
                exec_count: t('回数')
              }}
              showNowArea
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default MonitorPage;
