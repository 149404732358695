import {Tab} from '@mui/material';
import {withStyles} from '@mui/styles';

const CustomTab = withStyles({
  root: {
    backgroundColor: ''
  },
  selected: {
    backgroundColor: 'white',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px'
    }
  }
})(Tab);

export default CustomTab;
