import {Grid} from '@mui/material';
import axios from 'axios';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCChartsHeatMapLrobotFailureAnalysis from '_components/charts/LFCChartsHeatMapLrobotFailureAnalysis';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCButton from '_components/inputs/LFCButton';
import JobTrendAnalysisCompareDig from './_components/JobTrendAnalysisCompareDig';
import FailureAnalysisGapDig from './FailureAnalysisGapDig';
import dayjs from 'dayjs';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LR_FAILUREANALYSIS, LOCATIONID_LR_FAILUREANALYSIS_MAIN} from '_logics/LFCPageId';
import {useTranslation} from 'react-i18next';

const FailureAnalysisPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  const [data60004, setData60004] = useState<any>([]);
  const [data60020, setData60020] = useState<any>([]);

  const [singleValuesData, setSingleValuesData] = useState<any>([]);

  const [fittingOpen, setFittingOpen] = React.useState(false);
  const [gapOpen, setGapOpen] = React.useState(false);
  const [openData, setOpenData] = React.useState([]);
  const handleClose = () => {
    setOpenData([]);
    setFittingOpen(false);
    setGapOpen(false);
  };

  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [searchValue, setSearchValue] = useState<any>(
    getDefaultCondition(PAGEID_LR_FAILUREANALYSIS, LOCATIONID_LR_FAILUREANALYSIS_MAIN) ?? {
      select_datetime_from: dayjs().add(-7, 'd').format('YYYY-MM-DDT00:00'),
      select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm')
    }
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setData60004([]);

    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60004,
        parameters: {
          select_datetime_from: searchValue.select_datetime_from,
          select_datetime_to: searchValue.select_datetime_to
        },
        ds_state: setData60004,
        name: `LROBOT_${t('現場モニタ')}_${t('生産数不良数')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60020,
        parameters: {
          select_datetime_from: searchValue.select_datetime_from,
          select_datetime_to: searchValue.select_datetime_to
        },
        ds_state: setData60020,
        name: `LROBOT_${t('不良傾向分析')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  useEffect(() => {
    setSingleValuesData({
      total_count: data60004[0] ? String(data60004[0].total_count) : '-',
      ng_percent: data60004[0] ? round(data60004[0].ng_percent * 100, 1) + '%' : '-'
    });
  }, [data60004]);

  let data60020_column: any = {
    job_name: t('ジョブ名称'),
    map: t('マップ'),
    judge: t('判定')
  };

  const Fitting_heatmap = (data: any) => {
    data.searchValue = searchValue;
    setOpenData(data);
    setFittingOpen(true);
  };
  const Gap_heatmap = (data: any) => {
    data.searchValue = searchValue;
    setOpenData(data);
    setGapOpen(true);
  };

  const makeMax = (list: any, keyName: string) => {
    if (list.length > 0 && keyName !== '') {
      let tmp_max: number = Math.max(
        ...list.filter((item: any) => item.job_name === keyName).map((item: any) => item.judge)
      );
      return tmp_max < 10 ? 10 : tmp_max;
    } else {
      return 10;
    }
  };

  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`L-ROBOT:${t('menu.page_name.不良傾向分析')}`}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <form ref={formRef}>
            <LFCFormRowGroup>
              <LFCDatetimePicker
                name={'select_datetime_from'}
                label={t('対象期間From')}
                value={searchValue.select_datetime_from}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCDatetimePicker
                name={'select_datetime_to'}
                label={t('対象期間To')}
                value={searchValue.select_datetime_to}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCButton color="primary" onClick={() => doSearch()}>
                {t('検索')}
              </LFCButton>
              <LFCSavedCondition
                open={openSavedCondition}
                pageId={PAGEID_LR_FAILUREANALYSIS}
                locationNo={LOCATIONID_LR_FAILUREANALYSIS_MAIN}
                onLoad={onLoadSavedCondition}
                onSelect={onSelectSavedCondition}
                onClose={() => setOpenSavedCondition(false)}
                conditions={condition}
              />
            </LFCFormRowGroup>
          </form>
        </Grid>
        <Grid item xs={4}>
          <Grid container justifyContent="end">
            <Grid item>
              <LFCSingleValues
                columns={[
                  {field: 'total_count', headerName: t('生産数'), width: '100px'},
                  {field: 'ng_percent', headerName: `${t('不良率')}`, width: '100px'}
                ]}
                source={singleValuesData}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LFCChartsHeatMapLrobotFailureAnalysis
            title=""
            source={data60020.filter((item: any) => item.job_name === 'Fitting')}
            x={{dsColumn: 'map'}}
            y={{dsColumn: 'job_name'}}
            series="judge"
            visual_map={{
              min: 0,
              max: makeMax(data60020, 'Fitting'),
              show: true
            }}
            exportData={data60020.filter((item: any) => item.job_name === 'Fitting')}
            exportFields={data60020_column}
            exportFilename={`LROBOT_${t('不良傾向分析')}_Fitting`}
            height={'25vh'}
            onClick={Fitting_heatmap}
          />

          <LFCChartsHeatMapLrobotFailureAnalysis
            title=""
            source={data60020.filter((item: any) => item.job_name === 'Gap')}
            x={{dsColumn: 'map'}}
            y={{dsColumn: 'job_name'}}
            series="judge"
            visual_map={{
              min: 0,
              max: makeMax(data60020, 'Gap'),
              show: true
            }}
            exportData={data60020.filter((item: any) => item.job_name === 'Gap')}
            exportFields={data60020_column}
            exportFilename={`LROBOT_${t('不良傾向分析')}_Gap`}
            height={'25vh'}
            onClick={Gap_heatmap}
          />
        </Grid>
      </Grid>

      <JobTrendAnalysisCompareDig open={fittingOpen} onClose={handleClose} datas={openData} />

      <FailureAnalysisGapDig open={gapOpen} onClose={handleClose} datas={openData} />
    </GenericTemplate>
  );
};

export default FailureAnalysisPage;
