/**
 * 共通 FontFamily
 */
export const getFontFamily = (): string => {
  return [
    // "BIZ UDPGothic",
    // "Meiryo",
    '游ゴシック Medium',
    '游ゴシック体',
    'Yu Gothic Medium',
    'YuGothic',
    'ヒラギノ角ゴ ProN',
    'Hiragino Kaku Gothic ProN',
    'Noto Sans JP',
    'Lato',
    'メイリオ',
    'Meiryo',
    'ＭＳ Ｐゴシック',
    'MS PGothic',
    'sans-serif'
  ].join(',');
};

/**
 * 共通カラー
 * @param theme
 * @returns
 */
export const getColors = (theme: 'dark' | 'light') => {
  return theme === 'dark'
    ? {
        primaryColor: 'rgb(126,252,246)',
        secondaryColor: 'rgb(255, 167, 108)',
        textColor: {
          primary: 'rgb(126,252,246)',
          secondary: 'rgba(126,252,246,0.8)',
          disabled: 'rgba(126,252,246,0.5)',
          hint: 'rgba(126,252,246,0.5)'
        },
        backgroundColor: {default: 'rgb(26, 26, 26)', paper: 'rgb(26, 26, 26)'},
        backgroundColorBackdrop: 'rgba(0, 0, 0, 0.3)',
        scrollbarColor: {bar: 'rgb(5, 198, 193)', back: 'rgb(66, 66, 66)'},
        dividerColor: 'rgba(126,252,246,0.2)',
        chartsLineColor: 'rgba(126,252,246,0.3)',
        chartsSplitColor: 'rgba(126,252,246,0.1)',
        chartsLabelColor: 'rgb(84, 110, 122)',
        shadowColor: 'rgba(126,252,246, 0.7)'
      }
    : {
        primaryColor: 'rgb(33, 150, 243)',
        secondaryColor: 'rgb(255, 87, 34)',
        textColor: {
          primary: 'rgb(0,0,0)',
          secondary: 'rgba(0,0,0,0.8)',
          disabled: 'rgba(0,0,0,0.45)',
          hint: 'rgba(0,0,0,0.45)'
        },
        backgroundColor: {default: 'rgb(242, 242, 242)', paper: 'rgb(242, 242, 242)'},
        backgroundColorBackdrop: 'rgba(0, 0, 0, 0.3)',
        scrollbarColor: {bar: 'rgb(5, 47, 55)', back: 'rgb(238, 238, 238)'},
        dividerColor: 'rgb(0,0,0,0.12)',
        chartsLineColor: 'rgba(0,0,0,0.3)',
        chartsSplitColor: 'rgba(0,0,0,0.05)',
        chartsLabelColor: 'rgb(26, 26, 26)',
        shadowColor: 'rgba(0, 0, 0, 0.3)'
      };
};
