import dayjs from 'dayjs';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  xAxisName?: string;
  yAxisName?: string;
  target: string;
}

const MAX_DATA_WIDTH = 60;

export const LfcChartsLineArcStatus = (props: Props) => {
  let seriesData: any = [];
  props?.source?.forEach((workedData: any) => {
    const firstDataTimestamp = dayjs(workedData[0].log_timestamp);
    let tmpData: any = [];
    let skipFlag: boolean = false;
    if (!isEmpty(workedData)) {
      workedData.forEach((a: any) => {
        const offsetTimestamp = dayjs(a.log_timestamp).diff(firstDataTimestamp) / 1000;
        /* 横幅が8秒以上あるものは排除する */
        if (offsetTimestamp > MAX_DATA_WIDTH) {
          skipFlag = true;
        }
        tmpData.push([offsetTimestamp, a[props.target]]);
      });
      !skipFlag &&
        seriesData.push({
          name: dayjs(workedData[0].log_timestamp).format('HH:mm'),
          type: 'line',
          data: tmpData
        });
    }
  });

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            show: true,
            bottom: '0px',
            type: 'scroll'
          },
          xAxis: {
            name: props.xAxisName,
            nameLocation: 'middle',
            nameRotate: 0,
            nameTextStyle: {
              padding: [20, 0, 0, 0],
              fontSize: 16
            },
            splitLine: {
              show: true
            },
            axisLabel: {
              fontSize: 16,
              formatter: (val: number) => `${val}秒`
            },
            type: 'value',
            min: 0
          },
          yAxis: {
            name: props.yAxisName,
            nameLocation: 'middle',
            nameRotate: 90,
            nameTextStyle: {
              padding: [0, 0, 20, 0],
              fontSize: 16
            },
            splitLine: {
              show: true
            },
            axisLabel: {
              fontSize: 16,
              formatter: (val: number) => (val > 1000 ? `${val / 1000}k` : val)
            }
          },
          series: seriesData,
          grid: {
            left: 50,
            right: 5,
            bottom: 80,
            top: 60
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height}
      />
    </div>
  );
};
