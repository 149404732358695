import React from 'react';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import {handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {LOCATIONID_HEARTBEAT_MAIN, PAGEID_HEARTBEAT} from '_logics/LFCPageId';
import {Box, Card, CardContent, Grid, Typography} from '@mui/material';
import LFCSelectFormCompany from '_components-with-data/inputs/LFCSelectFormCompany';
import LFCSelectFormHeartBeatDevice from '_components-with-data/inputs/LFCSelectFormHeartBeatDevice';
import LFCSelectFormHeartBeatWorkTime from '_components-with-data/inputs/LFCSelectFormHeartBeatWorkTime';
import {LFCChartsBarForHeartBeat} from '_components/charts/LFCChartsBarForHeartBeat';
import {useHeartbeat} from './hooks/useHeartbeat';

const HeartBeatPage: React.FC = () => {
  const {
    resetSearch,
    doSearch,
    openSavedCondition,
    setOpenSavedCondition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    condition,
    startProcess,
    searchValue,
    setSearchValue,
    formRef,
    t,
    /* データの読み込みに必要なので消さない */
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12
  } = useHeartbeat();

  return (
    <GenericTemplate title={t('menu.page_name.ハートビート')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatePicker
            name={'selectDate'}
            label="基準日"
            value={searchValue.selectDate}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={resetSearch} disabled={startProcess}>
            {t('リセット')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_HEARTBEAT}
            locationNo={LOCATIONID_HEARTBEAT_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Box mt={1}>
        <Grid container spacing={4} justifyContent={'center'}>
          {searchValue?.heartBeats?.map((_, index) => {
            return (
              <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                {index + 1}
                <Card>
                  <CardContent
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '500px',
                      flexDirection: 'column'
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'start',
                        gap: '10px',
                        width: '100%',
                        padding: '10px'
                      }}
                    >
                      <Typography style={{fontSize: '14px', width: '90px'}}>ユーザ名</Typography>
                      <LFCSelectFormCompany
                        name={`company_id_${index}`}
                        value={searchValue?.heartBeats?.[index]?.companyId ?? ''}
                        onChange={event => {
                          setSearchValue(prevSearchValue => {
                            const newHeartBeats = [...prevSearchValue.heartBeats];
                            newHeartBeats[index] = {
                              ...newHeartBeats[index],
                              companyId: event.target.value
                            };
                            return {
                              ...prevSearchValue,
                              heartBeats: newHeartBeats
                            };
                          });
                        }}
                        required
                        fullWidth
                        label="ユーザ名"
                      />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'start',
                        gap: '10px',
                        width: '100%',
                        padding: '10px'
                      }}
                    >
                      <Typography style={{fontSize: '14px', width: '90px'}}>検査装置</Typography>
                      <LFCSelectFormHeartBeatDevice
                        disabled={!searchValue.heartBeats[index]?.companyId}
                        companyId={searchValue.heartBeats[index]?.companyId}
                        name={`device_id_${index}`}
                        value={searchValue.heartBeats[index]?.deviceId}
                        onChange={event => {
                          setSearchValue(prevSearchValue => {
                            const newHeartBeats = [...prevSearchValue.heartBeats];
                            newHeartBeats[index] = {
                              ...newHeartBeats[index],
                              deviceId: event.target.value
                            };
                            return {
                              ...prevSearchValue,
                              heartBeats: newHeartBeats
                            };
                          });
                        }}
                        required
                        fullWidth
                        label="検査装置"
                      />
                    </Box>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignSelf: 'start',
                        gap: '10px',
                        width: '100%',
                        padding: '10px'
                      }}
                    >
                      <Typography style={{fontSize: '14px', width: '90px'}}>
                        設定稼働時間
                      </Typography>
                      <LFCSelectFormHeartBeatWorkTime
                        name={`work_time_id_${index}`}
                        value={searchValue.heartBeats[index]?.workTime}
                        onChange={event => {
                          setSearchValue(prevSearchValue => {
                            const newHeartBeats = [...prevSearchValue.heartBeats];
                            newHeartBeats[index] = {
                              ...newHeartBeats[index],
                              workTime: event.target.value
                            };
                            return {
                              ...prevSearchValue,
                              heartBeats: newHeartBeats
                            };
                          });
                        }}
                        required
                        fullWidth
                        label="設定稼働時間"
                      />
                    </Box>
                    {searchValue.heartBeats[index]?.companyId &&
                    searchValue.heartBeats[index]?.deviceId &&
                    searchValue.heartBeats[index]?.workTime ? (
                      <Box width={'100%'}>
                        <LFCChartsBarForHeartBeat
                          title={''}
                          // eslint-disable-next-line no-eval
                          source={eval(`data${index + 1}`)}
                          x={{type: 'category', dsColumn: 'work_date'}}
                          y={{type: 'value', dsColumn: 'work_time_hours'}}
                          height={'300px'}
                        />
                      </Box>
                    ) : (
                      <Box color="red" marginTop={10}>
                        必須項目が未選択です
                      </Box>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default HeartBeatPage;
