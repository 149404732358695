import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';
import {ResultsSearchBar} from './components/ResultsSearchBar';
import {useResults} from './hooks/useResults';
import {ResultsDisplay} from './components/ResultsDisplay';
import {ResultsDetailDig} from './components/ResultsDetailDig';
import {Modal, Typography} from '@mui/material';
export const ResultsPage = () => {
  const {
    t,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    work,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    deviceid,
    doSearch,
    resetSearch,
    openSavedCondition,
    setOpenSavedCondition,
    condition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    onChangeShowLatestOnly,
    onChangeAutoReload,
    onSelectAutoReloadInterval,
    open,
    setOpen,
    displayNextData,
    displayPrevData,
    data,
    index,
    setWorkParam,
    setDeviceId,
    setSerial,
    setStartFetchingData,
    startFetchingData,
    workParam,
    deviceId,
    serial,
    serialNumber,
    openDataScreenShot,
    imageFullPath,
    handleClickDataScreenShot,
    openModal,
    setOpenModal,
    isDisplayNgOnly,
    toggleDisplayNgOnly,
    setOpenDataScreenShot
  } = useResults();
  console.log({openDataScreenShot});
  return (
    <GenericTemplate title={t('menu.page_name.検査結果一覧表示')}>
      <ProgressBar startProcess={startProcess} />
      <ResultsSearchBar
        serial={serial}
        deviceId={deviceId}
        startFetchingData={startFetchingData}
        setStartFetchingData={setStartFetchingData}
        workParam={workParam}
        index={index}
        formRef={formRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        work={work}
        autoOnChange={autoOnChange}
        autoCompleteReset={autoCompleteReset}
        setAutoCompleteReset={setAutoCompleteReset}
        deviceid={deviceid}
        doSearch={doSearch}
        resetSearch={resetSearch}
        openSavedCondition={openSavedCondition}
        setOpenSavedCondition={setOpenSavedCondition}
        condition={condition}
        onLoadSavedCondition={onLoadSavedCondition}
        onSelectSavedCondition={onSelectSavedCondition}
        onChangeShowLatestOnly={onChangeShowLatestOnly}
        onChangeAutoReload={onChangeAutoReload}
        onSelectAutoReloadInterval={onSelectAutoReloadInterval}
        t={t}
        setOpen={setOpen}
        open={open}
        displayNextData={displayNextData}
        displayPrevData={displayPrevData}
        data={data}
        setSerial={setSerial}
        setWorkParam={setWorkParam}
        setDeviceId={setDeviceId}
        serialNumber={serialNumber}
        isDisplayNgOnly={isDisplayNgOnly}
        toggleDisplayNgOnly={toggleDisplayNgOnly}
        setOpenDataScreenShot={setOpenDataScreenShot}
      />
      <ResultsDisplay
        setOpen={handleClickDataScreenShot}
        displayData={data[index]}
        isDisplayNgOnly={isDisplayNgOnly}
        openDataScreenShot={openDataScreenShot}
        imageFullPath={imageFullPath}
        work={searchValue.work}
      />
      <ResultsDetailDig
        deviceId={deviceId}
        setWorkParam={setWorkParam}
        setDeviceId={setDeviceId}
        setSerial={setSerial}
        setStartFetchingData={setStartFetchingData}
        open={open}
        setOpen={setOpen}
        onClose={() => setOpen(false)}
        uuid={data?.[index]?.[0]?.uuid}
        baseSearchValue={searchValue}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            width: '450px',
            height: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '10px',
            borderColor: 'grey',
            border: '1px solid'
          }}
        >
          <Typography fontSize="20px" fontWeight="bold">
            該当のシリアルNoのデータはありません
          </Typography>
        </div>
      </Modal>
    </GenericTemplate>
  );
};
