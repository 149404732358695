import {SelectChangeEvent} from '@mui/material';
import React from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
}

/**
 * 機種プルダウン
 * @param props
 * @returns
 */
const LFCSelectFormJudge = React.memo((props: Props) => {
  const {t} = useTranslation();
  const items: {name: string; value?: number}[] = [
    {name: t('すべて'), value: 9},
    {name: 'OK', value: 0},
    {name: 'NG', value: 1}
  ];

  return (
    <LFCSelectForm
      name={props.name}
      label={props.label ? props.label : t('総合判定')}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      margin={props.margin}
      required={props.required}
      size={props.size}
      multiple={props.multiple}
      selectItem={items}
      id={'sf_judge'}
    />
  );
});
export default LFCSelectFormJudge;
