import LFCButton from '_components/inputs/LFCButton';
import {aiChatAPIAccess, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem, {timelineItemClasses} from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Box,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  List,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const AIChatPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [source] = useState(axios.CancelToken.source()); // CancelTokenをページに１つ
  const [formValue, setFormValue] = useState({
    comment: ''
  });
  const [comments, setComments] = useState<{role: string; content: string}[]>([
    {
      role: 'system',
      content: '今から質問する事を、大阪弁でこたえてください.'
    }
  ]);
  const [threadId, setThreadId] = useState('');
  const [channelList, setChannelList] = useState([]);

  const [isCannelListLoading, setIsCannelListLoading] = useState(false);
  const [isThreadLoading, setIsThreadLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    thread_id: string
  ) => {
    setSelectedIndex(index);
    setThreadId(thread_id);
  };

  useEffect(() => {
    getCannelList(false);

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setComments([]);

    if (threadId === '') {
      return;
    }

    // 会話履歴を取得する
    setIsThreadLoading(true);

    aiChatAPIAccess({
      apipath: 'Chat',
      parameters: {thread_id: threadId, new_messages: []}
    })
      .then(res => {
        console.debug(res);
        if (res.data.messages.length !== 0) {
          setComments(res.data.messages);
        }
      })
      .catch(err => {
        console.error(err);
        setComments([
          {
            role: 'assistant',
            content: '( 問い合わせに失敗しました。もう一度質問をお願いします。 )'
          }
        ]);
      })
      .finally(() => {
        setIsThreadLoading(false);
      });
  }, [threadId]);

  useEffect(() => {
    //最後までスクロール
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [comments]);

  const getCannelList = (isResetFocus: boolean) => {
    setIsCannelListLoading(true);
    aiChatAPIAccess({
      apipath: 'GetChannelList',
      parameters: {}
    })
      .then(res => {
        console.debug(res);
        setChannelList(res.data.channel_list);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setIsCannelListLoading(false);
      });

    if (isResetFocus) {
      setSelectedIndex(-1);
    }
  };

  const onChat = () => {
    // 新しい質問を表示する
    const newcom = [
      {
        role: 'user',
        content: formValue.comment
      }
    ];
    setComments([...comments, ...newcom]);
    setFormValue({...formValue, comment: ''});
    setIsLoading(true);

    // 新しい質問をAIに質問する
    aiChatAPIAccess({
      apipath: 'Chat',
      parameters: {
        thread_id: threadId,
        new_messages: newcom
      }
    })
      .then(res => {
        console.debug(res);
        if (res.data.messages.length !== 0) {
          //回答を反映
          setComments(res.data.messages);
          if (threadId === '') {
            setThreadId(res.data.thread_id);
            getCannelList(true);
          }
        }
      })
      .catch(err => {
        console.error(err);
        setComments([
          ...comments,
          ...newcom,
          {
            role: 'assistant',
            content: '( 問い合わせに失敗しました。もう一度質問をお願いします。 )'
          }
        ]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <GenericTemplate title={'AI Chat'}>
      <Box m={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Chip label="Channel List" />
                  <IconButton
                    color="primary"
                    onClick={() => {
                      getCannelList(false);
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                {isCannelListLoading ? (
                  <Box sx={{width: '100%'}}>
                    <LinearProgress />
                  </Box>
                ) : null}
                <List
                  dense
                  sx={{
                    width: '100%',
                    height: '67vh',
                    overflow: 'auto',
                    border: 1,
                    borderColor: 'divider',
                    borderRadius: 1
                  }}
                >
                  {channelList.map((r, idx) => {
                    return (
                      <ListItem key={`lid_${idx}`} disablePadding>
                        <ListItemButton
                          selected={selectedIndex === idx}
                          onClick={event => handleListItemClick(event, idx, r['thread_id'])}
                        >
                          <ListItemText primary={`${r['create_dt']}`} sx={{width: '50%'}} />
                          <ListItemText primary={`${r['title']}`} sx={{width: '100%'}} />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>
              <Grid item xs={12}>
                <LFCButton
                  color="primary"
                  onClick={() => {
                    setThreadId('');
                  }}
                >
                  New Channel
                </LFCButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Chip label="Thread" />
              </Grid>
              <Grid
                item
                xs={12}
                ref={scrollRef}
                sx={{
                  height: '67vh',
                  overflowY: 'auto',
                  border: 0,
                  borderColor: 'divider',
                  borderRadius: 1
                }}
              >
                {isThreadLoading ? (
                  <Box sx={{width: '100%'}}>
                    <LinearProgress />
                  </Box>
                ) : null}
                <Timeline
                  sx={{
                    [`& .${timelineItemClasses.root}:before`]: {
                      flex: 0,
                      padding: 0
                    },
                    marginBlockStart: 0
                  }}
                >
                  {comments.map((item: any, index: number) => {
                    const icolor = item.role === 'assistant' ? 'secondary' : 'success';
                    const iDot = item.role === 'assistant' ? <SupportAgentIcon /> : <PersonIcon />;
                    return (
                      <TimelineItem key={'tl_' + index}>
                        <TimelineSeparator>
                          <TimelineDot color={icolor}>{iDot}</TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Typography variant="h6" color={icolor} component="span">
                            {item.role}
                          </Typography>
                          <Typography
                            variant="body2"
                            color={icolor}
                            sx={{whiteSpace: 'pre-line', lineHeight: 1.1}}
                          >
                            {item.content}
                          </Typography>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  })}
                  {isLoading ? (
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot color={'secondary'}>
                          <SupportAgentIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <Skeleton variant="rounded" height={60} />
                      </TimelineContent>
                    </TimelineItem>
                  ) : null}
                </Timeline>
              </Grid>
              <Grid item xs={12} mt={1}>
                <TextField
                  name="comment"
                  label="Comment"
                  multiline
                  rows={4}
                  fullWidth
                  value={formValue.comment}
                  // onKeyDown={event => {
                  //   if (event.key === 'Enter') {
                  //     onChat();
                  //   }
                  // }}
                  onChange={event => {
                    handleInputChange(event, formValue, setFormValue);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <LFCButton color="primary" onClick={onChat} disabled={isLoading}>
                  Send
                </LFCButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default AIChatPage;
