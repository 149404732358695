import {useTheme} from '@mui/material';
import {histogram} from 'echarts-stat';
import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';
import {formatTwoNumbersInString} from 'utils/math-utilities';

interface SourceType {
  exec_time: string;
  cycle_time: number;
}
interface ExportFieldsType {
  exec_time: string;
  cycle_time: string;
}

/**
 * 引数
 */
interface Props {
  title: string;
  source: SourceType[];
  exportFields: ExportFieldsType;
  exportFilename?: string;
}

/**
 * LFCChartsWorkingInterval
 * 作業時間分布
 * @param props
 * @returns
 */
const LFCChartsWorkingInterval = (props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [sourceEx, setSourceEx] = useState<(string | number)[][]>([]);

  useEffect(() => {
    const times = props.source.map((item: SourceType) => Math.round(item.cycle_time));
    const tmp = times.length ? histogram(times, 'squareRoot').data : [];
    const filteredArray = tmp.filter(subArray =>
      subArray.every(element => {
        if (typeof element === 'number') {
          return !isNaN(element);
        }
        return typeof element === 'string';
      })
    );

    const _sourceEx = filteredArray.map(e => {
      return e.map(f => {
        if (typeof f === 'number') {
          return parseFloat(f.toFixed(2));
        } else {
          return formatTwoNumbersInString(f);
        }
      });
    });
    setSourceEx(_sourceEx);
  }, [props.source]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            left: 50,
            right: 5
          },
          color: [theme.palette.primary.main],
          dataset: [
            {
              source: sourceEx
            }
          ],
          xAxis: {
            type: 'category',
            name: `${t('作業時間')}(${t('秒')})`,
            nameGap: 40,
            nameLocation: 'middle',
            minInterval: 1,
            axisLabel: {
              rotate: '20'
            }
          },
          yAxis: {
            type: 'value',
            name: t('生産数'),
            minInterval: 1
          },
          series: [
            {
              type: 'bar',
              label: {
                show: true
              },
              barWidth: '99.3%',
              encode: {x: 4, y: 1}
            }
          ],
          tooltip: {
            trigger: 'item'
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ]
        }}
        exportData={props.source}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
      />
    </div>
  );
};
export default LFCChartsWorkingInterval;
