import 'echarts-gl';
import LFCBaseCharts from '../../charts/base/LFCBaseCharts';
import {RobotPaths} from 'pages/weldingShow/types';
import dayjs from 'dayjs';

interface Props {
  data: RobotPaths[];
}

export const WeldingShow3dEcharts = ({data}: Props) => {
  const colorMap: {[key: string]: string} = {};

  const processedData = data.flatMap((dataItem, dataIndex) =>
    dataItem.nodes.map((node, index) => {
      const lq_time = dataItem.lq_time;
      if (!colorMap[lq_time]) {
        colorMap[lq_time] = `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(
          Math.random() * 256
        )}, ${Math.floor(Math.random() * 256)})`;
      }

      return {
        node_x: node[0],
        node_y: node[1],
        node_z: node[2],
        lq_time: lq_time,
        dataIndex: dataIndex,
        color: colorMap[lq_time]
      };
    })
  );

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: '3D Scatter with Dataset'
        },
        xAxis3D: {},
        yAxis3D: {},
        zAxis3D: {},
        grid3D: {
          boxWidth: 200,
          boxDepth: 80,
          viewControl: {
            autoRotate: false,
            distance: 300
          },
          axisPointer: {
            lineStyle: {
              color: '#ffbd67'
            }
          }
        },
        dataset: {
          dimensions: ['node_x', 'node_y', 'node_z', 'lq_time', 'dataIndex'],
          source: processedData
        },
        series: [
          {
            type: 'scatter3D',
            symbolSize: 5,
            encode: {
              x: 'node_x',
              y: 'node_y',
              z: 'node_z',
              itemStyle: {
                color: 'color'
              }
            },
            itemStyle: {
              color: (params: any) => {
                return colorMap[params.data.lq_time];
              }
            },
            emphasis: {
              label: {
                show: true,
                formatter: (params: {data: RobotPaths}) =>
                  dayjs(params.data.lq_time).format('HH:mm')
              }
            }
          }
        ],
        legend: {
          type: 'scroll',
          data: Object.keys(colorMap).map(lq_time => ({
            name: lq_time,
            textStyle: {
              color: colorMap[lq_time]
            }
          }))
        }
      }}
      height="400px"
      style={{width: '100%'}}
    />
  );
};
