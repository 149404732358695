import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

/**
 * DB構造チェック
 * @returns
 */
const DbCheckPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [checkresult, setCheckresult] = useState<any>([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * チェック処理
   * @returns
   */
  const onSearch = () => {
    // 会社情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(data => {
      // 会社IDと会社名
      const promiseRes = data.map(async (item: any, idx: number) => {
        const company_id = String(item['id']);
        const company_name = String(item['name']);

        try {
          const response = await adminAPIAccess({
            apipath: 'CorpDbCheck',
            parameters: {
              company_id_from: 1,
              company_id_to: company_id
            }
          });
          // エラー判定
          let re = {mes: '', resEx: ''};
          switch (response.data.result_code) {
            case 0:
              re['mes'] = `OK`;
              break;
            case -1:
              console.log(response.data.result_ex);
              re['mes'] = response.data.result_ex[0];
              re['resEx'] = response.data.result_ex[1];
              break;
            default:
              re['mes'] = '比較処理失敗';
              break;
          }
          return {company_id: company_id, company_name: company_name, ...re};
        } catch {
          return {
            company_id: company_id,
            company_name: company_name,
            ...{mes: '比較処理失敗', resEx: ''}
          };
        }
      });

      Promise.all(promiseRes).then(resarr => {
        console.log(resarr);
        setCheckresult(resarr);
      });
    });
  };

  useEffect(() => {
    // マウント時処理
    onSearch();
  }, []);

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <GenericTemplate title={t('会社毎DBチェック')}>
      <form ref={formRef}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {t('message.lfc-linkwizと各社DBのテーブル構造の差異を参照する画面です。')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <LFCButton color="primary" onClick={onSearch}>
              {t('message.最新の情報に更新')}
            </LFCButton>
          </Grid>
          {checkresult.map((row: any, index: number) => {
            return (
              <Grid item xs={6}>
                <Accordion
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="body1" sx={{width: '40%', flexShrink: 0}}>
                      {row['company_name']}
                    </Typography>
                    {row['mes'] === 'OK' ? (
                      <Typography variant="subtitle1">{row['mes']}</Typography>
                    ) : (
                      <Typography variant="subtitle1" color="secondary">
                        {row['mes']}
                      </Typography>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    {row['resEx'].split(',').map((r: String) => {
                      return (
                        <Typography variant="body2" component="p">
                          {r}
                        </Typography>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            );
          })}
        </Grid>
      </form>
    </GenericTemplate>
  );
};

export default DbCheckPage;
