import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  datas: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  style?: any;
  height?: string;
  x_middle?: number;
  y_middle?: number;
  top?: string;
  left?: string;
  xaxisName: string;
  yaxisName: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsScatter
 * @param props
 * @returns
 */
const LFCChartsScatter2 = (props: Props) => {
  // const renderFlgRef = useRef(false);

  let makeLineObject: any = undefined;
  if (props.x_middle || props.y_middle) {
    makeLineObject = {
      data: [
        {
          name: props.x_middle !== undefined ? props.x_middle : undefined,
          label: {position: 'insideEnd'},
          xAxis: props.x_middle !== undefined ? props.x_middle : undefined,
          lineStyle: {
            type: 'dashed',
            color: 'red'
          }
        },
        {
          name: props.y_middle !== undefined ? props.y_middle : undefined,
          label: {position: 'insideEnd'},
          yAxis: props.y_middle !== undefined ? props.y_middle : undefined,
          lineStyle: {
            type: 'dashed',
            color: 'yellow'
          }
        }
      ],
      symbol: 'none',
      silent: false
    };
  }

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: [
          {
            top: '15%',
            left: '10%',
            right: '5%',
            bottom: '20%'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: function (params: any) {
            return params[0].value[2] + '</br>' + params[0].value[0] + '</br>' + params[0].value[1];
          }
        },
        xAxis: {
          type: 'value',
          name: props.xaxisName,
          nameLocation: 'middle',
          nameRotate: 0,
          nameTextStyle: {
            padding: [20, 0, 0, 0]
          },
          min: 'dataMin',
          max: 'dataMax',
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 10
          }
        },
        yAxis: {
          type: 'value',
          name: props.yaxisName,
          nameLocation: 'middle',
          nameRotate: 0,
          nameTextStyle: {
            padding: [0, 20, 0, 0]
          },
          min: 'dataMin',
          max: 'dataMax',
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 10
          }
        },
        series: [
          {
            type: 'scatter',
            data: props.datas,
            symbolSize: 7,
            markLine: makeLineObject
          }
        ],
        dataZoom: [
          {
            type: 'slider',
            show: true,
            xAxisIndex: [0],
            start: 0,
            end: 100,
            height: 12,
            top: 28
          },
          {
            type: 'slider',
            show: true,
            yAxisIndex: [0],
            start: 0,
            end: 100,
            width: 12,
            left: props.left !== undefined ? props.left : '90%'
          },
          {
            type: 'inside',
            xAxisIndex: [0],
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            yAxisIndex: [0],
            start: 0,
            end: 100
          }
        ]
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
      style={props.style}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
    />
  );
};
export default LFCChartsScatter2;
