import {useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  yAxisName: string;
  inputScale?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarSearchDay02 = (props: Props) => {
  const {t} = useTranslation();
  let x_list: any =
    props.source.length > 0 ? props.source.map((item: any) => item['cycle_time']) : [];
  let y_value: any =
    props.source.length > 0 ? props.source.map((item: any) => item['カウント数']) : [];

  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            name: t('検査間隔(秒)'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            data: x_list,
            interval: 1,
            axisLabel: {
              rotate: '20',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName,
            minInterval: 1,
            // interval: 100,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              data: y_value,
              type: 'bar'
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return (
                prm.name +
                t('秒') +
                '<br>' +
                t('検査数') +
                ':' +
                prm.value +
                t('件') +
                '<br>' +
                t('構成比') +
                ':' +
                (
                  (prm.value / y_value.reduce((sum: number, item: any) => sum + item, 0)) *
                  100
                ).toFixed(1) +
                '%'
              );
            }
            // position: "inside",
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: 80
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBarSearchDay02;
