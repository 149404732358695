import {Tooltip, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';

const ExcelJS = require('exceljs');

/**
 * 引数
 */
interface Props {
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  color?: 'inherit' | 'primary' | 'secondary' | 'default' | undefined;
  size?: 'small' | 'medium' | 'large' | undefined;
  className?: string | undefined;
  data: any;
  fields: any;
  filename: string | undefined;
}

export const clickButtonFromChart = async (props: Props, fileType: string) => {
  if (props.data !== undefined) {
    const fields_data = [];
    for (const aKey in props.fields) {
      if (props.fields[aKey].columnName) {
        fields_data.push({
          header: props.fields[aKey].columnName,
          key: props.fields[aKey].columnField
        });
      } else {
        fields_data.push({header: props.fields[aKey], key: aKey});
      }
    }

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('export_data');
    const worksheet = workbook.getWorksheet('export_data');
    worksheet.columns = fields_data;

    for (const list_data in props.data) {
      worksheet.addRow(props.data[list_data]);
    }
    const uint8Array =
      fileType === 'excel'
        ? await workbook.xlsx.writeBuffer()
        : null
          ? await workbook.csv.writeBuffer()
          : new Uint8Array(await workbook.csv.writeBuffer());

    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileType === 'excel' ? props.filename + '.xlsx' : props.filename + '.csv';
    a.click();
    a.remove();
  }
};

export const clickButtonAsync = async (props: Props, fileType: string) => {
  if (props.data !== undefined) {
    const fields_data = [];
    for (const aKey in props.fields) {
      if (props.fields[aKey].columnName) {
        fields_data.push({
          header: props.fields[aKey].columnName,
          key: props.fields[aKey].columnField
        });
      } else {
        fields_data.push({header: props.fields[aKey], key: aKey});
      }
    }
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('export_data');
    const worksheet = workbook.getWorksheet('export_data');
    worksheet.columns = fields_data;

    for (const list_data in props.data) {
      worksheet.addRow(props.data[list_data]);
    }
    const uint8Array =
      fileType === 'excel'
        ? await workbook.xlsx.writeBuffer()
        : null
          ? await workbook.csv.writeBuffer()
          : new Uint8Array(await workbook.csv.writeBuffer());

    const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileType === 'excel' ? props.filename + '.xlsx' : props.filename + '.csv';
    a.click();
    a.remove();
  }
};

export const ExportExcel = (props: any) => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip title={t('Excel出力')} arrow>
      <span
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          margin: '5px'
        }}
        className={props.className !== undefined ? props.className : undefined}
        onClick={e => clickButtonAsync(props, 'excel')}
      >
        <svg width="20" height="20" style={{fill: theme.palette.text.secondary}}>
          <path d="M15.123,9.991c-0.944,0-1.71,0.766-1.71,1.71c0,0.945,0.766,1.711,1.71,1.711c0.945,0,1.711-0.766,1.711-1.711 C16.834,10.756,16.068,9.991,15.123,9.991z M15.703,12.281h-1.141v-1.141h1.141V12.281z M17.984,4.867h-2.288v-0.57h-0.024 c0.011-0.086,0.025-0.17,0.025-0.26V2.852c0-1.092-0.838-1.977-1.871-1.977H2.745C1.8,0.875,1.027,1.618,0.9,2.58H0.875v15.404 c0,0.629,0.511,1.141,1.141,1.141h15.969c0.629,0,1.14-0.512,1.14-1.141V6.008C19.124,5.377,18.613,4.867,17.984,4.867z M2.016,2.586c0-0.315,0.255-0.57,0.57-0.57h11.406c0.314,0,0.57,0.255,0.57,0.57v2.275H2.016V2.586z M17.984,17.414 c0,0.314-0.257,0.57-0.57,0.57H2.586c-0.315,0-0.57-0.256-0.57-0.57V6.578c0-0.315,0.255-0.57,0.57-0.57h14.828 c0.313,0,0.57,0.255,0.57,0.57V17.414z" />
        </svg>
      </span>
    </Tooltip>
  );
};

export const ExportCsv = (props: any) => {
  const {t} = useTranslation();
  const theme = useTheme();
  return (
    <Tooltip title={t('CSV出力')} arrow>
      <span
        style={{
          cursor: 'pointer',
          display: 'inline-flex',
          margin: '5px'
        }}
        className={props.className !== undefined ? props.className : undefined}
        onClick={e => clickButtonAsync(props, 'csv')}
      >
        <svg width="20" height="20" style={{fill: theme.palette.text.secondary}}>
          <path d="M5.029,1.734h10.935c0.317,0,0.575-0.257,0.575-0.575s-0.258-0.576-0.575-0.576H5.029 c-0.318,0-0.576,0.258-0.576,0.576S4.711,1.734,5.029,1.734z M1,5.188V19h18.417V5.188H1z M18.266,17.849H2.151V6.338h16.115 V17.849z M2.727,4.036H17.69c0.317,0,0.575-0.257,0.575-0.576c0-0.318-0.258-0.575-0.575-0.575H2.727 c-0.318,0-0.576,0.257-0.576,0.575C2.151,3.779,2.409,4.036,2.727,4.036z" />
        </svg>
      </span>
    </Tooltip>
  );
};
