import {Box, Divider, Dialog, DialogContent, Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {memo, useCallback, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import LFCChartsBarSearchMonth from '_components/charts/LFCChartsBarSearchMonth';
import LFCChartsBarSearchMonthStack from '_components/charts/LFCChartsBarSearchMonthStack';
import LFCChartsBarSearchMonthStackJobtype from '_components/charts/LFCChartsBarSearchMonthStackJobtype';
import LFCChartsBoxSearchByMonth from '_components/charts/LFCChartsBoxSearchByMonth';
import LFCChartsComboYMD from '_components/charts/LFCChartsComboYMD';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN, PAGEID_LQ_SEARCH_BY_MONTH} from '_logics/LFCPageId';
import {distinct, getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import PieChartSearchByMonth from './search-chart/PieChartSearchByMonth';
import PieChartSearchByMonthJobtype from './search-chart/PieChartSearchByMonthJobtype';
import {useTranslation} from 'react-i18next';
import DateTimePicker from '_components/inputs/DateTimePicker';

const SearchByMonthPage = memo(() => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(true);

  const [days_list, setDays_list] = useState([]);

  const initialSearchValue = {
    select_year_month:
      new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2),
    select_date_from: new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/1',
    select_date_to:
      new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getDate(),
    select_date_from_1: '',
    select_date_to_1: '',
    select_date_from_2: '',
    select_date_to_2: '',
    select_date_from_3: '',
    select_date_to_3: '',
    work: [],
    cycle_time_from: 50,
    cycle_time_to: 200
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LQ_SEARCH_BY_MONTH,
    LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const [data60333, setData60333] = useState({
    work: '',
    '不良品/日数': '',
    不良品数: '',
    不良率: '',
    平均生産間隔: '',
    数量総合計: '',
    日数: '',
    '総数/日数': '',
    '良品/日数': '',
    良品数: ''
  });

  const [data60335, setData60335] = useState([]);
  const [data60336, setData60336] = useState([]);
  const [data6033700, setData6033700] = useState([]);
  const [data6033700_show, setData6033700_show] = useState([]);
  const [data60338, setData60338] = useState([]);
  const [data60339, setData60339] = useState([]);
  const [data603381, setData603381] = useState([]);
  const [data603391, setData603391] = useState([]);
  const [data60346, setData60346] = useState([]);
  const [data603331, setData603331] = useState([]);
  const [data603332, setData603332] = useState([]);
  const [data603333, setData603333] = useState([]);
  const [data603335, setData603335] = useState([]);

  const [openResponse, setOpenResponse] = useState(false);
  const handleClose = () => setOpenResponse(false);

  const makeDaysList = () => {
    let tmp_day = new Date(searchValue.select_date_from);
    let tmp_days_list: any = [];
    for (let i = 1; i <= 31; i++) {
      tmp_day.setDate(+i);
      if (tmp_day.getMonth() + 1 === new Date(searchValue.select_date_from).getMonth() + 1) {
        tmp_days_list.push(
          ('0' + (tmp_day.getMonth() + 1)).slice(-2) + '/' + ('0' + tmp_day.getDate()).slice(-2)
        );
      }
    }
    setDays_list(tmp_days_list);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    let tmp_end_date: any = new Date(searchValue.select_year_month);
    tmp_end_date.setMonth(tmp_end_date.getMonth() + 1);
    tmp_end_date.setDate(0);
    setSearchValue({
      ...searchValue,
      select_date_from: tmp_end_date.getFullYear() + '/' + (tmp_end_date.getMonth() + 1) + '/1',
      select_date_to:
        tmp_end_date.getFullYear() +
        '/' +
        (tmp_end_date.getMonth() + 1) +
        '/' +
        tmp_end_date.getDate()
    });
  }, [searchValue.select_year_month]);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    makeDaysList();
    apiFetch().then(() => setStartProcess(false));
  };

  const apiFetch = useCallback(async () => {
    setData60335([]);
    setData6033700([]);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60335,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60335,
      name: `LROBOT:${t('月間集計')}:${t('実績と不良率グラフ')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60336,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        cycle_time_from: searchValue.cycle_time_from,
        cycle_time_to: searchValue.cycle_time_to
      },
      ds_state: setData60336,
      name: `LROBOT:${t('月間集計')}:${t('サイクルタイム推移')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 6033700,
      parameters: {
        select_date_from:
          searchValue.select_date_from_1 !== ''
            ? searchValue.select_date_from_1
            : searchValue.select_date_from,
        select_date_to:
          searchValue.select_date_to_1 !== ''
            ? searchValue.select_date_to_1
            : searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        cycle_time_from: searchValue.cycle_time_from,
        cycle_time_to: searchValue.cycle_time_to
      },
      ds_state: setData6033700,
      name: `LROBOT:${t('年間集計')}:${t('生産間隔')} X ${t('頻度')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60338,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60338,
      name: `LROBOT:${t('月間集計')}:${t('月間集計')}_${t('部位別不良率')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603381,
      parameters: {
        select_date_from:
          searchValue.select_date_from_2 !== ''
            ? searchValue.select_date_from_2
            : searchValue.select_date_from,
        select_date_to:
          searchValue.select_date_to_2 !== ''
            ? searchValue.select_date_to_2
            : searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603381,
      name: `LROBOT:${t('月間集計')}:${t('月間集計')}_${t('部位別不良率')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60339,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60339,
      name: `LROBOT:${t('月間集計')}:${t('検査手法別')}_${t('不良数推移')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603391,
      parameters: {
        select_date_from:
          searchValue.select_date_from_3 !== ''
            ? searchValue.select_date_from_3
            : searchValue.select_date_from,
        select_date_to:
          searchValue.select_date_to_3 !== ''
            ? searchValue.select_date_to_3
            : searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603391,
      name: `LROBOT:${t('月間集計')}:${t('検査手法別')}_${t('不良数推移')}`,
      cancelToken: source.token,
      t
    });

    let tmp_array: any = searchValue.select_date_from.split('/');
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60346,
      parameters: {
        select_date: tmp_array[0] + '/' + ('0' + tmp_array[1]).slice(-2),
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60346,
      name: `LROBOT:${t('月間集計')}:${t('データ明細リスト')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603331,
      parameters: {
        select_date: searchValue.select_date_from,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603331,
      name: `LROBOT_${t('月間集計')}_${t('合計平均')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603332,
      parameters: {
        select_date: searchValue.select_date_from,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603332,
      name: `LROBOT_${t('月間集計')}_${t('合計平均')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603333,
      parameters: {
        select_date: searchValue.select_date_from,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603333,
      name: `LROBOT_${t('月間集計')}_${t('合計平均')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 603335,
      parameters: {
        select_date: searchValue.select_date_from,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData603335,
      name: `LROBOT_${t('月間集計')}_${t('合計平均')}`,
      cancelToken: source.token,
      t
    });
  }, [searchValue]);

  useEffect(() => {
    setData60333({
      work: '',
      '不良品/日数':
        data603331.length > 0 && data603333.length > 0
          ? (data603333[0]['不良品数'] / data603332[0]['日数']).toFixed(1)
          : '',
      不良品数: data603333.length > 0 ? data603333[0]['不良品数'] : '',
      不良率:
        data603333.length > 0 && data603331.length > 0
          ? ((data603333[0]['不良品数'] / data603331[0]['数量総合計']) * 100).toFixed(1)
          : '',
      平均生産間隔: data603335.length > 0 ? data603335[0]['平均生産間隔'] : '',
      数量総合計: data603331.length > 0 ? data603331[0]['数量総合計'] : '',
      日数: data603332.length > 0 ? data603332[0]['日数'] : '',
      '総数/日数':
        data603331.length > 0 && data603332.length > 0
          ? String((data603331[0]['数量総合計'] / data603332[0]['日数']).toFixed(1))
          : '',
      '良品/日数':
        data603331.length > 0 && data603333.length > 0
          ? (
              (data603331[0]['数量総合計'] - data603333[0]['不良品数']) /
              data603332[0]['日数']
            ).toFixed(1)
          : '',
      良品数:
        data603331.length > 0 && data603333.length > 0
          ? String(data603331[0]['数量総合計'] - data603333[0]['不良品数'])
          : ''
    });
  }, [data603331, data603332, data603333, data603335]);

  const handleChangeCycleTime = (event: any) => {
    if (Number(event.target.value)) {
      setSearchValue({...searchValue, [event.target.name]: event.target.value});
    }
    if (event.target.value === '') {
      setSearchValue({...searchValue, [event.target.name]: ''});
    }
  };

  const handleBlurCycleTime = (event: any) => {
    if (event.target.value === '') {
      setSearchValue({...searchValue, [event.target.name]: 0});
    }
  };

  const [data60336_box, setData60336_box] = useState<any>({
    data_value: [],
    x_list: []
  });

  useEffect(() => {
    let tmp_datas: any = {
      x_list: distinct(data60336, 'lq_time'),
      data_value: []
    };
    tmp_datas.x_list.forEach((date_string: string, index: number) => {
      tmp_datas.data_value[index] = [];
      tmp_datas.data_value[index] = data60336
        .filter((item: any) => item.lq_time === date_string)
        .map((filter_item: any) => filter_item.cycle_time);
    });
    setData60336_box(tmp_datas);
    setStartProcess(false);
  }, [data60336]);

  const graphClick_gotoDay = (data: any) => {
    if (data.componentType === 'series') {
      history.push({pathname: '/l-qualify/search-by-day', state: {select_date: data.name}});
    }
  };

  const graphClick_01_selectDate = (data: any) => {
    setStartProcess(true);
    if (data !== '') {
      let tmp_year: string = searchValue.select_date_from.split('/')[0];
      let tmp_select_date: any = new Date(tmp_year + '/' + data.name);
      let tmp_to_date: any = new Date(tmp_year + '/' + data.name);
      tmp_to_date.setDate(tmp_select_date.getDate() + 1);
      setSearchValue({
        ...searchValue,
        select_date_from_1:
          tmp_select_date.getFullYear() +
          '/' +
          (tmp_select_date.getMonth() + 1) +
          '/' +
          tmp_select_date.getDate()
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 6033700,
        parameters: {
          select_date_from:
            tmp_select_date.getFullYear() +
            '/' +
            (tmp_select_date.getMonth() + 1) +
            '/' +
            tmp_select_date.getDate(),
          select_date_to:
            tmp_to_date.getFullYear() +
            '/' +
            (tmp_to_date.getMonth() + 1) +
            '/' +
            tmp_to_date.getDate(),
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          cycle_time_from: searchValue.cycle_time_from,
          cycle_time_to: searchValue.cycle_time_to
        },
        ds_state: setData6033700,
        name: `LROBOT:${t('年間集計')}:${t('生産間隔')} X ${t('頻度')}`,
        cancelToken: source.token,
        t
      });
    } else {
      setSearchValue({...searchValue, select_date_from_1: ''});
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 6033700,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          cycle_time_from: searchValue.cycle_time_from,
          cycle_time_to: searchValue.cycle_time_to
        },
        ds_state: setData6033700,
        name: `LROBOT:${t('年間集計')}:${t('生産間隔')} X ${t('頻度')}`,
        cancelToken: source.token,
        t
      });
    }
    setStartProcess(false);
  };

  const graphClick_02_selectDate = (data: any) => {
    setStartProcess(true);
    if (data !== '') {
      let tmp_year: string = searchValue.select_date_from.split('/')[0];
      let tmp_select_date: any = new Date(tmp_year + '/' + data.name);
      setSearchValue({
        ...searchValue,
        select_date_from_2:
          tmp_select_date.getFullYear() +
          '/' +
          (tmp_select_date.getMonth() + 1) +
          '/' +
          tmp_select_date.getDate()
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 603382,
        parameters: {
          select_date:
            tmp_select_date.getFullYear() +
            '/' +
            (tmp_select_date.getMonth() + 1) +
            '/' +
            tmp_select_date.getDate(),
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        ds_state: setData603381,
        name: `LROBOT:${t('月間集計')}:${t('月間集計')}_${t('部位別不良率')}`,
        cancelToken: source.token,
        t
      });
    } else {
      setSearchValue({...searchValue, select_date_from_2: ''});
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 603381,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        ds_state: setData603381,
        name: `LROBOT:${t('月間集計')}:${t('月間集計')}_${t('部位別不良率')}`,
        cancelToken: source.token,
        t
      });
    }
    setStartProcess(false);
  };

  const graphClick_03_selectDate = (data: any) => {
    setStartProcess(true);
    if (data !== '') {
      let tmp_year: string = searchValue.select_date_from.split('/')[0];
      let tmp_select_date: any = new Date(tmp_year + '/' + data.name);
      setSearchValue({
        ...searchValue,
        select_date_from_3:
          tmp_select_date.getFullYear() +
          '/' +
          (tmp_select_date.getMonth() + 1) +
          '/' +
          tmp_select_date.getDate()
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 603392,
        parameters: {
          select_date:
            tmp_select_date.getFullYear() +
            '/' +
            (tmp_select_date.getMonth() + 1) +
            '/' +
            tmp_select_date.getDate(),
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        ds_state: setData603391,
        name: `LROBOT:${t('月間集計')}:${t('検査手法別')}_${t('不良数推移')}`,
        cancelToken: source.token,
        t
      });
    } else {
      setSearchValue({...searchValue, select_date_from_3: ''});
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 603391,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
        },
        ds_state: setData603391,
        name: `LROBOT:${t('月間集計')}:${t('検査手法別')}_${t('不良数推移')}`,
        cancelToken: source.token,
        t
      });
    }
    setStartProcess(false);
  };

  useEffect(() => {
    let index_list: any = [];
    if (data6033700.length > 0) {
      let data_start: any = data6033700[0];
      let data_end: any = data6033700[data6033700.length - 1];
      for (let i: number = Number(data_start.index); data_end.index >= i; i++) {
        index_list.push(i);
      }
    }

    let tmp: any = [];
    index_list.forEach((index_number: number) => {
      if (data6033700.find((item: any) => item.index === String(index_number))) {
        let tmp_data: any = data6033700.find((item: any) => item.index === String(index_number));
        tmp.push({
          index: String(index_number),
          cycle_time: tmp_data['cycle_time'],
          カウント数: tmp_data['カウント数']
        });
      } else {
        tmp.push({
          index: String(index_number),
          cycle_time: String(index_number * 10) + '_' + String(index_number * 10 + 9),
          カウント数: 0
        });
      }
    });
    setData6033700_show(tmp);
  }, [data6033700]);

  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  let responseData004_column = {
    lq_time: t('日時'),
    total_count: t('検査数'),
    ng_par: t('不良率'),
    ng: t('不良数')
  };
  let data60336_column = {
    lq_time: t('日時'),
    cycle_time: t('サイクルタイム')
  };
  let data6033700_column = {
    index: t('連番'),
    cycle_time: t('サイクルタイム'),
    カウント数: t('カウント')
  };
  let data60338_column = {
    lq_time: t('日時'),
    map: t('マップ'),
    ng_count: t('不良数')
  };
  let data60339_column = {
    lq_time: t('日時'),
    job_type_name: t('ジョブタイプ名称'),
    ng_count: t('不良数')
  };

  const monthSelectChange = (event: any) => {
    setSearchValue({
      ...searchValue,
      select_year_month: event.format('YYYY-MM'),
      select_date_from_1: '',
      select_date_to_1: '',
      select_date_from_2: '',
      select_date_to_2: '',
      select_date_from_3: '',
      select_date_to_3: ''
    });
  };

  useEffect(() => {
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'cycle_time_from',
        value: searchValue.cycle_time_from,
        valueLabel: searchValue.cycle_time_from,
        colName: t('検査間隔From'),
        colWidth: 150
      },
      {
        name: 'cycle_time_to',
        value: searchValue.cycle_time_to,
        valueLabel: searchValue.cycle_time_to,
        colName: t('検査間隔To'),
        colWidth: 150
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={`${t('menu.page_name.月間集計')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_year_month)}
            format="YYYY/MM"
            views={['month', 'year']}
            onChange={event => {
              monthSelectChange(event);
            }}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCTextField
            name={'cycle_time_from'}
            label={t('検査間隔')}
            value={searchValue.cycle_time_from}
            onChange={handleChangeCycleTime}
            onBlur={handleBlurCycleTime}
            fullWidth={false}
            other={{
              required: true,
              inputProps: {pattern: '[0-9]*'}
            }}
            style={{width: '80px'}}
          />
          <div style={{lineHeight: '40px', verticalAlign: 'middle'}}>〜</div>
          <LFCTextField
            name={'cycle_time_to'}
            label={t('検査間隔')}
            value={searchValue.cycle_time_to}
            onChange={handleChangeCycleTime}
            onBlur={handleBlurCycleTime}
            fullWidth={false}
            other={{
              required: true,
              inputProps: {pattern: '[0-9]*'}
            }}
            style={{width: '80px'}}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LQ_SEARCH_BY_MONTH}
            locationNo={LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} textAlign="right">
            <LFCButton color="secondary" onClick={() => setOpenResponse(true)}>
              {t('明細')}
            </LFCButton>
          </Grid>

          <Grid item xs={12} md={8}>
            <LFCChartsComboYMD
              title={t('検査数と不良率')}
              source={data60335}
              x={{
                startDate: dayjs(searchValue.select_date_from),
                endDate: dayjs(searchValue.select_date_to)
              }}
              yLeft={{
                type: 'bar',
                name: `${t('検査数')}(${t('個')})`,
                dsColumn: 'total_count'
              }}
              yRight={{
                type: 'line',
                name: `${t('不良率')}(%)`,
                dsColumn: 'ng_par',
                markPoint: {
                  data: [
                    {
                      type: 'min',
                      itemStyle: {color: '#E91E63'},
                      label: {
                        formatter: function (params: any) {
                          return params.value.toFixed(1) + '%';
                        }
                      }
                    },
                    {
                      type: 'max',
                      itemStyle: {color: '#2196F3'},
                      label: {
                        formatter: function (params: any) {
                          return params.value.toFixed(1) + '%';
                        }
                      }
                    }
                  ]
                },
                markLine: {
                  symbol: 'arrow',
                  data: [
                    {
                      type: 'average',
                      label: {
                        position: 'end',
                        formatter: function (params: any) {
                          return 'Avg.' + params.value.toFixed(1) + '%';
                        },
                        backgroundColor: '#FFEB3B'
                      }
                    }
                  ]
                }
              }}
              color={[green[500], red[500]]}
              height="40vh"
              exportData={data60335}
              exportFields={responseData004_column}
              exportFilename={`${t('実績数と不良率')}`}
              onClick={graphClick_gotoDay}
              inputScale={true}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <LFCSingleValues
              columns={[
                {
                  field: '数量総合計',
                  headerName: t('総合計'),
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '総数/日数',
                  headerName: `${t('総合計')} ${t('平均')}/${t('日')}`,
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '良品数',
                  headerName: t('良品'),
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '良品/日数',
                  headerName: `${t('良品')} ${t('平均')}/${t('日')}`,
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '不良品数',
                  headerName: t('不良品'),
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                },
                {
                  field: '不良品/日数',
                  headerName: `${t('不良品')} ${t('平均')}/${t('日')}`,
                  width: '110px',
                  unit: `${t('個')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
            <LFCSingleValues
              columns={[
                {
                  field: '不良率',
                  headerName: `${t('不良率')}`,
                  width: '110px',
                  unit: '%',
                  formatter: toLocalStringEx
                },
                {
                  field: '平均生産間隔',
                  headerName: t('平均生産間隔'),
                  width: '110px',
                  unit: `${t('秒')}`,
                  formatter: toLocalStringEx
                }
              ]}
              source={data60333}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <LFCChartsBoxSearchByMonth
              title={`${t('検査間隔')} X ${t('時間推移')}`}
              source={data60336_box}
              xlist={days_list}
              exportData={data60336}
              exportFields={data60336_column}
              exportFilename={`${t('検査間隔')} X ${t('時間推移')}`}
              height={'35vh'}
              bottom={'30'}
              yAxisName={`${t('サイクルタイム')}(${t('秒')})`}
              onClick={(data: any) => graphClick_01_selectDate(data)}
              inputScale={true}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{display: 'flex'}}>
              <LFCFormRowGroup>
                <LFCTextField
                  name={'select_date_from_1'}
                  label={t('選択日')}
                  value={
                    searchValue.select_date_from_1 === ''
                      ? t('すべて')
                      : searchValue.select_date_from_1
                  }
                  disabled={true}
                />
                {searchValue.select_date_from_1 !== '' ? (
                  <LFCButton size={'small'} onClick={() => graphClick_01_selectDate('')}>
                    {t('すべてに戻す')}
                  </LFCButton>
                ) : (
                  <></>
                )}
              </LFCFormRowGroup>
            </Box>
            <LFCChartsBarSearchMonth
              title={`${t('検査間隔')} X ${t('頻度')}`}
              source={data6033700_show}
              exportData={data6033700_show}
              exportFields={data6033700_column}
              exportFilename={`${t('検査間隔')} X ${t('頻度')}`}
              xAxisName={`${t('検査数')}(${t('個')})`}
              inputScale={true}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <LFCChartsBarSearchMonthStack
              title={`${t('部位別')}　${t('不良数推移')}`}
              source={data60338}
              xlist={days_list}
              exportData={data60338}
              exportFields={data60338_column}
              exportFilename={`${t('部位別')}_${t('不良数推移')}`}
              onClick={graphClick_02_selectDate}
              colorSelectBaseData={distinct(data60338, 'map').sort()}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{display: 'flex'}}>
              <LFCFormRowGroup>
                <LFCTextField
                  name={'select_date_from_2'}
                  label={t('選択日')}
                  value={
                    searchValue.select_date_from_2 === ''
                      ? t('すべて')
                      : searchValue.select_date_from_2
                  }
                  disabled={true}
                />
                {searchValue.select_date_from_2 !== '' ? (
                  <LFCButton size={'small'} onClick={() => graphClick_02_selectDate('')}>
                    {t('すべてに戻す')}
                  </LFCButton>
                ) : (
                  <></>
                )}
              </LFCFormRowGroup>
            </Box>
            <PieChartSearchByMonth
              data={data603381}
              name={t('部位別不良率')}
              exportData={data603381}
              exportFields={data60338_column}
              exportFilename={t('部位別不良率')}
              colorSelectBaseData={distinct(data60338, 'map').sort()}
            />
          </Grid>

          <Grid item xs={12} md={8}>
            <LFCChartsBarSearchMonthStackJobtype
              title={`${t('検査手法別')}　${t('不良数推移')}`}
              source={data60339}
              xlist={days_list}
              exportData={data60339}
              exportFields={data60339_column}
              exportFilename={`${t('検査手法別')}_${t('不良数推移')}`}
              onClick={graphClick_03_selectDate}
              colorSelectBaseData={distinct(data60339, 'job_type_name').sort()}
              inputScale={true}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{display: 'flex'}}>
              <LFCFormRowGroup>
                <LFCTextField
                  name={'select_date_from_3'}
                  label={t('選択日')}
                  value={
                    searchValue.select_date_from_3 === ''
                      ? t('すべて')
                      : searchValue.select_date_from_3
                  }
                  disabled={true}
                />
                {searchValue.select_date_from_3 !== '' ? (
                  <LFCButton size={'small'} onClick={() => graphClick_03_selectDate('')}>
                    {t('すべてに戻す')}
                  </LFCButton>
                ) : (
                  <></>
                )}
              </LFCFormRowGroup>
            </Box>
            <PieChartSearchByMonthJobtype
              data={data603391}
              name={t('検査手法別不良率')}
              exportData={data603391}
              exportFields={data60339_column}
              exportFilename={t('検査手法別不良率')}
              colorSelectBaseData={distinct(data60339, 'job_type_name').sort()}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog open={openResponse} onClose={handleClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={handleClose}>{t('日別明細')}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={[
              {
                field: '日付',
                headerName: `${t('日付')}`,
                description: `${t('日付')}`,
                sortable: false,
                width: 200,
                align: 'center'
              },
              {
                field: '検査数',
                headerName: `${t('検査数')}`,
                description: `${t('検査数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '良品数',
                headerName: `${t('良品数')}`,
                description: `${t('良品数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '不良数',
                headerName: `${t('不良数')}`,
                description: `${t('不良数')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '不良率',
                headerName: `${t('不良率')}`,
                description: `${t('不良率')}`,
                width: 150,
                sortable: false,
                align: 'right'
              },
              {
                field: '平均ジョブ処理時間',
                headerName: `${t('平均ジョブ処理時間')}`,
                description: `${t('平均ジョブ処理時間')}`,
                width: 150,
                sortable: false,
                align: 'right'
              }
            ]}
            rows={data60346}
            height="80vh"
            exportFilename={`${t('日別明細')}`}
          />
        </DialogContent>
      </Dialog>
    </GenericTemplate>
  );
});

export default SearchByMonthPage;
