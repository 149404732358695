import {SelectChangeEvent} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import {getLFCData} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  // selectItem: any;
  // id: string;
}

/**
 * 機種プルダウン
 * @param props
 * @returns
 */
const LFCSelectFormMap = React.memo((props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const source = useRef(axios.CancelToken.source());

  const [items, setItems] = useState<{name: string; value?: string}[]>([{name: t('すべて')}]);

  useEffect(() => {
    // マウント時処理

    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 18,
      parameters: {},
      cancelToken: source.current.token,
      t
    }).then((data: any) => {
      const tmp: any = data.map((item: any) => {
        return {name: item['map']};
      });
      tmp.unshift({name: t('すべて'), value: `${t('すべて')}`});
      setItems(tmp);
    });

    return () => {
      // アンマウント処理
      source.current.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <LFCSelectForm
      name={props.name}
      label={props.label ? props.label : t('検査部位')}
      value={props.value !== undefined ? props.value : t('すべて')}
      onChange={props.onChange}
      style={props.style}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      margin={props.margin}
      required={props.required}
      size={props.size}
      multiple={props.multiple}
      selectItem={items}
      id={'sf_map'}
    />
  );
});
export default LFCSelectFormMap;
