import {useTheme} from '@mui/material';

interface Props {
  startProcess?: boolean;
}

const ProgressBar = (props: Props) => {
  const theme = useTheme();
  const isShow = props.startProcess !== undefined ? props.startProcess : true;

  if (isShow) {
    return (
      <>
        <input type="hidden" id="isProcessing" value="1" />
        <style>
          {`
        @keyframes width-change {
          0% { width: 0%;}
          100% { width: 100%;}
        }`}
        </style>
        <div
          style={{
            animation: 'width-change 2s ease infinite',
            borderTop: `3px solid ${theme.palette.primary.main}`,
            position: 'fixed',
            left: 0,
            top: 0,
            zIndex: 999999999,
            color: theme.palette.primary.main
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <input type="hidden" id="isProcessing" value="0" />
      </>
    );
  }
};

export default ProgressBar;
