import {SelectChangeEvent} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import {getLFCData} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  // selectItem: any;
  // id: string;
}

/**
 * 検査装置プルダウン
 * @param props
 * @returns
 */
const LFCSelectFormMachineWelding = React.memo((props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [items, setItems] = useState<{name: string; value: string}[]>([
    {name: t('すべて'), value: t('すべて')}
  ]);

  useEffect(() => {
    // マウント時処理

    // 検査装置情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 41002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      if (ds !== undefined) {
        const tmp: {name: string; value: string}[] = ds.map((item: any, index: number) => {
          return {name: item['machine'], value: item['machine']};
        });
        setItems([{name: t('すべて'), value: `${t('すべて')}`}, ...tmp]);
      }
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);
  return (
    <LFCSelectForm
      name={props.name}
      label={props.label ? props.label : t('検査装置')}
      value={props.value != null ? props.value : t('すべて')}
      onChange={props.onChange}
      style={props.style}
      fullWidth={props.fullWidth}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      margin={props.margin}
      required={props.required}
      size={props.size}
      multiple={props.multiple}
      selectItem={items}
      id={'sf_machine'}
    />
  );
});
export default LFCSelectFormMachineWelding;
