import React, {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  y: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  xList: any;
}

/**
 * LFCChartsBarYMD
 * @param props
 * @returns
 */
const LFCChartsBarYMD2 = React.memo((props: Props) => {
  const {t} = useTranslation();
  const renderFlgRef = useRef(false);

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // データソースクリア
    if (props.source.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});

    if (props.series) {
      // シリーズ指定あり
      let strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.source.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.series, value: grp}
          }
        });
        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          encode: {y: props.y.dsColumn},
          datasetIndex: i + 1
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        encode: {y: props.y.dsColumn}
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '40',
              left: '60',
              right: '10',
              bottom: '93'
            },
        ...(props.series
          ? {
              legend: {type: 'scroll', bottom: 0, itemWidth: 10}
            }
          : {
              legend: {
                show: false
              }
            }),
        tooltip: {
          trigger: 'item',
          formatter: (params: any) => {
            let tmp: string = '';
            if (params.data.map !== undefined) {
              tmp =
                params.data.lq_time +
                '<br/>' +
                t('部位') +
                '： ' +
                params.data.map +
                '<br/>' +
                t('不良数') +
                '： ' +
                params.data.ng +
                '<br/>';
            } else if (params.data.work !== undefined) {
              tmp =
                params.data.lq_time +
                '<br/>' +
                t('機種') +
                '： ' +
                params.data.work +
                '<br/>' +
                t('不良数') +
                '： ' +
                params.data.ng +
                '<br/>';
            } else if (params.data.job_type_name !== undefined) {
              tmp =
                params.data.lq_time +
                '<br/>' +
                t('検査手法') +
                '： ' +
                params.data.job_type_name +
                '<br/>' +
                t('不良数') +
                '： ' +
                params.data.ng +
                '<br/>';
            }
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color ? props.color : null,
        dataset: dataset,
        xAxis: [
          {
            type: 'category',
            axisLabel: {
              rotate: '45',
              fontSize: 10
            },
            data: !isEmpty(props.xList)
              ? props.xList
              : props.source.map((a: any) => a.id).filter((b: any) => b !== undefined)
          }
        ],
        yAxis: [
          {
            type: props.y.type
          }
        ],
        series: series
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
});
export default LFCChartsBarYMD2;
