import {Box, Divider, Grid, Typography} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {useSystemSettings} from '_contexts/SystemSettingsProvider';
import {FORMAT_YYYYMMDD} from '_logics/LFCConst';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_ON_SITE_OBSERVER} from '_logics/LFCPageId';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsGanttChartWelding from '../_charts/LFCChartsGanttChartWelding';
import {useTranslation} from 'react-i18next';
import LFCTextField from '_components/inputs/LFCTextField';
import {AlarmType, RegType} from 'types/onSiteObserver';
import {OperatingStatusCard} from '_components/model/welding/on-site-observer/OperatingStatusCard';
import {TransitionCard} from '_components/model/welding/on-site-observer/TransitionCard';
import LFCOkNgPie2 from '_components/surfaces/LFCOkNgPie2';
import LFCOkNgPie3 from '_components/surfaces/LFCOkNgPie3';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import LFCOkNgPie4 from '_components/surfaces/LFCOkNgPie4';
import LFCSelectFormMachineWelding from '_components-with-data/inputs/LFCSelectFormMachineWelding';
import {roundToFirstDecimal} from 'utils/math-utilities';

const OnSiteObserverPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const systemSettings = useSystemSettings();
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_ON_SITE_OBSERVER,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState({
    working_time: 8,
    target_working_time: 60,
    target_working_rate: 80,
    ...defaultCondition
  });
  const [alarmMessage, setAlarmMessage] = useState<AlarmType[]>([
    {message1: '', regtime: '', last_update_time: ''}
  ]);
  const [regio, setRegio] = useState<RegType[]>([{adno: '', regtype: '', val: ''}]);

  const [productionToday, setProductionToday] = useState([{total_count: '0', ng_count: '0'}]);
  const [productionMonitor, setProductionMonitor] = useState([
    {
      min_time: 0,
      average_time: 0,
      max_time: 0,
      work_rate: 0
    }
  ]);
  const [machineStatus, setMachineStatus] = useState([]);
  const [productTransition, setProductTransition] = useState([]);
  const [workTimeTransition, setWorkTimeTransition] = useState([]);
  const [dispDate, setDispDate] = useState('');
  const [source] = useState(axios.CancelToken.source());
  const apiFetch = () => {
    setStartProcess(true);
    const dtnow = dayjs();
    setDispDate(dtnow.format(FORMAT_YYYYMMDD));

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3110,
        parameters: {
          select_date: dtnow,
          work: null
        },
        ds_state: setWorkTimeTransition,
        name: `${t('作業時間推移')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3205,
        parameters: {select_date: dtnow, work: null},
        ds_state: setProductTransition,
        name: `${t('生産数推移')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 7000000,
        parameters: {},
        ds_state: setAlarmMessage,
        name: `${t('アラームメッセージ')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 7000001,
        parameters: {},
        ds_state: setRegio,
        name: `${t('レジオ')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3101,
        parameters: {select_date: dtnow},
        ds_state: setProductionToday,
        name: `${t('本日の生産状況')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3102,
        parameters: {
          select_date: dtnow,
          working_time: searchValue.working_time
        },
        ds_state: setProductionMonitor,
        name: `${t('作業時間')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3104,
        parameters: {select_date: dtnow},
        ds_state: setMachineStatus,
        name: `${t('稼働状況')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  useEffect(() => {
    apiFetch();

    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useInterval(apiFetch, systemSettings.RELOAD_TIME * 1000);

  const [openSavedCondition, setOpenSavedCondition] = useState<boolean>(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'machine',
        value: searchValue.machine,
        valueLabel: searchValue.machine,
        colName: t('マシン名'),
        colWidth: 300
      },
      {
        name: 'working_time',
        value: searchValue.working_time,
        valueLabel: searchValue.working_time,
        colName: t('1日の稼働時間(h)'),
        colWidth: 300
      },
      {
        name: 'target_working_time',
        value: searchValue.target_working_time,
        valueLabel: searchValue.target_working_time,
        colName: t('目標作業時間(sec)'),
        colWidth: 300
      },
      {
        name: 'target_working_rate',
        value: searchValue.target_working_rate,
        valueLabel: searchValue.target_working_rate,
        colName: t('目標稼働率(%)'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.現場ウォッチャー')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCSelectFormMachineWelding
            name={'machine'}
            label={`${t('マシン名')}`}
            value={searchValue.machine}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCSelectForm
            name={'working_time'}
            label={t('1日の稼働時間(h)')}
            value={searchValue.working_time}
            id={'working_time'}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            selectItem={[...Array(24)].map((_, i) => ({name: String(i + 1), value: i + 1}))}
          />
          <LFCTextField
            name="target_working_time"
            label={t('目標作業時間(sec)')}
            value={searchValue.target_working_time}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCTextField
            name="target_working_rate"
            label={t('目標稼働率(%)')}
            value={searchValue.target_working_rate}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCButton color="primary" onClick={() => apiFetch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_ON_SITE_OBSERVER}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={2}>
            <LFCTitledCard title={t('本日')} titleSub={`${dispDate}`} height="350px">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LFCOkNgPie2 source={productionToday[0]} height="200px" />
                </Grid>
              </Grid>
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12} lg={2}>
            <LFCTitledCard title={t('作業時間')} titleSub={`${dispDate}`} height="350px">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LFCOkNgPie3
                    source={{
                      ...productionMonitor[0],
                      average_time: roundToFirstDecimal(productionMonitor[0].average_time),
                      target_working_time: searchValue.target_working_time
                    }}
                    height="200px"
                  />
                  <Grid container spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <LFCSingleValue
                        title={t('最小')}
                        val={roundToFirstDecimal(productionMonitor[0].min_time)}
                      />
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <LFCSingleValue
                        title={t('最大')}
                        val={roundToFirstDecimal(productionMonitor[0].max_time)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12} lg={2}>
            <LFCTitledCard title={t('稼働率')} titleSub={`${dispDate}`} height="350px">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LFCOkNgPie4
                    source={{
                      ...productionMonitor[0],
                      work_rate: roundToFirstDecimal(100 * productionMonitor[0].work_rate) / 100
                    }}
                    targetWorkingRate={searchValue.target_working_rate}
                    height="200px"
                  />
                </Grid>
                <Typography mt="25px">稼働率 = 作業時間の合計 / 1日の稼働時間</Typography>
              </Grid>
            </LFCTitledCard>
          </Grid>
          <Grid item lg={6} xs={12} mt={1}>
            <LFCChartsGanttChartWelding
              title={t('稼働状況')}
              source={machineStatus}
              exportFields={{
                machine: t('マシン'),
                judge: `${t('判定')}(0:${t('code.judge.1')}/1:${t('code.judge.2')})`,
                detection_time_from: t('開始時間'),
                detection_time_to: t('終了時間')
              }}
              exportFilename={`${t('稼働状況')}`}
            />
          </Grid>
        </Grid>
        <TransitionCard
          productTransition={productTransition}
          workTimeTransition={workTimeTransition}
        />
        <OperatingStatusCard regio={regio[0]} alarmMessage={alarmMessage[0]} />
      </Box>
    </GenericTemplate>
  );
};

export default OnSiteObserverPage;
