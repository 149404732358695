import {createContext, useContext, useEffect, useState} from 'react';
import {useSnackbar} from 'notistack';
import {useAuthUser} from './AuthUserProvider';
import {getLFCData} from '_logics/LFCUtil';
import SavedCondition from './types/SavedCondition';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';
import {useMySettings} from './MySettingsProvider';

/**
 * コンテキスト
 */
const SavedConditionContext = createContext({} as any);

/**
 * 検索条件設定プロバイダ
 */
export const SavedConditionProvider: React.FC = ({children}) => {
  const {enqueueSnackbar} = useSnackbar();
  const authUser = useAuthUser();
  const mySettings = useMySettings();
  const [savedCondition, setSavedCondition] = useState<SavedCondition[]>([]);
  const {t} = useTranslation();

  /**
   * 認証情報変更時の処理
   */
  useEffect(() => {
    if (0 === Object.keys(mySettings).length) {
      //未認証だったら初期化
      setSavedCondition([]);
      return;
    }
    reloadSavedCondition();
  }, [mySettings]);

  /**
   * condition_json列の文字列をJSONに変換
   * @param condition_json
   * @returns
   */
  const getConditionJson = (condition_json: string) => {
    const condJson = JSON.parse(condition_json);
    return condJson.reduce((acc: any, cur: any) => {
      return {...acc, [cur.name]: cur.value};
    }, []);
  };

  /**
   * DB読み直し
   */
  const reloadSavedCondition = async () => {
    let newSavedCondition: any = {};
    if (authUser != null) {
      const data = await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 1000,
        parameters: {},
        t
      });
      newSavedCondition = data.map((item: any, idx: number) => {
        return {
          pageId: item['page_id'],
          locationNo: item['location_no'],
          conditions: getConditionJson(item['condition_json'])
        };
      });
    }
    setSavedCondition(newSavedCondition);
  };

  /**
   * ページID、ロケーションNoを指定した保存検索条件(初期設定)の取得
   * @param pageId
   * @param locationNo
   * @returns
   */
  const getDefaultCondition = (pageId: number, locationNo: number): any => {
    if (!isEmpty(savedCondition)) {
      const defaultCondition = savedCondition.filter(item => {
        return item['pageId'] === pageId && item['locationNo'] === locationNo;
      });
      return defaultCondition.length > 0 ? defaultCondition[0].conditions : null;
    } else {
      return null;
    }
  };

  return (
    <SavedConditionContext.Provider value={{reloadSavedCondition, getDefaultCondition}}>
      {children}
    </SavedConditionContext.Provider>
  );
};

export const useSavedCondition = () => useContext(SavedConditionContext);
export const useGetDefaultCondition = () => useContext(SavedConditionContext).getDefaultCondition;
export const useReloadSavedCondition = () => useContext(SavedConditionContext).reloadSavedCondition;

export default SavedConditionProvider;
