import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LFCButton from '_components/inputs/LFCButton';
import {ExportCsv, ExportExcel} from '_components/ExportExcelCsv';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import MasterEditPanelDig from './MasterEditPanelDig';
import LFCFileField from '_components/inputs/LFCFileField';
import axios from 'axios';
import ProgressBar from '../ProgressBar';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
      flexGrow: 1,
      display: 'flex'
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: '300px'
    },
    table: {
      minWidth: 650
    },
    mgt20_padin10: {
      marginTop: 20,
      padding: 10
    },
    title: {
      fontWeight: 800,
      marginRight: 20
    },
    mgr5: {
      display: 'inline-flex',
      marginRight: 5
    }
  };
});

type MasterEditProps = {
  ComponentTitle: string;
  CategoryNo?: number;
  DataObject: any;
  FieldObject: any;
  ExportFileName: string;
  DataKeyName: string;
  SaveObject: any;
  AddNewObject?: any | boolean;
  DeleteObject?: any | boolean;
  Dialog?: boolean;
  other?: any;
};

const MasterEditPanel = (props: MasterEditProps) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [listEditMode, setListEditMode] = useState('');
  const categoryFilter = (data: any[], category: number) =>
    data.filter((item: any) => item.category === category);
  const [editButtonStatus, setEditButtonStatus] = useState(false);
  const [addMode, setAddMode] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    thisCancel();
  };

  const [dataMode, setDataMode] = useState<string>('');

  const [editInput, setEditInput] = useState<any>([]);
  let input_data: any = {};
  const onChangeEdit = (event: any) => {
    setEditInput({...editInput, [event.target.name]: event.target.value});
  };
  const onChangeAdd = (event: any) => {
    input_data[event.target.name] = event.target.value;
  };

  const thisEdit = (id_key: string, category: any) => {
    if (category) {
      if (id_key.indexOf(props.DataKeyName) !== -1) {
        setEditInput(
          categoryFilter(props.DataObject, category)[
            Number(id_key.replace(props.DataKeyName + '_', ''))
          ]
        );
      }
    } else {
      setEditInput(props.DataObject[Number(id_key.replace(props.DataKeyName + '_', ''))]);
    }
    setListEditMode(id_key);
    setEditButtonStatus(!editButtonStatus);
    setDataMode('edit');
    setOpen(props.Dialog ? props.Dialog : false);
  };

  const thisSave = async (id_key: any) => {
    await setStartProcess(true);
    editInput.fileData = searchValues;
    props.SaveObject(id_key, editInput);
    thisCancel();
    await setStartProcess(false);
  };

  const thisSaveFromDialog = (id_key: any, searchValue: any) => {
    props.SaveObject(id_key, searchValue);
    thisCancel();
  };

  const thisCancel = () => {
    setListEditMode('');
    setAddMode('');
    setEditButtonStatus(!editButtonStatus);
    setInputFileData(undefined);
    setOpen(false);
  };

  const addNewRecord = async (master_name: string) => {
    await setStartProcess(true);
    await setEditInput([]);
    await setOpen(props.Dialog ? props.Dialog : false);
    await setEditButtonStatus(!editButtonStatus);
    await setAddMode(master_name);
    await setDataMode('add');
    await setStartProcess(false);
  };

  const saveNew = (id_key: any) => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    Promise.allSettled([
      (input_data.fileData = searchValues),
      props.AddNewObject(id_key, input_data),
      thisCancel()
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const saveNewFromDialog = (id_key: any, searchValue: any) => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    Promise.allSettled([props.AddNewObject(id_key, searchValue), thisCancel()]).then(() => {
      setStartProcess(false);
    });
  };

  const thisDelete = (id_key: any) => {
    // // input check
    // if (!formRef.current?.reportValidity()) {
    //   return;
    // }
    setStartProcess(true);
    Promise.allSettled([props.DeleteObject(id_key), thisCancel()]).then(() => {
      setStartProcess(false);
    });
  };

  const thisDelete_Image = async (id_key: any, filename: string) => {
    // @ts-ignore
    let delete_mapimage: string = process.env.REACT_APP_V1 + process.env.REACT_APP_DELETEMAPIMAGE;
    let config = {
      headers: {
        Authorization: null
      }
    };
    await axios
      .post(
        delete_mapimage,
        {
          FileName: encodeURI(filename)
        },
        config
      )
      .then(response => {
        props.DeleteObject(id_key);
        thisCancel();
      })
      .catch(error => {
        console.log(error);
      });
  };

  // ファイルアップロード
  const [inputFileRow, setInputFileRow] = useState<any>();
  const [inputFileData, setInputFileData] = useState<any>();
  const [searchValues, setSearchValues] = useState({
    tmp_filename: '',
    filesize: '',
    filetype: ''
  });
  const formhandleChangeFile = (e: any) => {
    setInputFileData(e.target.files[0]);

    if (e.target.files[0].size > 30720000) {
      //30MB
      // if (e.target.files[0].size > 5120000) { //5MB
      enqueueSnackbar(t('message.添付ファイルサイズは30MB未満でお願いします。'), {
        variant: 'error',
        persist: true
      });
      let file_object = document.getElementById('input_file_value');
      // @ts-ignore
      file_object.value = '';
    } else if (e.target.files[0].type === 'application/octet-stream') {
      //DLL, EXE　など禁止
      enqueueSnackbar(t('message.選択されたファイル形式は添付できません。'), {
        variant: 'error',
        persist: true
      });
      let file_object = document.getElementById('input_file_value');
      // @ts-ignore
      file_object.value = '';
    } else {
      setInputFileRow(e.target.files[0]);
      setSearchValues({
        ...searchValues,
        tmp_filename: e.target.files[0].name,
        filesize: e.target.files[0].size,
        filetype: e.target.files[0].filetype
      });
    }
  };

  //S3-direct-upload
  const fileUpLoad = async () => {
    // @ts-ignore
    let receiver_api: string = process.env.REACT_APP_V1 + process.env.REACT_APP_GETURLMAPIMAGE;
    let config = {
      headers: {
        Authorization: null
      }
    };
    await axios
      .post(
        receiver_api,
        {
          FileType: searchValues.filetype,
          FileName: encodeURI(searchValues.tmp_filename)
        },
        config
      )
      .then(response => {
        let tmp_url = JSON.parse(response.data.body);
        s3DirectUpload(tmp_url.s3_url);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const s3DirectUpload = (url: string) => {
    let config = {
      headers: {
        'Content-Type': searchValues.filetype
      }
    };
    axios
      .put(url, inputFileRow, config)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const sendsearchValuesDirect = async () => {
    setStartProcess(true);
    await fileUpLoad().then(() => {
      resetSearchValues();
      setStartProcess(false);
      saveNew(props.DataKeyName);
    });
  };

  const resetSearchValues = () => {
    let file_object = document.getElementById('input_file_value');
    // @ts-ignore
    file_object.value = '';
    setSearchValues({
      tmp_filename: '',
      filetype: '',
      filesize: ''
    });
  };

  return (
    <div className="MasterEdit">
      <ProgressBar startProcess={startProcess} />
      <TableContainer component={Paper} className={classes.mgt20_padin10}>
        <span className={classes.title}>
          {props.ComponentTitle}({t('カテゴリ')}：{props.CategoryNo})
        </span>
        {props.AddNewObject ? (
          <LFCButton
            color="primary"
            className={classes.mgr5}
            disabled={editButtonStatus}
            onClick={() => addNewRecord(props.DataKeyName)}
          >
            {t('レコード追加')}
          </LFCButton>
        ) : (
          false
        )}
        <ExportCsv
          variant={'outlined'}
          color={'primary'}
          size={'small'}
          className={classes.mgr5}
          data={props.DataObject}
          fields={props.FieldObject}
          filename={props.ExportFileName}
        />
        <ExportExcel
          variant={'outlined'}
          color={'secondary'}
          size={'small'}
          className={classes.mgr5}
          data={props.DataObject}
          fields={props.FieldObject}
          filename={props.ExportFileName}
        />
        <form ref={formRef}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                {addMode === props.DataKeyName
                  ? props.FieldObject.map((item: any, index: number) =>
                      item.add === true ? (
                        <TableCell align="left" key={'h_' + index}>
                          {item.columnName}
                        </TableCell>
                      ) : (
                        false
                      )
                    )
                  : props.FieldObject.map((item: any, index: number) =>
                      item.hide === false ? (
                        <TableCell align="left" key={'h_' + index}>
                          {item.columnName}
                        </TableCell>
                      ) : (
                        false
                      )
                    )}
                <TableCell align="left" />
              </TableRow>
            </TableHead>
            <TableBody>
              {props.CategoryNo ? (
                //カテゴリーを持つ処理
                categoryFilter(props.DataObject, props.CategoryNo).map((row: any, index: number) =>
                  listEditMode !== props.DataKeyName + '_' + index ? (
                    <TableRow key={props.DataKeyName + '_' + index}>
                      {props.FieldObject.map((item: any, index: number) => {
                        if (item.hide) {
                          return false;
                        } else {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'show_' + index}
                            >
                              {row[item.columnField]}
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell component="td" scope="row" align="right">
                        <LFCButton
                          color="primary"
                          disabled={editButtonStatus}
                          onClick={() =>
                            thisEdit(props.DataKeyName + '_' + index, props.CategoryNo)
                          }
                        >
                          {t('編集')}
                        </LFCButton>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow key={props.DataKeyName + '_' + index}>
                      {props.FieldObject.map((item: any, index: number) => {
                        if (item.edit && !item.multiRows) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCTextField
                                name={item.columnField}
                                label={item.columnField}
                                value={editInput[item.columnField]}
                                onChange={onChangeEdit}
                                fullWidth={true}
                                style={{width: '200px'}}
                              />
                            </TableCell>
                          );
                        } else if (item.edit && item.multiRows) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCTextField
                                name={item.columnField}
                                label={item.columnField}
                                value={editInput[item.columnField]}
                                onChange={onChangeEdit}
                                fullWidth={true}
                                multiline={true}
                                style={{width: '200px'}}
                              />
                            </TableCell>
                          );
                        } else if (item.edit && item.dateType) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCDatePicker
                                name={item.columnField}
                                label={item.columnField}
                                value={editInput[item.columnField]}
                                onChange={onChangeEdit}
                              />
                            </TableCell>
                          );
                        } else if (!item.hide) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              {row[item.columnField]}
                            </TableCell>
                          );
                        } else {
                          return false;
                        }
                      })}
                      <TableCell component="td" scope="row" align="right">
                        <LFCButton
                          color="primary"
                          className={classes.mgr5}
                          onClick={() => thisSave(props.DataKeyName + '_' + index)}
                        >
                          {t('保存')}
                        </LFCButton>
                        {props.DeleteObject ? (
                          <LFCButton
                            color="primary"
                            className={classes.mgr5}
                            onClick={isOk => {
                              if (!isOk) {
                                return;
                              }
                              thisDelete(props.DataKeyName + '_' + index);
                            }}
                            confirmMessage={`${t('message.削除しますか？')}`}
                          >
                            {t('削除')}
                          </LFCButton>
                        ) : (
                          false
                        )}
                        <LFCButton onClick={() => thisCancel()}>{t('キャンセル')}</LFCButton>
                      </TableCell>
                    </TableRow>
                  )
                )
              ) : //　カテゴリーを持たない処理
              addMode === props.DataKeyName ? (
                props.Dialog ? (
                  false
                ) : (
                  <TableRow key={'add'}>
                    {props.FieldObject.map((field_setting: any, index: number) => {
                      if (
                        field_setting.add &&
                        !field_setting.multiRows &&
                        !field_setting.dateType &&
                        !field_setting.fileType
                      ) {
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={200}
                            key={'add_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnName}
                              // value={editInput[field_setting.columnField]}
                              value={input_data[field_setting.columnField]}
                              onChange={onChangeAdd}
                              fullWidth={true}
                              style={{marginTop: '10px'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        );
                      } else if (field_setting.add && field_setting.multiRows) {
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={200}
                            key={'add_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnName}
                              // value={editInput[field_setting.columnField]}
                              value={input_data[field_setting.columnField]}
                              onChange={onChangeAdd}
                              fullWidth={true}
                              multiline={true}
                              style={{marginTop: '10px'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        );
                      } else if (field_setting.edit && field_setting.dateType) {
                        return (
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'add_' + index}
                          >
                            <LFCDatetimePicker
                              name={field_setting.columnField}
                              label={field_setting.columnField}
                              value={input_data[field_setting.columnField]}
                              onChange={onChangeAdd}
                              required={field_setting.other}
                            />
                          </TableCell>
                        );
                      } else if (field_setting.edit && field_setting.fileType) {
                        return (
                          <TableCell component="td" scope="row" align="left" key={'add_' + index}>
                            <LFCFileField
                              id="input_file_value"
                              name="file"
                              label="file"
                              onChange={formhandleChangeFile}
                              // fullWidth={true}
                              style={{marginTop: '10px', float: 'left'}}
                            />
                            <Box style={{float: 'left', marginTop: '11px', marginLeft: '10px'}}>
                              {inputFileData !== undefined ? (
                                <LFCButton onClick={() => sendsearchValuesDirect()}>
                                  {t('アップロード')}
                                </LFCButton>
                              ) : (
                                <></>
                              )}
                            </Box>
                          </TableCell>
                        );
                      } else {
                        return false;
                      }
                    })}
                    <TableCell component="td" scope="row" align="right">
                      {!(
                        props.FieldObject.filter((field_setting: any) => field_setting.fileType)
                          .length > 0
                      ) ? (
                        <LFCButton
                          color={'primary'}
                          className={classes.mgr5}
                          onClick={() => saveNew(props.DataKeyName)}
                        >
                          {t('保存')}
                        </LFCButton>
                      ) : (
                        false
                      )}
                      <LFCButton onClick={() => thisCancel()}>{t('キャンセル')}</LFCButton>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                props.DataObject.map((row: any, index: number) =>
                  listEditMode !== props.DataKeyName + '_' + index ? (
                    <TableRow key={props.DataKeyName + '_r_' + index}>
                      {props.FieldObject.map((item: any, index: number) => {
                        if (!item.fileType) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'show_' + index}
                            >
                              {row[item.columnField]}
                            </TableCell>
                          );
                        } else {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'show_' + index}
                            >
                              {row[item.columnField]}
                              <br />
                              <img
                                src={`${
                                  process.env.REACT_APP_MAPIMAGE_PATH + row[item.columnField]
                                }`}
                                alt=""
                                style={{height: '80px'}}
                              />
                            </TableCell>
                          );
                        }
                      })}
                      <TableCell component="td" scope="row" align="right">
                        <LFCButton
                          color="primary"
                          onClick={() => thisEdit(props.DataKeyName + '_' + index, undefined)}
                          disabled={editButtonStatus}
                        >
                          {t('編集')}
                        </LFCButton>
                      </TableCell>
                    </TableRow>
                  ) : props.Dialog ? (
                    false
                  ) : (
                    <TableRow key={props.DataKeyName + '_' + index}>
                      {props.FieldObject.map((item: any, index: number) => {
                        if (item.edit && !item.multiRows && !item.dateType && !item.fileType) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCTextField
                                name={item.columnField}
                                label={item.columnField}
                                value={editInput[item.columnField]}
                                onChange={onChangeEdit}
                                fullWidth={true}
                                style={{width: '200px'}}
                              />
                            </TableCell>
                          );
                        } else if (item.edit && item.multiRows) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCTextField
                                name={item.columnField}
                                label={item.columnField}
                                value={editInput[item.columnField]}
                                onChange={onChangeEdit}
                                fullWidth={true}
                                multiline={true}
                                style={{width: '200px'}}
                              />
                            </TableCell>
                          );
                        } else if (item.edit && item.dateType) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              <LFCDatetimePicker
                                name={item.columnField}
                                label={item.columnField}
                                value={
                                  new Date(editInput[item.columnField]).getFullYear() +
                                  '-' +
                                  (
                                    '0' +
                                    (new Date(editInput[item.columnField]).getMonth() + 1)
                                  ).slice(-2) +
                                  '-' +
                                  ('0' + new Date(editInput[item.columnField]).getDate()).slice(
                                    -2
                                  ) +
                                  'T' +
                                  new Date(editInput[item.columnField]).getHours() +
                                  ':' +
                                  new Date(editInput[item.columnField]).getMinutes()
                                }
                                onChange={onChangeEdit}
                              />
                            </TableCell>
                          );
                        } else if (item.edit && item.fileType) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              {t('既存ファイル')}：{row[item.columnField]}
                              <br />
                            </TableCell>
                          );
                        } else if (!item.hide) {
                          return (
                            <TableCell
                              component="td"
                              scope="row"
                              align="left"
                              width={300}
                              key={'edit_' + index}
                            >
                              {row[item.columnField]}
                            </TableCell>
                          );
                        } else {
                          return false;
                        }
                      })}

                      <TableCell component="td" scope="row" align="right">
                        {!(
                          props.FieldObject.filter((field_setting: any) => field_setting.fileType)
                            .length > 0
                        ) ? (
                          <LFCButton
                            color="primary"
                            className={classes.mgr5}
                            onClick={() => thisSave(props.DataKeyName + '_' + index)}
                          >
                            {t('保存')}
                          </LFCButton>
                        ) : (
                          false
                        )}
                        {props.DeleteObject ? (
                          <LFCButton
                            color="secondary"
                            className={classes.mgr5}
                            onClick={isOk => {
                              if (!isOk) {
                                return;
                              }
                              !row.filename
                                ? thisDelete(props.DataKeyName + '_' + index)
                                : thisDelete_Image(props.DataKeyName + '_' + row.id, row.filename);
                            }}
                            confirmMessage={`${t('message.削除しますか？')}`}
                          >
                            {t('削除')}
                          </LFCButton>
                        ) : (
                          false
                        )}
                        <LFCButton onClick={() => thisCancel()}>{t('キャンセル')}</LFCButton>
                      </TableCell>
                    </TableRow>
                  )
                )
              )}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
      <MasterEditPanelDig
        open={open}
        onClose={handleClose}
        rowData={editInput}
        fieldObject={props.FieldObject}
        dataMode={dataMode}
        dataKeyName={listEditMode ? listEditMode : props.DataKeyName}
        saveNewObject={saveNewFromDialog}
        saveEditObject={thisSaveFromDialog}
        deleteObject={thisDelete}
        cancelObject={thisCancel}
        other={props.other ? props.other : undefined}
      />
    </div>
  );
};

export default MasterEditPanel;
