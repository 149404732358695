import dayjs from 'dayjs';
import {SearchType} from 'types/weldingDemo';

export const SEARCH_TYPE: SearchType[] = [
  {id: 0, text: 'All'},
  {id: 1, text: 'Hourly'},
  {id: 2, text: 'Daily'},
  {id: 3, text: 'Monthly'}
];

export const DEFAULT_WELDING_DEMO_SEARCH_FORM_VALUES = {
  date01: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
  date02: dayjs().format('YYYY-MM-DDTHH:mm'),
  work: [],
  deviceid: [],
  map: [],
  searchType: 2
};
