import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import dayjs from 'dayjs';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  series?: string;
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  onClick?: (prm: {x: string; y: string}) => void;
  typeName?: string;
  height?: string;
  format?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineLRobotCompare = (props: Props) => {
  let gData: any;
  if (props.series === '') {
    gData = props.source;
  } else {
    gData = props.source.filter((item: any) => item.map === props.series);
  }

  let xList: any =
    props.typeName === '始点' || props.typeName === '終点'
      ? gData.map((item: any) =>
          dayjs(item.exec_time).format(props.format ?? 'YYYY/MM/DD HH:mm:ss')
        )
      : gData.map((item: any) => dayjs(item.lq_time).format(props.format ?? 'YYYY/MM/DD HH:mm:ss'));

  let data_x: any = [];
  let data_y: any = [];
  let data_z: any = [];

  if (props.typeName === 'Fitting-a' || props.typeName === 'Fitting-b') {
    data_x = gData.map((item: any) => (item.map === props.series ? item.difference_x : false));
    data_y = gData.map((item: any) => (item.map === props.series ? item.difference_y : false));
    data_z = gData.map((item: any) => (item.map === props.series ? item.difference_z : false));
  }

  if (props.typeName === 'PathSend-a' || props.typeName === 'PathSend-b') {
    data_x = gData.map((item: any) => item.node_x);
    data_y = gData.map((item: any) => item.node_y);
    data_z = gData.map((item: any) => item.node_z);
  }

  if (props.typeName === '始点') {
    data_x = gData.map((item: any) => item.welding_start_path_x);
    data_y = gData.map((item: any) => item.welding_start_path_y);
    data_z = gData.map((item: any) => item.welding_start_path_z);
  }

  if (props.typeName === '終点') {
    data_x = gData.map((item: any) => item.welding_end_path_x);
    data_y = gData.map((item: any) => item.welding_end_path_y);
    data_z = gData.map((item: any) => item.welding_end_path_z);
  }

  let option = {
    title: {
      text: props.title,
      left: 'left'
    },
    source: props.source,
    grid: {
      top: 60,
      right: 40,
      left: 120
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        // prettier-ignore
        data: xList,
        axisLabel: {
          fontSize: 10,
          rotate: 20
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Y',
        nameTextStyle: {
          fontSize: 10
        },
        position: 'right',
        // alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {
            // color: colors[0]
          }
        },
        min: (prm: any) => {
          return prm.min.toFixed(2);
        },
        max: (prm: any) => {
          return prm.max < 0 ? Math.trunc(prm.max * 100) / 100 : Math.ceil(prm.max * 100) / 100;
        },
        axisLabel: {
          interval: 0,
          fontSize: 10
        }
      },
      {
        type: 'value',
        name: 'X',
        nameTextStyle: {
          fontSize: 10
        },
        position: 'left',
        // alignTicks: true,
        axisLine: {
          show: true,
          lineStyle: {}
        },
        min: (prm: any) => {
          return prm.min.toFixed(2);
        },
        max: (prm: any) => {
          return prm.max < 0 ? Math.trunc(prm.max * 100) / 100 : Math.ceil(prm.max * 100) / 100;
        },
        axisLabel: {
          interval: 0,
          fontSize: 10
        }
      },
      {
        type: 'value',
        name: 'Z',
        nameTextStyle: {
          fontSize: 10
        },
        position: 'left',
        // alignTicks: true,
        offset: 60,
        axisLine: {
          show: true,
          lineStyle: {
            // color: colors[1]
          }
        },
        min: (prm: any) => {
          return prm.min.toFixed(2);
        },
        max: (prm: any) => {
          return prm.max < 0 ? Math.trunc(prm.max * 100) / 100 : Math.ceil(prm.max * 100) / 100;
        },
        axisLabel: {
          interval: 0,
          fontSize: 10
        }
      }
    ],
    series: [
      {
        name: 'X',
        type: 'line',
        yAxisIndex: 1,
        data: data_x,
        symbolSize: 10,
        color: 'red'
      },
      {
        name: 'Y',
        type: 'line',
        data: data_y,
        symbolSize: 10,
        color: 'green'
      },
      {
        name: 'Z',
        type: 'line',
        yAxisIndex: 2,
        data: data_z,
        symbolSize: 10,
        color: 'blue'
      }
    ],
    legend: {
      type: 'scroll',
      bottom: 0,
      itemWidth: 10,
      textStyle: {
        fontSize: 9
      }
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow', //小数点以下出すぎなのでクロスは一旦なし
        label: {
          formatter: function (prm: any) {
            return prm.value;
          }
        }
      }
    }
  };

  return (
    <div style={{width: '100%'}}>
      {/*{props.title}*/}
      <LFCBaseCharts
        option={option}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        onEvents={{
          click: (params: any) => {
            params.mapname = props.title;
            if (props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        height={props.height !== undefined ? props.height : undefined}
      />
    </div>
  );
};
export default LFCChartsLineLRobotCompare;
