import {useTheme} from '@mui/material';
import dayjs from 'dayjs';
import {cloneDeep, range, round} from 'lodash';
import React, {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {fmtYYYYMMDD} from '_logics/LFCFormatUtil';
import {useTranslation} from 'react-i18next';

interface SourceKeyType {
  x: string; //YYYY-MM-DD
  y: string;
  series: string;
}

/**
 * 引数
 */
interface Props {
  chartType: 'bar' | 'line';
  title: string;
  source: Array<any>;
  sourceKey: SourceKeyType;
  yUnit?: string;
  yDigit?: number;
  exportFields: any;
}

/**
 * LFCChartsWeldingMonthBarLine
 * @param props
 * @returns
 */
const LFCChartsWeldingMonthBarLine = (props: Props) => {
  const {t} = useTranslation();
  type DsRow = {source?: Array<any>; transform?: any};

  const theme = useTheme();
  const [dataX, setDataX] = useState<(string | undefined)[]>([]);
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // データソースクリア
    if (props.source.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // X軸の展開
    let l_dataX: (string | undefined)[] = [];
    if (0 < props.source.length) {
      const fdt = dayjs(props.source[0][props.sourceKey.x]);
      const startOfMonth = fdt.startOf('month').date();
      const endOfMonth = fdt.endOf('month').date();
      l_dataX = range(endOfMonth - startOfMonth + 1).map(i =>
        fmtYYYYMMDD(fdt.startOf('month').add(i, 'day'))
      );
    }

    // データソース生成
    let l_ds: DsRow[] = [];
    let l_sourceEx = cloneDeep(props.source);

    // sourceカスタマイズ(精度調整)
    l_sourceEx = l_sourceEx.map((row: any) => {
      let lrow = row;
      lrow[props.sourceKey.y] = String(round(Number(row[props.sourceKey.y]), props.yDigit || 0));
      return lrow;
    });

    // source設定
    l_ds.push({source: l_sourceEx});

    let l_series: any = [];
    if (props.sourceKey.series) {
      // シリーズ収集
      const grpNames = Array.from(
        new Set(props.source.map((obj: any) => obj[props.sourceKey.series]))
      );
      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.sourceKey.series, value: grp}
          }
        });
        l_series.push({
          type: props.chartType,
          name: grp,
          stack: 'total',
          label: {
            show: true
          },
          encode: {x: props.sourceKey.x, y: props.sourceKey.y},
          datasetIndex: i + 1
        });
      });
    } else {
      l_series.push({
        type: props.chartType,
        label: {
          show: true
        },
        encode: {x: props.sourceKey.x, y: props.sourceKey.y},
        smooth: false
      });
    }

    setDataX(l_dataX);
    setDataset(l_ds);
    setSeries(l_series);
  }, [props.source]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            left: 75,
            right: 5
          },
          color: props.sourceKey.series ? null : [theme.palette.primary.main],
          xAxis: {
            type: 'category',
            name: t('日'),
            data: dataX,
            nameGap: 30,
            nameLocation: 'middle',
            axisLabel: {
              rotate: '45',
              formatter: (val: string) => dayjs(val).format('DD')
            }
          },
          yAxis: {
            type: 'value',
            name: props.yUnit ? `(${props.yUnit})` : '',
            minInterval: 1 // 目盛りを1単位にする
            // boundaryGap: false, // 軸値の下限上限範囲
            // max: (value: any) => value.max + 1, // y軸の最大値を設定
          },
          dataset: dataset,
          series: series,
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: props.sourceKey.series
              ? (prm: any) => {
                  return `${prm.marker}${prm.value[props.sourceKey.series]}<br>${prm.name}<br>${
                    prm.value[props.sourceKey.y]
                  }&nbsp;${props.yUnit || ''}`;
                }
              : (prm: any) => {
                  return `${prm.value[props.sourceKey.x]}<br>${prm.value[props.sourceKey.y]}&nbsp;${
                    props.yUnit || ''
                  }`;
                }
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          }
        }}
        exportData={0 < dataset.length ? dataset[0].source : []}
        exportFields={props.exportFields}
        exportFilename={props.title}
      />
    </div>
  );
};
export default LFCChartsWeldingMonthBarLine;
