import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import LFCChartsBarPerson01 from '_components/charts/LFCChartsBarPerson01';
import LFCChartsLinePerson01 from '_components/charts/LFCChartsLinePerson01';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCChartsGanttChartPersons from './_components/LFCChartsGanttChartPersons';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {LOCATIONID_PERSONS_MAIN, PAGEID_PERSONS_DAILY_REPORT_WORKER} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

const DailyReportWorkerPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [data001, setData001] = useState({ok_count: 0, ng_percent: 0});
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState([]);
  const [data004, setData004] = useState([]);
  const [data005, setData005] = useState([]);
  const [data006, setData006] = useState([]);

  const [zoomPosition, setZoomPosition] = useState({start: 0, end: 100});

  const initialSearchValue = {
    select_date: dayjs().format('YYYY-MM-DD'),
    work: []
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_PERSONS_DAILY_REPORT_WORKER,
    LOCATIONID_PERSONS_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const doSearch = async () => {
    setData003([]);
    setData004([]);
    setData005([]);
    await setStartProcess(true);
    const conditionsDate = {
      select_date: searchValue.select_date,
      select_datetime_from: dayjs(searchValue.select_date).format('YYYY-MM-DD') + ' 00:00',
      select_datetime_to: dayjs(searchValue.select_date).format('YYYY-MM-DD') + ' 23:59'
    };
    const conditionsOther = {
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
    };

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4011,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      // ds_state: setData001,
      name: 'sql-id:4011',
      cancelToken: source.token,
      t
    }).then((datas: any) => setData001(datas[0]));

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4010,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData002,
      name: 'sql-id:4010',
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4012,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData006,
      name: 'sql-id:4012',
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4004,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData003,
      name: 'sql-id:4004',
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4005,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData004,
      name: 'sql-id:4005',
      cancelToken: source.token,
      t
    });
    setZoomPosition({start: makeStartTime(), end: makeEndTime()});
    await setStartProcess(false);
  };

  useEffect(() => {
    if (data004.length > 0) {
      let tmp: any = Array.from(new Set(data004.map((a: any) => a.machine)));
      setData005(tmp);
    }
  }, [data004]);

  const makeStartTime = () => {
    let select_date: any = new Date(searchValue.select_date + ' 00:00:00');
    let tmp_now: any = new Date();
    let now_time: any = new Date(
      select_date.getFullYear() +
        '/' +
        (select_date.getMonth() + 1) +
        '/' +
        select_date.getDate() +
        ' ' +
        (tmp_now.getHours() - 1) +
        ':' +
        tmp_now.getMinutes()
    );
    return (
      ((Math.floor((now_time.getTime() - select_date.getTime()) / 300000) * 300000) / 86400000) *
      100
    );
  };

  const makeEndTime = () => {
    let select_date: any = new Date(searchValue.select_date + ' 00:00:00');
    let tmp_now = new Date();
    let now_time: any = new Date(
      select_date.getFullYear() +
        '/' +
        (select_date.getMonth() + 1) +
        '/' +
        select_date.getDate() +
        ' ' +
        tmp_now.getHours() +
        ':' +
        tmp_now.getMinutes()
    );
    return (
      ((Math.ceil((now_time.getTime() - select_date.getTime()) / 300000) * 300000) / 86400000) * 100
    );
  };

  let data002_column: any = {
    work: 'work',
    時刻: t('時刻'),
    生産数: t('生産数')
  };
  let data004_column: any = {
    machine: t('マシン'),
    judge: t('判定'),
    detection_time_from: t('開始時間'),
    detection_time_to: t('終了時間'),
    time_diff: t('継続時間')
  };

  let data006_column: any = {
    work: 'work',
    時刻: t('時刻'),
    cycle_time: t('生産間隔')
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date',
        value: searchValue.select_date,
        valueLabel: searchValue.select_date,
        colName: t('日付'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={`${t('menu.tab_name.人ログ分析')}:${t('日報')}(${t('人ログ')})`}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCDatePicker
            name={'select_date'}
            label={t('日付')}
            value={searchValue.select_date}
            required
            onChange={handleChange}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_PERSONS_DAILY_REPORT_WORKER}
            locationNo={LOCATIONID_PERSONS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            <LFCSingleValues
              columns={[
                {
                  field: 'ok',
                  headerName: t('生産数'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 0).toLocaleString()
                },
                {
                  field: 'ng_par',
                  headerName: `${t('不良率')}`,
                  width: '100px',
                  unit: ' %',
                  formatter: v => round(v, 1)
                }
              ]}
              source={data001}
            />
          </Grid>
          <Grid item xs={6}>
            <LFCChartsBarPerson01
              title={t('生産数推移')}
              source={data002}
              exportData={data002}
              exportFields={data002_column}
              exportFilename={`${t('生産数推移')}`}
              yAxisName={`${t('生産数')}[${t('個')}]`}
              height={'330px'}
            />
          </Grid>
          <Grid item xs={5}>
            <LFCChartsLinePerson01
              title={t('本日の生産間隔推移')}
              source={data006}
              exportData={data006}
              exportFields={data006_column}
              exportFilename={`${t('本日の生産間隔推移')}`}
              yAxisName={''}
              height={'330px'}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <LFCChartsGanttChartPersons
              datas={data004}
              exportData={data004}
              exportFields={data004_column}
              exportFilename={`${t('人ログカメラ状況')}`}
              deviceIndex={data005}
              infoData={data003}
              startDay={searchValue.select_date}
              zoomData={zoomPosition}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default DailyReportWorkerPage;
