import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {Dialog, DialogContent} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {distinct, getLFCData} from '_logics/LFCUtil';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import JobTrendAnalysisCompareDig from './_components/JobTrendAnalysisCompareDig';
import LFCChartsBox from '_components/charts/LFCChartsBox';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    mgr5: {
      display: 'inline-flex',
      marginRight: 5
    }
  };
});

interface Props {
  open: boolean;
  onClose: () => void;
  datas: any;
}

const FailureAnalysisGapDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [openData, setOpenData] = React.useState({
    x: '',
    y: 'Gap',
    gapno: '',
    searchValue: {
      select_datetime_from: '',
      select_datetime_to: ''
    }
  });
  const handleCloseCompareDig = () => {
    setCompareDig(false);
  };

  const [compareDig, setCompareDig] = React.useState(false);

  const [listData, setListData] = useState([]);
  type GRAPHBASEDATA = {
    data_value: any;
    x_list: any;
  };

  const [graphBaseData, setGraphBaseData] = useState<GRAPHBASEDATA>({
    data_value: [],
    x_list: []
  });

  let today = new Date();
  let fromday = new Date();
  fromday.setDate(today.getDate() - 7);

  let fromday_string: string =
    fromday.getFullYear() +
    '-' +
    ('0' + (fromday.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fromday.getDate()).slice(-2) +
    'T' +
    ('0' + fromday.getHours()).slice(-2) +
    ':' +
    ('0' + fromday.getMinutes()).slice(-2);

  let today_string: string =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2) +
    'T' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2);

  const [searchValue, setSearchValue] = useState<any>({
    map: '',
    page_type: '',
    selectType: 0,
    select_datetime_from: fromday_string,
    select_datetime_to: today_string
  });

  useEffect(() => {
    if (props.open) {
      setSearchValue({
        ...searchValue,
        map: props.datas.x,
        page_type: props.datas.y,
        select_datetime_from:
          props.datas.searchValue && props.datas.searchValue.select_datetime_from
            ? props.datas.searchValue.select_datetime_from
            : fromday_string,
        select_datetime_to:
          props.datas.searchValue && props.datas.searchValue.select_datetime_to
            ? props.datas.searchValue.select_datetime_to
            : today_string,
        select_datetime_from_a:
          props.datas.searchValue && props.datas.searchValue.select_datetime_from
            ? props.datas.searchValue.select_datetime_from
            : fromday_string,
        select_datetime_to_a:
          props.datas.searchValue && props.datas.searchValue.select_datetime_to
            ? props.datas.searchValue.select_datetime_to
            : today_string,
        select_datetime_from_b:
          props.datas.searchValue && props.datas.searchValue.select_datetime_from
            ? props.datas.searchValue.select_datetime_from
            : fromday_string,
        select_datetime_to_b:
          props.datas.searchValue && props.datas.searchValue.select_datetime_to
            ? props.datas.searchValue.select_datetime_to
            : today_string
      });
    } else {
      setSearchValue({
        map: '',
        page_type: '',
        selectType: '0',
        select_datetime_from: fromday_string,
        select_datetime_to: today_string
      });
      setListData([]);
      setGraphBaseData({
        data_value: [],
        x_list: []
      });
    }
  }, [props.open]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    if (searchValue.map === '') {
      return;
    }
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60023,
      parameters: {
        map: searchValue.map,
        select_datetime_from:
          searchValue.select_datetime_from !== undefined
            ? searchValue.select_datetime_from
            : fromday_string,
        select_datetime_to:
          searchValue.select_datetime_to !== undefined
            ? searchValue.select_datetime_to
            : today_string
      },
      ds_state: setListData,
      name: `LROBOT_${t('不良傾向分析')}_Gap`,
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  }, [searchValue]);

  useEffect(() => {
    const path_address_list = distinct(listData, 'path_address');
    let tmp: any = [];
    path_address_list.forEach((path_string: string, index: number) => {
      let data_value: any = [];
      listData.forEach((item: any) => {
        if (item['path_address'] === path_string && item['gap'] !== null) {
          data_value.push(item['gap']);
        }
      });
      tmp[index] = data_value;
      setGraphBaseData({
        data_value: tmp,
        x_list: path_address_list
      });
    });
  }, [listData]);

  let listData_column = {
    map: t('マップ'),
    lq_time: t('日付'),
    path_address: 'path_address',
    gap: t('ギャップ')
  };

  const goJobTrendAnalysisCompareDig = () => {
    setOpenData({
      ...openData,
      x: searchValue.map,
      gapno: '',
      searchValue: {
        select_datetime_from: searchValue.select_datetime_from,
        select_datetime_to: searchValue.select_datetime_to
      }
    });
    setCompareDig(true);
  };
  const graphOnClick = (data: any) => {
    setOpenData({
      x: props.datas.x,
      y: 'Gap',
      gapno: data.name,
      searchValue: {
        select_datetime_from: searchValue.select_datetime_from,
        select_datetime_to: searchValue.select_datetime_to
      }
    });
    setCompareDig(true);
  };

  const date_calc = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);
    for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
      datelist.push(
        d.getFullYear() +
          '-' +
          ('00' + (d.getMonth() + 1)).slice(-2) +
          '-' +
          ('00' + d.getDate()).slice(-2)
      );
    }
    return datelist.sort();
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={props.onClose}>{`L-ROBOT:${t('不良傾向分析')}:Gap${t(
          '詳細'
        )}`}</LFCDialogTitle>
        <DialogContent>
          <ProgressBar startProcess={startProcess} />
          <div style={{margin: 8, float: 'left'}}>{searchValue.map}</div>
          <LFCButton
            color="primary"
            className={classes.mgr5}
            onClick={() => goJobTrendAnalysisCompareDig()}
          >
            {t('比較')}
          </LFCButton>

          <LFCChartsBox
            title={`${t('不良傾向分析')}_Gap${t('詳細')}`}
            source={graphBaseData}
            x={{dsColumn: '', type: 'category'}}
            y={{dsColumn: '', type: 'category'}}
            exportData={listData}
            exportFields={listData_column}
            exportFilename={`${t('不良傾向分析')}_Gap${t('詳細')}`}
            height={'75vh'}
            bottom={'100'}
            onClick={graphOnClick}
            xlist={date_calc()}
          />

          <JobTrendAnalysisCompareDig
            open={compareDig}
            onClose={handleCloseCompareDig}
            datas={openData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FailureAnalysisGapDig;
