import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import LFCButton from '_components/inputs/LFCButton';
import LFCPasswordField from '_components/inputs/LFCPasswordField';
import {useLogin} from '_contexts/AuthUserProvider';
import {handleInputChange} from '_logics/LFCUtil';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  userId: string;
  password: string;
}

/** * 初回ログイン新パスワード入力ダイアログ
 * @returns
 */
const NewPassDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const login = useLogin();

  const formRefNewPassPop = useRef<HTMLFormElement>(null!);
  const [values, setValues] = useState({
    newPassword1: '',
    newPassword2: ''
  });

  /**
   * 初回パスワード変更処理
   * @returns
   */
  const newPassCng = () => {
    // input check
    if (!formRefNewPassPop.current?.reportValidity()) {
      return;
    }

    // input check(一致)
    if (values.newPassword1 !== values.newPassword2) {
      enqueueSnackbar(t('message.確認パスワードが一致しません。'), {variant: 'error'});
      return;
    }

    // auth check
    login(
      props.userId,
      props.password,
      (err: Error) => {
        enqueueSnackbar(t(`aws.cognito.${err.message}`), {variant: 'error'});
      },
      () => {
        enqueueSnackbar(t('message.パスワードを設定しました。'), {variant: 'info'});
      },
      values.newPassword1
    );
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle id="form-dialog-title">{t('新パスワード登録')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans i18nKey={'message.仮パスワードを変更する必要があります。'} />
          <br />
          <Trans i18nKey={'message.新パスワード入力依頼文章'} />
        </DialogContentText>
        <form ref={formRefNewPassPop}>
          <LFCPasswordField
            name="newPassword1"
            label={t('新パスワード')}
            value={values.newPassword1}
            onChange={event => {
              handleInputChange(event, values, setValues);
            }}
            required
            margin="normal"
            fullWidth
            inputProps={{minLength: 8}}
            autoFocus
          />
          <LFCPasswordField
            name="newPassword2"
            label={t('新パスワード(確認)')}
            value={values.newPassword2}
            onChange={event => {
              handleInputChange(event, values, setValues);
            }}
            required
            margin="normal"
            fullWidth
            inputProps={{minLength: 8}}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <LFCButton
          onClick={() => {
            props.onClose();
          }}
        >
          {t('キャンセル')}
        </LFCButton>
        <LFCButton color="primary" onClick={newPassCng}>
          {t('実行')}
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};
export default NewPassDig;
