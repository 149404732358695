import {
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Box,
  Divider
} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBoxPerson1 from '_components/charts/LFCChartsBoxPerson1';
import LFCChartsComboXList from '_components/charts/LFCChartsComboXList';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_PERSONS_MAIN, PAGEID_PERSONS_COMPARE_ANALYSIS} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCChartsBoxPerson2 from '../../_components/charts/LFCChartsBoxPerson2';
import {useTranslation} from 'react-i18next';

const CompareAnalysisWithFacilityPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [data001, setData001] = useState({
    total_count: '0',
    ok_count: '0',
    ng_count: '0',
    ng_percent: '0'
  });
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState({
    total_count: '0',
    ok_count: '0',
    ng_count: '0',
    ng_percent: '0'
  });
  const [data004, setData004] = useState([]);

  const [data4006_left, setData4006_left] = useState([]);
  const [data4006_right, setData4006_right] = useState([]);
  const [data4006_box_left, setData4006_box_left] = useState([]);
  const [data4006_box_right, setData4006_box_right] = useState([]);
  const [data4006_export_left, setData4006_export_left] = useState([]);
  const [data4006_export_right, setData4006_export_right] = useState([]);

  const [data4004_left, setData4004_left] = useState([]);
  const [data4004_right, setData4004_right] = useState([]);

  const [data4018_left, setData4018_left] = useState([]);
  const [data4018_right, setData4018_right] = useState([]);
  const [data4018_box_left, setData4018_box_left] = useState([]);
  const [data4018_box_right, setData4018_box_right] = useState([]);
  const [data4019_left, setData4019_left] = useState([]);
  const [data4019_right, setData4019_right] = useState([]);

  const initialSearchValue = {
    select_date_from1: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
    select_date_to1: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    select_date_from2: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
    select_date_to2: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    work: [],
    deviceid: [],
    dongleid: [],
    searchType: 2
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_PERSONS_COMPARE_ANALYSIS,
    LOCATIONID_PERSONS_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    detaReset();
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };

  const date_calc: any = (searchType: number, select_date_from: string, select_date_to: string) => {
    let datelist: any = [];
    let from_day = new Date(select_date_from);
    let to_day = new Date(select_date_to);

    switch (searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  };

  const detaReset = async () => {
    setData001({total_count: '0', ok_count: '0', ng_count: '0', ng_percent: '0'});
    setData002([]);
    setData003({total_count: '0', ok_count: '0', ng_count: '0', ng_percent: '0'});
    setData004([]);
    setData4006_left([]);
    setData4006_right([]);
    setData4006_box_left([]);
    setData4006_box_right([]);
    setData4006_export_left([]);
    setData4006_export_right([]);
    setData4004_left([]);
    setData4004_right([]);
    setData4018_left([]);
    setData4018_right([]);
    setData4018_box_left([]);
    setData4018_box_right([]);
    setData4019_left([]);
    setData4019_right([]);
    setMachineList_left([]);
    setMachineList_right([]);
    setMachineList_all([]);
    setMachine_id_index_left([]);
    setMachine_id_index_right([]);
    setMachine_id_index_all([]);
  };

  const doSearch = async () => {
    await setStartProcess(true);
    const conditionsDate1 = {
      select_datetime_from: searchValue.select_date_from1,
      select_datetime_to: searchValue.select_date_to1
    };
    const conditionsDate2 = {
      select_datetime_from: searchValue.select_date_from2,
      select_datetime_to: searchValue.select_date_to2
    };
    const conditionsOther = {
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      dongleid: !isEmpty(searchValue.dongleid) ? '{' + searchValue.dongleid.join(',') + '}' : null
    };

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60351,
      parameters: {
        ...conditionsDate1,
        ...conditionsOther
      },
      name: 'sql-id:60351',
      cancelToken: source.token,
      t
    }).then(datas => {
      if (datas.length > 0) {
        setData001({
          total_count: datas[0].total_count,
          ok_count: String(datas[0].total_count - datas[0].ng_count),
          ng_count: datas[0].ng_count,
          ng_percent: datas[0].ng_percent
        });
      }
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60351,
      parameters: {
        ...conditionsDate2,
        ...conditionsOther
      },
      name: 'sql-id:60351',
      cancelToken: source.token,
      t
    }).then(datas => {
      if (datas.length > 0) {
        setData003({
          total_count: datas[0].total_count,
          ok_count: String(datas[0].total_count - datas[0].ng_count),
          ng_count: datas[0].ng_count,
          ng_percent: datas[0].ng_percent
        });
      }
    });

    // 日時比較分析：最小、平均、最大、標準偏差、総作業時間
    //左
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4004,
      parameters: {
        ...conditionsDate1,
        ...conditionsOther
      },
      ds_state: setData4004_left,
      name: 'sql-id:4004',
      cancelToken: source.token,
      t
    });
    //右
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4004,
      parameters: {
        ...conditionsDate2,
        ...conditionsOther
      },
      ds_state: setData4004_right,
      name: 'sql-id:4004',
      cancelToken: source.token,
      t
    });

    switch (searchValue.searchType) {
      case 0:
        //実績数_不良率：検査
        //左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4003,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData002,
          name: 'sql-id:4003',
          cancelToken: source.token,
          t
        });
        //実績数_不良率：検査
        //右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4003,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData004,
          name: 'sql-id:4003',
          cancelToken: source.token,
          t
        });
        // 検査ごと
        // ボックスチャート
        // 左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4009,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4006_left,
          name: 'sql-id:4009',
          cancelToken: source.token,
          t
        });
        // 右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4009,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4006_right,
          name: 'sql-id:4009',
          cancelToken: source.token,
          t
        });

        // ボックスチャート：設備稼働時間
        // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4024,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4018_left,
          name: 'sql-id:4024',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4025,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4019_left,
          name: 'sql-id:4025',
          cancelToken: source.token,
          t
        });
        // ボックスチャート：設備稼働時間
        // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4024,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4018_right,
          name: 'sql-id:4024',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4025,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4019_right,
          name: 'sql-id:4025',
          cancelToken: source.token,
          t
        });

        break;
      case 1:
        //実績数_不良率：時間
        //左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4002,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData002,
          name: 'sql-id:4002',
          cancelToken: source.token,
          t
        });
        //実績数_不良率：時間
        //右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4002,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData004,
          name: 'sql-id:4002',
          cancelToken: source.token,
          t
        });
        // 時間ごと
        // ボックスチャート
        // 左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4008,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4006_left,
          name: 'sql-id:4008',
          cancelToken: source.token,
          t
        });
        // 右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4008,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4006_right,
          name: 'sql-id:4008',
          cancelToken: source.token,
          t
        });

        // ボックスチャート：設備稼働時間
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4022,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4018_left,
          name: 'sql-id:4022',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4023,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4019_left,
          name: 'sql-id:4023',
          cancelToken: source.token,
          t
        });
        // ボックスチャート：設備稼働時間
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4022,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4018_right,
          name: 'sql-id:4022',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4023,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4019_right,
          name: 'sql-id:4023',
          cancelToken: source.token,
          t
        });

        break;
      case 2:
        //実績数_不良率：日
        //左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4000,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData002,
          name: 'sql-id:4000',
          cancelToken: source.token,
          t
        });
        //実績数_不良率：日
        //右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4000,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData004,
          name: 'sql-id:4000',
          cancelToken: source.token,
          t
        });
        // 日ごと
        // ボックスチャート
        // 左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4006,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4006_left,
          name: 'sql-id:4006',
          cancelToken: source.token,
          t
        });
        // 右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4006,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4006_right,
          name: 'sql-id:4006',
          cancelToken: source.token,
          t
        });

        // ボックスチャート：設備稼働時間
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4018,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4018_left,
          name: 'sql-id:4018',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4019,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4019_left,
          name: 'sql-id:4019',
          cancelToken: source.token,
          t
        });
        // ボックスチャート：設備稼働時間
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4018,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4018_right,
          name: 'sql-id:4018',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4019,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4019_right,
          name: 'sql-id:4019',
          cancelToken: source.token,
          t
        });

        break;
      case 3:
        //実績数_不良率：月
        //左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4001,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData002,
          name: 'sql-id:4001',
          cancelToken: source.token,
          t
        });
        //実績数_不良率：月
        //右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4001,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData004,
          name: 'sql-id:4001',
          cancelToken: source.token,
          t
        });
        // 月ごと
        // ボックスチャート
        // 左
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4007,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4006_left,
          name: 'sql-id:4007',
          cancelToken: source.token,
          t
        });
        // 右
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4007,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4006_right,
          name: 'sql-id:4007',
          cancelToken: source.token,
          t
        });

        // ボックスチャート：設備稼働時間
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4020,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4018_left,
          name: 'sql-id:4020',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4021,
          parameters: {
            ...conditionsDate1,
            ...conditionsOther
          },
          ds_state: setData4019_left,
          name: 'sql-id:4021',
          cancelToken: source.token,
          t
        });
        // ボックスチャート：設備稼働時間
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4020,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4018_right,
          name: 'sql-id:4020',
          cancelToken: source.token,
          t
        });
        // 設備稼働時間：計算値
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4021,
          parameters: {
            ...conditionsDate2,
            ...conditionsOther
          },
          ds_state: setData4019_right,
          name: 'sql-id:4021',
          cancelToken: source.token,
          t
        });
        break;
    }
    await setStartProcess(false);
  };

  const [machineList_left, setMachineList_left] = useState([]);
  const [machineList_right, setMachineList_right] = useState([]);
  const [machineList_all, setMachineList_all] = useState([]);
  const makeBoxData = (
    baseData: any,
    makeBoxDataObj: any,
    makeBoxExportDataObj: any,
    searchType: number,
    select_date_from: string,
    select_date_to: string,
    machineList_leftObj: any
  ) => {
    let tmp_data: any = [];
    let tmp_data_export: any = [];
    let tmp_machine: any = [];
    if (baseData.length > 0) {
      tmp_machine = Array.from(new Set(baseData.map((a: any) => a.machine))).sort();
      machineList_leftObj(tmp_machine);
      let x_list: any = date_calc(searchType, select_date_from, select_date_to);

      let type_by_x_name: string;
      switch (searchType) {
        case 0:
          type_by_x_name = 'idx';
          break;
        case 1:
          type_by_x_name = 'year_month_day_hour';
          break;
        case 2:
          type_by_x_name = 'year_month_day';
          break;
        case 3:
          type_by_x_name = 'year_month';
          break;
      }

      if (searchType !== 0) {
        tmp_machine.forEach((a: string) => {
          x_list.forEach((b: string) => {
            if (
              baseData.filter((c: any) => c.machine === a && c[type_by_x_name] === b).length > 0
            ) {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: baseData.filter(
                  (c: any) => c.machine === a && c[type_by_x_name] === b
                )[0]['data_value']
              });
              let tmp_dv: any = baseData.filter(
                (c: any) => c.machine === a && c[type_by_x_name] === b
              )[0]['data_value'];
              for (const v of tmp_dv) {
                tmp_data_export.push({
                  machine: a,
                  x_list: b,
                  data_value: v
                });
              }
            } else {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: []
              });
            }
          });
        });
      } else {
        tmp_machine.forEach((a: string) => {
          baseData
            .filter((b: any) => b.machine === a)
            .forEach((c: any) => {
              tmp_data.push({
                machine: a,
                x_list: c.idx,
                data_value: [c.time_diff],
                start_time: c.start_time
              });
              tmp_data_export.push({
                machine: a,
                x_list: c.idx,
                data_value: c.time_diff,
                start_time: c.start_time
              });
            });
        });
      }
      makeBoxDataObj(tmp_data);
      makeBoxExportDataObj(tmp_data_export);
    }
  };

  useEffect(() => {
    makeBoxData(
      data4006_left,
      setData4006_box_left,
      setData4006_export_left,
      searchValue.searchType,
      searchValue.select_date_from1,
      searchValue.select_date_to1,
      setMachineList_left
    );
  }, [data4006_left]);
  useEffect(() => {
    makeBoxData(
      data4006_right,
      setData4006_box_right,
      setData4006_export_right,
      searchValue.searchType,
      searchValue.select_date_from2,
      searchValue.select_date_to2,
      setMachineList_right
    );
  }, [data4006_right]);

  useEffect(() => {
    let tmp: any = Array.from(
      new Set([...machineList_left, ...machineList_right].map((a: string) => a))
    ).sort();
    setMachineList_all(tmp);
    setStartProcess(false);
  }, [machineList_left, machineList_right]);

  const [machine_id_index_left, setMachine_id_index_left] = React.useState<any>([]);
  const [machine_id_index_right, setMachine_id_index_right] = React.useState<any>([]);
  const [machine_id_index_all, setMachine_id_index_all] = React.useState<any>([]);
  useEffect(() => {
    let tmp_data: any = [];
    let tmp_machine: any = [];
    if (data4018_left.length > 0) {
      tmp_machine = Array.from(new Set(data4018_left.map((item: any) => item.machine_id)));
      setMachine_id_index_left(tmp_machine);
      let x_list: any = date_calc(
        searchValue.searchType,
        searchValue.select_date_from1,
        searchValue.select_date_to1
      );

      let type_by_x_name: string;
      switch (searchValue.searchType) {
        case 0:
          type_by_x_name = 'idx';
          break;
        case 1:
          type_by_x_name = 'year_month_day_hour';
          break;
        case 2:
          type_by_x_name = 'year_month_day';
          break;
        case 3:
          type_by_x_name = 'year_month';
          break;
      }

      if (searchValue.searchType !== 0) {
        tmp_machine.forEach((a: string) => {
          x_list.forEach((b: string) => {
            if (
              data4018_left.filter((c: any) => c.machine_id === a && c[type_by_x_name] === b)
                .length > 0
            ) {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: data4018_left
                  .filter((c: any) => c.machine_id === a && c[type_by_x_name] === b)
                  .map((data: any) => data.time_diff)
              });
            } else {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: []
              });
            }
          });
        });
      } else {
        tmp_machine.forEach((a: string) => {
          data4018_left
            .filter((b: any) => b.machine_id === a)
            .forEach((c: any) => {
              tmp_data.push({
                machine: a,
                x_list: c.idx,
                data_value: [c.time_diff],
                start_time: c.begin_time
              });
            });
        });
      }
      setData4018_box_left(tmp_data);
    }
  }, [data4018_left]);

  useEffect(() => {
    let tmp_data: any = [];
    let tmp_machine: any = [];
    if (data4018_right.length > 0) {
      tmp_machine = Array.from(new Set(data4018_right.map((item: any) => item.machine_id)));
      setMachine_id_index_right(tmp_machine);
      let x_list: any = date_calc(
        searchValue.searchType,
        searchValue.select_date_from2,
        searchValue.select_date_to2
      );

      let type_by_x_name: string;
      switch (searchValue.searchType) {
        case 0:
          type_by_x_name = 'idx';
          break;
        case 1:
          type_by_x_name = 'year_month_day_hour';
          break;
        case 2:
          type_by_x_name = 'year_month_day';
          break;
        case 3:
          type_by_x_name = 'year_month';
          break;
      }

      if (searchValue.searchType !== 0) {
        tmp_machine.forEach((a: string) => {
          x_list.forEach((b: string) => {
            if (
              data4018_right.filter((c: any) => c.machine_id === a && c[type_by_x_name] === b)
                .length > 0
            ) {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: data4018_right
                  .filter((c: any) => c.machine_id === a && c[type_by_x_name] === b)
                  .map((data: any) => data.time_diff)
              });
            } else {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: []
              });
            }
          });
        });
      } else {
        tmp_machine.forEach((a: string) => {
          data4018_right
            .filter((b: any) => b.machine_id === a)
            .forEach((c: any) => {
              tmp_data.push({
                machine: a,
                x_list: c.idx,
                data_value: [c.time_diff],
                start_time: c.begin_time
              });
            });
        });
      }
      setData4018_box_right(tmp_data);
    }
  }, [data4018_right]);

  useEffect(() => {
    let tmp: any = Array.from(
      new Set([...machine_id_index_left, ...machine_id_index_right].map((a: string) => a))
    ).sort();
    setMachine_id_index_all(tmp);
  }, [machine_id_index_left, machine_id_index_right]);

  let data4006_column: any = {
    カメラ名: t('カメラ名'),
    枠名: t('枠名'),
    日時: t('日時'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };

  let data4018_column: any = {
    設備名: t('設備名'),
    日時: t('日時'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };

  let data4018_column_type0: any = {
    設備名: t('設備名'),
    作業開始時間: t('作業開始時間'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : '',
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: !isEmpty(searchValue.deviceid) ? searchValue.deviceid.join(',') : '',
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'dongleid',
        value: searchValue.dongleid,
        valueLabel: !isEmpty(searchValue.dongleid) ? searchValue.dongleid.join(',') : '',
        colName: t('オペレータ'),
        colWidth: 300
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel: searchValue.searchType,
        colName: t('集計単位'),
        colWidth: 300
      },
      {
        name: 'select_date_from1',
        value: searchValue.select_date_from1,
        valueLabel: searchValue.select_date_from1,
        colName: t('対象期間From1'),
        colWidth: 300
      },
      {
        name: 'select_date_to1',
        value: searchValue.select_date_to1,
        valueLabel: searchValue.select_date_to1,
        colName: t('対象期間To1'),
        colWidth: 300
      },
      {
        name: 'select_date_from2',
        value: searchValue.select_date_from2,
        valueLabel: searchValue.select_date_from2,
        colName: t('対象期間To2'),
        colWidth: 300
      },
      {
        name: 'select_date_to2',
        value: searchValue.select_date_to2,
        valueLabel: searchValue.select_date_to2,
        colName: t('対象期間From2'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [dongleid, setDongleid] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    // 情報取得： LFCAutocomplete：deviceid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });
    // 情報取得： LFCAutocomplete：dongleid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40003,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['dongleid']};
      });
      setDongleid(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid, dongleid]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.人ログ分析')}:${t('日時比較分析')}(${t('設備データあり')})`}
    >
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCAutocomplete
            name={'deviceid'}
            label={t('検査装置')}
            id={'deviceid'}
            size="small" // small/medium
            value={multiSelectData(deviceid, searchValue.deviceid)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={deviceid}
          />
          <LFCAutocomplete
            name={'dongleid'}
            label={t('オペレータ')}
            id={'dongleid'}
            size="small" // small/medium
            value={multiSelectData(dongleid, searchValue.dongleid)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={dongleid}
          />
          <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
            <span style={{marginTop: 10}}>{t('集計単位')}:</span>
            {searchType.map((item: any) => (
              <FormControlLabel
                value={item.id}
                key={'search_type-' + item.id}
                control={
                  <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                }
                label={
                  <Typography display="block" key={'search_type-body-' + item.id}>
                    {item.text}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_PERSONS_COMPARE_ANALYSIS}
            locationNo={LOCATIONID_PERSONS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <form>
                  <LFCFormRowGroup>
                    <LFCDatetimePicker
                      name={'select_date_from1'}
                      label={t('対象期間From')}
                      value={searchValue.select_date_from1}
                      required
                      onChange={handleChange}
                    />
                    <LFCDatetimePicker
                      name={'select_date_to1'}
                      label={t('対象期間To')}
                      value={searchValue.select_date_to1}
                      required
                      onChange={handleChange}
                    />
                  </LFCFormRowGroup>
                </form>
              </Grid>

              <Grid item xs={10}>
                <LFCChartsComboXList
                  title={`${t('実績数と不良率')}`}
                  source={data002}
                  xList={
                    searchValue.searchType !== 0
                      ? date_calc(
                          searchValue.searchType,
                          searchValue.select_date_from1,
                          searchValue.select_date_to1
                        )
                      : data002.map((a: any) => a.idx)
                  }
                  yLeft={{
                    type: 'bar',
                    name: `${t('実績数')}`,
                    dsColumn: 'total_count'
                  }}
                  yRight={{
                    type: 'line',
                    name: `${t('不良率')}(%)`,
                    dsColumn: 'ng_par',
                    markPoint: {
                      data: [
                        {type: 'min', itemStyle: {color: '#E91E63'}},
                        {type: 'max', itemStyle: {color: '#2196F3'}}
                      ]
                    },
                    markLine: {
                      symbol: 'arrow',
                      data: [
                        {
                          type: 'average',
                          label: {
                            position: 'end',
                            formatter: 'Ave. {c}',
                            backgroundColor: '#FFEB3B'
                          }
                        }
                      ]
                    }
                  }}
                  color={[green[500], red[500]]}
                  height="250px"
                  exportData={data002}
                  exportFields={{
                    lq_time: t('日付'),
                    total_count: t('実績数'),
                    ng: t('不良数'),
                    ng_par: t('不良率')
                  }}
                  exportFilename={`${t('実績数と不良率')}`}
                />
              </Grid>
              <Grid item xs={2}>
                <LFCSingleValues
                  columns={[
                    {
                      field: 'ok_count',
                      headerName: t('生産数'),
                      width: '100px',
                      unit: `${t('個')}`,
                      formatter: v => round(v, 3).toLocaleString()
                    },
                    {
                      field: 'ng_percent',
                      headerName: `${t('不良率')}`,
                      width: '100px',
                      unit: ' %',
                      formatter: v => round(v * 100, 1).toLocaleString()
                    }
                  ]}
                  source={data001}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <form>
                  <LFCFormRowGroup>
                    <LFCDatetimePicker
                      name={'select_date_from2'}
                      label={t('対象期間From')}
                      value={searchValue.select_date_from2}
                      required
                      onChange={handleChange}
                    />
                    <LFCDatetimePicker
                      name={'select_date_to2'}
                      label={t('対象期間To')}
                      value={searchValue.select_date_to2}
                      required
                      onChange={handleChange}
                    />
                  </LFCFormRowGroup>
                </form>
              </Grid>
              <Grid item xs={2}>
                <LFCSingleValues
                  columns={[
                    {
                      field: 'ok_count',
                      headerName: t('生産数'),
                      width: '100px',
                      unit: `${t('個')}`,
                      formatter: v => round(v, 3).toLocaleString()
                    },
                    {
                      field: 'ng_percent',
                      headerName: `${t('不良率')}`,
                      width: '100px',
                      unit: ' %',
                      formatter: v => round(v * 100, 1).toLocaleString()
                    }
                  ]}
                  source={data003}
                />
              </Grid>
              <Grid item xs={10}>
                <LFCChartsComboXList
                  title={`${t('実績数と不良率')}`}
                  source={data004}
                  xList={
                    searchValue.searchType !== 0
                      ? date_calc(
                          searchValue.searchType,
                          searchValue.select_date_from2,
                          searchValue.select_date_to2
                        )
                      : data004.map((a: any) => a.idx)
                  }
                  yLeft={{
                    type: 'bar',
                    name: `${t('実績数')}`,
                    dsColumn: 'total_count'
                  }}
                  yRight={{
                    type: 'line',
                    name: `${t('不良率')}(%)`,
                    dsColumn: 'ng_par',
                    markPoint: {
                      data: [
                        {type: 'min', itemStyle: {color: '#E91E63'}},
                        {type: 'max', itemStyle: {color: '#2196F3'}}
                      ]
                    },
                    markLine: {
                      symbol: 'arrow',
                      data: [
                        {
                          type: 'average',
                          label: {
                            position: 'end',
                            formatter: 'Ave. {c}',
                            backgroundColor: '#FFEB3B'
                          }
                        }
                      ]
                    }
                  }}
                  color={[green[500], red[500]]}
                  height="250px"
                  exportData={data004}
                  exportFields={{
                    lq_time: t('日付'),
                    total_count: t('実績数'),
                    ng: t('不良数'),
                    ng_par: t('不良率')
                  }}
                  exportFilename={`${t('実績数と不良率')}`}
                />
              </Grid>
            </Grid>
          </Grid>

          {machine_id_index_all.map((a: string, index: number) => {
            return (
              <Grid container key={'base_0' + index}>
                {index === 0 ? (
                  <Grid item xs={12} style={{fontWeight: 'bold', marginBottom: 10}}>
                    {t('設備稼働時間')}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={5}>
                  <LFCChartsBoxPerson2
                    // 生産間隔_時間推移
                    title=""
                    source={data4018_box_left.filter((b: any) => b.machine === a)}
                    exportData={data4018_left}
                    exportFields={
                      searchValue.searchType !== 0 ? data4018_column : data4018_column_type0
                    }
                    exportFilename={`${t('設備稼働時間')}_${t('左')}_`}
                    machineName={a.split('..')[0]}
                    height={'200px'}
                    grid={{top: 50, bottom: 50, left: 70, right: 60}}
                    bottom={'80'}
                    yAxisName={`${t('設備稼働時間')}(${t('秒')})`}
                    xlist={
                      searchValue.searchType !== 0
                        ? date_calc(
                            searchValue.searchType,
                            searchValue.select_date_from1,
                            searchValue.select_date_to1
                          )
                        : data4018_left
                            .filter((b: any) => b.machine_id === a)
                            .map((c: any) => c.idx)
                    }
                    searchType={searchValue.searchType}
                  />
                </Grid>

                <Grid item xs={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{minWidth: 250}} size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} style={{width: 200}}>
                            {a}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            {t('作業時間')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2} style={{width: 200}}>
                            {data4019_left.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_left.filter((b: any) => b.machine_id === a)[0][
                                    'data_min'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2} style={{width: 200}}>
                            {t('最小')}
                          </TableCell>
                          <TableCell align="right" colSpan={2} style={{width: 200}}>
                            {data4019_right.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_right.filter((b: any) => b.machine_id === a)[0][
                                    'data_min'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4019_left.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_left.filter((b: any) => b.machine_id === a)[0][
                                    'data_avg'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('平均')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4019_right.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_right.filter((b: any) => b.machine_id === a)[0][
                                    'data_avg'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4019_left.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_left.filter((b: any) => b.machine_id === a)[0][
                                    'data_max'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('最大')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4019_right.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_right.filter((b: any) => b.machine_id === a)[0][
                                    'data_max'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4019_left.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_left.filter((b: any) => b.machine_id === a)[0][
                                    'data_stddev'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('標準偏差')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4019_right.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_right.filter((b: any) => b.machine_id === a)[0][
                                    'data_stddev'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4019_left.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_left.filter((b: any) => b.machine_id === a)[0][
                                    'data_total'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('合計')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4019_right.filter((b: any) => b.machine_id === a)[0] !== undefined
                              ? round(
                                  data4019_right.filter((b: any) => b.machine_id === a)[0][
                                    'data_total'
                                  ],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={5}>
                  <LFCChartsBoxPerson2
                    // 生産間隔_時間推移
                    title=""
                    source={data4018_box_right.filter((b: any) => b.machine === a)}
                    exportData={data4018_right}
                    exportFields={
                      searchValue.searchType !== 0 ? data4018_column : data4018_column_type0
                    }
                    machineName={a.split('..')[0]}
                    exportFilename={`${t('設備稼働時間')}_${t('右')}_`}
                    height={'200px'}
                    grid={{top: 50, bottom: 50, left: 70, right: 60}}
                    bottom={'80'}
                    yAxisName={`${t('設備稼働時間')}(${t('秒')})`}
                    xlist={
                      searchValue.searchType !== 0
                        ? date_calc(
                            searchValue.searchType,
                            searchValue.select_date_from2,
                            searchValue.select_date_to2
                          )
                        : data4018_right
                            .filter((b: any) => b.machine_id === a)
                            .map((c: any) => c.idx)
                    }
                    searchType={searchValue.searchType}
                  />
                </Grid>
              </Grid>
            );
          })}

          {machineList_all.map((a: string, index: number) => {
            return (
              <Grid container key={'base_' + index}>
                {index === 0 ? (
                  <Grid item xs={12} style={{fontWeight: 'bold', marginBottom: 10}}>
                    {t('人の作業時間')}
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={5}>
                  <LFCChartsBoxPerson1
                    // 生産間隔_時間推移
                    title=""
                    source={data4006_box_left.filter((b: any) => b.machine === a)}
                    exportData={data4006_export_left}
                    exportFields={data4006_column}
                    cameraName={a.split('..')[0]}
                    frameNo={a.split('..')[1]}
                    exportFilename={`${t('作業時間チャート')}_${t('左')}_`}
                    height={'300px'}
                    grid={{top: 50, bottom: 70, left: 75, right: 60}}
                    yAxisName={`${t('作業時間チャート')}(${t('秒')})`}
                    xlist={
                      searchValue.searchType !== 0
                        ? date_calc(
                            searchValue.searchType,
                            searchValue.select_date_from1,
                            searchValue.select_date_to1
                          )
                        : data4006_box_left[0] !== undefined
                          ? data4006_left.filter((b: any) => b.machine === a).map((c: any) => c.idx)
                          : []
                    }
                    searchType={searchValue.searchType}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TableContainer component={Paper}>
                    <Table sx={{minWidth: 250}} size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} style={{width: 200}}>
                            【{t('カメラ')}】{a.split('..')[0]}【{t('枠')}】{a.split('..')[1]}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="center" colSpan={6}>
                            {t('作業時間')}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2} style={{width: 200}}>
                            {data4004_left.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_left.filter((b: any) => b.key === a)[0]['data_min'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2} style={{width: 200}}>
                            {t('最小')}
                          </TableCell>
                          <TableCell align="right" colSpan={2} style={{width: 200}}>
                            {data4004_right.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_right.filter((b: any) => b.key === a)[0]['data_min'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4004_left.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_left.filter((b: any) => b.key === a)[0]['data_avg'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('平均')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4004_right.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_right.filter((b: any) => b.key === a)[0]['data_avg'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4004_left.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_left.filter((b: any) => b.key === a)[0]['data_max'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('最大')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4004_right.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_right.filter((b: any) => b.key === a)[0]['data_max'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4004_left.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_left.filter((b: any) => b.key === a)[0]['data_stddev'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('標準偏差')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4004_right.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_right.filter((b: any) => b.key === a)[0]['data_stddev'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right" colSpan={2}>
                            {data4004_left.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_left.filter((b: any) => b.key === a)[0]['data_total'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                          <TableCell align="center" colSpan={2}>
                            {t('合計')}
                          </TableCell>
                          <TableCell align="right" colSpan={2}>
                            {data4004_right.filter((b: any) => b.key === a)[0] !== undefined
                              ? round(
                                  data4004_right.filter((b: any) => b.key === a)[0]['data_total'],
                                  1
                                ).toLocaleString()
                              : '-'}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={5}>
                  <LFCChartsBoxPerson1
                    // 生産間隔_時間推移
                    title=""
                    source={data4006_box_right.filter((b: any) => b.machine === a)}
                    exportData={data4006_export_right}
                    exportFields={data4006_column}
                    cameraName={a.split('..')[0]}
                    frameNo={a.split('..')[1]}
                    exportFilename={`${t('作業時間チャート')}_${t('右')}_`}
                    height={'300px'}
                    grid={{top: 50, bottom: 70, left: 75, right: 60}}
                    yAxisName={`${t('作業時間チャート')}(${t('秒')})`}
                    xlist={
                      searchValue.searchType !== 0
                        ? date_calc(
                            searchValue.searchType,
                            searchValue.select_date_from2,
                            searchValue.select_date_to2
                          )
                        : data4006_box_right[0] !== undefined
                          ? data4006_right
                              .filter((b: any) => b.machine === a)
                              .map((c: any) => c.idx)
                          : []
                    }
                    searchType={searchValue.searchType}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default CompareAnalysisWithFacilityPage;
