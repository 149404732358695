import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  Paper,
  Typography
} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {ReactComponent as LFCTitleImgWide} from '_assets/images/lfc-logo-1.svg';
import {ReactComponent as LFCTitleImg} from '_assets/images/lfc-logo-3.svg';
import Copyright from '_components/common/Copyright';
import LFCButton from '_components/inputs/LFCButton';
import LFCPasswordField from '_components/inputs/LFCPasswordField';
import LFCTextField from '_components/inputs/LFCTextField';
import {getNewCognitoUser} from '_logics/LFCCognito';
import {handleInputChange} from '_logics/LFCUtil';
import OpenSiteTemplate from '_templates/OpenSiteTemplate';

/**
 * パスワードリセット
 * @returns
 */
const PasswordResetPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [openFinishPop, setOpenFinishPop] = useState(false);

  const [values, setValues] = useState({
    userId: '',
    verificationCode: '',
    newPassword1: '',
    newPassword2: ''
  });

  /**
   * 保存ボタン押下
   * @param event
   */
  const confirmPassword = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    // input check(一致)
    if (values.newPassword1 !== values.newPassword2) {
      enqueueSnackbar(t('message.確認パスワードが一致しません。'), {variant: 'error'});
      return;
    }

    const cognitoUser = getNewCognitoUser(values.userId);
    cognitoUser.confirmPassword(values.verificationCode, values.newPassword1, {
      onSuccess() {
        // 完了ダイアログ表示
        setOpenFinishPop(true);
      },
      onFailure(err) {
        console.error(err);
        enqueueSnackbar(t(`aws.cognito.${err.message}`), {variant: 'error'});
      }
    });
  };

  /**
   * OKボタン押下
   * @param event
   */
  const finish = () => {
    window.location.href = '/';
  };

  return (
    <OpenSiteTemplate>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        {/* フォーム */}
        <Box position="absolute" width="80%" maxWidth="1000px">
          <Grid container justifyContent="center" alignItems="center">
            <Hidden mdUp>
              <Grid item xs={12}>
                <LFCTitleImgWide fill="white" width="100%" height="50" />
              </Grid>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
              <Grid item md={6}>
                <LFCTitleImg fill="white" width="90%" />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{backgroundColor: 'rgba(0,0,0,0.2)', padding: 20}}>
                <Box mb={1} textAlign="center">
                  <Typography variant="h6">{t('パスワードリセット')}</Typography>
                  <Typography variant="caption">
                    <Trans i18nKey={'message.新パスワード入力依頼文章'} />
                  </Typography>
                </Box>
                <Grid container justifyContent="center">
                  <Grid item xs={12}>
                    <form ref={formRef}>
                      <LFCTextField
                        name="userId"
                        label={t('ユーザID')}
                        value={values.userId}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                        autoFocus
                      />
                      <LFCTextField
                        name="verificationCode"
                        label={t('確認コード')}
                        value={values.verificationCode}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                      />
                      <LFCPasswordField
                        name="newPassword1"
                        label={t('新パスワード')}
                        value={values.newPassword1}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                        inputProps={{minLength: 8}}
                      />
                      <LFCPasswordField
                        name="newPassword2"
                        label={t('新パスワード(確認)')}
                        value={values.newPassword2}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                        inputProps={{minLength: 8}}
                      />
                    </form>
                  </Grid>
                </Grid>
                <Box mt={1} textAlign="center">
                  <LFCButton color="primary" size="large" onClick={confirmPassword}>
                    {t('実行')}
                  </LFCButton>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
        {/* コピーライト */}
        <Box position="absolute" right={0} bottom={0} mr={1}>
          <Copyright />
        </Box>
        {/* 完了ダイアログ */}
        <Dialog open={openFinishPop} aria-labelledby="form-dialog-title" fullWidth>
          <DialogTitle id="form-dialog-title">{t('完了')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('パスワード変更が完了しました。ログインページへ遷移します。')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LFCButton color="primary" onClick={finish}>
              OK
            </LFCButton>
          </DialogActions>
        </Dialog>
      </Box>
    </OpenSiteTemplate>
  );
};
export default PasswordResetPage;
