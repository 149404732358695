export type SystemSettings = {
  [key: string]: any; //動的セット用

  RELOAD_TIME: number; //リロード間隔(X秒)
};

// 初期値定義
export const SystemSettingsInitVal: SystemSettings = {
  RELOAD_TIME: 60
};
