import dayjs from 'dayjs';
import React from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  };
  yLeft: {
    type: string;
    name: string;
    dsColumn: any;
    markPoint?: any;
    markLine?: any;
  };
  yRight: {
    type: string;
    name: string;
    dsColumn: any;
    markPoint?: any;
    markLine?: any;
  };
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string[];
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  onClick?: (prm: {data: any}) => void;
}

/**
 * LFCChartsComboHH
 * @param props
 * @returns
 */
const LFCChartsComboHH = React.memo((props: Props) => {
  const {t} = useTranslation();
  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '65',
              left: '60',
              right: '63',
              bottom: '93'
            },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 10
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color ? props.color : null,
        dataset: {
          source: props.source
        },
        xAxis: [
          {
            type: 'category',
            name: t('時刻'),
            nameGap: 45,
            nameLocation: 'middle',
            axisLabel: {
              rotate: '45',
              fontSize: 10
            },
            data: (() => {
              let list = [];
              let dt = props.x.startDate;
              for (let i = 0; dt.add(i, 'h') <= props.x.endDate; i++) {
                list.push(dt.add(i, 'h').format('MM-DD HH'));
              }
              return list;
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: props.yLeft.name,
            nameTextStyle: {
              color: props.color ? props.color[0] : null
            },
            axisLabel: {
              color: props.color ? props.color[0] : null
            },
            axisLine: {
              lineStyle: {
                color: props.color ? props.color[0] : null
              }
            },
            axisTick: {
              lineStyle: {
                color: props.color ? props.color[0] : null
              }
            }
          },
          {
            type: 'value',
            name: props.yRight.name,
            nameTextStyle: {
              color: props.color ? props.color[1] : null
            },
            axisLabel: {
              color: props.color ? props.color[1] : null
            },
            axisLine: {
              lineStyle: {
                color: props.color ? props.color[1] : null
              }
            },
            axisTick: {
              lineStyle: {
                color: props.color ? props.color[1] : null
              }
            }
          }
        ],
        series: [
          {
            type: props.yLeft.type,
            name: props.yLeft.name,
            xAxisIndex: 0,
            yAxisIndex: 0,
            encode: {
              // x: props.x.dsColumn,
              y: props.yLeft.dsColumn
            },
            markPoint: props.yLeft.markPoint,
            markLine: props.yLeft.markLine
          },
          {
            type: props.yRight.type,
            name: props.yRight.name,
            xAxisIndex: 0,
            yAxisIndex: 1,
            encode: {
              // x: props.x.dsColumn,
              y: props.yRight.dsColumn
            },
            markPoint: props.yRight.markPoint,
            markLine: props.yRight.markLine
          }
        ]
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick(params);
          }
        }
      }}
    />
  );
});
export default LFCChartsComboHH;
