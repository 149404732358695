import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Theme} from '@mui/material';
import React from 'react';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: 120
  }
}));

interface Props {
  name: string;
  label: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  selectItem: any;
  id: string;
}

const LFCSelectForm = (props: Props) => {
  const classes = useStyles();

  const ITEM_HEIGHT = 50;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6
      }
    }
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      style={props.style}
      size={props.size !== undefined ? props.size : 'small'}
      fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
      disabled={props.disabled !== undefined ? props.disabled : false}
      required={props.required !== undefined ? props.required : false}
      margin={props.margin}
    >
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
      <Select
        id={props.id}
        name={props.name}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        multiple={props.multiple}
        MenuProps={MenuProps}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : false}
        style={props.style}
      >
        {props.selectItem.map((item: any, index: number) => {
          const lvalue = item.value !== undefined ? item.value : item.name;
          const lHeight = item.value !== undefined ? '36px' : undefined;
          return (
            <MenuItem value={lvalue} key={index} style={{...props.style, lineHeight: lHeight}}>
              {item.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export default LFCSelectForm;
