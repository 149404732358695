import {useEffect, useRef, useState} from 'react';
import {Button, Checkbox, IconButton, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import {GridColumns} from '@mui/x-data-grid-pro';
import {DataGridPro} from '@mui/x-data-grid-pro';
import LFCButton from '_components/inputs/LFCButton';
import {Bookmarks} from '@mui/icons-material';
import axios from 'axios';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import {useAuthUser} from '_contexts/AuthUserProvider';
import {useSnackbar} from 'notistack';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import {useReloadSavedCondition} from '_contexts/SavedConditionProvider';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import {useTranslation} from 'react-i18next';

export type ConditionParamer = {
  name: string;
  value: any;
  valueLabel: string;
  colName: string;
  colWidth: number;
};
export type Props = {
  open: boolean;
  pageId: number;
  locationNo: number;
  conditions: ConditionParamer[];
  onLoad: () => void;
  onSelect: (conditionValues: any) => void;
  onClose: () => void;
  style?: {};
};

const LFCSavedCondition = (props: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const authUser = useAuthUser();
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [isAdded, setIsAdded] = useState(false);
  const reloadSavedCondition = useReloadSavedCondition();

  // 保存した検索条件の一覧取得
  const onSearch = () => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 1001,
      parameters: {
        page_id: props.pageId,
        location_no: props.locationNo
      },
      cancelToken: source.token,
      t
    }).then(data => {
      const savedData = data.map((item: any, idx: number) => {
        // 基本列の作成
        let rowData = {
          id: String(idx),
          base_col__search_id: item['search_id'],
          base_col__user_id: item['user_id'],
          base_col__page_id: item['page_id'],
          base_col__location_no: item['location_no'],
          base_col__condition_json: item['condition_json'],
          base_col__default_flg: item['default_flg']
        };

        // condition_jsonから画面表示データに変換
        const condJson = JSON.parse(item['condition_json']);
        for (const cond of props.conditions) {
          const result = condJson.find((obj: any) => {
            return obj.name === cond.name;
          });
          rowData = {...rowData, [cond.name]: result?.valueLabel};
        }

        return rowData;
      });
      setDgRows(savedData);
    });
  };

  // 現在の条件を追加する
  const onClickAdd = async () => {
    setIsAdded(true);

    const condJson = props.conditions.map((item: any, idx: any) => {
      return {
        name: item.name,
        value: item.value,
        valueLabel: item.valueLabel
      };
    });

    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 1002,
      parameters: {
        page_id: props.pageId,
        location_no: props.locationNo,
        condition_json: JSON.stringify(condJson)
      },
      cancelToken: source.token,
      name: `${t('検索条件の保存')}`,
      t
    });

    onSearch();
  };

  // 選択
  const onSelectClick = (condition_json: string) => {
    const condJson = JSON.parse(condition_json);
    props.onSelect(
      condJson.reduce((acc: any, cur: any) => {
        return {...acc, [cur.name]: cur.value};
      }, {})
    );
  };

  // 初期設定
  const onDefaultClick = async (searchId: number) => {
    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 1003,
      parameters: {
        search_id: searchId,
        page_id: props.pageId,
        location_no: props.locationNo
      },
      cancelToken: source.token,
      name: `${t('初期設定の変更')}`,
      t
    });

    onSearch();

    // 初期設定が変更されたので、コンテキスト再読み込み
    reloadSavedCondition();
  };

  // 削除
  const onDeleteClick = async (searchId: number, default_flg: boolean) => {
    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 1004,
      parameters: {
        search_id: searchId
      },
      cancelToken: source.token,
      name: `${t('条件の削除')}`,
      t
    });

    onSearch();

    // 初期設定された検索条件が削除された場合はコンテキスト再読み込み
    default_flg && reloadSavedCondition();
  };

  useEffect(() => {
    if (props.open) {
      // DataGrid-前列
      const frontCol: GridColumns = [
        {field: 'id', hide: true},
        {
          field: 'base_col__select',
          headerName: '',
          width: 70,
          align: 'center',
          renderCell: params => {
            return (
              <LFCButton
                color="primary"
                onClick={() => onSelectClick(params.row.base_col__condition_json)}
                size="small"
              >
                {t('選択')}
              </LFCButton>
            );
          }
        },
        {
          field: 'base_col__default_flg',
          headerName: `${t('初期設定')}`,
          width: 80,
          align: 'center',
          renderCell: params => {
            return (
              <Checkbox
                color="primary"
                size="small"
                checked={params.row.base_col__default_flg}
                onClick={() => {
                  onDefaultClick(params.row.base_col__search_id);
                }}
              />
            );
          }
        }
      ];

      // DataGrid-画面別の検索条件表示列
      const condCol: GridColumns = props.conditions.map((item: any, idx: any) => {
        return {
          field: item.name,
          headerName: item.colName,
          width: item.colWidth
        };
      });

      // DataGrid-後列
      const backCol: GridColumns = [
        {
          field: 'base_col__delete',
          headerName: '',
          width: 70,
          align: 'center',
          renderCell: params => {
            return (
              <LFCButton
                color="secondary"
                onClick={() =>
                  onDeleteClick(params.row.base_col__search_id, params.row.base_col__default_flg)
                }
                size="small"
              >
                {t('削除')}
              </LFCButton>
            );
          }
        }
      ];

      setDgColumns([...frontCol, ...condCol, ...backCol]);
      onSearch();
    }

    return () => {
      // アンマウント処理
      setIsAdded(false);
      setDgRows([]);
    };
  }, [props.conditions]);

  return (
    <>
      <IconButton onClick={props.onLoad} sx={props.style ? props.style : undefined}>
        <Bookmarks />
      </IconButton>
      <Dialog
        sx={props.style ? props.style : undefined}
        open={props.open}
        onClose={props.onClose}
        maxWidth={'xl'}
        fullWidth
      >
        <LFCDialogTitle>{t('保存した検索条件')}</LFCDialogTitle>
        <DialogContent>
          <form ref={formRef}>
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  {t('message.検索条件の保存、または呼び出しを行います。')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={onClickAdd}
                      disabled={isAdded}
                      startIcon={<BookmarkAddIcon />}
                    >
                      {t('現在の条件を追加する')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <LFCButton color="primary" onClick={props.onClose}>
                      {t('キャンセル')}
                    </LFCButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box style={{height: '60vh'}}>
                  <DataGridPro
                    // apiRef={apiRef}
                    columns={dgColumns}
                    rows={dgRows}
                    density={'compact'}
                    showCellRightBorder
                    showColumnRightBorder
                    disableSelectionOnClick
                    hideFooterRowCount
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default LFCSavedCondition;
