import {colors, createTheme, ThemeOptions, ThemeProvider} from '@mui/material';
import {enUS as coreEnUS, jaJP as coreJaJP} from '@mui/material/locale';
import {enUS, jaJP} from '@mui/x-data-grid-pro';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import {useTranslation} from 'react-i18next';
import {useMySettings} from '_contexts/MySettingsProvider';
import {getColors, getFontFamily} from '_themes/LFCCommonThemes';
export interface Props {
  children: React.ReactNode;
}

/**
 * LFCThemeProvider(V5)
 * @returns
 */
const LFCThemeProviderV5 = (props: Props) => {
  const {i18n} = useTranslation();
  const mySettings = useMySettings();

  /**
   * テーマ設定
   * @returns
   */
  const theme = (): ThemeOptions => {
    const comColors = getColors(mySettings.isDark ? 'dark' : 'light');

    return {
      typography: {
        fontFamily: getFontFamily()
      },
      mixins: {
        toolbar: {
          backgroundColor: 'inherit',
          minHeight: 51,
          '@media (min-width:0px) and (orientation: landscape)': {
            minHeight: 43
          },
          '@media (min-width:600px)': {
            minHeight: 59
          }
        }
      },
      palette: {
        mode: mySettings.isDark ? 'dark' : 'light',
        primary: {
          main: comColors.primaryColor
        },
        secondary: {
          main: comColors.secondaryColor
        },
        text: comColors.textColor,
        divider: comColors.dividerColor,
        background: comColors.backgroundColor
      },
      zIndex: {
        fab: 1101
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: {
            '::-webkit-scrollbar': {
              width: '10px',
              height: '15px'
            },
            '::-webkit-scrollbar-track': {
              backgroundColor: comColors.scrollbarColor.back
            },
            '::-webkit-scrollbar-thumb': {
              border: `2px solid ${comColors.scrollbarColor.back}`,
              backgroundColor: comColors.scrollbarColor.bar,
              transition: 'background-color 100ms ease-out 0s'
            },
            '::-webkit-calendar-picker-indicator': {
              filter: 'invert(1) brightness(0.5)'
              // filter: "invert(0.8) sepia(100%) saturate(10000%) hue-rotate(240deg)",
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: comColors.textColor.primary,
              marginRight: 5,
              background: mySettings.isDark
                ? 'linear-gradient(135deg, rgb(14,14,14) 0%, rgb(38,38,38) 100%)'
                : 'linear-gradient(135deg, rgb(230,230,230) 0%, rgb(254,254,254) 100%)',
              boxShadow: mySettings.isDark
                ? '-5px 5px 10px rgba(0, 0, 0, 0.2), 5px -5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(50, 50, 50, 0.9), 5px 5px 12.5px rgba(0, 0, 0, 0.9), inset 1px 1px 2px rgba(50, 50, 50, 0.3), inset -1px -1px 2px rgba(0, 0, 0, 0.5)'
                : '-5px 5px 10px rgba(205, 205, 205, 0.2), 5px -5px 10px rgba(205, 205, 205, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 12.5px rgba(205, 205, 205, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(205, 205, 205, 0.5)'
            }
          }
        },
        MuiAppBar: {
          styleOverrides: {
            colorPrimary: {
              color: comColors.textColor.primary,
              backgroundColor: comColors.backgroundColor.paper,
              // boxShadow: "none",
              boxShadow: `0 0 0.3rem ${comColors.shadowColor}}`
            }
          }
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              // backgroundImage: "none !important",
              background: mySettings.isDark
                ? 'linear-gradient(135deg, rgb(14,14,14) 0%, rgb(38,38,38) 100%)'
                : 'linear-gradient(135deg, rgb(230,230,230) 0%, rgb(254,254,254) 100%)',
              boxShadow: mySettings.isDark
                ? '-5px 5px 10px rgba(0, 0, 0, 0.2), 5px -5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(50, 50, 50, 0.9), 5px 5px 12.5px rgba(0, 0, 0, 0.9), inset 1px 1px 2px rgba(50, 50, 50, 0.3), inset -1px -1px 2px rgba(0, 0, 0, 0.5)'
                : '-5px 5px 10px rgba(205, 205, 205, 0.2), 5px -5px 10px rgba(205, 205, 205, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.9), 5px 5px 12.5px rgba(205, 205, 205, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(205, 205, 205, 0.5)',
              borderRadius: '10px'
            }
          }
        },
        MuiDialog: {
          styleOverrides: {
            paper: {
              elevation: 24,
              backgroundColor: comColors.backgroundColor.paper,
              backgroundImage: 'none !important',
              boxShadow: `0 0 100rem ${comColors.shadowColor}}`
            }
          }
        },
        MuiBackdrop: {
          styleOverrides: {
            root: {
              backgroundColor: comColors.backgroundColorBackdrop
            }
          }
        },
        MuiDataGrid: {
          styleOverrides: {
            root: {
              backgroundColor: 'inherit',
              // "& .MuiDataGrid-main": {
              //   backgroundColor: "inherit",
              // },
              '& .MuiDataGrid-overlay': {
                backgroundColor: 'inherit'
              },
              '& .MuiDataGrid-pinnedColumnHeaders, & .MuiDataGrid-pinnedColumns': {
                backgroundColor: comColors.backgroundColor.paper,
                borderRightWidth: '1px',
                borderRightStyle: 'solid',
                borderRightColor: mySettings.isDark ? colors.blueGrey[700] : colors.blueGrey[100],
                backgroundImage: 'none !important'
              }
            }
          }
        }
      }
    };
  };

  /**
   * 言語設定
   * @returns
   */
  const lang = () => {
    let res = [];

    // MUIの言語切替
    switch (i18n.language) {
      case 'en':
        res = [enUS, coreEnUS];
        break;

      default:
        res = [jaJP, coreJaJP];
        break;
    }

    return res;
  };

  return <ThemeProvider theme={createTheme(theme(), ...lang())}>{props.children}</ThemeProvider>;
};
export default LFCThemeProviderV5;
