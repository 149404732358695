import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState, useCallback} from 'react';
import {Box, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import ProgressBar from '_components/ProgressBar';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCButton from '_components/inputs/LFCButton';
import dayjs from 'dayjs';
import axios from 'axios';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import HoleCompareDiameterDig from './HoleCompareDiameterDig';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_HOLEDIAMETER} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import LFCChartsBox4 from '../../_components/charts/LFCChartsBox4';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      '& > *': {
        margin: theme.spacing(1)
      }
    }
  };
});

const HoleCompareDiameterPage = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_HOLEDIAMETER) ?? {
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    }
  );

  const [listData, setListData] = useState([]);
  const [map_series, setMap_series] = useState([]);

  const [graphBaseData, setGraphBaseData] = useState([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setListData([]);
  };

  const date_calc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const doSearch = async () => {
    await setStartProcess(true);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 207,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        deviceid: !isEmpty(searchValue.deviceid)
          ? '{' + searchValue.deviceid.join(',') + '}'
          : null,
        judge: searchValue.judge === 9 ? null : searchValue.judge
      },
      ds_state: setListData,
      name: 'id-207',
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  };

  useEffect(() => {
    setMap_series(Array.from(new Set(listData.map(({map}) => map))).sort());

    let tmp_all: any = [];
    Array.from(new Set(listData.map(({map}) => map)))
      .sort()
      .forEach((map_string: string) => {
        let tmp_data: any = [];
        if (searchValue.searchType !== 0) {
          date_calc().forEach((lq_time_string: string) => {
            let tmp: any = [];
            listData.forEach((item: any) => {
              switch (searchValue.searchType) {
                case 1:
                  if (item.year_month_day_hour === lq_time_string && item.map === map_string) {
                    tmp.push(Number(item['work_radius']));
                  }
                  break;
                case 2:
                  if (item.year_month_day === lq_time_string && item.map === map_string) {
                    tmp.push(Number(item['work_radius']));
                  }
                  break;
                case 3:
                  if (item.year_month === lq_time_string && item.map === map_string) {
                    tmp.push(Number(item['work_radius']));
                  }
                  break;
              }
            });
            tmp_data.push(tmp);
          });

          tmp_all.push({
            map: map_string,
            data_value: tmp_data,
            xlist: date_calc()
          });
        } else {
          tmp_all.push({
            map: map_string,
            data_value: listData
              .filter((a: any) => a.map === map_string)
              .map((b: any) => b.work_radius),
            xlist: listData.filter((a: any) => a.map === map_string).map((b: any) => b.idx)
          });
        }
      });

    setGraphBaseData(tmp_all);
  }, [listData]);

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    });
    setListData([]);
    setMap_series([]);
    setGraphBaseData([]);
    setAutoCompleteReset(true);
  };

  const graphClick = (datas: any) => {
    setOpenData(datas);
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const [openData, setOpenData] = React.useState([]);
  const handleClose = () => {
    setOpenData([]);
    setOpen(false);
  };

  let listData_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: t('Q3'),
    d2: t('中央(orQ2)'),
    d3: t('Q1'),
    d4: t('最小')
  };

  let listData_column_type0 = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    work_radius: t('穴径'),
    serial: t('シリアルナンバー')
  };

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel:
          searchValue.select_date_from === ''
            ? ''
            : dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間From'),
        colWidth: 200
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel:
          searchValue.select_date_to === ''
            ? ''
            : dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間To'),
        colWidth: 200
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : '',
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: !isEmpty(searchValue.deviceid) ? searchValue.deviceid.join(',') : '',
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge === 0 ? 'OK' : 'NG',
        colName: t('総合判定'),
        colWidth: 100
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel: searchValue.searchType,
        colName: t('集計単位'),
        colWidth: 100
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42009,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42010,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.L-Qジョブ傾向分析')}：${t('menu.page_name.Hole Compare 穴径')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <Box>
        <form className={classes.search}>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name={t('select_date_from')}
              label={t('対象期間From')}
              value={searchValue.select_date_from}
              required
              onChange={handleChange}
              disabled={startProcess}
            />
            <LFCDatetimePicker
              name={t('select_date_to')}
              label={t('対象期間To')}
              value={searchValue.select_date_to}
              required
              onChange={handleChange}
              disabled={startProcess}
            />
            <LFCAutocomplete
              name={t('work')}
              label={t('機種')}
              id={'work'}
              size="small"
              value={multiSelectData(work, searchValue.work)}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={work}
              disabled={startProcess}
            />
            <LFCAutocomplete
              name={'deviceid'}
              label={t('検査装置')}
              id={'deviceid'}
              size="small"
              value={multiSelectData(deviceid, searchValue.deviceid)}
              onChange={autoOnChange}
              multiple={true}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              selectItem={deviceid}
              disabled={startProcess}
            />
            <LFCSelectFormJudge
              name={'judge'}
              value={searchValue.judge}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              multiple={false}
              disabled={startProcess}
            />
            <LFCButton color="primary" onClick={doSearch} disabled={startProcess}>
              {t('検索')}
            </LFCButton>
            <LFCButton onClick={restSearch} disabled={startProcess}>
              {t('リセット')}
            </LFCButton>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={PAGEID_LQ_JOBANALYSIS}
              locationNo={LOCATIONID_LQ_JOBANALYSIS_HOLEDIAMETER}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
          </LFCFormRowGroup>
        </form>
        <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
        <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
          {searchType.map((item: any, index: number) => (
            <FormControlLabel
              disabled={startProcess}
              value={item.id}
              key={'search_type-' + item.id}
              control={
                <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
              }
              label={
                <Typography display="block" key={'search_type-body-' + item.id}>
                  {item.text}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </Box>
      <Box mt={1}>
        <Grid container spacing={2}>
          {map_series.map((item: string, index) => {
            return (
              <Grid item xs={12} md={6} key={index}>
                {t('部位名')}:{item}
                {graphBaseData.filter((data: any) => data.map === item)[0] ? (
                  <LFCChartsBox4
                    title=""
                    source={
                      graphBaseData.length > 0
                        ? graphBaseData.filter((data: any) => data.map === item)[0]
                        : false
                    }
                    x={{dsColumn: 'xlist', type: 'category'}}
                    y={{dsColumn: 'data_value', type: 'value'}}
                    exportData={listData}
                    exportFields={
                      searchValue.searchType !== 0 ? listData_column : listData_column_type0
                    }
                    exportFilename={`HoleCompare(${t('穴径')})_` + item}
                    onClick={graphClick}
                    height={'30vh'}
                    top={'40'}
                    bottom={'70'}
                    mapName={item}
                    xlist={date_calc()}
                    searchType={searchValue.searchType}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <HoleCompareDiameterDig
        pageName={`${t('menu.page_name.Hole Compare 穴径')}: ${t('詳細')}`}
        open={open}
        onClose={handleClose}
        datas={openData}
        searchValue={searchValue}
      />
    </GenericTemplate>
  );
};

export default HoleCompareDiameterPage;
