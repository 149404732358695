import {Grid} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro';
import LFCChartsScatterFittng from '_components/charts/LFCChartsScatterFittng';
import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  show: string;
  xyz: any;
  gapheader: any;
  gap: any;
  gapthreshold: any;
}

const SearchDetailGraph = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [imagePath, setImagePath] = useState<any>([]);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const imageFileUrl = (params: any) => {
    if (params !== undefined) {
      return (
        <img src={imagePath[0].svalue + params} width="100%" style={{cursor: 'pointer'}} alt="" />
      );
    } else {
      return <></>;
    }
  };

  const dsColumns: any[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {field: 'path_address', headerName: 'GapNo', description: 'GapNo', width: 80, hide: false},
    {
      field: 'gap',
      headerName: t('GAP量'),
      description: t('GAP量'),
      width: 200,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => {
        if (Number(prms.row['max_gap']) === 0) {
          return (
            <>
              <style>
                {`
                  @keyframes border-color-change {
                  0% { border: solid 1px #303030;}
                  50% { border: solid 1px red; background-color: rgb(200,8,8,0.80); }
                  100% { border: solid 1px #303030;}
                }`}
              </style>
              {prms.value >= props.gapthreshold[0]['gap_limit_position'] ? (
                <span
                  style={{
                    marginRight: '-10px',
                    marginLeft: '-10px',
                    width: '0%',
                    backgroundColor: 'rgba(154,36,36,0.77)',
                    border: 'solid 1px red',
                    animation: `border-color-change 3s ease 1s infinite`
                  }}
                >
                  {prms.value}
                </span>
              ) : (
                <span
                  style={{
                    marginRight: '-10px',
                    marginLeft: '-10px',
                    width: '0%',
                    backgroundColor: 'rgba(154,36,36,0.77)'
                  }}
                >
                  {prms.value}
                </span>
              )}
            </>
          );
        } else {
          return (
            <>
              <style>
                {`
                @keyframes border-color-change {
                0% { border: solid 1px #303030;}
                50% { border: solid 1px red; background-color: rgb(200,8,8,0.80); }
                100% { border: solid 1px #303030;}
              }`}
              </style>

              {prms.value >= props.gapthreshold[0]['gap_limit_position'] ? (
                <span
                  style={{
                    marginRight: '-10px',
                    marginLeft: '-10px',
                    width: (prms.value / prms.row['max_gap']) * 100 + '%',
                    backgroundColor: 'rgba(154,36,36,0.77)',
                    border: 'solid 1px red',
                    animation: `border-color-change 3s ease 1s infinite`
                  }}
                >
                  {prms.value}
                </span>
              ) : (
                <span
                  style={{
                    marginRight: '-10px',
                    marginLeft: '-10px',
                    width: (prms.value / prms.row['max_gap']) * 100 + '%',
                    backgroundColor: 'rgba(154,36,36,0.77)'
                  }}
                >
                  {prms.value}
                </span>
              )}
            </>
          );
        }
      }
    }
  ];

  const COLUMN_ORDER: any = [
    {name: 'id', order: 0},
    {name: 'path_address', order: 1},
    {name: 'gap', order: 2}
  ];

  let fittng_columns = {
    uuid: 'uuid',
    lq_time: 'lq_time',
    map: 'map',
    judge: 'judge',
    branch_no: 'branch_no',
    job_type: 'job_type',
    difference_rx: 'difference_rx',
    difference_ry: 'difference_ry',
    difference_rz: 'difference_rz',
    difference_x: 'difference_x',
    difference_y: 'difference_y',
    difference_z: 'difference_z',
    mulmat_rx: 'mulmat_rx',
    mulmat_ry: 'mulmat_ry',
    mulmat_rz: 'mulmat_rz',
    mulmat_x: 'mulmat_x',
    mulmat_y: 'mulmat_y',
    mulmat_z: 'mulmat_z',
    offset_type: 'offset_type',
    transration_limit: 'transration_limit',
    angle_limit: 'angle_limit'
  };

  switch (props.show) {
    case 'fitting':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <LFCChartsScatterFittng
              title=""
              datas={props.xyz}
              exportData={props.xyz}
              exportFields={fittng_columns}
              exportFilename={'詳細_Fitting'}
            />
          </Grid>
          {/* 見やすく調整が必要なので一旦お蔵入り */}
          {/* <Grid item xs={12} sm={6}>
            <Search3dEcharts title="" source={props.xyz} />
          </Grid> */}
        </Grid>
      );
    case 'gap':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={5}>
            <LFCSingleValues
              columns={[
                {
                  field: 'img_filename',
                  headerName: t('結果'),
                  width: '100%',
                  formatter: imageFileUrl
                }
              ]}
              source={props.gapheader[0]}
            />
            <LFCSingleValues
              columns={[{field: 'gap_limit_position', headerName: t('閾値'), width: '100%'}]}
              source={props.gapthreshold[0]}
            />
          </Grid>
          <Grid item xs={12} sm={7}>
            <LFCDataGridSimple
              columns={dsColumns}
              rows={props.gap[0]}
              rowHeight={44}
              height="50vh"
              columnOrder={COLUMN_ORDER}
              exportFilename={`L-ROBOT_${t('GAP量')}`}
            />
          </Grid>
        </Grid>
      );
    case 'pathsend':
      return <></>;
    default:
      return <></>;
  }
};

export default SearchDetailGraph;
