import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  xlist: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxLineSurfaceCompare = (props: Props) => {
  const {t} = useTranslation();
  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      data = data.filter((e: any) => !isNaN(e));
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      let data_s: any = data
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((b: string) => Number(b));

      const dataQ1 = () => {
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp: any = [];
    props.xlist.forEach((a: string) => {
      if (props.source.x_list !== undefined && props.source.x_list.find((k: string) => k === a)) {
        props.source.x_list.find((b: string, index: number) => {
          if (b === a) {
            tmp.push(props.source.data_value[index]);
          }
        });
      } else {
        tmp.push([]);
      }
    });

    let tmpOutPut: any = [];
    tmp.forEach((a: any) => {
      if (!isEmpty(a)) {
        tmpOutPut.push(CalcQuartile(a));
      } else {
        tmpOutPut.push([]);
      }
    });
    return tmpOutPut;
  };

  const makeLineData = () => {
    let tmp: any = [];
    props.xlist.forEach((a: string) => {
      if (props.source.x_list !== undefined && props.source.x_list.find((k: string) => k === a)) {
        props.source.x_list.find((b: string, index: number) => {
          if (b === a && typeof props.source.data_line[index] === 'number') {
            tmp.push(Number(props.source.data_line[index]));
          } else if (b === a) {
            tmp.push('');
          }
        });
      }
    });
    return tmp;
  };

  const [seriesDataBox, setSeriesDataBox] = useState([]);
  const [seriesDataLine, setSeriesDataLine] = useState([]);

  useEffect(() => {
    if (props.source !== undefined && props.source.x_list !== undefined) {
      setSeriesDataBox(makeBoxData());
      setSeriesDataLine(makeLineData());
    }
  }, [props]);

  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (seriesDataBox.length > 0) {
      props.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(Array.from(new Set(props.exportData.map((b: any) => b.work)))),
          deviceid: String(Array.from(new Set(props.exportData.map((b: any) => b.deviceid)))),
          map: props.mapName,
          branch_no: String(Array.from(new Set(props.exportData.map((b: any) => b.branch_no)))),
          lq_time: a,
          threshold:
            (props.exportData.filter((b: any) => b.year_month === a)[0] !== undefined
              ? props.exportData.filter((b: any) => b.year_month === a)[0].threshold
              : '') ||
            (props.exportData.filter((b: any) => b.year_month_day === a)[0] !== undefined
              ? props.exportData.filter((b: any) => b.year_month_day === a)[0].threshold
              : '') ||
            (props.exportData.filter((b: any) => b.year_month_day_hour === a)[0] !== undefined
              ? props.exportData.filter((b: any) => b.year_month_day_hour === a)[0].threshold
              : ''),
          d0:
            seriesDataBox[index] !== undefined && seriesDataBox[index][4] !== undefined
              ? seriesDataBox[index][4]
              : '',
          d1:
            seriesDataBox[index] !== undefined && seriesDataBox[index][3] !== undefined
              ? seriesDataBox[index][3]
              : '',
          d2:
            seriesDataBox[index] !== undefined && seriesDataBox[index][2] !== undefined
              ? seriesDataBox[index][2]
              : '',
          d3:
            seriesDataBox[index] !== undefined && seriesDataBox[index][1] !== undefined
              ? seriesDataBox[index][1]
              : '',
          d4:
            seriesDataBox[index] !== undefined && seriesDataBox[index][0] !== undefined
              ? seriesDataBox[index][0]
              : ''
        });
      });
      setExportData(tmp);
    }
  }, [seriesDataBox]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
              if (params.seriesType === 'boxplot') {
                return (
                  params.name +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  Number(params.value[5]).toFixed(2) +
                  '<br/>' +
                  'Q3：' +
                  Number(params.value[4]).toFixed(2) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  Number(params.value[3]).toFixed(2) +
                  '<br/>' +
                  'Q1：' +
                  Number(params.value[2]).toFixed(2) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  Number(params.value[1]).toFixed(2)
                );
              } else if (params.seriesType === 'scatter') {
                if (params.value[1] !== undefined) {
                  return (
                    params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
                  );
                } else {
                  return;
                }
              } else {
                return (
                  t('閾値') +
                  '<br/>' +
                  t('年月日時') +
                  ':' +
                  params.name +
                  '<br/>' +
                  t('データ') +
                  ':' +
                  params.value
                );
              }
            }
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            bottom: 0,
            textStyle: {
              fontSize: 10
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '80',
            right: '20',
            bottom: props.bottom
          },
          xAxis: {
            type: 'category',
            data: props.xlist !== undefined ? props.xlist : [],
            splitLine: {
              show: false
            },
            axisLabel: {
              // interval: 0,
              rotate: '30',
              fontSize: 10
            }
          },
          yAxis: [
            {
              type: 'value',
              name: '(%)',
              nameTextStyle: {
                fontSize: 10
              },
              max: 100,
              axisLabel: {
                interval: 0,
                fontSize: 10
              }
            }
          ],
          series: [
            {
              name: t('マッチング率Min'),
              type: 'boxplot',
              data: seriesDataBox.length > 0 ? seriesDataBox : [],
              color:
                props.source !== undefined && props.source.branch_no !== undefined
                  ? colorPallet[props.source.branch_no + 50]
                  : 0
            },
            {
              name: t('閾値'),
              type: 'line',
              data: seriesDataLine.length > 0 ? seriesDataLine : [],
              connectNulls: true,
              color:
                props.source !== undefined && props.source.branch_no !== undefined
                  ? colorPallet[props.source.branch_no + 40]
                  : 0
            }
          ]
        }}
        exportData={exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
};

export default LFCChartsBoxLineSurfaceCompare;
