import {SessionDatas, SessionDatasInitVal} from './types/SessionDatas';

const LFC_SESSION_KEY = 'lfc.sessiondatas';

// SessionDatasをWebStorageにセット
export const setLFCSessionStorage = (settings: SessionDatas): void => {
  try {
    const settingsJSON = JSON.stringify(settings);
    sessionStorage.setItem(LFC_SESSION_KEY, settingsJSON);
  } catch (error) {
    console.error('Failed to save settings to sessionStorage:', error);
  }
};

// SessionDatasをWebStorageから取得
export const getLFCSessionStorage = (): SessionDatas => {
  try {
    const settingsJSON = sessionStorage.getItem(LFC_SESSION_KEY);
    if (settingsJSON) {
      return JSON.parse(settingsJSON) as SessionDatas;
    }
    return SessionDatasInitVal;
  } catch (error) {
    console.error('Failed to load settings from sessionStorage:', error);
    throw error;
  }
};
