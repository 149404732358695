import {useTheme} from '@mui/material';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

type tsign = '=' | '<' | '<=' | '>=' | '>';

/**
 * パラメータ
 */
interface Props {
  title: string;
  source: any[];
  valueKey: string;
  valueUnit?: string; //単位
  targetValue?: number; //目標値
  targetComparisonSign?: tsign; //目標値 <-> 値 の比較記号
}

/**
 * LFCSingleValuePie
 * 単値そのまま　＆　目標達成度を加味した円チャート付きで表現します。
 * 目標値が無い場合常に100%緑になります。
 */
const LFCSingleValuePie = (props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();

  /**
   * 符号の言語化
   * @param targetComparisonSign
   * @returns
   */
  const getDispSign = (targetComparisonSign: tsign | undefined) => {
    switch (targetComparisonSign) {
      case '=':
        return '';
      case '<':
        return '超え';
      case '<=':
        return '以上';
      case '>=':
        return '以下';
      case '>':
        return '未満';
      default:
        return '';
    }
  };

  /**
   * 目標達成判定をします
   * @param value
   * @param targetValue
   * @param targetComparisonSign
   * @returns
   */
  const judgeTarget = (
    value: number,
    targetValue: number | undefined,
    targetComparisonSign: tsign | undefined
  ) => {
    if (targetValue == null) {
      // 目標なしの場合
      return true;
    }

    switch (targetComparisonSign) {
      case '=':
        return targetValue <= value;
      case '<':
        return targetValue < value;
      case '<=':
        return targetValue <= value;
      case '>=':
        return targetValue >= value;
      case '>':
        return targetValue > value;
      default:
        return true;
    }
  };

  return props.source.length > 0 ? (
    <LFCBaseCharts
      option={{
        toolbox: {},
        ...(props.targetValue
          ? {
              title: {
                text: t('目標'),
                textStyle: {
                  fontSize: '0.875rem'
                },
                subtext: ` ${props.targetValue.toLocaleString()}${
                  props.valueUnit ? props.valueUnit : ''
                } ${getDispSign(props.targetComparisonSign)}`,
                subtextStyle: {
                  fontSize: '1.5rem'
                },
                borderWidth: 3,
                borderColor: theme.palette.divider,
                borderRadius: 5,
                top: 20,
                left: 1
              }
            }
          : null),
        select: {disabled: true},
        series: [
          {
            type: 'pie',
            radius: ['60%', '90%'],
            data: [
              {value: Number(props.source[0][props.valueKey]), name: 'main'},
              {
                value: props.targetValue
                  ? props.targetValue - Number(props.source[0][props.valueKey])
                  : 0,
                name: 'sub',
                label: {show: false}
              }
            ],
            itemStyle: {
              color: (params: any) => {
                if (params['name'] === 'main') {
                  if (
                    !judgeTarget(params['value'], props.targetValue, props.targetComparisonSign)
                  ) {
                    // 目標未達の場合
                    return theme.palette.error.main;
                  }
                  return theme.palette.success.main;
                }
                return theme.palette.action.disabled;
              }
            },
            label: {
              show: true,
              position: 'center',
              formatter: (params: any) => {
                const strP = {
                  title: props.title,
                  val: params['value'].toLocaleString(),
                  val_unit: props.valueUnit ? props.valueUnit : ''
                };

                return ` {title|${strP.title}} \n{hr|}\n{val|${strP.val}}{val_unit|${strP.val_unit}}`;
              },
              // backgroundColor: mySettings.isDark ? "#424242" : "#FFF",
              padding: 5,
              rich: {
                title: {
                  fontSize: '0.875rem',
                  color: theme.palette.text.primary,
                  // align: "center",
                  // lineHeight: 25,
                  padding: [0, 0, 5, 0]
                },
                hr: {
                  borderColor: theme.palette.divider,
                  width: '100%',
                  align: 'left',
                  borderWidth: 1,
                  height: 0
                },
                val: {
                  fontSize: '2rem',
                  color: theme.palette.text.primary,
                  // align: "center",
                  fontWeight: 'bold',
                  verticalAlign: 'bottom',
                  padding: [7, 0, 0, 0]
                  // lineHeight: 50,
                },
                val_unit: {
                  fontSize: '0.75rem',
                  color: theme.palette.text.primary,
                  verticalAlign: 'bottom',
                  padding: [7, 0, 0, 0]
                }
              }
            }
          }
        ]
      }}
    />
  ) : (
    <></>
  );
};

export default LFCSingleValuePie;
