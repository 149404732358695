import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any | {};
  xData?: any;
  yAxisName?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineMulti6 = (props: Props) => {
  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            left: '5%',
            right: '4%',
            bottom: '10%',
            top: '20%',
            containLabel: true
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
              // label: {
              //   formatter: (prm: any) => {
              //     return dayjs(prm.value).format("YYYY/MM/DD");
              //   },
              // },
            }
          },
          dataZoom: [
            {
              type: 'inside'
              // minValueSpan: 3600 * 24 * 1000 * 7, // 最小ZOOM 7日分
            }
          ],
          color: props.color ? props.color : null,
          xAxis: [
            {
              type: 'category',
              data: props.xData,
              axisLabel: {
                interval: 0,
                rotate: '45',
                fontSize: 10
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: props.yAxisName !== undefined ? props.yAxisName : '',
              nameTextStyle: {
                fontSize: 10
              },
              axisLabel: {
                interval: 0,
                fontSize: 10
              }
            }
          ],
          series: props.source

          //   [
          //   {
          //     name: 'Email',
          //     type: 'line',
          //     stack: false,
          //     data: [120, 132, 101, 134, 90, 230, 210]
          //   },
          //   {
          //     name: 'Union Ads',
          //     type: 'line',
          //     stack: false,
          //     data: [220, 182, 191, 234, 290, 330, 310]
          //   },
          // ],
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height !== undefined ? props.height : '300px'}
      />
    </div>
  );
};
export default LFCChartsLineMulti6;
