import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import LFCChartsScatterDemo from '_components/charts/LFCChartsScatterDemo';
import LFCButton from '_components/inputs/LFCButton';
import ProgressBar from '_components/ProgressBar';
import LFCSideMenu from '_components/search-conditions/LFCSideMenu';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useMySettings} from '_contexts/MySettingsProvider';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {FORMAT_YYYYMMDDHHmmSS} from '_logics/LFCConst';
import {useTranslation} from 'react-i18next';
import {
  LOCATIONID_DEMO_MAIN,
  LOCATIONID_LR_ONSITE_MONITOR_MAIN,
  PAGEID_DEMO,
  PAGEID_LR_ONSITE_MONITOR
} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import ProgressMonitorDemo from './_components/ProgressMonitorDemo';

export type WeldingDataProps = {
  setRecommendedConditionsFlag: any;
  setExecutionProcessFlag: any;
  setWeldingDataFlag: any;
  searchValue: any;
  setSearchValue: any;
};

const WeldingData = (props: WeldingDataProps) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const mySettings = useMySettings();
  const useStyles = makeStyles()(theme => {
    return {
      downLine: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderRight: '40px solid transparent',
        borderLeft: '40px solid transparent',
        borderTop: '10px solid #46a6eb',
        borderBottom: 0,
        marginTop: '10px',
        marginLeft: 'auto',
        marginRight: 'auto'
      },
      greenLabel: {
        borderRadius: 8,
        backgroundColor: '#4caf50',
        width: '200px',
        fontSize: 24,
        fontWeight: 900,
        color: 'white',
        margin: 'auto'
      },
      redLabel: {
        borderRadius: 8,
        backgroundColor: 'red',
        width: '200px',
        fontSize: 24,
        fontWeight: 900,
        color: 'white',
        margin: 'auto'
      },
      tableLine: {
        borderRight: !mySettings.isDark ? '1px solid LightGray' : '1px solid rgba(40, 80, 78, 1)'
      }
    };
  });
  const {classes} = useStyles();
  const [startProcess, setStartProcess] = useState(false);
  const getDefaultCondition = useGetDefaultCondition();
  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_DEMO, LOCATIONID_DEMO_MAIN) ?? {
      select_time: '00:00',
      select_date: dayjs(new Date()).format('YYYY-MM-DD'),
      work: [t('すべて')],
      deviceid: [t('すべて')],
      auto_play_interval: props.searchValue.auto_play_interval,
      working_time: 24
    }
  );

  const [data60351, setData60351] = useState({total_count: '0', ok_count: '0', ng_count: '0'});
  const [data60005, setData60005] = useState({});
  const [data905, setData905] = useState<any>({});
  const [data906, setData906] = useState<any>({});
  const [dispDate, setDispDate] = useState('');
  const [delay, setDelay] = useState(30000);

  useEffect(() => {
    setDelay(searchValue.auto_play_interval * 1000);
    props.setSearchValue({
      ...props.searchValue,
      auto_play_interval: searchValue.auto_play_interval
    });
  }, [searchValue.auto_play_interval]);

  const goTop = () => {
    props.setRecommendedConditionsFlag(true);
    props.setWeldingDataFlag(false);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const doSearch = () => {
    // 対象期間算出
    const now = dayjs(searchValue.select_date);
    let fromDt = dayjs(`${now.format('YYYY-MM-DD')} ${searchValue.select_time}`);

    // 稼働開始時刻より前の場合、前日の同時刻を開始時刻とする。
    if (searchValue.select_time > now.format('HH:mm')) {
      // 稼働開始時刻
      fromDt = fromDt.add(-1, 'd');
    }
    const toDt = fromDt.add(1, 'd');

    setDispDate(fromDt.format(FORMAT_YYYYMMDDHHmmSS));
    const conditionsDate = {
      select_datetime_from: fromDt.format(),
      select_datetime_to: toDt.format()
    };
    const conditionsOther = {
      work: searchValue.work[0] !== t('すべて') ? `{${searchValue.work.join(',')}}` : null,
      deviceid:
        searchValue.deviceid[0] !== t('すべて') ? `{${searchValue.deviceid.join(',')}}` : null,
      dongleid: null
    };

    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60351,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        name: '生産数と不良数',
        cancelToken: source.token,
        t
      })
        .then(datas => {
          setData60351(datas.length > 0 ? datas[0] : []);
        })
        .catch(e => e),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60005,
        parameters: {
          ...conditionsDate,
          ...conditionsOther,
          working_time: searchValue.working_time
        },
        name: 'ジョブ処理時間',
        cancelToken: source.token,
        t
      })
        .then(datas => {
          setData60005(datas.length > 0 ? datas[0] : []);
        })
        .catch(e => e),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 905,
        parameters: {
          welding_method: props.searchValue.welding_method,
          material_properties: props.searchValue.material_properties,
          boardthickness: props.searchValue.boardthickness
        },
        name: '905',
        cancelToken: source.token,
        t
      })
        .then(datas => {
          setData905(datas.length > 0 ? datas[0] : []);
        })
        .catch(e => e)
    ]).then(() => {
      props.searchValue.auto_play ? setStartProcess(true) : setStartProcess(false);
    });
  };

  useEffect(() => {
    if (data905.area_no !== undefined) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 906,
        parameters: {
          area_no: Number(data905.area_no)
        },
        name: '906',
        cancelToken: source.token,
        t
      }).then(datas => {
        setData906(datas);
      });
    }
  }, [data905]);

  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (data906.length > 0) {
      for (let i = 0; i < data906.length; i++) {
        tmp.push({
          ampare: i === 0 ? data905.ampare : '',
          voltage: i === 0 ? data905.voltage : '',
          ok_current: data906[i].ok_current,
          ok_voltage: data906[i].ok_voltage
        });
        setExportData(tmp);
      }
    }
  }, [data905, data906]);

  useEffect(() => {
    doSearch();
  }, []);

  const netxStatus = () => {
    props.setRecommendedConditionsFlag(true);
    props.setWeldingDataFlag(false);
  };

  useEffect(() => {
    props.searchValue.auto_play ? setStartProcess(true) : setStartProcess(false);
  }, [props.searchValue.auto_play]);
  /**
   * 自動更新処理
   */
  useInterval(netxStatus, props.searchValue.auto_play ? delay : null);

  return (
    <div>
      <ProgressBar startProcess={startProcess} />
      <LFCSideMenu onClose={() => doSearch()} top={120}>
        <ProgressMonitorDemo
          values={searchValue}
          onChange={res => setSearchValue(res)}
          pageId={PAGEID_LR_ONSITE_MONITOR}
          locationId={LOCATIONID_LR_ONSITE_MONITOR_MAIN}
        ></ProgressMonitorDemo>
      </LFCSideMenu>

      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{
            fontSize: 42,
            marginBottom: 3,
            padding: 1,
            backgroundColor: 'rgb(0, 102, 204)',
            color: 'white'
          }}
          justifyItems={'center'}
          textAlign={'center'}
        >
          Utilize data during welding to determine defective products
          <br />
          Accumulating knowledge eliminates the need for inspections
        </Grid>

        <Grid item xs={3}>
          <LFCTitledCard title={`Production Progress`} titleSub={`${dispDate} ～ `}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <LFCOkNgPie source={data60351} height="300px" />
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title="Cycle Time [sec]" transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'minimum_ct',
                        headerName: 'Min.',
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'average_ct',
                        headerName: 'Ave.',
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'maximum_ct',
                        headerName: 'Max.',
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60005}
                  />
                </LFCTitledCard>
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>

        <Grid item xs={9} sx={{padding: 1}}>
          <Grid container component={Card} spacing={1} sx={{padding: 2}}>
            <Typography variant="h3" component="h3" style={{fontSize: 18, paddingBottom: 7}}>
              Production Result
            </Typography>
            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h2"
                      component="h2"
                      style={{fontSize: 24, paddingBottom: 27}}
                    >
                      Welding Path
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{position: 'relative', marginBottom: '10px'}}>
                    <div
                      style={{
                        backgroundImage:
                          'url(' + process.env.PUBLIC_URL + 'images/demo/Status7-1_R.jpg)',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        marginTop: '20px',
                        height: '285px',
                        width: '100%',
                        backgroundColor: 'white',
                        // position: 'absolute',
                        top: 30,
                        // right: 0,
                        // bottom: 0,
                        // left: 0,
                        margin: 'auto'
                      }}
                    ></div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    justifyContent={'center'}
                    style={{textAlign: 'center', marginBottom: '10px'}}
                  >
                    {data905['welding_path_result'] === 1 ? (
                      <div className={classes.greenLabel}>OK</div>
                    ) : (
                      <div className={classes.redLabel}>NG</div>
                    )}
                    <div className={classes.downLine} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h2" component="h2" style={{fontSize: 24, paddingBottom: 7}}>
                  Welding Conditions
                </Typography>

                <Grid container spacing={3}>
                  <Grid item xs={5} sx={{position: 'relative'}}>
                    Torch Angle
                    <Grid item xs={12} sx={{position: 'relative', marginBottom: '10px'}}>
                      <div
                        style={{
                          backgroundImage:
                            'url(' + process.env.PUBLIC_URL + 'images/demo/status_7-2.jpg)',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          height: '280px',
                          width: '100%',
                          backgroundColor: 'white',
                          // position: 'absolute',
                          // top: 0,
                          // right: 0,
                          // bottom: 0,
                          // left: 0,
                          margin: 'auto'
                        }}
                      ></div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      justifyContent={'center'}
                      style={{textAlign: 'center', marginBottom: '10px'}}
                    >
                      {data905['torch_angle_result'] === 1 ? (
                        <div className={classes.greenLabel}>OK</div>
                      ) : (
                        <div className={classes.redLabel}>NG</div>
                      )}

                      <div className={classes.downLine} />
                    </Grid>
                  </Grid>
                  <Grid item xs={7}>
                    Current / Voltage
                    {!isEmpty(data905) && !isEmpty(data906) ? (
                      <LFCChartsScatterDemo
                        title=""
                        xaxisName={'Current [A]'}
                        yaxisName={'Voltage [V]'}
                        datas={[[data905['ampare'], data905['voltage']]]}
                        datas2={data906.map((a: any) => {
                          return [Number(a.ok_current), Number(a.ok_voltage)];
                        })}
                        height={'290px'}
                        exportData={exportData}
                        exportFields={{
                          ampare: 'ampere',
                          voltage: 'voltage',
                          ok_current: 'ok ampere',
                          ok_voltage: 'ok voltage'
                        }}
                        exportFilename={'demo'}
                        left={'90%'}
                      />
                    ) : (
                      <></>
                    )}
                    <Grid
                      item
                      xs={12}
                      justifyContent={'center'}
                      style={{textAlign: 'center', marginBottom: '10px'}}
                    >
                      {data905['current_voltage_result'] === 1 ? (
                        <div className={classes.greenLabel}>OK</div>
                      ) : (
                        <div className={classes.redLabel}>NG</div>
                      )}
                      <div className={classes.downLine} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              justifyContent={'center'}
              style={{textAlign: 'center', marginBottom: '10px'}}
            >
              {data905['total_result'] === 1 ? (
                <div className={classes.greenLabel} style={{width: '100%'}}>
                  Overall:OK
                </div>
              ) : (
                <div className={classes.redLabel} style={{width: '100%'}}>
                  Overall:NG
                </div>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} justifyContent={'end'} sx={{margin: '20px 0', display: 'flex'}}>
            <LFCButton onClick={() => goTop()}>EXIT</LFCButton>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="h2" component="h2" style={{fontSize: 24}}>
        Record
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} size={'medium'}>
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.tableLine}>
                Date
              </TableCell>
              <TableCell align="left" className={classes.tableLine}>
                Welding Path
              </TableCell>
              <TableCell align="left" className={classes.tableLine}>
                Torch Angle
              </TableCell>
              <TableCell align="left">Current / Voltage</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow key={'row0'}>
              <TableCell align="left" rowSpan={3} className={classes.tableLine}>
                {data905['start_time']}
              </TableCell>
              <TableCell align="left" className={classes.tableLine}>
                {data905['welding_path_result'] === 1 ? '○' : 'X'}
              </TableCell>
              <TableCell align="left" className={classes.tableLine}>
                {data905['torch_angle_result'] === 1 ? '○' : 'X'}
              </TableCell>
              <TableCell align="left">
                {data905['current_voltage_result'] === 1 ? '○' : 'X'}
              </TableCell>
            </TableRow>
            <TableRow key={'row1'}>
              <TableCell align="left" rowSpan={2} className={classes.tableLine}>
                ({data905['welding_start_path_x']},{data905['welding_start_path_y']},
                {data905['welding_start_path_z']}) → ({data905['welding_end_path_x']},
                {data905['welding_end_path_y']},{data905['welding_end_path_z']})
              </TableCell>
              <TableCell align="left" className={classes.tableLine}>
                Angle {data905['aiming_angle']}deg
              </TableCell>
              <TableCell align="left">Current {data905['ampare']}A</TableCell>
            </TableRow>
            <TableRow key={'row2'}>
              <TableCell align="left" className={classes.tableLine}>
                Welding Speed {data905['weld_speed']}mm/sec
              </TableCell>
              <TableCell align="left">
                Voltage {data905['voltage'] !== undefined ? data905['voltage'] : t('データなし')} V
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WeldingData;
