import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  yAxisName: string;
  xAxisName?: string;
  xList: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarWeldingProductionStatus = (props: Props) => {
  const {t} = useTranslation();
  let showData: any = [];

  if (!isEmpty(props.source)) {
    props.xList.forEach((a: string) => {
      if (props.source.filter((b: any) => b.datelist === a).length > 0) {
        showData.push(props.source.filter((b: any) => b.datelist === a)[0]);
      } else {
        showData.push({ok: null, ng: null, datelist: a});
      }
    });
  }

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName ?? t('日'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            data: props.xList,
            axisLabel: {
              interval: 0,
              rotate: '20',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            }
          },
          series: [
            {
              name: 'OK',
              data: showData.map((a: any) => a !== undefined)
                ? showData.map((a: any) => a.ok)
                : null,
              type: 'bar',
              stack: true,
              // barWidth: '100%',
              xAxisIndex: 0
            },
            {
              name: 'NG',
              data: showData.map((a: any) => a !== undefined)
                ? showData.map((a: any) => a.ng)
                : null,
              type: 'bar',
              stack: true,
              // barWidth: '100%',
              xAxisIndex: 0,
              itemStyle: {color: '#E91E63'}
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return prm.name + '<br>' + prm.seriesName + `${t('個数')}:` + prm.value + t('個');
            }
            // position: "inside",
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10,
            textStyle: {
              fontSize: 10
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: 50,
            right: 5,
            bottom: 80
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
      />
    </div>
  );
};
export default LFCChartsBarWeldingProductionStatus;
