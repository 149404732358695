import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import {useState} from 'react';
import OpenSiteTemplate from '_templates/OpenSiteTemplate';
import {useTranslation} from 'react-i18next';
/**
 * SWアップデート完了ダイアログ
 * @returns
 */
export const SWSuccessDialog = () => {
  const {t} = useTranslation();
  const [show, setShow] = useState(true);

  return show ? (
    <OpenSiteTemplate>
      <Dialog open={true} fullWidth>
        <DialogTitle>Software Update</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('最新版へのアップデートが完了しました。')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setShow(false);
            }}
            color="primary"
          >
            {t('閉じる')}
          </Button>
        </DialogActions>
      </Dialog>
    </OpenSiteTemplate>
  ) : (
    <></>
  );
};
