import {
  Card,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography
} from '@mui/material';
import {green} from '@mui/material/colors';
import {makeStyles, styled} from '@mui/styles';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import LFCSingleValues2 from './_components/LFCSingleValues2';
import React, {useEffect, useState} from 'react';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCChartsBarSc2 from '_components/charts/LFCChartsBarSc2';
import LFCChartsBoxLineSurfaceCompare from '_components/charts/LFCChartsBoxLineSurfaceCompare';
import LFCChartsLineSurfaceCompareDot2 from '_components/charts/LFCChartsLineSurfaceCompareDot2';
import LFCPieChartJobAnalysisSurfaceCompare from '_components/charts/LFCPieChartJobAnalysisSurfaceCompare';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {distinct, getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  mapName: string;
  selectDate: string;
  searchValue: any;
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles({
  root: {
    '& div': {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
});

const SurfaceCompareDig = (props: Props) => {
  const {t} = useTranslation();
  const [startProcess, setStartProcess] = useState(false);
  const classes = useStyles();

  const {enqueueSnackbar} = useSnackbar();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [data217, setData217] = useState<any>([]);
  const [map_series, setMap_series] = useState<any>([]);
  const [graphX_list, setGraphX_list] = useState<any>([]);
  const [graphBaseData, setGraphBaseData] = useState([]);
  const [data218, setData218] = useState<any>([]);
  const [data219, setData219] = useState([]);
  const [branchList, setBranchList] = useState<any>([]);
  const [dataExport, setDataExport] = useState([]);

  /**
   * 検索条件初期値
   */
  const initialSearchValue = {
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    judge: 9,
    work: [],
    deviceid: [],
    map: '',
    searchType: 2
  };
  const [searchValue, setSearchValue] = useState<any>(initialSearchValue);

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const export_detail_SurfaceCompare: any = [
    {
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100
    },
    {
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('シリアルNo'),
      sortable: true,
      width: 200
    },
    {
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('検査日時'),
      sortable: true,
      width: 200
    },
    {
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位'),
      sortable: true,
      width: 200
    },
    {
      field: 'branch_no',
      headerName: t('検査部位追番'),
      description: t('検査部位追番'),
      sortable: true,
      width: 150
    },
    {
      field: 'max_ratio',
      headerName: t('最大マッチング率％'),
      description: t('最大マッチング率％'),
      sortable: true,
      width: 200
    },
    {
      field: 'min_ratio',
      headerName: t('最小マッチング率％'),
      description: t('最小マッチング率％'),
      sortable: true,
      width: 200
    },
    {
      field: 'threshold',
      headerName: t('閾値％'),
      description: t('閾値％'),
      sortable: true,
      width: 100
    },
    {
      field: 'colormap_vmax',
      headerName: t('OK範囲凸方向㎜'),
      description: t('OK範囲凸方向㎜'),
      sortable: true,
      width: 150
    },
    {
      field: 'colormap_vmin',
      headerName: t('OK範囲凹方向㎜'),
      description: t('OK範囲凹方向㎜'),
      sortable: true,
      width: 150
    },
    {
      field: 'lower_point_count',
      headerName: t('OK範囲外凹方向点群数'),
      description: t('OK範囲外凹方向点群数'),
      sortable: true,
      width: 150
    },
    {
      field: 'valid_point_count',
      headerName: t('OK範囲内点群数'),
      description: t('OK範囲内点群数'),
      sortable: true,
      width: 150
    },
    {
      field: 'higher_point_count',
      headerName: t('OK範囲外凸方向点群数'),
      description: t('OK範囲外凸方向点群数'),
      sortable: true,
      width: 150
    }
  ];

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData217([]);
    setData218([]);
    setData219([]);
    setBranchList([]);
    setMap_series([]);
    setGraphX_list([]);
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };

  const searchTypeValue: any = [
    {
      key: 0,
      value: 'idx'
    },
    {
      key: 1,
      value: 'year_month_day_hour'
    },
    {
      key: 2,
      value: 'year_month_day'
    },
    {
      key: 3,
      value: 'year_month'
    }
  ];

  const [data219_columnKey, setData219_columnKey] = useState('');
  useEffect(() => {
    setData219_columnKey(
      searchTypeValue.find((searchKey: any) => searchKey.key === searchValue.searchType)['value']
    );
  }, [searchTypeValue]);

  const [data219_xList_current, setData219_xList_current] = useState([]);

  useEffect(() => {
    let tmp: any = distinct(
      data219,
      searchTypeValue.find((searchKey: any) => searchKey.key === searchValue.searchType)['value']
    );
    setData219_xList_current(tmp);
  }, [data219]);

  /**
   * リセット処理
   */
  const restSearch = () => {
    setData217([]);
    setData218([]);
    setData219([]);
    setSearchValue({
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      judge: props.searchValue.judge,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: String(props.mapName),
      searchType: props.searchValue.searchType
    });
    setAutoCompleteReset(true);
    setBranchList([]);
    setMap_series([]);
    setGraphX_list([]);
  };

  const doSearch = () => {
    setStartProcess(true);
    setData217([]);
    setData218([]);
    setData219([]);
    let sql_id_by_searchType: number = 0;
    switch (searchValue.searchType) {
      case 0: // uuidごと
        sql_id_by_searchType = 220;
        break;
      case 1: // 時間ごと
        sql_id_by_searchType = 221;
        break;
      case 2: //　1日ごと
        sql_id_by_searchType = 219;
        break;
      case 3: //月ごと
        sql_id_by_searchType = 222;
        break;
    }

    setStartProcess(true);

    const searchPrams: object = {
      select_date_from: dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      map: searchValue.map !== '' ? searchValue.map : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      judge: searchValue.judge !== 9 ? searchValue.judge : null
    };
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 290,
        parameters: searchPrams,
        ds_state: setData217,
        name: '290',
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 218,
        parameters: searchPrams,
        ds_state: setData218,
        name: '218',
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: sql_id_by_searchType,
        parameters: searchPrams,
        ds_state: setData219,
        name: '219',
        cancelToken: source.token,
        t
      })
    ]).then(() => setStartProcess(false));
  };

  const makeDataList = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return data217.map((item: any) => item['idx']);
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  };

  useEffect(() => {
    setBranchList([]);
    setMap_series([]);
    setGraphX_list([]);
  }, []);

  useEffect(() => {
    if (searchValue.map === t('すべて')) {
      setMap_series([t('すべて')]);
    } else {
      setMap_series(
        Array.from(new Set(data217.map((item: any) => item.map))).sort((a: any, b: any) => a - b)
      );
    }
    setGraphX_list(makeDataList());
    if (data217.length > 0) {
      setBranchList(
        Array.from(new Set(data217.map((item: any) => item.branch_no))).sort(
          (a: any, b: any) => a - b
        )
      );
    }
  }, [data217, data219]);

  useEffect(() => {
    let tmp_all: any = [];
    map_series.forEach((map_string: string) => {
      branchList.forEach((branch_no: number) => {
        let tmp_box_data: any = [];
        let tmp_line_data: any = [];
        let tmp_higher_score: any = [];
        let tmp_lower_score: any = [];

        if (searchValue.searchType !== 0) {
          graphX_list.forEach((graphX_list_string: string) => {
            let tmp_box: any = [];
            let tmp_line: any = [];

            data217.forEach((item: any) => {
              if (
                item.map === map_string &&
                item.branch_no === branch_no &&
                item[
                  searchTypeValue.find((searchKey: any) => searchKey.key === searchValue.searchType)
                    .value
                ] === graphX_list_string
              ) {
                tmp_box.push(item.min_ratio);
                tmp_line = item.threshold;
              }
            });
            tmp_box_data.push(tmp_box);
            tmp_line_data.push(tmp_line);

            if (
              data219.map(
                (b: any) =>
                  b.map === map_string &&
                  b.branch_no === branch_no &&
                  b[
                    searchTypeValue.find(
                      (searchKey: any) => searchKey.key === searchValue.searchType
                    ).value
                  ] === graphX_list_string
              )
            ) {
              tmp_higher_score.push(
                data219
                  .filter(
                    (b: any) =>
                      b.map === map_string &&
                      b.branch_no === branch_no &&
                      b[
                        searchTypeValue.find(
                          (searchKey: any) => searchKey.key === searchValue.searchType
                        ).value
                      ] === graphX_list_string
                  )
                  .map((c: any) => c.higher_score)
              );
              tmp_lower_score.push(
                data219
                  .filter(
                    (b: any) =>
                      b.map === map_string &&
                      b.branch_no === branch_no &&
                      b[
                        searchTypeValue.find(
                          (searchKey: any) => searchKey.key === searchValue.searchType
                        ).value
                      ] === graphX_list_string
                  )
                  .map((c: any) => c.lower_score * -1)
              );
            }
          });
          tmp_all.push({
            map: map_string,
            branch_no: branch_no,
            data_value: tmp_box_data,
            data_line: tmp_line_data,
            x_list: graphX_list,
            higher_score: tmp_higher_score,
            lower_score: tmp_lower_score
          });
        } else {
          let tmp_box: any = [];
          let tmp_line: any = [];
          data217.forEach((item: any) => {
            if (item.map === map_string && item.branch_no === branch_no) {
              tmp_box.push(item.min_ratio);
              tmp_line.push(item.threshold);
            }
          });

          data219.forEach((item: any) => {
            if (item.map === map_string && item.branch_no === branch_no) {
              tmp_higher_score.push(item.higher_score);
              tmp_lower_score.push(Number(item.lower_score * -1));
            }
          });
          tmp_all.push({
            map: map_string,
            branch_no: branch_no,
            data_value: tmp_box,
            data_line: tmp_line,
            x_list: graphX_list,
            higher_score: tmp_higher_score,
            lower_score: tmp_lower_score
          });
        }
      });
    });
    setGraphBaseData(tmp_all);
  }, [graphX_list]);

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    restSearch();
  }, [props]);

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };
  const getAllList = () => {
    setStartProcess(true);

    const searchPrams: object = {
      select_date_from: dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      map: searchValue.map !== '' ? searchValue.map : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      judge: searchValue.judge !== 9 ? searchValue.judge : null
    };

    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 295,
      parameters: searchPrams,
      ds_state: setDataExport,
      cancelToken: source.token,
      t
    }).then(() => {
      setStartProcess(false);
      setDetailListOpen(true);
    });
  };

  useEffect(
    () => () => {
      restSearch();
    },
    [props]
  );

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    // マウント時処理
    // 42008,LFCSelectForm：work：t_lqresult_surface_compare,"
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42008,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);

    // 情報取得： 42007,LFCAutocomplete：deviceid：t_lqresult_surface_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42007,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：map：t_lqresult_surface_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42006,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 600
    }
  });

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <ProgressBar startProcess={startProcess} />
      <LFCDialogTitle onClose={props.onClose}>{`${t('menu.page_name.Surface Compare')}:${t(
        '詳細'
      )}`}</LFCDialogTitle>
      <DialogContent>
        <form>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name="select_date_from"
              label={t('日付From')}
              value={searchValue.select_date_from}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
            />
            <LFCDatetimePicker
              name="select_date_to"
              label={t('日付To')}
              value={searchValue.select_date_to}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
            />
            <LFCAutocomplete
              name={t('work')}
              label={t('機種')}
              id={'work'}
              size="small" // small/medium
              value={multiSelectData(work, work)}
              // style={{width: 500}}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={work}
            />
            <LFCAutocomplete
              name={'deviceid'}
              label={t('検査装置')}
              id={'deviceid'}
              size="small" // small/medium
              value={multiSelectData(deviceid, searchValue.deviceid)}
              // style={{width: 500}}
              onChange={autoOnChange}
              multiple={true}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              selectItem={deviceid}
            />
            <LFCSelectFormJudge
              name={'judge'}
              value={searchValue.judge}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              multiple={false}
            />
            <LFCAutocomplete
              name={t('map')}
              label={t('検査部位')}
              id={'map'}
              size="small" // small/medium
              value={multiSelectData(map, searchValue.map)}
              // style={{width: 500}}
              onChange={autoOnChange}
              multiple={false}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              selectItem={map}
            />
            <LFCButton color="primary" onClick={doSearch}>
              {t('検索')}
            </LFCButton>
            <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
          </LFCFormRowGroup>
        </form>
        <div style={{float: 'right'}}>
          <LFCButton size={'small'} color="secondary" onClick={getAllList}>
            {t('詳細データ')}
          </LFCButton>
        </div>

        <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
        <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
          {searchType.map((item: any, index: number) => (
            <FormControlLabel
              value={item.id}
              key={'search_type-' + item.id}
              control={
                <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
              }
              label={
                <Typography display="block" key={'search_type-body-' + item.id}>
                  {item.text}
                </Typography>
              }
            />
          ))}
        </RadioGroup>

        {branchList.length === 0 ? (
          <div style={{height: '70vh'}}></div>
        ) : (
          branchList.map((branchNo: number) => (
            <Grid container spacing={2} key={'branch_number_' + branchNo}>
              <Grid item xs={9}>
                {searchValue.searchType !== 0 ? (
                  <>
                    {t('部位名') + ':' + searchValue.map + ` ${t('追番')}:` + branchNo}
                    <LFCChartsBoxLineSurfaceCompare
                      title={t('マッチング率推移')}
                      source={graphBaseData.filter((a: any) => a.branch_no === branchNo)[0]}
                      x={{dsColumn: '', type: 'category'}}
                      y={{dsColumn: '', type: 'value'}}
                      mapName={searchValue.map}
                      exportData={data217.filter((a: any) => a.branch_no === branchNo)}
                      exportFields={{
                        work: t('機種'),
                        deviceid: t('検査装置'),
                        map: t('検査部位'),
                        branch_no: t('検査部位追番'),
                        lq_time: t('検査日時'),
                        threshold: t('閾値'),
                        d0: t('最大'),
                        d1: t('Q3'),
                        d2: t('中央(orQ2)'),
                        d3: t('Q1'),
                        d4: t('最小')
                      }}
                      exportFilename={`SurfaceCompare_${t('マッチング率推移')}`}
                      height={'35vh'}
                      bottom={'70'}
                      xlist={graphX_list}
                      zoomChartId={'zoom-chart-a-' + branchNo}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-a-' + branchNo)}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  </>
                ) : (
                  <LFCChartsLineSurfaceCompareDot2
                    title={t('部位名') + ':' + searchValue.map + ` ${t('追番')}:` + branchNo}
                    source={graphBaseData.filter((a: any) => a.branch_no === branchNo)}
                    x={{dsColumn: ''}}
                    mapName={searchValue.map}
                    exportData={data217.filter((a: any) => a.branch_no === branchNo)}
                    exportFields={{
                      work: t('機種'),
                      deviceid: t('検査装置'),
                      map: t('検査部位'),
                      branch_no: t('検査部位追番'),
                      lq_time: t('検査日時'),
                      threshold: t('閾値'),
                      min_ratio: t('マッチング率(最小)'),
                      judge: t('判定'),
                      serial: t('シリアルナンバー')
                    }}
                    exportFilename={`SurfaceCompare_${t('マッチング率推移')}`}
                    height={'35vh'}
                    zoomChartId={'zoom-chart-b-' + branchNo}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-b-' + branchNo)}
                    zoomChartsClose={() => setZoomChartOpen('')}
                  />
                )}
                <>
                  <CustomWidthTooltip
                    title={
                      <>
                        <Typography color="inherit" fontSize={11}>
                          {`${t('点群数割合')} =(${t('凸方向もしくは凹方向のOK範囲外点群数')})/(${t(
                            '検出全点群数'
                          )})* 100`}
                        </Typography>
                        <Typography color="inherit" fontSize={11}>
                          {t('※OK範囲に全て収まった場合は0%')}
                        </Typography>
                      </>
                    }
                    arrow
                  >
                    <span style={{fontSize: 16, width: '600px'}}>
                      {t('OK範囲外で検出された点群数割合')}
                    </span>
                  </CustomWidthTooltip>
                  <LFCChartsBarSc2
                    title=""
                    source={graphBaseData.filter((a: any) => a.branch_no === branchNo)}
                    x={{
                      dsColumn: data219_columnKey,
                      data: data219_xList_current
                    }}
                    y={{
                      type: 'bar',
                      name: [t('凸方向'), t('凹方向')],
                      dsColumn: ['higher_score', 'lower_score_show']
                    }}
                    stacked={true}
                    xlist={searchValue.searchType !== 0 ? graphX_list : null}
                    exportData={data219.filter((a: any) => a.branch_no === branchNo)}
                    exportFields={
                      searchValue.searchType !== 0
                        ? {
                            work: t('機種'),
                            deviceid: t('検査装置'),
                            map: t('検査部位'),
                            branch_no: t('検査部位追番'),
                            lq_time: t('検査日時'),
                            higher_score: `${t('凸方向')}%`,
                            lower_score: `${t('凹方向')}%`
                          }
                        : {
                            work: t('機種'),
                            deviceid: t('検査装置'),
                            map: t('検査部位'),
                            branch_no: t('検査部位追番'),
                            lq_time: t('検査日時'),
                            higher_score: `${t('凸方向')}%`,
                            lower_score: `${t('凹方向')}%`,
                            serial: t('シリアルナンバー')
                          }
                    }
                    exportFilename={`SurfaceCompare_${t('凹凸推移')}`}
                    height={'35vh'}
                    zoomChartId={'zoom-chart-c-' + branchNo}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-c-' + branchNo)}
                    zoomChartsClose={() => setZoomChartOpen('')}
                  />
                </>
              </Grid>
              <Grid item xs={3} justifyContent={'center'} style={{textAlign: 'center'}}>
                <Card
                  className={classes.root}
                  style={{
                    width: '100%',
                    padding: '2px',
                    marginBottom: '10px'
                  }}
                >
                  {t('OK範囲基準')}
                  <LFCSingleValues2
                    columns={[
                      {
                        field: 'colormap_vmax',
                        headerName: t('凸方向'),
                        width: '100%',
                        unit: ` mm${t('以内')}`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'colormap_vmin',
                        headerName: t('凹方向'),
                        width: '100%',
                        unit: ` mm${t('以内')}`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'threshold',
                        headerName: t('閾値'),
                        width: '100%',
                        unit: ` %${t('以上')}`,
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      }
                    ]}
                    source={data218.filter((a: any) => a.branch_no === branchNo)[0]}
                  />
                </Card>

                <Card
                  className={classes.root}
                  style={{
                    width: '100%',
                    padding: '2px',
                    marginBottom: '10px'
                  }}
                >
                  {t('検査結果')}
                  <LFCSingleValues2
                    columns={[
                      {
                        field: 'all_count',
                        headerName: t('検査数'),
                        width: '100%',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'ok_count',
                        headerName: t('OK判定'),
                        width: '100%',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'ng_count',
                        headerName: t('NG判定'),
                        width: '100%',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      },
                      {
                        field: 'ng_percent',
                        headerName: t('NG率'),
                        width: '100%',
                        unit: ' %',
                        formatter: v => round(v, 1),
                        fontColor: green[500]
                      }
                    ]}
                    source={data218.filter((a: any) => a.branch_no === branchNo)[0]}
                  />
                </Card>

                <LFCPieChartJobAnalysisSurfaceCompare
                  data={data219.filter((a: any) => a.branch_no === branchNo)}
                  name={t('OK範囲外凹凸比率')}
                  exportData={data219.filter((a: any) => a.branch_no === branchNo)}
                  exportFields={{
                    work: t('機種'),
                    deviceid: t('検査装置'),
                    map: t('検査部位'),
                    branch_no: t('検査部位追番'),
                    d0: `${t('凸方向比率')}%`,
                    d1: `${t('凹方向比率')}%`,
                    c0: `${t('凸方向期間平均')}%`,
                    c1: `${t('凹方向期間平均')}%`
                  }}
                  exportFilename={t('OK範囲外凹凸比率')}
                />
              </Grid>
            </Grid>
          ))
        )}

        <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
          <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Surface Compare')}:${t(
            '詳細'
          )}`}</LFCDialogTitle>
          <DialogContent>
            <LFCDataGridSimple
              columns={export_detail_SurfaceCompare}
              rows={dataExport}
              height="80vh"
              exportFilename={`${t('menu.page_name.Surface Compare')}:${t('詳細')}`}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default SurfaceCompareDig;
