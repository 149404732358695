import {Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCChartsComboYMD from '_components/charts/LFCChartsComboYMD';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import SearchDetailDig from '_components/lrobot/search/SearchDetailDig';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {rendJudgeDisp, rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {
  distinct,
  getLFCData,
  groupBy,
  handleInputChange,
  handleInputChange2
} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LR_SEARCH, LOCATIONID_LR_SEARCH_MAIN} from '_logics/LFCPageId';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {useTranslation} from 'react-i18next';

const SearchPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [data60004, setData60004] = useState([]);
  const [data11, setData11] = useState([]);
  const [data12, setData12] = useState([]);
  const apiRef = useGridApiRef();
  const [dgColumns, setDgColumns] = useState<GridColDef[]>([]);
  const [dgRows, setDgRows] = useState<any>([]);

  const initialSearchValue = {
    select_datetime_from: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
    select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
    judgestatus: 9,
    search_menu: 0,
    work: [],
    deviceid: [],
    dongleid: [],
    serialno: ''
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(PAGEID_LR_SEARCH, LOCATIONID_LR_SEARCH_MAIN);
  const [searchValue, setSearchValue] = useState(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * 初期カラム定義
   */
  const BASE_COL: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'uuid', width: 100, hide: true},
    {field: 'uuid', headerName: 'uuid', description: 'uuid', width: 100, hide: true},
    {
      field: 'lq_time',
      headerName: `${t('検査日時')}`,
      description: `${t('検査日時')}`,
      width: 150,
      valueFormatter: (prms: GridValueFormatterParams) => fmtYMD(prms.value)
    },
    {
      field: 'serial',
      headerName: `${t('シリアルNo')}`,
      description: `${t('シリアルNo')}`,
      width: 100
    },
    {
      field: 'ct',
      headerName: `${t('処理時間')}`,
      description: `${t('処理時間')}`,
      width: 100,
      valueFormatter: (prms: GridValueFormatterParams) => {
        return prms.value !== null ? prms.value.toFixed(1) : '';
      }
    },
    {
      field: 'total_judge',
      headerName: `${t('総合判定')}`,
      description: `${t('総合判定')}`,
      width: 100,
      renderCell: (prms: GridRenderCellParams) => rendTotalJudgeDisp(prms.value)
    },
    {
      field: 'work',
      headerName: `${t('機種')}`,
      description: `${t('機種')}`,
      width: 100
    },
    {
      field: 'deviceid',
      headerName: `${t('検査装置')}`,
      description: `${t('検査装置')}`,
      width: 100
    },
    {
      field: 'dongleid',
      headerName: `${t('オペレータ')}`,
      description: `${t('オペレータ')}`,
      width: 100
    }
  ];

  const COLUMN_ORDER: any = [
    {name: 'uuid', order: 0},
    {name: 'lq_time', order: 1},
    {name: 'serial', order: 2},
    {name: 'ct', order: 3},
    {name: 'total_judge', order: 4}
  ];

  useEffect(() => {
    // header
    const maps = distinct(data12, 'map');
    setDgColumns([
      ...BASE_COL,
      ...maps.map((map: string) => {
        return {
          field: map,
          headerName: map,
          width: 100,
          disableColumnMenu: true,
          sortable: false,
          renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value)
        };
      })
    ]);
  }, [data12]);

  useEffect(() => {
    const gLists = groupBy(data12, 'uuid', ['map', 'judge']);
    let tmp_gLists: any = [];
    gLists.forEach((item: any, index: number) => {
      tmp_gLists[index] = [];
      let tmp_key = Object.keys(item);
      BASE_COL.forEach((BASE_COL_keys: any) => {
        tmp_gLists[index].push(
          tmp_key.find((tmp_key_value: string) => BASE_COL_keys.field === tmp_key_value)
        );
      });
    });
    let tmp_datalist: any = [];
    gLists.forEach((item: any, index: number) => {
      tmp_datalist[index] = {};
      let tmp: any = {};
      dgColumns.forEach((field: any) => {
        tmp[field.field] = item[field.field] !== undefined ? item[field.field] : '';
      });
      tmp.uuid = item.id;
      tmp.id = item.id;
      tmp_datalist[index] = tmp;
    });
    setDgRows(tmp_datalist);
  }, [dgColumns]);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  const [searchPrams, setSearchPrams] = useState({});
  useEffect(() => {
    setSearchPrams({
      select_datetime_from: searchValue.select_datetime_from,
      select_datetime_to: searchValue.select_datetime_to,
      search_menu: searchValue.search_menu,
      total_judge: searchValue.judgestatus !== 9 ? searchValue.judgestatus : null,
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      dongleid: !isEmpty(searchValue.dongleid) ? '{' + searchValue.dongleid.join(',') + '}' : null,
      serialno: searchValue.serialno !== '' ? '%' + searchValue.serialno + '%' : null
    });
  }, [searchValue]);

  const apiFetch = () => {
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 6000402,
        parameters: searchPrams,
        ds_state: setData60004,
        name: `LROBOT_${t('現場モニタ')}_${t('生産数不良数')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 6001102,
        parameters: searchPrams,
        ds_state: setData11,
        name: `LROBOT_${t('検索')}_${t('実績数と不良率')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 6001204,
        parameters: searchPrams,
        ds_state: setData12,
        name: `LROBOT_${t('検索')}_${t('一覧')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const doSearchList = () => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 6001204,
      parameters: searchPrams,
      ds_state: setData12,
      name: `LROBOT_${t('検索')}_${t('一覧')}`,
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  };

  const restSearch = () => {
    setSearchValue({
      select_datetime_from: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
      select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
      judgestatus: 9,
      search_menu: 0,
      work: [],
      deviceid: [],
      dongleid: [],
      serialno: ''
    });
    setAutoCompleteReset(true);
  };

  let data10_column = {
    lq_time: t('日付'),
    total_count: t('総数'),
    ng_par: t('不良率'),
    ng: t('不良数')
  };

  let searchMenu: any = [
    {name: 'All', value: 0},
    {name: 'Fitting', value: 1},
    {name: 'Gap', value: 2},
    {name: 'PathSend', value: 3}
  ];

  const onChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const [open, setOpen] = React.useState(false);
  const [openData, setOpenData] = React.useState([]);
  const handleClose = () => {
    setOpenData([]);
    setOpen(false);
  };

  const goDetail = (datas: any) => {
    setOpenData(datas);
    setOpen(true);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    goDetail(params.row);
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work,
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid,
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'dongleid',
        value: searchValue.dongleid,
        valueLabel: searchValue.dongleid,
        colName: t('オペレータ'),
        colWidth: 200
      },
      {
        name: 'serialno',
        value: searchValue.serialno,
        valueLabel: searchValue.serialno,
        colName: t('シリアルNo'),
        colWidth: 200
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [dongleid, setDongleid] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    // 情報取得： LFCAutocomplete：deviceid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });
    // 情報取得： LFCAutocomplete：dongleid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40003,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['dongleid']};
      });
      setDongleid(tmp);
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid, dongleid]);

  return (
    <GenericTemplate title={t('menu.page_name.補正結果検索')}>
      <ProgressBar startProcess={startProcess} />
      <Grid container>
        <Grid item xs={12}>
          <form ref={formRef}>
            <LFCFormRowGroup>
              <LFCDatetimePicker
                name={'select_datetime_from'}
                label={t('対象期間From')}
                value={searchValue.select_datetime_from}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCDatetimePicker
                name={'select_datetime_to'}
                label={t('対象期間To')}
                value={searchValue.select_datetime_to}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCAutocomplete
                name={t('work')}
                label={t('機種')}
                id={'work'}
                size="small" // small/medium
                value={multiSelectData(work, searchValue.work)}
                // style={{width: 500}}
                onChange={autoOnChange}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                multiple={true}
                selectItem={work}
              />
              <LFCAutocomplete
                name={'deviceid'}
                label={t('検査装置')}
                id={'deviceid'}
                size="small" // small/medium
                value={multiSelectData(deviceid, searchValue.deviceid)}
                // style={{width: 500}}
                onChange={autoOnChange}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                multiple={true}
                selectItem={deviceid}
              />
              <LFCAutocomplete
                name={'dongleid'}
                label={t('オペレータ')}
                id={'dongleid'}
                size="small" // small/medium
                value={multiSelectData(dongleid, searchValue.dongleid)}
                // style={{width: 500}}
                onChange={autoOnChange}
                multiple={true}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                selectItem={dongleid}
              />

              <LFCTextField
                name="serialno"
                label={t('シリアルNo')}
                value={searchValue.serialno}
                onChange={event => handleInputChange(event, searchValue, setSearchValue)}
              />

              <LFCButton color="primary" onClick={() => doSearch()}>
                {t('検索')}
              </LFCButton>
              <LFCButton onClick={() => restSearch()}>{t('リセット')}</LFCButton>
              <LFCSavedCondition
                open={openSavedCondition}
                pageId={PAGEID_LR_SEARCH}
                locationNo={LOCATIONID_LR_SEARCH_MAIN}
                onLoad={onLoadSavedCondition}
                onSelect={onSelectSavedCondition}
                onClose={() => setOpenSavedCondition(false)}
                conditions={condition}
              />
            </LFCFormRowGroup>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} sm={3} md={2} lg={1}>
              <LFCSingleValue
                title={t('生産数')}
                val={data60004[0] ? String(data60004[0]['total_count']) : '-'}
                width={'100px'}
                titleVariant="caption"
                valVariant="body1"
                key={t('生産数')}
              />
              <LFCSingleValue
                title={t('不良率')}
                val={data60004[0] ? String(round(data60004[0]['ng_percent'] * 100, 1)) + '%' : '-'}
                width={'100px'}
                titleVariant="caption"
                valVariant="body1"
                key={t('不良率')}
              />
            </Grid>
            <Grid item xs={12} sm={9} md={10} lg={11}>
              <LFCChartsComboYMD
                title={t('実績数と不良率')}
                source={data11}
                x={{
                  startDate: dayjs(searchValue.select_datetime_from),
                  endDate: dayjs(searchValue.select_datetime_to)
                }}
                yLeft={{
                  type: 'bar',
                  name: `${t('実績数')}`,
                  dsColumn: 'total_count'
                }}
                yRight={{
                  type: 'line',
                  name: `${t('不良率')}(%)`,
                  dsColumn: 'ng_par',
                  markPoint: {
                    data: [
                      {
                        type: 'min',
                        itemStyle: {color: '#E91E63'},
                        label: {
                          formatter: function (params: any) {
                            return params.value.toFixed(1) + '%';
                          }
                        }
                      },
                      {
                        type: 'max',
                        itemStyle: {color: '#2196F3'},
                        label: {
                          formatter: function (params: any) {
                            return params.value.toFixed(1) + '%';
                          }
                        }
                      }
                    ]
                  },
                  markLine: {
                    symbol: 'arrow',
                    data: [
                      {
                        type: 'average',
                        label: {
                          position: 'end',
                          formatter: function (params: any) {
                            return 'Avg.' + params.value.toFixed(1) + '%';
                          },
                          backgroundColor: '#FFEB3B'
                        }
                      }
                    ]
                  }
                }}
                color={[green[500], red[500]]}
                height="30vh"
                exportData={data11}
                exportFields={data10_column}
                exportFilename={`L-ROBOT_${t('実績数と不良率')}`}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <form>
                <LFCFormRowGroup>
                  <LFCSelectForm
                    name={'search_menu'}
                    label={t('ジョブ選択')}
                    value={searchValue.search_menu}
                    id={'sl1'}
                    onChange={onChange}
                    selectItem={searchMenu}
                  />

                  <LFCSelectFormJudge
                    name={'judgestatus'}
                    value={searchValue.judgestatus}
                    onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
                    multiple={false}
                  />

                  <LFCButton color="primary" onClick={() => doSearchList()}>
                    {t('絞り込み')}
                  </LFCButton>
                </LFCFormRowGroup>
              </form>
            </Grid>
            <Grid item xs={12}>
              <LFCDataGrid
                apiRef={apiRef}
                columns={dgColumns}
                rows={dgRows}
                height="75vh"
                onCellClick={onCellClick}
                exportFilename={`L-ROBOT_${t('検索')}`}
                columnOrder={COLUMN_ORDER}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SearchDetailDig open={open} onClose={handleClose} datas={openData} />
    </GenericTemplate>
  );
};

export default SearchPage;
