import {Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBar from '_components/charts/LFCChartsBar';
import LFCChartsComboHH from '_components/charts/LFCChartsComboHH';
import ProgressBar from '_components/ProgressBar';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {FORMAT_YYYYMMDDHHmmSS} from '_logics/LFCConst';
import {
  LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN,
  PAGEID_LQ_INSPECTION_PROGRESS_MONITOR
} from '_logics/LFCPageId';
import {rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsGanttChartPersons from './_components/LFCChartsGanttChartPersons';
import LFCChartsGanttChartPersonsFacility from './_components/LFCChartsGanttChartPersonsFacility';
import {useTranslation} from 'react-i18next';

const WorkTimeMonitorWithFacilityPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const getDefaultCondition = useGetDefaultCondition();

  const searchValue = getDefaultCondition(
    PAGEID_LQ_INSPECTION_PROGRESS_MONITOR,
    LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN
  ) ?? {
    select_time: '00:00',
    working_time: 24,
    target_number: '1000',
    work: [t('すべて')],
    deviceid: [t('すべて')],
    dongleid: [t('すべて')]
  };

  const [data60351, setData60351] = useState({total_count: '0', ok_count: '0', ng_count: '0'});
  const [data60009, setData60009] = useState({});
  const [data60006, setData60006] = useState({});
  const [data60008, setData60008] = useState({
    deviceid: '',
    dongleid: '',
    lq_time: '',
    serial: '',
    total_judge: 0,
    uuid: '',
    work: ''
  });
  const [data60352, setData60352] = useState([]);
  const [data60353, setData60353] = useState([]);
  const [dispDate, setDispDate] = useState('');
  const delay = 100000;
  const [fixPeriod, setFixPeriod] = useState<{
    startDate: dayjs.Dayjs;
    endDate: dayjs.Dayjs;
  }>({startDate: dayjs(), endDate: dayjs()});

  const [data001, setData001] = useState([]);
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState([]);

  const [data007, setData007] = useState([]);
  const [listData_50023, setListData_50023] = useState([]);
  const [machine_id_index, setMachine_id_index] = React.useState<any>([]);

  const [zoomPosition, setZoomPosition] = useState({start: 0, end: 100});

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const doSearch = () => {
    setData001([]);
    setData002([]);
    setData003([]);

    // 対象期間算出
    const now = dayjs();
    let fromDt = dayjs(`${now.format('YYYY-MM-DD')} ${searchValue.select_time}`);

    // 稼働開始時刻より前の場合、前日の同時刻を開始時刻とする。
    if (searchValue.select_time > now.format('HH:mm')) {
      // 稼働開始時刻
      fromDt = fromDt.add(-1, 'd');
    }
    const toDt = fromDt.add(1, 'd');

    setDispDate(fromDt.format(FORMAT_YYYYMMDDHHmmSS));
    setFixPeriod({startDate: fromDt, endDate: toDt});

    const conditionsDate = {
      select_date: fromDt.format('YYYY-MM-DD'),
      select_datetime_from: fromDt.format(),
      select_datetime_to: toDt.format()
    };
    const conditionsOther = {
      work: searchValue.work[0] !== t('すべて') ? `{${searchValue.work.join(',')}}` : null,
      deviceid:
        searchValue.deviceid[0] !== t('すべて') ? `{${searchValue.deviceid.join(',')}}` : null,
      dongleid:
        searchValue.dongleid[0] !== t('すべて') ? `{${searchValue.dongleid.join(',')}}` : null
    };

    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60351,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('検査数と不良数')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60351(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60009,
        parameters: {
          working_time: searchValue.working_time,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('検査間隔')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60009(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60006,
        parameters: {
          working_time: searchValue.working_time,
          target_number: searchValue.target_number,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('タクトタイム')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60006(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60352,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData60352,
        name: `${t('実績数と不良率')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60353,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData60353,
        name: `${t('不良数')}(${t('部位別')})`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60008,
        parameters: {
          ...conditionsOther
        },
        name: `${t('最終実行結果')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60008(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        // sql_id: 50023,
        sql_id: 4026,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setListData_50023,
        name: 'sql-id:4026',
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 4017,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData007,
        name: 'sql-id:4017',
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 4004,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData001,
        name: 'sql-id:4004',
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 4005,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        ds_state: setData002,
        name: 'sql-id:4005',
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setZoomPosition({start: makeStartTime(), end: makeEndTime()});
      setStartProcess(false);
    });
  };

  useEffect(() => {
    let tmp_machine_id: any = Array.from(new Set(listData_50023.map((item: any) => item.device)));
    setMachine_id_index(tmp_machine_id.sort());
  }, [listData_50023]);

  const makeStartTime = () => {
    let select_date: any = new Date(
      new Date().getFullYear() +
        '/' +
        (new Date().getMonth() + 1) +
        '/' +
        new Date().getDate() +
        ' 00:00:00'
    );
    let tmp_now: any = new Date();
    let now_time: any = new Date(
      select_date.getFullYear() +
        '/' +
        (select_date.getMonth() + 1) +
        '/' +
        select_date.getDate() +
        ' ' +
        (tmp_now.getHours() - 1) +
        ':' +
        tmp_now.getMinutes()
    );
    return (
      ((Math.floor((now_time.getTime() - select_date.getTime()) / 300000) * 300000) / 86400000) *
      100
    );
  };

  const makeEndTime = () => {
    let select_date: any = new Date(
      new Date().getFullYear() +
        '/' +
        (new Date().getMonth() + 1) +
        '/' +
        new Date().getDate() +
        ' 00:00:00'
    );
    let tmp_now = new Date();
    let now_time: any = new Date(
      select_date.getFullYear() +
        '/' +
        (select_date.getMonth() + 1) +
        '/' +
        select_date.getDate() +
        ' ' +
        tmp_now.getHours() +
        ':' +
        tmp_now.getMinutes()
    );
    return (
      ((Math.ceil((now_time.getTime() - select_date.getTime()) / 300000) * 300000) / 86400000) * 100
    );
  };

  const zoomLink = (datas: any) => {
    if (datas.start !== undefined && datas.end !== undefined) {
      setZoomPosition({start: datas.start, end: datas.end});
    }
  };

  /**
   * 自動更新処理
   */
  useInterval(doSearch, delay);

  useEffect(() => {
    // マウント時処理
    doSearch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  let responseData004_column = {
    machine: t('マシン'),
    judge: t('判定'),
    detection_time_from: t('開始時間'),
    detection_time_to: t('終了時間'),
    time_diff: t('継続時間')
  };

  let listData_50023_column = {
    device: t('デバイス'),
    machine_status: t('ステータス'),
    detection_time_from: t('開始時間'),
    detection_time_to: t('終了時間'),
    time_diff: t('継続時間')
  };

  useEffect(() => {
    if (data002.length > 0) {
      let tmp: any = Array.from(new Set(data002.map((a: any) => a.machine)));
      setData003(tmp);
    }
  }, [data002]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.人ログ分析')}:${t('menu.page_name.作業時間モニタ')}(${t(
        '設備データあり'
      )})`}
    >
      <ProgressBar startProcess={startProcess} />
      <Grid container>
        <Grid item xs={12} md={2.5}>
          <LFCTitledCard title={t('本日の生産状況')} titleSub={`${dispDate} ～ `}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <LFCOkNgPie source={data60351} height="200px" />
              </Grid>
              <Grid item xs={12}>
                {`${t('検査間隔')}(${t('秒')})`}
                <LFCSingleValues
                  columns={[
                    {
                      field: 'minimum_ct',
                      headerName: `${t('最小')}`,
                      formatter: v => round(v, 3).toLocaleString(),
                      width: '30%',
                      valVariant: 'h6'
                    },
                    {
                      field: 'average_ct',
                      headerName: `${t('平均')}`,
                      formatter: v => round(v, 3).toLocaleString(),
                      width: '30%',
                      valVariant: 'h6'
                    },
                    {
                      field: 'maximum_ct',
                      headerName: `${t('最大')}`,
                      formatter: v => round(v, 3).toLocaleString(),
                      width: '30%',
                      valVariant: 'h6'
                    }
                  ]}
                  source={data60009}
                />
              </Grid>
              <Grid item xs={12}>
                {`${t('タクトタイム')}(${t('秒')})`}
                <LFCSingleValues
                  columns={[
                    {
                      field: 'tt_plan',
                      headerName: t('計画'),
                      formatter: v => round(v, 3).toLocaleString(),
                      width: '47%',
                      valVariant: 'h6'
                    },
                    {
                      field: 'tt_results',
                      headerName: t('実績'),
                      formatter: v => round(v, 3).toLocaleString(),
                      width: '47%',
                      valVariant: 'h6'
                    }
                  ]}
                  source={data60006}
                />
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>
        <Grid item xs={12} md={9.5}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LFCChartsComboHH
                title={`${t('実績数と不良率')}`}
                source={data60352}
                x={fixPeriod}
                yLeft={{
                  type: 'bar',
                  name: `${t('実績数')}`,
                  dsColumn: 'total_count'
                }}
                yRight={{
                  type: 'line',
                  name: `${t('不良率')}(%)`,
                  dsColumn: 'ng_par',
                  markPoint: {
                    data: [
                      {type: 'min', itemStyle: {color: '#E91E63'}},
                      {type: 'max', itemStyle: {color: '#2196F3'}}
                    ]
                  },
                  markLine: {
                    symbol: 'arrow',
                    data: [
                      {
                        type: 'average',
                        label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                      }
                    ]
                  }
                }}
                color={[green[500], red[500]]}
                height={'300px'}
                exportData={data60352}
                exportFields={{
                  lq_time: t('日付'),
                  total_count: t('実績数'),
                  ng: t('不良数'),
                  ng_par: t('不良率')
                }}
                exportFilename={`${t('実績数と不良率')}`}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <LFCChartsBar
                title={`${t('不良数')}(${t('部位別')})`}
                source={data60353}
                x={{type: 'category', dsColumn: 'map'}}
                y={{type: 'value', dsColumn: 'count'}}
                exportData={data60353}
                exportFields={{
                  map: t('部位'),
                  count: t('件数')
                }}
                exportFilename={`${t('不良数')}(${t('部位別')})`}
                height={'300px'}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {t('最終実行結果')}
              <LFCSingleValues
                columns={[
                  {
                    field: 'total_judge',
                    headerName: `${t('総合判定')}`,
                    formatter: rendTotalJudgeDisp
                  },
                  {field: 'lq_time', headerName: `${t('日時')}`},
                  {field: 'serial', headerName: `${t('シリアルNo')}`},
                  {field: 'work', headerName: `${t('機種')}`},
                  {field: 'deviceid', headerName: `${t('マシン')}`}
                  // {field: "dongleid", headerName: "オペレータ"},
                  // { field: "uuid", headerName: "UUID" },
                ]}
                source={data60008}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <LFCChartsGanttChartPersonsFacility
            datas={listData_50023}
            exportData={listData_50023}
            exportFields={listData_50023_column}
            exportFilename={`${t('人ログMMチャート')}`}
            deviceIndex={machine_id_index}
            infoData={data007}
            startDay={dayjs().format('YYYY-MM-DD')}
            zoomLink={zoomLink}
            zoomData={zoomPosition}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <LFCChartsGanttChartPersons
            datas={data002}
            exportData={data002}
            exportFields={responseData004_column}
            exportFilename={`${t('作業時間チャート')}`}
            deviceIndex={data003}
            infoData={data001}
            startDay={dayjs().format('YYYY-MM-DD')}
            zoomLink={zoomLink}
            zoomData={zoomPosition}
          />
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default WorkTimeMonitorWithFacilityPage;
