import {SelectChangeEvent} from '@mui/material';
import React from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  // selectItem: any;
  // id: string;
}

/**
 * 機種プルダウン
 * @param props
 * @returns
 */
const LFCSelectFormTimeSpan = React.memo((props: Props) => {
  const {t} = useTranslation();
  const items: {name: string; value?: number}[] = [
    {name: t('過去7日'), value: 7},
    {name: t('過去30日'), value: 30},
    {name: t('過去60日'), value: 60},
    {name: t('過去90日'), value: 90}
  ];

  if (items.length > 1) {
    return (
      <LFCSelectForm
        name={props.name}
        label={props.label ? props.label : t('期間')}
        value={props.value !== undefined ? props.value : t('すべて')}
        onChange={props.onChange}
        style={props.style}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        margin={props.margin}
        required={props.required}
        size={props.size}
        multiple={props.multiple}
        selectItem={items}
        id={'sf_year'}
      />
    );
  } else {
    return <></>;
  }
});
export default LFCSelectFormTimeSpan;
