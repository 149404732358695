import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_PRODUCTION_STATUS} from '_logics/LFCPageId';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import WeldingDetailDig from './WeldingDetailDig';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCSelectFormMachineWelding from '_components-with-data/inputs/LFCSelectFormMachineWelding';
import LFCSelectFormWorkWelding from '_components-with-data/inputs/LFCSelectFormWorkWelding';
import LFCSelectFormMtCode from '_components-with-data/inputs/LFCSelectFormMtCode';
import LFCSelectFormSpliceCode from '_components-with-data/inputs/LFCSelectFormSpliceCode';
import LFCSelectFormThicknessWelding from '_components-with-data/inputs/LFCSelectFormThicknessWelding';
import LFCSelectFormWorkHeightWelding from '_components-with-data/inputs/LFCSelectFormWorkHeightWelding';

const ProcessingConditionFinderPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const apiRef = useGridApiRef();

  const BASE_COL: GridColDef[] = [
    {
      field: 'exec_time',
      headerName: `${t('日時')}`,
      description: `${t('日時')}`,
      width: 200,
      renderCell: (prms: GridRenderCellParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {field: 'machine', headerName: `${t('マシン名')}`, description: `${t('マシン名')}`, width: 200},
    {field: 'work', headerName: `${t('製品番号')}`, description: `${t('製品番号')}`, width: 100},
    {field: 'judge', headerName: `${t('判定結果')}`, description: `${t('判定結果')}`, width: 100},
    {
      field: 'serial',
      headerName: `${t('シリアルNo')}`,
      description: `${t('シリアルNo')}`,
      width: 100
    },
    {field: 'mtcode', headerName: `${t('材質')}`, description: `${t('材質')}`, width: 100},
    {
      field: 'boardthickness',
      headerName: `${t('板厚(mm)')}`,
      description: `${t('板厚(mm)')}`,
      width: 100
    },
    {
      field: 'workheight',
      headerName: `${t('高さ(mm)')}`,
      description: `${t('高さ(mm)')}`,
      width: 100
    },
    {field: 'splicecode', headerName: `${t('継手')}`, description: `${t('継手')}`, width: 100},
    {
      field: 'place_count',
      headerName: `${t('総設置回数')}`,
      description: `${t('総設置回数')}`,
      width: 100
    },
    {
      field: 'welding_data_number',
      headerName: `${t('溶接データ番号')}`,
      description: `${t('溶接データ番号')}`,
      width: 100
    },
    {
      field: 'frangle',
      headerName: `${t('前進・後進角')}`,
      description: `${t('前進・後進角')}`,
      width: 100
    },
    {
      field: 'aimingangle',
      headerName: `${t('狙い角度')}`,
      description: `${t('狙い角度')}`,
      width: 100
    },
    {field: 'arclen', headerName: `${t('アーク長')}`, description: `${t('アーク長')}`, width: 100},
    {
      field: 'welding_condition_number_low',
      headerName: `${t('溶接条件番号(本条件)\nパス分割率 ～A')}`,
      description: `${t('溶接条件番号(本条件)\nパス分割率 ～A')}`,
      width: 100
    },
    {
      field: 'welding_condition_number_main',
      headerName: `${t('溶接条件番号(本条件)\nパス分割率 A～B')}`,
      description: `${t('溶接条件番号(本条件)\nパス分割率 A～B')}`,
      width: 100
    },
    {
      field: 'welding_condition_number_high',
      headerName: `${t('溶接条件番号(本条件)\nパス分割率 B～')}`,
      description: `${t('溶接条件番号(本条件)\nパス分割率 B～')}`,
      width: 100
    },
    {
      field: 'welding_condition_number_end',
      headerName: `${t('溶接条件番号（終了）')}`,
      description: `${t('溶接条件番号（終了）')}`,
      width: 100
    },
    {
      field: 'pathsplita',
      headerName: `${t('パス分割率(%)\u3000A')}`,
      description: `${t('パス分割率(%)\u3000A')}`,
      width: 100
    },
    {
      field: 'pathsplitb',
      headerName: `${t('パス分割率(%)\u3000B')}`,
      description: `${t('パス分割率(%)\u3000B')}`,
      width: 100
    },
    {
      field: 'heightcorrst',
      headerName: `${t('高さ補正\u3000(始端)')}`,
      description: `${t('高さ補正\u3000(始端)')}`,
      width: 100
    },
    {
      field: 'heightcorred',
      headerName: `${t('高さ補正\u3000(終端)')}`,
      description: `${t('高さ補正\u3000(終端)')}`,
      width: 100
    }
  ];

  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState([]);
  const [data, setData] = useState<[]>([]);
  const handleClose = () => {
    setOpenData([]);
    setOpen(false);
  };

  const goDetail = (datas: any) => {
    setOpenData(datas);
    setOpen(true);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    goDetail(params.row);
  };

  const COLUMN_ORDER: any = [{name: 'exec_time', order: 0}];

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_PRODUCTION_STATUS,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: fmtYYYYMMDDHHmmss(dayjs().add(-7, 'd')),
    select_date_to: fmtYYYYMMDDHHmmss(dayjs()),
    machine: null,
    serial: null,
    work: null,
    judge: 9,
    mtcode: 9,
    boardthickness: null,
    workheight: null,
    splicecode: null,
    ...defaultCondition
  });

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  const apiFetchSub = async () => {
    setStartProcess(true);

    Promise.allSettled([
      //
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const apiFetch = async () => {
    setStartProcess(true);

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42024,
        parameters: {
          machine: searchValue.machine === t('すべて') ? null : searchValue.machine,
          serial: searchValue.serial ?? null,
          work: searchValue.work === t('すべて') ? null : searchValue.work,
          judge: searchValue.judge === 9 ? null : searchValue.judge,
          mtcode: searchValue.mtcode === 9 ? null : searchValue.mtcode,
          boardthickness:
            searchValue.boardthickness === t('すべて') ? null : searchValue.boardthickness,
          workheight: searchValue.workheight === t('すべて') ? null : searchValue.workheight,
          splicecode: searchValue.splicecode === 9 ? null : searchValue.splicecode,
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to
        },
        ds_state: setData,
        name: `${t('加工条件ファインダー')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetchSub();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 検索イベント
   * @returns
   */
  const doSearch = () => {
    apiFetch();
  };

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   * 検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel: searchValue.select_date_from,
        colName: t('対象期間From'),
        colWidth: 300
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel: searchValue.select_date_to,
        colName: t('対象期間To'),
        colWidth: 300
      },
      {
        name: 'machine',
        value: searchValue.machine,
        valueLabel: searchValue.machine === t('すべて') ? ALL : searchValue.machine,
        colName: t('マシン'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work === t('すべて') ? ALL : searchValue.work,
        colName: t('製造番号'),
        colWidth: 300
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge,
        colName: t('総合判定'),
        colWidth: 300
      },
      {
        name: 'serial',
        value: searchValue.serial,
        valueLabel: searchValue.serial,
        colName: t('シリアルNo'),
        colWidth: 300
      },
      {
        name: 'mtcode',
        value: searchValue.mtcode,
        valueLabel: searchValue.mtcode,
        colName: t('材質'),
        colWidth: 300
      },
      {
        name: 'boardthickness',
        value: searchValue.boardthickness,
        valueLabel: searchValue.boardthickness,
        colName: t('板厚(mm)'),
        colWidth: 300
      },
      {
        name: 'workheight',
        value: searchValue.workheight,
        valueLabel: searchValue.workheight,
        colName: t('高さ(mm)'),
        colWidth: 300
      },
      {
        name: 'splicecode',
        value: searchValue.splicecode,
        valueLabel: searchValue.splicecode,
        colName: t('継手'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.加工条件ファインダー')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
          />
          <LFCSelectFormMachineWelding
            name={'machine'}
            label={`${t('マシン名')}`}
            value={searchValue.machine}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            size={'small'}
          />
          <LFCSelectFormWorkWelding
            name={'work'}
            label={`${t('製造番号')}`}
            value={searchValue.work}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCTextField
            name="serial"
            label={t('シリアルNo')}
            value={searchValue.serial}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCSelectFormMtCode
            name={'mtcode'}
            value={searchValue.mtcode}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCSelectFormThicknessWelding
            name={'boardthickness'}
            label={`${t('板厚(mm)')}`}
            value={searchValue.boardthickness}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            size={'small'}
          />
          <LFCSelectFormWorkHeightWelding
            name={'workheight'}
            label={`${t('高さ(mm)')}`}
            value={searchValue.workheight}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            size={'small'}
          />
          <LFCSelectFormSpliceCode
            name={'splicecode'}
            label={`${t('継手')}`}
            value={searchValue.splicecode}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            size={'small'}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_PRODUCTION_STATUS}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LFCDataGrid
              apiRef={apiRef}
              columns={BASE_COL}
              rows={data}
              height="75vh"
              onCellClick={onCellClick}
              exportFilename={`L-ROBOT_${t('検索')}`}
              columnOrder={COLUMN_ORDER}
            />
          </Grid>
        </Grid>
      </Box>
      <WeldingDetailDig open={open} onClose={handleClose} datas={openData} />
    </GenericTemplate>
  );
};

export default ProcessingConditionFinderPage;
