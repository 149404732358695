import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsLineMonthlyResults from '_components/charts/LFCChartsLineMonthlyResults';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

const MonthlyResults = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [searchValue, setSearchValue] = useState({
    yearmonth: new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/1'
  });

  // 期間：
  let yearmonth: any = [];
  let tmp_date: any = new Date(new Date().getFullYear() + '/' + (new Date().getMonth() + 1) + '/1');
  for (let i = 0; i < 12; i++) {
    yearmonth.push({
      name: tmp_date.getFullYear() + '/' + ('0' + (tmp_date.getMonth() + 1)).slice(-2),
      value: tmp_date.getFullYear() + '/' + (tmp_date.getMonth() + 1) + '/1'
    });
    tmp_date.setMonth(tmp_date.getMonth() - 1);
  }

  const [listData, setListData] = useState([]);
  const [totalListData, setTotalListData] = useState([]);

  const listData_column = [
    {
      columnField: 'working_date',
      columnName: t('稼働日'),
      columnOrder: 0
    },
    {
      columnField: 'work_time',
      columnName: t('稼働時間'),
      columnOrder: 1
    },
    {
      columnField: 'month',
      columnName: t('月'),
      columnOrder: 2
    },
    {
      columnField: 'day',
      columnName: t('日'),
      columnOrder: 3
    },

    {
      columnField: 'aim_cycle_time',
      columnName: t('目標サイクルタイム'),
      columnOrder: 4
    },
    {
      columnField: 'aim_line_productivity',
      columnName: t('目標ライン効率'),
      columnOrder: 5
    },
    {
      columnField: 'aim_pph',
      columnName: t('目標PPH'),
      columnOrder: 6
    },
    {
      columnField: 'cycle_time',
      columnName: t('サイクルタイム'),
      columnOrder: 7
    },
    {
      columnField: 'line_productivity',
      columnName: t('ライン効率'),
      columnOrder: 8
    },
    {
      columnField: 'pph',
      columnName: 'PPH',
      columnOrder: 9
    },
    {
      columnField: 'stop_time',
      columnName: t('設備停止時間'),
      columnOrder: 10
    },
    {
      columnField: 'work_rate',
      columnName: t('作業効率'),
      columnOrder: 11
    }
  ];

  const thisNendo = () => {
    let today: any = new Date();
    if (today.getMonth() + 1 < 4) {
      return today.getFullYear() - 1;
    } else {
      return today.getFullYear();
    }
  };

  let totalListData_column: any = [
    {
      field: '年月日',
      headerName: t('年月日'),
      description: t('年月日'),
      width: 100
    },
    {
      field: '計画',
      headerName: t('計画'),
      description: t('計画'),
      width: 80,
      align: 'right'
    },
    {
      field: '計画累計',
      headerName: t('計画累計'),
      description: t('計画累計'),
      width: 80,
      align: 'right'
    },
    {
      field: '実績',
      headerName: t('実績'),
      description: t('実績'),
      width: 80,
      align: 'right'
    },
    {
      field: '実績累計',
      headerName: t('実績累計'),
      description: t('実績累計'),
      width: 80,
      align: 'right'
    },
    {
      field: '進度',
      headerName: t('進度'),
      description: t('進度'),
      width: 80,
      align: 'right'
    }
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setStartProcess(true);
    thisNendo();
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50014,
      parameters: {
        select_date: searchValue.yearmonth
      },
      ds_state: setListData,
      name: `POC_${t('月間実績')}`,
      cancelToken: source.token,
      t
    }).then(() => {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50055,
        parameters: {
          select_date: searchValue.yearmonth
        },
        ds_state: setTotalListData,
        name: `POC_${t('月間実績')}_${t('累計一覧')}`,
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    });
  }, [searchValue]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  function getTableLine(num: number) {
    let list = [];
    let bottom = 40;
    let height = 20;
    for (let i = 0; i < num; i++) {
      list.push({
        type: 'line',
        bottom: bottom - i * height,
        left: 10,
        right: 10,
        style: {
          stroke: '#8c8a8a'
        },
        shape: {
          x1: 0,
          y1: 0,
          x2: 3000,
          y2: 0
        }
      });
    }
    return list;
  }

  return (
    <GenericTemplate title={t('menu.page_name.月間実績')}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCSelectForm
            name={'yearmonth'}
            label={t('年月')}
            value={searchValue.yearmonth}
            id={'sl1'}
            onChange={handleChange}
            selectItem={yearmonth}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Grid item xs={12} md={12}>
              <LFCChartsLineMonthlyResults
                title={` \n${t('目標')}\n${t('実績')}`}
                source={listData}
                x={{dsColumn: 'day'}}
                y={{
                  type: 'line',
                  name: `${t('ライン効率')}(%)`,
                  series: [`${t('ライン効率')}(%)`, `${t('目標ライン効率')}(%)`],
                  dsColumn: ['line_productivity', 'aim_line_productivity'],
                  max: 100
                }}
                color={['rgb(38, 197, 63)', 'rgb(64, 121, 239)']}
                exportData={listData}
                exportFields={listData_column}
                exportFilename={`${t('月間実績')}_${t('ライン効率')}_${t('グラフ')}`}
                graphic={getTableLine(3)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LFCChartsLineMonthlyResults
                title={` \n${t('目標')}\n${t('実績')}`}
                source={listData}
                x={{dsColumn: 'day'}}
                y={{
                  type: 'line',
                  name: `PPH(${t('台')})`,
                  series: [`PPH(${t('台')})`, t('目標PPH')],
                  dsColumn: ['pph', 'aim_pph'],
                  max: 100
                }}
                color={['rgb(226, 239, 50)', 'rgb(64, 121, 239)']}
                exportData={listData}
                exportFields={listData_column}
                exportFilename={`${t('月間実績')}_PPH_${t('グラフ')}`}
                graphic={getTableLine(3)}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LFCChartsLineMonthlyResults
                title={` \n \n${t('実績')}`}
                source={listData}
                x={{dsColumn: 'day'}}
                y={{
                  type: 'line',
                  name: t('設備停止時間'),
                  series: [t('実績')],
                  dsColumn: ['stop_time']
                }}
                color={['rgb(227, 56, 87)']}
                exportData={listData}
                exportFields={listData_column}
                exportFilename={`${t('月間実績')}_${t('設備停止時間')}_${t('グラフ')}`}
                graphic={getTableLine(2)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <LFCDataGridSimple
              columns={totalListData_column}
              rows={totalListData}
              rowHeight={36}
              height="90vh"
              exportFilename={`${t('月間実績')}_${t('累計')}`}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default MonthlyResults;
