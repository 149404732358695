import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import LFCTableWorkerPerformance from 'pages/poc/_components/LFCTableWorkerPerformance';
import {useEffect, useState} from 'react';
import LFCCChartsBarWorkerPerformance from '_components/charts/LFCCChartsBarWorkerPerformance';
import LFCChartsLineMulti6 from '_components/charts/LFCChartsLineMulti6';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';
import DateTimePicker from '_components/inputs/DateTimePicker';

const WorkerPerformance = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [listData50017, setListData50017] = useState([]);
  const [listData, setListData] = useState([]);
  const [listData_show, setListData_show] = useState([]);
  const [tmp_days_list, setTmp_days_list] = useState([]);
  const [userIdList, setUserIdList] = useState<any>([]);

  const [searchValue, setSearchValue] = useState({
    select_year_month: dayjs().startOf('month').format('YYYY-MM')
  });

  //データタイプリスト:
  // ここはi18nで変換して良いか微妙なので一旦保留
  let datatype: any = ['良品数', '不良数', '作業時間', '停止時間', 'サイクルタイム', '稼働率'];

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, select_year_month: event.format('YYYY-MM')});
  };

  let data1_column: any = {
    data_type: t('カテゴリー'),
    year_month_day: t('年月日'),
    user_name: t('ユーザー名'),
    data_value: t('結果')
  };

  const makeDaysList = () => {
    let tmp_day = new Date(searchValue.select_year_month);
    let tmp_days_list: any = [];
    for (let i = 1; i <= 31; i++) {
      tmp_day.setDate(+i);
      if (tmp_day.getMonth() + 1 === new Date(searchValue.select_year_month).getMonth() + 1) {
        tmp_days_list.push(('0' + tmp_day.getDate()).slice(-2));
      }
    }
    setTmp_days_list(tmp_days_list);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    let month: string = dayjs(searchValue.select_year_month).startOf('month').format('YYYY-MM-DD');
    if (month !== 'Invalid Date') {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50017,
        parameters: {
          select_date: month
        },
        ds_state: setListData50017,
        name: `POC_${t('作業者実績')}_${t('一覧')}_${t('総合計あり')}`,
        cancelToken: source.token,
        t
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 50018,
        parameters: {
          select_date: month
        },
        ds_state: setListData,
        name: `POC_${t('作業者実績')}_${t('チャート')}`,
        cancelToken: source.token,
        t
      });
      makeDaysList();
    }
  }, [searchValue]);

  useEffect(() => {
    setUserIdList(Array.from(new Set(listData.map((item: any) => item.user_id))).sort());
  }, [listData]);

  useEffect(() => {
    let tmp: any = [];
    datatype.forEach((item: string) => {
      tmp[item] = [];
      tmp_days_list.forEach((date: number) => {
        userIdList.forEach((userid: string) => {
          listData.forEach((datas: any) => {
            if (
              datas.data_type === item &&
              datas.user_id === userid &&
              ('00' + datas.day).slice(-2) === String(date)
            ) {
              tmp[item].push({
                data_type: item,
                data_value: datas.data_value,
                day: ('0' + datas.day).slice(-2),
                id: datas.id,
                month: datas.month,
                user_id: datas.user_id,
                user_name: datas.user_name,
                year: datas.year,
                year_month_day: datas.year_month_day
              });
            }
          });
        });
      });
    });
    setStartProcess(false);
    setListData_show(tmp);
  }, [userIdList]);

  const dataToLineChart = (searchValue: any, dataType: string) => {
    let list: any = [];
    userIdList.forEach((userID: number) => {
      list[userID] = [];
      searchValue[dataType].forEach((data: any) => {
        if (data.user_id === userID) {
          list[userID].push(data);
        }
      });
    });

    let lineChartData: any = [];
    list.forEach((userData: any) => {
      let tmp_data: any = {
        user_id: userData.length > 0 ? userData[0].user_id : '',
        data: [],
        name: userData.length > 0 ? userData[0].user_name : '',
        stack: false,
        type: 'line'
      };
      tmp_days_list.forEach((day: string) => {
        if (userData.find((data: any) => data.day === day)) {
          userData.forEach((datas: any) => {
            if (datas.day === day) {
              tmp_data.data.push(datas.data_value);
            }
          });
        } else {
          tmp_data.data.push(0);
        }
      });
      lineChartData.push(tmp_data);
    });

    return lineChartData;
  };

  return (
    <GenericTemplate title={t('menu.page_name.作業者実績')}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_year_month)}
            format="YYYY/MM"
            views={['year', 'month']}
            onChange={event => {
              handleChange(event);
            }}
          />
        </LFCFormRowGroup>
      </form>

      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <LFCTableWorkerPerformance
              select_date={searchValue.select_year_month}
              datas={listData50017}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsLineMulti6
              title={t('サイクルタイム')}
              source={dataToLineChart(listData_show, 'サイクルタイム')}
              xData={tmp_days_list}
              color={['#ecf83e', '#707070', '#0ed31f', '#ce8040', '#1c5bf1']}
              exportData={listData}
              exportFields={data1_column}
              exportFilename={`${t('作業者実績')}_${t('グラフ')}_${t('サイクルタイム')}`}
              height="40vh"
              // yAxisName={'実績数'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsLineMulti6
              title={'稼働率'}
              source={dataToLineChart(listData_show, '稼働率')}
              xData={tmp_days_list}
              color={['#ecf83e', '#707070', '#0ed31f', '#ce8040', '#1c5bf1']}
              exportData={listData}
              exportFields={data1_column}
              exportFilename={`${t('作業者実績')}_${t('グラフ')}_${t('稼働率')}`}
              height="40vh"
              // yAxisName={'実績数'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCCChartsBarWorkerPerformance
              title={t('作業時間')}
              // @ts-ignore
              source={listData_show['作業時間']}
              x={{type: 'category', dsColumn: 'day', data: tmp_days_list}}
              y={{type: 'value', dsColumn: 'data_value'}}
              stacked={true}
              series="user_name"
              height="40vh"
              color={['#ecf83e', '#707070', '#0ed31f', '#ce8040', '#1c5bf1']}
              exportData={listData}
              exportFields={data1_column}
              exportFilename={`${t('作業者実績')}_${t('グラフ')}_${t('作業時間')}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCCChartsBarWorkerPerformance
              title={t('設備停止時間')}
              // @ts-ignore
              source={listData_show['停止時間']}
              x={{type: 'category', dsColumn: 'day', data: tmp_days_list}}
              y={{type: 'value', dsColumn: 'data_value'}}
              stacked={true}
              series="user_name"
              height="40vh"
              color={['#ecf83e', '#707070', '#0ed31f', '#ce8040', '#1c5bf1']}
              exportData={listData}
              exportFields={data1_column}
              exportFilename={`${t('作業者実績')}_${t('グラフ')}_${t('設備停止時間')}`}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};
export default WorkerPerformance;
