import {CognitoUser, CognitoUserPool, CognitoUserSession} from 'amazon-cognito-identity-js';
import awsConfiguration from 'awsConfiguration';

const _cognitoUserPool: CognitoUserPool = new CognitoUserPool({
  UserPoolId: awsConfiguration.UserPoolId,
  ClientId: awsConfiguration.ClientId
});
let _cognitoUser: CognitoUser | null = null;

/**
 * CognitoのUserPoolを返します
 * @returns
 */
export const getUserPool = () => {
  return _cognitoUserPool;
};

/**
 * CognitoのCognitoUserを生成します
 * @returns
 */
export const getNewCognitoUser = (userId: string) => {
  _cognitoUser = new CognitoUser({
    Username: userId,
    Pool: getUserPool()
  });
  return _cognitoUser;
};

/**
 * Cognitoの認証済CognitoUserを返します
 * @returns
 */
export const getCognitoUser = () => {
  return _cognitoUser;
};

/**
 * CognitoのSessionを返します
 * @returns
 */
export const getSessionSync = (): Promise<CognitoUserSession | null> => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser();
    if (cognitoUser == null) {
      // 未認証
      console.error(`cognitoUser is null!! `);
      resolve(null);
      return;
    }

    cognitoUser.getSession((error: Error | null, session: CognitoUserSession) => {
      if (error) {
        // ネットワークエラーなど
        console.error(`LFCCognito.getSession() is Error!! : ${error}`); //Network error
        resolve(null);
        return;
      }
      if (!session.isValid()) {
        // セッションの有効期限切れ
        console.error(`LFCCognito.getSession() is isValid false!! `);
        resolve(null);
        return;
      }
      resolve(session);
      return;
    });

    // reject('処理失敗');
  });
};
