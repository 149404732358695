import {AppBar, Box, Dialog, DialogContent, Grid, Tab, Tabs, Typography} from '@mui/material';
import axios from 'axios';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LRobotXYZGraph from '_components/lrobot/LRobotXYZGraph';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import LFCButton from '_components/inputs/LFCButton';
import {useTranslation} from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style?: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <section
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </section>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

interface Props {
  open: boolean;
  onClose: () => void;
  datas: any;
}

const PathGenerateDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [value, setValue] = useState<number>(0);
  let today = new Date();
  let fromday = new Date();
  fromday.setDate(today.getDate() - 7);

  let fromday_string: string =
    fromday.getFullYear() +
    '-' +
    ('0' + (fromday.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + fromday.getDate()).slice(-2) +
    'T' +
    ('0' + fromday.getHours()).slice(-2) +
    ':' +
    ('0' + fromday.getMinutes()).slice(-2);

  let today_string: string =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2) +
    'T' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2);

  const [searchValue, setSearchValue] = useState<any>({
    map: '',
    page_type: '',
    selectType: '0',
    select_datetime_from_a: fromday_string,
    select_datetime_to_a: today_string,
    select_datetime_from_b: fromday_string,
    select_datetime_to_b: today_string,
    gap_no: '0'
  });

  const [searchType, setSearchType] = useState([{name: t('ALL'), value: '0'}]);

  const TabHandleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    setSearchValue({...searchValue, page_type: newValue === 0 ? 'Fitting' : 'Gap'});
  };

  let selectType: any = [
    {name: t('ALL'), value: '0'},
    {name: t('月毎'), value: '1'},
    {name: t('日毎'), value: '2'},
    {name: t('時間毎'), value: '3'}
  ];

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const [data60004_a, setData60004_a] = useState<any>([]);
  const [data60004_b, setData60004_b] = useState<any>([]);
  const [singleValuesData_a, setSingleValuesData_a] = useState<any>([]);
  const [singleValuesData_b, setSingleValuesData_b] = useState<any>([]);

  const [graphData60021_a, setGraphData60021_a] = useState<any>([]);
  const [graphData60021_b, setGraphData60021_b] = useState<any>([]);

  const [graphData60025_a, setGraphData60025_a] = useState<any>([]);
  const [graphData60025_b, setGraphData60025_b] = useState<any>([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setSearchValue({...searchValue, map: props.datas.x});
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setSingleValuesData_a({
      total_count: data60004_a[0] ? String(data60004_a[0].total_count) : '-',
      ng_percent: data60004_a[0] ? round(data60004_a[0].ng_percent * 100, 1) + '%' : '-'
    });
  }, [data60004_a]);

  useEffect(() => {
    setSingleValuesData_b({
      total_count: data60004_b[0] ? String(data60004_b[0].total_count) : '-',
      ng_percent: data60004_b[0] ? round(data60004_b[0].ng_percent * 100, 1) + '%' : '-'
    });
  }, [data60004_b]);

  useEffect(() => {
    setValue(searchValue.page_type === 'Fitting' ? 0 : 1);
  }, [searchValue]);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    setData60004_a([]);
    setData60004_b([]);
    setGraphData60021_a([]);
    setGraphData60021_b([]);
    setGraphData60025_a([]);
    setGraphData60025_b([]);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60004,
      parameters: {
        select_datetime_from: searchValue.select_datetime_from_a,
        select_datetime_to: searchValue.select_datetime_to_a
      },
      ds_state: setData60004_a,
      name: `LROBOT_${t('現場モニタ')}_${t('生産数不良数')}:a`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60004,
      parameters: {
        select_datetime_from: searchValue.select_datetime_from_b,
        select_datetime_to: searchValue.select_datetime_to_b
      },
      ds_state: setData60004_b,
      name: `LROBOT_${t('現場モニタ')}_${t('生産数不良数')}:b`,
      cancelToken: source.token,
      t
    });
    if (value === 0) {
      doSearch_Fitting();
    }
    if (value === 1) {
      doSearch_Gap();
    }
  };

  const doSearch_Fitting = async () => {
    // if (searchValue.map) {
    switch (searchValue.selectType) {
      case '0':
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 600221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_a,
            select_datetime_to: searchValue.select_datetime_to_a
          },
          ds_state: setGraphData60021_a,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('ALL')}:a`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 600221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_b,
            select_datetime_to: searchValue.select_datetime_to_b
          },
          ds_state: setGraphData60021_b,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('ALL')}:b`,
          cancelToken: source.token,
          t
        });
        break;
      case '1':
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 601221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_a,
            select_datetime_to: searchValue.select_datetime_to_a
          },
          ds_state: setGraphData60021_a,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('月単位')}:a`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 601221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_b,
            select_datetime_to: searchValue.select_datetime_to_b
          },
          ds_state: setGraphData60021_b,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('月単位')}:b`,
          cancelToken: source.token,
          t
        });
        break;
      case '2':
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 602221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_a,
            select_datetime_to: searchValue.select_datetime_to_a
          },
          ds_state: setGraphData60021_a,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('日単位')}:a`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 602221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_b,
            select_datetime_to: searchValue.select_datetime_to_b
          },
          ds_state: setGraphData60021_b,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('日単位')}:b`,
          cancelToken: source.token,
          t
        });
        break;
      case '3':
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 603221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_a,
            select_datetime_to: searchValue.select_datetime_to_a
          },
          ds_state: setGraphData60021_a,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('時間単位')}:a`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 603221,
          parameters: {
            map:
              searchValue.map !== '' ? (searchValue.map !== 'None' ? searchValue.map : '') : null,
            select_datetime_from: searchValue.select_datetime_from_b,
            select_datetime_to: searchValue.select_datetime_to_b
          },
          ds_state: setGraphData60021_b,
          name: `LROBOT_Fitting${t('比較グラフ')}:${t('時間単位')}:b`,
          cancelToken: source.token,
          t
        });
        break;
    }
    // }
    await setStartProcess(false);
  };

  const doSearch_Gap = async () => {
    if (searchValue.gap_no) {
      switch (searchValue.selectType) {
        case '0':
          // sqlId = 60025;
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 600251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_a,
              select_datetime_to: searchValue.select_datetime_to_a
            },
            ds_state: setGraphData60025_a,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('ALL')}:a`,
            cancelToken: source.token,
            t
          });
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 600251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_b,
              select_datetime_to: searchValue.select_datetime_to_b
            },
            ds_state: setGraphData60025_b,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('ALL')}:b`,
            cancelToken: source.token,
            t
          });
          break;
        case '1':
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 601251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_a,
              select_datetime_to: searchValue.select_datetime_to_a
            },
            ds_state: setGraphData60025_a,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('月単位')}:a`,
            cancelToken: source.token,
            t
          });
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 601251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_b,
              select_datetime_to: searchValue.select_datetime_to_b
            },
            ds_state: setGraphData60025_b,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('月単位')}:b`,
            cancelToken: source.token,
            t
          });
          break;
        case '2':
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 602251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_a,
              select_datetime_to: searchValue.select_datetime_to_a
            },
            ds_state: setGraphData60025_a,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('日単位')}:a`,
            cancelToken: source.token,
            t
          });
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 602251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_b,
              select_datetime_to: searchValue.select_datetime_to_b
            },
            ds_state: setGraphData60025_b,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('日単位')}:b`,
            cancelToken: source.token,
            t
          });
          break;
        case '3':
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 603251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_a,
              select_datetime_to: searchValue.select_datetime_to_a
            },
            ds_state: setGraphData60025_a,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('時間単位')}:a`,
            cancelToken: source.token,
            t
          });
          await getLFCData({
            snack: enqueueSnackbar,
            sql_id: 603251,
            parameters: {
              map: searchValue.map !== '' ? searchValue.map : null,
              gap_no: searchValue.gap_no,
              select_datetime_from: searchValue.select_datetime_from_b,
              select_datetime_to: searchValue.select_datetime_to_b
            },
            ds_state: setGraphData60025_b,
            name: `LROBOT_Gap${t('比較グラフ')}:${t('時間単位')}:b`,
            cancelToken: source.token,
            t
          });
          break;
      }
    }
    await setStartProcess(false);
  };

  useEffect(() => {
    if (props.open) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60024,
        parameters: {},
        ds_state: setSearchType,
        name: `LROBOT_Gap${t('セレクトメニュー')}`,
        cancelToken: source.token,
        t
      }).then(() =>
        setSearchValue({
          ...searchValue,
          map: props.datas.x,
          page_type: props.datas.y,
          selectType: '0',
          select_datetime_from:
            props.datas.searchValue && props.datas.searchValue.select_datetime_from
              ? props.datas.searchValue.select_datetime_from
              : fromday_string,
          select_datetime_to:
            props.datas.searchValue && props.datas.searchValue.select_datetime_to
              ? props.datas.searchValue.select_datetime_to
              : today_string,
          select_datetime_from_a:
            props.datas.searchValue && props.datas.searchValue.select_datetime_from
              ? props.datas.searchValue.select_datetime_from
              : fromday_string,
          select_datetime_to_a:
            props.datas.searchValue && props.datas.searchValue.select_datetime_to
              ? props.datas.searchValue.select_datetime_to
              : today_string,
          select_datetime_from_b:
            props.datas.searchValue && props.datas.searchValue.select_datetime_from
              ? props.datas.searchValue.select_datetime_from
              : fromday_string,
          select_datetime_to_b:
            props.datas.searchValue && props.datas.searchValue.select_datetime_to
              ? props.datas.searchValue.select_datetime_to
              : today_string,
          gap_no: props.datas.gapno ? props.datas.gapno : '0'
        })
      );
    } else {
      setSearchValue({
        map: '',
        page_type: '',
        gap_no: '0',
        selectType: '0',
        select_datetime_from_a: fromday_string,
        select_datetime_to_a: today_string,
        select_datetime_from_b: fromday_string,
        select_datetime_to_b: today_string
      });
      setData60004_a([]);
      setData60004_b([]);
      setGraphData60021_a([]);
      setGraphData60021_b([]);
      setGraphData60025_a([]);
      setGraphData60025_b([]);
    }
  }, [props.open]);

  const selectChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={props.onClose}>{t('比較')}</LFCDialogTitle>
        <DialogContent>
          <ProgressBar startProcess={startProcess} />
          <Grid container spacing={1} style={{paddingTop: 10}}>
            <Grid item xs={12} md={5} lg={4} xl={3}>
              <LFCTitledCard title={t('検索条件')}>
                <form ref={formRef}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <LFCTitledCard title={t('部位名')} transparent>
                        <Typography variant={'h5'}>{searchValue.map}</Typography>
                      </LFCTitledCard>
                    </Grid>
                    <Grid item xs={12}>
                      <LFCTitledCard title={`${t('対象期間')}A`} transparent>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <LFCFormRowGroup>
                              <LFCDatetimePicker
                                name={'select_datetime_from_a'}
                                label={t('対象期間From')}
                                value={searchValue.select_datetime_from_a}
                                onChange={event => {
                                  handleInputChange(event, searchValue, setSearchValue);
                                }}
                                required
                              />
                              <LFCDatetimePicker
                                name={'select_datetime_to_a'}
                                label={t('対象期間To')}
                                value={searchValue.select_datetime_to_a}
                                onChange={event => {
                                  handleInputChange(event, searchValue, setSearchValue);
                                }}
                                required
                              />
                            </LFCFormRowGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <LFCSingleValues
                              columns={[
                                {field: 'total_count', headerName: t('生産数'), width: '100px'},
                                {field: 'ng_percent', headerName: `${t('不良率')}`, width: '100px'}
                              ]}
                              source={singleValuesData_a}
                            />
                          </Grid>
                        </Grid>
                      </LFCTitledCard>
                    </Grid>
                    <Grid item xs={12}>
                      <LFCTitledCard title={`${t('対象期間')}B`} transparent>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <LFCFormRowGroup>
                              <LFCDatetimePicker
                                name={'select_datetime_from_b'}
                                label={t('対象期間From')}
                                value={searchValue.select_datetime_from_b}
                                onChange={event => {
                                  handleInputChange(event, searchValue, setSearchValue);
                                }}
                                required
                              />
                              <LFCDatetimePicker
                                name={'select_datetime_to_b'}
                                label={t('対象期間To')}
                                value={searchValue.select_datetime_to_b}
                                onChange={event => {
                                  handleInputChange(event, searchValue, setSearchValue);
                                }}
                                required
                              />
                            </LFCFormRowGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <LFCSingleValues
                              columns={[
                                {field: 'total_count', headerName: t('生産数'), width: '100px'},
                                {field: 'ng_percent', headerName: `${t('不良率')}`, width: '100px'}
                              ]}
                              source={singleValuesData_b}
                            />
                          </Grid>
                        </Grid>
                      </LFCTitledCard>
                    </Grid>
                    <Grid item xs={12}>
                      <LFCSelectForm
                        name={'selectType'}
                        label={t('集計単位')}
                        value={searchValue.selectType}
                        id={'sl1'}
                        onChange={handleChange}
                        selectItem={selectType}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LFCButton color="primary" onClick={() => doSearch()} fullWidth>
                        {t('検索')}
                      </LFCButton>
                    </Grid>
                  </Grid>
                </form>
              </LFCTitledCard>
            </Grid>

            <Grid item xs={12} md={7} lg={8} xl={9}>
              <AppBar position="static">
                <Tabs value={value} onChange={TabHandleChange}>
                  <Tab label="Fitting" {...a11yProps(0)} />
                  <Tab label="Gap" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0}>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12} sm={6} textAlign="center">
                    [{`${t('対象期間')}A`}]
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="center">
                    [{`${t('対象期間')}B`}]
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LRobotXYZGraph
                      show={'Fitting-a'}
                      map={searchValue.map}
                      xyz={graphData60021_a}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LRobotXYZGraph
                      show={'Fitting-b'}
                      map={searchValue.map}
                      xyz={graphData60021_b}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Grid container spacing={1} mt={1}>
                  <Grid item xs={12}>
                    <LFCSelectForm
                      name={'gap_no'}
                      label={'GapNO'}
                      value={searchValue.gap_no}
                      id={'gap_no'}
                      onChange={selectChange}
                      selectItem={searchType}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="center">
                    [{`${t('対象期間')}A`}]
                  </Grid>
                  <Grid item xs={12} sm={6} textAlign="center">
                    [{`${t('対象期間')}B`}]
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LRobotXYZGraph show={'Gap-a'} map={searchValue.map} gap={graphData60025_a} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LRobotXYZGraph show={'Gap-b'} map={searchValue.map} gap={graphData60025_b} />
                  </Grid>
                </Grid>
              </TabPanel>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PathGenerateDig;
