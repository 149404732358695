import {useMemo} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  data: any;
  onEvents?: any;
  name: string;
  exportData: any;
  exportFields: any;
  exportFilename: string;
  onClick?: (prm: {data: any}) => void;
}

const PieChartSearchByYear = (props: Props) => {
  const {t} = useTranslation();
  return useMemo(() => {
    const option = {
      title: {
        text: props.name
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        show: false
      },
      series: [
        {
          name: props.data.name,
          type: 'pie',
          radius: ['20%', '70%'],
          avoidLabelOverlap: false,
          label: {
            formatter: (prm: any) => {
              return prm.name + '\n' + prm.value + t('件') + '\n' + prm.percent + '%';
            },
            position: 'inside'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: props.data
        }
      ]
    };

    return (
      <LFCBaseCharts
        option={option}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
      />
    );
  }, [props.data]);
};

export default PieChartSearchByYear;
