import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import MySettings from '_contexts/types/MySettings';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import {useAuthUser} from './AuthUserProvider';
import {setLFCSessionStorage} from './LFCSessionStorage';

type OperationType = {
  regetMySetting: () => void;
  saveMySetting: (mys: MySettings) => void;
};

/**
 * OperationContext
 */
const MySettingsOperationContext = React.createContext<OperationType>({
  regetMySetting: () => console.error('Providerが設定されていません'),
  saveMySetting: (mys: MySettings) => console.error('Providerが設定されていません')
});

/**
 * Context
 */
const MySettingsContext = React.createContext<MySettings>({} as MySettings);

/**
 * My設定プロバイダ
 */
const MySettingsProvider: React.FC = ({children}) => {
  const {i18n, t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const authUser = useAuthUser();
  const [mySettings, setMySettings] = useState<MySettings>({} as MySettings);

  /**
   * 認証情報変更時の処理
   */
  useEffect(() => {
    if (authUser == null) {
      //未認証だったら初期化
      setMySettings({} as MySettings);
      return;
    }
    regetMySetting();
  }, [authUser]);

  /**
   * locale変更時の処理
   */
  useEffect(() => {
    if (!mySettings.locale) {
      return;
    }
    i18n.changeLanguage(mySettings.locale);
    dayjs.locale(mySettings.locale);
    //MUIとEchartsは個別処理する
  }, [mySettings.locale]);

  /**
   * MySettingを再読み込みします
   */
  const regetMySetting = async () => {
    const data = await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 10001,
      parameters: {},
      name: 'MySetting',
      t
    });

    if (data.length === 0) {
      return;
    }

    //ブラウザのlanguagesを取得
    const blrLang = (
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language ||
      'en'
    ).slice(0, 2);

    const setting = {
      ...mySettings,
      isDark: data[0].is_dark,
      locale: data[0].locale || blrLang, // DBに言語設定されてない場合はブラウザの設定を使う
      userName: data[0].user_name,
      companyName: data[0].company_name,
      region: data[0].region || 'ap' // DBに言語設定されてない場合はap(アジアパシフィック)を使う
    };

    //関数で読み込めるようにWebStorageにも保持
    const wUrlBase =
      process.env[`REACT_APP_EP_WEBAPI_${setting.region.toUpperCase()}`] ||
      process.env.REACT_APP_EP_WEBAPI_AP;
    const wUrlBaseFull = `${wUrlBase}/${process.env.REACT_APP_ENV}`;
    setLFCSessionStorage({webapiBaseUrl: wUrlBaseFull});

    setMySettings(setting);
  };

  /**
   * DB保存処理
   * @param mys
   */
  const saveMySetting = async (mys: MySettings) => {
    setMySettings(mys);

    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 10003,
      parameters: {
        is_dark: mys.isDark,
        locale: mys.locale
      },
      name: '更新',
      t
    });
  };

  return (
    <MySettingsOperationContext.Provider value={{regetMySetting, saveMySetting}}>
      <MySettingsContext.Provider value={mySettings}>{children}</MySettingsContext.Provider>
    </MySettingsOperationContext.Provider>
  );
};

export const useMySettings = () => useContext(MySettingsContext);
export const useRegetMySetting = () => useContext(MySettingsOperationContext).regetMySetting;
export const useSaveSetMySetting = () => useContext(MySettingsOperationContext).saveMySetting;

export default MySettingsProvider;
