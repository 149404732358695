import {Box, FormLabel, Paper, Typography, useTheme} from '@mui/material';
import {getColors} from '_themes/LFCCommonThemes';

/**
 * 引数
 */
interface Props {
  title: string;
  titleSub?: string;
  children?: React.ReactNode;
  transparent?: boolean;
  height?: string;
}

/**
 * LFCTitledCard
 */
const LFCTitledCard = (props: Props) => {
  const theme = useTheme();
  const colors = getColors(theme.palette.mode);

  return (
    <Paper
      style={{
        marginBottom: 10,
        marginRight: 10,
        padding: 10,
        border: props.transparent ? '' : `2px solid ${colors.dividerColor}`,
        height: props.height
      }}
    >
      <FormLabel>
        <Typography color="textSecondary" variant="subtitle1">
          {props.title}&nbsp;&nbsp;
          <Typography color="textSecondary" variant="caption">
            {props.titleSub}
          </Typography>
        </Typography>
      </FormLabel>
      <Box mt={1} ml={1}>
        {props.children}
      </Box>
    </Paper>
  );
};
export default LFCTitledCard;
