import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Typography
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useSnackbar} from 'notistack';
import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as LFCTitleImgWide} from '_assets/images/lfc-logo-1.svg';
import {ReactComponent as LFCTitleImg} from '_assets/images/lfc-logo-3.svg';
import Copyright from '_components/common/Copyright';
import LFCButton from '_components/inputs/LFCButton';
import LFCPasswordField from '_components/inputs/LFCPasswordField';
import LFCTextField from '_components/inputs/LFCTextField';
import {useLogin} from '_contexts/AuthUserProvider';
import {handleInputChange} from '_logics/LFCUtil';
import OpenSiteTemplate from '_templates/OpenSiteTemplate';
import NewPassDig from './components/NewPassDig';
import ResetPassDig from './components/ResetPassDig';

/**
 * Styles
 */
const useStyles = makeStyles(theme => ({
  video: {
    minWidth: '100%',
    minHeight: '100vh',
    position: 'absolute'
  },
  /*画面サイズが変わっても常に動画の中央が表示されるようにする*/
  /*動画よりも画面が横に長くなるとき用*/
  '@media (aspect-ratio: 16/9), (min-aspect-ratio: 16/9)': {
    video: {
      width: '100%',
      top: '50%',
      transform: 'translateY(-50%)'
    }
  },
  /*動画よりも画面が縦に長くなるとき用*/
  '@media (max-aspect-ratio: 16/9)': {
    video: {
      height: '100%',
      left: '50%',
      transform: 'translateX(-50%)'
    }
  }
}));

/**
 * ログイン画面
 * @returns
 */
const LoginPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const login = useLogin();

  const formRef = useRef<HTMLFormElement>(null!);

  // パスワード変更
  const [openNewPassPop, setOpenNewPassPop] = useState(false);
  // パスワードリセット
  const [openPassResetPop, setOpenPassResetPop] = useState(false);
  // プログレス
  const [openProg, setOpenProg] = useState(false);

  const [values, setValues] = useState({
    userId: '',
    password: ''
  });

  /**
   * ログイン処理
   * @returns
   */
  const signIn = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    // progress on
    setOpenProg(true);

    // auth check
    login(
      values.userId,
      values.password,
      (err: Error) => {
        // 認証エラー処理
        setOpenProg(false);
        enqueueSnackbar(t(`aws.cognito.${err.message}`), {variant: 'error'});
      },
      (userAttributes, requiredAttributes) => {
        // 要パスワード変更
        setOpenProg(false);
        setOpenNewPassPop(true);
      },
      ''
    );
  };

  return (
    <OpenSiteTemplate>
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        {/* 背景 */}
        <Box position="relative" overflow="hidden" width="100%" height="100vh">
          <video
            id="video"
            poster="videos/none.png"
            webkit-playsinline="true"
            playsInline
            muted
            autoPlay
            loop
            className={classes.video}
          >
            <source src="videos/login_backV3.mp4" type="video/mp4" />
          </video>
        </Box>
        {process.env.REACT_APP_ENV !== 'production' ? (
          <Box
            position="fixed"
            top="0px"
            left="0px"
            width="100%"
            sx={{textAlign: 'center', color: 'write', backgroundColor: 'red'}}
          >
            <Typography variant="h6" noWrap>
              {`${process.env.REACT_APP_ENV}`}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {/* フォーム */}
        <Box position="absolute" width="80%" maxWidth="1000px">
          <Grid container justifyContent="center" alignItems="center">
            <Hidden mdUp>
              <Grid item xs={12}>
                <LFCTitleImgWide fill="white" width="100%" height="50" />
              </Grid>
            </Hidden>
            <Hidden only={['xs', 'sm']}>
              <Grid item md={6}>
                {/* <img alt="complex" src={LFCTitleImg} width="90%" /> */}
                <LFCTitleImg fill="white" width="90%" />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6}>
              <Paper
                style={{
                  padding: 20,
                  backgroundColor: 'rgba(33, 33, 33, 0.5)',
                  boxShadow: '0 0 20px #9E9E9E'
                }}
              >
                <Grid container justifyContent="center">
                  <Grid item xs={10}>
                    <form ref={formRef}>
                      <LFCTextField
                        name="userId"
                        label={t('ユーザID')}
                        value={values.userId}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                        autoFocus
                        size="medium"
                      />
                      <LFCPasswordField
                        name="password"
                        label={t('パスワード')}
                        value={values.password}
                        onChange={event => {
                          handleInputChange(event, values, setValues);
                        }}
                        required
                        margin="normal"
                        fullWidth
                        inputProps={{minLength: 8}}
                        size="medium"
                      />
                      <Box mt={2} textAlign="center">
                        <LFCButton size="large" onClick={signIn}>
                          {t('ログイン')}
                        </LFCButton>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Box mt={1} textAlign="right">
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    setOpenPassResetPop(true);
                  }}
                >
                  {t('パスワードリセット')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {/* コピーライト */}
        <Box position="absolute" right={0} bottom={0} mr={1}>
          <Copyright />
        </Box>
      </Box>
      {/* 初回ログイン新パスワード入力ダイアログ */}
      <NewPassDig
        open={openNewPassPop}
        onClose={() => {
          setOpenNewPassPop(false);
        }}
        userId={values.userId}
        password={values.password}
      />
      {/* パスワードリセット依頼ダイアログ */}
      <ResetPassDig
        open={openPassResetPop}
        onClose={() => {
          setOpenPassResetPop(false);
        }}
      />
      {/* プログレス */}
      <Backdrop sx={{zIndex: 9999, color: '#fff'}} open={openProg}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </OpenSiteTemplate>
  );
};
export default LoginPage;
