import {Grid, Box, RadioGroup, FormControlLabel, Radio, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBar from '_components/charts/LFCChartsBar';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';

import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import dayjs from 'dayjs';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_BUMPCHECK} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start', // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1) // width: "20ch",
      }
    }
  };
});

const BumpCheckPage: React.FC = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();

  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);

  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LQ_JOBANALYSIS, LOCATIONID_LQ_JOBANALYSIS_BUMPCHECK) ?? {
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    }
  );
  const [listData, setListData] = useState([]);
  const [map_series, setMap_series] = useState([]);
  const [lq_time_list, setLq_time_list] = useState([]);
  const [graphBaseData, setGraphBaseData] = useState([]);
  const [startProcess, setStartProcess] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };

  const date_calc = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setMap_series(Array.from(new Set(listData.map(({map}) => map))).sort());
    setLq_time_list(date_calc());
  }, [listData]);

  useEffect(() => {
    let info_list_map_time: any = [];
    let tmp_map_lq_time: any = [];

    if (searchValue.searchType !== 0) {
      map_series.forEach(map_value => {
        tmp_map_lq_time[map_value] = [];
        lq_time_list.forEach(lq_time => {
          tmp_map_lq_time[map_value][lq_time] = [];
          listData.forEach((item: any) => {
            let lq_time_date: string = item.lq_date;
            if (lq_time_date === lq_time && item.map === map_value) {
              tmp_map_lq_time[map_value][lq_time].push({
                map: item.map,
                lq_time: lq_time_date,
                count: item.bump_count
              });
            }
          });
        });
      });

      if (Object.keys(tmp_map_lq_time).length > 0) {
        map_series.forEach((map_value: string) => {
          info_list_map_time[map_value] = [];
          Object.values(tmp_map_lq_time[map_value]).forEach((map_date: any, index) => {
            info_list_map_time[map_value].push({
              map: map_value,
              lq_time: Object.keys(tmp_map_lq_time[map_value])[index],
              count: map_date
                .map((map_value: any) => map_value.count)
                .reduce((prev: any, curr: any) => prev + curr, 0)
            });
          });
        });
      }
    } else {
      map_series.forEach(map_value => {
        info_list_map_time[map_value] = listData.filter((a: any) => a.map === map_value);
      });
    }
    setGraphBaseData(info_list_map_time);
  }, [map_series]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const doSearch = async () => {
    setStartProcess(true);
    setListData([]);
    setMap_series([]);
    setLq_time_list([]);

    switch (searchValue.searchType) {
      case 0:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 135,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
            deviceid: !isEmpty(searchValue.deviceid)
              ? '{' + searchValue.deviceid.join(',') + '}'
              : null,
            judge: searchValue.judge === 9 ? null : searchValue.judge
          },
          ds_state: setListData,
          name: 'sql-id:135',
          cancelToken: source.token,
          t
        });
        break;
      case 1:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 134,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
            deviceid: !isEmpty(searchValue.deviceid)
              ? '{' + searchValue.deviceid.join(',') + '}'
              : null,
            judge: searchValue.judge === 9 ? null : searchValue.judge
          },
          ds_state: setListData,
          name: 'sql-id:134',
          cancelToken: source.token,
          t
        });
        break;
      case 2:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 132,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
            deviceid: !isEmpty(searchValue.deviceid)
              ? '{' + searchValue.deviceid.join(',') + '}'
              : null,
            judge: searchValue.judge === 9 ? null : searchValue.judge
          },
          ds_state: setListData,
          name: 'sql-id:132',
          cancelToken: source.token,
          t
        });
        break;
      case 3:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 133,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
            deviceid: !isEmpty(searchValue.deviceid)
              ? '{' + searchValue.deviceid.join(',') + '}'
              : null,
            judge: searchValue.judge === 9 ? null : searchValue.judge
          },
          ds_state: setListData,
          name: 'sql-id:133',
          cancelToken: source.token,
          t
        });
        break;
    }
    setStartProcess(false);
  };

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
      select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      work: [],
      deviceid: [],
      judge: 9,
      searchType: 2
    });
    setListData([]);
    setMap_series([]);
    setLq_time_list([]);
    setAutoCompleteReset(true);
  };

  let graphBaseData_column = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    bump_count: t('検出数')
  };

  let graphBaseData_column_type0 = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    bump_count: t('検出数'),
    serial: t('シリアルナンバー')
  };

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel:
          searchValue.select_date_from === ''
            ? ''
            : dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間From'),
        colWidth: 200
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel:
          searchValue.select_date_to === ''
            ? ''
            : dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間To'),
        colWidth: 200
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: !isEmpty(searchValue.work) ? searchValue.work.join(',') : '',
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: !isEmpty(searchValue.deviceid) ? searchValue.deviceid.join(',') : '',
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel: searchValue.searchType,
        colName: t('集計単位'),
        colWidth: 100
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42019,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    // 情報取得： LFCAutocomplete：deviceid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42020,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.L-Qジョブ傾向分析')}:${t('menu.page_name.Bump Check')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <Box>
        <form className={classes.search}>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name={t('select_date_from')}
              label={t('対象期間From')}
              value={searchValue.select_date_from}
              required
              onChange={handleChange}
            />
            <LFCDatetimePicker
              name={t('select_date_to')}
              label={t('対象期間To')}
              value={searchValue.select_date_to}
              required
              onChange={handleChange}
            />
            <LFCAutocomplete
              name={t('work')}
              label={t('機種')}
              id={'work'}
              size="small" // small/medium
              value={multiSelectData(work, searchValue.work)}
              // style={{width: 500}}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={work}
            />
            <LFCAutocomplete
              name={'deviceid'}
              label={t('検査装置')}
              id={'deviceid'}
              size="small" // small/medium
              value={multiSelectData(deviceid, searchValue.deviceid)}
              // style={{width: 500}}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={deviceid}
            />
            <LFCSelectFormJudge
              name={'judge'}
              value={searchValue.judge}
              onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
              multiple={false}
            />
            <LFCButton color="primary" onClick={doSearch}>
              {t('検索')}
            </LFCButton>
            <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={PAGEID_LQ_JOBANALYSIS}
              locationNo={LOCATIONID_LQ_JOBANALYSIS_BUMPCHECK}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
          </LFCFormRowGroup>
        </form>
        <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
        <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
          {searchType.map((item: any, index: number) => (
            <FormControlLabel
              value={item.id}
              key={'search_type-' + item.id}
              control={
                <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
              }
              label={
                <Typography display="block" key={'search_type-body-' + item.id}>
                  {item.text}
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </Box>

      <Box mt={1}>
        <Grid container spacing={2}>
          {map_series.map((item, index) => {
            return (
              <Grid item xs={12} md={6} key={index}>
                {item !== '' ? item : '空'}
                <LFCChartsBar
                  title=""
                  source={graphBaseData[item]}
                  x={
                    searchValue.searchType !== 0
                      ? {dsColumn: 'lq_time', type: 'category'}
                      : {dsColumn: 'id', type: 'category'}
                  }
                  y={
                    searchValue.searchType !== 0
                      ? {dsColumn: 'count', type: 'value'}
                      : {dsColumn: 'bump_count', type: 'value'}
                  }
                  exportData={listData.filter((a: any) => a.map === item)}
                  exportFields={
                    searchValue.searchType !== 0 ? graphBaseData_column : graphBaseData_column_type0
                  }
                  exportFilename={'BumpCheck_' + item}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default BumpCheckPage;
