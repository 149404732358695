import {Card, CardContent, Grid, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';
import {AlarmType, RegType} from 'types/onSiteObserver';

type Props = {
  alarmMessage: AlarmType;
  regio: RegType;
};

export const OperatingStatusCard = ({alarmMessage, regio}: Props) => {
  const {t} = useTranslation();
  const determineBgColor = (regio: RegType) => {
    if (
      regio?.regtype === 'numreg' &&
      regio?.adno === '7' &&
      (regio?.val === '20' || regio?.val === '30' || regio?.val === '40')
    ) {
      return 'green';
    } else {
      return '#004020';
    }
  };
  const DrivingCard = () => (
    <Card>
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: determineBgColor(regio)
        }}
      >
        <Typography variant="h3">{t('運転')}</Typography>
      </CardContent>
    </Card>
  );

  const AlarmCard = () => (
    <Card>
      <CardContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: `${
            alarmMessage?.message1 === 'ﾘｾｯﾄ' ? 'rgba(64, 48, 0, 0.5)' : 'rgba(254, 194, 1, 1)'
          }`
        }}
      >
        <Typography variant="h3">{t('警報')}</Typography>
      </CardContent>
    </Card>
  );

  const AlarmMessageCard = () => (
    <Card>
      <CardContent style={{padding: '8px 16px'}}>
        <Typography variant="h6">{t('アラームメッセージ')}</Typography>
      </CardContent>
      <CardContent
        style={{backgroundColor: 'white', gap: '20px', flexDirection: 'row', display: 'flex'}}
      >
        {alarmMessage?.message1 === 'ﾘｾｯﾄ' ? (
          <Typography variant="h6" color="black">
            {dayjs(alarmMessage?.last_update_time).format('YYYY/MM/DD HH:mm:ss')}
          </Typography>
        ) : (
          <>
            <Typography variant="h6" color="black">
              {dayjs(alarmMessage?.regtime).format('YYYY/MM/DD HH:mm:ss')}
            </Typography>
            <Typography variant="h6" color="black">
              {alarmMessage?.message1}
            </Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
  return (
    <CardContent>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DrivingCard />
            </Grid>
            <Grid item xs={6}>
              <AlarmCard />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
          <AlarmMessageCard />
        </Grid>
      </Grid>
    </CardContent>
  );
};
