import {round} from 'lodash';
import React, {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

interface SourceKeyType {
  x: string; // YYYY-MM-DD HH:mm:ss
  y: string;
  series: string;
}

/**
 * 引数
 */
interface Props {
  title: string;
  source: Array<any>;
  sourceKey: SourceKeyType;
  yUnit?: string;
  yDigit?: number;
  exportFields: any;
}

/**
 * LFCChartsWeldingFreeLine
 * @param props
 * @returns
 */
const LFCChartsWeldingFreeLine = (props: Props) => {
  const {t} = useTranslation();
  type DsRow = {source?: Array<any>; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    // データソースクリア
    if (props.source.length === 0) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // シリーズ指定
    const grpNames = Array.from(
      new Set(props.source.map((obj: any) => obj[props.sourceKey.series]))
    );

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});
    // シリーズ設定
    grpNames.forEach((grp, i) => {
      l_ds.push({
        transform: {
          type: 'filter',
          config: {dimension: props.sourceKey.series, value: grp}
        }
      });
      l_series.push({
        type: 'line',
        name: grp,
        encode: {x: props.sourceKey.x, y: props.sourceKey.y},
        datasetIndex: i + 1
      });
    });
    setSeries(l_series);
    setDataset(l_ds);
  }, [props.source]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            left: 50,
            right: 5
          },
          xAxis: {
            type: 'time',
            name: t('日時'),
            nameGap: 30,
            nameLocation: 'middle',
            axisLabel: {
              rotate: '45'
            }
          },
          yAxis: {
            type: 'value',
            name: props.yUnit ? `(${props.yUnit})` : '',
            minInterval: 1, // 目盛りを1単位にする
            boundaryGap: false // 軸値の下限上限範囲
            // max: (value: any) => value.max + 1, // y軸の最大値を設定
          },
          dataset: dataset,
          series: series,
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return `${prm.marker}${prm.value[props.sourceKey.series]}<br>${
                prm.value[props.sourceKey.x]
              }<br>${round(prm.value[props.sourceKey.y], props.yDigit || 0)}${props.yUnit || ''}
              `;
            }
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          }
        }}
        exportData={props.source}
        exportFields={props.exportFields}
        exportFilename={props.title}
      />
    </div>
  );
};
export default LFCChartsWeldingFreeLine;
