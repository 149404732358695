import React from 'react';
import html2canvas from 'html2canvas';

/**
 * キャプチャを出力する
 * @param targetId
 */
export const saveAsCaptureImage = (targetId: string, fileName: string) => {
  // 画像に変換する component の id を指定
  const target = document.getElementById(targetId);

  // スクロールで隠れているところも取るように入れる
  let mcElm = document.getElementById('mainContent');
  if (mcElm) {
    mcElm.style.overflow = 'inherit';
  }

  html2canvas(target as HTMLElement, {scrollY: 0}).then(canvas => {
    // 戻す
    if (mcElm) {
      mcElm.style.overflow = 'auto';
    }

    const targetImgUri = canvas.toDataURL('img/png');

    const downloadLink = document.createElement('a');

    // html2canvas で得られる URI を用いてダウンロードさせる
    // Ref: https://stackoverflow.com/questions/31656689/how-to-save-img-to-users-local-computer-using-html2canvas
    if (typeof downloadLink.download === 'string') {
      downloadLink.href = targetImgUri;

      // ファイル名
      downloadLink.download = `LFC_${fileName}.png`;

      // Firefox では body の中にダウンロードリンクがないといけないので一時的に追加
      document.body.appendChild(downloadLink);

      // ダウンロードリンクが設定された a タグをクリック
      downloadLink.click();

      // Firefox 対策で追加したリンクを削除しておく
      document.body.removeChild(downloadLink);
    } else {
      window.open(targetImgUri);
    }
  });
};

/**
 * 引数
 */
interface Props {
  id: string;
  children: React.ReactNode;
}

/**
 * ScreenCapture
 * @returns
 */
const ScreenCapture = (props: Props) => {
  // 出力予定のコンポーネントのルート階層に id を付けておく
  return <div id={props.id}>{props.children}</div>;
};

export default ScreenCapture;
