import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCSelectFormTimeSpan from '_components-with-data/inputs/LFCSelectFormTimeSpan';
import LFCChartsBarProductivityAnalysis from '_components/charts/LFCChartsBarProductivityAnalysis';
import LFCChartsLineMultiProductivityAnalysis from '_components/charts/LFCChartsLineMultiProductivityAnalysis';
import LFCChartsLineProductivityAnalysis from '_components/charts/LFCChartsLineProductivityAnalysis';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsGanttChartProductivityAnalysis from './components/LFCChartsGanttChartProductivityAnalysis';
import {useTranslation} from 'react-i18next';

const ProductivityAnalysis = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [searchValue, setSearchValue] = useState({
    select_date: dayjs().format('YYYY-MM-DD'),
    timespan: 30
  });
  const [listData_50019, setListData_50019] = useState([]);
  const [listData_50020, setListData_50020] = useState([]);
  const [listData_50020_view, setListData_50020_view] = useState([]);
  const [listData_50020_view_column, setListData_50020_view_column] = useState([]);
  const [listData_50021, setListData_50021] = useState([]);
  const [listData_50022, setListData_50022] = useState([]);
  const [listData_50023, setListData_50023] = useState([]);
  const [days_list, setDays_list] = useState([]);
  const [machine_id_index, setMachine_id_index] = React.useState<any>([]);

  const handleChange = (event: any) => {
    if (event.target.name === 'timespan') {
      setSearchValue({...searchValue, [event.target.name]: event.target.value});
    } else {
      setSearchValue({...searchValue, [event.target.name]: event.target.value});
    }
  };

  const thisNendo = () => {
    let today: any = new Date();
    if (today.getMonth() + 1 < 4) {
      return today.getFullYear() - 1;
    } else {
      return today.getFullYear();
    }
  };
  const makeDaysList = () => {
    let start_date: Date = new Date(searchValue.select_date);
    let tmp_days_list: any = [];

    for (let i = 0; i < searchValue.timespan; i++) {
      tmp_days_list.unshift(start_date.getMonth() + 1 + '/' + start_date.getDate());
      start_date.setDate(start_date.getDate() - 1);
    }
    setDays_list(tmp_days_list);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    doSearch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = async () => {
    setStartProcess(true);
    thisNendo();
    makeDaysList();
    let tmp_date: any = new Date(searchValue.select_date);
    tmp_date.setDate(tmp_date.getDate() - searchValue.timespan);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50019,
      parameters: {
        select_date: new Date(tmp_date)
      },
      ds_state: setListData_50019,
      name: 'POC_生産性分析_生産数予実',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50020,
      parameters: {
        select_date: new Date(tmp_date)
      },
      ds_state: setListData_50020,
      name: 'POC_生産性分析_工程内不良率',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50021,
      parameters: {
        select_date: new Date(tmp_date)
      },
      ds_state: setListData_50021,
      name: 'POC_生産性分析_設備稼働率',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50022,
      parameters: {
        select_date: new Date(tmp_date)
      },
      ds_state: setListData_50022,
      name: 'POC_生産性分析_サイクルタイム',
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50023,
      parameters: {
        select_date: new Date(searchValue.select_date)
      },
      ds_state: setListData_50023,
      name: 'POC_生産性分析_MMチャート',
      cancelToken: source.token,
      t
    });
    setStartProcess(false);
  };

  useEffect(() => {
    let tmp_model: any = Array.from(new Set(listData_50020.map((item: any) => item.model_id)));
    setListData_50020_view_column(tmp_model);
    let tmp_data: any = [];
    tmp_model.forEach((model_st: string, index0: number) => {
      listData_50020.forEach((item: any) => {
        if (item.model_id === model_st) {
          let tmp: any = {};
          tmp_model.forEach((model_st_st: string, index2: number) => {
            if (index0 === index2) {
              tmp[tmp_model[index0]] = 0;
              tmp[tmp_model[index2]] = item.fail_rate;
            } else {
              tmp[tmp_model[index0]] = item.fail_rate;
              tmp[tmp_model[index2]] = 0;
            }
          });
          tmp.fail_rate = item.fail_rate;
          tmp.model_id = item.model_id;
          tmp.month_day = item.month_day;
          tmp.prd_act_count = item.prd_act_count;
          tmp.prd_fail_count = item.prd_fail_count;
          tmp.process_id = item.process_id;
          tmp.working_date = item.working_date;
          tmp_data.push(tmp);
        }
      });
    });

    let tmp_3: any = [];
    days_list.forEach((date: string) => {
      let tmp_2: any = tmp_data.filter((item: any) => item.month_day === date);
      if (tmp_2.length > 0 && tmp_2[1]) {
        tmp_3.push({
          '78R00': tmp_2[0]['78R00'] + tmp_2[1]['78R00'],
          '78R10': tmp_2[0]['78R10'] + tmp_2[1]['78R10'],
          month_day: tmp_2[0].month_day,
          working_date: tmp_2[0].working_date
        });
      } else if (tmp_2.length > 0) {
        tmp_3.push({
          '78R00': tmp_2[0]['78R00'],
          '78R10': tmp_2[0]['78R10'],
          month_day: tmp_2[0].month_day,
          working_date: tmp_2[0].working_date
        });
      }
    });
    setListData_50020_view(tmp_3);
  }, [listData_50020]);

  useEffect(() => {
    let tmp_machine_id: any = Array.from(new Set(listData_50023.map((item: any) => item.device)));
    setMachine_id_index(tmp_machine_id.sort());
  }, [listData_50023]);

  let listData_50019_column = {
    // model_id: "model_id",
    // model_id1: "model_id1",
    plan_count: t('生産計画数'),
    prd_act_count: t('生産実績数'),
    prd_fail_count: t('不良品数'),
    // process_id: "process_id",
    // process_id1: "process_id1",
    working_date: t('稼働日')
    // working_date1: "working_date1",
  };

  let listData_50020_column = {
    fail_rate: t('不良率'),
    model_id: t('モデルID'),
    // month_day: "month_day",
    prd_act_count: t('生産実績数'),
    prd_fail_count: t('不良数'),
    process_id: t('プロセスID'),
    working_date: t('稼働日')
  };

  let listData_50021_column = {
    work_rate: t('設備稼働率'),
    // year: t("年"),
    year_month: t('年月')
  };

  let listData_50022_column = {
    cycle_time: t('サイクルタイム'),
    // year: "year",
    year_month: t('年月')
  };

  let listData_50023_column = {
    device: t('デバイス'),
    machine_status: t('ステータス'),
    detection_time_from: t('開始時間'),
    detection_time_to: t('終了時間'),
    time_diff: t('継続時間')
  };

  // Gantt chart：開始日
  let startDay = searchValue.select_date;

  return (
    <GenericTemplate title="生産性分析">
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCDatePicker
            name="select_date"
            label={t('日付')}
            value={searchValue.select_date}
            onChange={handleChange}
          />
          <LFCButton color="primary" size={'small'} onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {t('MMチャート')}
            <Box style={{margin: 3, padding: 3, border: 'solid 1px silver'}}>
              <LFCChartsGanttChartProductivityAnalysis
                datas={listData_50023}
                deviceIndex={machine_id_index}
                exportData={listData_50023}
                exportFields={listData_50023_column}
                exportFilename={`${t('MMチャート')}`}
                startDay={startDay}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <form>
              <LFCFormRowGroup>
                <LFCSelectFormTimeSpan
                  name={'timespan'}
                  value={searchValue.timespan}
                  onChange={handleChange}
                  multiple={false}
                />
              </LFCFormRowGroup>
            </form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <LFCChartsBarProductivityAnalysis
                  title={`${t('生産数予実')}`}
                  source={listData_50019}
                  x={{dsColumn: 'month_day', data: days_list}}
                  y={{
                    type: 'bar',
                    name: [t('生産計画'), t('生産実績')],
                    dsColumn: ['plan_count', 'prd_act_count']
                  }}
                  color={['rgb(38, 197, 63)', 'rgb(64, 121, 239)']}
                  exportData={listData_50019}
                  exportFields={listData_50019_column}
                  exportFilename={`${t('生産性分析')}・${t('生産数予実')}`}
                  // graphic={getTableLine(3)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LFCChartsLineProductivityAnalysis
                  title={`${t('設備稼働率')}`}
                  source={listData_50021}
                  x={{dsColumn: 'month_day', data: days_list}}
                  y={{
                    type: 'line',
                    name: t('設備稼働率'),
                    dsColumn: 'work_rate',
                    series: t('設備稼働率'),
                    max: 100
                  }}
                  color={['rgb(106,113,255)']}
                  exportData={listData_50021}
                  exportFields={listData_50021_column}
                  exportFilename={`${t('生産性分析')}・${t('設備稼働率')}`}
                  // graphic={getTableLine(2)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LFCChartsLineMultiProductivityAnalysis
                  title={`${t('工程内不良率')}`}
                  source={listData_50020_view}
                  x={{dsColumn: 'month_day', data: days_list}}
                  y={{
                    type: 'line',
                    name: listData_50020_view_column,
                    series: listData_50020_view_column,
                    dsColumn: listData_50020_view_column
                  }}
                  color={['rgb(106,113,255)']}
                  exportData={listData_50020}
                  exportFields={listData_50020_column}
                  exportFilename={`${t('生産性分析')}・${t('工程内不良率')}`}
                  // graphic={getTableLine(2)}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LFCChartsLineProductivityAnalysis
                  title={`${t('サイクルタイム')}`}
                  source={listData_50022}
                  x={{dsColumn: 'month_day', data: days_list}}
                  y={{
                    type: 'line',
                    name: t('サイクルタイム'),
                    dsColumn: 'cycle_time',
                    series: t('サイクルタイム')
                  }}
                  color={['rgb(106,113,255)']}
                  exportData={listData_50022}
                  exportFields={listData_50022_column}
                  exportFilename={`${t('生産性分析')}・${t('サイクルタイム')}`}
                  // graphic={getTableLine(2)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default ProductivityAnalysis;
