import {useEffect, useRef, useState} from 'react';
import {useSnackbar} from 'notistack';
import ProgressBar from '_components/ProgressBar';
import LFCSelectFormWeldingMethod from '_components-with-data/inputs/LFCSelectFormWeldingMethod';
import {getLFCData, handleInputChange, handleInputChange2, setLFCData} from '_logics/LFCUtil';
import LFCSelectFormMaterialProperties from '_components-with-data/inputs/LFCSelectFormMaterialProperties';
import LFCSelectFormThickness from '_components-with-data/inputs/LFCSelectFormThickness';
import LFCButton from '_components/inputs/LFCButton';
import {Card, Grid, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCChartsScatterDemo from '_components/charts/LFCChartsScatterDemo';
import axios from 'axios';
import {isEmpty} from 'lodash';
import useInterval from '_reacts/useInterval';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    rightLine: {
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderTop: '100px solid transparent',
      borderBottom: '100px solid transparent',
      borderLeft: '30px solid #46a6eb',
      borderRight: 0
    },
    MuiOutlinedInput: {
      fontSize: '2rem'
    }
  };
});

export type RecommendedConditionsProps = {
  setRecommendedConditionsFlag: any;
  setExecutionProcessFlag: any;
  setWeldingDataFlag: any;
  searchValue: any;
  setSearchValue: any;
};

const RecommendedConditions = (props: RecommendedConditionsProps) => {
  const {enqueueSnackbar} = useSnackbar();
  const {classes} = useStyles();
  const formRef = useRef<HTMLFormElement>(null!);

  const [startProcess, setStartProcess] = useState(false);

  const [searchValue, setSearchValue] = useState({
    electrode_diameter: '',
    cooling_type: '',
    rated_current: '',
    filler_unit: '',
    wire: '',
    material: '',
    power_supply: '',
    welding_speed: '',
    torch_angle: '',
    target_voltage: '',
    target_current: ''
  });

  useEffect(() => {
    props.setSearchValue({
      ...props.searchValue,
      welding_method: 'Tig Welding',
      material_properties: 'Stainless Steel',
      boardthickness: '1.6'
    });
    setSearchValue({
      ...searchValue,
      electrode_diameter: '',
      cooling_type: '',
      rated_current: '',
      filler_unit: '',
      wire: '',
      material: '',
      power_supply: '',
      welding_speed: '',
      torch_angle: '',
      target_voltage: '',
      target_current: ''
    });
  }, []);

  const [data903, setData903] = useState([]);
  const {t} = useTranslation();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const doInput = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    if (
      !isEmpty(props.searchValue.welding_method) &&
      !isEmpty(props.searchValue.material_properties) &&
      !isEmpty(props.searchValue.boardthickness)
    ) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 903,
        parameters: {
          welding_method: props.searchValue.welding_method,
          material_properties: props.searchValue.material_properties,
          boardthickness: props.searchValue.boardthickness
        },
        ds_state: setData903,
        name: '903',
        cancelToken: source.token,
        t
      }).then(() => {
        setShowFlag(true);
        props.searchValue.auto_play ? setStartProcess(true) : setStartProcess(false);
      });
    } else {
      enqueueSnackbar(t('message.Input Conditionsを選択してください。'), {variant: 'warning'});
    }
  };

  useEffect(() => {
    if (data903[0] !== undefined) {
      setSearchValue({
        ...searchValue,
        electrode_diameter: data903[0]['electrode_diameter'],
        cooling_type: data903[0]['cooling_type'],
        rated_current: data903[0]['rated_current'],
        filler_unit: data903[0]['filler_unit'],
        wire: data903[0]['wire_diameter'],
        material: data903[0]['material_properties'],
        power_supply: data903[0]['power_supply'],
        welding_speed: data903[0]['weld_speed'],
        torch_angle: data903[0]['aiming_angle'],
        target_voltage: data903[0]['target_voltage'],
        target_current: data903[0]['target_current']
      });
    }
  }, [data903]);

  const doStart = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    setLFCData({
      snack: enqueueSnackbar,
      sql_id: 904,
      parameters: {
        status: 2
      },
      cancelToken: source.token,
      t
    })
      .then(() => {
        props.setRecommendedConditionsFlag(false);
        props.setExecutionProcessFlag(true);
        props.searchValue.auto_play ? setStartProcess(true) : setStartProcess(false);
      })
      .catch(e => {
        setStartProcess(false);
        enqueueSnackbar(t('message.Startを失敗しました。'), {variant: 'error'});
      });
  };

  const [showFlag, setShowFlag] = useState(false);

  const [state, setState] = useState(1);
  const [delay, setDelay] = useState(10000);

  useEffect(() => {
    setDelay(props.searchValue.auto_play_interval * 1000);
    props.searchValue.auto_play ? setStartProcess(true) : setStartProcess(false);
  }, [props.searchValue.auto_play]);

  const netxStatus = () => {
    switch (state) {
      case 1:
        setShowFlag(true);
        doInput();
        setState(state + 1);
        break;
      case 2:
        doStart();
        setState(state + 1);
        break;
    }
  };

  useInterval(netxStatus, props.searchValue.auto_play ? delay : null);

  return (
    <>
      <ProgressBar startProcess={startProcess} />

      <Grid container spacing={1} fontSize={26}>
        <Grid
          item
          xs={12}
          sx={{
            fontSize: 42,
            marginBottom: 2,
            padding: 1,
            backgroundColor: 'rgb(0, 102, 204)',
            color: 'white'
          }}
          justifyItems={'center'}
          textAlign={'center'}
        >
          Digitization of craftsmanship and judgment as parameters
          <br />
          Transmitted to the robot through software
        </Grid>

        <Grid item xs={3} component={Card} sx={{padding: 1}}>
          <Typography variant="h1" component="h1" style={{fontSize: 42, marginBottom: 20}}>
            Input Conditions
          </Typography>

          <form ref={formRef}>
            <Grid item xs={12}>
              <LFCSelectFormWeldingMethod
                name={'welding_method'}
                value={props.searchValue.welding_method}
                onChange={event =>
                  handleInputChange2(event, props.searchValue, props.setSearchValue)
                }
                required
                fullWidth
                size={'medium'}
                style={{marginBottom: 10, fontSize: '26px'}}
              />
            </Grid>
            <Grid item xs={12}>
              <LFCSelectFormMaterialProperties
                name={'material_properties'}
                value={props.searchValue.material_properties}
                onChange={event =>
                  handleInputChange2(event, props.searchValue, props.setSearchValue)
                }
                required
                fullWidth
                size={'medium'}
                style={{marginBottom: 10, fontSize: '26px'}}
              />
            </Grid>
            <Grid item xs={12} style={{display: 'flex'}}>
              <LFCSelectFormThickness
                name={'boardthickness'}
                value={props.searchValue.boardthickness}
                onChange={event =>
                  handleInputChange2(event, props.searchValue, props.setSearchValue)
                }
                required
                fullWidth
                size={'medium'}
                style={{marginBottom: 10, fontSize: '26px'}}
              />
              <span style={{marginLeft: '10px', paddingTop: '36px'}}>mm</span>
            </Grid>
            <Grid item xs={12} style={{marginTop: 10, marginBottom: 10}}>
              <LFCButton
                size="large"
                onClick={() => doInput()}
                fullWidth
                style={{fontSize: '26px'}}
              >
                INPUT
              </LFCButton>
            </Grid>
          </form>
        </Grid>

        <Grid
          item
          xs={0.5}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className={classes.rightLine} />
        </Grid>

        {showFlag ? (
          <Grid item xs={8.5} component={Card} sx={{padding: 2}}>
            <Typography variant="h1" component="h1" style={{fontSize: 42, marginBottom: 20}}>
              Recommended Welding Conditions
            </Typography>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="h2" component="h2" style={{fontSize: 26, marginBottom: 20}}>
                  Hardware Configuration
                </Typography>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="electrode_diameter"
                    label="Electrode Diameter"
                    value={searchValue.electrode_diameter}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>mm</span>
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="cooling_type"
                    label="Cooling Type"
                    value={searchValue.cooling_type}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="rated_current"
                    label="Rated Current"
                    value={searchValue.rated_current}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>A</span>
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="filler_unit"
                    label="Filler Unit"
                    value={searchValue.filler_unit}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="wire"
                    label="Wire Φ"
                    value={searchValue.wire}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    disabled={searchValue.filler_unit === 'Not Use'}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>mm</span>
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="material"
                    label="Material"
                    value={searchValue.material}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    disabled={searchValue.filler_unit === 'Not Use'}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                </Grid>
                <Grid item xs={11.5} style={{display: 'flex'}}>
                  <LFCTextField
                    name="power_supply"
                    label="Power Supply"
                    value={searchValue.power_supply}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>A</span>
                </Grid>
              </Grid>
              <Grid item xs={6} component={Card} sx={{padding: 1, position: 'relative'}}>
                <Typography variant="h2" component="h2" style={{fontSize: 26, marginBottom: 20}}>
                  Welding Configuration
                </Typography>

                <div style={{width: '100%', marginBottom: '30px'}}>
                  <div
                    style={{
                      backgroundImage:
                        'url(' + process.env.PUBLIC_URL + 'images/demo/status_0_R.jpg)',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      height: '250px',
                      width: '250px',
                      margin: '0 auto'
                      // backgroundColor: "white"
                    }}
                  ></div>
                </div>

                <div style={{position: 'absolute', top: '40px', left: '10px', display: 'flex'}}>
                  <LFCTextField
                    name="welding_speed"
                    label="Welding Speed"
                    value={searchValue.welding_speed}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    style={{width: '100px'}}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>mm/sec</span>
                </div>

                <div style={{position: 'absolute', top: '150px', right: '10px', display: 'flex'}}>
                  <LFCTextField
                    name="torch_angle"
                    label="Torch Angle"
                    value={searchValue.torch_angle}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                    margin="normal"
                    readonly={true}
                    style={{width: '100px'}}
                    sx={{
                      '.MuiInputBase-input': {fontSize: '1.5rem'}
                    }}
                  />
                  <span style={{marginLeft: '10px', paddingTop: '34px'}}>deg.</span>
                </div>

                <Typography variant="h2" component="h2" style={{fontSize: 26, marginBottom: 20}}>
                  Current / Voltage TARGET
                </Typography>

                <LFCChartsScatterDemo
                  title=""
                  xaxisName={'Current [A]'}
                  yaxisName={'Voltage [V]'}
                  datas={[[searchValue.target_current, searchValue.target_voltage]]}
                  height={'300px'}
                  exportData={[[searchValue.target_current, searchValue.target_voltage]]}
                  exportFields={{
                    ampare: 'ampere',
                    voltage: 'voltage',
                    ok_current: 'ok ampere',
                    ok_voltage: 'ok voltage'
                  }}
                  exportFilename={'demo'}
                  left={'95%'}
                />

                <Grid item>
                  <LFCButton
                    size="large"
                    onClick={() => doStart()}
                    fullWidth
                    style={{fontSize: '26px'}}
                  >
                    START
                  </LFCButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default RecommendedConditions;
