import {Box} from '@mui/material';
import React from 'react';

interface LFCMenuTabProps {
  index: any;
  value: any;
  children: React.ReactNode;
  other?: any;
}

/**
 * MENU Tabコンポーネント
 * @param props
 * @returns
 */
const LFCMenuTab = (props: LFCMenuTabProps) => {
  return (
    <Box
      role="tabpanel"
      id={`simple-tabpanel-${props.index}`}
      aria-labelledby={`simple-tab-${props.index}`}
      display={props.value !== props.index ? 'none' : 'flex'}
      alignItems="center"
      width={'100%'}
      // height={"90%"}
      mt={2}
      {...props.other}
    >
      {props.value === props.index && <>{props.children}</>}
    </Box>
  );
};

export default LFCMenuTab;
