import {Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBoxPerson1 from '_components/charts/LFCChartsBoxPerson1';
import LFCChartsComboXList from '_components/charts/LFCChartsComboXList';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_PERSONS_MAIN, PAGEID_PERSONS_WORK_TIME_TREND_ANALYSIS} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {useTranslation} from 'react-i18next';

const WorkTimeTrendAnalysisPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const initialSearchValue = {
    select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
    select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    dongleid: [t('すべて')],
    searchType: 2
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_PERSONS_WORK_TIME_TREND_ANALYSIS,
    LOCATIONID_PERSONS_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const [data4000, setData4000] = useState([]);
  const [data4006, setData4006] = useState([]);
  const [data4006_box, setData4006_box] = useState([]);
  const [data4004, setData4004] = useState([]);
  const [data60351, setData60351] = useState({
    total_count: '0',
    ok_count: '0',
    ng_count: '0',
    ng_percent: '0'
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setData4000([]);
    setData4006([]);
    setData4006_box([]);
  };

  const date_calc: any = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  };

  const doSearch = async () => {
    await setStartProcess(true);

    setData4000([]);
    setData4006([]);
    setData4006_box([]);
    setData4004([]);
    setData60351({total_count: '0', ok_count: '0', ng_count: '0', ng_percent: '0'});
    setMachineList([]);

    const conditionsDate = {
      select_datetime_from: searchValue.select_date_from,
      select_datetime_to: searchValue.select_date_to
    };
    const conditionsOther = {
      work: null,
      deviceid: null,
      dongleid:
        searchValue.dongleid[0] !== t('すべて') ? `{${searchValue.dongleid.join(',')}}` : null
    };

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60351,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      name: `${t('検査数と不良数')}`,
      cancelToken: source.token,
      t
    }).then(datas => {
      if (datas.length > 0) {
        setData60351({
          total_count: datas[0].total_count,
          ok_count: String(datas[0].total_count - datas[0].ng_count),
          ng_count: datas[0].ng_count,
          ng_percent: datas[0].ng_percent
        });
      }
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 4004,
      parameters: {
        ...conditionsDate,
        ...conditionsOther
      },
      ds_state: setData4004,
      name: 'sql-id:4004',
      cancelToken: source.token,
      t
    });

    switch (searchValue.searchType) {
      case 0:
        // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4003,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4000,
          name: 'sql-id:4003',
          cancelToken: source.token,
          t
        });
        // ボックスチャート
        // 検査ごと
        // 4009,人ログ：作業時間傾向分析：ボックスチャート：検査ごと,"
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4009,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4006,
          name: 'sql-id:4009',
          cancelToken: source.token,
          t
        });
        break;
      case 1:
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4002,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4000,
          name: 'sql-id:4002',
          cancelToken: source.token,
          t
        });
        // ボックスチャート
        // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4008,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4006,
          name: 'sql-id:4008',
          cancelToken: source.token,
          t
        });
        break;
      case 2:
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4000,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4000,
          name: 'sql-id:4000',
          cancelToken: source.token,
          t
        });
        // ボックスチャート
        // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4006,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4006,
          name: 'sql-id:4006',
          cancelToken: source.token,
          t
        });
        break;
      case 3:
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4001,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4000,
          name: 'sql-id:4001',
          cancelToken: source.token,
          t
        });
        // ボックスチャート
        // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 4007,
          parameters: {
            ...conditionsDate,
            ...conditionsOther
          },
          ds_state: setData4006,
          name: 'sql-id:4007',
          cancelToken: source.token,
          t
        });
        break;
    }
    await setStartProcess(false);
  };

  const [machineList, setMachineList] = useState([]);

  useEffect(() => {
    let tmp_data: any = [];
    let tmp_machine: any = [];
    let type_by_x_name: string;

    if (data4006.length > 0) {
      tmp_machine = Array.from(new Set(data4006.map((a: any) => a.machine))).sort();
      setMachineList(tmp_machine);
      let x_list: any = date_calc();

      switch (searchValue.searchType) {
        case 0:
          type_by_x_name = 'idx';
          break;
        case 1:
          type_by_x_name = 'year_month_day_hour';
          break;
        case 2:
          type_by_x_name = 'year_month_day';
          break;
        case 3:
          type_by_x_name = 'year_month';
          break;
      }

      if (searchValue.searchType !== 0) {
        tmp_machine.forEach((a: string) => {
          x_list.forEach((b: string) => {
            if (
              data4006.filter((c: any) => c.machine === a && c[type_by_x_name] === b).length > 0
            ) {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: data4006.filter(
                  (c: any) => c.machine === a && c[type_by_x_name] === b
                )[0]['data_value']
              });
            } else {
              tmp_data.push({
                machine: a,
                x_list: b,
                data_value: []
              });
            }
          });
        });
      } else {
        tmp_machine.forEach((a: string) => {
          data4006
            .filter((b: any) => b.machine === a)
            .forEach((c: any) => {
              tmp_data.push({
                machine: a,
                x_list: c.idx,
                data_value: [c.time_diff],
                start_time: c.start_time
              });
            });
        });
      }
      setData4006_box(tmp_data);
    }
  }, [data4006]);

  let data4006_column: any = {
    カメラ名: t('カメラ名'),
    枠名: t('枠名'),
    日時: t('日時'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };
  let data4006_column_type0: any = {
    カメラ名: t('カメラ名'),
    枠名: t('枠名'),
    作業開始時間: t('作業開始時間'),
    最大: t('最大'),
    Q3: t('Q3'),
    中央値: t('中央値'),
    平均: t('平均'),
    Q1: t('Q1'),
    最小: t('最小')
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel: searchValue.select_date_from,
        colName: t('対象期間From'),
        colWidth: 300
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel: searchValue.select_date_to,
        colName: t('対象期間To'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.人ログ分析')}:${t('作業時間傾向分析')}`}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_PERSONS_WORK_TIME_TREND_ANALYSIS}
            locationNo={LOCATIONID_PERSONS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
            <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
              {searchType.map((item: any) => (
                <FormControlLabel
                  value={item.id}
                  key={'search_type-' + item.id}
                  control={
                    <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                  }
                  label={
                    <Typography display="block" key={'search_type-body-' + item.id}>
                      {item.text}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Grid>

          <Grid item xs={12} lg={2.5}>
            <LFCSingleValues
              columns={[
                {
                  field: 'ok_count',
                  headerName: t('生産数'),
                  width: '100px',
                  unit: `${t('個')}`,
                  formatter: v => round(v, 0).toLocaleString()
                },
                {
                  field: 'ng_percent',
                  headerName: `${t('不良率')}`,
                  width: '100px',
                  unit: ' %',
                  formatter: v => round(v * 100, 1)
                }
              ]}
              source={data60351}
            />
          </Grid>

          <Grid item xs={12} lg={9.5}>
            <LFCChartsComboXList
              title={`${t('実績数と不良率')}`}
              source={data4000}
              xList={searchValue.searchType !== 0 ? date_calc() : data4000.map((a: any) => a.idx)}
              yLeft={{
                type: 'bar',
                name: `${t('実績数')}`,
                dsColumn: 'total_count'
              }}
              yRight={{
                type: 'line',
                name: `${t('不良率')}(%)`,
                dsColumn: 'ng_par',
                markPoint: {
                  data: [
                    {type: 'min', itemStyle: {color: '#E91E63'}},
                    {type: 'max', itemStyle: {color: '#2196F3'}}
                  ]
                },
                markLine: {
                  symbol: 'arrow',
                  data: [
                    {
                      type: 'average',
                      label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                    }
                  ]
                }
              }}
              color={[green[500], red[500]]}
              height="250px"
              exportData={data4000}
              exportFields={{
                lq_time: t('日付'),
                total_count: t('実績数'),
                ng: t('不良数'),
                ng_par: t('不良率')
              }}
              exportFilename={`${t('実績数と不良率')}`}
            />
          </Grid>

          {machineList.map((a: string, index: number) => {
            return (
              <Grid container key={'base_' + index}>
                <Grid item xs={12} lg={2.5} key={'index_' + index}>
                  【{t('カメラ')}：{a.split('..')[0]}】 【{t('枠')}：{a.split('..')[1]}】
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'data_max',
                        headerName: `${t('最大')}`,
                        width: '80px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_avg',
                        headerName: `${t('平均')}`,
                        width: '80px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_min',
                        headerName: `${t('最小')}`,
                        width: '80px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_stddev',
                        headerName: `${t('標準偏差')}`,
                        width: '80px',
                        unit: '',
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      },
                      {
                        field: 'data_total',
                        headerName: t('総作業時間'),
                        width: '80px',
                        unit: `${t('秒')}`,
                        formatter: v => round(v, 1).toLocaleString(),
                        valVariant: 'subtitle2'
                      }
                    ]}
                    source={data4004.filter((b: any) => b.key === a)[0]}
                  />
                </Grid>
                <Grid item xs={12} lg={9.5} key={'graph_' + index}>
                  <LFCChartsBoxPerson1
                    // 生産間隔_時間推移
                    title=""
                    source={data4006_box.filter((b: any) => b.machine === a)}
                    exportData={[]}
                    exportFields={
                      searchValue.searchType !== 0 ? data4006_column : data4006_column_type0
                    }
                    cameraName={a.split('..')[0]}
                    frameNo={a.split('..')[1]}
                    exportFilename={`${t('人ログ作業時間')}`}
                    height={'200px'}
                    grid={{top: 50, bottom: 50, left: 70, right: 60}}
                    bottom={'80'}
                    yAxisName={`${t('作業時間チャート')}(${t('秒')})`}
                    xlist={
                      searchValue.searchType !== 0
                        ? date_calc()
                        : data4006_box[0] !== undefined
                          ? data4006.filter((b: any) => b.machine === a).map((c: any) => c.idx)
                          : []
                    }
                    searchType={searchValue.searchType}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default WorkTimeTrendAnalysisPage;
