import {Box, Dialog, DialogContent, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, round} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCChartsLineLRobotCompare from '_components/charts/LFCChartsLineLrobotCompare';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {fmtYYYYMMDD} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_DAILY_REPORT} from '_logics/LFCPageId';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsWeldingDayBarLine from '../_charts/LFCChartsWeldingDayBarLine';
import LFCChartsWeldingFreeLine from '../_charts/LFCChartsWeldingFreeLine';
import LFCChartsWorkingInterval from '../_charts/LFCChartsWorkingInterval';
import {useTranslation} from 'react-i18next';

const DailyReportPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_DAILY_REPORT,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>({
    select_date: fmtYYYYMMDD(dayjs()),
    work: [],
    ...defaultCondition
  });

  const [data001, setData001] = useState([]);
  const [data002, setData002] = useState([]);
  const [data003, setData003] = useState([]);
  const [data004, setData004] = useState([]);

  const [data012, setData012] = useState([]);
  const [data013, setData013] = useState([]);
  const [data014, setData014] = useState([]);

  const [data001Total, setData001Total] = useState(0);
  const [data003Total, setData003Total] = useState(0);
  const [data012Total, setData012Total] = useState(0);

  const export_detail_ResultWelding: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100
    },
    {
      order: 1,
      field: '日時',
      headerName: t('日時'),
      description: t('日時'),
      sortable: true,
      width: 200
    },
    {
      order: 2,
      field: 'uuid',
      headerName: 'UUID',
      description: 'UUID',
      sortable: true,
      width: 200
    },
    {
      order: 3,
      field: 'serial',
      headerName: t('シリアル'),
      description: t('シリアル'),
      sortable: true,
      width: 200
    },
    {
      order: 4,
      field: '機種',
      headerName: t('機種'),
      description: t('機種'),
      sortable: true,
      width: 200
    },
    {
      order: 5,
      field: '生産個数',
      headerName: t('生産個数'),
      description: t('生産個数'),
      sortable: true,
      width: 100
    },
    {
      order: 6,
      field: '不良個数',
      headerName: t('不良個数'),
      description: t('不良個数'),
      sortable: true,
      width: 100
    },
    {
      order: 7,
      field: '加工時間',
      headerName: t('加工時間'),
      description: t('加工時間'),
      sortable: true,
      width: 100
    },
    {
      order: 8,
      field: '総溶接長さ',
      headerName: t('総溶接長さ'),
      description: t('総溶接長さ'),
      sortable: true,
      width: 150
    },
    {
      order: 9,
      field: '電極交換回数',
      headerName: t('電極交換回数'),
      description: t('電極交換回数'),
      sortable: true,
      width: 150
    }
  ];

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  const apiFetchSub = () => {
    setStartProcess(true);

    Promise.allSettled([
      // 機種情報取得
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 41001,
        parameters: {},
        cancelToken: source.token,
        t
      }).then(ds => {
        const tmp: {label: string}[] = ds
          .filter((e: any) => {
            return e.work !== '' && e.work !== null;
          })
          .map((item: any) => {
            return {label: item['work']};
          });
        setWork(tmp);
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const apiFetch = () => {
    const lwork = !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null;

    setStartProcess(true);

    Promise.allSettled([
      // 3205,板金溶接：生産数推移：work指定,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3205,
        parameters: {
          select_date: searchValue.select_date,
          work: lwork
        },
        ds_state: setData001,
        name: `${t('生産数推移')}`,
        cancelToken: source.token,
        t
      }),
      // 3110,板金溶接：作業時間推移,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3110,
        parameters: {
          select_date: searchValue.select_date,
          work: lwork
        },
        ds_state: setData002,
        name: `${t('作業時間推移')}`,
        cancelToken: source.token,
        t
      }),
      // 3209,板金溶接：溶接長さ：日付指定,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3209,
        parameters: {
          select_date: searchValue.select_date,
          work: lwork
        },
        ds_state: setData003,
        name: `${t('溶接長さ')}`,
        cancelToken: source.token,
        t
      }),
      // 3111,板金溶接：日報：作業時間分布
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3111,
        parameters: {
          select_date: searchValue.select_date,
          work: lwork
        },
        ds_state: setData004,
        name: `${t('作業時間分布')}`,
        cancelToken: source.token,
        t
      }),
      // 3207,板金溶接：日報：電極交換履歴,"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3207,
        parameters: {
          select_date: searchValue.select_date
        },
        ds_state: setData012,
        name: `${t('電極交換履歴')}`,
        cancelToken: source.token,
        t
      }),
      // 3013,板金溶接：日報：溶接経路の始点・終点位置"
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 3013,
        parameters: {
          select_date: searchValue.select_date,
          work: lwork
        },
        ds_state: setData013,
        name: `${t('溶接経路')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetchSub();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setData001Total(data001.reduce((a: number, b: any) => a + Number(b.exec_count), 0));
  }, [data001]);

  useEffect(() => {
    setData003Total(data003.reduce((a: number, b: any) => a + Number(b.welding_length), 0));
  }, [data003]);

  useEffect(() => {
    setData012Total(data012.reduce((a: number, b: any) => a + Number(b.exec_count), 0));
  }, [data012]);

  /**
   * 検索イベント
   * @returns
   */
  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  const [detailListOpen, setDetailListOpen] = useState(false);

  const showList = () => {
    setStartProcess(true);
    // 3014,板金溶接：日報：一覧表"
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 3014,
      parameters: {
        select_date: searchValue.select_date,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData014,
      name: '一覧表',
      cancelToken: source.token,
      t
    })
      .then(() => setDetailListOpen(true))
      .then(() => setStartProcess(false));
  };

  const listDigClose = () => {
    setDetailListOpen(false);
    setData014([]);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   * 検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date',
        value: searchValue.select_date,
        valueLabel: searchValue.select_date,
        colName: t('日付'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.板金溶接')}:${t('日報')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatePicker
            name={'select_date'}
            label={t('日付')}
            value={searchValue.select_date}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_DAILY_REPORT}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('生産数合計')} val={data001Total} />
          </Grid>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('溶接長さ合計')} val={round(data003Total, 1)} unit="mm" />
          </Grid>
          <Grid item xs={3} lg={2}>
            <LFCSingleValue title={t('電極交換回数合計')} val={data012Total} unit="回" />
          </Grid>
          <Grid item xs={3} lg={6} textAlign="right">
            <LFCButton color="secondary" onClick={() => showList()}>
              {t('明細')}
            </LFCButton>
          </Grid>

          {/*-1---*/}
          <Grid item xs={12} lg={6}>
            <LFCChartsWeldingDayBarLine
              chartType="bar"
              title={t('生産数推移')}
              source={data001}
              sourceKey={{x: 'exec_date', y: 'exec_count', series: 'work'}}
              exportFields={{
                exec_date: t('時刻'),
                work: t('機種'),
                exec_count: t('個数')
              }}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <LFCChartsWeldingFreeLine
              title={t('作業時間推移')}
              source={data002}
              sourceKey={{x: 'exec_time', y: 'cycle_time', series: 'work'}}
              yUnit={`${t('秒')}`}
              yDigit={1}
              exportFields={{
                exec_time: t('時刻'),
                work: t('機種'),
                cycle_time: t('作業時間')
              }}
            />
          </Grid>

          {/*-2---*/}
          <Grid item xs={12} lg={6}>
            <LFCChartsWeldingDayBarLine
              chartType="line"
              title={t('溶接長さ')}
              source={data003}
              sourceKey={{x: 'exec_date', y: 'welding_length', series: ''}}
              yUnit="mm"
              yDigit={1}
              exportFields={{
                exec_date: t('時刻'),
                welding_length: t('長さ累計')
              }}
              addition
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <LFCChartsWorkingInterval
              title={t('作業時間分布')}
              source={data004}
              exportFields={{
                exec_time: t('時刻'),
                cycle_time: t('作業時間')
              }}
              exportFilename={`${t('作業時間分布')}`}
            />
          </Grid>

          {/*-3----*/}
          <Grid item xs={12} lg={6}>
            <LFCChartsWeldingDayBarLine
              chartType="bar"
              title={t('電極交換履歴')}
              source={data012}
              sourceKey={{x: 'exec_hour', y: 'exec_count', series: ''}}
              yUnit={`${t('回')}`}
              yDigit={0}
              exportFields={{
                exec_hour: t('時刻'),
                exec_count: t('回数')
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <LFCChartsLineLRobotCompare
              title={t('溶接経路の始点')}
              source={data013}
              series={''}
              color={['rgb(106,113,255)']}
              exportData={data013}
              exportFields={{
                exec_time: t('時刻'),
                welding_start_path_x: t('X軸_始点'),
                welding_end_path_x: t('X軸_終点'),
                welding_start_path_y: t('Y軸_始点'),
                welding_end_path_y: t('Y軸_終点'),
                welding_start_path_z: t('Z軸_始点'),
                welding_end_path_z: t('Z軸_終点')
              }}
              exportFilename={`${t('溶接経路の始点')}`}
              typeName={`${t('始点')}`}
              height={'270px'}
              format={'HH:mm:ss'}
            />
          </Grid>

          <Grid item xs={12}>
            <LFCChartsLineLRobotCompare
              title={t('溶接経路の終点')}
              source={data013}
              series={''}
              color={['rgb(106,113,255)']}
              exportData={data013}
              exportFields={{
                exec_time: t('時刻'),
                welding_start_path_x: t('X軸_始点'),
                welding_end_path_x: t('X軸_終点'),
                welding_start_path_y: t('Y軸_始点'),
                welding_end_path_y: t('Y軸_終点'),
                welding_start_path_z: t('Z軸_始点'),
                welding_end_path_z: t('Z軸_終点')
              }}
              exportFilename={`${t('溶接経路の終点')}`}
              typeName={`${t('終点')}`}
              height={'270px'}
              format={'HH:mm:ss'}
            />
          </Grid>
        </Grid>
      </Box>
      <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={listDigClose}>{`${t('日報')}・${t('明細')}`}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={export_detail_ResultWelding}
            rows={data014}
            height="80vh"
            exportFilename={`${t('日報')}_${t('明細')}`}
          />
        </DialogContent>
      </Dialog>
    </GenericTemplate>
  );
};

export default DailyReportPage;
