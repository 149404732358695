import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  yAxis: {
    type: string;
    name: any;
    dsColumn: any;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  mapName?: string;
  onClick?: (prm: {data: any}) => void;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineHoleCompare2 = (props: Props) => {
  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(props.exportData)) {
      props.exportData.forEach((a: any) => {
        tmp.push({
          work: a.work,
          deviceid: a.deviceid,
          map: a.map,
          for_export: a.lq_time,
          work_radius: a.work_radius,
          serial: a.serial
        });
      });
      setExportData(tmp);
    }
  }, [props]);

  if (props.source !== undefined) {
    return (
      <div style={{width: '100%'}}>
        <LFCBaseCharts
          option={{
            title: [
              {
                text: props.title
              }
            ],
            grid: props.grid
              ? props.grid
              : {
                  left: '8%',
                  right: '5%',
                  bottom: '22%',
                  top: '20%'
                },
            legend: {
              type: 'scroll',
              bottom: 0,
              itemWidth: 10,
              textStyle: {
                fontSize: 10
              }
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow',
                label: {
                  formatter: (prm: any) => {
                    return prm.seriesData[0]?.data?.lq_time;
                  }
                }
              }
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            color: props.color ? props.color : null,
            dataset: {
              source: props.source
            },
            xAxis: [
              {
                type: 'category',
                axisLabel: {
                  // interval: 0,
                  rotate: '15',
                  fontSize: 10
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: props.yAxis.name,
                min: (value: any) => {
                  return Math.floor(value.min * 100) / 100;
                },
                max: (value: any) => {
                  return Math.ceil(value.max * 100) / 100;
                },
                interval: 1,
                axisLabel: {
                  fontSize: 10
                }
              }
            ],
            series: [
              {
                type: props.yAxis.type,
                name: props.yAxis.name,
                xAxisIndex: 0,
                yAxisIndex: 0,
                encode: {
                  x: props.x.dsColumn,
                  y: props.yAxis.dsColumn
                }
              }
            ]
          }}
          exportData={exportData}
          exportFields={props.exportFields}
          exportFilename={props.exportFilename}
          onEvents={{
            click: (params: any) => {
              if (props.onClick != null) {
                params.mapName = props.mapName;
                props.onClick(params);
              }
            }
          }}
          zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
          zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
          zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
          zoomStatus={props.zoomChartId === props.zoomedChartId}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
export default LFCChartsLineHoleCompare2;
