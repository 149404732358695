import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  data: any;
  onEvents?: any;
  name: string;
  exportData: any;
  exportFields: any;
  exportFilename: string;
  onClick?: (prm: {data: any}) => void;
}

const LFCPieChartJobAnalysisSurfaceCompare = (props: Props) => {
  const {t} = useTranslation();
  let higher_score_avg: number =
    props.data
      .map((a: any) => Number(a.higher_score))
      .reduce((sum: number, item: number) => sum + item, 0) / props.data.length;
  let lower_score_avg: number =
    props.data
      .map((a: any) => Number(a.lower_score))
      .reduce((sum: number, item: number) => sum + item, 0) / props.data.length;
  let data0: number = (higher_score_avg / (higher_score_avg + lower_score_avg)) * 100;
  let data1: number = (lower_score_avg / (higher_score_avg + lower_score_avg)) * 100;

  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    if (!isEmpty(props.exportData)) {
      let tmp: any = [];
      tmp.push({
        work: Array.from(new Set(props.exportData.map((a: any) => a.work).flat())).toString(),
        deviceid: Array.from(
          new Set(props.exportData.map((a: any) => a.deviceid).flat())
        ).toString(),
        map: Array.from(new Set(props.exportData.map((a: any) => a.map).flat())).toString(),
        branch_no: Array.from(
          new Set(props.exportData.map((a: any) => a.branch_no).flat())
        ).toString(),
        d0: data0,
        d1: data1,
        c0: higher_score_avg,
        c1: lower_score_avg * -1
      });
      setExportData(tmp);
    }
  }, [props.exportData]);

  const option = {
    title: {
      text: props.name
    },
    legend: {
      show: false
    },
    tooltip: {
      trigger: 'item',
      formatter: (params: any) => {
        if (params.dataIndex === 0) {
          let tmp: string;
          tmp = t('凸方向期間平均') + ':' + Math.round(higher_score_avg * 100) / 100 + '%';
          return tmp;
        } else {
          let tmp: string;
          tmp = t('凹方向期間平均') + ':' + (Math.round(lower_score_avg * 100) / 100) * -1 + '%';
          return tmp;
        }
      }
    },
    series: [
      {
        name: props.data.name,
        type: 'pie',
        radius: ['20%', '70%'],
        avoidLabelOverlap: false,
        color: ['#E91E63', '#2196F3'],
        label: {
          formatter: (prm: any) => {
            return (
              prm.name +
              '\n' +
              // + 'point_count:' + prm.value + '\n'
              (prm.percent !== undefined ? Math.round(prm.percent * 10) / 10 + '%' : +'')
            );
          },
          position: 'inside'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '20',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          {value: data0, name: t('凸方向比率')},
          {value: data1, name: t('凹方向比率')}
        ]
      }
    ]
  };

  return (
    <LFCBaseCharts
      option={option}
      exportData={exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick(params);
          }
        }
      }}
      // height={'200px'}
    />
  );
};

export default LFCPieChartJobAnalysisSurfaceCompare;
