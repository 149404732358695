import dayjs from 'dayjs';
import {ArcStatus} from 'pages/weldingShow/types';

export const date_calc = (searchValue: any) => {
  let datelist: any = [];
  let from_day = new Date(searchValue.date01);
  let to_day = new Date(searchValue.date02);

  switch (searchValue.searchType) {
    case 0:
      return [];
    case 1:
      for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
        datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
      }
      return datelist.sort();
    case 2:
      for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
        datelist.push(dayjs(d).format('YYYY-MM-DD'));
      }
      return datelist.sort();
    case 3:
      let start_month_1day: any = new Date(from_day.setDate(1));
      let end_month_1day: any = new Date(to_day.setDate(1));
      for (
        start_month_1day;
        start_month_1day <= end_month_1day;
        start_month_1day.setMonth(start_month_1day.getMonth() + 1)
      ) {
        datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
      }
      return datelist.sort();
  }
};

export const extractAndFormatWeldingWorks = (datas: ArcStatus[], showLatestOnly?: boolean) => {
  var works: any[] = [];
  var flag: boolean = false;
  var array: any[] = [];
  datas.forEach((data: ArcStatus) => {
    if (data.arc_status === 'start') {
      if (flag) {
        works.push(array);
        array = [];
        array.push(data);
      } else {
        flag = true;
        array.push(data);
      }
    } else if (data.arc_status === 'idle') {
      array.push(data);
      if (array[0].arc_status === 'start' || array[0].arc_status === 'stable') {
        works.push(array);
      }
      flag = false;
      array = [];
    } else if (data.arc_status === 'stable') {
      if (flag) {
        if (dayjs(data.log_timestamp).diff(dayjs(array[0].log_timestamp)) >= 2000) {
          works.push(array);
          flag = false;
        } else {
          works.push(array);
        }
      }
      flag = true;
      array.push(data);
    } else {
      if (flag) {
        array.push(data);
      }
    }
  });
  if (showLatestOnly) return works.slice(-1);
  return works;
};
export const extractAndFormatWeldingWorksNew = (datas: ArcStatus[], showLatestOnly: boolean) => {
  var works: any[] = [];
  var array: any[] = [];

  for (let i = 0; i < datas.length; i++) {
    const data = datas[i];

    if (data.arc_status === 'start') {
      array.push(data);
    } else if (data.arc_status === 'idle' && array.length > 0) {
      array.push(data);
      works.push(array);
      array = [];
    } else if (data.arc_status === 'stable' && array.length > 0) {
      array.push(data);
      works.push(array);
      array = [];
    } else if (
      array.length > 0 &&
      ((data.arc_status === 'stable' &&
        datas[i + 1]?.arc_status !== 'idle' &&
        datas[i + 1]?.arc_status !== 'start' &&
        new Date(datas[i + 1]?.log_timestamp).getTime() - new Date(data.log_timestamp).getTime() >=
          2000) ||
        (data.arc_status !== 'idle' && data.arc_status !== 'stable'))
    ) {
      array.push(data);
    }
  }

  if (showLatestOnly) return works.slice(-1);

  return works;
};
