import {Button} from '@mui/material';
import React, {useState} from 'react';
import LFCConfirmDialog from '_components/feedback/LFCConfirmDialog';
/**
 * 引数
 */
interface Props {
  children: React.ReactNode;
  onClick: (isOk: boolean) => void;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  fullWidth?: boolean;
  style?: {};
  // 確認ダイアログ付にしたい場合、以下を設定
  confirmMessage?: string;
  type?: 'button' | 'submit' | 'reset';
}

/**
 * LFCButton
 * LFCの標準ボタンです。確認ダイアログ付のボタンにも出来ます。
 * @param props
 * @returns
 */
const LFCButton = (props: Props) => {
  const [open, setOpen] = useState(false);

  /**
   * ボタンクリック
   * @returns
   */
  const onButtonClick = () => {
    if (props.confirmMessage != null) {
      //確認ダイアログONの場合
      setOpen(true);
      return;
    }
    props.onClick(true);
  };

  /**
   * 確認ダイアログ閉じる
   */
  const onDialogClose = (isOk: boolean) => {
    setOpen(false);
    props.onClick(isOk);
  };

  return (
    <>
      <Button
        variant={props.color ? 'contained' : 'outlined'}
        onClick={onButtonClick}
        color={props.color ? props.color : undefined}
        className={props.className}
        size={props.size}
        disabled={props.disabled}
        fullWidth={props.fullWidth ? props.fullWidth : false}
        sx={props.style ? props.style : undefined}
        type={props.type ?? 'button'}
      >
        {props.children}
      </Button>
      {/* 確認ダイアログ */}
      {props.confirmMessage != null ? (
        <LFCConfirmDialog
          open={open}
          message={props.confirmMessage}
          onClose={onDialogClose}
        ></LFCConfirmDialog>
      ) : null}
    </>
  );
};

export default LFCButton;
