import {SelectChangeEvent} from '@mui/material';
import {debounce} from 'lodash';

import {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_DEVELOPMENT_RESULTS, PAGEID_DEVELOPMENT_RESULTS} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SearchValue} from '../types';
import {isEmpty} from 'lodash';

export const useResults = () => {
  const {t} = useTranslation();
  // 一覧表示の開閉
  const [open, setOpen] = useState(false);
  // データスクリーンショットの開閉
  const [openDataScreenShot, setOpenDataScreenShot] = useState<string>('');
  const formRef = useRef<HTMLFormElement>(null);
  const [index, setIndex] = useState<number>(0);
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [data, setData] = useState<any>([[]]);
  const [startProcess, setStartProcess] = useState<boolean>(false);
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState<boolean>(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_DEVELOPMENT_RESULTS,
    LOCATIONID_DEVELOPMENT_RESULTS
  );
  const initialSearchValue = {
    work: [],
    deviceid: [],
    auto_reload: false,
    auto_reload_interval: 10,
    serialno: null
  };
  const [searchValue, setSearchValue] = useState<SearchValue>(
    defaultCondition ?? initialSearchValue
  );
  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState<boolean>(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work,
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid.join(', '),
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'serialno',
        value: serialNumber,
        valueLabel: serialNumber,
        colName: t('シリアルNo'),
        colWidth: 200
      },
      {
        name: 'auto_reload',
        value: searchValue.auto_reload,
        valueLabel: searchValue.auto_reload ? 'する' : 'しない',
        colName: t('自動更新'),
        colWidth: 200
      },
      {
        name: 'auto_reload_interval',
        value: searchValue.auto_reload_interval,
        valueLabel: searchValue.auto_reload ? `${searchValue.auto_reload_interval}秒` : '',
        colName: t('自動更新の間隔'),
        colWidth: 200
      }
    ]);

    setOpenSavedCondition(true);
  };
  const resetSearch = () => {
    setSearchValue({
      work: '',
      deviceid: [],
      auto_reload: false,
      auto_reload_interval: 10,
      show_latest_only: false,
      serialno: ''
    });
    setAutoCompleteReset(true);
  };
  const doSearch = () => {
    setStartProcess(true);
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
    setStartProcess(false);
  };

  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (openModal) {
      setTimeout(() => {
        setOpenModal(false);
      }, 3000);
    }
  }, [openModal]);

  const apiFetch = () => {
    setIndex(0);
    const sParams = {
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      serial: searchValue.serialno === '' ? null : searchValue.serialno
    };
    setAutoCompleteReset(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 307,
      parameters: sParams,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    })
      .then((datas: {[key: string]: string | number}[]) => {
        if (datas.length > 0) {
          const results = extractAndFormatDevelopments(datas.filter(e => e.map !== ''));
          console.log({results});
          if (results.length > 0) {
            setData(results);
          } else {
            setData([]);
            setOpenModal(true);
          }
        } else {
          setData([]);
          if (searchValue.serialno !== '') {
            setOpenModal(true);
          }
        }
      })
      .finally(() => {
        setSearchValue({
          ...searchValue,
          serialno: ''
        });
      });
  };

  useEffect(() => {
    if (data?.[index]?.[0]?.serial) {
      setSerialNumber(data[index][0].serial);
    } else {
      setSerialNumber('');
    }
  }, [data, index]);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const {enqueueSnackbar} = useSnackbar();
  const [imagePath, setImagePath] = useState<{[key: string]: string}[]>([]);
  const [imageFullPath, setImageFullPath] = useState<string>('');
  const [isDisplayNgOnly, setIsDisplayNgOnly] = useState<boolean>(false);
  const handleClickDataScreenShot = async (uuid: string, map: string) => {
    let filePath: {img_filename: string}[] = [];
    if (map === '1108_10-1') {
      filePath = [{img_filename: 'LWAppImage/2024/05/22/2024_05_22_12_06_17_24.png'}];
    } else {
      filePath = await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 71,
        parameters: {
          uuid,
          job_type_name: 'SurfaceCompare',
          map
        },
        name: `${t('検査結果_検査の一覧_部位毎')}`,
        cancelToken: source.token,
        t
      });
    }

    setImageFullPath(imagePath[0].svalue + filePath[0].img_filename);
    setOpenDataScreenShot(map);
  };

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });
    const sParams = {
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      serial: searchValue.serialno === '' ? null : searchValue.serialno
    };
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 307,
      parameters: sParams,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    }).then((datas: {[key: string]: string | number}[]) => {
      if (datas && datas.length > 0) {
        const results = extractAndFormatDevelopments(datas.filter(e => e.map !== ''));
        setData(results);
      } else {
        setData([]);
      }
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const onChangeShowLatestOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, show_latest_only: event.target.checked});
  };
  const onChangeAutoReload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, auto_reload: event.target.checked});
  };
  const toggleDisplayNgOnly = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsDisplayNgOnly(event.target.checked);
  };

  const onSelectAutoReloadInterval = (event: SelectChangeEvent<any>) => {
    setSearchValue({
      ...searchValue,
      auto_reload_interval: parseInt(event.target.value as string, 10)
    });
  };

  const displayNextData = () => {
    if (index > 0) {
      const _index = index - 1;
      setIndex(_index);
    }
  };
  const displayPrevData = () => {
    if (index < data.length - 1) {
      const _index = index + 1;
      setIndex(_index);
    }
  };

  const [workParam, setWorkParam] = useState<any[]>([]);
  const [deviceId, setDeviceId] = useState<any[]>([]);
  const [serial, setSerial] = useState<string>('');
  const [startFetchingData, setStartFetchingData] = useState<boolean>(false);

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  const [processing, setProcessing] = useState(false);

  const handleKeyDown = debounce((event: {key: string; ctrlKey: boolean; code: string}) => {
    if (processing) return; // 処理中なら無視

    setProcessing(true); // 処理開始
    setTimeout(() => setProcessing(false), 50); // 処理終了後、再度実行可能に (300ms後)
    console.log(data[index]);

    const sortedMap = data[index]
      .map((item: any[]) => item.map) // mapプロパティを抽出
      .sort((a: number, b: number) => a - b); // 昇順でソート

    if (event.key === 'Escape') {
      setOpenDataScreenShot('');
    } else if (event.ctrlKey && event.code === 'Space') {
      openDataScreenShot !== '' ? setOpenDataScreenShot('') : setOpenDataScreenShot(sortedMap[0]);
    } else if (event.ctrlKey && event.key === 'ArrowLeft') {
      if (openDataScreenShot !== '') {
        /* 現在何番目のmapが選択されているかを確認 */
        /* 空のmapが存在している場合があるのでフィルター */
        const targetIndex = data[index]
          .filter((e: {judge: boolean}) => (isDisplayNgOnly ? e.judge : e))
          .filter((e: {map: string}) => e.map !== '')
          .findIndex((item: any) => item.map === openDataScreenShot);
        if (targetIndex < data[index].length - 1) {
          const tmp = data[index]
            .filter((e: {judge: boolean}) => (isDisplayNgOnly ? e.judge : e))
            .filter((e: {map: string}) => e?.map !== '')[targetIndex + 1]?.map;
          if (!tmp) return;
          setOpenDataScreenShot(tmp);
        }
      } else {
        displayPrevData();
      }
    } else if (event.ctrlKey && event.key === 'ArrowRight') {
      if (openDataScreenShot !== '') {
        /* 現在何番目のmapが選択されているかを確認 */
        /* 空のmapが存在している場合があるのでフィルター */
        const targetIndex = data[index]
          .filter((e: {judge: boolean}) => (isDisplayNgOnly ? e.judge : e))
          .filter((e: {map: string}) => e.map !== '')
          .findIndex((item: any) => item.map === openDataScreenShot);
        if (targetIndex > 0) {
          const tmp = data[index]
            .filter((e: {judge: boolean}) => (isDisplayNgOnly ? e.judge : e))
            .filter((e: {map: string}) => e?.map !== '')[targetIndex - 1]?.map;
          if (!tmp) return;
          setOpenDataScreenShot(tmp);
        }
      } else {
        displayNextData();
      }
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [processing, openDataScreenShot, data, index]);

  /**
   * 自動更新処理
   */
  useInterval(doSearch, searchValue.auto_reload ? searchValue.auto_reload_interval * 1000 : null);
  return {
    t,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    work,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    deviceid,
    doSearch,
    resetSearch,
    openSavedCondition,
    setOpenSavedCondition,
    condition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    onChangeShowLatestOnly,
    onChangeAutoReload,
    onSelectAutoReloadInterval,
    open,
    setOpen,
    displayNextData,
    displayPrevData,
    data,
    index,
    setWorkParam,
    workParam,
    deviceId,
    setDeviceId,
    serial,
    setSerial,
    startFetchingData,
    setStartFetchingData,
    serialNumber,
    setSerialNumber,
    openDataScreenShot,
    setOpenDataScreenShot,
    imagePath,
    imageFullPath,
    handleClickDataScreenShot,
    openModal,
    setOpenModal,
    isDisplayNgOnly,
    toggleDisplayNgOnly
  };
};

const extractAndFormatDevelopments = (array: any[]) => {
  // lq_timeでグループ化
  const grouped = array
    .sort((a, b) => b.serial.localeCompare(a.serial))
    .reduce((acc, obj) => {
      const key = obj.serial;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});

  // グループ化された配列をサブ配列として格納し、mapで昇順にソート
  return Object.values(grouped).map((group: any) => {
    return group.sort((a: {map: string}, b: {map: string}) => {
      // mapの形式 '1118_1-1' を分解して数値で比較
      const mapA = a.map.match(/(\d+)_(\d+)-(\d+)/);
      const mapB = b.map.match(/(\d+)_(\d+)-(\d+)/);

      // mapA, mapB がnullでないかチェック
      if (!mapA || !mapB) {
        // マッチしない場合は安全なデフォルト値で比較（ここでは0とする）
        return !mapA ? -1 : 1;
      }

      // 正規表現で得た結果を数値配列に変換
      const parsedMapA = mapA.slice(1).map(Number);
      const parsedMapB = mapB.slice(1).map(Number);

      // 大きい要素から順に比較
      for (let i = 0; i < parsedMapA.length; i++) {
        if (parsedMapA[i] !== parsedMapB[i]) {
          return parsedMapA[i] - parsedMapB[i];
        }
      }

      // 完全に同じ場合は同順位
      return 0;
    });
  });
};
