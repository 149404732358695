import {Box, Divider, Grid} from '@mui/material';
import {GridValueFormatterParams} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import {number} from 'echarts';
import {round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCSelectFormYear from '_components-with-data/inputs/LFCSelectFormYear';
import LFCChartsLineYearlyResults from '_components/charts/LFCChartsLineYearlyResults';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {useMySettings} from '_contexts/MySettingsProvider';
import {fmtRoundOffZero} from '_logics/LFCFormatUtil';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCSingleValueYearlyResults from '../_components/LFCSingleValueYearlyResults';
import {useTranslation} from 'react-i18next';

const YearlyResults: React.FC = () => {
  const {t} = useTranslation();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [searchValue, setSearchValue] = useState({
    selectYear: dayjs().format('YYYY')
  });

  const [listData, setListData] = useState([]);
  const [listDataAverage, setListDataAverage] = useState([
    {
      aim_cycle_time: number,
      aim_line_productivity: number,
      aim_pph: number,
      avg_cycle_time: number,
      avg_line_productivity: number,
      avg_pph: number,
      avg_work_rate: number,
      id: number,
      idx: number,
      nendo: number,
      pph_judge: String,
      sum_stop_time: String,
      sum_work_time: String,
      year: String
    }
  ]);
  const [result_year_month_day, setresult_year_month_day] = useState([]);
  const [graphData, setgraphData] = useState([]);
  const [month_list, setmonth_list] = useState([]);

  let tmp_month_list: any = [];

  const makeMonthList = () => {
    let first_date = new Date(searchValue.selectYear + '/4');
    for (let i = 0; i < 12; i++) {
      tmp_month_list.push(
        first_date.getFullYear() + '/' + ('0' + (first_date.getMonth() + 1)).slice(-2)
      );
      first_date.setMonth(first_date.getMonth() + 1);
    }
    setmonth_list(tmp_month_list);
  };

  const thisNendo = () => {
    let today: any = new Date();
    if (today.getMonth() + 1 < 4) {
      return today.getFullYear() - 1;
    } else {
      return today.getFullYear();
    }
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    Promise.allSettled([
      thisNendo(),
      (async () => {
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 50010,
          parameters: {
            select_year: searchValue.selectYear
          },
          ds_state: setListData,
          name: `POC_${t('年間実績')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 50011,
          parameters: {
            select_year: searchValue.selectYear
          },
          ds_state: setListDataAverage,
          name: `POC_${t('年間実績')}_${t('平均')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 50013,
          parameters: {
            select_year: searchValue.selectYear
          },
          ds_state: setresult_year_month_day,
          name: `POC_${t('年間実績')}_${t('一覧')}_${t('日あり')}`,
          cancelToken: source.token,
          t
        });
      })(),
      makeMonthList()
    ]).then(() => {
      setStartProcess(false);
    });
  }, [searchValue]);

  useEffect(() => {
    let tmp_g: any = [];
    listData.forEach((item: any) => {
      tmp_g.push({
        id: item.year + '/' + item.month,
        avg_line_productivity: round(item.avg_line_productivity, 1),
        aim_line_productivity: item.aim_line_productivity,
        avg_pph: round(item.avg_pph, 1),
        aim_pph: item.aim_pph,
        year: item.year,
        month: item.month,
        nendo: item.nendo,
        pph_judge: item.pph_judge
      });
    });
    month_list.filter(element => {
      if (!tmp_g.find((item: any) => item.id === element)) {
        tmp_g.push({
          id: element,
          avg_line_productivity: null,
          aim_line_productivity: null,
          avg_pph: null,
          aim_pph: null,
          year: null,
          month: null,
          nendo: null,
          pph_judge: null
        });
      }
      return true;
    });
    setgraphData(tmp_g);
  }, [listData]);

  const [avg_line_productivity, setAvg_line_productivity] = useState({
    data1: '0',
    data2: '',
    data3: '0'
  });
  const [avg_pph, setavg_pph] = useState({
    data1: '0',
    data2: '',
    data3: '0'
  });

  useEffect(() => {
    let tmp_thisNendo: any = listDataAverage.filter((item: any) => item.idx === 0);
    let tmp_beforeNendo: any = listDataAverage.filter((item: any) => item.idx === -1);
    let tmp_avg_line_productivity: any = [];
    let tmp_avg_pph: any = [];
    let tmp_avg_line_productivity_before: any = [];
    let tmp_avg_pph_before: any = [];

    if (tmp_thisNendo.length > 0) {
      tmp_thisNendo.forEach((item: any) => {
        tmp_avg_line_productivity.push(item['avg_line_productivity']);
        tmp_avg_pph.push(item['avg_pph']);
      });
    }
    if (tmp_beforeNendo.length > 0) {
      tmp_beforeNendo.forEach((item: any) => {
        tmp_avg_line_productivity_before.push(item['avg_line_productivity']);
        tmp_avg_pph_before.push(item['avg_pph']);
      });
    }

    let calc_avg_line_productivity: string = '';
    let calc_tmp_avg_pph: string = '';
    let calc_avg_line_productivity_before: string = '';
    let calc_tmp_avg_pph_before: string = '';

    if (tmp_avg_line_productivity.length > 0) {
      calc_avg_line_productivity = String(
        tmp_avg_line_productivity.reduce(
          (item0: string, item1: string) => Number(item0) + Number(item1)
        ) / tmp_avg_line_productivity.length
      );
    }
    if (tmp_avg_line_productivity_before.length > 0) {
      calc_avg_line_productivity_before = String(
        tmp_avg_line_productivity_before.reduce(
          (item0: string, item1: string) => Number(item0) + Number(item1)
        ) / tmp_avg_line_productivity_before.length
      );
    }
    if (tmp_avg_pph.length > 0) {
      calc_tmp_avg_pph = String(
        tmp_avg_pph.reduce((item0: string, item1: string) => Number(item0) + Number(item1)) /
          tmp_avg_pph.length
      );
    }
    if (tmp_avg_pph_before.length > 0) {
      calc_tmp_avg_pph_before = String(
        tmp_avg_pph_before.reduce((item0: string, item1: string) => Number(item0) + Number(item1)) /
          tmp_avg_pph_before.length
      );
    }

    if (listDataAverage.length > 0 && listDataAverage[0].avg_line_productivity) {
      setAvg_line_productivity({
        data1: calc_avg_line_productivity_before
          ? fmtRoundOffZero(calc_avg_line_productivity_before)
          : '0',
        data2: '　',
        data3: calc_avg_line_productivity ? fmtRoundOffZero(calc_avg_line_productivity) : '0'
      });
    }
    if (listDataAverage.length > 0 && listDataAverage[0].avg_pph) {
      setavg_pph({
        data1: calc_tmp_avg_pph_before ? fmtRoundOffZero(calc_tmp_avg_pph_before) : '0',
        data2: '　',
        data3: calc_tmp_avg_pph ? fmtRoundOffZero(calc_tmp_avg_pph) : '0'
      });
    }
  }, [listDataAverage]);

  const getTreeDataPath = (listDataShow: any) => listDataShow.group_key;
  const groupingColDef = {
    headerName: t('年月日'),
    width: 180
  };

  let listData_column = {
    // id: "id",
    aim_line_productivity: `${t('ライン効率目標')}(%)`,
    aim_pph: t('PPH目標'),
    avg_line_productivity: t('ライン効率'),
    avg_pph: 'PPH',
    month: t('月'),
    nendo: t('年度'),
    year: t('年')
  };

  let columns: any = [
    {
      field: 'nendo',
      headerName: t('年度'),
      description: t('年度'),
      width: 120,
      sortable: false,
      hide: true
    },
    {
      field: 'day',
      headerName: '日',
      description: '日',
      width: 120,
      sortable: false,
      hide: true
    },
    {
      field: 'line_productivity',
      headerName: t('ライン効率'),
      description: t('ライン効率'),
      width: 120,
      sortable: false,
      // @ts-ignore
      valueFormatter: (prms: GridValueFormatterParams) => fmtRoundOffZero(prms.value),
      headerClassName: 'header--line_productivity',
      cellClassName: 'cell--line_productivity',
      align: 'right'
    },
    {
      field: 'pph',
      headerName: 'PPH',
      description: 'PPH',
      width: 120,
      sortable: false,
      // @ts-ignore
      valueFormatter: (prms: GridValueFormatterParams) => fmtRoundOffZero(prms.value),
      headerClassName: 'header--pph',
      cellClassName: 'cell--pph',
      align: 'right'
    },
    {
      field: 'aim_line_productivity',
      headerName: t('ライン効率目標'),
      description: t('ライン効率目標'),
      width: 140,
      sortable: false,
      // @ts-ignore
      valueFormatter: (prms: GridValueFormatterParams) => fmtRoundOffZero(prms.value),
      headerClassName: 'header--aim_line_productivity',
      cellClassName: 'cell--aim_line_productivity',
      align: 'right'
    },
    {
      field: 'aim_pph',
      headerName: t('PPH目標'),
      description: t('PPH目標'),
      width: 120,
      sortable: false,
      // @ts-ignore
      valueFormatter: (prms: GridValueFormatterParams) => fmtRoundOffZero(prms.value),
      headerClassName: 'header--aim_pph',
      cellClassName: 'cell--aim_pph',
      align: 'right'
    }
  ];

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  return (
    <GenericTemplate title={t('menu.page_name.年間実績')}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCSelectFormYear
            name={'selectYear'}
            value={searchValue.selectYear}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <LFCDataGridSimple
              columns={columns}
              rows={result_year_month_day}
              height="700px"
              exportFilename={`${t('menu.page_name.年間実績')}`}
              treeData={true}
              group={getTreeDataPath}
              groupingColDef={groupingColDef}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <LFCSingleValueYearlyResults
              title={t('ライン効率平均')}
              val={avg_line_productivity}
              fontColor={mySettings.isDark ? '#e2ef32' : '#7e8603'}
            />
            <LFCSingleValueYearlyResults
              title={'PPH平均'}
              val={avg_pph}
              fontColor={mySettings.isDark ? '#26c53f' : '#047c27'}
            />
            <Grid item xs={12} md={12}>
              <LFCChartsLineYearlyResults
                title={t('生産性実績')}
                source={graphData}
                x={{dsColumn: 'id'}}
                yLeft={{
                  type: 'line',
                  name: [`${t('ライン効率')}(%)`, `${t('ライン効率目標')}(%)`],
                  dsColumn: ['avg_line_productivity', 'aim_line_productivity']
                }}
                yRight={{
                  type: 'line',
                  name: [`PPH(${t('台')})`, t('PPH目標')],
                  dsColumn: ['avg_pph', 'aim_pph']
                }}
                color={[
                  'rgb(226, 239, 50)',
                  'rgb(227, 56, 87)',
                  'rgb(38, 197, 63)',
                  'rgb(64, 121, 239)'
                ]}
                exportData={graphData}
                exportFields={listData_column}
                exportFilename={`${t('生産性実績')}_${t('グラフ')}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};
export default YearlyResults;
