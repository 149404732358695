import {Button, Dialog, DialogContent, Grid, Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsHeatMap from '_components/charts/LFCChartsHeatMap';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {rendJudgeDisp, rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import ImageDig from './ImageDig';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import DownloadIcon from '@mui/icons-material/Download';
import {green} from '@mui/material/colors';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  uuid: string;
  map: string;
}

const DetailDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [dsTop, setDsTop] = useState({});
  const [dsListData, setDsListData] = useState([]);
  const [dsColumns, setDsColumns] = useState<GridColDef[]>([]);
  const [dsRows, setDsRows] = useState<any>([]);
  const ExcelJS = require('exceljs');

  //ImageDig用
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [showDetailPath, setShowDetailPath] = useState<string>('');

  const [imagePath, setImagePath] = useState<any>([]);
  const [startProcess, setStartProcess] = useState(false);
  const [serial, setSerial] = useState('');
  const [dsRow, setDsRow] = useState<any>([]);

  // CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * 画像セル定義
   * @param params
   * @returns
   */
  const imageFileUrl = (params: any) => {
    return (
      <img
        src={imagePath[0].svalue + params.formattedValue}
        width="100%"
        onClick={(event: any) => {
          setShowDetailPath(imagePath[0].svalue + params.formattedValue);
          setOpen(true);
        }}
        style={{cursor: 'pointer'}}
        alt=""
      />
    );
  };

  /**
   * 一覧のヘッダを生成
   * @param getList
   */
  const makeColumn = (getList: any) => {
    let tmp_key_0: any = Object.keys(getList[0]);
    let nullClean_data: any = {};

    getList.forEach((dataOneRecord: any) => {
      Object.values(dataOneRecord).forEach((item: any, index: number) => {
        if (item !== null) {
          nullClean_data[tmp_key_0[index]] = item;
        }
      });
    });

    let tmp_key: any = Object.keys(nullClean_data);
    setDsColumns([
      ...tmp_key.map((item: string) => {
        switch (item) {
          case 'id':
            return {
              field: item,
              width: 100,
              hide: true
            };
          case 'img_filename':
            return {
              field: item,
              headerName: t('画像'),
              width: 160,
              disableColumnMenu: true,
              sortable: false,
              renderCell: imageFileUrl
            };
          case 'uuid':
            return {
              field: item,
              headerName: 'uuid',
              width: 160,
              disableColumnMenu: true,
              sortable: false,
              renderCell: null,
              hide: true
            };
          case 'serial':
            return {
              field: item,
              headerName: `${t('シリアルNo')}`,
              width: 160,
              disableColumnMenu: true,
              sortable: false,
              renderCell: null,
              hide: true
            };
          case 'lq_time':
            return {
              field: item,
              headerName: `${t('日時')}`,
              width: 160,
              disableColumnMenu: true,
              sortable: false,
              renderCell: null,
              hide: true
            };
          case 'job_type_name':
            return {
              field: item,
              headerName: t('ジョブ'),
              width: 160,
              disableColumnMenu: true,
              sortable: true,
              renderCell: null
            };
          case 'map':
            return {
              field: item,
              headerName: t('部位'),
              width: 80,
              disableColumnMenu: true,
              sortable: true,
              renderCell: null
            };
          case 'branch_no':
            return {
              field: item,
              headerName: 'SEQ',
              width: 60,
              disableColumnMenu: true,
              sortable: true,
              renderCell: null,
              align: 'right'
            };
          case 'judge':
            return {
              field: item,
              headerName: `${t('判定')}`,
              width: 65,
              disableColumnMenu: true,
              sortable: true,
              renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value),
              align: 'center'
            };
          default:
            return {
              field: item,
              headerName: item,
              width: 100,
              disableColumnMenu: true,
              sortable: false,
              renderCell: null,
              align: 'right'
            };
        }
      })
    ]);
  };

  const [selectedLine, setSelectedLine] = useState({y: ''});

  /**
   * 【検査x部位】クリックイベント
   * @param params
   */
  const onClickHeat = (params: {x: string; y: string}) => {
    setSelectedLine({y: params.y});
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 71,
        parameters: {
          uuid: props.uuid,
          job_type_name: params.y,
          map: null
        },
        name: `${t('検査結果_検査の一覧_部位毎')}`,
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          makeColumn(datas);
          setDsRows(datas.sort((a: any, b: any) => a.map - b.map));
        }
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * Loadイベント
   */
  useEffect(() => {
    if (props.open) {
      //検査結果_詳細単行
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 69,
        parameters: {
          uuid: props.uuid
        },
        name: `${t('検査結果_詳細単行')}`,
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setDsTop(datas[0]);
          setSerial(datas[0]['serial']);
        }
      });

      //検査結果_検査箇所×検査種別
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 70,
        parameters: {
          uuid: props.uuid
        },
        name: `${t('検査結果_検査箇所×検査種別')}`,
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        setDsListData(datas);
      });
    } else {
      setDsTop({});
      setDsListData([]);
      setDsColumns([]);
      setSelectedLine({y: ''});
    }
  }, [props.open]);

  useEffect(() => {
    // マウント時処理

    // 画像パス取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const onDownloadCSV = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {
        serialno: serial,
        uuid: props.uuid
      }
    })
      .then(response => {
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, response.data], {type: 'text/plain'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}_${serial}.csv`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDownloadExcel = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {
        serialno: serial,
        uuid: props.uuid
      }
    })
      .then(async response => {
        const data: string[][] = response.data.split('\r\n').map((row: any) => row.split(','));
        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet('export_data');
        const worksheet = workbook.getWorksheet('export_data');
        data.forEach((row, indexRow) => {
          if (indexRow === 0) {
            const header_data: {header: string; key: string}[] = [];
            row.forEach((cell, indexCol) => {
              header_data.push({header: cell.slice(1).slice(0, -1), key: `c${indexCol}`});
            });
            worksheet.columns = header_data;
          } else {
            let row_data = {};
            row.forEach((cell, indexCol) => {
              if (cell.length > 0) {
                row_data = {...row_data, [`c${indexCol}`]: cell.slice(1).slice(0, -1)};
              }
            });
            worksheet.addRow(row_data);
          }
        });
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}_${serial}.xlsx`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <ProgressBar startProcess={startProcess} />
      <LFCDialogTitle onClose={props.onClose}>{t('詳細')}</LFCDialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10}>
            <LFCSingleValues
              columns={[
                {field: 'judge', headerName: `${t('総合判定')}`, formatter: rendTotalJudgeDisp},
                {field: 'lq_time', headerName: t('検査日時'), formatter: fmtYMD},
                {field: 'serial', headerName: `${t('シリアルNo')}`},
                {field: 'work', headerName: `${t('機種')}`},
                {field: 'deviceid', headerName: t('検査装置')},
                {field: 'dongleid', headerName: t('オペレータ')},
                {field: 'lv2count', headerName: t('検査回数')}
              ]}
              source={dsTop}
            />
          </Grid>
          <Grid item xs={12} textAlign="right">
            <Tooltip title={t('CSV出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DownloadIcon />}
                onClick={onDownloadCSV}
              >
                {t('数値データ')}(CSV)
              </Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title={t('Excel出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                style={{background: green[300]}}
                startIcon={<DownloadIcon />}
                onClick={onDownloadExcel}
              >
                {t('数値データ')}(Excel)
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <LFCChartsHeatMap
              title={`${t('判定結果')}(${t('ジョブ')}×${t('部位')})`}
              source={dsListData}
              x={{dsColumn: 'map'}}
              y={{dsColumn: 'job_type_name'}}
              series="judge"
              visual_map={{
                min: 0,
                max: 1,
                show: false
              }}
              onClick={onClickHeat}
              exportData={dsListData}
              exportFields={{
                serial: t('シリアルNo'),
                job_type_name: t('ジョブ'),
                map: t('部位'),
                judge: t('判定')
              }}
              exportFilename={`${t('判定結果')}(${t('検査')}×${t('部位')})`}
              selected={selectedLine}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              '.grid-css--1': {
                bgcolor: 'lightcoral'
              }
            }}
          >
            {dsColumns.length > 0 ? (
              <>
                <LFCDataGrid
                  columns={dsColumns}
                  onCellClick={(e: any) => {
                    setShowDetailPath(
                      imagePath[0].svalue +
                        dsRows.filter((row: any) => row.id === e.id)[0].img_filename
                    );
                    setDsRow(dsRows.filter((row: any) => row.id === e.id));
                    setOpen(true);
                  }}
                  rows={dsRows}
                  height="40vh"
                  exportFilename={`${t('判定結果')}(${t('検査')}×${t('部位')})${t('明細')}`}
                  columnOrder={[
                    {name: 'job_type_name', order: 0},
                    {name: 'map', order: 1},
                    {name: 'branch_no', order: 2}
                  ]}
                  style={{cursor: 'pointer'}}
                  initialState={{
                    left: ['job_type_name', 'map', 'branch_no', 'judge', 'img_filename'],
                    right: ['actions']
                  }}
                  pageSize={50}
                  getRowClassName={(params: any) => `grid-css--${params.row.judge}`}
                />
              </>
            ) : (
              <div
                style={{
                  height: '200px',
                  textAlign: 'center',
                  padding: '100px 0',
                  border: 'solid 1px silver'
                }}
              >
                {t('↑をクリック')}
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <ImageDig
              open={open}
              onClose={handleClose}
              showDetailPath={showDetailPath}
              dsColumns={dsColumns.filter(col => col.field !== 'img_filename')}
              dsRow={dsRow}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DetailDig;
