import {Card, CardContent, Grid} from '@mui/material';
import LFCChartsWeldingDayBarLine from 'pages/welding/_charts/LFCChartsWeldingDayBarLine';
import LFCChartsWeldingFreeLine from 'pages/welding/_charts/LFCChartsWeldingFreeLine';
import {useTranslation} from 'react-i18next';

type Props = {
  productTransition: any;
  workTimeTransition: any;
};

export const TransitionCard = ({productTransition, workTimeTransition}: Props) => {
  const {t} = useTranslation();
  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <LFCChartsWeldingDayBarLine
              chartType="bar"
              title={t('生産数推移')}
              source={productTransition}
              sourceKey={{x: 'exec_date', y: 'exec_count', series: 'work'}}
              exportFields={{
                exec_date: t('時刻'),
                work: t('機種'),
                exec_count: t('個数')
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <LFCChartsWeldingFreeLine
              title={t('作業時間推移')}
              source={workTimeTransition}
              sourceKey={{x: 'exec_time', y: 'cycle_time', series: 'work'}}
              yUnit={`${t('秒')}`}
              yDigit={1}
              exportFields={{
                exec_time: t('時刻'),
                work: t('機種'),
                cycle_time: t('作業時間')
              }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
