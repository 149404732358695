import eazyMenu from '_assets/data/eazy_menu.json';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import {MenuTabContext} from '_contexts/MenuTabProvider';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {makeStyles} from 'tss-react/mui';

import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Box,
  Collapse,
  colors,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Tab
} from '@mui/material';

/**
 * Style
 */
const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      width: '100%',
      margin: 0
    },
    formcontrol: {
      display: 'flex'
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    table: {
      maxWidth: '100%'
      // Width: '50%',
      // border: '1px solid black'
    },
    table_inner: {
      maxWidth: '100%'
      // Width: '50%',
    },
    table_list: {
      '&:hover': {
        backgroundColor: 'rgba(152,151,151,0.25)'
      }
    },
    cardMenu: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: colors.blueGrey[500],
        opacity: 0.9
      }
    },
    menuImage: {
      fill: theme.palette.primary.main
    },

    menuBoxSetting: {
      width: '250px',
      maxWidth: '250px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      }
      // height: '30px',
    },

    menuBoxSetting2nd: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '100%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen1: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '200%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen2: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '300%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen3: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '400%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen4: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '500%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen5: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '600%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen6: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '700%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen7: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '800%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen8: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '900%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen9: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1000%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen10: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1100%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2nd_last: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '50%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen_last: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '50%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting3rd: {
      width: '770px',
      maxWidth: '770px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '468px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 0,
        height: '100%',
        left: '-188px',
        top: 0,
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-188px',
        top: '50%',
        width: '190px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting3rd_last: {
      width: '770px',
      maxWidth: '770px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '468px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 0,
        height: '100%',
        left: '-188px',
        top: 0,
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-188px',
        top: '50%',
        width: '190px',
        height: 0,
        borderTop: '1px dotted #666'
      },
      '&:last-child': {
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 0,
          height: '50%',
          left: '-188px',
          top: 0,
          borderLeft: '1px dotted #666'
        }
      }
    },
    stepperBorder: {
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-40%',
        top: '50%',
        width: '40%',
        height: 0,
        borderTop: '1px dotted #666'
      }
    }
  };
});

interface Props {
  onClose: () => void;
}

const LFCEasyNavi = (props: Props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const {classes} = useStyles();
  const [value, setValue] = React.useState('1');
  const [canView, setCanView] = useState(true);

  const MenuTabContextData = useContext(MenuTabContext);

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setOpenMenuIDLv1(999);
    setOpenMenuIDLv2(999);
    setActiveStep(0);
    setValue(newValue);
  };

  const tabShow = (tabId: string, tabName: string) => {
    if (MenuTabContextData.tabData.find((a: any) => a.tab_id === Number(tabId)) !== undefined) {
      return <Tab label={tabName} value={tabId} />;
    } else {
      return null;
    }
  };

  const checkTabNo = () => {
    if (
      MenuTabContextData.tabData.find((a: any) => a.tab_id === 1) === undefined &&
      MenuTabContextData.tabData.find((a: any) => a.tab_id === 5) === undefined
    ) {
      setValue(String(999));
      setCanView(false);
    } else {
      if (
        MenuTabContextData.tabData.find((a: any) => a.tab_id === 1) !== undefined &&
        MenuTabContextData.tabData.find((a: any) => a.tab_id === 5) === undefined
      ) {
        setValue(String(1));
      } else if (
        MenuTabContextData.tabData.find((a: any) => a.tab_id === 1) === undefined &&
        MenuTabContextData.tabData.find((a: any) => a.tab_id === 5) !== undefined
      ) {
        setValue(String(5));
      } else {
        setValue(String(1));
      }
      setCanView(true);
    }
  };

  useEffect(() => {
    checkTabNo();
  }, [MenuTabContextData]);

  const steps = [
    t('easynavi.message.欲しい情報は何ですか？'),
    t('easynavi.message.知りたい項目は何ですか？'),
    t('easynavi.message.一つ選んでサンプルを見てみましょう！')
  ];
  const [activeStep, setActiveStep] = React.useState(0);

  const [openMenuIDLv1, setOpenMenuIDLv1] = React.useState<number>(999);
  const [openMenuIDLv2, setOpenMenuIDLv2] = React.useState<number>(999);

  const clickMenuIdLv1 = (menuId: number) => {
    setOpenMenuIDLv1(menuId);
    setOpenMenuIDLv2(999);
    if (menuId === openMenuIDLv1 && openMenuIDLv1 < 10) {
      setOpenMenuIDLv1(999);
      setActiveStep(0);
    }
    if (menuId < 10) {
      setActiveStep(0);
    } else if (menuId >= 10) {
      setActiveStep(1);
    }
  };
  const clickMenuIdLv2 = (menuId: number) => {
    setOpenMenuIDLv2(menuId);
    if (menuId === openMenuIDLv2 && menuId > 0 && menuId < 20) {
      setOpenMenuIDLv2(0);
      setActiveStep(0);
    } else if (menuId === openMenuIDLv2 && menuId >= 20 && menuId < 30) {
      setOpenMenuIDLv2(1);
      setActiveStep(0);
    } else if (menuId === openMenuIDLv2 && menuId >= 30 && menuId < 40) {
      setOpenMenuIDLv2(2);
      setActiveStep(0);
    } else if (menuId === openMenuIDLv2 && menuId >= 40 && menuId < 50) {
      setOpenMenuIDLv2(3);
      setActiveStep(0);
    } else if (menuId === openMenuIDLv2 && menuId >= 50 && menuId < 60) {
      setOpenMenuIDLv2(4);
      setActiveStep(0);
    }

    if (menuId < 10) {
      setActiveStep(0);
    } else if (menuId >= 10) {
      setActiveStep(1);
    }
  };

  const [show, setShow] = useState(false);
  const [path, setPath] = useState('');
  const [name, setName] = useState('');
  const [pageId, setPageId] = useState(999);

  const closeDialog = () => {
    setShow(false);
  };

  const showSample = (pageId: number, path: string, name: string) => {
    setPath(path);
    setName(name);
    setPageId(pageId);
    setShow(true);
    setActiveStep(2);
  };

  const gotoPage = () => {
    if (history.location.pathname !== path) {
      setShow(false);
      setOpenMenuIDLv1(999);
      setOpenMenuIDLv2(999);
      history.push(path);
    } else {
      setShow(false);
      setOpenMenuIDLv1(999);
      setOpenMenuIDLv2(999);
      props.onClose();
    }
  };

  const calcLineHeight = (record: number) => {
    switch (record) {
      case 1:
        return classes.menuBoxSetting2ndOpen1;
      case 2:
        return classes.menuBoxSetting2ndOpen2;
      case 3:
        return classes.menuBoxSetting2ndOpen3;
      case 4:
        return classes.menuBoxSetting2ndOpen4;
      case 5:
        return classes.menuBoxSetting2ndOpen5;
      case 6:
        return classes.menuBoxSetting2ndOpen6;
      case 7:
        return classes.menuBoxSetting2ndOpen7;
      case 8:
        return classes.menuBoxSetting2ndOpen8;
      case 9:
        return classes.menuBoxSetting2ndOpen9;
      case 10:
        return classes.menuBoxSetting2ndOpen10;
    }
  };

  const ListNavi = (propsListNavi: {menuNo: number}) => {
    const menuGroups: any =
      propsListNavi.menuNo === 0
        ? eazyMenu
            .filter((a: any) => a.menu_group < 5)
            .filter((a: any) => a.menu_id === a.menu_group)
        : eazyMenu
            .filter((a: any) => a.menu_group > 4)
            .filter((a: any) => a.menu_id === a.menu_group);
    return menuGroups.map((a: any, index: number) => {
      return (
        <div key={'listBody-' + index}>
          <ListItemButton
            component={'li'}
            onClick={() => clickMenuIdLv1(a.menu_id)}
            className={classes.menuBoxSetting}
            key={'lev1_' + a.menu_id + index}
          >
            <Box sx={{display: 'flex', width: '80px'}}>
              <svg width="50px" height="50px">
                <use
                  xlinkHref={`images/easy/easy_lv1_${a.menu_id}.svg#_x32_`}
                  className={classes.menuImage}
                />
              </svg>
            </Box>
            <ListItemText primary={a.menu_name} />
            {openMenuIDLv1 === a.menu_id ? (
              <span className="material-icons">navigate_before</span>
            ) : (
              <span className="material-icons">navigate_next</span>
            )}
          </ListItemButton>
          {eazyMenu
            .filter((b: any) => b.menu_group === a.menu_id && b.menu_content.length > 0)
            .map((c: any, index: number) => {
              return (
                <Collapse
                  in={openMenuIDLv1 === c.menu_group}
                  timeout={'auto'}
                  unmountOnExit
                  key={'base_lev2_' + c.menu_id + index}
                >
                  <ListItemButton
                    component={'li'}
                    onClick={() => clickMenuIdLv2(c.menu_id)}
                    key={'lev2_' + c.menu_id}
                    className={
                      c.menu_id === Math.max(...eazyMenu.map((g: any) => g.menu_id))
                        ? classes.menuBoxSetting2ndOpen_last
                        : openMenuIDLv2 !== c.menu_id
                          ? classes.menuBoxSetting2nd
                          : calcLineHeight(c.menu_content.length)
                    }
                  >
                    <Box sx={{display: 'flex', width: '80px'}}>
                      <svg width="50px" height="50px">
                        <use
                          xlinkHref={`images/easy/easy_lv2_${c.menu_id}.svg#_x32_`}
                          className={classes.menuImage}
                        />
                      </svg>
                    </Box>
                    <ListItemText primary={c.menu_name} />
                    {openMenuIDLv2 === c.menu_id ? (
                      <span className="material-icons">navigate_before</span>
                    ) : (
                      <span className="material-icons">navigate_next</span>
                    )}
                  </ListItemButton>
                  {eazyMenu
                    .filter((b: any) => b.menu_id === c.menu_id)
                    .map((e: any) => {
                      return e.menu_content.map((f: any, index: number) => {
                        return (
                          <Collapse
                            in={e.menu_id === openMenuIDLv2}
                            timeout={'auto'}
                            unmountOnExit
                            key={'base_lev3_' + e.menu_id + index}
                          >
                            <ListItem
                              component={'li'}
                              className={
                                e.menu_content.length - 1 !== index
                                  ? classes.menuBoxSetting3rd
                                  : classes.menuBoxSetting3rd_last
                              }
                              key={'lev3_' + e.menu_id}
                            >
                              <Box sx={{display: 'flex', width: '80px'}}>
                                <svg width="50px" height="50px">
                                  <use
                                    xlinkHref={`images/menu${f.path}.svg#_x32_`}
                                    className={classes.menuImage}
                                  />
                                </svg>
                              </Box>
                              <Box
                                sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                              >
                                <ListItemText primary={f.comment} />
                              </Box>
                              <Box
                                sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}
                              >
                                <LFCButton
                                  color="primary"
                                  onClick={() => showSample(f.id, f.path, f.name)}
                                >
                                  {/* TODO:多言語対応必要 */}
                                  {f.name}
                                </LFCButton>
                              </Box>
                            </ListItem>
                          </Collapse>
                        );
                      });
                    })}
                </Collapse>
              );
            })}
        </div>
      );
    });
  };

  const StepperNavi = (propsPopNavi: {menuNo: number}) => {
    const {t} = useTranslation();
    return (
      <>
        <Stepper nonLinear activeStep={activeStep} sx={{marginBottom: 2}} key={'step_top'}>
          {steps.map((label, index) => (
            <Step key={'step_' + index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <List
          sx={{width: '100%', maxWidth: '100%'}}
          component="ul"
          aria-labelledby="nested-list-subheader"
          key={'list_top'}
        >
          <ListNavi menuNo={propsPopNavi.menuNo} />
        </List>

        {show ? (
          <Dialog open={true} maxWidth={'lg'} fullWidth>
            <LFCDialogTitle onClose={closeDialog}>
              {/* TODO:多言語対応必要 */}
              {name}
            </LFCDialogTitle>
            <DialogContent dividers>
              <img
                src={String(process.env.REACT_APP_EASYMENUIMAGE_PATH) + pageId + '.jpeg'}
                alt=""
                style={{width: '100%'}}
              />
            </DialogContent>
            <DialogActions>
              <LFCButton onClick={() => gotoPage()} color="primary">
                {t('easynavi.画面に移動')}
              </LFCButton>
            </DialogActions>
          </Dialog>
        ) : (
          <></>
        )}
      </>
    );
  };

  return canView ? (
    <Box className={classes.root}>
      <TabContext value={value}>
        <TabList onChange={handleChange} aria-label="lab API tabs example">
          {tabShow('1', t('easynavi.L-Qualify 簡単ナビ'))}
          {tabShow('5', t('easynavi.L-Robot 簡単ナビ'))}
        </TabList>

        {/*-タブ：１-------------------------*/}
        <TabPanel value="1" style={{margin: 18}}>
          {/*-ステッパー：１-------------------------*/}
          <StepperNavi menuNo={0} />
        </TabPanel>
        <TabPanel value="5" style={{margin: 18}}>
          {/*-ステッパー：１-------------------------*/}
          <StepperNavi menuNo={1} />
        </TabPanel>
      </TabContext>
    </Box>
  ) : (
    <Box className={classes.root}>
      <TabContext value={value}>
        <TabPanel value="999" style={{margin: 18}}>
          <h3>
            <Trans i18nKey={'easynavi.message.簡単ナビ利用不可文章'} />
          </h3>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default LFCEasyNavi;
