import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {getLFCData} from '_logics/LFCUtil';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  yAxisName: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarPerson01 = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [item, setItems] = useState([]);

  useEffect(() => {
    // マウント時処理

    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      ds_state: setItems,
      t
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  let x_list: any = [...Array(24)].map((_, i) => i);

  let workList: any = [];
  if (props.source.length > 0) {
    workList = Array.from(new Set(props.source.map((a: any) => a.work))).sort();
  }

  let workData: any = [];
  let seriesData: any = [];
  workList.forEach((workString: string, index: number) => {
    workData[index] = [];
    x_list.forEach((timeString: number) => {
      if (
        !isEmpty(
          props.source.filter((a: any) => a.work === workString && Number(a.時刻) === timeString)
        )
      ) {
        workData[index].push(
          Number(
            props.source.filter(
              (a: any) => a.work === workString && Number(a.時刻) === timeString
            )[0].生産数
          )
        );
      } else if (!isEmpty(props.source.filter((a: any) => a.work === workString))) {
        workData[index].push('');
      }
    });
    seriesData.push({
      name: workString,
      data: workData[index],
      type: 'bar',
      stack: true,
      color: colorPallet[item.findIndex((b: any) => b.work === workString) + 35]
    });
  });

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            name: t('時刻'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            data: x_list.map((a: string) => a + t('時')),
            axisLabel: {
              interval: 0,
              rotate: '30',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            }
          },
          series: seriesData,
          grid: {
            left: 50,
            right: 5,
            bottom: 80,
            top: 60
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10,
            textStyle: {
              fontSize: 10
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return (
                prm.seriesName +
                '<br>' +
                prm.name +
                '<br>' +
                t('生産数') +
                ':' +
                prm.value +
                t('個')
              );
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
      />
    </div>
  );
};
export default LFCChartsBarPerson01;
