import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  data: any;
  onEvents?: any;
  name: string;
  exportData: any;
  exportFields: any;
  exportFilename: string;
}

const PieChartOnsiteMonitor = (props: Props) => {
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: (prm: any) => {
        return prm.name + '<br/> -' + prm.value.toLocaleString();
      }
    },
    legend: {
      top: '85%',
      left: 'center',
      textStyle: {
        fontSize: 8
      }
    },
    series: [
      {
        name: props.name,
        type: 'pie',
        radius: ['40%', '70%'],
        color: ['rgb(0,187,255)', 'rgb(243,6,6)', 'rgb(255,211,115)'],
        avoidLabelOverlap: false,
        label: {
          formatter: (prm: any) => {
            return prm.percent + '%';
          },
          position: 'inside'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: props.data
      }
    ]
  };

  return (
    <LFCBaseCharts
      option={option}
      onEvents={props.onEvents}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
    />
  );
};

export default PieChartOnsiteMonitor;
