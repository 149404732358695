import {useEffect, useState} from 'react';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import {getLFCData, handleChangeMultipleUtil, handleInputChange} from '_logics/LFCUtil';
import LFCButton from '_components/inputs/LFCButton';
import {useSnackbar} from 'notistack';
import {GridColDef} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import {Dialog, DialogContent} from '@mui/material';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import ProgressBar from '_components/ProgressBar';
import LFCSelectFormMap from '_components-with-data/inputs/LFCSelectFormMap';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  onRow: any;
}

const StartupSupportSearchDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [startProcess, setStartProcess] = useState(false);

  //　CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * 検索条件初期値
   */
  const initialSearchValue = {
    date01: dayjs().format('YYYY-MM-DDT00:00'),
    date02: dayjs().format('YYYY-MM-DDT00:00'),
    work: [t('すべて')],
    map: [t('すべて')]
  };

  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [data60348, setData60348] = useState([]);

  useEffect(() => {
    apiFetch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const apiFetch = async () => {
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });
  };

  /**
   * 検索処理
   */
  const doSearch = async () => {
    let tmp_map: any = searchValue.map.map((item: any) => {
      if (item === 'None') {
        return '';
      } else {
        return item;
      }
    });
    await setStartProcess(true);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60348,
      parameters: {
        date_from: searchValue.date01 === '' ? null : searchValue.date01,
        date_to: searchValue.date02 === '' ? null : searchValue.date02,
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
        map: searchValue.map[0] !== t('すべて') ? '{' + tmp_map.join(',') + '}' : null
      },
      ds_state: setData60348,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    await setStartProcess(false);
  };

  const [imagePath, setImagePath] = useState<any>([]);
  const imageFileUrl = (params: any) => {
    if (params !== undefined) {
      return (
        <img
          src={imagePath[0].svalue + params.value}
          width="100%"
          style={{cursor: 'pointer'}}
          alt=""
        />
      );
    } else {
      return <></>;
    }
  };

  const dsColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'img_filename',
      headerName: `${t('画像')}`,
      description: 'img_filename',
      width: 200,
      hide: false,
      renderCell: imageFileUrl
    },
    {field: 'serial', headerName: 'serial', description: 'serial', width: 300, hide: false},
    {field: 'work', headerName: 'work', description: 'work', width: 200, hide: false},
    {
      field: 'lq_time',
      headerName: `${t('検査日時')}`,
      description: 'lq_time',
      width: 200,
      hide: false
    }
  ];

  const COLUMN_ORDER: any = [{name: 'id', order: 0}];

  /**
   * リセット処理
   */
  const restSearch = () => {
    setStartProcess(false);
    setSearchValue(initialSearchValue);
    // setDgColumns([]);
    setData60348([]);
    setAutoCompleteReset(true);
  };

  const onClose = () => {
    setData60348([]);
    props.onClose();
  };

  const onClickRow = (param: any) => {
    props.onRow(param);
    setData60348([]);
    props.onClose();
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  return (
    <Dialog open={props.open} onClose={() => onClose()} maxWidth={false} fullWidth>
      <ProgressBar startProcess={startProcess} />
      <LFCDialogTitle onClose={() => onClose()}>傾向分析(SC)</LFCDialogTitle>
      <DialogContent>
        <form>
          <LFCFormRowGroup>
            <LFCDatetimePicker
              name="date01"
              label={t('日付From')}
              value={searchValue.date01}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
            />
            <LFCDatetimePicker
              name="date02"
              label={t('日付To')}
              value={searchValue.date02}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
            />
            <LFCAutocomplete
              name={t('work')}
              label={t('機種')}
              id={'work'}
              size="small" // small/medium
              value={multiSelectData(work, searchValue.work)}
              // style={{width: 500}}
              onChange={autoOnChange}
              onReset={autoCompleteReset}
              doneReset={setAutoCompleteReset}
              multiple={true}
              selectItem={work}
            />
            <LFCSelectFormMap
              name={t('map')}
              value={searchValue.map}
              onChange={(event: any) => {
                handleChangeMultipleUtil(setSearchValue, searchValue, event, t);
              }}
              multiple={true}
            />
            <LFCButton color="primary" onClick={doSearch}>
              {t('検索')}
            </LFCButton>
            <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
          </LFCFormRowGroup>
        </form>

        <LFCDataGrid
          // apiRef={apiRef}
          columns={dsColumns}
          rows={data60348}
          rowHeight={80}
          height="70vh"
          columnOrder={COLUMN_ORDER}
          exportFilename="傾向分析(SC)リスト"
          onCellClick={onClickRow}
          style={{cursor: 'pointer'}}
        />
      </DialogContent>
    </Dialog>
  );
};

export default StartupSupportSearchDig;
