import {Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {PAGEID_LQ_FAILUREANALYSIS, LOCATIONID_LQ_FAILUREANALYSIS_MAIN} from '_logics/LFCPageId';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCChartsComboYMD2 from '_components/charts/LFCChartsComboYMD2';
import LFCChartsBarYMD2 from '_components/charts/LFCChartsBarYMD2';
import {useTranslation} from 'react-i18next';

/**
 * 不良分析画面
 * @returns
 */
const FailureAnalysisPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LQ_FAILUREANALYSIS, LOCATIONID_LQ_FAILUREANALYSIS_MAIN) ?? {
      select_datetime_from: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
      select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
      work: [],
      job_title: [],
      map: [],
      deviceid: [],
      dongleid: [],
      searchType: 2
    }
  );

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];
  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setDs1([]);
    setDs2([]);
    setDs3([]);
    setDs4([]);
  };

  const date_calc = () => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_datetime_from);
    let to_day = new Date(searchValue.select_datetime_to);

    switch (searchValue.searchType) {
      case 0:
        // for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
        //   datelist.push(dayjs(d).format('YYYY-MM-DD HH:MM:SS'));
        // }
        return [];
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  };

  const [ds1, setDs1] = useState([]);
  const [ds2, setDs2] = useState([]);
  const [ds3, setDs3] = useState([]);
  const [ds4, setDs4] = useState([]);
  const [startProcess, setStartProcess] = useState(false);

  // CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * 検索
   */
  const search = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    const sParams = {
      select_datetime_from: searchValue.select_datetime_from,
      select_datetime_to: searchValue.select_datetime_to,
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      map: !isEmpty(searchValue.map) ? '{' + searchValue.map.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      dongleid: !isEmpty(searchValue.dongleid) ? '{' + searchValue.dongleid.join(',') + '}' : null
    };

    // data get
    setStartProcess(true);

    switch (searchValue.searchType) {
      case 0:
        Promise.allSettled([
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 37,
            parameters: sParams,
            ds_state: setDs1,
            name: `${t('実績数と不良率')}`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 38,
            parameters: sParams,
            ds_state: setDs2,
            name: `${t('不良数')}(${t('機種別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 39,
            parameters: sParams,
            ds_state: setDs3,
            name: `${t('不良数')}(${t('検査手法別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 40,
            parameters: sParams,
            ds_state: setDs4,
            name: `${t('不良数')}(${t('検査部位別')})`,
            cancelToken: source.token,
            t
          })
        ]).then(() => {
          setStartProcess(false);
        });
        break;
      case 1:
        Promise.allSettled([
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 29,
            parameters: sParams,
            ds_state: setDs1,
            name: `${t('実績数と不良率')}`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 30,
            parameters: sParams,
            ds_state: setDs2,
            name: `${t('不良数')}(${t('機種別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 31,
            parameters: sParams,
            ds_state: setDs3,
            name: `${t('不良数')}(${t('検査手法別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 32,
            parameters: sParams,
            ds_state: setDs4,
            name: `${t('不良数')}(${t('検査部位別')})`,
            cancelToken: source.token,
            t
          })
        ]).then(() => {
          setStartProcess(false);
        });
        break;
      case 2:
        Promise.allSettled([
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 25,
            parameters: sParams,
            ds_state: setDs1,
            name: `${t('実績数と不良率')}`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 26,
            parameters: sParams,
            ds_state: setDs2,
            name: `${t('不良数')}(${t('機種別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 27,
            parameters: sParams,
            ds_state: setDs3,
            name: `${t('不良数')}(${t('検査手法別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 28,
            parameters: sParams,
            ds_state: setDs4,
            name: `${t('不良数')}(${t('検査部位別')})`,
            cancelToken: source.token,
            t
          })
        ]).then(() => {
          setStartProcess(false);
        });
        break;
      case 3:
        Promise.allSettled([
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 33,
            parameters: sParams,
            ds_state: setDs1,
            name: `${t('実績数と不良率')}`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 34,
            parameters: sParams,
            ds_state: setDs2,
            name: `${t('不良数')}(${t('機種別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 35,
            parameters: sParams,
            ds_state: setDs3,
            name: `${t('不良数')}(${t('検査手法別')})`,
            cancelToken: source.token,
            t
          }),
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 36,
            parameters: sParams,
            ds_state: setDs4,
            name: `${t('不良数')}(${t('検査部位別')})`,
            cancelToken: source.token,
            t
          })
        ]).then(() => {
          setStartProcess(false);
        });
        break;
    }
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel: String(searchValue.select_datetime_from),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel: String(searchValue.select_datetime_to),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'map',
        value: searchValue.map,
        valueLabel: searchValue.map[0] === t('すべて') ? ALL : searchValue.map.join(','),
        colName: t('検査部位'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid.join(','),
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'dongleid',
        value: searchValue.dongleid,
        valueLabel: searchValue.dongleid[0] === t('すべて') ? ALL : searchValue.dongleid.join(','),
        colName: t('オペレータ'),
        colWidth: 300
      },
      {
        name: 'search_type',
        value: searchValue.searchType,
        valueLabel: String(searchValue.searchType),
        colName: t('集計単位'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  const [dongleid, setDongleid] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    // マウント時処理
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    // 情報取得： LFCAutocomplete：map：t_lqresult_lv3
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42021,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);
    // 情報取得： LFCAutocomplete：dongleid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40003,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['dongleid']};
        });
        setDongleid(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42007,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
        setStartProcess(false);
      })
      .catch(e => e);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, map, deviceid, dongleid]);

  return (
    <GenericTemplate title={t('menu.page_name.検査結果傾向分析')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={'select_datetime_from'}
            label={t('対象期間From')}
            value={searchValue.select_datetime_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={'select_datetime_to'}
            label={t('対象期間To')}
            value={searchValue.select_datetime_to}
            required
            onChange={handleChange}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCAutocomplete
            name={t('map')}
            label={t('検査部位')}
            id={'map'}
            size="small" // small/medium
            value={multiSelectData(map, searchValue.map)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={map}
          />
          <LFCAutocomplete
            name={'deviceid'}
            label={t('検査装置')}
            id={'deviceid'}
            size="small" // small/medium
            value={multiSelectData(deviceid, searchValue.deviceid)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={deviceid}
          />
          <LFCAutocomplete
            name={'dongleid'}
            label={t('オペレータ')}
            id={'dongleid'}
            size="small" // small/medium
            value={multiSelectData(dongleid, searchValue.dongleid)}
            // style={{width: 500}}
            onChange={autoOnChange}
            multiple={true}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            selectItem={dongleid}
          />
          <LFCButton color="primary" onClick={search}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LQ_FAILUREANALYSIS}
            locationNo={LOCATIONID_LQ_FAILUREANALYSIS_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
          <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
          <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
            {searchType.map((item: any) => (
              <FormControlLabel
                value={item.id}
                key={'search_type-' + item.id}
                control={
                  <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                }
                label={
                  <Typography display="block" key={'search_type-body-' + item.id}>
                    {item.text}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <LFCChartsComboYMD2
              title={t('実績数と不良率')}
              source={ds1}
              xList={date_calc()}
              yLeft={{
                type: 'bar',
                name: `${t('実績数')}`,
                dsColumn: 'total_count'
              }}
              yRight={{
                type: 'line',
                name: `${t('不良率')}(%)`,
                dsColumn: 'ng_par',
                markPoint: {
                  data: [
                    {type: 'min', itemStyle: {color: '#E91E63'}},
                    {type: 'max', itemStyle: {color: '#2196F3'}}
                  ]
                },
                markLine: {
                  symbol: 'arrow',
                  data: [
                    {
                      type: 'average',
                      label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                    }
                  ]
                }
              }}
              color={[green[500], red[500]]}
              height="40vh"
              exportData={ds1}
              exportFields={{
                lq_time: t('日付'),
                total_count: t('実績数'),
                ng: t('不良数'),
                ng_par: t('不良率')
              }}
              exportFilename={`${t('実績数と不良率')}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBarYMD2
              title={`${t('不良数')}(${t('機種別')})`}
              source={ds2}
              xList={date_calc()}
              y={{type: 'value', dsColumn: 'ng'}}
              series="work"
              height="40vh"
              stacked
              exportData={ds2}
              exportFields={{
                lq_time: t('日付'),
                work: t('機種'),
                ng: t('不良数')
              }}
              exportFilename={`${t('不良数')}(${t('機種別')})`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBarYMD2
              title={`${t('不良数')}(${t('検査手法別')})`}
              source={ds3}
              xList={date_calc()}
              y={{type: 'value', dsColumn: 'ng'}}
              series="job_type_name"
              height="40vh"
              stacked
              exportData={ds3}
              exportFields={{
                lq_time: t('日付'),
                job_type_name: t('検査手法'),
                ng: t('不良数')
              }}
              exportFilename={`${t('不良数')}(${t('検査手法別')})`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LFCChartsBarYMD2
              title={`${t('不良数')}(${t('検査部位別')})`}
              source={ds4}
              xList={date_calc()}
              y={{type: 'value', dsColumn: 'ng'}}
              series="map"
              height="40vh"
              stacked
              exportData={ds4}
              exportFields={{
                lq_time: t('日付'),
                map: t('検査部位'),
                ng: t('不良数')
              }}
              exportFilename={`${t('不良数')}(${t('検査部位別')})`}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default FailureAnalysisPage;
