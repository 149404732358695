import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import {green} from '@mui/material/colors';
import {GridRenderCellParams, GridValueFormatterParams, useGridApiRef} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import LFCChartsBarStartUpSupport from '_components/charts/LFCChartsBarStartUpSupport';
import LFCChartsLineMulti from '_components/charts/LFCChartsLineMulti';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {rendJudgeRedDisp} from '_logics/LFCRenderUtil';
import {distinct, getLFCData, setLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import StartupSupportSearchDig from './component/StartupSupportSearchDig';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    button: {
      height: '40px',
      margin: '10px 5px'
    }
  };
});

const StartupSupportPage = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const apiRef = useGridApiRef();

  const [startProcess, setStartProcess] = useState(false);

  const judgeStatus: any = [
    {id: 0, text: 'OK'},
    {id: 1, text: 'NG'}
  ];

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, judgeStatus: Number(e.target.value)});
  };

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  //　CancelTokenをページに１つ
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [searchValue, setSearchValue] = useState({
    sc_vmin: 0,
    sc_vmax: 0,
    sc_threshold: 0,
    judgeStatus: 0
  });
  const [selectRow, setSelectRow] = useState({
    row: {
      uuid: '',
      map: '',
      img_filename: '',
      lq_time: ''
    }
  });
  const [selectRowFromList, setSelectRowFromList] = useState({
    row: {
      uuid: '',
      map: '',
      img_filename: '',
      lq_time: ''
    }
  });
  const [data60349, setData60349] = useState([]);
  const [data60350, setData60350] = useState([]);
  const [data60356, setData60356] = useState([]);
  const [data60357, setData60357] = useState([]);

  const [doSIM, setDoSIM] = useState(false);

  const startSearch = () => {
    setSelectRow({
      row: {
        uuid: '',
        map: '',
        img_filename: '',
        lq_time: ''
      }
    });
    setSelectRowFromList({
      row: {
        uuid: '',
        map: '',
        img_filename: '',
        lq_time: ''
      }
    });
    setData60350([]);
    setData60357([]);
    setUpdated(false);
    setOpen(true);
  };

  const onRow = (params: any) => {
    setSelectRow(params);
    setUpdated(false);
  };
  const onCellClick = (params: any) => {
    setSelectRowFromList(params);
    setUpdated(true);
  };

  useEffect(() => {
    (async () => {
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60349,
        parameters: {
          uuid: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.uuid : selectRow.row.uuid,
          map: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.map : selectRow.row.map
        },
        ds_state: setData60349,
        cancelToken: source.token,
        t
      }).then(() => true);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60356,
        parameters: {
          uuid: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.uuid : selectRow.row.uuid,
          map: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.map : selectRow.row.map,
          sc_vmin: searchValue.sc_vmin,
          sc_vmax: searchValue.sc_vmax
        },
        ds_state: setData60356,
        cancelToken: source.token,
        t
      }).then(() => true);
    })();
  }, [selectRowFromList]);

  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    }).then(() => true);

    if (selectRow.row.uuid !== '' && selectRow.row.map !== '') {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60349,
        parameters: {
          uuid: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.uuid : selectRow.row.uuid,
          map: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.map : selectRow.row.map
        },
        ds_state: setData60349,
        cancelToken: source.token,
        t
      }).then(() => true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60350,
        parameters: {
          sc_vmin: searchValue.sc_vmin,
          sc_vmax: searchValue.sc_vmax,
          sc_threshold: searchValue.sc_threshold,
          map: selectRow.row.map
        },
        ds_state: setData60350,
        cancelToken: source.token,
        t
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60356,
        parameters: {
          uuid: selectRow.row.uuid,
          map: selectRow.row.map,
          sc_vmin: searchValue.sc_vmin,
          sc_vmax: searchValue.sc_vmax
        },
        ds_state: setData60356,
        cancelToken: source.token,
        t
      });
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60357,
        parameters: {
          map: selectRow.row.map
        },
        ds_state: setData60357,
        cancelToken: source.token,
        t
      });
    }
  }, [selectRow]);

  const [showData_60357, setShowData_60357] = useState([]);
  useEffect(() => {
    let tmp_uuid: any = distinct(data60357, 'uuid');
    let tmp_distance: any = distinct(data60357, 'distance');

    let tmp_data: any = [];

    tmp_uuid.forEach((tmp_uuid_string: string, index: number) => {
      tmp_data[index] = [];
      tmp_distance.forEach((tmp_distance_string: string) => {
        tmp_data[index].push(
          data60357.filter(
            (item: any) => tmp_distance_string === item.distance && tmp_uuid_string === item.uuid
          ).length > 0
            ? data60357.filter(
                (item: any) =>
                  tmp_distance_string === item.distance && tmp_uuid_string === item.uuid
              )[0]['p_count']
            : 0
        );
      });
    });
    setShowData_60357(
      tmp_uuid.map((item: string, index: number) => ({
        name: tmp_uuid[index],
        type: 'line',
        stack: false,
        data: tmp_data[index]
      }))
    );
  }, [data60357]);

  const [imagePath, setImagePath] = useState<any>([]);
  const imageFileUrl = (params: any) => {
    if (params !== '' && imagePath.length > 0) {
      return (
        <img
          src={imagePath[0].svalue + params}
          width="300px"
          // style={{cursor: "pointer"}}
          alt=""
        />
      );
    } else {
      return <></>;
    }
  };

  const dsColumns: any[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'img_filename',
      headerName: t('画像'),
      description: '画像',
      width: 150,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => imageFileUrl(prms.value)
    },
    {
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('シリアルNo.'),
      width: 150,
      hide: false
    },
    {field: 'map', headerName: t('検査部位'), description: t('検査部位'), width: 100, hide: false},
    {
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('検査日時'),
      width: 150,
      hide: false
    },
    {
      field: 'insert_time',
      headerName: '保存日時',
      description: '保存日時',
      width: 150,
      hide: false
    },
    {
      field: 'judge_real',
      headerName: '判定(人)',
      description: '判定(人)',
      width: 80,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeRedDisp(prms.value)
    },
    {
      field: 'judge_now',
      headerName: '今回判定結果',
      description: '今回判定結果',
      width: 80,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeRedDisp(prms.value)
    },
    {
      field: 'sc_score',
      headerName: '今回スコア',
      description: '今回スコア',
      width: 80,
      hide: false,
      valueFormatter: (prms: GridValueFormatterParams) => toPercent(prms.value)
    },
    {
      field: '',
      headerName: '操作',
      description: '',
      width: 100,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => {
        return (
          <LFCButton color="warning" size={'small'} onClick={() => buttonDelete(prms.row)}>
            {t('削除')}
          </LFCButton>
        );
      }
    }
  ];

  const toPercent = (param: any) => {
    return new Intl.NumberFormat('ja', {style: 'percent', maximumSignificantDigits: 3}).format(
      param / 100
    );
  };

  const onChangeEdit = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onBlurEdit = (event: any) => {
    if (!Number(event.target.value) || event.target.value === '-' || event.target.value === '') {
      setSearchValue({...searchValue, [event.target.name]: 0});
    }
  };

  const doSimulation = async () => {
    await setStartProcess(true);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60356,
      parameters: {
        uuid: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.uuid : selectRow.row.uuid,
        map: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.map : selectRow.row.map,
        sc_vmin: searchValue.sc_vmin,
        sc_vmax: searchValue.sc_vmax
      },
      ds_state: setData60356,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60350,
      parameters: {
        sc_vmin: searchValue.sc_vmin,
        sc_vmax: searchValue.sc_vmax,
        sc_threshold: searchValue.sc_threshold,
        map: selectRowFromList.row.uuid !== '' ? selectRowFromList.row.map : selectRow.row.map
      },
      ds_state: setData60350,
      cancelToken: source.token,
      t
    });
    setDoSIM(false);
    await setStartProcess(false);
  };

  const restSimulation = async () => {
    await setStartProcess(true);
    setSearchValue({
      sc_vmin: 0,
      sc_vmax: 0,
      sc_threshold: 0,
      judgeStatus: 0
    });
    setDoSIM(true);
  };

  useEffect(() => {
    if (doSIM) {
      doSimulation().then(() => setDoSIM(false));
    }
  }, [doSIM]);

  const COLUMN_ORDER: any = [{name: 'id', order: 0}];

  const buttonSave = async () => {
    setStartProcess(true);
    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 60354,
      parameters: {
        uuid: selectRow.row.uuid,
        map: selectRow.row.map,
        lq_time: selectRow.row.lq_time,
        insert_time: dayjs().format(),
        judge_real: searchValue.judgeStatus
      },
      name: '保存',
      cancelToken: source.token,
      t
    }).then(() =>
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60350,
        parameters: {
          sc_vmin: searchValue.sc_vmin,
          sc_vmax: searchValue.sc_vmax,
          sc_threshold: searchValue.sc_threshold,
          map: selectRow.row.map
        },
        ds_state: setData60350,
        cancelToken: source.token,
        t
      }).then(() => setUpdated(true))
    );
    setStartProcess(false);
  };
  const buttonDelete = async (data: any) => {
    setStartProcess(true);
    await setLFCData({
      snack: enqueueSnackbar,
      sql_id: 60355,
      parameters: {
        uuid: data.uuid,
        map: data.map
      },
      name: '削除',
      cancelToken: source.token,
      t
    }).then(() =>
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60350,
        parameters: {
          sc_vmin: searchValue.sc_vmin,
          sc_vmax: searchValue.sc_vmax,
          sc_threshold: searchValue.sc_threshold,
          map: selectRow.row.map
        },
        ds_state: setData60350,
        cancelToken: source.token,
        t
      })
    );
    setStartProcess(false);
  };

  const calcScore = () => {
    if (
      data60356.length > 0 &&
      data60356[0]['有効p数'] !== null &&
      data60356[0]['合計p数'] !== null
    ) {
      return String(toPercent((data60356[0]['有効p数'] / data60356[0]['合計p数']) * 100));
    } else {
      return '0.0%';
    }
  };

  const selectItemImage = () => {
    if (
      (selectRowFromList.row.uuid !== '' || selectRow.row.img_filename !== '') &&
      imagePath.length > 0
    ) {
      return (
        <div
          style={{
            width: '100%',
            height: '250px',
            marginTop: 8,
            marginLeft: 16,
            backgroundImage:
              selectRowFromList.row.uuid !== ''
                ? 'url(' + imagePath[0]['svalue'] + selectRowFromList.row.img_filename + ')'
                : 'url(' + imagePath[0]['svalue'] + selectRow.row.img_filename + ')',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '50%  50%',
            backgroundColor: 'gray'
          }}
        ></div>
      );
    } else {
      return (
        <div
          style={{
            width: '100%',
            height: '250px',
            marginTop: 8,
            marginLeft: 16,
            backgroundColor: 'gray'
          }}
        ></div>
      );
    }
  };

  let data60349_column = {
    uuid: 'uuid',
    serial: 'serial',
    map: 'map',
    distance: 'distance',
    p_count: 'p_count',
    vmin: 'vmin',
    vmax: 'vmax'
  };
  let data60357_column = {
    uuid: 'uuid',
    map: 'map',
    distance: 'distance',
    p_count: 'p_count'
  };

  return (
    <GenericTemplate title="立上支援">
      <ProgressBar startProcess={startProcess} />
      <LFCButton color="primary" onClick={() => startSearch()}>
        {t('検査結果検索')}
      </LFCButton>

      <Grid container spacing={2} style={{marginTop: '8px'}}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} style={{display: 'inline-flex'}}>
              <LFCSingleValues
                columns={[
                  {field: 'serial', headerName: `${t('シリアルNo')}`, width: '300px'},
                  {field: 'map', headerName: t('検査部位'), width: '100px'},
                  {field: 'lq_time', headerName: t('検査日時'), formatter: fmtYMD}
                ]}
                source={selectRowFromList.row.uuid !== '' ? selectRowFromList.row : selectRow.row}
              />

              <RadioGroup
                row
                aria-label="search_type"
                name="row-radio-buttons-group"
                style={{paddingLeft: '16px'}}
              >
                {judgeStatus.map((item: any, index: number) => (
                  <FormControlLabel
                    value={item.id}
                    key={'judge_status-' + item.id}
                    control={
                      <Radio
                        onChange={onChangeRadio}
                        checked={item.id === searchValue.judgeStatus}
                      />
                    }
                    label={
                      <Typography display="block" key={'judge_status-body-' + item.id}>
                        {item.text}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
              <LFCButton
                color="primary"
                disabled={updated}
                onClick={() => buttonSave()}
                className={classes.button}
              >
                分析用に保存する
              </LFCButton>
            </Grid>
            <Grid item xs={12} md={3}>
              {selectItemImage()}
            </Grid>
            <Grid item xs={12} md={8}>
              <LFCChartsBarStartUpSupport
                title=""
                x={{type: 'category', dsColumn: 'distance'}}
                y={{type: 'value', dsColumn: 'p_count'}}
                source={data60349}
                exportData={data60349}
                exportFields={data60349_column}
                exportFilename={'立上支援-検査結果'}
                height={'250px'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <LFCSingleValue
            title={'検査シミュレーション'}
            val={''}
            width={'100%'}
            titleVariant="body1"
            valVariant="body1"
            key={'検査シミュレーション'}
          />
          <div style={{display: 'inline-flex', margin: '10px'}}>
            <LFCTextField
              name={'sc_vmin'}
              label={'vmin'}
              value={searchValue.sc_vmin}
              onChange={onChangeEdit}
              onBlur={onBlurEdit}
              fullWidth={false}
              style={{width: '70px'}}
            />
            <div style={{paddingTop: 20}}>mm</div>
          </div>
          <div style={{display: 'inline-flex', margin: '10px'}}>
            <LFCTextField
              name={'sc_vmax'}
              label={'vmax'}
              value={searchValue.sc_vmax}
              onChange={onChangeEdit}
              onBlur={onBlurEdit}
              fullWidth={false}
              style={{width: '70px'}}
            />
            <div style={{paddingTop: 20}}>mm</div>
          </div>
          <div style={{display: 'inline-flex', margin: '10px'}}>
            <LFCTextField
              name={'sc_threshold'}
              label={'threshold'}
              value={searchValue.sc_threshold}
              onChange={onChangeEdit}
              onBlur={onBlurEdit}
              fullWidth={false}
              style={{width: '70px'}}
            />
            <div style={{paddingTop: 20}}>%</div>
          </div>
          <div style={{display: 'inline', margin: '10px', verticalAlign: 'sub'}}>
            <span style={{marginRight: '5px'}}>
              <LFCButton color="inherit" onClick={() => setDoSIM(true)}>
                実行
              </LFCButton>
            </span>
            <span>
              <LFCButton color="inherit" onClick={() => restSimulation()}>
                {t('リセット')}
              </LFCButton>
            </span>
          </div>

          <LFCSingleValue
            title={'スコア'}
            val={calcScore()}
            width={'100%'}
            titleVariant="body1"
            valVariant="h3"
            key={'スコア'}
            fontColor={green[500]}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <LFCDataGrid
            apiRef={apiRef}
            columns={dsColumns}
            rows={data60350}
            rowHeight={50}
            height="40vh"
            columnOrder={COLUMN_ORDER}
            exportFilename="傾向分析(SC)リスト"
            onCellClick={onCellClick}
            style={{cursor: 'pointer'}}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LFCChartsLineMulti
            title={'点群分布比較'}
            source={showData_60357}
            xData={distinct(data60357, 'distance').map((item: string) =>
              parseFloat(item).toFixed(1)
            )}
            color={['rgb(106,113,255)']}
            exportData={data60357}
            exportFields={data60357_column}
            exportFilename={'点群分布比較'}
            yAxisName={''}
            height={'350px'}
          />
        </Grid>
      </Grid>

      <StartupSupportSearchDig open={open} onClose={handleClose} onRow={onRow} />
    </GenericTemplate>
  );
};

export default StartupSupportPage;
