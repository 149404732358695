import React, {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  x: {dsColumn: string};
  series?: {name: string; dsColumn: string}[];
  source: any;

  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsLineGap
 * @param props
 * @returns
 */
const LFCChartsLineSurfaceCompareDot2 = React.memo((props: Props) => {
  const {t} = useTranslation();
  const renderFlgRef = useRef(false);
  const [makeDataForBranchNumber, setMakeDataForBranchNumber] = useState<any>([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }
    let tmp: any = [];
    tmp.push(
      {
        name: t('マッチング率最小値'),
        data:
          props.source.length > 0 &&
          props.source[0].data_value.map((a: string) => (!isEmpty(a) ? Number(a) : '')),
        type: 'effectScatter',
        rippleEffect: {
          number: 0,
          scale: 0,
          period: 0
        }
      },
      {
        name: t('閾値'),
        data:
          props.source.length > 0 &&
          props.source[0].data_line.map((a: string) => (typeof a === 'number' ? a : '')),
        type: 'line',
        connectNulls: true
      }
    );
    setMakeDataForBranchNumber(tmp);
  }, [props.source]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            top: '65',
            left: '55',
            right: '10',
            bottom: '20',
            containLabel: true
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            bottom: 0,
            textStyle: {
              fontSize: 10
            },
            formatter: '{name}'
          },
          tooltip: {
            trigger: 'item',
            formatter: (params: any) => {
              let tmp: string;
              tmp = t('連番') + ':' + params.name + '</br>';
              tmp +=
                t('シリアルナンバー') +
                ':' +
                props.exportData.filter((a: any) => a.idx === Number(params.name))[0].serial +
                '</br>';
              tmp +=
                t('検査日時') +
                ':' +
                props.exportData.filter((a: any) => a.idx === Number(params.name))[0].lq_time +
                '</br>';
              tmp +=
                t('検査追番号') +
                ':' +
                props.exportData.filter((a: any) => a.idx === Number(params.name))[0].branch_no +
                '</br>';
              tmp +=
                t('閾値') +
                ':' +
                props.exportData.filter((a: any) => a.idx === Number(params.name))[0].threshold +
                '</br>';
              tmp += t('マッチング率') + ':' + Math.round(Number(params.data * 100)) / 100;
              return tmp;
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          series: makeDataForBranchNumber,
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                rotate: '30',
                fontSize: 10
              },
              data: !isEmpty(props.exportData) && props.exportData.map((a: any) => a.idx)
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: t('マッチング率推移(%)'),
              max: 100,
              nameTextStyle: {
                fontSize: 10
              },
              axisLabel: {
                interval: 0,
                fontSize: 10
              }
            }
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height !== undefined ? props.height : '300px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
});
export default LFCChartsLineSurfaceCompareDot2;
