import {Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import React from 'react';
import LFCButton from '../inputs/LFCButton';

/**
 * 引数
 */
interface Props {
  open: boolean;
  message: string;
  onClose: () => void;
}

/**
 * LFCAlertDialog
 * OK オンリー の確認ダイアログです。
 * @param props
 * @returns
 */
const LFCAlertDialog = (props: Props) => {
  const handleCloseOk = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} keepMounted fullWidth>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LFCButton color="primary" onClick={() => handleCloseOk()}>
          OK
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};

export default LFCAlertDialog;
