import {SelectChangeEvent} from '@mui/material';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

interface Props {
  name: string;
  label?: string;
  value: any;
  onChange: ((event: SelectChangeEvent<any>, child: React.ReactNode) => void) | undefined;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  multiple?: boolean;
  // selectItem: any;
  // id: string;
}

/**
 * 機種プルダウン
 * @param props
 * @returns
 */
const LFCSelectFormYear = React.memo((props: Props) => {
  const {t} = useTranslation();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [items, setItems] = useState<{name: string; value?: string}[]>([{name: t('すべて')}]);

  useEffect(() => {
    // マウント時処理

    let startYear: number = Number(dayjs().format('YYYY'));
    let limit_past_year: number = 10;
    let year_list: any = [];
    for (let i = 0; i < limit_past_year; i++) {
      year_list.push({name: startYear - i, value: startYear - i});
    }
    setItems(year_list);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  if (items.length > 1) {
    return (
      <LFCSelectForm
        name={props.name}
        label={props.label ? props.label : t('対象年限')}
        value={props.value !== undefined ? props.value : t('すべて')}
        onChange={props.onChange}
        style={props.style}
        fullWidth={props.fullWidth}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        margin={props.margin}
        required={props.required}
        size={props.size}
        multiple={props.multiple}
        selectItem={items}
        id={'sf_year'}
      />
    );
  } else {
    return <></>;
  }
});
export default LFCSelectFormYear;
