export const roundToFirstDecimal = (input: number): number => {
  if (!input) return 0;
  return parseFloat(input.toFixed(1));
};

export const roundToNearestWholeNumber = (input: number): number => {
  if (!input) return 0;
  return Math.round(input);
};

export const formatTwoNumbersInString = (input: string): string => {
  // 数字を抽出するための正規表現
  const regex = /-?\d+(\.\d+)?/g;
  // 文字列から数字を抽出
  const numbers = input.match(regex);
  if (!numbers || numbers.length !== 2) {
    throw new Error('Input string must contain exactly two numbers.');
  }
  // 抽出した数字に対してtoFixed(2)を適用し、小数点以下2桁に丸める
  const formattedNumbers = numbers.map(num => {
    const fixedNum = parseFloat(num).toFixed(2);
    return fixedNum.replace(/\.00$|0$/, '');
  });

  // 元の形式に戻す
  return input.replace(regex, () => formattedNumbers.shift() ?? '');
};
