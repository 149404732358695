import {useEffect, useRef} from 'react';

/**
 * 一定の遅延間隔を置いて関数やコードスニペットを繰り返し呼び出します。
 * setIntervalのReactフック版です。
 * Call元のコンポーネントのアンマウントにより、繰り返しは停止します。
 *
 * @param callback delay ミリ秒が経過するたびに実行する関数です。最初の実行は delay ミリ秒後に行われます。
 * @param delay 指定した関数またはコードを実行する前にタイマーが待つべき時間をミリ秒 (1/1000 秒) 単位で指定します。null指定で停止します。
 */

const useInterval = async (callback: Function, delay?: number | null) => {
  const savedCallback = useRef<Function>(() => {});
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => savedCallback.current(), delay || 0);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [delay]);
};

export default useInterval;
