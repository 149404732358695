import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBarLineSearchByYear from '_components/charts/LFCChartsBarLineSearchByYear';
import LFCChartsLineMultiSearchByYear from '_components/charts/LFCChartsLineMultiSearchByYear';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {LOCATIONID_LR_SEARCH_BY_YEAR_MAIN, PAGEID_LR_SEARCH_BY_YEAR} from '_logics/LFCPageId';
import {distinct, getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import PieChartSearchByYear from './search-chart/PieChartSearchByYear';
import {useTranslation} from 'react-i18next';

const SearchByYearPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [listData_90023, setListData_90023] = useState([]);
  const [listData_90024, setListData_90024] = useState([]);
  const [listData_90030, setListData_90030] = useState([]);

  const [state90024, setState90024] = useState([]);

  let month_list: any = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  let from_day: any = new Date();
  const initialSearchValue = {
    select_year: from_day.getFullYear(),
    work: []
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LR_SEARCH_BY_YEAR,
    LOCATIONID_LR_SEARCH_BY_YEAR_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  let tmp_year_list: any = [];
  for (let i = 0; i < 100; i++) {
    let tmp: any = new Date(from_day);
    tmp.setFullYear(tmp.getFullYear() - i);
    tmp_year_list.push({name: tmp.getFullYear(), value: tmp.getFullYear()});
  }

  const selectList = tmp_year_list;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = () => {
    apiFetch();
  };

  const apiFetch = async () => {
    await setStartProcess(true);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90024,
      parameters: {
        year: String(searchValue.select_year),
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setState90024,
      name: `LROBOT:${t('年間集計')}:${t('年間集計')}_${t('実績')}:${t('表')}`,
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90030,
      parameters: {
        year: String(searchValue.select_year),
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setListData_90030,
      name: `LROBOT:${t('年間集計')}:${t('生産数と不良率')}:${t('生産数')}`,
      cancelToken: source.token,
      t
    });

    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90023,
      parameters: {
        year: String(searchValue.select_year)
      },
      ds_state: setListData_90023,
      name: `LROBOT:${t('年間集計')}:${t('年間集計')}_${t('実績')}`,
      cancelToken: source.token,
      t
    });
    setStartProcess(false);
  };

  useEffect(() => {
    setListData_90024(state90024.reverse());
  }, [state90024]);

  let pie_data90023_column = {
    year: t('年'),
    name: t('機種'),
    value: t('実績数')
  };

  let listData_90030_column = {
    year: t('年'),
    month: t('月'),
    生産数: t('生産数'),
    不良数: t('不良数'),
    不良率: t('不良率')
  };

  const [showData_90025, setShowData_90025] = useState<any>([]);
  const [showData_90027, setShowData_90027] = useState<any>([]);
  const [showData_90028, setShowData_90028] = useState<any>([]);
  const [showData_90029, setShowData_90029] = useState<any>([]);
  useEffect(() => {
    if (listData_90030.length > 0) {
      let tmp_data: any = [];
      month_list.forEach((tmp_month_string: string, index: number) => {
        // tmp_data[index] = [];
        tmp_data.push({
          month: tmp_month_string,
          不良率:
            listData_90030.filter(
              (item: any) =>
                tmp_month_string === item.month && searchValue.select_year === item.year
            ).length > 0
              ? listData_90030.filter(
                  (item: any) =>
                    tmp_month_string === item.month && searchValue.select_year === item.year
                )[0]['不良率']
              : null
        });
      });
      setShowData_90025(tmp_data);

      let tmp_year: any = distinct(listData_90030, 'year');
      tmp_data = [];
      tmp_year.forEach((tmp_year_string: string, index: number) => {
        tmp_data[index] = [];
        month_list.forEach((tmp_month_string: string) => {
          // tmp_data[tmp_year_string].push()
          tmp_data[index].push(
            listData_90030.filter(
              (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
            ).length > 0
              ? listData_90030.filter(
                  (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
                )[0]['生産数']
              : null
          );
        });
      });
      setShowData_90027(
        tmp_year.map((item: string, index: number) => ({
          name: tmp_year[index],
          type: 'line',
          stack: false,
          data: tmp_data[index]
        }))
      );

      tmp_data = [];
      tmp_year.forEach((tmp_year_string: string, index: number) => {
        tmp_data[index] = [];
        month_list.forEach((tmp_month_string: string) => {
          tmp_data[index].push(
            listData_90030.filter(
              (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
            ).length > 0
              ? listData_90030.filter(
                  (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
                )[0]['不良率']
              : null
          );
        });
      });
      setShowData_90028(
        tmp_year.map((item: string, index: number) => ({
          name: tmp_year[index],
          type: 'line',
          stack: false,
          data: tmp_data[index]
        }))
      );

      tmp_data = [];
      tmp_year.forEach((tmp_year_string: string, index: number) => {
        tmp_data[index] = [];
        month_list.forEach((tmp_month_string: string) => {
          tmp_data[index].push(
            listData_90030.filter(
              (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
            ).length > 0
              ? listData_90030.filter(
                  (item: any) => tmp_month_string === item.month && tmp_year_string === item.year
                )[0]['平均サイクルタイム']
              : null
          );
        });
      });
      setShowData_90029(
        tmp_year.map((item: string, index: number) => ({
          name: tmp_year[index],
          type: 'line',
          stack: false,
          data: tmp_data[index]
        }))
      );
    }
  }, [listData_90030]);

  const graphClick = (data: any) => {
    setSearchValue({...searchValue, work: [data.name]});
  };

  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={`L-ROBOT:${t('menu.page_name.年間集計')}`}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <LFCSelectForm
            name={'select_year'}
            label={t('年')}
            value={searchValue.select_year}
            id={'sl1'}
            onChange={handleChange}
            selectItem={selectList}
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LR_SEARCH_BY_YEAR}
            locationNo={LOCATIONID_LR_SEARCH_BY_YEAR_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4}>
            <PieChartSearchByYear
              data={listData_90023}
              name={t('生産数')}
              exportData={listData_90023}
              exportFields={pie_data90023_column}
              exportFilename={t('年間集積_生産数')}
              onClick={graphClick}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            {listData_90024.length > 0 ? (
              <>
                {t('今期')}
                <LFCSingleValues
                  columns={[
                    {
                      field: '生産数',
                      headerName: t('生産数'),
                      width: '100px',
                      unit: `${t('個')}`,
                      formatter: toLocalStringEx
                    },
                    {
                      field: '良品数',
                      headerName: t('良品'),
                      width: '100px',
                      unit: `${t('個')}`,
                      formatter: toLocalStringEx
                    },
                    {
                      field: '不良数',
                      headerName: t('不良品'),
                      width: '100px',
                      unit: `${t('個')}`,
                      formatter: toLocalStringEx
                    },
                    {
                      field: '不良率',
                      headerName: `${t('不良率')}`,
                      width: '100px',
                      unit: ' %',
                      formatter: toLocalStringEx
                    },
                    {
                      field: '平均サイクルタイム',
                      headerName: t('平均生産間隔'),
                      width: '100px',
                      unit: `${t('秒')}`,
                      formatter: toLocalStringEx
                    }
                  ]}
                  source={listData_90024[0]}
                />
                {listData_90024[1] !== undefined ? (
                  <>
                    {t('前期')}
                    <LFCSingleValues
                      columns={[
                        {
                          field: '生産数',
                          headerName: t('生産数'),
                          width: '100px',
                          unit: `${t('個')}`,
                          formatter: toLocalStringEx
                        },
                        {
                          field: '良品数',
                          headerName: t('良品'),
                          width: '100px',
                          unit: `${t('個')}`,
                          formatter: toLocalStringEx
                        },
                        {
                          field: '不良数',
                          headerName: t('不良品'),
                          width: '100px',
                          unit: `${t('個')}`,
                          formatter: toLocalStringEx
                        },
                        {
                          field: '不良率',
                          headerName: `${t('不良率')}`,
                          width: '100px',
                          unit: ' %',
                          formatter: toLocalStringEx
                        },
                        {
                          field: '平均サイクルタイム',
                          headerName: t('平均生産間隔'),
                          width: '100px',
                          unit: `${t('秒')}`,
                          formatter: toLocalStringEx
                        }
                      ]}
                      source={listData_90024[1]}
                    />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <LFCChartsBarLineSearchByYear
              title={t('生産数と不良率')}
              source={listData_90030.filter((item: any) => item.year === searchValue.select_year)}
              x={{type: 'category', dsColumn: 'month'}}
              y={{type: 'value', dsColumn: '生産数'}}
              xData={month_list}
              lineData={showData_90025}
              stacked={true}
              // series="month"
              yAxisName={t('生産数')}
              exportData={listData_90030.filter(
                (item: any) => item.year === searchValue.select_year
              )}
              exportFields={listData_90030_column}
              exportFilename={`${t('年間集計')}・${t('生産数と不良率')}`}
            />
          </Grid>

          <Grid item xs={12} md={6} xl={4}>
            <LFCChartsLineMultiSearchByYear
              title={t('生産数推移')}
              source={showData_90027}
              xData={month_list}
              color={['rgb(106,113,255)']}
              exportData={listData_90030}
              exportFields={listData_90030_column}
              exportFilename={`${t('年間集計')}・${t('生産数推移')}`}
              yAxisName={`${t('生産数')}(${t('個')})`}
              grid={{top: 60, bottom: 55, left: 80, right: 20}}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LFCChartsLineMultiSearchByYear
              title={t('不良率推移')}
              source={showData_90028}
              xData={month_list}
              color={['rgb(106,113,255)']}
              exportData={listData_90030}
              exportFields={listData_90030_column}
              exportFilename={`${t('年間集計')}・${t('不良率推移')}`}
              yAxisName={`${t('不良率')}(%)`}
              grid={{top: 60, bottom: 55, left: 80, right: 20}}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={4}>
            <LFCChartsLineMultiSearchByYear
              title={t('平均生産間隔推移')}
              source={showData_90029}
              xData={month_list}
              color={['rgb(106,113,255)']}
              exportData={listData_90030}
              exportFields={listData_90030_column}
              exportFilename={`${t('年間集計')}・${t('平均生産間隔推移')}`}
              yAxisName={`${t('平均生産間隔')}(${t('秒')})`}
              grid={{top: 60, bottom: 55, left: 80, right: 20}}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default SearchByYearPage;
