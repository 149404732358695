import {Grid} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid-pro';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import ProgressBar from '_components/ProgressBar';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

const SystemLogPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [searchValue, setSearchValue] = useState({
    select_date:
      new Date().getFullYear() +
      '-' +
      ('0' + (new Date().getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + new Date().getDate()).slice(-2),
    log_level: 0,
    job: 0,
    message: ''
  });
  const [logLevel, setLogLevel] = useState<any>([]);
  const [logLevelList, setLogLevelList] = useState<any>([]);
  const [job, setJob] = useState<any>([]);
  const [jobList, setJobList] = useState<any>([]);
  let dgColumns: GridColDef[] = [
    {
      field: 'seq',
      headerName: 'seq',
      description: 'seq',
      // width: 100,
      hide: false
    },
    {
      field: 'device',
      headerName: 'device',
      description: 'device',
      // width: 150,
      hide: false
    },
    {
      field: 'lq_time',
      headerName: 'lq_time',
      description: 'lq_time',
      // width: 150,
      hide: false
    },
    {
      field: 'log_level',
      headerName: 'log_level',
      description: 'log_level',
      // width: 150,
      hide: false
    },
    {
      field: 'err_code',
      headerName: 'err_code',
      description: 'err_code',
      // width: 150,
      hide: false
    },
    {
      field: 'job_name',
      headerName: 'job_name',
      description: 'job_name',
      // width: 150,
      hide: false
    },
    {
      field: 'message',
      headerName: 'message',
      description: 'message',
      // width: 300,
      hide: false
    }
  ];
  const [dsRows, setDsRows] = useState([]);

  let COLUMN_ORDER = [{name: 'seq', order: 1}];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    apiFetch();
    getLogList().then(() => true);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const apiFetch = async () => {
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60001,
      parameters: {},
      ds_state: setLogLevel,
      name: `LROBOT_${t('システムログ')}_${t('ログレベル')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60002,
      parameters: {},
      ds_state: setJob,
      name: `LROBOT_${t('システムログ')}_${t('ジョブ')}`,
      cancelToken: source.token,
      t
    });
  };

  useEffect(() => {
    let tmp: any = [{name: t('すべて'), value: 0}];
    setLogLevelList(tmp.concat(logLevel));
  }, [logLevel]);

  useEffect(() => {
    let tmp: any = [{name: t('すべて'), value: 0}];
    setJobList(tmp.concat(job));
  }, [job]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    setStartProcess(true);
    getLogList();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    getLogList();
  };

  const getLogList = async () => {
    if (searchValue.select_date !== '') {
      setDsRows([]);
      await getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60003,
        parameters: {
          lq_time_from: searchValue.select_date + ' 00:00:00',
          lq_time_to: searchValue.select_date + ' 23:59:59',
          log_level: searchValue.log_level !== 0 ? searchValue.log_level : null,
          job_name: searchValue.job !== 0 ? searchValue.job : null,
          message: `%${searchValue.message}%`
        },
        ds_state: setDsRows,
        name: `LROBOT_${t('システムログ')}`,
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  };

  return (
    <GenericTemplate title={`L-ROBOT:${t('システムログ')}`}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <form ref={formRef}>
            <LFCTitledCard title={t('条件')}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {/* データのある日付 <br />
                  2021-12-20 <br />
                  2022-01-06 <br />
                  2022-01-07 <br />
                  2022-2-24 <br /> */}
                  <LFCDatePicker
                    name="select_date"
                    label={t('日付')}
                    value={searchValue.select_date}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCSelectForm
                    name={'log_level'}
                    label={t('ログレベル')}
                    value={searchValue.log_level}
                    id={'sl1'}
                    onChange={handleChange}
                    selectItem={logLevelList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCSelectForm
                    name={'job'}
                    label={t('ジョブ')}
                    value={searchValue.job}
                    id={'sl1'}
                    onChange={handleChange}
                    selectItem={jobList}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="message"
                    label={t('メッセージ')}
                    value={searchValue.message}
                    onChange={event => handleInputChange(event, searchValue, setSearchValue)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCButton color="primary" onClick={() => doSearch()} fullWidth>
                    {t('検索')}
                  </LFCButton>
                </Grid>
              </Grid>
            </LFCTitledCard>
          </form>
        </Grid>
        <Grid item xs={12} md={9}>
          <LFCDataGrid
            columns={dgColumns}
            rows={dsRows}
            height="80vh"
            columnOrder={COLUMN_ORDER}
            exportFilename={`${t('システムログ_')}`}
          />
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default SystemLogPage;
