import {Dialog, DialogContent, Grid} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import {makeStyles} from 'tss-react/mui';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {green} from '@mui/material/colors';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  selectData: any;
  selectUser?: any;
  selectSerial?: string;
}

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    }
  };
});

const DefectiveProcedureDetailDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  interface SEARCHVALUE {
    defectType: string | number;
    defectCauseType: string | number;
    memo: string;
  }

  const [searchValue, setSearchValue] = useState<SEARCHVALUE>({
    defectType: '',
    defectCauseType: '',
    memo: ''
  });
  const [tmpDefectType, setTmpDefectType] = useState<any>([]);
  const [tmpDefectCauseType, setTmpDefectCauseType] = useState<any>([]);
  const [selectDefectType, setSelectDefectType] = useState<any>([]);
  const [selectDefectCauseType, setSelectDefectCauseType] = useState<any>([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50027,
      parameters: {},
      ds_state: setTmpDefectType,
      name: `POC_${t('不良処置')}_${t('不良内容')}_${t('プルダウン')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50028,
      parameters: {},
      ds_state: setTmpDefectCauseType,
      name: `POC_${t('不良処置')}_${t('発生形態')}_${t('プルダウン')}`,
      cancelToken: source.token,
      t
    });
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    let tmp: any = tmpDefectType.map((item: any) => {
      return {name: item['defect_type_name'], value: item['defect_type']};
    });
    tmp.unshift({name: '選択してください', value: ''});
    setSelectDefectType(tmp);
  }, [tmpDefectType]);

  useEffect(() => {
    let tmp: any = tmpDefectCauseType.map((item: any) => {
      return {name: item['defect_cause_type_name'], value: item['defect_cause_type']};
    });
    tmp.unshift({name: '選択してください', value: ''});
    setSelectDefectCauseType(tmp);
  }, [tmpDefectCauseType]);

  useEffect(() => {
    setSearchValue({
      defectType: '',
      defectCauseType: '',
      memo: ''
    });
  }, [props.open]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const newSave = async () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    if (
      searchValue.defectType !== '' &&
      searchValue.defectCauseType !== '' &&
      props.selectUser.id !== ''
    ) {
      setStartProcess(true);
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 50029,
        parameters: {
          uuid: props.selectData.uuid,
          map: props.selectData.map,
          p_user_id: props.selectUser.id,
          defect_type: searchValue.defectType,
          defect_cause_type: searchValue.defectCauseType,
          memo: searchValue.memo,
          update_time: new Date()
        },
        name: `POC_${t('不良処置')}_${t('登録')}`,
        cancelToken: source.token,
        t
      }).then(() => successSave());
    } else {
      enqueueSnackbar(t('message.全ての値を入力してください。'), {variant: 'error'});
    }
    setStartProcess(false);
  };

  const successSave = () => {
    props.onClose();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <ProgressBar startProcess={startProcess} />
      <LFCDialogTitle onClose={props.onClose}>{t('処置記入フォーム')}</LFCDialogTitle>
      <DialogContent>
        <LFCSingleValue title={t('刻印No')} val={props.selectSerial} fontColor={green[500]} />
        <LFCSingleValue title={t('検査部位')} val={props.selectData.map} fontColor={green[500]} />
        <LFCSingleValue title={t('処置者')} val={props.selectUser.name} fontColor={green[500]} />

        <form className={classes.search} ref={formRef}>
          <LFCFormRowGroup>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <LFCSelectForm
                  name={'defectType'}
                  label={t('不良内容')}
                  value={searchValue.defectType}
                  id={'sl1'}
                  onChange={handleChange}
                  selectItem={selectDefectType}
                  required
                  style={{marginRight: '8px'}}
                />
                <LFCSelectForm
                  name={'defectCauseType'}
                  label={t('発生形態')}
                  value={searchValue.defectCauseType}
                  id={'sl2'}
                  onChange={handleChange}
                  selectItem={selectDefectCauseType}
                  required
                  style={{marginRight: '8px'}}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LFCTextField
                  name={'memo'}
                  label={t('備考')}
                  value={searchValue.memo}
                  fullWidth={true}
                  multiline={true}
                  rows={10}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LFCButton onClick={() => newSave()}>{t('登録')}</LFCButton>
              </Grid>
            </Grid>
          </LFCFormRowGroup>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default DefectiveProcedureDetailDig;
