import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any | {};
  xData?: any;
  yAxisName?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  onClick?: (prm: {data: any}) => void;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  showCP?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineMultiSearchByYear = (props: Props) => {
  const {t} = useTranslation();
  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          left: props.grid !== undefined ? props.grid.left : '8%',
          right: props.grid !== undefined ? props.grid.right : '5%',
          bottom: props.grid !== undefined ? props.grid.bottom : '20%',
          top: props.grid !== undefined ? props.grid.top : '15%'
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 11,
          textStyle: {
            fontSize: 10
          },
          icon: 'circle'
          // data: props.source,
        },
        tooltip: {
          trigger: 'axis',
          position: (point: any, params: any, dom: any, rect: any, size: any) => {
            // fixed at top
            return [point[0] + 20, '10%'];
          },
          formatter: (prm: any) => {
            let tmp: any;
            tmp = t('計測日時') + ':' + prm[0].axisValue + '<br/>';
            prm.forEach((a: any) => {
              if (a.value !== undefined) {
                tmp += a.marker + t(a.seriesName) + ':' + Number(a.value).toFixed(3) + '<br/>';
              } else {
                tmp += a.marker + t(a.seriesName) + ':' + t('データなし') + '<br/>';
              }
            });
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
            // minValueSpan: 3600 * 24 * 1000 * 7, // 最小ZOOM 7日分
          }
        ],
        color: props.color ? props.color : null,
        xAxis: {
          type: 'category',
          data: props?.xData !== null ? props?.xData : null,
          // data: null,
          axisLabel: {
            // interval: 0,
            rotate: '15',
            fontSize: 10
          }
          // splitLine: {
          //   show: true,
          // },
        },
        dataset: {
          source: props?.source
        },
        yAxis: [
          {
            type: 'value',
            name: props?.yAxisName !== undefined ? props?.yAxisName : '',
            nameLocation: 'middle',
            nameGap: 45,
            nameTextStyle: {
              // align: 'left',
              fontSize: 10
            },
            position: 'left',
            // max: x_max,
            // nameTextStyle: {
            //   fontSize: 10,
            // },
            axisLabel: {
              // interval: 0,
              fontSize: 10
            }
          }
        ],
        series: [props?.source?.[0] ?? [], props?.source?.[1] ?? []]
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height !== undefined ? props.height : '300px'}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
    />
  );
};
export default LFCChartsLineMultiSearchByYear;
