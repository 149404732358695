import {FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import awsConfiguration from 'awsConfiguration';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useAuthUser, useRegetAuthUser} from '_contexts/AuthUserProvider';
import {useMySettings, useSaveSetMySetting} from '_contexts/MySettingsProvider';
import {adminAPIAccess, handleInputChange} from '_logics/LFCUtil';

/**
 * 個人設定画面
 * @returns
 */
const LFCMySettingPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const mySettings = useMySettings();
  const authUser = useAuthUser();
  const regetAuthUser = useRegetAuthUser();
  const saveMySetting = useSaveSetMySetting();
  const formEmailRef = useRef<HTMLFormElement>(null!);

  const [values, setValues] = useState({
    emailAddr: '',
    nowPass: '',
    newPass1: '',
    newPass2: ''
  });

  /**
   * テーマ切替
   * @param isOpen
   */
  const themeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    saveMySetting({...mySettings, isDark: target.value === true.toString()});
  };

  /**
   * locale切替
   * @param isOpen
   */
  const localeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    saveMySetting({...mySettings, locale: target.value});
  };

  /**
   * Email保存
   * @param isOpen
   */
  const saveEmail = () => {
    // input check
    if (!formEmailRef.current?.reportValidity()) {
      return;
    }

    // cognitoに登録
    adminAPIAccess({
      apipath: 'UpdateUserAttributes',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        user_attributes: [
          new CognitoUserAttribute({
            Name: 'email',
            Value: values.emailAddr
          }),
          new CognitoUserAttribute({
            Name: 'email_verified',
            Value: 'true'
          })
        ]
      }
    })
      .then(response => {
        regetAuthUser();
        enqueueSnackbar(t('message.更新しました'), {variant: 'success'});
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(`${t('message.更新失敗しました')}:${error}`, {
            variant: 'error',
            persist: true
          });
        }
      });
  };

  useEffect(() => {
    setValues({...values, emailAddr: authUser?.email ?? ''});
  }, [authUser]);

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <LFCTitledCard title={t('テーマ')} transparent>
            <RadioGroup name="theme" value={mySettings.isDark} onChange={themeChange} row>
              <FormControlLabel value={false} control={<Radio />} label="Light" />
              <FormControlLabel value={true} control={<Radio />} label="Dark" />
            </RadioGroup>
          </LFCTitledCard>
        </Grid>
        <Grid item xs={12}>
          <LFCTitledCard title={t('表示言語')} transparent>
            <RadioGroup
              name="locale"
              value={mySettings.locale?.toLowerCase() || 'ja'}
              onChange={localeChange}
              row
            >
              <FormControlLabel value={'ja'} control={<Radio />} label="日本語" />
              <FormControlLabel value={'en'} control={<Radio />} label="English" />
            </RadioGroup>
          </LFCTitledCard>
        </Grid>
        <Grid item xs={12}>
          <LFCTitledCard title={t('ユーザ情報')} transparent>
            <form ref={formEmailRef}>
              <LFCFormRowGroup>
                <LFCTextField
                  name="emailAddr"
                  label="Email"
                  value={values.emailAddr}
                  onChange={event => {
                    handleInputChange(event, values, setValues);
                  }}
                  other={{
                    required: true,
                    inputProps: {
                      pattern: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
                    }
                  }}
                />
                <LFCButton color="primary" onClick={saveEmail}>
                  {t('更新')}
                </LFCButton>
              </LFCFormRowGroup>
            </form>
          </LFCTitledCard>
        </Grid>
      </Grid>
    </>
  );
};

export default LFCMySettingPage;
