import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  yAxis: {
    type: string;
    name: any;
    dsColumn: any;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  mapName?: string;
  onClick?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  zoomedChartId?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineHoleCompareCube = (props: Props) => {
  const {t} = useTranslation();
  if (props.source !== undefined) {
    return (
      <div style={{width: '100%'}}>
        <LFCBaseCharts
          option={{
            title: [
              {
                text: props.title
              }
            ],
            grid: props.grid
              ? props.grid
              : {
                  left: '8%',
                  right: '5%',
                  bottom: '22%',
                  top: '20%'
                },
            legend: {
              type: 'scroll',
              bottom: 0,
              itemWidth: 10,
              textStyle: {
                fontSize: 10
              }
            },
            tooltip: {
              trigger: 'axis',
              position: (point: any, params: any, dom: any, rect: any, size: any) => {
                // fixed at top
                return [point[0] + 20, '10%'];
              },
              formatter: (prm: any) => {
                let tmp: any;
                tmp =
                  t('計測日時') +
                  ':' +
                  prm[0].data[2] +
                  '<br/>' +
                  t('検査部位') +
                  ':' +
                  prm[0].data[1] +
                  '<br/>' +
                  prm[0].marker +
                  t('計測値') +
                  ':' +
                  Number(prm[0].data[4]).toFixed(3);
                return tmp;
              }
            },
            dataZoom: [
              {
                type: 'inside'
              }
            ],
            color: props.color ? props.color : null,
            dataset: {
              source: props.source
            },
            xAxis: [
              {
                type: 'category',
                axisLabel: {
                  // interval: 0,
                  rotate: '15',
                  fontSize: 10
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                name: props.yAxis.name,
                axisLabel: {
                  // interval: 0,
                  fontSize: 10
                }
              }
            ],
            series: [
              {
                type: props.yAxis.type,
                name: props.yAxis.name,
                xAxisIndex: 0,
                yAxisIndex: 0,
                encode: {
                  x: props.x.dsColumn,
                  y: props.yAxis.dsColumn
                }
              }
            ]
          }}
          exportData={props.exportData}
          exportFields={props.exportFields}
          exportFilename={props.exportFilename}
          onEvents={{
            click: (params: any) => {
              if (props.onClick != null) {
                params.mapName = props.mapName;
                // props.onClick(params.data[2]);
                props.onClick(props.source.serial[params.dataIndex]);
              }
            }
          }}
          zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
          zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
          zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
          zoomStatus={props.zoomChartId === props.zoomedChartId}
        />
      </div>
    );
  } else {
    return <></>;
  }
};
export default LFCChartsLineHoleCompareCube;
