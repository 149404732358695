import {Grid} from '@mui/material';
import {green, red} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBar from '_components/charts/LFCChartsBar';
import LFCChartsComboYMD from '_components/charts/LFCChartsComboYMD';
import ProgressBar from '_components/ProgressBar';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

const UsageAnalysisPage: React.FC = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [responseData001, setData001] = useState([
    {
      count: ''
    }
  ]);
  const [responseData002, setData002] = useState([
    {
      count: ''
    }
  ]);
  const [responseData003, setData003] = useState<any>([]);
  const [responseData003_status, setData003_status] = useState<string>(
    String(new Date().getTime())
  );
  const [responseData003_list, setResponseData003_list] = useState<any>([]);
  const [responseData004, setData004] = useState([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  //ページ読み込み完了に実行
  useEffect(() => {
    apiFetch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    graphDataEdit();
  }, [responseData003_status]);

  const apiFetch = async () => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 7,
      parameters: {},
      ds_state: setData001,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 8,
      parameters: {},
      ds_state: setData002,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 10,
      parameters: {period: '30'},
      ds_state: setData004,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 105,
      parameters: {},
      ds_state: setData003,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    }).then(() => {
      setData003_status(String(new Date().getTime()));
    });
    setStartProcess(false);
  };

  type LIST_FORMAT = {
    lq_time: string;
    judge: number;
  };

  const graphDataEdit = () => {
    // 現在時間から48時間前までのリストを作成
    let time_list: any = [];
    for (let i = 0; i < 48; i++) {
      let data_now = new Date();
      let dateTime = new Date(data_now.setHours(data_now.getHours() - i));
      time_list.push(
        dateTime.getFullYear().toString() +
          '/' +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          dateTime.getDate().toString().padStart(2, '0') +
          ' ' +
          dateTime.getHours().toString().padStart(2, '0') +
          ':00'
      );
    }
    time_list.sort();

    //APIデータを日付ごとにまとめる
    let edit_list: any = [];
    responseData003.map((data: any) => {
      let seikeigo = {};
      let lq_tmp = new Date(data.lq_time);
      let tmp_date =
        lq_tmp.getFullYear().toString() +
        '/' +
        (lq_tmp.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        lq_tmp.getDate().toString().padStart(2, '0') +
        ' ' +
        lq_tmp.getHours().toString().padStart(2, '0') +
        ':00';
      seikeigo = {
        tmp_date: tmp_date,
        lq_time: data.lq_time,
        judge: data.judge
      };
      edit_list.push(seikeigo);
    });

    //整形後のデータで同一時間の検査結果のjudgeの値を合計する。
    let judge_sum = 0;
    let show_list_record: LIST_FORMAT = {
      lq_time: '',
      judge: 0
    };
    let show_list: any = [];
    time_list.forEach((time_one: string) => {
      let tmp_find = edit_list.filter((item: any) => item.tmp_date === time_one);
      judge_sum = tmp_find.reduce(function (sum: number, judge: any) {
        return sum + judge.judge;
      }, 0);
      show_list_record = {
        lq_time: time_one,
        judge: judge_sum
      };
      show_list.push(show_list_record);
    });
    setResponseData003_list(show_list);
  };

  let responseData003_list_column = {
    lq_time: 'lq_time',
    judge: 'judge'
  };

  let responseData004_column = {
    lq_time: 'lq_time',
    total_count: 'total_count',
    ng_par: 'ng_par',
    ng: 'ng'
  };

  let responseData_count: any = [
    {total_count: responseData001[0].count, ng_count: responseData002[0].count}
  ];

  return (
    <GenericTemplate title={`${t('menu.page_name.利用状況')}`}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <LFCChartsBar
            title={`${t('本日')}`}
            source={responseData003_list}
            x={{type: 'category', dsColumn: 'lq_time'}}
            y={{type: 'value', dsColumn: 'judge'}}
            exportData={responseData003_list}
            exportFields={responseData003_list_column}
            exportFilename={`${t('利用状況')}・${t('本日')}`}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <LFCOkNgPie source={responseData_count[0]} />
        </Grid>
        <Grid item xs={12} md={9}>
          <LFCChartsComboYMD
            title={`${t('実績数と不良率')}`}
            source={responseData004}
            x={{startDate: dayjs().add(-30, 'd'), endDate: dayjs()}}
            yLeft={{
              type: 'bar',
              name: `${t('実績数')}`,
              dsColumn: 'total_count'
            }}
            yRight={{
              type: 'line',
              name: `${t('不良率')}(%)`,
              dsColumn: 'ng_par',
              markPoint: {
                data: [
                  {type: 'min', itemStyle: {color: '#E91E63'}},
                  {type: 'max', itemStyle: {color: '#2196F3'}}
                ]
              },
              markLine: {
                symbol: 'arrow',
                data: [
                  {
                    type: 'average',
                    label: {position: 'end', formatter: 'Ave. {c}', backgroundColor: '#FFEB3B'}
                  }
                ]
              }
            }}
            color={[green[500], red[500]]}
            height="40vh"
            exportData={responseData004}
            exportFields={responseData004_column}
            exportFilename={`${t('実績数と不良率')}`}
          />
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default UsageAnalysisPage;
