import {FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import {green} from '@mui/material/colors';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValue from '_components/surfaces/LFCSingleValue';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import SurfaceCompareDig from './SurfaceCompareDig';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import {ALL} from '_logics/LFCConst';
import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {
  PAGEID_LQ_JOBANALYSIS,
  LOCATIONID_LQ_JOBANALYSIS_SURFACECOMPARE_NEW
} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCChartsLineSurfaceCompareDot from '_components/charts/LFCChartsLineSurfaceCompareDot';
import LFCChartsBoxSC1 from '_components/charts/LFCChartsBoxSC1';
import {useTranslation} from 'react-i18next';

const SurfaceComparePage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [data217, setData217] = useState<any>([]);
  const [data218, setData218] = useState<any>([]);

  const [map_series, setMap_series] = useState<any>([]);
  const [branch_no, setBranch_no] = useState<any>([]);
  const [graphX_list, setGraphX_list] = useState<any>([]);

  const [graphBaseData, setGraphBaseData] = useState([]);

  const [selectData, setSelectData] = useState<any>([]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  /**
   * 検索条件初期値
   */
  const initialSearchValue = {
    select_date_from: dayjs().add(-7, 'd').format('YYYY-MM-DD HH:mm:ss'),
    select_date_to: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    judge: 9,
    work: [],
    deviceid: [],
    map: null,
    searchType: 2
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LQ_JOBANALYSIS,
    LOCATIONID_LQ_JOBANALYSIS_SURFACECOMPARE_NEW
  );
  const [searchValue, setSearchValue] = useState(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  /**
   * リセット処理
   */
  const restSearch = () => {
    setSearchValue(initialSearchValue);
    setData217([]);
    setData218([]);
    setAutoCompleteReset(true);
  };

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const onChangeRadio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setData217([]);
    setData218([]);
  };

  useEffect(() => {
    setData218([]);
    setGraphBaseData([]);
  }, [searchValue.searchType]);

  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    const searchPrams: object = {
      select_date_from: searchValue.select_date_from,
      select_date_to: searchValue.select_date_to,
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      map: null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      judge: searchValue.judge !== 9 ? searchValue.judge : null
    };
    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 217,
        parameters: searchPrams,
        ds_state: setData217,
        name: `${t('検索結果')}1`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 218,
        parameters: searchPrams,
        ds_state: setData218,
        name: `${t('検索結果')}2`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  const date_calc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return Array.from(new Set(data217.map((item: any) => String(item['idx']))));
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const searchTypeValue: any = [
    {
      key: 0,
      value: 'idx'
    },
    {
      key: 1,
      value: 'year_month_day_hour'
    },
    {
      key: 2,
      value: 'year_month_day'
    },
    {
      key: 3,
      value: 'year_month'
    }
  ];

  useEffect(() => {
    setMap_series(Array.from(new Set(data217.map((item: any) => item.map))).sort());
    setBranch_no(
      Array.from(new Set(data217.map((item: any) => Number(item.branch_no)))).sort(
        (a: any, b: any) => {
          return a - b;
        }
      )
    );
    setGraphX_list(date_calc());
  }, [data217]);

  useEffect(() => {
    let tmp_all: any = [];
    if (searchValue.searchType !== 0) {
      map_series.forEach((map_string: string) => {
        branch_no.forEach((branch_no_number: number) => {
          graphX_list.forEach((graphX_list_string: string) => {
            if (
              !isEmpty(
                data217.filter(
                  (a: any) =>
                    a.map === map_string &&
                    a.branch_no === branch_no_number &&
                    a[
                      searchTypeValue.filter((x: any) => x.key === searchValue.searchType)[0].value
                    ] === graphX_list_string
                )
              )
            ) {
              tmp_all.push({
                map: map_string,
                branch_no: branch_no_number,
                x_list: graphX_list_string,
                data_value: data217
                  .filter(
                    (a: any) =>
                      a.map === map_string &&
                      a.branch_no === branch_no_number &&
                      a[
                        searchTypeValue.filter((x: any) => x.key === searchValue.searchType)[0]
                          .value
                      ] === graphX_list_string
                  )
                  .map((b: any) => Number(b.min_ratio))
              });
            }
          });
        });
      });
    } else {
      map_series.forEach((map_string: string) => {
        branch_no.forEach((branch_no_number: number) => {
          if (
            !isEmpty(
              data217.filter((a: any) => a.map === map_string && a.branch_no === branch_no_number)
            )
          ) {
            tmp_all.push({
              map: map_string,
              branch_no: branch_no_number,
              data_value: data217.filter(
                (a: any) => a.map === map_string && a.branch_no === branch_no_number
              )
            });
          }
        });
      });
    }
    setGraphBaseData(tmp_all);
  }, [graphX_list]);

  const graphClick1 = (data: any) => {
    setSelectData({
      mapName: data.mapName
    });
    setOpen(true);
  };

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [condition, setCondition] = useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel:
          searchValue.select_date_from === ''
            ? ''
            : dayjs(searchValue.select_date_from).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel:
          searchValue.select_date_to === ''
            ? ''
            : dayjs(searchValue.select_date_to).format('YYYY-MM-DD HH:mm:ss'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work,
        colName: t('機種'),
        colWidth: 200
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid,
        colName: t('検査装置'),
        colWidth: 200
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge === 0 ? 'OK' : 'NG',
        colName: t('総合判定'),
        colWidth: 100
      },
      {
        name: 'searchType',
        value: searchValue.searchType,
        valueLabel:
          searchValue.searchType === 0
            ? t('検査ごと')
            : searchValue.searchType === 1
              ? t('1時間ごと')
              : searchValue.searchType === 2
                ? t('1日ごと')
                : searchValue.searchType === 3
                  ? t('1ヶ月ごと')
                  : '',
        colName: t('集計単位'),
        colWidth: 100
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    // マウント時処理
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42008,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42007,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid]);

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.L-Qジョブ傾向分析')}：${t('menu.page_name.Surface Compare')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name="select_date_from"
            label={t('日付From')}
            value={searchValue.select_date_from}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCDatetimePicker
            name="select_date_to"
            label={t('日付To')}
            value={searchValue.select_date_to}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small" // small/medium
            value={multiSelectData(work, searchValue.work)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCAutocomplete
            name={'deviceid'}
            label={t('検査装置')}
            id={'deviceid'}
            size="small" // small/medium
            value={multiSelectData(deviceid, searchValue.deviceid)}
            // style={{width: 500}}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={deviceid}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_LQ_JOBANALYSIS}
            locationNo={LOCATIONID_LQ_JOBANALYSIS_SURFACECOMPARE_NEW}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>

      <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
      <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
        {searchType.map((item: any) => (
          <FormControlLabel
            value={item.id}
            key={'search_type-' + item.id}
            control={
              <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
            }
            label={
              <Typography display="block" key={'search_type-body-' + item.id}>
                {item.text}
              </Typography>
            }
          />
        ))}
      </RadioGroup>

      {map_series.map((item: any, index: number) => {
        return (
          <Grid container spacing={2} key={index}>
            {graphBaseData.filter((data: any) => data.map === item) ? (
              <>
                {searchValue.searchType !== 0 ? (
                  <Grid item xs={12} md={9} key={'g_' + index}>
                    <LFCChartsBoxSC1
                      title={`${t('部位名')}:` + item}
                      source={
                        graphBaseData.length > 0
                          ? graphBaseData.filter((data: any) => data.map === item)
                          : false
                      }
                      x={{dsColumn: '', type: 'category'}}
                      y={{dsColumn: '', type: 'value'}}
                      exportData={
                        data217.length > 0
                          ? data217.filter((data: any) => data.map === item)
                          : false
                      }
                      exportFields={{
                        work: t('機種'),
                        deviceid: t('検査装置'),
                        map: t('検査部位'),
                        lq_time: t('検査日時')
                      }}
                      searchType={searchValue.searchType}
                      exportFilename={'SurfaceCompare_' + item}
                      onClick={graphClick1}
                      height={'30vh'}
                      top={'55'}
                      bottom={'30%'}
                      mapName={item}
                      xlist={date_calc()}
                      zoomChartId={'zoom-chart-' + index}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-' + index)}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={9} key={'g_' + item}>
                    <LFCChartsLineSurfaceCompareDot
                      title={`${t('部位名')}:` + item}
                      source={graphBaseData.filter((data: any) => data.map === item)}
                      x={{dsColumn: ''}}
                      mapName={item}
                      exportData={data217}
                      exportFields={{
                        work: t('機種'),
                        deviceid: t('検査装置'),
                        map: t('検査部位'),
                        branch_no: t('検査部位追番'),
                        lq_time: t('検査日時'),
                        threshold: t('閾値'),
                        min_ratio: t('マッチング率(最小)'),
                        judge: t('判定'),
                        serial: t('シリアルナンバー')
                      }}
                      exportFilename={'SurfaceCompare_' + item}
                      height={'30vh'}
                      onClick={graphClick1}
                      zoomChartId={'zoom-chart-' + index}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-' + index)}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={3} key={'h_' + index}>
                  <LFCSingleValue
                    title={t('検査数')}
                    val={
                      isFinite(
                        Math.max(
                          ...data218.filter((b: any) => b.map === item).map((c: any) => c.all_count)
                        )
                      )
                        ? Math.max(
                            ...data218
                              .filter((b: any) => b.map === item)
                              .map((c: any) => c.all_count)
                          ).toLocaleString()
                        : ''
                    }
                    width={'200px'}
                    titleVariant="caption"
                    valVariant="body1"
                    key={t('検査数')}
                    fontColor={green[500]}
                  />
                  <LFCSingleValue
                    title={t('良品')}
                    val={
                      !isNaN(
                        Math.max(
                          ...data218.filter((b: any) => b.map === item).map((c: any) => c.all_count)
                        ) -
                          Math.max(
                            ...data218
                              .filter((b: any) => b.map === item)
                              .map((c: any) => c.ng_count)
                          )
                      )
                        ? (
                            Math.max(
                              ...data218
                                .filter((b: any) => b.map === item)
                                .map((c: any) => c.all_count)
                            ) -
                            Math.max(
                              ...data218
                                .filter((b: any) => b.map === item)
                                .map((c: any) => c.ng_count)
                            )
                          ).toLocaleString()
                        : ''
                    }
                    width={'200px'}
                    titleVariant="caption"
                    valVariant="body1"
                    key={t('良品')}
                    fontColor={green[500]}
                  />
                  <LFCSingleValue
                    title={t('不良品')}
                    val={
                      isFinite(
                        Math.max(
                          ...data218.filter((b: any) => b.map === item).map((c: any) => c.ng_count)
                        )
                      )
                        ? Math.max(
                            ...data218
                              .filter((b: any) => b.map === item)
                              .map((c: any) => c.ng_count)
                          ).toLocaleString()
                        : ''
                    }
                    width={'200px'}
                    titleVariant="caption"
                    valVariant="body1"
                    key={t('不良品')}
                    fontColor={green[500]}
                  />

                  <LFCSingleValue
                    title={t('不良率')}
                    val={
                      !isNaN(
                        Math.max(
                          ...data218.filter((b: any) => b.map === item).map((c: any) => c.ng_count)
                        ) /
                          Math.max(
                            ...data218
                              .filter((b: any) => b.map === item)
                              .map((c: any) => c.all_count)
                          )
                      )
                        ? (
                            Math.round(
                              (Math.max(
                                ...data218
                                  .filter((b: any) => b.map === item)
                                  .map((c: any) => c.ng_count)
                              ) /
                                Math.max(
                                  ...data218
                                    .filter((b: any) => b.map === item)
                                    .map((c: any) => c.all_count)
                                )) *
                                10000
                            ) / 100
                          ).toLocaleString() + '%'
                        : ''
                    }
                    width={'200px'}
                    titleVariant="caption"
                    valVariant="body1"
                    key={t('不良率')}
                    fontColor={green[500]}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        );
      })}

      <SurfaceCompareDig
        open={open}
        onClose={handleClose}
        mapName={selectData.mapName}
        selectDate={selectData.selectDate}
        searchValue={searchValue}
      />
    </GenericTemplate>
  );
};
export default SurfaceComparePage;
