import {Box, Tab, Tabs} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';

import MasterEditPanel from '_components/master-edit-panel/MasterEditPanel';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
      flexGrow: 1,
      display: 'flex'
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
      minWidth: '200px'
      // width:'200px'
    },
    table: {
      minWidth: 650
    },
    mgt20_padin10: {
      marginTop: 20,
      padding: 10
    },
    title: {
      fontWeight: 800,
      marginRight: 20
    },
    mgr5: {
      display: 'inline-flex',
      marginRight: 5
    }
  };
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
}

const CommonMasterPage: React.FC = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const {classes} = useStyles();
  const [startProcess, setStartProcess] = useState(true);

  const [tabidx, setTabidx] = React.useState(0);
  const tabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabidx(newValue);
  };

  const [listUpdate, setListUpdate] = useState(String(new Date().getTime()));
  const [editButtonStatus, setEditButtonStatus] = useState(false);
  const [m_setting, setM_setting] = useState([]);
  const [m_comp_method, setM_comp_method] = useState([]);
  const [m_job_type, setM_job_type] = useState([]);
  const [t_heart_beat, setT_heart_beat] = useState([]);
  let fields_m_setting = [
    {
      columnField: 'skey',
      columnName: 'skey',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: true
    },
    {
      columnField: 'svalue',
      columnName: 'svalue',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'memo',
      columnName: 'memo',
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true
    }
  ];
  let fields_m_comp_method = [
    {
      columnField: 'comp_method',
      columnName: 'comp_method',
      columnOrder: 0,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'comp_method_name',
      columnName: 'comp_method_name',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true
    }
  ];
  let fields_m_job_type = [
    {
      columnField: 'job_type',
      columnName: 'job_type',
      columnOrder: 0,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'job_type_name',
      columnName: 'job_type_name',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'job_type_alias',
      columnName: 'job_type_alias',
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'is_show',
      columnName: 'is_show',
      columnOrder: 3,
      hide: false,
      edit: true,
      add: true
    }
  ];
  let fields_t_heart_beat = [
    {
      columnField: 'device',
      columnName: 'device',
      columnOrder: 0,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'detection_time_from',
      columnName: 'detection_time_from',
      columnOrder: 1,
      hide: false,
      edit: true,
      add: true,
      dateType: true
    },
    {
      columnField: 'detection_time_to',
      columnName: 'detection_time_to',
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true,
      dateType: true
    },
    {
      columnField: 'status',
      columnName: 'status',
      columnOrder: 3,
      hide: false,
      edit: true,
      add: true
    },
    {
      columnField: 'message',
      columnName: 'message',
      columnOrder: 4,
      hide: false,
      edit: true,
      add: true
    }
  ];

  const [map_image_master, setMap_image_master] = useState([]);
  let fields_map_image_master = [
    {
      columnField: 'id',
      columnName: 'id',
      columnOrder: 0,
      hide: false,
      edit: false,
      add: false
    },
    {
      columnField: 'mapname',
      columnName: 'mapname',
      columnOrder: 1,
      hide: false,
      edit: false,
      add: false,
      dateType: false
    },
    {
      columnField: 'filename',
      columnName: 'filename',
      columnOrder: 2,
      hide: false,
      edit: true,
      add: true,
      dateType: false,
      fileType: true
    }
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setStartProcess(true);
    apiFetch().then(() => setStartProcess(false));
  }, [listUpdate]);

  const apiFetch = async () => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 109,
      parameters: {},
      ds_state: setM_setting,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 110,
      parameters: {},
      ds_state: setM_comp_method,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 111,
      parameters: {},
      ds_state: setM_job_type,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 112,
      parameters: {},
      ds_state: setT_heart_beat,
      name: `${t('検索結果')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 70001,
      parameters: {},
      ds_state: setMap_image_master,
      name: `${t('Mapマスタ画像')}`,
      cancelToken: source.token,
      t
    });
  };

  const thisSaveObject = async (id_key: string, editInput: any) => {
    setStartProcess(true);
    if (id_key.indexOf('m_setting') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 113,
        parameters: {
          skey: editInput.skey,
          svalue: editInput.svalue,
          memo: editInput.memo
        },
        name: `m_setting_${t('保存')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_comp_method_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 114,
        parameters: {
          comp_method: editInput.comp_method,
          comp_method_name: editInput.comp_method_name
        },
        name: `m_comp_method_${t('保存')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_job_type_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 115,
        parameters: {
          job_type: editInput.job_type,
          job_type_name: editInput.job_type_name,
          job_type_alias: editInput.job_type_alias,
          is_show: editInput.is_show
        },
        name: `m_job_type_${t('保存')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('t_heart_beat_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 116,
        parameters: {
          device: editInput.device,
          detection_time_from: editInput.detection_time_from,
          detection_time_from_update: new Date(),
          detection_time_to: editInput.detection_time_to,
          status: Number(editInput.status),
          message: editInput.message
        },
        name: `t_heart_beat_${t('保存')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_map_image_master_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70002,
        parameters: {
          id: editInput.id,
          map: editInput.fileData.tmp_filename.split('.')[0],
          filename: editInput.fileData.tmp_filename,
          filesize: editInput.fileData.filesize
        },
        name: `m_map_image_master_${t('保存')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const newSaveObject = async (master_name: string, input_data: any) => {
    setStartProcess(true);
    if (master_name === 'm_setting') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 117,
        parameters: {
          skey: input_data.skey,
          svalue: input_data.svalue,
          memo: input_data.memo
        },
        name: `m_setting_${t('新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_comp_method') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 118,
        parameters: {
          comp_method: input_data.comp_method,
          comp_method_name: input_data.comp_method_name
        },
        name: `m_comp_method_${t('新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_job_type') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 119,
        parameters: {
          job_type: input_data.job_type,
          job_type_name: input_data.job_type_name,
          job_type_alias: input_data.job_type_alias,
          is_show: input_data.is_show
        },
        name: `m_job_type_${t('新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 't_heart_beat') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 120,
        parameters: {
          device: input_data.device,
          detection_time_from: input_data.detection_time_from,
          detection_time_from_update: new Date(),
          detection_time_to: input_data.detection_time_to,
          status: Number(input_data.status),
          message: input_data.message
        },
        name: `t_heart_beat_${t('新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    if (master_name === 'm_map_image_master') {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70003,
        parameters: {
          map: input_data.fileData.tmp_filename.split('.')[0],
          filename: input_data.fileData.tmp_filename,
          filesize: input_data.fileData.filesize
        },
        name: `m_map_image_master_${t('新規追加')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  const deleteObject = async (id_key: string) => {
    setStartProcess(true);
    if (id_key.indexOf('m_setting') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 121,
        parameters: {skey: m_setting[Number(id_key.replace('m_setting_', ''))]['skey']},
        name: `m_setting_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_comp_method_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 122,
        parameters: {
          comp_method: m_comp_method[Number(id_key.replace('m_comp_method_', ''))]['comp_method']
        },
        name: `m_comp_method_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_job_type_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 123,
        parameters: {job_type: m_job_type[Number(id_key.replace('m_job_type_', ''))]['job_type']},
        name: `m_job_type_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('t_heart_beat_') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 124,
        parameters: {
          device: t_heart_beat[Number(id_key.replace('t_heart_beat_', ''))]['device'],
          detection_time_from:
            t_heart_beat[Number(id_key.replace('t_heart_beat_', ''))]['detection_time_from']
        },
        name: `t_heart_beat_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    if (id_key.indexOf('m_map_image_master') !== -1) {
      await setLFCData({
        snack: enqueueSnackbar,
        sql_id: 70004,
        parameters: {id: Number(id_key.replace('m_map_image_master_', ''))},
        name: `m_map_image_master_${t('削除')}`,
        cancelToken: source.token,
        t
      });
    }
    setListUpdate(String(new Date().getTime()));
    setListUpdate(String(new Date().getTime()));
    setEditButtonStatus(!editButtonStatus);
  };

  return (
    <GenericTemplate title={t('menu.page_name.マスタ管理')}>
      <ProgressBar startProcess={startProcess} />
      <Box className={classes.root}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tabidx}
          onChange={tabChange}
          className={classes.tabs}
        >
          <Tab label="M_SETTING" {...a11yProps(0)} />
          <Tab label="M_COMP_METHOD" {...a11yProps(1)} />
          <Tab label="M_JOB_TYPE" {...a11yProps(2)} />
          <Tab label="T_HEART_BEAT" {...a11yProps(3)} />
          <Tab label={t('マップマスター画像')} {...a11yProps(4)} />
        </Tabs>
        <TabPanel value={tabidx} index={0}>
          <MasterEditPanel
            ComponentTitle={'m_setting'}
            // CategoryNo={1}
            DataObject={m_setting}
            DataKeyName={'m_setting'}
            FieldObject={fields_m_setting}
            ExportFileName={'m_setting'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={1}>
          <MasterEditPanel
            ComponentTitle={'m_comp_method'}
            // CategoryNo={1}
            DataObject={m_comp_method}
            DataKeyName={'m_comp_method'}
            FieldObject={fields_m_comp_method}
            ExportFileName={'m_comp_method'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={2}>
          <MasterEditPanel
            ComponentTitle={'m_job_type'}
            // CategoryNo={1}
            DataObject={m_job_type}
            DataKeyName={'m_job_type'}
            FieldObject={fields_m_job_type}
            ExportFileName={'m_job_type'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={3}>
          <MasterEditPanel
            ComponentTitle={'t_heart_beat'}
            // CategoryNo={1}
            DataObject={t_heart_beat}
            DataKeyName={'t_heart_beat'}
            FieldObject={fields_t_heart_beat}
            ExportFileName={'t_heart_beat'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
        <TabPanel value={tabidx} index={4}>
          <MasterEditPanel
            ComponentTitle={t('マップマスター画像')}
            // CategoryNo={1}
            DataObject={map_image_master}
            DataKeyName={'m_map_image_master'}
            FieldObject={fields_map_image_master}
            ExportFileName={'map_image_master'}
            SaveObject={thisSaveObject}
            AddNewObject={newSaveObject}
            DeleteObject={deleteObject}
          />
        </TabPanel>
      </Box>
    </GenericTemplate>
  );
};

export default CommonMasterPage;
