import {Box, Grid, Typography} from '@mui/material';
import {LfcChartsLineArcStatus} from '_components/charts/weldingShow/lfcChartsLineArcStatus';
import {LfcChartsLineWorkGap} from '_components/charts/weldingShow/lfcChartsLineWorkGap';
import {TFunction} from 'i18next';
import {ArcStatus, RobotPaths, WorkGaps} from '../types';
import {WeldingShow3dEcharts} from '_components/charts/weldingShow/weldingShow3dEcharts';

interface WeldingShowTablesProps {
  t: TFunction;
  dataArcStatus: ArcStatus[][];
  dataRobotPaths: RobotPaths[];
  dataWorkGaps: WorkGaps;
}

export const WeldingShowTables = ({
  t,
  dataArcStatus,
  dataWorkGaps,
  dataRobotPaths
}: WeldingShowTablesProps) => {
  return (
    <Box sx={{flexGrow: 1, padding: '20px'}}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography
            sx={{
              background: '#2494f4',
              fontSize: '30px',
              fontWeight: '560',
              textAlign: 'center',
              borderRadius: '2px',
              height: '60px',
              color: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
            }}
          >
            ワークとロボットの情報
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
              padding: '20px',
              fontSize: '40px',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              gap: '20px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: '560',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              ワークのギャップ
            </Typography>
            <Box sx={{padding: '10px'}}>
              <LfcChartsLineWorkGap
                title="Work Gap"
                source={dataWorkGaps}
                xAxisName={`${t('ワークの長手方向の位置')}`}
                yAxisName={`${t('GAP量 [mm]')}`}
                height={'400px'}
                target="voltage"
              />
            </Box>
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: '560',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              ロボットのパス
            </Typography>
            <Box sx={{padding: '10px'}}>
              <WeldingShow3dEcharts data={dataRobotPaths} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography
            sx={{
              background: '#2494f4',
              fontSize: '30px',
              fontWeight: '560',
              textAlign: 'center',
              borderRadius: '2px',
              height: '60px',
              color: 'white',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)'
            }}
          >
            溶接機の情報
          </Typography>
          <Box
            sx={{
              textAlign: 'center',
              padding: '20px',
              fontSize: '40px',
              borderBottomLeftRadius: '20px',
              borderBottomRightRadius: '20px',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
              gap: '20px',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: '560',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              溶接電圧
            </Typography>
            <Box sx={{padding: '10px'}}>
              <LfcChartsLineArcStatus
                title="Voltage Trend"
                source={dataArcStatus}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={`${t('電圧 [V]')}`}
                height={'400px'}
                target="voltage"
              />
            </Box>
            <Typography
              sx={{
                fontSize: '30px',
                fontWeight: '560',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
              }}
            >
              溶接電流
            </Typography>
            <Box sx={{padding: '10px'}}>
              <LfcChartsLineArcStatus
                title="Current Trend"
                source={dataArcStatus}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={`${t('電流 [A]')}`}
                height={'400px'}
                target="current"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
