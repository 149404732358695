import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Divider, FormControlLabel, FormGroup, Grid, Slider, Stack, Switch} from '@mui/material';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCSelectFormMap from '_components-with-data/inputs/LFCSelectFormMap';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ImageGrid, {ShowOption} from './_components/ImageGrid';

/**
 * MapImagesPage
 * @returns
 */
const MapImagesPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const [imgDatas, setImgDatas] = useState([]);
  const [optValue, setOptValue] = useState<ShowOption>({
    colPicCount: 5,
    isShowDatail: true
  });
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [imageRootPath, setImageRootPath] = useState<string>('');

  /**
   * 検索条件初期値
   */
  const initialSearchValue = {
    date01: dayjs().subtract(7, 'days').format('YYYY-MM-DDT00:00'),
    date02: dayjs().format('YYYY-MM-DDTHH:mm'),
    work: [],
    map: t('すべて'),
    judge: 9
  };
  const [searchValue, setSearchValue] = useState(initialSearchValue);

  const source = useRef(axios.CancelToken.source()); // CancelTokenをページに１つ

  /**
   * 検索処理
   */
  const doSearch = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setStartProcess(true);
    setImgDatas([]);

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 7000,
        parameters: {
          date1: searchValue.date01 === '' ? null : searchValue.date01,
          date2: searchValue.date02 === '' ? null : searchValue.date02,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          map: searchValue.map === t('すべて') ? null : searchValue.map,
          judge: searchValue.judge === 9 ? null : searchValue.judge
        },
        cancelToken: source.current.token,
        name: `イメージデータ`,
        ds_state: setImgDatas,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * リセット処理
   */
  const restSearch = () => {
    setStartProcess(false);
    setSearchValue(initialSearchValue);
    setAutoCompleteReset(true);
  };

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  useEffect(() => {
    // マウント時処理

    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.current.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });

    // 画像パス取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      cancelToken: source.current.token,
      t
    }).then(ds => {
      if (ds.length > 0) setImageRootPath(ds[0]['svalue']);
    });

    return () => {
      // アンマウント処理
      source.current.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate title={t('menu.page_name.イメージ検索')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name="date01"
            label={t('日付From')}
            value={searchValue.date01}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCDatetimePicker
            name="date02"
            label={t('日付To')}
            value={searchValue.date02}
            onChange={event => {
              handleInputChange(event, searchValue, setSearchValue);
            }}
            required
          />
          <LFCAutocomplete
            name={t('work')}
            label={t('機種')}
            id={'work'}
            size="small"
            value={multiSelectData(work, searchValue.work)}
            onChange={autoOnChange}
            onReset={autoCompleteReset}
            doneReset={setAutoCompleteReset}
            multiple={true}
            selectItem={work}
          />
          <LFCSelectFormMap
            name="map"
            value={searchValue.map}
            onChange={(event: any) => {
              handleInputChange2(event, searchValue, setSearchValue);
            }}
            multiple={false}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCButton color="primary" onClick={doSearch}>
            {t('検索')}
          </LFCButton>
          <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
        </LFCFormRowGroup>
      </form>
      <Divider style={{margin: '1em 0'}} />
      {imgDatas.length !== 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9} xl={10}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    name="isShowDatail"
                    checked={optValue.isShowDatail}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setOptValue({...optValue, [event.target.name]: event.target.checked});
                    }}
                  />
                }
                label={t('ワーク情報')}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={2}>
            <Stack spacing={2} direction="row" sx={{mb: 1}} alignItems="center">
              <RemoveIcon />
              <Slider
                min={1}
                max={15}
                step={1}
                valueLabelDisplay="auto"
                value={optValue.colPicCount}
                onChange={(event: Event, newValue: number | number[]) => {
                  setOptValue({...optValue, colPicCount: newValue as number});
                }}
              />
              <AddIcon />
            </Stack>
          </Grid>
          <Grid item xs={12} container justifyContent="center" alignItems="flex-start">
            <Grid item>
              <ImageGrid
                iDatas={imgDatas}
                showOptions={optValue}
                imageRootPath={imageRootPath}
              ></ImageGrid>
            </Grid>
          </Grid>
        </Grid>
      ) : null}
    </GenericTemplate>
  );
};

export default MapImagesPage;
