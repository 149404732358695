import WeldingDemoSearchForm from '_components/model/welding-demo/WeldingDemoSearchForm';
import GenericTemplate from '_templates/GenericTemplate';

const WeldingDemo = () => {
  return (
    <GenericTemplate title="Welding Data">
      <WeldingDemoSearchForm />
    </GenericTemplate>
  );
};
export default WeldingDemo;
