import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import {ALL} from '_logics/LFCConst';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCButton from '_components/inputs/LFCButton';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {LOCATIONID_LQ_INSPECTIONRESULTS_MAIN, PAGEID_LQ_INSPECTIONRESULTS} from '_logics/LFCPageId';
import dayjs from 'dayjs';
import React from 'react';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import {SearchValue} from 'types/weldingDemo';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

type Props = {
  setStartProcess: Dispatch<SetStateAction<boolean>>;
  initialSearchValue: SearchValue;
  searchValue: any;
  setSearchValue: Dispatch<any>;
};

const WeldingDemoSearchBar = (props: Props) => {
  const {t} = useTranslation();
  const {setStartProcess, initialSearchValue, searchValue, setSearchValue} = props;
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const restSearch = () => {
    setStartProcess(false);
    setSearchValue(initialSearchValue);
    setAutoCompleteReset(true);
  };
  const doSearch = async () => {
    setStartProcess(true);
    switch (
      searchValue.selectType
      /* TODO: セレクトタイプによる検索処理を行う */
    ) {
    }
    Promise.allSettled([
      /* TODO: API処理 */
    ]).then(() => {
      setStartProcess(false);
    });
  };
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'date01',
        value: searchValue.date01,
        valueLabel:
          searchValue.date01 === '' ? '' : dayjs(searchValue.date01).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'date02',
        value: searchValue.date02,
        valueLabel:
          searchValue.date02 === '' ? '' : dayjs(searchValue.date02).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid[0] === t('すべて') ? ALL : searchValue.deviceid.join(','),
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'map',
        value: searchValue.map,
        valueLabel: searchValue.map[0] === t('すべて') ? ALL : searchValue.map.join(','),
        colName: '検査部位',
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };
  const formRef = useRef<HTMLFormElement>(null!);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const {enqueueSnackbar} = useSnackbar();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work, deviceid, map]);
  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['work']};
      });
      setWork(tmp);
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40002,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(ds => {
      const tmp: {label: string}[] = ds.map((item: any) => {
        return {label: item['deviceid']};
      });
      setDeviceid(tmp);
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42011,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);

    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);
  return (
    <form ref={formRef}>
      <LFCFormRowGroup>
        <LFCDatetimePicker
          name="date01"
          label={t('対象期間From')}
          value={searchValue.date01}
          onChange={event => {
            handleInputChange(event, searchValue, setSearchValue);
          }}
          required
        />
        <LFCDatetimePicker
          name="date02"
          label={t('対象期間To')}
          value={searchValue.date02}
          onChange={event => {
            handleInputChange(event, searchValue, setSearchValue);
          }}
        />
        <LFCAutocomplete
          name={'work'}
          label={t('機種')}
          id={'work'}
          size="small"
          value={multiSelectData(work, searchValue.work)}
          onChange={autoOnChange}
          onReset={autoCompleteReset}
          doneReset={setAutoCompleteReset}
          multiple={true}
          selectItem={work}
        />
        <LFCAutocomplete
          name={'deviceid'}
          label={t('検査装置')}
          id={'deviceid'}
          size="small"
          value={multiSelectData(deviceid, searchValue.deviceid)}
          onChange={autoOnChange}
          multiple={true}
          onReset={autoCompleteReset}
          doneReset={setAutoCompleteReset}
          selectItem={deviceid}
        />
        <LFCAutocomplete
          name={'map'}
          label={t('検査部位')}
          id={'map'}
          size="small"
          value={multiSelectData(map, searchValue.map)}
          onChange={autoOnChange}
          multiple={true}
          onReset={autoCompleteReset}
          doneReset={setAutoCompleteReset}
          selectItem={map}
        />
        <LFCButton color="primary" onClick={doSearch}>
          {t('検索')}
        </LFCButton>
        <LFCButton onClick={restSearch}>{t('リセット')}</LFCButton>
        <LFCSavedCondition
          open={openSavedCondition}
          pageId={PAGEID_LQ_INSPECTIONRESULTS}
          locationNo={LOCATIONID_LQ_INSPECTIONRESULTS_MAIN}
          onLoad={onLoadSavedCondition}
          onSelect={onSelectSavedCondition}
          onClose={() => setOpenSavedCondition(false)}
          conditions={condition}
        />
      </LFCFormRowGroup>
    </form>
  );
};
export default WeldingDemoSearchBar;
