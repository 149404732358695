import {CardContent, Grid, Tooltip, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';

/**
 * パラメータ
 */
interface Props {
  title: string;
  val: any;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  fontColor?: string;
  width?: string;
  titleVariant?: 'inherit' | Variant;
  valVariant?: 'inherit' | Variant;
  formatter?: (value: any) => any;
  unit?: string;
  info?: string;
}

/**
 * LFCSingleValue
 */
const LFCSingleValue2 = (props: Props) => {
  return (
    <div
      style={{
        width: '100%'
      }}
    >
      <Tooltip title={props.val != null ? String(props.val) : ''} arrow>
        <CardContent style={{padding: '1px'}}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Typography variant={props.titleVariant ? props.titleVariant : 'caption'}>
                {props.title} &nbsp;
              </Typography>
            </Grid>
            <Grid>
              <Typography
                variant={props.valVariant ? props.valVariant : 'body1'}
                style={{color: props.fontColor, fontWeight: 'bold'}}
              >
                {props.val != null
                  ? props.formatter
                    ? props.formatter(props.val)
                    : String(props.val)
                  : ''}
                &nbsp;
              </Typography>
            </Grid>
            <Grid>
              <Typography style={{fontSize: '0.75rem', fontWeight: 'normal'}}>
                {props.unit ? props.unit : ''}
              </Typography>
            </Grid>
          </Grid>

          {props.info !== '' ? (
            <Typography variant={'overline'} style={{color: props.fontColor}}>
              {props.info}
            </Typography>
          ) : (
            <></>
          )}
        </CardContent>
      </Tooltip>
    </div>
  );
};

export default LFCSingleValue2;
