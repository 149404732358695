import {Box, Typography} from '@mui/material';

type Props = {
  low: number;
  main: number;
  high: number;
  path_split_a: number;
  path_split_b: number;
};

const BarComponent = ({low, main, high, path_split_a, path_split_b}: Props) => {
  return (
    <Box>
      <Box display="flex" flexDirection="row" justifyContent="flex-start" width="100%">
        <Typography variant="body2" width="100px">
          パス分割率
        </Typography>
        <Box justifyContent="space-between" display="flex" width="100%">
          {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(num => (
            <Typography key={num} variant="body2">
              {num}
            </Typography>
          ))}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Typography variant="body2" width="100px">
          溶接条件番号
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          width="100%"
          height="40px"
          bgcolor="#E0E0E0"
          borderRadius="4px"
          position="relative"
        >
          <Box
            bgcolor="#FFD700"
            width={`${path_split_a}%`}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
          >
            <Typography variant="body2">{low}</Typography>
          </Box>
          <Box
            bgcolor="#9E9E9E"
            width={`${path_split_b}%`}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
          >
            <Typography variant="body2">{main}</Typography>
          </Box>
          <Box
            bgcolor="#FFD700"
            width={`${100 - path_split_b - path_split_a}%`}
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            borderRadius="4px"
          >
            <Typography variant="body2">{high}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BarComponent;
