import {Dialog, DialogContent, Grid} from '@mui/material';
import {GridCallbackDetails, GridCellParams, GridColDef, MuiEvent} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useTranslation} from 'react-i18next';
import {getLFCData} from '_logics/LFCUtil';
import TraceAbilitySearchDetailDig2 from './TraceAbilitySearchDetailDig2';
import {useSnackbar} from 'notistack';

type Props = {
  open: boolean;
  onClose: () => void;
  uuid: string;
  exec_time: string;
};

const TraceAbilitySearchDetailDig = ({open, onClose, uuid, exec_time}: Props) => {
  const {t} = useTranslation();
  const [startProcess, setStartProcess] = useState(true);

  const imageFileUrl = (params: any) => {
    if (params !== undefined) {
      const imageUrl =
        /* 古いデータは2桁で来ており、jpgになっているが、新しいデータは4桁で来ており、pngになっている */
        params.formattedValue.length === 2
          ? `/images/welding/S${params.formattedValue}.jpg`
          : `/images/welding/${params.formattedValue}.png`;
      return <img src={imageUrl} width="100%" style={{cursor: 'pointer'}} alt="" />;
    } else {
      return <></>;
    }
  };

  const weldingDetailColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'welding_count',
      headerName: '溶接回数(N番目)',
      description: 'welding_count',
      width: 100
    },
    {
      field: 'image_number',
      headerName: '溶接位置',
      description: 'image_number',
      width: 100,
      renderCell: imageFileUrl
    },
    {
      field: 'welding_start_path_x',
      headerName: '溶接パス始点X',
      description: 'welding_start_path_x',
      width: 100
    },
    {
      field: 'welding_start_path_y',
      headerName: '溶接パス始点Y',
      description: 'welding_start_path_y',
      width: 100
    },
    {
      field: 'welding_start_path_z',
      headerName: '溶接パス始点Z',
      description: 'welding_start_path_z',
      width: 100
    },
    {
      field: 'welding_end_path_x',
      headerName: '溶接パス終点X',
      description: 'welding_end_path_x',
      width: 100
    },
    {
      field: 'welding_end_path_y',
      headerName: '溶接パス終点Y',
      description: 'welding_end_path_y',
      width: 100
    },
    {
      field: 'welding_end_path_z',
      headerName: '溶接パス終点Z',
      description: 'welding_end_path_z',
      width: 100
    },
    {field: 'welding_length', headerName: '溶接距離', description: 'welding_length', width: 100},
    {field: 'splicecode', headerName: '継ぎ手', description: 'splicecode', width: 100},

    {field: 'frangle', headerName: '前進・後進角', description: 'frangle', width: 100},
    {field: 'aimingangle', headerName: '狙い角度', description: 'aimingangle', width: 100},
    {field: 'arclen', headerName: 'アーク長', description: 'arclen', width: 100},
    {
      field: 'welding_data_number',
      headerName: '溶接データ番号',
      description: 'welding_data_number',
      width: 100
    },
    {
      field: 'welding_condition_number_low',
      headerName: '溶接条件番号(本条件)パス分割率~A',
      description: 'welding_condition_number_low',
      width: 100
    },
    {
      field: 'welding_condition_number_main',
      headerName: '溶接条件番号(本条件)パス分割率A~B',
      description: 'welding_condition_number_main',
      width: 100
    },
    {
      field: 'welding_condition_number_high',
      headerName: '溶接条件番号(本条件)パス分割率B~',
      description: 'welding_condition_number_high',
      width: 100
    },
    {
      field: 'welding_condition_number_end',
      headerName: '溶接条件番号(終了)',
      description: 'welding_condition_number_end',
      width: 100
    },
    {field: 'pathsplita', headerName: 'パス分割率(%)A', description: 'pathsplita', width: 100},
    {field: 'pathsplitb', headerName: 'パス分割率(%)B', description: 'pathsplitb', width: 100},
    {field: 'heightcorrst', headerName: '高さ補正(始端)', description: 'heightcorrst', width: 100},
    {field: 'heightcorred', headerName: '高さ補正(終端)', description: 'heightcorred', width: 100}
  ];
  const [weldingDetailRows, setWeldingDetailRows] = useState<any>([]);

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (open) {
      setStartProcess(true);
      Promise.allSettled([
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 42027,
          parameters: {
            uuid: uuid,
            exec_time: exec_time
          },
          ds_state: setWeldingDetailRows,
          name: `${t('トレサビ検索詳細1')}`,
          cancelToken: source.token,
          t
        })
      ]).then(() => {
        setStartProcess(false);
      });
    }
  }, [open]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  let COLUMN_ORDER = [{name: 'id', order: 0}];

  const [openDetail, setOpenDetail] = useState(false);
  const [openData, setOpenData] = useState<{[key: string]: string}>({
    low: '',
    main: '',
    high: '',
    pathsplita: '',
    pathsplitb: '',
    welding_data_number: '',
    exec_time: ''
  });
  const handleClose = () => {
    setOpenData({
      low: '',
      main: '',
      high: '',
      pathsplita: '',
      pathsplitb: '',
      welding_data_number: '',
      exec_time: ''
    });
    setOpenDetail(false);
  };

  const goDetail = (data: any) => {
    setOpenData({
      low: data.welding_condition_number_low,
      main: data.welding_condition_number_main,
      high: data.welding_condition_number_high,
      pathsplita: data.pathsplita,
      pathsplitb: data.pathsplitb,
      welding_data_number: data.welding_data_number,
      exec_time: data.exec_time
    });
    setOpenDetail(true);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    goDetail(params.row);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth>
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LFCTitledCard title={''}>
              <LFCDataGridSimple
                columns={weldingDetailColumns}
                rows={weldingDetailRows}
                rowHeight={80}
                height="60vh"
                columnOrder={COLUMN_ORDER}
                onCellClick={onCellClick}
              />
            </LFCTitledCard>
          </Grid>
        </Grid>
      </DialogContent>
      <TraceAbilitySearchDetailDig2 open={openDetail} onClose={handleClose} data={openData} />
    </Dialog>
  );
};

export default TraceAbilitySearchDetailDig;
