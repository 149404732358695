import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {colorPallet} from '../../_themes/color-pallet/ColorPallet';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  searchType?: number;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  xlist: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxSC1 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  const [branchList, setBranchList] = useState([]);
  const [seriesData, setSeriesData] = useState<any>([]);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  useEffect(() => {
    if (props.source.length > 0) {
      setBranchList(Array.from(new Set(props.source.map((a: any) => a.branch_no))));
    }
  }, [props.source]);

  useEffect(() => {
    if (!isEmpty(props.source)) {
      let tmp: any = [];
      branchList.forEach((a: number) => {
        let tmp_select_branch_no: any = props.source.filter((c: any) => c.branch_no === a);
        let datas: any = [];
        props.xlist.forEach((b: string) => {
          if (!isEmpty(tmp_select_branch_no.filter((d: any) => d.x_list === b))) {
            datas.push(
              makeBoxData(tmp_select_branch_no.filter((f: any) => f.x_list === b)[0].data_value)
            );
          } else {
            datas.push([]);
          }
        });
        tmp.push({
          name: a,
          type: 'boxplot',
          data: datas,
          color: colorPallet[a + 50]
        });
      });
      setSeriesData(tmp);
    }
  }, [branchList]);

  const makeBoxData = (datalist: any) => {
    datalist = datalist.filter((e: any) => !isNaN(e));
    //ボックスデータの算出
    let max = Math.max(...datalist.map((a: any) => Number(a)));
    let min = Math.min(...datalist.map((a: any) => Number(a)));

    const dataQ1 = (datalist: any) => {
      let data_s: any = datalist.sort((a: number, b: number) => a - b);
      let pos = (data_s.length - 1) * 0.25;
      let base = Math.floor(pos);
      let rest = pos - base;
      if (data_s[base + 1] !== undefined) {
        return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
      } else {
        return data_s[base];
      }
    };

    const dataQ2 = (datalist: any) => {
      let data_s: any = datalist.sort((a: number, b: number) => a - b);
      let pos = (data_s.length - 1) * 0.5;
      let base = Math.floor(pos);
      let rest = pos - base;
      if (data_s[base + 1] !== undefined) {
        return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
      } else {
        return data_s[base];
      }
    };

    const dataQ3 = (datalist: any) => {
      let data_s: any = datalist.sort((a: number, b: number) => a - b);
      let pos = (data_s.length - 1) * 0.75;
      let base = Math.floor(pos);
      let rest = pos - base;
      if (data_s[base + 1] !== undefined) {
        return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
      } else {
        return data_s[base];
      }
    };
    // [min,  Q1,  median (or Q2),  Q3,  max]
    return [min, dataQ1(datalist), dataQ2(datalist), dataQ3(datalist), max];
  };

  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(seriesData)) {
      props.xlist.forEach((a: any, index: number) => {
        let data_by_branch: any = {};
        branchList.forEach((branchNo: number, branchIndex: number) => {
          data_by_branch['d_' + branchNo + '_0'] =
            !isEmpty(seriesData) && seriesData[branchIndex] !== undefined
              ? seriesData[branchIndex].data[index][4]
              : '';
          data_by_branch['d_' + branchNo + '_1'] =
            !isEmpty(seriesData) && seriesData[branchIndex] !== undefined
              ? seriesData[branchIndex].data[index][3]
              : '';
          data_by_branch['d_' + branchNo + '_2'] =
            !isEmpty(seriesData) && seriesData[branchIndex] !== undefined
              ? seriesData[branchIndex].data[index][2]
              : '';
          data_by_branch['d_' + branchNo + '_3'] =
            !isEmpty(seriesData) && seriesData[branchIndex] !== undefined
              ? seriesData[branchIndex].data[index][1]
              : '';
          data_by_branch['d_' + branchNo + '_4'] =
            !isEmpty(seriesData) && seriesData[branchIndex] !== undefined
              ? seriesData[branchIndex].data[index][0]
              : '';
        });

        tmp.push({
          work: String(Array.from(new Set(props.exportData.map((b: any) => b.work)))),
          deviceid: String(Array.from(new Set(props.exportData.map((b: any) => b.deviceid)))),
          map: props.mapName,
          // lq_time: props.exportData.filter((lq: any) => lq.year_month_day === a)[0] !== undefined
          //   ? props.exportData.filter((lq: any) => lq.year_month_day === a)[0].lq_time
          //   : a,
          lq_time: a,
          ...data_by_branch
        });
      });
      setExportData(tmp);
    }
  }, [seriesData]);

  const [exportFields, setExportFields] = useState(props.exportFields);
  useEffect(() => {
    let tmp: any = {};
    branchList.forEach((branch_no_data: number) => {
      tmp['d_' + branch_no_data + '_0'] = `${t('検査部位追番')}${branch_no_data}　${t('最大')}`;
      tmp['d_' + branch_no_data + '_1'] = `${t('検査部位追番')}${branch_no_data}　Q3`;
      tmp['d_' + branch_no_data + '_2'] = `${t('検査部位追番')}${branch_no_data}　${t(
        '中央(orQ2)'
      )}`;
      tmp['d_' + branch_no_data + '_3'] = `${t('検査部位追番')}${branch_no_data}　Q1`;
      tmp['d_' + branch_no_data + '_4'] = `${t('検査部位追番')}${branch_no_data}　${t('最小')}`;
    });
    setExportFields({...exportFields, ...tmp});
  }, [branchList]);

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            bottom: 0,
            textStyle: {
              fontSize: 10
            },
            formatter: `${t('追番')}{name}${t('最小値')}`
          },
          tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
              if (params.seriesType === 'boxplot') {
                // [min,  Q1,  median (or Q2),  Q3,  max]
                return (
                  params.name +
                  '<br/>' +
                  t('検査追番号') +
                  ':' +
                  params.seriesName +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  Number(params.value[5]).toFixed(2) +
                  '<br/>' +
                  'Q3：' +
                  Number(params.value[4]).toFixed(2) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  Number(params.value[3]).toFixed(2) +
                  '<br/>' +
                  'Q1：' +
                  Number(params.value[2]).toFixed(2) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  Number(params.value[1]).toFixed(2)
                );
              } else if (params.seriesType === 'scatter') {
                return (
                  params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
                );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '8%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: '',
            data: !isEmpty(props.xlist) ? props.xlist : props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            // boundaryGap: true,
            // nameGap: 10,
            splitLine: {
              show: true
            },
            axisLabel: {
              // interval: 0,
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: t('マッチング率(%)'),
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: 0,
            max: 100,
            interval: scaleValue.y1AxisInterval
          },
          series: seriesData
        }}
        exportData={exportData}
        exportFields={exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxSC1;
