import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useMySettings} from '_contexts/MySettingsProvider';
import LFCButton from '_components/inputs/LFCButton';

export default function TabMenuList(props: any) {
  const mySettings = useMySettings();

  const [checked, setChecked] = useState<readonly any[]>([]);
  const [left, setLeft] = useState<readonly any[]>([]);
  const [right, setRight] = useState<readonly any[]>([]);
  const [updateStatus, setUpdateStatus] = useState(false);

  useEffect(() => {
    setLeft(props.leftList);
    setRight(props.rightList);
  }, [props]);

  const not = (a: readonly string[], b: readonly string[]) => {
    return a.filter(value => b.indexOf(value) === -1);
  };

  const intersection = (a: readonly string[], b: readonly string[]) => {
    return a.filter(value => b.indexOf(value) !== -1);
  };

  const union = (a: readonly string[], b: readonly string[]) => {
    return [...a, ...not(b, a)];
  };

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const numberOfChecked = (items: readonly string[]) => intersection(checked, items).length;

  const handleToggle = (event: any, value: string) => {
    if (event.target.name !== 'move') {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setChecked(newChecked);
    }
  };

  const handleToggleAll = (items: readonly string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
    setUpdateStatus(true);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    setUpdateStatus(true);
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    setUpdateStatus(true);
  };

  const goUP = (targetItem: string, allItem: any) => {
    let tmp = allItem.findIndex((item: string) => item === targetItem);
    if (tmp > 0) {
      allItem.splice(tmp - 1, 0, targetItem);
      allItem.splice(tmp + 1, 1);
      setRight(allItem);
    }
    const newChecked = [...checked];
    setChecked(newChecked);
    setUpdateStatus(true);
  };

  const goDown = (targetItem: string, allItem: any) => {
    let tmp = allItem.findIndex((item: string) => item === targetItem);
    allItem.splice(tmp + 2, 0, targetItem);
    allItem.splice(tmp, 1);
    setRight(allItem);
    const newChecked = [...checked];
    setChecked(newChecked);
    setUpdateStatus(true);
  };

  const customList = (title: React.ReactNode, items: readonly any[]) => (
    <Card
      style={{
        backgroundColor: mySettings.isDark ? 'rgb(4,24,24)' : 'white',
        color: mySettings.isDark ? 'rgb(126,252,246)' : 'black'
      }}
    >
      <CardHeader
        sx={{px: 12, py: 1}}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected'
            }}
          />
        }
        title={title}
      />
      <Divider />
      <List
        sx={{
          height: 500,
          padding: 1,
          overflow: 'auto'
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: any, index: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={event => handleToggle(event, value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId
                  }}
                  style={{color: mySettings.isDark ? 'rgb(126,252,246)' : 'default'}}
                />
              </ListItemIcon>
              {props.settingType === 'page' ? (
                <ListItemText
                  id={labelId}
                  primary={value['page_name'] + ' : ' + value['page_path']}
                />
              ) : (
                <ListItemText id={labelId} primary={value['tab_name']} />
              )}
              {title === '割り当てページ' || title === '割り当てタブ' ? (
                <>
                  <Button
                    sx={{my: 0.2}}
                    variant="outlined"
                    size="small"
                    name={'move'}
                    onClick={() => goUP(value, items)}
                  >
                    ↑
                  </Button>
                  <Button
                    sx={{my: 0.5}}
                    variant="outlined"
                    size="small"
                    name={'move'}
                    onClick={() => goDown(value, items)}
                  >
                    ↓
                  </Button>
                </>
              ) : (
                false
              )}
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <>
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={5} md={5}>
          {customList(props.leftName, left)}
        </Grid>
        <Grid item xs={1} md={1}>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{my: 0.5}}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{my: 0.5}}
              variant="outlined"
              size="small"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={6} md={6}>
          {customList(props.rightName, right)}
        </Grid>
      </Grid>

      <Box style={{textAlign: 'center', margin: '10px'}}>
        <LFCButton
          color="primary"
          onClick={() => props.saveObject(right, left)}
          disabled={!updateStatus}
        >
          {props.saveTitle}
        </LFCButton>
      </Box>
    </>
  );
}
