import {green, red} from '@mui/material/colors';
import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  y: {
    dsColumn: string;
  };
  series: string;
  visual_map: {
    min: number;
    max: number;
    show?: boolean;
    inRangeColor?: string[];
  };
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  onClick?: (prm: {x: string; y: string}) => void;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
  height?: string;
}

/**
 * LFCChartsHeatMap
 * @param props
 * @returns
 */
const LFCChartsHeatMapLrobotFailureAnalysis = (props: Props) => {
  const {t} = useTranslation();
  const [dataSeriesX, setDataSeriesX] = useState([]);
  const [dataSeriesY, setDataSeriesY] = useState([]);
  const [dataPlot, setDataPlot] = useState([]);

  /**
   * sourceからXとY軸を作成
   */
  useEffect(() => {
    // シリーズ抽出：ヒートマップはXY全てCategory。
    let xds: any = [];
    let yds: any = [];

    if (props.source.length > 0) {
      xds = Array.from(new Set(props.source.map((value: any) => value[props.x.dsColumn])));
      yds = Array.from(new Set(props.source.map((value: any) => value[props.y.dsColumn])));
      xds.sort();
    }

    // データソースからシリーズのインデックス配列(ヒートマップの座標に)変換
    let dp: any = [];
    props.source.forEach((item: any) => {
      dp.push([
        xds.indexOf(item[props.x.dsColumn]),
        yds.indexOf(item[props.y.dsColumn]),
        item[props.series]
      ]);
    });

    setDataSeriesX(xds);
    setDataSeriesY(yds);
    setDataPlot(dp);
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '40',
              left: '130',
              right: '50'
            },
        legend: {type: 'scroll', bottom: props.visual_map.min, itemWidth: props.visual_map.max},
        tooltip: {
          axisPointer: {
            type: 'shadow'
          },
          formatter: (prm: any) => {
            return (
              '' +
              props.source[0].job_name +
              '<br/>' +
              props.source[prm.dataIndex].map +
              '<br/>' +
              t('不良数') +
              ' [' +
              props.source[prm.dataIndex].judge +
              ']'
            );
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            rotate: '45',
            fontSize: 10
          },
          data: dataSeriesX
        },
        yAxis: {
          type: 'category',
          data: dataSeriesY
        },
        visualMap: {
          show: props.visual_map.show ? true : props.visual_map.show,
          min: props.visual_map.min,
          max: props.visual_map.max,
          inRange: {
            color: props.visual_map.inRangeColor
              ? props.visual_map.inRangeColor
              : [green[500], red[500]]
          },
          calculable: true,
          orient: 'vertical',
          right: 'right',
          bottom: 60
        },
        series: [
          {
            type: 'heatmap',
            data: dataPlot,
            label: {
              show: true
            }
          }
        ]
      }}
      // onEvents={props.onEvents}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick({x: dataSeriesX[params.data[0]], y: dataSeriesY[params.data[1]]});
          }
        }
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
};
export default LFCChartsHeatMapLrobotFailureAnalysis;
