import {Button, Dialog, DialogContent, Grid, Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useTranslation} from 'react-i18next';
import {green} from '@mui/material/colors';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import BarComponent from '../BarComponent';
import {useSnackbar} from 'notistack';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';

type Props = {
  open: boolean;
  onClose: () => void;
  datas: any;
};

const WeldingDetailDig = ({open, onClose, datas}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (open) {
      setStartProcess(true);
      Promise.allSettled([
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 42025,
          parameters: {
            welding_data_number: datas.welding_data_number,
            exec_time: datas.exec_time
          },
          ds_state: setData,
          name: `${t('加工条件ファインダー詳細')}`,
          cancelToken: source.token,
          t
        })
      ]).then(() => {
        setStartProcess(false);
      });
    }
  }, [open]);
  const {t} = useTranslation();
  const [startProcess, setStartProcess] = useState(true);

  const weldingDetailColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {field: 'condition', headerName: '溶接条件名', description: 'condition', width: 100},
    {field: 'number', headerName: '溶接条件番号', description: 'number', width: 100},
    {field: 'volt', headerName: '電圧', description: 'volt', width: 100},
    {field: 'ampare', headerName: '電流', description: 'ampare', width: 100},
    {field: 'speed', headerName: '溶接速度', description: 'speed', width: 100},
    {field: 'exec_time', headerName: '処理時間', description: 'exec_time', width: 100}
  ];
  const [weldingDetailRows, setWeldingDetailRows] = useState<any>([
    {
      id: 1,
      condition: '',
      number: '',
      volt: '',
      ampare: '',
      speed: '',
      exec_time: ''
    }
  ]);

  const weldingColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'welding_data_number',
      headerName: '溶接データ番号',
      description: 'welding_data_number',
      width: 100
    },
    {
      field: 'update_time',
      headerName: '更新日時',
      description: 'update_time',
      width: 100,
      renderCell: (prms: GridRenderCellParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {
      field: 'welding_condition_number',
      headerName: '溶接条件番号',
      description: 'welding_condition_number',
      width: 100
    },
    {field: 'rise_process', headerName: '立ち上がり処理', description: 'rise_process', width: 100},
    {
      field: 'wire_post_process',
      headerName: 'ワイヤ後処理',
      description: 'wire_post_process',
      width: 100
    },
    {
      field: 'weld_release_process',
      headerName: '溶着解除番号',
      description: 'weld_release_process',
      width: 100
    },
    {field: 'weld_release', headerName: '溶着解除', description: 'weld_release', width: 100},
    {
      field: 'welding_condition_slope_specification',
      headerName: '溶接条件傾斜指定',
      description: 'welding_condition_slope_specification',
      width: 100
    },
    {
      field: 'gas_purge_time',
      headerName: 'ガスパージ時間',
      description: 'gas_purge_time',
      width: 100
    },
    {
      field: 'gas_pre_flow_time',
      headerName: 'ガスプリフロー時間',
      description: 'gas_pre_flow_time',
      width: 100
    },
    {
      field: 'gas_post_flow_time',
      headerName: 'ガスポストフロー時間',
      description: 'gas_post_flow_time',
      width: 100
    },
    {
      field: 'as_head_execution_time',
      headerName: 'AS先実行時間',
      description: 'as_head_execution_time',
      width: 100
    },
    {
      field: 'running_crater_processing_time',
      headerName: 'ランニングクレータ処理時間',
      description: 'running_crater_processing_time',
      width: 100
    }
  ];
  const [weldingRows, setWeldingRows] = useState<any>([{}]);

  useEffect(() => {
    const tmpWeldingRows = data.map((e: any, index: number) => {
      return {
        welding_data_number: e.welding_data_number,
        update_time: e.update_date_time,
        welding_condition_number: e.welding_condition_number,
        rise_process: e.rise_process,
        wire_post_process: e.wire_post_process,
        weld_release_process: e.weld_release_number,
        weld_release: e.weld_release,
        welding_condition_slope_specification: e.welding_condition_slope_specification,
        gas_purge_time: e.gas_purge_time,
        gas_pre_flow_time: e.gas_pre_flow_time,
        gas_post_flow_time: e.gas_post_flow_time,
        as_head_execution_time: e.as_head_execution_time,
        running_crater_processing_time: e.running_crater_processing_time
      };
    });
    const uniqueObjects = Array.from(new Set(tmpWeldingRows.map(JSON.stringify)))
      .map((e: any) => JSON.parse(e)) // 重複を除去したオブジェクトをパースする
      .map((item, index) => {
        return {...item, id: index + 1}; // 元のデータに id プロパティを追加
      });

    setWeldingRows(uniqueObjects);
    const tmpWeldingDetailRows = data.map((e: any, index: number) => {
      return {
        id: index + 1,
        condition: e.welding_condition_name,
        number: e.welding_condition_number,
        volt: e.voltage,
        ampare: e.current,
        speed: e.welding_speed,
        exec_time: e.processing_time
      };
    });
    setWeldingDetailRows(tmpWeldingDetailRows);
  }, [data]);

  const ExcelJS = require('exceljs');

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  let COLUMN_ORDER = [{name: 'id', order: 0}];

  const onDownloadCSV = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {}
    })
      .then(response => {
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, response.data], {type: 'text/plain'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}.csv`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDownloadExcel = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {}
    })
      .then(async response => {
        const data: string[][] = response.data.split('\r\n').map((row: any) => row.split(','));
        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet('export_data');
        const worksheet = workbook.getWorksheet('export_data');
        data.forEach((row, indexRow) => {
          if (indexRow === 0) {
            const header_data: {header: string; key: string}[] = [];
            row.forEach((cell, indexCol) => {
              header_data.push({header: cell.slice(1).slice(0, -1), key: `c${indexCol}`});
            });
            worksheet.columns = header_data;
          } else {
            let row_data = {};
            row.forEach((cell, indexCol) => {
              if (cell.length > 0) {
                row_data = {...row_data, [`c${indexCol}`]: cell.slice(1).slice(0, -1)};
              }
            });
            worksheet.addRow(row_data);
          }
        });
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}_.xlsx`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth>
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <Grid container spacing={1}>
          <Grid item xs={12} textAlign="right">
            <Tooltip title={t('CSV出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DownloadIcon />}
                onClick={onDownloadCSV}
              >
                {t('数値データ')}(CSV)
              </Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title={t('Excel出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                style={{background: green[300]}}
                startIcon={<DownloadIcon />}
                onClick={onDownloadExcel}
              >
                {t('数値データ')}(Excel)
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={''}>
              <BarComponent
                low={datas.welding_condition_number_low}
                main={datas.welding_condition_number_main}
                high={datas.welding_condition_number_high}
                path_split_a={datas.pathsplita}
                path_split_b={datas.pathsplitb}
              />
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={`${t('溶接データ')}`}>
              <LFCDataGridSimple
                columns={weldingColumns}
                rows={weldingRows}
                rowHeight={40}
                height="25vh"
                columnOrder={COLUMN_ORDER}
                onCellClick={() => {}}
              />
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={`${t('溶接データ詳細')}`}>
              <LFCDataGridSimple
                columns={weldingDetailColumns}
                rows={weldingDetailRows}
                rowHeight={44}
                height="60vh"
                columnOrder={COLUMN_ORDER}
                onCellClick={() => {}}
              />
            </LFCTitledCard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default WeldingDetailDig;
