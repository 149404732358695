// PAGE ID ------------------------------------------------
// LQ
export const PAGEID_LQ_INSPECTION_PROGRESS_MONITOR = 42; // 検査進捗モニタ
export const PAGEID_LQ_FAILUREANALYSIS = 2; // 検査結果傾向分析
export const PAGEID_LQ_INSPECTIONRESULTS = 3; // 検査結果検索
export const PAGEID_LQ_JOBANALYSIS = 4; // 検査結果比較分析
export const PAGEID_LQ_SEARCH_BY_DAY = 37; // 日報
export const PAGEID_LQ_SEARCH_BY_MONTH = 38; //月間集計
export const PAGEID_LQ_SEARCH_BY_YEAR = 39; // 年間集計
// LR
export const PAGEID_LR_ONSITE_MONITOR = 24; // 生産進捗モニタ
export const PAGEID_LR_FAILUREANALYSIS = 25; // 不良傾向分析
export const PAGEID_LR_SEARCH = 26; // 補正結果検索
export const PAGEID_LR_JOBTRENDANALYSIS = 27; // ジョブ傾向分析
export const PAGEID_LR_SEARCH_BY_DAY = 28; // 日報
export const PAGEID_LR_SEARCH_BY_MONTH = 29; // 月間集計
export const PAGEID_LR_SEARCH_BY_YEAR = 30; // 年間集計
export const PAGEID_WELDING_MONITOR = 49; // 49,生産進捗モニタ,/welding/monitor
export const PAGEID_WELDING_DAILY_REPORT = 50; // 50,日報,/welding/daily-report
export const PAGEID_WELDING_MONTHLY_REPORT = 51; // 51,月報,/welding/monthly-report
export const PAGEID_WELDING_PRODUCTION_STATUS = 52; // 52,生産中の変化,/welding/production-status

export const PAGEID_PERSONS_WORK_TIME_MONITO = 53; // 53,作業時間モニタ,/persons/work-time-monitor
export const PAGEID_PERSONS_WORK_TIME_TREND_ANALYSIS = 54; // 54,作業時間傾向分析,/persons/work-time-trend-analysis
export const PAGEID_PERSONS_COMPARE_ANALYSIS = 55; // 55,日時比較分析,/persons/compare-analysis
export const PAGEID_PERSONS_DAILY_REPORT_WORKER = 56; // 56,日報(人ログ),/persons/daily-report-worker
export const PAGEID_PERSONS_MONTHLY_REPORT_WORKER = 57; // 57,月間集計(人ログ),/persons/monthly-report-worker
export const PAGEID_DEMO = 58; // 57,月間集計(人ログ),/persons/monthly-report-worker

export const PAGEID_WELDING_ON_SITE_OBSERVER = 59; // 59,現場ウォッチャー,/welding/on-site-observer
export const PAGEID_WELDING_PROCESSING_CONDITION_FINDER = 60; // 60,加工条件ファインダー,/welding/processing-condition-finder
export const PAGEID_HEARTBEAT = 61;
export const PAGEID_WELDING_SHOW = 62;
export const PAGEID_DEVELOPMENT_RESULTS = 63; // 検査結果一覧表示

// LOCATION ID --------------------------------------------
// LQ
export const LOCATIONID_LQ_INSPECTION_PROGRESS_MONITOR_MAIN = 1; // 検査進捗モニタ : メイン検索
export const LOCATIONID_LQ_FAILUREANALYSIS_MAIN = 1; // 検査結果傾向分析 : メイン検索
export const LOCATIONID_LQ_INSPECTIONRESULTS_MAIN = 1; // 検査結果検索 : メイン検索

export const LOCATIONID_LQ_JOBANALYSIS_BUMPCHECK = 1; // 検査結果比較分析 : BUMPCHECK (JobAnalysis1Page.tsx)
export const LOCATIONID_LQ_JOBANALYSIS_HOLECOMPARE = 2; // 検査結果比較分析 : HOLECOMPARE (JobAnalysis2Page.tsx)　※廃止予定
export const LOCATIONID_LQ_JOBANALYSIS_LENGTHCOMPARE = 3; // 検査結果比較分析 : LENGTHCOMPARE (JobAnalysis3Page.tsx)
export const LOCATIONID_LQ_JOBANALYSIS_SURFACECOMPARE = 4; // 検査結果比較分析 : SURFACECOMPARE (TrendAnalysisScPage.tsx)　※廃止予定
export const LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION = 5; // 検査結果比較分析 : HOLECOMPARE(穴位置) (JobAnalysisHolePositionPage.tsx)　※廃止予定
export const LOCATIONID_LQ_JOBANALYSIS_HOLEDIAMETER = 6; // 検査結果比較分析 : HOLECOMPARE(穴径) (HoleCompareDiameterPage.tsx)　※ResultCompareに変わる？
export const LOCATIONID_LQ_JOBANALYSIS_SURFACECOMPARE_NEW = 7; // 検査結果比較分析 : SURFACECOMPARE(新) (JobAnalysisSurfaceComparePage.tsx)
export const LOCATIONID_LQ_JOBANALYSIS_HOLEPOSITION_NEW = 8; // 検査結果比較分析 : HOLECOMPARE(穴位置)新 (HoleComparePositionPage.tsx)　※HOLECOMPARE(穴位置)に変わる？

export const LOCATIONID_LR_JOBTRENDANALYSIS_FITTING = 1; // ジョブ傾向分析 : FITTING (JobTrendAnalysisFittingPage.tsx)
export const LOCATIONID_LR_JOBTRENDANALYSIS_PATHGENERATE = 2; // ジョブ傾向分析 : PATHGENERATE (PathGeneratePage.tsx)
export const LOCATIONID_LR_JOBTRENDANALYSIS_PATHSEND = 3; // ジョブ傾向分析 : 溶接パス (JobTrendAnalysisPathSendPage.tsx)

export const LOCATIONID_LQ_SEARCH_BY_DAY_MAIN = 1; // 日報 : メイン検索
export const LOCATIONID_LQ_SEARCH_BY_MONTH_MAIN = 1; //月間集計 : メイン検索
export const LOCATIONID_LQ_SEARCH_BY_YEAR_MAIN = 1; // 年間集計 : メイン検索
// LR
export const LOCATIONID_LR_ONSITE_MONITOR_MAIN = 1; // 生産進捗モニタ : メイン検索
export const LOCATIONID_LR_FAILUREANALYSIS_MAIN = 1; // 不良傾向分析 : メイン検索
export const LOCATIONID_LR_SEARCH_MAIN = 1; // 補正結果検索 : メイン検索
export const LOCATIONID_LR_SEARCH_BY_DAY_MAIN = 1; // 日報 : メイン検索
export const LOCATIONID_LR_SEARCH_BY_MONTH_MAIN = 1; // 月間集計 : メイン検索
export const LOCATIONID_LR_SEARCH_BY_YEAR_MAIN = 1; // 年間集計 : メイン検索

export const LOCATIONID_WELDING_MAIN = 1; // 溶接 : メイン検索
export const LOCATIONID_PERSONS_MAIN = 1; // 人ログ : メイン検索
export const LOCATIONID_DEMO_MAIN = 1; // 人ログ : メイン検索

// HEARTBEAT
export const LOCATIONID_HEARTBEAT_MAIN = 1; // ハートビート
// WELDING SHOW
export const LOCATIONID_WELDING_SHOW_MAIN = 1; // ウェルディングショー
export const LOCATIONID_DEVELOPMENT_RESULTS = 1; // 検査結果一覧表示
