import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import Decimal from 'decimal.js-light';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  xAxisName?: string;
  onClick?: (prm: {data: any}) => void;
  limit?: any;
  limitRow?: any;
  extraDatas?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  xLineMin?: number;
  xLineMax?: number;
  xRenge?: number;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarAxisGap = (props: Props) => {
  const {t} = useTranslation();
  if (props.source.length !== 3) {
    return <></>;
  }

  let y_value: any = props.source[0].map((item: any) => item['カウント数']);

  let showData: any = [];
  showData[0] = [];
  showData[1] = [];
  showData[2] = [];

  // x,y,xのidxの数を揃える
  let idx_max: number = Math.max(
    ...[
      ...props.source[0].map((a: any) => a.idx),
      ...props.source[1].map((a: any) => a.idx),
      ...props.source[2].map((a: any) => a.idx)
    ]
  );
  let idx_min: number = Math.min(
    ...[
      ...props.source[0].map((a: any) => a.idx),
      ...props.source[1].map((a: any) => a.idx),
      ...props.source[2].map((a: any) => a.idx)
    ]
  );

  let x_start = new Decimal(0);
  if (
    props.source !== undefined &&
    props.source[0] !== undefined &&
    props.source[0][0] !== undefined &&
    props.source[0][0].idx !== undefined &&
    props.xRenge !== undefined
  ) {
    x_start = new Decimal(props.xRenge as number);
    x_start = x_start.times(idx_min);
  }

  let x_list: any = [];
  let i = new Decimal(x_start.toNumber());
  let r = !isNaN(props.xRenge as number) ? new Decimal(Number(props.xRenge)) : new Decimal(0);

  if (props.xRenge !== undefined && idx_max !== -Infinity) {
    for (i; i.toNumber() <= r.times(idx_max).toNumber(); i = i.plus(r.toNumber())) {
      x_list.push(String(i + '_' + i.plus(r.toNumber()).toString()));
    }
  }

  x_list.forEach((a: any, index: number) => {
    let tmp: any = a.split('_');
    let tmp_a = new Decimal(tmp[0]);
    if (
      props.source[0].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber()).length !== 0
    ) {
      showData[0].push(
        Number(
          props.source[0].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber())[0]
            ?.カウント数
        )
      );
    } else {
      showData[0].push(null);
    }
    if (
      props.source[1].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber()).length !== 0
    ) {
      showData[1].push(
        Number(
          props.source[1].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber())[0]
            ?.カウント数
        )
      );
    } else {
      showData[1].push(null);
    }
    if (
      props.source[2].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber()).length !== 0
    ) {
      showData[2].push(
        Number(
          props.source[2].filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber())[0]
            ?.カウント数
        )
      );
    } else {
      showData[2].push(null);
    }
  });

  let x_max = 0;
  x_max = Math.max(Math.max(...showData[0]), Math.max(...showData[1]), Math.max(...showData[2]));

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: [
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 0
            },
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              show: false,
              xAxisIndex: 1
            },
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              show: false,
              xAxisIndex: 2
            }
          ],
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            // interval: 1,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            },
            yAxisIndex: 0,
            max: x_max
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: props.zoomStatus ? '95%' : '90%',
            textStyle: {
              fontSize: 10
            }
          },
          series: [
            {
              name: 'x_gap',
              data: showData[0],
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 0,
              itemStyle: {
                color: 'rgba(0, 200, 400)',
                opacity: 0.5
              }
            },
            {
              name: 'y_gap',
              data: showData[1],
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 1,
              itemStyle: {
                color: 'rgba(600, 200, 100)',
                opacity: 0.5
              }
            },
            {
              name: 'z_gap',
              data: showData[2],
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 2,
              itemStyle: {
                color: 'rgba(200, 90, 200)',
                opacity: 0.5
              }
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              if (prm.seriesName === t('個')) {
                return (
                  prm.name +
                  'mm<br>' +
                  t('検査数') +
                  ':' +
                  prm.value +
                  t('件') +
                  '<br>' +
                  t('構成比') +
                  ':' +
                  (
                    (prm.value /
                      y_value.reduce((sum: number, item: any) => sum + Number(item), 0)) *
                    100
                  ).toFixed(1) +
                  '%'
                );
              } else {
                // return (
                //   prm.seriesName + ":  " + Number(showTooltipValue(prm.seriesName)).toFixed(3) + "mm"
                // );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '5%',
            right: '0%',
            bottom: '22%'
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params.seriesName === t('個') && props.onClick != null) {
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
      />
    </div>
  );
};
export default LFCChartsBarAxisGap;
