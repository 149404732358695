import {Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import React from 'react';
import LFCButton from '../inputs/LFCButton';

/**
 * 引数
 */
interface Props {
  open: boolean;
  message: string;
  onClose: (isOk: boolean) => void;
}

/**
 * LFCConfirmDialog
 * OK or Cancel の確認ダイアログです。
 * @param props
 * @returns
 */
const LFCConfirmDialog = (props: Props) => {
  const handleClose = () => {
    props.onClose(false);
  };

  const handleCloseOk = () => {
    props.onClose(true);
  };

  return (
    <Dialog open={props.open} keepMounted fullWidth>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <LFCButton onClick={() => handleClose()}>Cancel</LFCButton>
        <LFCButton color="primary" onClick={() => handleCloseOk()}>
          OK
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};

export default LFCConfirmDialog;
