import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import * as echarts from 'echarts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any | {};
  xData?: any;
  yAxisName?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  onClick?: (prm: {data: any}) => void;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineMultiBySearch1 = (props: Props) => {
  const {t} = useTranslation();
  const legendChange = (data: any) => {
    let graph: any = document.getElementById(
      props.zoomChartId !== undefined ? props.zoomChartId : 'graph'
    );
    let graphZoom: any = document.getElementById('graphZoom');
    let echartsInstance: any;

    if (!props.zoomStatus && graphZoom === null) {
      echartsInstance = echarts.getInstanceByDom(graph.firstChild);
    } else {
      echartsInstance = echarts.getInstanceByDom(graphZoom.firstChild);
    }

    switch (data.name) {
      case '移動範囲Rm':
        if (data.selected.移動範囲Rm) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: t('移動範囲Rs')
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: t('移動範囲Rm')
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '範囲R'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cpk'
        });
        break;
      case '標準偏差S':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '範囲R'
        });
        if (data.selected.標準偏差S) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: '標準偏差S'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: '標準偏差S'
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cpk'
        });
        break;
      case '範囲R':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        if (data.selected.範囲R) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: '範囲R'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: '範囲R'
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '標準偏差S'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cp'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '工程能力Cpk'
        });
        break;
      case '工程能力Cp':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '標準偏差S'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '範囲R'
        });
        if (data.selected.工程能力Cp) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: '工程能力Cp'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: '工程能力Cp'
          });
        }
        echartsInstance.dispatchAction({
          type: 'legendSelect',
          name: '工程能力Cpk'
        });
        break;
      case '工程能力Cpk':
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: t('移動範囲Rm')
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '標準偏差S'
        });
        echartsInstance.dispatchAction({
          type: 'legendUnSelect',
          name: '範囲R'
        });
        echartsInstance.dispatchAction({
          type: 'legendSelect',
          name: '工程能力Cp'
        });
        if (data.selected.工程能力Cpk) {
          echartsInstance.dispatchAction({
            type: 'legendSelect',
            name: '工程能力Cpk'
          });
        } else {
          echartsInstance.dispatchAction({
            type: 'legendUnSelect',
            name: '工程能力Cpk'
          });
        }
        break;
    }
  };

  let legendSelected: any = {
    移動範囲Rm: true,
    範囲R: false,
    標準偏差S: true,
    工程能力Cp: false,
    工程能力Cpk: false
  };

  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(props.exportData)) {
      props.exportData.forEach((a: any, index: number) => {
        tmp.push({
          work: a.work,
          deviceid: a.deviceid,
          map: a.map,
          ref_job_title: a.ref_job_title,
          parameter_name: a.parameter_name,
          for_export: a.lq_time,
          d0:
            props.source[0] !== undefined && props.source[0].data !== null
              ? props.source[0].data[index]
              : '',
          serial: a.serial
        });
      });
      setExportData(tmp);
    }
  }, [props]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          left: '8%',
          right: '5%',
          bottom: '20%',
          top: '15%'
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 11,
          textStyle: {
            fontSize: 10
          },
          icon: 'circle',
          selected: legendSelected
        },
        tooltip: {
          trigger: 'axis',
          position: (point: any, params: any, dom: any, rect: any, size: any) => {
            return [point[0] + 20, '10%'];
          },
          formatter: (prm: any) => {
            let tmp: any;
            tmp =
              t('計測日時') +
              ':' +
              props.source[0].lq_time[prm[0].dataIndex] +
              '<br/>' +
              t('検査部位') +
              ':' +
              props.source[0].map[prm[0].dataIndex] +
              '<br/>';
            prm.forEach((a: any) => {
              tmp +=
                a.marker +
                t(a.seriesName) +
                ':' +
                (a.value !== undefined ? a.value.toFixed(3) : t('データなし')) +
                '<br/>';
            });
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color ? props.color : null,
        xAxis: {
          type: 'category',
          data: props.xData !== null ? props.xData : null,
          axisLabel: {
            rotate: '15',
            fontSize: 10
          }
        },
        yAxis: [
          {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            axisLabel: {
              fontSize: 10
            }
          }
        ],
        series: [
          {
            name:
              props.source !== undefined && props.source[0] !== undefined
                ? props.source[0].name
                : undefined,
            data:
              props.source !== undefined && props.source[0] !== undefined
                ? props.source[0].data
                : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: colorPallet[30]
          },
          {
            name:
              props.source !== undefined && props.source[1] !== undefined
                ? props.source[1].name
                : undefined,
            data:
              props.source !== undefined && props.source[1] !== undefined
                ? props.source[1].data
                : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: colorPallet[31]
          },
          {
            name:
              props.source !== undefined && props.source[2] !== undefined
                ? props.source[2].name
                : undefined,
            data:
              props.source !== undefined && props.source[2] !== undefined
                ? props.source[2].data
                : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: colorPallet[32]
          },
          {
            name:
              props.source !== undefined && props.source[3] !== undefined
                ? props.source[3].name
                : undefined,
            data:
              props.source !== undefined && props.source[3] !== undefined
                ? props.source[3].data
                : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: colorPallet[33]
          },
          {
            name:
              props.source !== undefined && props.source[4] !== undefined
                ? props.source[4].name
                : undefined,
            data:
              props.source !== undefined && props.source[4] !== undefined
                ? props.source[4].data
                : undefined,
            stack: false,
            type: 'line',
            showSymbol: true,
            showAllSymbol: true,
            color: colorPallet[34]
          }
        ]
      }}
      exportData={exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height !== undefined ? props.height : '300px'}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick(params);
          }
        },
        legendselectchanged: (params: any) => {
          legendChange(params);
        }
      }}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomChartId === props.zoomedChartId}
    />
  );
};
export default LFCChartsLineMultiBySearch1;
