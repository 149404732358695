import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import Decimal from 'decimal.js-light';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  xAxisName?: string;
  onClick?: (prm: {data: any}) => void;
  limit?: any;
  limitRow?: any;
  extraDatas?: any;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  xLineMin?: number;
  xLineMax?: number;
  xRenge?: number;
  compareType: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarParameterValue = (props: Props) => {
  const {t} = useTranslation();
  let y_value: any =
    props.source !== undefined ? props.source.map((item: any) => item['カウント数']) : [];

  let showData: any = [];

  let x_start: any;
  if (props.source[0] !== undefined && props.source[0].idx !== undefined) {
    x_start = new Decimal(props.xRenge as number);
    x_start = x_start.times(props.source[0].idx);
    for (
      x_start;
      x_start.toNumber() > Number(props.xLineMin);
      x_start.minus(props.xRenge as number)
    ) {
      x_start = x_start.minus(props.xRenge as number);
    }
  }

  let x_list: any = [];
  let i: any = x_start !== undefined ? new Decimal(x_start.toNumber()) : [];
  let r = !isNaN(props.xRenge as number) ? new Decimal(Number(props.xRenge)) : new Decimal(1);
  if (props.xRenge !== undefined && !isEmpty(i)) {
    for (i; i.toNumber() < Number(props.xLineMax); i = i.plus(r.toNumber())) {
      x_list.push(String(i + '_' + i.plus(r.toNumber()).toString()));
    }
  }

  let x_max: number = 1;
  let x_list2: any = [];
  x_list.forEach((a: any, index: number) => {
    let tmp: any = a.split('_');
    let tmp_a = new Decimal(tmp[0]);
    if (props.source.filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber()).length !== 0) {
      showData.push(
        Number(
          props.source.filter((b: any) => Number(b.idx) === tmp_a.div(r).toNumber())[0]?.カウント数
        )
      );
    } else {
      showData.push(null);
    }
  });

  let x_max_tmp = Math.max(...showData);
  if (x_max_tmp > 0) {
    let tmp: any = new Decimal(x_max_tmp);
    x_max = Math.ceil(tmp.toNumber());
  }

  let j: any = x_start !== undefined ? new Decimal(x_start.toNumber()) : [];
  let s = !isNaN(props.xRenge as number) ? new Decimal(Number(props.xRenge)) : new Decimal(1);
  let end: any = x_list.length > 0 ? x_list[x_list.length - 1].split('_') : [];
  if (props.xRenge !== undefined && !isEmpty(j) && !isEmpty(end)) {
    for (j; j.toNumber() <= Number(end[1]); j = j.plus(s.div(10).toNumber())) {
      x_list2.push(j.toNumber());
    }
  }

  const makeBar = (data: string | undefined) => {
    let index_tmp: any = [];
    if (data !== undefined) {
      x_list2.forEach((a: number, index: number) => {
        if (Number(data) >= a && Number(data) < x_list2[index + 1]) {
          index_tmp.push(x_max);
        } else {
          index_tmp.push('');
        }
      });
    }
    return index_tmp;
  };

  const showTooltipValue = (name: string) => {
    switch (name) {
      case '目標':
        return props.extraDatas.d1;
      case '上限':
        return props.extraDatas.d3;
      case '下限':
        return props.extraDatas.d5;
      case '中央':
        return props.extraDatas.d9;
      case '平均':
        return props.extraDatas.d8;
      case '平均+3σ':
        return props.extraDatas.d12;
      case '平均-3σ':
        return props.extraDatas.d13;
    }
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: [
            {
              type: 'category',
              data: x_list,
              axisLabel: {
                interval: 0,
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 0
            },
            {
              type: 'category',
              data: x_list2,
              axisLabel: {
                rotate: '15',
                fontSize: 10
              },
              xAxisIndex: 1,
              show: false,
              axisPointer: {
                show: true
              }
            }
          ],
          yAxis: {
            type: 'value',
            name: props.xAxisName,
            minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            },
            yAxisIndex: 0,
            max: x_max
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            selected: {
              目標: true,
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均-3σ': false,
              '平均+3σ': false
            },
            formatter: (prm: any) => {
              return t(prm);
            },
            data: [
              {
                name: props.extraDatas.d1 !== '-' ? '目標' : '',
                icon: 'circle'
              },
              !props.compareType
                ? {
                    name: '上限',
                    icon: 'circle'
                  }
                : '',
              {
                name: props.extraDatas.d5 !== '-' ? '下限' : '',
                icon: 'circle'
              },
              {
                name: '中央',
                icon: 'circle'
              },
              {
                name: '平均',
                icon: 'circle'
              },
              {
                name: props.extraDatas.d13 !== '-' ? '平均-3σ' : '',
                icon: 'circle'
              },
              !props.compareType
                ? {
                    name: '平均+3σ',
                    icon: 'circle'
                  }
                : ''
            ],
            top: props.zoomStatus ? '95%' : '90%',
            textStyle: {
              fontSize: 10
            }
          },
          series: [
            {
              name: t('個'),
              data: showData,
              type: 'bar',
              barWidth: '100%',
              xAxisIndex: 0
            },
            {
              name: '目標',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d1),
              label: {
                show: true,
                position: 'top',
                formatter: t('目標')
              },
              barWidth: 2,
              color: colorPallet[0]
            },
            {
              name: '上限',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d3),
              label: {
                show: true,
                position: 'top',
                formatter: t('上限')
              },
              barWidth: 2,
              color: colorPallet[7]
            },
            {
              name: '下限',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d5),
              label: {
                show: true,
                position: 'right',
                formatter: t('下限')
              },
              barWidth: 2,
              color: colorPallet[8]
            },
            {
              name: '中央',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d9),
              label: {
                show: true,
                position: 'top',
                formatter: t('中央')
              },
              barWidth: 2,
              color: colorPallet[2]
            },
            {
              name: '平均',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d8),
              label: {
                show: true,
                position: 'top',
                formatter: t('平均')
              },
              barWidth: 2,
              color: colorPallet[4]
            },
            {
              name: '平均+3σ',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d12),
              label: {
                show: true,
                position: 'top',
                formatter: t('平均+3σ')
              },
              barWidth: 2,
              color: colorPallet[5]
            },
            {
              name: '平均-3σ',
              type: 'bar',
              xAxisIndex: 1,
              data: makeBar(props.extraDatas.d13),
              label: {
                show: true,
                position: 'top',
                formatter: t('平均-3σ')
              },
              barWidth: 2,
              color: colorPallet[6]
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              if (prm.seriesName === t('個')) {
                return (
                  prm.name +
                  'mm<br>' +
                  t('検査数') +
                  ':' +
                  prm.value +
                  t('件') +
                  '<br>' +
                  t('構成比') +
                  ':' +
                  (
                    (prm.value / y_value.reduce((sum: number, item: any) => sum + item, 0)) *
                    100
                  ).toFixed(1) +
                  '%'
                );
              } else {
                return (
                  t(prm.seriesName) +
                  ':  ' +
                  Number(showTooltipValue(prm.seriesName)).toFixed(3) +
                  'mm'
                );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: '30%'
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params.seriesName === t('個') && props.onClick != null) {
              params.idx = props.source[params.dataIndex].idx;
              props.onClick(params);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    </div>
  );
};
export default LFCChartsBarParameterValue;
