import {useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  inputScale?: boolean;
  xlist: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxSearchByDay = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    if (!isEmpty(props.source)) {
      //ボックスデータの算出
      const CalcQuartile = (data: any) => {
        if (!isEmpty(data)) {
          let max = Math.max(...data.map((a: any) => a));
          let min = Math.min(...data.map((a: any) => a));

          const dataQ1 = () => {
            let data_s: any = data.sort((a: number, b: number) => a - b);
            let pos = (data_s.length - 1) * 0.25;
            let base = Math.floor(pos);
            let rest = pos - base;
            if (data_s[base + 1] !== undefined) {
              return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
            } else {
              return data_s[base];
            }
          };

          const dataQ2 = () => {
            let data_s: any = data.sort((a: number, b: number) => a - b);
            let pos = (data_s.length - 1) * 0.5;
            let base = Math.floor(pos);
            let rest = pos - base;
            if (data_s[base + 1] !== undefined) {
              return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
            } else {
              return data_s[base];
            }
          };

          const dataQ3 = () => {
            let data_s: any = data.sort((a: number, b: number) => a - b);
            let pos = (data_s.length - 1) * 0.75;
            let base = Math.floor(pos);
            let rest = pos - base;
            if (data_s[base + 1] !== undefined) {
              return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
            } else {
              return data_s[base];
            }
          };
          // [min,  Q1,  median (or Q2),  Q3,  max]
          return [min, dataQ1(), dataQ2(), dataQ3(), max];
        }
      };

      let tmp: any = [];
      props.xlist.forEach((a: string) => {
        if (props.source.x_list.find((k: any) => k === a)) {
          props.source.x_list.find((b: any, index: number) => {
            if (b === a) {
              tmp.push(props.source.data_value[index].map((e: string) => Number(e)));
            }
          });
        } else {
          tmp.push([]);
        }
      });

      let tmpOutPut: any = [];
      tmp.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut.push(CalcQuartile(a));
        } else {
          tmpOutPut.push([]);
        }
      });
      return tmpOutPut;
    } else {
      return null;
    }
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          // dataset: [
          //   {
          //     source: props.source ? props.source.data_value : false,
          //   },
          //   {
          //     transform: {
          //       type: "boxplot",
          //       config: {
          //         itemNameFormatter: (params: any) => {
          //           return props.source.x_list[params.value];
          //         },
          //       },
          //     },
          //   },
          //   {
          //     fromDatasetIndex: 1,
          //     fromTransformResult: 1,
          //   },
          // ],
          tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
              if (params.seriesType === 'boxplot') {
                // [min,  Q1,  median (or Q2),  Q3,  max]
                return (
                  params.name +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  params.value[5].toFixed(2) +
                  '<br/>' +
                  'Q3：' +
                  params.value[4].toFixed(2) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  params.value[3].toFixed(2) +
                  '<br/>' +
                  'Q1：' +
                  params.value[2].toFixed(2) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  params.value[1].toFixed(2)
                );
              } else if (params.seriesType === 'scatter') {
                return params.name + '<br/>' + t('はずれ値') + ':' + params.value[1].toFixed(2);
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: '18%'
          },
          xAxis: {
            type: 'category',
            name: t('時刻帯(時)'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            splitLine: {
              show: false
            },
            axisLabel: {
              interval: 0,
              rotate: '45',
              fontSize: 10,
              formatter: function (params: any) {
                return params;
              }
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              name: 'boxplot',
              type: 'boxplot',
              data: makeBoxData()
            }
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              props.onClick(params);
            }
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxSearchByDay;
