import {useRef, useState} from 'react';
import {HeartBeat} from '../types';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_HEARTBEAT_MAIN, PAGEID_HEARTBEAT} from '_logics/LFCPageId';
import {getLFCData} from '_logics/LFCUtil';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';

export const useHeartbeat = () => {
  let from_day: Date = new Date();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const getDefaultCondition = useGetDefaultCondition();
  const {t} = useTranslation();
  const DEFAULT_SEARCH_VALUE = {
    selectDate:
      from_day.getFullYear() +
      '-' +
      ('0' + (from_day.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + from_day.getDate()).slice(-2),
    heartBeats: Array(12).fill({
      companyId: '',
      deviceId: '',
      workTime: '8'
    })
  };

  const convertData = (
    input: Record<string, string>
  ): {selectDate: string; heartBeats: HeartBeat[]} => {
    if (!input) return DEFAULT_SEARCH_VALUE;
    const {selectDate, ...rest} = input;
    const heartBeats: HeartBeat[] = [];

    for (let i = 1; i <= 12; i++) {
      const userKey = `user_${i}`;
      const deviceIdKey = `device_id_${i}`;
      const workTimeKey = `work_time_${i}`;

      heartBeats.push({
        companyId: rest[userKey] ?? '',
        deviceId: rest[deviceIdKey] ?? '',
        workTime: rest[workTimeKey] ?? ''
      });
    }

    return {
      selectDate,
      heartBeats
    };
  };

  const [searchValue, setSearchValue] = useState<{
    selectDate: string;
    heartBeats: HeartBeat[];
  }>(
    convertData(getDefaultCondition(PAGEID_HEARTBEAT, LOCATIONID_HEARTBEAT_MAIN)) ??
      DEFAULT_SEARCH_VALUE
  );

  const apiFetch = async () => {
    setStartProcess(true);
    searchValue.heartBeats.forEach(async (heartBeat: HeartBeat, index: number) => {
      if (heartBeat.companyId && heartBeat.deviceId && heartBeat.workTime) {
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 303,
          parameters: {
            device: heartBeat.deviceId,
            work_time: heartBeat.workTime,
            select_date: searchValue.selectDate
          },
          // eslint-disable-next-line no-eval
          ds_state: eval(`setData${index + 1}`),
          name: `${t('検索結果')}`,
          cancelToken: source.token,
          t,
          rep_company_id: heartBeat.companyId
        });
      } else {
        return;
      }
    });
    setStartProcess(false);
  };

  const resetSearch = () => {
    setStartProcess(false);
    setSearchValue(DEFAULT_SEARCH_VALUE);
  };

  const formRef = useRef<HTMLFormElement>(null!);

  const [openSavedCondition, setOpenSavedCondition] = useState(false);

  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  const onSelectSavedCondition = async (conditionValues: Record<string, string>) => {
    setSearchValue(convertData(conditionValues));
    setOpenSavedCondition(false);
  };

  const doSearch = async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setStartProcess(true);
    await apiFetch();
  };

  const onLoadSavedCondition = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    const conditionArray: {
      name: string;
      value: string;
      valueLabel: string;
      colName: string;
      colWidth: number;
    }[] = [];

    searchValue.heartBeats.forEach((heartBeat: HeartBeat, index: number) => {
      conditionArray.push(
        {
          name: `user_${index + 1}`,
          value: heartBeat.companyId,
          valueLabel: String(heartBeat.companyId),
          colName: `ユーザー${index + 1}`,
          colWidth: 150
        },
        {
          name: `device_id_${index + 1}`,
          value: heartBeat.deviceId,
          valueLabel: String(heartBeat.deviceId),
          colName: `検査装置${index + 1}`,
          colWidth: 150
        },
        {
          name: `work_time_${index + 1}`,
          value: heartBeat.workTime,
          valueLabel: String(heartBeat.workTime),
          colName: `目標稼働時間${index + 1}`,
          colWidth: 150
        }
      );
    });

    setCondition([
      {
        name: 'selectDate',
        value: searchValue.selectDate,
        valueLabel: String(searchValue.selectDate),
        colName: '基準日',
        colWidth: 150
      },
      ...conditionArray
    ]);
    setOpenSavedCondition(true);
  };

  const [data1, setData1] = useState<any>();
  const [data2, setData2] = useState<any>();
  const [data3, setData3] = useState<any>();
  const [data4, setData4] = useState<any>();
  const [data5, setData5] = useState<any>();
  const [data6, setData6] = useState<any>();
  const [data7, setData7] = useState<any>();
  const [data8, setData8] = useState<any>();
  const [data9, setData9] = useState<any>();
  const [data10, setData10] = useState<any>();
  const [data11, setData11] = useState<any>();
  const [data12, setData12] = useState<any>();

  return {
    resetSearch,
    convertData,
    apiFetch,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    t,
    doSearch,
    openSavedCondition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    setOpenSavedCondition,
    condition,
    data1,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
    data8,
    data9,
    data10,
    data11,
    data12
  };
};
