import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCSingleValue from './LFCSingleValue';
import {Variant} from '@mui/material/styles/createTypography';

export interface LFCSingleValuesColDef {
  headerName: string;
  field: string;
  width?: string;
  titleVariant?: 'inherit' | Variant;
  valVariant?: 'inherit' | Variant;
  formatter?: (value: any) => any;
  unit?: string;
  info?: string;
}

/**
 * パラメータ
 */
interface Props {
  columns: LFCSingleValuesColDef[];
  source: any; // 1行のJSON
}

/**
 * LFCSingleValues
 */
const LFCSingleValues = (props: Props) => {
  if (props.source !== undefined) {
    return (
      <LFCFormRowGroup>
        {props.columns.length > 0 &&
          props.columns.map((r, index: number) => {
            return (
              <LFCSingleValue
                title={r.headerName}
                val={props.source[r.field]}
                width={r.width}
                titleVariant={r.titleVariant}
                valVariant={r.valVariant}
                formatter={r.formatter}
                key={index}
                unit={r.unit}
                info={r.info}
              />
            );
          })}
      </LFCFormRowGroup>
    );
  } else {
    return <></>;
  }
};
export default LFCSingleValues;
