import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
    data?: any;
  };
  y: {
    type: string;
    name: any;
    dsColumn: any;
    series: any;
    max?: number | string;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLine_Multi_ProductivityAnalysis = (props: Props) => {
  let series_data: any = [
    {
      type: props.y.type,
      name: props.y.name[0],
      encode: {
        x: props.x.dsColumn,
        y: props.y.dsColumn[0]
      }
      // color: props.color[0]
    },
    {
      type: props.y.type,
      name: props.y.name[1],
      encode: {
        x: props.x.dsColumn,
        y: props.y.dsColumn[1]
      }
      // color: props.color[1]
    }
  ];

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            top: '40',
            left: '40',
            right: '40',
            bottom: '30',
            containLabel: true
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow', //細かく見ないのでここはクロスなしでOK
              label: {
                formatter: (prm: any) => {
                  return '■' + prm.value.replace(/ /g, '');
                }
              }
            }
          },
          dataZoom: [
            {
              type: 'inside',
              minValueSpan: 3600 * 24 * 1000 * 7 // 最小ZOOM 7日分
            }
          ],
          color: props.color ? props.color : null,
          dataset: {
            source: props.source
          },
          xAxis: [
            {
              type: 'category',
              data: props.x.data,
              axisLabel: {
                rotate: '45',
                rich: {
                  table: {
                    top: 50,
                    lineHeight: 20,
                    align: 'center'
                  }
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              // name: props.y.name[0],
              axisLine: {
                show: false
              },
              splitLine: {
                show: true,
                color: '#333'
              }
            }
          ],
          series: series_data
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
      />
    </div>
  );
};
export default LFCChartsLine_Multi_ProductivityAnalysis;
