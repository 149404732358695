import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import {Drawer, Fab} from '@mui/material';
import React, {useState} from 'react';

interface Props {
  children: React.ReactNode;
  onClose: () => void;
  top?: number;
}

/**
 * 検索用サイドメニュー
 * @param props
 * @returns
 */
const LFCSideMenu = (props: Props) => {
  const [showSearchConditions, setShowSearchConditions] = useState(false);
  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setShowSearchConditions(open);
    if (!open) {
      props.onClose();
    }
  };

  return (
    <>
      <Fab
        sx={
          props.top !== undefined
            ? {position: 'fixed', top: props.top, right: 5}
            : {position: 'fixed', top: 65, right: 5}
        }
        color="primary"
        size="small"
        onClick={toggleDrawer(true)}
      >
        <ManageSearchIcon />
      </Fab>
      <Drawer anchor="right" open={showSearchConditions} onClose={toggleDrawer(false)}>
        {props.children}
      </Drawer>
    </>
  );
};
export default LFCSideMenu;
