import {Dispatch} from 'react';
import WeldingDemoTabs from './WeldingDemoTabs';
import WeldingDemoTabPanels from './WeldingDemoTabPanels';

type Props = {
  searchValue: any;
  setSearchValue: Dispatch<any>;
  value: number;
  setValue: Dispatch<number>;
  startProcess: boolean;
};

const WeldingDemoCard = (props: Props) => {
  const {searchValue, setSearchValue, value, setValue, startProcess} = props;

  return (
    <>
      <WeldingDemoTabs value={value} setValue={setValue} />
      <WeldingDemoTabPanels
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        value={value}
        startProcess={startProcess}
      />
    </>
  );
};

export default WeldingDemoCard;
