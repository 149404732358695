import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import {useEffect, useRef, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '../inputs/LFCDatetimePicker';
import LFCTextField from '../inputs/LFCTextField';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    title: {
      fontWeight: 800,
      marginRight: 20
    },
    mgr5: {
      display: 'inline-flex',
      marginRight: 5
    }
  };
});

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  rowData: any;
  fieldObject: any;
  dataMode: any;
  dataKeyName: string;
  saveNewObject: any;
  saveEditObject: any;
  cancelObject: any;
  deleteObject: any;
  other?: any;
}

const MasterEditPanelDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const formRef = useRef<HTMLFormElement>(null!);
  let input_data: any = [];
  const onChangeAdd = (event: any) => {
    input_data[event.target.name] = event.target.value;
  };

  const [edit_data, setEditData] = useState<any>([]);
  const onChangeEdit = (event: any) => {
    setEditData({...edit_data, [event.target.name]: event.target.value});
  };

  useEffect(() => {
    setEditData(props.rowData);
  }, [props]);

  const thisEditSave = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    props.saveEditObject(props.dataKeyName, edit_data);
  };
  const thisNewSave = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    props.saveNewObject(props.dataKeyName, input_data);
  };

  const thisCancel = () => {
    props.cancelObject();
  };

  const thisDelete = (id_key: any) => {
    props.deleteObject(id_key);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      {props.dataMode === 'add' ? (
        <>
          <LFCDialogTitle onClose={props.onClose}>{t('新規追加')}</LFCDialogTitle>
          <DialogContent>
            <form ref={formRef}>
              <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                  {props.fieldObject.map((field_setting: any, index: number) => {
                    if (field_setting.add && !field_setting.multiRows && !field_setting.dateType) {
                      return (
                        <TableRow key={'add_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'add_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'add_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnName}
                              value={props.rowData[field_setting.columnField]}
                              onChange={onChangeAdd}
                              fullWidth={true}
                              style={{width: '100%'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (field_setting.add && field_setting.multiRows) {
                      return (
                        <TableRow key={'add_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'add_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'add_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnName}
                              value={props.rowData[field_setting.columnField]}
                              onChange={onChangeAdd}
                              fullWidth={true}
                              multiline={true}
                              rows={10}
                              style={{width: '100%'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (field_setting.edit && field_setting.dateType) {
                      return (
                        <TableRow key={'add_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'add_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'add_' + index}
                          >
                            <LFCDatetimePicker
                              name={field_setting.columnField}
                              label={field_setting.columnField}
                              value={props.rowData[field_setting.columnField]}
                              onChange={onChangeAdd}
                              required={true}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return false;
                    }
                  })}
                </TableBody>
              </Table>
              <LFCButton color="primary" className={classes.mgr5} onClick={() => thisNewSave()}>
                {t('保存')}
              </LFCButton>
              <LFCButton onClick={() => thisCancel()}>{t('キャンセル')}</LFCButton>
            </form>
          </DialogContent>
        </>
      ) : (
        //編集画面
        <>
          <LFCDialogTitle onClose={props.onClose}>{t('編集画面')}</LFCDialogTitle>
          <DialogContent>
            <form ref={formRef}>
              <Table size="small">
                <TableHead></TableHead>
                <TableBody>
                  {props.fieldObject.map((field_setting: any, index: number) => {
                    if (field_setting.edit && !field_setting.multiRows && !field_setting.dateType) {
                      return (
                        <TableRow key={'edit_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'edit_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'edit_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnField}
                              value={edit_data[field_setting.columnField]}
                              onChange={onChangeEdit}
                              fullWidth={true}
                              style={{width: '100%'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (field_setting.edit && field_setting.multiRows) {
                      return (
                        <TableRow key={'edit_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'edit_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'edit_' + index}
                          >
                            <LFCTextField
                              name={field_setting.columnField}
                              label={field_setting.columnField}
                              value={edit_data[field_setting.columnField]}
                              onChange={onChangeEdit}
                              fullWidth={true}
                              multiline={true}
                              rows={10}
                              style={{width: '100%'}}
                              other={field_setting.other}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (field_setting.edit && field_setting.dateType) {
                      return (
                        <TableRow key={'edit_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'edit_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'edit_' + index}
                          >
                            <LFCDatetimePicker
                              name={field_setting.columnField}
                              label={field_setting.columnField}
                              value={
                                edit_data[field_setting.columnField] !== undefined
                                  ? edit_data[field_setting.columnField].replace(' ', 'T')
                                  : ''
                              }
                              onChange={onChangeEdit}
                              required={field_setting.other}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    } else if (!field_setting.hide) {
                      return (
                        <TableRow key={'edit_' + index}>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={100}
                            key={'edit_header' + index}
                          >
                            {field_setting.columnName}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="left"
                            width={300}
                            key={'edit_' + index}
                          >
                            {edit_data[field_setting.columnField]}
                          </TableCell>
                        </TableRow>
                      );
                    } else {
                      return false;
                    }
                  })}
                </TableBody>
              </Table>
              <LFCButton color="primary" className={classes.mgr5} onClick={() => thisEditSave()}>
                {t('保存')}
              </LFCButton>
              {props.deleteObject ? (
                <LFCButton
                  color="secondary"
                  className={classes.mgr5}
                  onClick={isOk => {
                    if (!isOk) {
                      return;
                    }
                    thisDelete(props.dataKeyName);
                  }}
                  confirmMessage={`${t('message.削除しますか？')}`}
                >
                  {t('削除')}
                </LFCButton>
              ) : (
                false
              )}
              <LFCButton onClick={() => thisCancel()}>{t('キャンセル')}</LFCButton>
            </form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default MasterEditPanelDig;
