import ProgressBar from '_components/ProgressBar';
import GenericTemplate from '_templates/GenericTemplate';
import {useWeldingShow} from './hooks/useWeldingShow';
import {WeldingShowSearchBar} from './components/weldingShowSearchBar';
import {WeldingShowTables} from './components/weldingShowTables';

export const WeldingShowPage = () => {
  const {
    t,
    startProcess,
    formRef,
    searchValue,
    setSearchValue,
    work,
    autoOnChange,
    autoCompleteReset,
    setAutoCompleteReset,
    deviceid,
    doSearch,
    resetSearch,
    openSavedCondition,
    setOpenSavedCondition,
    condition,
    onLoadSavedCondition,
    onSelectSavedCondition,
    onChangeShowLatestOnly,
    onChangeAutoReload,
    onSelectAutoReloadInterval,
    dataArcStatus,
    dataWorkGaps,
    dataRobotPaths
  } = useWeldingShow();
  return (
    <GenericTemplate title={t('menu.page_name.ウェルディングショー')}>
      <ProgressBar startProcess={startProcess} />
      <WeldingShowSearchBar
        formRef={formRef}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        work={work}
        autoOnChange={autoOnChange}
        autoCompleteReset={autoCompleteReset}
        setAutoCompleteReset={setAutoCompleteReset}
        deviceid={deviceid}
        doSearch={doSearch}
        resetSearch={resetSearch}
        openSavedCondition={openSavedCondition}
        setOpenSavedCondition={setOpenSavedCondition}
        condition={condition}
        onLoadSavedCondition={onLoadSavedCondition}
        onSelectSavedCondition={onSelectSavedCondition}
        onChangeShowLatestOnly={onChangeShowLatestOnly}
        onChangeAutoReload={onChangeAutoReload}
        onSelectAutoReloadInterval={onSelectAutoReloadInterval}
        t={t}
      />
      <WeldingShowTables
        dataArcStatus={dataArcStatus}
        t={t}
        dataWorkGaps={dataWorkGaps}
        dataRobotPaths={dataRobotPaths}
      />
    </GenericTemplate>
  );
};
