import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {useMySettings} from '_contexts/MySettingsProvider';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {distinct, getLFCData} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import LFCChartsLineHoleCompareCylinder from '_components/charts/LFCChartsLineHoleCompareCylinder';
import LFCChartsBarCylinderRG from '_components/charts/LFCChartsBarCylinderRG';
import LFCChartsBarCylinderH from '_components/charts/LFCChartsBarCylinderH';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import ProgressBar from '_components/ProgressBar';
import {isEmpty} from 'lodash';
import LFCChartsBoxWithLine5 from '../../_components/charts/LFCChartsBoxWithLine5';
import LFCChartsBoxWithLine6 from '../../_components/charts/LFCChartsBoxWithLine6';
import LFCChartsLineMulti5 from '../../_components/charts/LFCChartsLineMulti5';
import LFCChartsLineMultiBySearch5 from '../../_components/charts/LFCChartsLineMultiBySearch5';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    typeTitle: {
      marginLeft: '-16px',
      fontWeight: 'bold',
      fontSize: '16px'
    },
    mrgLeft: {
      marginLeft: '8px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  open: boolean;
  searchValue: any;
  typeKey: any;
  graphData2: any;
  startProcess: boolean;
  setStartProcess: Dispatch<boolean>;
}

const PartsCylinderComponent = (props: Props) => {
  const {startProcess, setStartProcess} = props;
  const {t} = useTranslation();
  const exportData3_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('標準偏差'),
    d1: t('範囲（最大最小差）'),
    d2: 'Cp',
    d3: 'Cpk'
  };

  const dataCylinder_RG_SP_column: any = {
    cylinder_radius_gap: t('円筒半径誤差'),
    カウント数: t('カウント数')
  };

  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  //平均値の取得
  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  const export_detail_all_list: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100,
      hide: false
    },
    {
      order: 1,
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: true,
      width: 140
    },
    {
      order: 2,
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: true
    },
    {
      order: 3,
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 180,
      sortable: true
    },
    {
      order: 10,
      field: 'master_x',
      headerName: t('マスタX座標'),
      description: t('マスタX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 11,
      field: 'master_y',
      headerName: t('マスタY座標'),
      description: t('マスタY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 12,
      field: 'master_z',
      headerName: t('マスタZ座標'),
      description: t('マスタZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 13,
      field: 'work_x',
      headerName: t('ワークX座標'),
      description: t('ワークX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 14,
      field: 'work_y',
      headerName: t('ワークY座標'),
      description: t('ワークY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 15,
      field: 'work_z',
      headerName: t('ワークZ座標'),
      description: t('ワークZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 16,
      field: 'x_gap',
      headerName: t('X座標誤差'),
      description: t('X座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 17,
      field: 'y_gap',
      headerName: t('Y座標誤差'),
      description: t('Y座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 18,
      field: 'z_gap',
      headerName: t('Z座標誤差'),
      description: t('Z座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 30,
      field: 'center_range_limit',
      headerName: t('球体半径誤差閾値'),
      description: t('球体半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 31,
      field: 'center_range_gap',
      headerName: t('球体半径誤差'),
      description: t('球体半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 50,
      field: 'x_lower_limit',
      headerName: t('X座標誤差下限閾値'),
      description: t('X座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 51,
      field: 'x_upper_limit',
      headerName: t('X座標誤差上限閾値'),
      description: t('X座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 52,
      field: 'y_lower_limit',
      headerName: t('Y座標誤差下限閾値'),
      description: t('Y座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 53,
      field: 'y_upper_limit',
      headerName: t('Y座標誤差上限閾値'),
      description: t('Y座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 54,
      field: 'z_lower_limit',
      headerName: t('Z座標誤差下限閾値'),
      description: t('Z座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 55,
      field: 'z_upper_limit',
      headerName: t('Z座標誤差上限閾値'),
      description: t('Z座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 60,
      field: 'cylinder_radius_gap',
      headerName: t('円筒半径誤差'),
      description: t('円筒半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 61,
      field: 'cylinder_radius_limit',
      headerName: t('円筒半径誤差閾値'),
      description: t('円筒半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 62,
      field: 'height_gap',
      headerName: t('円筒高さ誤差'),
      description: t('円筒高さ誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 63,
      field: 'height_upper_limit',
      headerName: t('円筒高さ誤差上限閾値'),
      description: t('円筒高さ誤差上限閾値'),
      width: 180,
      sortable: true
    },
    {
      order: 64,
      field: 'height_lower_limit',
      headerName: t('円筒高さ誤差下限閾値'),
      description: t('円筒高さ誤差下限閾値'),
      width: 180,
      sortable: true
    }
  ];

  const exportData3_column_cylinder_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('円筒半径誤差移動範囲（前結果差）'),
    d1: t('円筒高さ誤差移動範囲（前結果差）'),
    serial: t('シリアルナンバー')
  };

  //標準偏差の取得
  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  const dataCylinderExport_column_h_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    cylinder_radius_gap: t('円筒半径誤差'),
    height_gap: t('円筒高さ誤差'),
    serial: t('シリアルナンバー')
  };

  //中央値の取得
  const Quartile = (data: any, q: number) => {
    let data_s: any = data.sort((a: number, b: number) => a - b);
    let pos = (data_s.length - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if (data_s[base + 1] !== undefined) {
      return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
    } else {
      return data_s[base];
    }
  };

  // 表示数字を桁区切り
  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  const data2MaxAvgMin_init: any = {
    data_radius: {
      上限: 0,
      目標: 0,
      下限: 0,
      最大: 0,
      平均: 0,
      中央: 0,
      最小: 0,
      標準偏差: 0,
      '平均+3σ': 0,
      '平均-3σ': 0,
      Cp: 0,
      Cpk: 0
    },
    data_height: {
      上限: 0,
      目標: 0,
      下限: 0,
      最大: 0,
      平均: 0,
      中央: 0,
      最小: 0,
      標準偏差: 0,
      '平均+3σ': 0,
      '平均-3σ': 0,
      Cp: 0,
      Cpk: 0
    }
  };

  const dataCylinder_H_SP_column: any = {
    height_gap: t('円筒高さ誤差'),
    カウント数: t('カウント数')
  };

  //シリンダの計算データ
  const [data2MaxAvgMin, setData2MaxAvgMin] = useState(data2MaxAvgMin_init);

  //シリンダ
  const [dataCylinder_RG, setDataCylinder_RG] = useState([]); // 半径
  const [dataCylinder_RG_show, setDataCylinder_RG_show] = useState([]); // 半径
  const [dataCylinder_H, setDataCylinder_H] = useState([]); // 高さ
  const [dataCylinder_H_show, setDataCylinder_H_show] = useState([]); // 高さ
  const [dataCylinder_R, setDataCylinder_R] = useState([]); // 範囲R
  const [dataCylinder_R_show, setDataCylinder_R_show] = useState([]); // 範囲R
  const [dataCylinder_RG_XYZ, setDataCylinder_RG_XYZ] = useState<any>([]); // X軸
  const [dataCylinder_H_XYZ, setDataCylinder_H_XYZ] = useState<any>([]); // Y軸
  const [dataRG_Renge, setDataRG_Renge] = useState(0); // 半径
  const [dataRG_LineMin, setDataRG_LineMin] = useState(0); // 半径
  const [dataRG_LineMax, setDataRG_LineMax] = useState(0); // 半径
  const [dataH_Renge, setDataH_Renge] = useState(0); // 高さ
  const [dataH_LineMin, setDataH_LineMin] = useState(0); // 高さ
  const [dataH_LineMax, setDataH_LineMax] = useState(0); // 高さ
  const [dataCylinder_RG_SP, setDataCylinder_RG_SP] = useState([]);
  const [dataCylinder_H_SP, setDataCylinder_H_SP] = useState([]);
  const [graphData2RGSelectDate, setGraphData2RGSelectDate] = useState('');
  const [graphData2HSelectDate, setGraphData2HSelectDate] = useState('');
  const [dataCylinderExport, setDataCylinderExport] = useState<any>({
    data_radius: [],
    data_height: []
  });

  //日付リストの生成
  const date_calc: any = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(props.searchValue.select_date_from);
    let to_day = new Date(props.searchValue.select_date_to);

    switch (props.searchValue.searchType) {
      case 0:
        return return_type0_byid();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  }, [startProcess]);

  const return_type0_byid = () => {
    if (props.typeKey[0] === undefined) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 28100,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge
        },
        name: 'id-28100',
        cancelToken: source.token,
        t
      }).then((data: any) => {
        switch (data[0]['tolerance_type']) {
          case 0:
          case null:
            return Array.from(new Set(props.graphData2.map((a: any) => a.lq_time))).sort();
          case 1:
        }
        return [];
      });
    } else {
      switch (props.typeKey[0]['tolerance_type']) {
        case 0:
        case null:
          return Array.from(new Set(props.graphData2.map((a: any) => a.lq_time))).sort();
      }
      return [];
    }
  };

  //推移グラフ
  //シリンダ
  const [graphBaseData2, setGraphBaseData2] = useState<any>({
    data_radius: {},
    data_height: {}
  });

  let tmp_cylinder_cylinder_radius_gap: any = [];
  let tmp_cylinder_height_upper_limit: any = [];
  let tmp_cg: any = [];
  let tmp_hg: any = [];

  const checkZero = (a: string | null) => {
    if (a !== null) {
      return Number(a);
    } else {
      return null;
    }
  };

  // ---------- シリンダ：tolerance_type = 2 ------------------------------------
  useEffect(() => {
    setStartProcess(true);
    if (props.typeKey.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          //半径
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281027,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: !isEmpty(props.searchValue.map)
                ? '{' + props.searchValue.map.join(',') + '}'
                : '{' +
                  props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                  '}'
            },
            ds_state: setDataCylinder_RG,
            name: 'id-281027',
            cancelToken: source.token,
            t
          });
          //高さ
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281028,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: !isEmpty(props.searchValue.map)
                ? '{' + props.searchValue.map.join(',') + '}'
                : '{' +
                  props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                  '}'
            },
            name: 'id-281028',
            ds_state: setDataCylinder_H,
            cancelToken: source.token,
            t
          });
          // 範囲Rを全権取得のため
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281029,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: !isEmpty(props.searchValue.map)
                ? '{' + props.searchValue.map.join(',') + '}'
                : '{' +
                  props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                  '}'
            },
            name: 'id-281029',
            ds_state: setDataCylinder_R,
            cancelToken: source.token,
            t
          });
          break;
        case 1: // 時間ごと
          if (props.graphData2.map((a: any) => a.year_month_day_hour).length > 0) {
            //半径
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281021,
              parameters: {
                year_month_day_hour:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month_day_hour))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setDataCylinder_RG,
              name: 'id-281021',
              cancelToken: source.token,
              t
            });
            //高さ
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281022,
              parameters: {
                year_month_day_hour:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month_day_hour))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281022',
              ds_state: setDataCylinder_H,
              cancelToken: source.token,
              t
            });
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281023,
              parameters: {
                select_date_from: props.searchValue.select_date_from,
                select_date_to: props.searchValue.select_date_to,
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281023',
              ds_state: setDataCylinder_R,
              cancelToken: source.token,
              t
            });
          }
          break;
        case 2: // 日ごと
          if (props.graphData2.map((a: any) => a.year_month_day).length > 0) {
            //半径
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281013,
              parameters: {
                year_month_day:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month_day))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setDataCylinder_RG,
              name: 'id-281013',
              cancelToken: source.token,
              t
            });
            //高さ
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281014,
              parameters: {
                year_month_day:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month_day))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281014',
              ds_state: setDataCylinder_H,
              cancelToken: source.token,
              t
            });
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280015,
              parameters: {
                select_date_from: props.searchValue.select_date_from,
                select_date_to: props.searchValue.select_date_to,
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-280015',
              ds_state: setDataCylinder_R,
              cancelToken: source.token,
              t
            });
          }
          break;
        case 3:
          // 月ごと
          if (props.graphData2.map((a: any) => a.year_month).length > 0) {
            //半径
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281024,
              parameters: {
                year_month:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month))).join(',') +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              ds_state: setDataCylinder_RG,
              name: 'id-281024',
              cancelToken: source.token,
              t
            });
            //高さ
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281025,
              parameters: {
                year_month:
                  '{' +
                  Array.from(new Set(props.graphData2.map((a: any) => a.year_month))).join(',') +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281025',
              ds_state: setDataCylinder_H,
              cancelToken: source.token,
              t
            });
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281026,
              parameters: {
                select_date_from: props.searchValue.select_date_from,
                select_date_to: props.searchValue.select_date_to,
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 2)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281026',
              ds_state: setDataCylinder_R,
              cancelToken: source.token,
              t
            });
          }
          break;
      }
    }

    //　シリンダのエクストラ：データ計算
    if (props.graphData2.length > 0) {
      let cylinder_radius_limit: any = distinct(props.graphData2, 'cylinder_radius_limit').map(
        (item: any) => Number(item)
      );
      let height_upper_limit: any = distinct(props.graphData2, 'height_upper_limit').map(
        (item: any) => Number(item)
      );
      let height_lower_limit: any = distinct(props.graphData2, 'height_lower_limit').map(
        (item: any) => Number(item)
      );

      let d8_rg: number = Number(
        average(props.graphData2.map((item: any) => item.cylinder_radius_gap))
      );
      let d8_h: number = Number(average(props.graphData2.map((item: any) => item.height_gap)));

      let d9_rg: number = Quartile(
        props.graphData2.map((a: any) => Number(a.cylinder_radius_gap)),
        0.5
      );
      let d9_h: number = Quartile(
        props.graphData2.map((a: any) => Number(a.height_gap)),
        0.5
      );

      // (上限 - 下限) / (6 * 標準偏差)
      let d14_h: number =
        average(height_upper_limit) -
        average(height_lower_limit) /
          (6 * stdev(props.graphData2.map((item: any) => Number(item.height_gap))));

      let d16_rg: number =
        Math.abs(
          (cylinder_radius_limit.length > 1
            ? average(cylinder_radius_limit)
            : cylinder_radius_limit[0]) - d8_rg
        ) /
        (3 * stdev(props.graphData2.map((item: any) => Number(item.cylinder_radius_gap))));
      let d16_h: number = Math.min(
        Math.abs(
          (height_upper_limit.length > 1 ? average(height_upper_limit) : height_upper_limit[0]) -
            d8_h
        ) /
          (3 * stdev(props.graphData2.map((item: any) => Number(item.height_gap)))),
        Math.abs(
          d8_h -
            (height_lower_limit.length > 1 ? average(height_lower_limit) : height_lower_limit[0])
        ) /
          (3 * stdev(props.graphData2.map((item: any) => Number(item.height_gap))))
      );

      setData2MaxAvgMin({
        data_radius: {
          d3:
            cylinder_radius_limit.length > 1
              ? average(cylinder_radius_limit)
              : cylinder_radius_limit[0], //上限
          d4: cylinder_radius_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: '-', //下限
          d6: '', //下限info
          d7: Math.max(...props.graphData2.map((item: any) => item.cylinder_radius_gap)), //最大
          d8: d8_rg, //平均
          d9: d9_rg, //中央
          d10: Math.min(...props.graphData2.map((item: any) => item.cylinder_radius_gap)), //最小
          d11: stdev(props.graphData2.map((item: any) => Number(item.cylinder_radius_gap))), //標準偏差
          d12:
            d8_rg +
            3 * stdev(props.graphData2.map((item: any) => Number(item.cylinder_radius_gap))), //管理限界線1 平均+３σ
          d13: '-', //管理限界線2 平均-３σ
          d14: '-', //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16_rg, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        },
        data_height: {
          d3: height_upper_limit.length > 1 ? average(height_upper_limit) : height_upper_limit[0], //上限
          d4: height_upper_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: height_lower_limit.length > 1 ? average(height_lower_limit) : height_lower_limit[0], //下限
          d6: height_lower_limit.length > 1 ? t('※規格複数のため参考値') : '', //下限info
          d7: Math.max(...props.graphData2.map((item: any) => item.height_gap)), //最大
          d8: d8_h, //平均
          d9: d9_h, //中央
          d10: Math.min(...props.graphData2.map((item: any) => item.height_gap)), //最小
          d11: stdev(props.graphData2.map((item: any) => Number(item.height_gap))), //標準偏差
          d12: d8_h + 3 * stdev(props.graphData2.map((item: any) => Number(item.height_gap))), //管理限界線1 平均+3σ
          d13: d8_h - 3 * stdev(props.graphData2.map((item: any) => Number(item.height_gap))), //管理限界線2 平均-3σ
          d14: d14_h, //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16_h, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        }
      });
    } else {
      setData2MaxAvgMin(data2MaxAvgMin_init);
    }
  }, [props.graphData2]);

  // １集計単位ごとに複数のマスター値、リミット値がある場合はnullとする。
  const checkNotSingleMasterAndLimit = (
    targetName: string,
    checkType: string,
    checkDate: string
  ) => {
    switch (targetName) {
      case 'radius':
        return (
          Array.from(
            new Set(
              props.graphData2
                .filter((a: any) => a[checkType] === checkDate)
                .map((b: any) => b.cylinder_radius_limit)
            )
          ).length === 1
        );
      case 'height':
        let height_lower_limit_list: any = Array.from(
          new Set(
            props.graphData2
              .filter((a: any) => a[checkType] === checkDate)
              .map((b: any) => b.height_lower_limit)
          )
        );
        let height_upper_limit_list: any = Array.from(
          new Set(
            props.graphData2
              .filter((a: any) => a[checkType] === checkDate)
              .map((b: any) => b.height_upper_limit)
          )
        );
        return height_lower_limit_list.length === 1 && height_upper_limit_list.length === 1;
      default:
        break;
    }
  };

  // シリンダー：ばらつき推移グラフの範囲Rと他のデータをまとめる
  // dataCylinder_RG
  useEffect(() => {
    let tmp_list: any = [];
    setDataCylinder_RG_show([]);
    if (dataCylinder_RG.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setDataCylinder_RG_show(dataCylinder_RG);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_RG.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month_day_hour', a)
            ) {
              tmp_list.push(dataCylinder_RG.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_RG[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_RG_show(tmp_list);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_RG.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month_day', a)
            ) {
              tmp_list.push(dataCylinder_RG.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_RG[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_RG_show(tmp_list);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_RG.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month', a)
            ) {
              tmp_list.push(dataCylinder_RG.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_RG[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_RG_show(tmp_list);
          break;
      }
    }
  }, [dataCylinder_RG]);

  // dataCylinder_H
  useEffect(() => {
    let tmp_list: any = [];
    setDataCylinder_H_show([]);
    if (dataCylinder_H.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setDataCylinder_H_show(dataCylinder_H);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_H.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('height', 'year_month_day_hour', a)
            ) {
              tmp_list.push(dataCylinder_H.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_H[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_H_show(tmp_list);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_H.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('height', 'year_month_day', a)
            ) {
              tmp_list.push(dataCylinder_H.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_H[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_H_show(tmp_list);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              dataCylinder_H.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('height', 'year_month', a)
            ) {
              tmp_list.push(dataCylinder_H.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: dataCylinder_H[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_H_show(tmp_list);
          break;
      }
    }
  }, [dataCylinder_H]);

  // dataCylinder_R
  useEffect(() => {
    let tmp_list_r: any = [];
    setDataCylinder_R_show([]);
    if (dataCylinder_R.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCylinder_R.find((d: any) => d.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month_day_hour', a) &&
              checkNotSingleMasterAndLimit('height', 'year_month_day_hour', a)
            ) {
              tmp_list_r.push(dataCylinder_R.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCylinder_R[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_R_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCylinder_R.find((d: any) => d.year_month_day === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month_day', a) &&
              checkNotSingleMasterAndLimit('height', 'year_month_day', a)
            ) {
              tmp_list_r.push(dataCylinder_R.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCylinder_R[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_R_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCylinder_R.find((d: any) => d.year_month === a) &&
              checkNotSingleMasterAndLimit('radius', 'year_month', a) &&
              checkNotSingleMasterAndLimit('height', 'year_month', a)
            ) {
              tmp_list_r.push(dataCylinder_R.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCylinder_R[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCylinder_R_show(tmp_list_r);
          break;
      }
    }
  }, [dataCylinder_R]);

  //　１集計単位ごとに10件のデータがない場合はnullとする。
  let show_limit: number = 10;

  // シリンダー：ばらつき推移のグラフ用データ生成
  // dataCylinder_RG
  useEffect(() => {
    if (props.searchValue.searchType !== 0) {
      setDataCylinder_RG_XYZ([
        {
          data:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            dataCylinder_R_show.filter((a: any) => a.範囲r_半径).length > 0
              ? dataCylinder_R_show.map((a: any) => checkZero(a.範囲r_半径))
              : null,
          name: '範囲R',
          map:
            dataCylinder_R_show.filter((a: any) => a.範囲r_半径).length > 0
              ? dataCylinder_R_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            dataCylinder_RG_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_RG_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setDataCylinder_RG_XYZ([
        {
          data:
            dataCylinder_RG_show.filter((a: any) => a.移動範囲rs).length > 0
              ? dataCylinder_RG_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          name: t('移動範囲Rs'),
          lq_time: dataCylinder_RG_show.map((a: any) => a.lq_time),
          map: dataCylinder_RG_show.map((a: any) => a.map)
        }
      ]);
    }
  }, [dataCylinder_RG_show, dataCylinder_R_show]);

  // dataCylinder_H
  useEffect(() => {
    if (props.searchValue.searchType !== 0) {
      setDataCylinder_H_XYZ([
        {
          data:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            dataCylinder_R_show.filter((a: any) => a.範囲r_高さ).length > 0
              ? dataCylinder_R_show.map((a: any) => checkZero(a.範囲r_高さ))
              : null,
          name: '範囲R',
          map:
            dataCylinder_R_show.filter((a: any) => a.範囲r_高さ).length > 0
              ? dataCylinder_R_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            dataCylinder_H_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCylinder_H_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setDataCylinder_H_XYZ([
        {
          data:
            dataCylinder_H_show.filter((a: any) => a.移動範囲rs).length > 0
              ? dataCylinder_H_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          name: t('移動範囲Rs'),
          lq_time: dataCylinder_H_show.map((a: any) => a.lq_time),
          map: dataCylinder_H_show.map((a: any) => a.map)
        }
      ]);
    }
  }, [dataCylinder_H_show, dataCylinder_R_show]);

  const [exportDataCylinderRG, setExportDataCylinderRG] = useState([]);
  useEffect(() => {
    if (graphBaseData2.data_radius !== undefined) {
      setExportDataCylinderRG(
        makeExportData3(graphBaseData2.data_radius.x_list, dataCylinder_RG_XYZ, 0)
      );
    }
  }, [dataCylinder_RG_XYZ]);

  const [exportDataCylinderH, setExportDataCylinderH] = useState([]);
  useEffect(() => {
    if (graphBaseData2.data_height !== undefined) {
      setExportDataCylinderH(
        makeExportData3(graphBaseData2.data_height.x_list, dataCylinder_H_XYZ, 1)
      );
    }
  }, [dataCylinder_H_XYZ]);

  useEffect(() => {
    // シリンダの分布グラフリミット計算
    let tmp_n: number = props.graphData2.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }

    // 「最大値」「上限(仕様)」「管理限界線(+3σ)」
    let rg_tmp_max: number = Math.max(
      data2MaxAvgMin.data_radius.d7,
      data2MaxAvgMin.data_radius.d3,
      data2MaxAvgMin.data_radius.d12
    );
    setDataRG_LineMax(rg_tmp_max);
    let h_tmp_max: number = Math.max(
      data2MaxAvgMin.data_height.d7,
      data2MaxAvgMin.data_height.d3,
      data2MaxAvgMin.data_height.d12
    );
    setDataH_LineMax(h_tmp_max);

    // 最小値」「下限(仕様)」「管理限界線(-3σ)」
    let rg_tmp_min: number = Math.min(data2MaxAvgMin.data_radius.d10);
    setDataRG_LineMin(rg_tmp_min);
    let h_tmp_min: number = Math.min(
      data2MaxAvgMin.data_height.d5,
      data2MaxAvgMin.data_height.d10,
      data2MaxAvgMin.data_height.d13
    );
    setDataH_LineMin(h_tmp_min);

    let rg_tmp_h: number = (rg_tmp_max - rg_tmp_min) / tmp_k;
    let under_zero: number = Math.floor(1 / rg_tmp_h);
    let zero: string = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }
    let rg_tmp_dot: number = String(rg_tmp_h).indexOf('.');
    let rg_tmp_round: number;
    if (rg_tmp_dot > 0) {
      rg_tmp_round =
        Math.floor(rg_tmp_h * (rg_tmp_dot * Number(zero))) / (rg_tmp_dot * Number(zero));
    } else {
      rg_tmp_round = Math.floor(rg_tmp_h);
    }
    setDataRG_Renge(rg_tmp_round);

    let h_tmp_h: number = (h_tmp_max - h_tmp_min) / tmp_k;
    under_zero = Math.floor(1 / h_tmp_h);
    zero = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }
    let h_tmp_dot: number = String(h_tmp_h).indexOf('.');
    let h_tmp_round: number;
    if (h_tmp_dot > 0) {
      h_tmp_round = Math.floor(h_tmp_h * (h_tmp_dot * Number(zero))) / (h_tmp_dot * Number(zero));
    } else {
      h_tmp_round = Math.floor(h_tmp_h);
    }
    setDataH_Renge(h_tmp_round);

    // シリンダ：誤差　推移のグラフのデータを作成
    if (props.graphData2.length > 0) {
      date_calc().forEach((lq_time_string: string) => {
        switch (props.searchValue.searchType) {
          case 0: //検査ごと
            break;
          case 1: //時間ごと
            tmp_cg = [];
            tmp_hg = [];
            props.graphData2.forEach((item: any) => {
              if (String(item.year_month_day_hour) === lq_time_string) {
                tmp_cg.push(Number(item['cylinder_radius_gap']));
                tmp_hg.push(Number(item['height_gap']));
              }
            });
            tmp_cylinder_cylinder_radius_gap.push(tmp_cg);
            tmp_cylinder_height_upper_limit.push(tmp_hg);
            break;
          case 2: //日ごと
            tmp_cg = [];
            tmp_hg = [];
            props.graphData2.forEach((item: any) => {
              if (String(item.year_month_day) === lq_time_string) {
                tmp_cg.push(Number(item['cylinder_radius_gap']));
                tmp_hg.push(Number(item['height_gap']));
              }
            });
            tmp_cylinder_cylinder_radius_gap.push(tmp_cg);
            tmp_cylinder_height_upper_limit.push(tmp_hg);
            break;
          case 3: //月ごと
            tmp_cg = [];
            tmp_hg = [];
            props.graphData2.forEach((item: any) => {
              if (String(item.year_month) === lq_time_string) {
                tmp_cg.push(Number(item['cylinder_radius_gap']));
                tmp_hg.push(Number(item['height_gap']));
              }
            });
            tmp_cylinder_cylinder_radius_gap.push(tmp_cg);
            tmp_cylinder_height_upper_limit.push(tmp_hg);
            break;
        }
      });

      if (props.searchValue.searchType !== 0) {
        setGraphBaseData2({
          data_radius: {
            map: props.graphData2[0]['map'],
            data_value: tmp_cylinder_cylinder_radius_gap,
            data_avg_cl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d9
            ),
            data_avg: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d8
            ), //平均
            data_avg_mel: tmp_cylinder_cylinder_radius_gap.map((a: any) =>
              !isEmpty(a) ? average(a) : ''
            ), //群平均
            data_middle_mel: tmp_cylinder_cylinder_radius_gap.map((a: any) =>
              !isEmpty(a) ? Quartile(a, 0.5) : ''
            ), //群中央
            data_middle: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d9
            ), //中央
            data_lcl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d13
            ),
            data_ucl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d12
            ),
            data_target: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d1
            ),
            data_lsl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d3
            ),
            data_usl: tmp_cylinder_cylinder_radius_gap.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: props.graphData2.map((a: any) => a.cylinder_radius_limit),
            lower_limit: undefined
          },
          data_height: {
            map: props.graphData2[0]['map'],
            data_value: tmp_cylinder_height_upper_limit,
            data_avg_cl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d9
            ),
            data_avg: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d8
            ), //平均
            data_avg_mel: tmp_cylinder_height_upper_limit.map((a: any) =>
              !isEmpty(a) ? average(a) : ''
            ), //群平均
            data_middle_mel: tmp_cylinder_height_upper_limit.map((a: any) =>
              !isEmpty(a) ? Quartile(a, 0.5) : ''
            ), //群中央
            data_middle: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d9
            ), //中央
            data_lcl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d13
            ),
            data_ucl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d12
            ),
            data_target: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d1
            ),
            data_lsl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d3
            ),
            data_usl: tmp_cylinder_height_upper_limit.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: props.graphData2.map((a: any) => a.height_upper_limit),
            lower_limit: props.graphData2.map((a: any) => a.height_lower_limit)
          }
        });

        //シリンダのエクスポートデータベースの生成
        setDataCylinderExport({
          data_radius: props.graphData2.map((a: any) => {
            return {
              map: a.map,
              work: a.work,
              deviceid: a.deviceid,
              cylinder_radius_gap: a.cylinder_radius_gap,
              cylinder_radius_limit: a.cylinder_radius_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month,
              serial: a.serial
            };
          }),
          data_height: props.graphData2.map((a: any) => {
            return {
              map: a.map,
              work: a.work,
              deviceid: a.deviceid,
              height_gap: a.height_gap,
              height_upper_limit: a.height_upper_limit,
              height_lower_limit: a.height_lower_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month,
              serial: a.serial
            };
          })
        });
      } else {
        setGraphBaseData2({
          data_radius: {
            id: props.graphData2.map((a: any, index: number) => index + 1),
            map: props.graphData2.map((a: any) => a.map),
            lq_time: props.graphData2.map((a: any) => a.lq_time),
            serial: props.graphData2.map((a: any) => a.serial),
            data_value: props.graphData2.map((a: any) => a.cylinder_radius_gap),
            data_avg_cl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d9
            ),
            data_avg: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d8
            ), //平均
            data_avg_mel: tmp_cylinder_cylinder_radius_gap.map((a: any) =>
              !isEmpty(a) ? average(a) : ''
            ), //群平均
            data_middle_mel: tmp_cylinder_cylinder_radius_gap.map((a: any) =>
              !isEmpty(a) ? Quartile(a, 0.5) : ''
            ), //群中央
            data_middle: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d9
            ), //中央
            data_lcl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d13
            ),
            data_ucl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d12
            ),
            data_target: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d1
            ),
            data_lsl: tmp_cylinder_cylinder_radius_gap.map(
              (a: any) => data2MaxAvgMin.data_radius.d3
            ),
            data_usl: tmp_cylinder_cylinder_radius_gap.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: props.graphData2.map((a: any) => a.cylinder_radius_limit),
            lower_limit: undefined
          },
          data_height: {
            id: props.graphData2.map((a: any, index: number) => index + 1),
            map: props.graphData2.map((a: any) => a.map),
            lq_time: props.graphData2.map((a: any) => a.lq_time),
            serial: props.graphData2.map((a: any) => a.serial),
            data_value: props.graphData2.map((a: any) => a.height_gap),
            data_avg_cl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d9
            ),
            data_avg: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d8
            ), //平均
            data_avg_mel: tmp_cylinder_height_upper_limit.map((a: any) =>
              !isEmpty(a) ? average(a) : ''
            ), //群平均
            data_middle_mel: tmp_cylinder_height_upper_limit.map((a: any) =>
              !isEmpty(a) ? Quartile(a, 0.5) : ''
            ), //群中央
            data_middle: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d9
            ), //中央
            data_lcl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d13
            ),
            data_ucl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d12
            ),
            data_target: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d1
            ),
            data_lsl: tmp_cylinder_height_upper_limit.map(
              (a: any) => data2MaxAvgMin.data_height.d3
            ),
            data_usl: tmp_cylinder_height_upper_limit.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: props.graphData2.map((a: any) => a.height_upper_limit),
            lower_limit: props.graphData2.map((a: any) => a.height_lower_limit)
          }
        });
        //シリンダのエクスポートデータベースの生成
        setDataCylinderExport({
          data_radius: props.graphData2.map((a: any) => {
            return {
              map: a.map,
              work: a.work,
              deviceid: a.deviceid,
              cylinder_radius_gap: a.cylinder_radius_gap,
              cylinder_radius_limit: a.cylinder_radius_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month,
              lq_time: a.lq_time,
              serial: a.serial
            };
          }),
          data_height: props.graphData2.map((a: any) => {
            return {
              map: a.map,
              work: a.work,
              deviceid: a.deviceid,
              height_gap: a.height_gap,
              height_upper_limit: a.height_upper_limit,
              height_lower_limit: a.height_lower_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month,
              lq_time: a.lq_time,
              serial: a.serial
            };
          })
        });
      }
    }
    setStartProcess(false);
  }, [data2MaxAvgMin]);

  const getRengeData2RG = () => {
    if (dataRG_Renge !== 0 && !isNaN(dataRG_Renge)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29006,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          renge: dataRG_Renge
        },
        ds_state: setDataCylinder_RG_SP,
        name: 'id-29003',
        cancelToken: source.token,
        t
      });
    }
  };

  const getRengeData2H = () => {
    if (dataH_Renge !== 0 && !isNaN(dataH_Renge)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29007,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          renge: dataH_Renge
        },
        ds_state: setDataCylinder_H_SP,
        name: 'id-29004',
        cancelToken: source.token,
        t
      });
    }
  };

  // シリンダの分布グラフデータ取得： cylinder_radius_gap
  useEffect(() => {
    getRengeData2RG();
  }, [dataRG_Renge]);

  // シリンダの分布グラフデータ取得：　height_gap
  useEffect(() => {
    getRengeData2H();
  }, [dataH_Renge]);

  //シリンダ：グラフクリック
  const getDataWithDate2rg = (datas: string) => {
    setGraphData2RGSelectDate(datas);
  };
  const getDataByData2rg = (datas: string) => {
    setGraphData2RGSelectDate(datas);
  };
  const getDataWithDate2h = (datas: string) => {
    setGraphData2HSelectDate(datas);
  };
  const getDataByData2h = (datas: string) => {
    setGraphData2HSelectDate(datas);
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');
  const zoomChartsCheck = (idString: string) => {
    return zoomChartOpen === idString;
  };

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  // ---------分布グラフ
  // シリンダ：RG：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData2RGSelectDate === '') {
      getRengeData2RG();
      return;
    }

    // 検査ごと：検索
    if (props.searchValue.searchType === 0 && dataRG_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29020,
        parameters: {
          select_serial: graphData2RGSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataRG_Renge
        },
        ds_state: setDataCylinder_RG_SP,
        name: 'id-29020',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1 && dataRG_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29021,
        parameters: {
          select_lq_date_hour: graphData2RGSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataRG_Renge
        },
        ds_state: setDataCylinder_RG_SP,
        name: 'id-29021',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2 && dataRG_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29022,
        parameters: {
          select_date: graphData2RGSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataRG_Renge
        },
        ds_state: setDataCylinder_RG_SP,
        name: 'id-29022',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3 && dataRG_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29023,
        parameters: {
          select_date_month: graphData2RGSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataRG_Renge
        },
        ds_state: setDataCylinder_RG_SP,
        name: 'id-29023)',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData2RGSelectDate]);

  // シリンダ：H：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData2HSelectDate === '') {
      getRengeData2H();
      return;
    }

    // 検査ごと：検索
    if (props.searchValue.searchType === 0 && dataH_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29024,
        parameters: {
          select_serial: graphData2HSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataH_Renge
        },
        ds_state: setDataCylinder_H_SP,
        name: 'id-29024',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1 && dataH_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29025,
        parameters: {
          select_lq_date_hour: graphData2HSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataH_Renge
        },
        ds_state: setDataCylinder_H_SP,
        name: 'id-29025',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2 && dataH_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29026,
        parameters: {
          select_date: graphData2HSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataH_Renge
        },
        ds_state: setDataCylinder_H_SP,
        name: 'id-29026',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3 && dataH_Renge !== 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29027,
        parameters: {
          select_date_month: graphData2HSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
              '}',
          renge: dataH_Renge
        },
        ds_state: setDataCylinder_H_SP,
        name: 'id-29027',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData2HSelectDate]);

  // 穴分布のグラフクリックして、クリックした位置の一覧表を出力
  const [g2clickData, setG2clickData] = useState([]);

  const g2clickRG = (datas: any) => {
    setStartProcess(true);
    let cylinder_radius_gap_min: number | null = null;
    let cylinder_radius_gap_max: number | null = null;
    if (datas.name !== undefined) {
      cylinder_radius_gap_min = datas.name.split('_')[0];
      cylinder_radius_gap_max = datas.name.split('_')[1];
    }

    if (graphData2RGSelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29052,
          parameters: {
            select_serial: graphData2RGSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                '}',
            cylinder_radius_gap_min: cylinder_radius_gap_min,
            cylinder_radius_gap_max: cylinder_radius_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29052',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29053,
          parameters: {
            select_lq_date_hour: graphData2RGSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                '}',
            cylinder_radius_gap_min: cylinder_radius_gap_min,
            cylinder_radius_gap_max: cylinder_radius_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29053',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29054,
          parameters: {
            select_date: graphData2RGSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                '}',
            cylinder_radius_gap_min: cylinder_radius_gap_min,
            cylinder_radius_gap_max: cylinder_radius_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29054',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29055,
          parameters: {
            select_date_month: graphData2RGSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 2)[0].map_array.join(',') +
                '}',
            cylinder_radius_gap_min: cylinder_radius_gap_min,
            cylinder_radius_gap_max: cylinder_radius_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29055',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29056,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          cylinder_radius_gap_min: cylinder_radius_gap_min,
          cylinder_radius_gap_max: cylinder_radius_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29056',
        cancelToken: source.token,
        t
      })
        .then(() => setDetailListOpen(true))
        .then(() => setStartProcess(false));
    }
  };

  const g2clickH = (datas: any) => {
    setStartProcess(true);
    let height_gap_min: number | null = null;
    let height_gap_max: number | null = null;
    if (datas.name !== undefined) {
      height_gap_min = datas.name.split('_')[0];
      height_gap_max = datas.name.split('_')[1];
    }

    if (graphData2HSelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29057,
          parameters: {
            select_serial: graphData2HSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            height_gap_min: height_gap_min,
            height_gap_max: height_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29057',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29059,
          parameters: {
            select_lq_date_hour: graphData2HSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            height_gap_min: height_gap_min,
            height_gap_max: height_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29059',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29060,
          parameters: {
            select_date: graphData2HSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            height_gap_min: height_gap_min,
            height_gap_max: height_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29060',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29061,
          parameters: {
            select_date_month: graphData2HSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            height_gap_min: height_gap_min,
            height_gap_max: height_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29061',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29058,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          height_gap_min: height_gap_min,
          height_gap_max: height_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29058',
        cancelToken: source.token,
        t
      })
        .then(() => setDetailListOpen(true))
        .then(() => setStartProcess(false));
    }
  };

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  //ばらつき推移グラフのダウンロードデータ生成
  const makeExportData3 = (x_list: any, baseData: any, type: number) => {
    let tmp: any = [];
    if (x_list !== undefined && baseData.length > 0) {
      if (props.searchValue.searchType !== 0) {
        x_list.forEach((a: string, index: number) => {
          tmp.push({
            date: a,
            標準偏差S: baseData[0].data !== null ? baseData[0].data[index] : null,
            範囲R:
              baseData[1].data !== null
                ? !isNaN(baseData[1].data[index])
                  ? baseData[1].data[index]
                  : null
                : null,
            工程能力Cp: baseData[2].data !== null ? baseData[2].data[index] : null,
            工程能力Cpk: baseData[3].data !== null ? baseData[3].data[index] : null,
            map: !isEmpty(props.graphData2)
              ? props.graphData2
                  .filter((b: any) => {
                    switch (props.searchValue.searchType) {
                      case 1:
                        return b['year_month_day_hour'] === a;
                      case 2:
                        return b['year_month_day'] === a;
                      case 3:
                        return b['year_month'] === a;
                    }
                  })
                  .map((c: any) => c.map)
                  .flat()
              : '',
            work: !isEmpty(props.graphData2)
              ? props.graphData2
                  .filter((b: any) => {
                    switch (props.searchValue.searchType) {
                      case 1:
                        return b['year_month_day_hour'] === a;
                      case 2:
                        return b['year_month_day'] === a;
                      case 3:
                        return b['year_month'] === a;
                    }
                  })
                  .map((c: any) => c.work)
                  .flat()
              : '',
            deviceid: !isEmpty(props.graphData2)
              ? props.graphData2
                  .filter((b: any) => {
                    switch (props.searchValue.searchType) {
                      case 1:
                        return b['year_month_day_hour'] === a;
                      case 2:
                        return b['year_month_day'] === a;
                      case 3:
                        return b['year_month'] === a;
                    }
                  })
                  .map((c: any) => c.deviceid)
                  .flat()
              : '',
            lq_time: !isEmpty(props.graphData2)
              ? props.graphData2
                  .filter((b: any) => {
                    switch (props.searchValue.searchType) {
                      case 1:
                        return b['year_month_day_hour'] === a;
                      case 2:
                        return b['year_month_day'] === a;
                      case 3:
                        return b['year_month'] === a;
                    }
                  })
                  .map((c: any) => c.lq_time)
                  .flat()
              : '',
            serial: !isEmpty(props.graphData2)
              ? props.graphData2
                  .filter((b: any) => {
                    switch (props.searchValue.searchType) {
                      case 1:
                        return b['year_month_day_hour'] === a;
                      case 2:
                        return b['year_month_day'] === a;
                      case 3:
                        return b['year_month'] === a;
                    }
                  })
                  .map((c: any) => c.serial)
                  .flat()
              : ''
          });
        });
      } else {
        props.graphData2.forEach((a: any, index: number) => {
          tmp.push({
            work: a.work,
            deviceid: a.deviceid,
            map: a.map,
            for_export: a.lq_time,
            d0: type !== 1 && baseData[0].data !== null ? baseData[0].data[index] : null,
            d1: type !== 0 && baseData[0].data !== null ? baseData[0].data[index] : null,
            serial: a.serial
          });
        });
      }
    }
    return tmp;
  };

  const dataCylinderExport_column_h: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均－3σ'),
    d13: t('平均＋3σ')
  };

  const dataCylinderExport_column_rg: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均－3σ'),
    d13: t('平均＋3σ')
  };

  const dataCylinderExport_column_rg_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    cylinder_radius_gap: t('円筒半径誤差'),
    height_gap: t('円筒高さ誤差'),
    serial: t('シリアルナンバー')
  };

  useEffect(() => {
    if (dataCylinder_RG_SP.length > 0 && dataCylinder_H_SP.length > 0) {
      setStartProcess(false);
    }
  }, [dataCylinder_RG_SP, dataCylinder_H_SP]);

  const [listHeaders, setListHeaders] = useState(export_detail_all_list);
  useEffect(() => {
    if (g2clickData.length > 0) {
      let tmp_listHeaders: any = Object.keys(g2clickData[0]);
      g2clickData.forEach((a: any) => {
        for (let k in a) {
          if (a[k] === '' || a[k] === null) {
            delete tmp_listHeaders[k];
          }
        }
      });
      setListHeaders(
        export_detail_all_list.filter((a: any) => tmp_listHeaders.indexOf(a.field) !== -1)
      );
      setDetailListOpen(true);
    }
  }, [g2clickData]);

  //　シリンダーのグラフ・リスト出力
  return (
    <>
      <Grid container spacing={2} key={'graphSetType2rg'}>
        <ProgressBar startProcess={startProcess} />
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLine5
              title={`${t('円筒半径')} ${t('推移')}`}
              source={graphBaseData2.data_radius}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={dataCylinderExport.data_radius}
              exportFields={dataCylinderExport_column_rg}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('シリンダ半径')}`}
              height={'35vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={props.searchValue.map}
              onClick={getDataWithDate2rg}
              xlist={date_calc()}
              zoomChartId={'zoom-chart-13'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-13')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-13')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareCylinder
              title={`${t('円筒半径')} ${t('推移')}`}
              source={graphBaseData2.data_radius}
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値X'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={dataCylinderExport.data_radius}
              exportFields={dataCylinderExport_column_rg_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('シリンダ半径')}`}
              // mapName={props.searchValue.map}
              onClick={getDataByData2rg}
              zoomChartId={'zoom-chart-13'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-13')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-13')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {graphData2RGSelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData2RGSelectDate
                : `${t('選択シリアル')}:` + graphData2RGSelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData2RGSelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <></>
          )}
          <LFCChartsBarCylinderRG
            title={`${t('円筒半径')} ${t('分布')}`}
            source={dataCylinder_RG_SP}
            extraDatas={data2MaxAvgMin.data_radius}
            exportData={dataCylinder_RG_SP}
            exportFields={dataCylinder_RG_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('シリンダ半径')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2clickRG}
            zoomChartId={'zoom-chart-14'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-14')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-14')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMin={dataRG_LineMin}
            xLineMax={dataRG_LineMax}
            xRenge={dataRG_Renge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-15'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti5
                title={`${t('円筒半径ばらつき推移')}`}
                source={dataCylinder_RG_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData2.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCylinderRG}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('シリンダ半径')}`}
                zoomChartId={'zoom-chart-15'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-15')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-15')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch5
                title={`${t('円筒半径ばらつき推移')}`}
                source={dataCylinder_RG_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData2.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCylinderRG}
                exportFields={exportData3_column_cylinder_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('シリンダ半径')}`}
                zoomChartId={'zoom-chart-15'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-15')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-15')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data2MaxAvgMin.data_radius.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data2MaxAvgMin.data_radius.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d3)} mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d7)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d12)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    --
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d8)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d9)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">--</TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_radius.d10)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">--</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} key={'graphSetType2h'}>
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLine6
              title={`${t('円筒高さ')} ${t('推移')}`}
              source={graphBaseData2.data_height}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={dataCylinderExport.data_height}
              exportFields={dataCylinderExport_column_h}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('シリンダ高さ')}`}
              height={'35vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={props.searchValue.map}
              onClick={getDataWithDate2h}
              xlist={date_calc()}
              zoomChartId={'zoom-chart-16'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-16')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-16')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareCylinder
              title={`${t('円筒高さ')} ${t('推移')}`}
              source={graphBaseData2.data_height}
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値X'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={dataCylinderExport.data_height}
              exportFields={dataCylinderExport_column_h_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('シリンダ高さ')}`}
              // mapName={props.searchValue.map}
              onClick={getDataByData2h}
              zoomChartId={'zoom-chart-16'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-16')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-16')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {graphData2HSelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData2HSelectDate
                : `${t('選択シリアル')}:` + graphData2HSelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData2HSelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <></>
          )}
          <LFCChartsBarCylinderH
            title={`${t('円筒高さ')} ${t('分布')}`}
            source={dataCylinder_H_SP}
            extraDatas={data2MaxAvgMin.data_height}
            exportData={dataCylinder_H_SP}
            exportFields={dataCylinder_H_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('シリンダ高さ')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2clickH}
            zoomChartId={'zoom-chart-17'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-17')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-17')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMin={dataH_LineMin}
            xLineMax={dataH_LineMax}
            xRenge={dataH_Renge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-18'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti5
                title={t('円筒高さばらつき推移')}
                source={dataCylinder_H_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData2.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCylinderH}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('シリンダ高さ')}`}
                zoomChartId={'zoom-chart-18'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-18')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-18')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch5
                title={t('円筒高さばらつき推移')}
                source={dataCylinder_H_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData2.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCylinderH}
                exportFields={exportData3_column_cylinder_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t('シリンダ高さ')}`}
                zoomChartId={'zoom-chart-18'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-18')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-18')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data2MaxAvgMin.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data2MaxAvgMin.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d3)} mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d7)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d12)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_height.d14)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_height.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d8)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_height.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d9)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data2MaxAvgMin.data_height.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d5)} mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d10)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data2MaxAvgMin.data_height.d13)} mm
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/*分布グラフクリックした際の一覧表*/}
      <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Hole Compare 穴位置')}:${t(
          '詳細データ'
        )}`}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={listHeaders}
            rows={g2clickData}
            height="80vh"
            exportFilename={`${t('menu.page_name.ジョブ傾向分析')}(${t('穴位置')})_${t(
              '明細リスト'
            )}`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PartsCylinderComponent;
