import {Box, Dialog, DialogContent, Grid} from '@mui/material';
import React, {useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import Draggable from 'react-draggable';
import Paper, {PaperProps} from '@mui/material/Paper';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  showDetailPath: string;
  dsRow?: any;
  dsColumns?: any;
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}

/**
 * 画像表示ダイアログ
 * @param props
 * @returns
 */
const ImageDig = (props: Props) => {
  // 画像の位置情報を管理するための状態変数
  const [position, setPosition] = useState({x: 0, y: 0});

  // ドラッグ開始時の処理
  const handleDragStart = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
  };

  // ドラッグ中の処理
  const handleDrag = (e: React.DragEvent<HTMLImageElement>) => {
    e.preventDefault();
    setPosition({
      x: position.x + e.movementX,
      y: position.y + e.movementY
    });
  };

  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={false}
      fullWidth
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      {isImageLoaded && <LFCDialogTitle onClose={props.onClose}>画像</LFCDialogTitle>}
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          height: '100vh'
        }}
      >
        <Box
          style={{
            overflow: 'hidden',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%'
          }}
        >
          <img
            src={props.showDetailPath}
            alt=""
            style={{
              position: 'relative',
              left: position.x,
              top: position.y,
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
            draggable
            onDragStart={handleDragStart}
            onDrag={handleDrag}
            onError={handleImageError}
          />
        </Box>
      </DialogContent>
      {props.dsColumns && props.dsRow && (
        <LFCDataGrid
          columns={props.dsColumns}
          rows={props.dsRow}
          columnOrder={[
            {name: 'job_type_name', order: 0},
            {name: 'map', order: 1},
            {name: 'branch_no', order: 2}
          ]}
          style={{cursor: 'pointer'}}
          initialState={{
            left: ['job_type_name', 'map', 'branch_no', 'judge', 'img_filename'],
            right: ['actions']
          }}
          getRowClassName={(params: any) => `grid-css--${params.row.judge}`}
          hideFooter
          height="110px"
          autoHeight={true}
          pagination={false}
        />
      )}
    </Dialog>
  );
};

export default ImageDig;
