import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';
import DateTimePicker from '_components/inputs/DateTimePicker';

const ChipExchange = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [listData_10, setListData_10] = useState([]);
  const [listData_15, setListData_15] = useState([]);

  let today = new Date();
  today.setDate(1);
  const [searchValue, setSearchValue] = useState({
    select_year_month: dayjs().startOf('month').format('YYYY-MM')
  });

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, select_year_month: event.format('YYYY-MM')});
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    setStartProcess(true);
    setListData_10([]);
    setListData_15([]);
    apiFetch().then(() => setStartProcess(false));
  }, [searchValue.select_year_month]);

  const apiFetch = async () => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50030,
      parameters: {
        timespan: searchValue.select_year_month + '-01'
      },
      ds_state: setListData_10,
      name: `POC_${t('チップ交換')}_${t('一覧')}_10"`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50031,
      parameters: {
        timespan: searchValue.select_year_month + '-01'
      },
      ds_state: setListData_15,
      name: `POC_${t('チップ交換')}_${t('一覧')}_15"`,
      cancelToken: source.token,
      t
    });
  };

  let list_column = [
    {
      field: 'target_date',
      headerName: t('年月日'),
      description: t('年月日'),
      width: 120,
      hide: true
    },
    {
      field: 'target_day',
      headerName: t('月日'),
      description: t('月日'),
      width: 100
    },
    {
      field: '1st_time',
      headerName: `1${t('回目')}`,
      description: `1${t('回目')}`,
      width: 100
    },
    {
      field: '2nd_time',
      headerName: `2${t('回目')}`,
      description: `2${t('回目')}`,
      width: 100
    },
    {
      field: '3rd_time',
      headerName: `3${t('回目')}`,
      description: `3${t('回目')}`,
      width: 100
    },
    {
      field: '4th_time',
      headerName: `4${t('回目')}`,
      description: `4${t('回目')}`,
      width: 100
    },
    {
      field: '5th_time',
      headerName: `5${t('回目')}`,
      description: `5${t('回目')}`,
      width: 100
    }
  ];

  return (
    <GenericTemplate title={t('menu.page_name.チップ交換')}>
      <ProgressBar startProcess={startProcess} />
      <form>
        <LFCFormRowGroup>
          <DateTimePicker
            label={t('年月')}
            value={dayjs(searchValue.select_year_month)}
            format="YYYY/MM"
            views={['year', 'month']}
            onChange={event => {
              handleChange(event);
            }}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            {t('表')}１：#10(A)
            {/*{JSON.stringify(listData_10)}*/}
            <LFCDataGridSimple
              columns={list_column}
              rows={listData_10}
              height="90vh"
              exportFilename={`${t('チップ交換')}_10_A}`}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {t('表')}2：#15(B)
            {/*{JSON.stringify(listData_15)}*/}
            <LFCDataGridSimple
              columns={list_column}
              rows={listData_15}
              height="90vh"
              exportFilename={`${t('チップ交換')}_15_B}`}
            />
          </Grid>
        </Grid>
      </Box>
    </GenericTemplate>
  );
};

export default ChipExchange;
