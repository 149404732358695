import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  xAxisName?: string;
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: (prm: {data: any}) => void;
  mapName?: string;
  refJobTitle?: string;
  parameterName?: string;
  inputScale?: boolean;
  xlist: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxXYZ1 = (props: Props) => {
  const {t} = useTranslation();
  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);
  const [boxData, setBoxData] = useState([]);

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp_x: any = [];
    let tmp_y: any = [];
    let tmp_z: any = [];
    let tmpOutPut: any = [];
    tmpOutPut[0] = [];
    tmpOutPut[1] = [];
    tmpOutPut[2] = [];

    if (!isEmpty(props.xlist)) {
      props.xlist.forEach((a: string) => {
        if (props.source.xlist.find((k: string) => k === a)) {
          props.source.xlist.find((b: string, index: number) => {
            if (b === a) {
              tmp_x.push(props.source.data_x[index]);
              tmp_y.push(props.source.data_y[index]);
              tmp_z.push(props.source.data_z[index]);
            }
          });
        } else {
          tmp_x.push([]);
          tmp_y.push([]);
          tmp_z.push([]);
        }
      });

      tmp_x.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[0].push(CalcQuartile(a));
        } else {
          tmpOutPut[0].push([]);
        }
      });
      tmp_y.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[1].push(CalcQuartile(a));
        } else {
          tmpOutPut[1].push([]);
        }
      });
      tmp_z.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmpOutPut[2].push(CalcQuartile(a));
        } else {
          tmpOutPut[2].push([]);
        }
      });
    } else {
      props.source.xlist.find((b: string, index: number) => {
        tmpOutPut[0].push(CalcQuartile([props.source.data_x[index]]));
        tmpOutPut[1].push(CalcQuartile([props.source.data_y[index]]));
        tmpOutPut[2].push(CalcQuartile([props.source.data_z[index]]));
      });
    }
    setBoxData(tmpOutPut);
  };

  const [exportData, setExportData] = useState([]);

  useEffect(() => {
    makeBoxData();
  }, [props]);

  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(boxData) && !isEmpty(props.xlist)) {
      props.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(
            Array.from(
              new Set(
                props.exportData.filter((a: any) => a.map === props.mapName).map((b: any) => b.work)
              )
            )
          ),
          deviceid: String(
            Array.from(
              new Set(
                props.exportData
                  .filter((a: any) => a.map === props.mapName)
                  .map((b: any) => b.deviceid)
              )
            )
          ),
          map: props.mapName,
          for_export: a,
          x0: boxData[0][index][4],
          x1: boxData[0][index][3],
          x2: boxData[0][index][2],
          x3: boxData[0][index][1],
          x4: boxData[0][index][0],
          y0: boxData[1][index][4],
          y1: boxData[1][index][3],
          y2: boxData[1][index][2],
          y3: boxData[1][index][1],
          y4: boxData[1][index][0],
          z0: boxData[2][index][4],
          z1: boxData[2][index][3],
          z2: boxData[2][index][2],
          z3: boxData[2][index][1],
          z4: boxData[2][index][0]
        });
      });
      setExportData(tmp);
    } else if (!isEmpty(boxData) && isEmpty(props.xlist)) {
      props.source.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(
            Array.from(
              new Set(
                props.exportData.filter((a: any) => a.map === props.mapName).map((b: any) => b.work)
              )
            )
          ),
          deviceid: String(
            Array.from(
              new Set(
                props.exportData
                  .filter((a: any) => a.map === props.mapName)
                  .map((b: any) => b.deviceid)
              )
            )
          ),
          map: props.mapName,
          for_export: props.exportData[index] !== undefined ? props.exportData[index].lq_time : '',
          data_x: props.exportData[index] !== undefined ? props.source.data_x[index] : '',
          data_y: props.exportData[index] !== undefined ? props.source.data_y[index] : '',
          data_z: props.exportData[index] !== undefined ? props.source.data_z[index] : '',
          serial: props.exportData[index] !== undefined ? props.exportData[index].serial : ''
        });
      });
      setExportData(tmp);
    }
  }, [boxData]);

  return (
    <div>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            id: 1,
            type: 'scroll',
            itemWidth: 10,
            show: true,
            top: '90%',
            textStyle: {
              fontSize: 10
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: function (params: any) {
              if (params.seriesType === 'boxplot') {
                // [min,  Q1,  median (or Q2),  Q3,  max]
                return (
                  params.name +
                  ':' +
                  params.seriesName +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  Number(params.value[5]).toFixed(2) +
                  '<br/>' +
                  'Q3：' +
                  Number(params.value[4]).toFixed(2) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  Number(params.value[3]).toFixed(2) +
                  '<br/>' +
                  'Q1：' +
                  Number(params.value[2]).toFixed(2) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  Number(params.value[1]).toFixed(2)
                );
              } else if (params.seriesType === 'scatter') {
                return (
                  params.name + '<br/>' + t('はずれ値') + ':' + Number(params.value[1]).toFixed(2)
                );
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '8%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            name: props.xAxisName !== undefined ? props.xAxisName : '',
            data: !isEmpty(props.xlist) ? props.xlist : props.source.xlist,
            nameTextStyle: {
              fontSize: 10
            },
            nameLocation: 'end',
            // boundaryGap: true,
            // nameGap: 10,
            splitLine: {
              show: true
            },
            axisLabel: {
              // interval: 0,
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              name: t('X軸'),
              type: 'boxplot',
              data: boxData[0],
              color: 'rgb(243,6,6)'
            },
            {
              name: t('Y軸'),
              type: 'boxplot',
              data: boxData[1],
              color: 'rgb(30,150,50)'
            },
            {
              name: t('Z軸'),
              type: 'boxplot',
              data: boxData[2],
              color: 'rgb(0,100,255)'
            }
          ]
        }}
        exportData={exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : '100px'}
        onEvents={{
          click: (params: any) => {
            if (props.onClick != null) {
              params.mapName = props.mapName;
              params.refJobTitle = props.refJobTitle;
              params.parameterName = props.parameterName;
              props.onClick(params);
            }
          }
        }}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBoxXYZ1;
