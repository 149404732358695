import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  datas: any;
  datas2?: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  style?: any;
  height?: string;
  x_middle?: number;
  y_middle?: number;
  top?: string;
  left?: string;
  xaxisName: string;
  yaxisName: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
  xList?: string[];
}

/**
 * LFCChartsScatter
 * @param props
 * @returns
 */
const LFCChartsScatterDemo = (props: Props) => {
  return (
    <LFCBaseCharts
      exportData={props.exportData}
      exportFields={props.exportFields}
      option={{
        title: {
          text: props.title
        },
        legend: {
          data: ['a', 'b', 'c', 'd']
        },
        grid: [
          {
            top: '15%',
            left: '50',
            right: '5%',
            bottom: '20%'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: function (params: any) {
            return (
              'Current[A]:' + params[0].value[0] + '</br>' + 'Voltage[V]:' + params[0].value[1]
            );
          }
        },
        xAxis: {
          type: 'value',
          name: props.xaxisName,
          nameLocation: 'middle',
          nameRotate: 0,
          nameTextStyle: {
            padding: [20, 0, 0, 0],
            fontSize: 16
          },
          // min: 'dataMin',
          // max: 'dataMax',
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 16
          },
          data: props.xList
        },
        yAxis: {
          type: 'value',
          name: props.yaxisName,
          nameLocation: 'middle',
          nameRotate: 90,
          nameTextStyle: {
            padding: [0, 0, 20, 0],
            fontSize: 16
          },
          // min: 'dataMin',
          // max: 'dataMax',
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 16
          }
        },
        series: [
          {
            name: 'a',
            type: 'effectScatter',
            colorBy: 'data',
            rippleEffect: {
              number: 0,
              scale: 0,
              period: 0
            },
            data: props.datas[0],
            symbolSize: 20
          },
          {
            name: 'b',
            type: 'effectScatter',
            colorBy: 'data',
            rippleEffect: {
              number: 0,
              scale: 0,
              period: 0
            },
            data: props.datas[1],
            symbolSize: 20
          }
        ]
      }}
      exportFilename={props.exportFilename}
      height={props.height}
      style={props.style}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
    />
  );
};
export default LFCChartsScatterDemo;
