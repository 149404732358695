import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title?: string;
  source: any;
  x: {
    dsColumn: string;
    data: any;
  };
  y: {
    type: string;
    name: any;
    dsColumn: any;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  xlist: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarSc2 = (props: Props) => {
  const {t} = useTranslation();
  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    let tmp: any = [];

    if (!isEmpty(props.source) && !isEmpty(props.exportData)) {
      props.exportData.forEach((a: any, index: number) => {
        if (props.exportFields['serial'] !== undefined) {
          tmp.push({
            work: String(Array.from(new Set(props.exportData.map((b: any) => b.work)))).toString(),
            deviceid: String(
              Array.from(new Set(props.exportData.map((b: any) => b.deviceid)))
            ).toString(),
            map:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].map
                : '',
            lq_time:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].lq_time
                : '',
            branch_no:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].branch_no
                : '',
            higher_score:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].higher_score
                : '',
            lower_score:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].lower_score
                : '',
            serial:
              props.exportData.filter((b: any) => b.idx === a.idx)[0] !== undefined
                ? props.exportData.filter((b: any) => b.idx === a.idx)[0].serial
                : ''
          });
        } else {
          tmp.push({
            work:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? String(
                    props.exportData.filter(
                      (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                    )[0].work
                  ).toString()
                : '',
            deviceid:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? String(
                    props.exportData.filter(
                      (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                    )[0].deviceid
                  ).toString()
                : '',
            map:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? props.exportData.filter(
                    (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                  )[0].map
                : '',
            lq_time:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? props.exportData.filter(
                    (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                  )[0][props.x.dsColumn]
                : '',
            branch_no:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? props.exportData.filter(
                    (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                  )[0].branch_no
                : '',
            higher_score:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? props.exportData.filter(
                    (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                  )[0].higher_score
                : '',
            lower_score:
              props.exportData.filter(
                (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
              )[0] !== undefined
                ? props.exportData.filter(
                    (b: any) => b[props.x.dsColumn] === a[props.x.dsColumn]
                  )[0].lower_score
                : ''
          });
        }
      });
      setExportData(tmp);
    }
  }, [props.source, props.exportData]);

  const [data0, setData0] = useState([]);
  const [data1, setData1] = useState([]);
  useEffect(() => {
    if (props.source[0] !== undefined && props.exportFields['serial'] === undefined) {
      let tmp0: any = [];
      let tmp1: any = [];
      props.source[0].higher_score.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmp0.push(Number(a.flat()));
        } else {
          tmp0.push('');
        }
      });
      setData0(tmp0);
      props.source[0].lower_score.forEach((a: any) => {
        if (!isEmpty(a)) {
          tmp1.push(Number(a.flat()));
        } else {
          tmp1.push('');
        }
      });
      setData1(tmp1);
    } else if (props.source[0] !== undefined) {
      setData0(props.source[0].higher_score);
      setData1(props.source[0].lower_score);
    }
  }, [props]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: {
          top: '40',
          left: '55',
          right: '15',
          bottom: '30',
          containLabel: true
        },
        legend: {
          type: 'scroll',
          bottom: 0,
          itemWidth: 10,
          textStyle: {
            fontSize: 10
          }
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params: any) {
            let tmp: string = params[0].name;
            params.forEach((a: any) => {
              tmp += '<br/>' + t(a.seriesName) + ':' + a.value;
            });
            return tmp;
          }
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        color: props.color ? props.color : null,
        dataset: {
          source: props.source
        },
        xAxis: [
          {
            type: 'category',
            data:
              props.xlist !== null
                ? props.xlist
                : !isEmpty(props.exportData) &&
                  props.exportData.map((a: any) => a[props.x.dsColumn]),
            axisLabel: {
              rotate: '45',
              fontSize: 10
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(%)',
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            }
          }
        ],
        series: [
          {
            type: 'bar',
            name: t('凸方向'),
            color: '#E91E63',
            stack: 'total',
            data: data0
          },
          {
            type: 'bar',
            name: t('凹方向'),
            color: '#2196F3',
            stack: 'total',
            data: data1
          }
        ]
      }}
      height={props.height ? props.height : ''}
      exportData={exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomChartId === props.zoomedChartId}
    />
  );
};
export default LFCChartsBarSc2;
