import {Box, Grid, Typography} from '@mui/material';
import {DataGridPro, GridColumns} from '@mui/x-data-grid-pro';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LFCButton from '_components/inputs/LFCButton';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {renderCellExpand} from '_logics/LFCRenderUtil';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';

/**
 * 会社管理
 * @returns
 */
const CompanyMaintenance = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const [dsSecrets, setDsSecrets] = useState<any>([]);
  const [active_company_id, setActive_company_id] = useState<any>('');

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const onSearch = () => {
    // 会社情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(data => {
      const companydata = data.map((item: any, idx: number) => {
        return {
          id: String(idx),
          company_id: String(item['id']),
          company_name: String(item['name'])
        };
      });
      setDgRows(companydata);
    });

    // Secrets情報取得
    adminAPIAccess({
      apipath: 'GetCorpSettingList',
      parameters: {},
      cancelToken: source.token
    })
      .then(response => {
        console.log(response);
        setDsSecrets(response.data);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      });
  };

  /**
   * scDBConstrSecretsの中身を表示します
   */
  const onShowDBConstr = () => {
    alert(JSON.stringify(dsSecrets.scDBConstrSecrets, null, '  '));
  };

  /**
   * 行選択
   * @param selectedData
   */
  const onRowClick = (selectedData: any) => {
    // データ取得のタイムラグ対策
    if (Object.keys(dsSecrets).length >= 0) {
      setActive_company_id(selectedData.row.company_id);
    }
  };

  useEffect(() => {
    // マウント時処理
    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {
        field: 'company_id',
        headerName: `${t('会社ID')}`,
        description: `${t('会社ID')}`,
        width: 100,
        renderCell: renderCellExpand
      },
      {
        field: 'company_name',
        headerName: `${t('会社名')}`,
        description: `${t('会社名')}`,
        flex: 1,
        renderCell: renderCellExpand
      }
      // {
      //   field: "actions",
      //   type: "actions",
      //   width: 50,
      //   getActions: (params) => [
      //     <GridActionsCellItem icon={<ArrowForwardIcon />} label="詳細" onClick={() => onRowClick(params)} />,
      //   ],
      // },
    ]);
    onSearch();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const IMSI = (prm: {cid: string; data: any}) => {
    if (prm.cid === '' || Object.keys(dsSecrets).length === 0) {
      return <></>;
    }
    return (
      <LFCTitledCard title="LFC-Company-Secrets" titleSub={`${t('SIMのIMSIリスト')}`} transparent>
        {Object.keys(prm.data)
          .filter((key: string) => prm.cid === prm.data[key])
          .map(key => {
            return <Box sx={{wordWrap: 'break-word'}}>{String(key)}</Box>;
          })}
      </LFCTitledCard>
    );
  };

  const S3BucketName = (prm: {cid: string; data: any}) => {
    if (prm.cid === '' || Object.keys(dsSecrets).length === 0) {
      return <></>;
    }
    return (
      <LFCTitledCard
        title="LFC-S3Bucketname-Secrets"
        titleSub={`${t('S3のバケット名')}`}
        transparent
      >
        {Object.keys(prm.data)
          .filter((key: string) => prm.cid === key)
          .map(key => {
            return <Box sx={{wordWrap: 'break-word'}}>{String(prm.data[key])}</Box>;
          })}
      </LFCTitledCard>
    );
  };

  const IoTDbConstr = (prm: {cid: string; data: any}) => {
    if (prm.cid === '' || Object.keys(dsSecrets).length === 0) {
      return <></>;
    }
    return (
      <LFCTitledCard
        title="LFC-DBConstr-Secrets"
        titleSub={`${t('IoT側のDB接続情報')}`}
        transparent
      >
        {Object.keys(prm.data)
          .filter((key: string) => prm.cid === key)
          .map(key => {
            return <Box sx={{wordWrap: 'break-word'}}>{String(prm.data[key])}</Box>;
          })}
      </LFCTitledCard>
    );
  };

  const WebDbConstr = (prm: {cid: string; clusters: any; data: any}) => {
    if (prm.cid === '' || Object.keys(dsSecrets).length === 0) {
      return <></>;
    }
    const clusterKey = dsSecrets.scRDSClusterAndDBNames[prm.cid].split(':')[0];
    return (
      <LFCTitledCard title={clusterKey} titleSub={`${t('Web側のDB接続情報')}`} transparent>
        {Object.keys(prm.data)
          .filter((key: string) => clusterKey === key)
          .map(key =>
            Object.keys(prm.data[key]).map(key2 => {
              return (
                <Box sx={{wordWrap: 'break-word'}}>
                  {String(key2)} : {String(prm.data[key][key2])}
                </Box>
              );
            })
          )}
      </LFCTitledCard>
    );
  };
  const WebDbConstrDbName = (prm: {cid: string; data: any}) => {
    if (prm.cid === '' || Object.keys(dsSecrets).length === 0) {
      return <></>;
    }
    return (
      <LFCTitledCard
        title="LFC-RDS-ClusterAndDBNames"
        titleSub={`${t('Web側のDB接続情報(クラスタ接続情報シークレットキー名:DB名)')}`}
        transparent
      >
        {Object.keys(prm.data)
          .filter((key: string) => prm.cid === key)
          .map(key => {
            return <Box sx={{wordWrap: 'break-word'}}>{String(prm.data[key])}</Box>;
          })}
      </LFCTitledCard>
    );
  };

  return (
    <GenericTemplate title={t('menu.page_name.会社管理')}>
      <form ref={formRef}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {t('message.会社毎のAWS設定を参照する画面です。')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <LFCButton onClick={onShowDBConstr}>LFC-DBConstr-Secrets</LFCButton>&nbsp;
            <LFCButton color="primary" onClick={onSearch}>
              {t('message.最新の情報に更新')}
            </LFCButton>
          </Grid>
          <Grid item xs={4}>
            <LFCTitledCard title="m_company">
              <Box sx={{height: '70vh'}}>
                <DataGridPro
                  // apiRef={props.apiRef}
                  columns={dgColumns}
                  rows={dgRows}
                  density={'compact'}
                  // pagination
                  // autoPageSize
                  // components={{
                  //   Toolbar: CustomToolbar,
                  // }}
                  // componentsProps={{
                  //   toolbar: {
                  //     rows: props.rows,
                  //     fieldname: exportField,
                  //     filename: exportFileName,
                  //   },
                  // }}
                  onCellClick={onRowClick}
                  // disableSelectionOnClick
                  showCellRightBorder
                  showColumnRightBorder
                  // initialState={{ pinnedColumns: { right: ["actions"] } }}
                  // initialState={
                  //   props.initialState
                  //     ? { pinnedColumns: props.initialState }
                  //     : {
                  //         pinnedColumns: {
                  //           left: [],
                  //           right: [],
                  //         },
                  //       }
                  // }
                  // rowHeight={38}
                />
              </Box>
            </LFCTitledCard>
          </Grid>
          <Grid item xs={8} container spacing={2}>
            <Grid item xs={12}>
              <IoTDbConstr cid={active_company_id} data={dsSecrets.scDBConstrSecrets}></IoTDbConstr>
            </Grid>
            <Grid item xs={12}>
              <WebDbConstr
                cid={active_company_id}
                clusters={dsSecrets.scRDSClusterAndDBNames}
                data={dsSecrets.scRDSDBConnects}
              ></WebDbConstr>
            </Grid>
            <Grid item xs={12}>
              <WebDbConstrDbName
                cid={active_company_id}
                data={dsSecrets.scRDSClusterAndDBNames}
              ></WebDbConstrDbName>
            </Grid>
            <Grid item xs={6}>
              <IMSI cid={active_company_id} data={dsSecrets.scCompanySecrets}></IMSI>
            </Grid>
            <Grid item xs={6}>
              <S3BucketName
                cid={active_company_id}
                data={dsSecrets.scS3BucketnameSecrets}
              ></S3BucketName>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </GenericTemplate>
  );
};

export default CompanyMaintenance;
