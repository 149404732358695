import {Visibility, VisibilityOff} from '@mui/icons-material';
import {Box, IconButton, InputAdornment, InputBaseComponentProps, TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useRef, useState} from 'react';

/**
 * Styles
 */
const useStyles = makeStyles(() => ({
  input: {
    /*オートコンプリートが適用された時の背違和感の対応*/
    '&:-webkit-autofill': {
      transition: 'background-color 5000s',
      // "-webkit-text-fill-color": "#FFF !important", //ライトテーマの時に見えなくなるので一旦コメントアウト
      boxShadow: '0 0 0 1000px transparent inset !important'
    }
  }
}));

/**
 * 引数
 */
interface Props {
  name: string;
  label: string;
  value: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: any;
  fullWidth?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  margin?: 'dense' | 'normal' | 'none';
  required?: boolean;
  size?: 'small' | 'medium';
  inputProps?: InputBaseComponentProps;
}

/**
 * LFCPasswordField
 */
const LFCPasswordField = (props: Props) => {
  const classes = useStyles();
  const myRef = useRef<HTMLInputElement>(null!);
  const [err, setErr] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /**
   * Text入力時
   * @param event
   */
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErr(!event?.target.checkValidity());
    if (props.onChange) {
      props.onChange(event);
    }
  };
  /**
   * フォーカスアウト時
   * @param event
   */
  const handleFocusOut = (event: React.FocusEvent<HTMLInputElement>) => {
    setErr(!event?.target?.checkValidity());
  };

  /**
   * パスワード表示非表示切替
   */
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box style={props.style}>
      <TextField
        type={showPassword ? 'text' : 'password'}
        name={props.name}
        label={props.label}
        value={props.value}
        inputRef={myRef}
        error={err}
        helperText={err && myRef.current?.validationMessage}
        onChange={handleInputChange}
        onBlur={handleFocusOut}
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
        size={props.size !== undefined ? props.size : 'small'}
        fullWidth={props.fullWidth !== undefined ? props.fullWidth : false}
        disabled={props.disabled !== undefined ? props.disabled : false}
        autoFocus={props.autoFocus !== undefined ? props.autoFocus : false}
        required={props.required !== undefined ? props.required : false}
        margin={props.margin}
        InputProps={{
          classes: {input: classes.input},
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClickShowPassword}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
        inputProps={props.inputProps !== undefined ? props.inputProps : {}}
        autoComplete="new-password"
      />
    </Box>
  );
};
export default LFCPasswordField;
