import {Box, Divider, Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCSavedCondition, {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {ALL} from '_logics/LFCConst';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import {LOCATIONID_WELDING_MAIN, PAGEID_WELDING_PRODUCTION_STATUS} from '_logics/LFCPageId';
import {getLFCData, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import TraceAbilitySearchDetailDig from './TraceAbilitySearchDetailDig';
import LFCSelectFormMachineWelding from '_components-with-data/inputs/LFCSelectFormMachineWelding';
import LFCSelectFormWorkWelding from '_components-with-data/inputs/LFCSelectFormWorkWelding';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import {useSnackbar} from 'notistack';

const TraceAbilitySearchPage = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const apiRef = useGridApiRef();
  const imageFileUrl = (params: any) => {
    if (params !== undefined) {
      const imageUrl = `/images/welding/${params.formattedValue}.jpg`;
      return <img src={imageUrl} width="100%" style={{cursor: 'pointer'}} alt="" />;
    } else {
      return <></>;
    }
  };

  const [data, setData] = useState<[]>([]);

  const BASE_COL: GridColDef[] = [
    {
      field: 'exec_time',
      headerName: `${t('日時')}`,
      description: `${t('日時')}`,
      width: 200,
      renderCell: (prms: GridRenderCellParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {field: 'machine', headerName: `${t('マシン名')}`, description: `${t('マシン名')}`, width: 200},
    {field: 'work', headerName: `${t('製品番号')}`, description: `${t('製品番号')}`, width: 100},
    {field: 'judge', headerName: `${t('判定結果')}`, description: `${t('判定結果')}`, width: 100},
    {
      field: 'serial',
      headerName: `${t('シリアルNo')}`,
      description: `${t('シリアルNo')}`,
      width: 100
    },
    {
      field: 'work_type',
      headerName: `${t('ワーク形状')}`,
      description: `${t('ワーク形状')}`,
      width: 100,
      renderCell: imageFileUrl
    },
    {field: 'mtcode', headerName: `${t('材質')}`, description: `${t('材質')}`, width: 100},
    {
      field: 'boardthickness',
      headerName: `${t('板厚(mm)')}`,
      description: `${t('板厚(mm)')}`,
      width: 100
    },
    {
      field: 'workheight',
      headerName: `${t('高さ(mm)')}`,
      description: `${t('高さ(mm)')}`,
      width: 100
    },
    {field: 'splicecode', headerName: `${t('継手')}`, description: `${t('継手')}`, width: 100},
    {
      field: 'place_count',
      headerName: `${t('総設置回数')}`,
      description: `${t('総設置回数')}`,
      width: 100
    }
  ];
  const dgColumns: GridColDef[] = BASE_COL;
  const [open, setOpen] = useState(false);
  const [openData, setOpenData] = useState<{uuid: string; exec_time: string}>({
    uuid: '1',
    exec_time: '1'
  });
  const handleClose = () => {
    setOpenData({
      uuid: '1',
      exec_time: '1'
    });
    setOpen(false);
  };

  const goDetail = (data: any) => {
    setOpenData({
      uuid: data.uuid,
      exec_time: data.exec_time
    });
    setOpen(true);
  };

  const onCellClick = (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    goDetail(params.row);
  };

  const COLUMN_ORDER: any = [{name: 'exec_time', order: 1}];

  // 初期設定条件を取得&設定
  const getDefaultCondition = useGetDefaultCondition();
  const defaultCondition = getDefaultCondition(
    PAGEID_WELDING_PRODUCTION_STATUS,
    LOCATIONID_WELDING_MAIN
  );
  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: fmtYYYYMMDDHHmmss(dayjs().add(-7, 'd')),
    select_date_to: fmtYYYYMMDDHHmmss(dayjs()),
    machine: null,
    work: null,
    judge: 9,
    serial: null,
    ...defaultCondition
  });

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());
  const apiFetch = async () => {
    setStartProcess(true);

    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 42026,
        parameters: {
          machine: searchValue.machine === t('すべて') ? null : searchValue.machine,
          serial: searchValue.serial ?? null,
          work: searchValue.work === t('すべて') ? null : searchValue.work,
          judge: searchValue.judge === 9 ? null : searchValue.judge,
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to
        },
        ds_state: setData,
        name: `${t('トレサビ検索')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  //ページ読み込み完了に実行
  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 検索イベント
   * @returns
   */
  const doSearch = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    apiFetch();
  };

  // 検索条件保存処理関連 ----------------------------------------->
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const [condition, setCondition] = useState<ConditionParamer[]>([]);

  /***
   * 検索条件保存画面が開いた際の処理
   */
  const onLoadSavedCondition = () => {
    setCondition([
      {
        name: 'select_date_from',
        value: searchValue.select_date_from,
        valueLabel: searchValue.select_date_from,
        colName: t('対象期間From'),
        colWidth: 300
      },
      {
        name: 'select_date_to',
        value: searchValue.select_date_to,
        valueLabel: searchValue.select_date_to,
        colName: t('対象期間To'),
        colWidth: 300
      },
      {
        name: 'machine',
        value: searchValue.machine,
        valueLabel: searchValue.machine === null ? ALL : searchValue.machine,
        colName: t('マシン'),
        colWidth: 300
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work === null ? ALL : searchValue.work,
        colName: t('製造番号'),
        colWidth: 300
      },
      {
        name: 'judge',
        value: searchValue.judge,
        valueLabel: searchValue.judge === 9 ? ALL : searchValue.judge,
        colName: t('総合判定'),
        colWidth: 300
      },
      {
        name: 'serial',
        value: searchValue.serial,
        valueLabel: searchValue.serial,
        colName: t('シリアルNo'),
        colWidth: 300
      }
    ]);
    setOpenSavedCondition(true);
  };

  /**
   * 検索条件保存画面で保存した検索条件を選んだ場合の処理
   * @param conditionValues
   */
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.板金溶接')}:${t('menu.page_name.トレサビ検索')}`}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <LFCFormRowGroup>
          <LFCDatetimePicker
            name={t('select_date_from')}
            label={t('対象期間From')}
            value={searchValue.select_date_from}
            required
            onChange={handleChange}
          />
          <LFCDatetimePicker
            name={t('select_date_to')}
            label={t('対象期間To')}
            value={searchValue.select_date_to}
            required
            onChange={handleChange}
          />
          <LFCSelectFormMachineWelding
            name={'machine'}
            label={`${t('マシン名')}`}
            value={searchValue.machine}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCSelectFormWorkWelding
            name={'work'}
            label={`${t('製造番号')}`}
            value={searchValue.work}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCSelectFormJudge
            name={'judge'}
            value={searchValue.judge}
            onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
            multiple={false}
          />
          <LFCTextField
            name="serial"
            label={t('シリアルNo')}
            value={searchValue.serial}
            onChange={event => handleInputChange(event, searchValue, setSearchValue)}
          />
          <LFCButton color="primary" onClick={() => doSearch()}>
            {t('検索')}
          </LFCButton>
          <LFCSavedCondition
            open={openSavedCondition}
            pageId={PAGEID_WELDING_PRODUCTION_STATUS}
            locationNo={LOCATIONID_WELDING_MAIN}
            onLoad={onLoadSavedCondition}
            onSelect={onSelectSavedCondition}
            onClose={() => setOpenSavedCondition(false)}
            conditions={condition}
          />
        </LFCFormRowGroup>
      </form>
      <Divider />
      <Box mt={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <LFCDataGrid
              apiRef={apiRef}
              columns={dgColumns}
              rows={data}
              height="75vh"
              onCellClick={onCellClick}
              exportFilename={`L-ROBOT_${t('検索')}`}
              columnOrder={COLUMN_ORDER}
              rowHeight={100}
            />
          </Grid>
        </Grid>
      </Box>
      <TraceAbilitySearchDetailDig
        open={open}
        onClose={handleClose}
        uuid={openData.uuid}
        exec_time={openData.exec_time}
      />
    </GenericTemplate>
  );
};

export default TraceAbilitySearchPage;
