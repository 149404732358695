export const colorPallet: any = [
  '#660099',
  '#cc3366',
  '#ffcc99',
  '#99ff33',
  '#00ff99',
  '#003399',
  '#9933cc',
  '#ff6699',
  '#ff9933',
  '#66ff00',
  '#cc9966',
  '#66cc00',
  '#99ffcc',
  '#3399ff',
  '#9900ff',
  '#990033',
  '#cc6600',
  '#ccff99',
  '#33ff99',
  '#0066ff',
  '#009966',
  '#3366cc',
  '#cc66ff',
  '#cc0033',
  '#ff9900',
  '#339900',
  '#33cc99',
  '#6699ff',
  '#9900cc',
  '#ff3366',
  '#996600',
  '#66cc33',
  '#66ffcc',
  '#0033cc',
  '#cc33ff',
  '#ff0033',
  '#cc9933',
  '#99ff66',
  '#00cc99',
  '#3366ff',
  '#6699cc',
  '#6600cc',
  '#ff99cc',
  '#663300',
  '#669933',
  '#66cc99',
  '#0066cc',
  '#cc99ff',
  '#ff3399',
  '#996633',
  '#99cc66',
  '#00cc66',
  '#99ccff',
  '#9933ff',
  '#ff0066',
  '#cc00ff',
  '#330000',
  '#ffcc66',
  '#33cc00',
  '#33ffcc',
  '#0033ff',
  '#330033',
  '#663333',
  '#cc9900',
  '#66ff33',
  '#6f5436',
  '#89c997',
  '#68a4d9',
  '#ea618e',
  '#aaaab0',
  '#cc3399',
  '#ff3300',
  '#99cc00',
  '#33ff66',
  '#006699',
  '#6633cc',
  '#ff66cc',
  '#cc3300',
  '#ccff33',
  '#009933',
  '#3399cc',
  '#9966ff',
  '#ff0099',
  '#ff6633',
  '#669900',
  '#33cc66',
  '#66ccff',
  '#6600ff',
  '#660033',
  '#993300',
  '#99cc33',
  '#66ff99',
  '#0099ff',
  '#330066',
  '#993366',
  '#cc6633',
  '#ccff66',
  '#00ff66',
  '#003366',
  '#663399',
  '#cc6699',
  '#ff9966',
  '#99ff00',
  '#006633',
  '#336699',
  '#9966cc',
  '#cc0066',
  '#ff6600',
  '#336600',
  '#339966',
  '#866629',
  '#37a34a',
  '#bcc7d7',
  '#b0778c',
  '#abb1ad',
  '#fad09e',
  '#009944',
  '#bccddb',
  '#6e4a55',
  '#9fa09e',
  '#f6ae54',
  '#bee0ce',
  '#b2cbe4',
  '#b33e5c',
  '#9d8e87',
  '#f3981d',
  '#a4d5bd',
  '#a2c2e6',
  '#942343',
  '#9f9f98',
  '#f39800',
  '#004d25',
  '#a3b9e0',
  '#c82c55',
  '#898989',
  '#f6e5cc',
  '#3cb37a',
  '#94adda',
  '#e73562',
  '#898880',
  '#eae1cf',
  '#00984f',
  '#7a99cf',
  '#e73562',
  '#7e837f',
  '#ba8b40',
  '#009854',
  '#6c9bd2',
  '#d70035',
  '#7d7b83',
  '#c5a05a',
  '#00a960',
  '#001e43',
  '#e8383d',
  '#7d7d7d',
  '#caac71',
  '#00a968',
  '#202f55',
  '#6c2735',
  '#736d71',
  '#00ffcc',
  '#000033',
  '#663366',
  '#660000',
  '#ffcc33',
  '#33ff00',
  '#003333',
  '#333366',
  '#660066',
  '#996666',
  '#ffcc00',
  '#003300',
  '#336666',
  '#000066',
  '#996699',
  '#993333',
  '#333300',
  '#336633',
  '#006666',
  '#666699',
  '#993399',
  '#990000',
  '#666633',
  '#006600',
  '#669999',
  '#333399',
  '#990099',
  '#cc9999',
  '#666600',
  '#669966',
  '#339999',
  '#000099',
  '#cc99cc',
  '#cc6666',
  '#999966',
  '#339933',
  '#009999',
  '#9999cc',
  '#cc66cc',
  '#cc3333',
  '#999933',
  '#009900',
  '#99cccc',
  '#6666cc',
  '#cc33cc',
  '#cc0000',
  '#999900',
  '#99cc99',
  '#66cccc',
  '#3333cc',
  '#cc00cc',
  '#ffcccc',
  '#cccc99',
  '#66cc66',
  '#33cccc',
  '#0000cc',
  '#ffccff',
  '#ff9999',
  '#cccc66',
  '#33cc33',
  '#00cccc',
  '#ccccff',
  '#ff99ff',
  '#ff6666',
  '#cccc33',
  '#00cc00',
  '#ccffff',
  '#9999ff',
  '#ff66ff',
  '#ff3333',
  '#ccffcc',
  '#99ffff',
  '#6666ff',
  '#ff33ff',
  '#ff0000',
  '#c2894b',
  '#417038',
  '#0068b7',
  '#debecc',
  '#b5b5ae',
  '#ac6b25',
  '#387d39',
  '#0075c2',
  '#e5c1cd',
  '#abb1b5',
  '#e8c59c',
  '#bee0c2',
  '#0075c2',
  '#eb6ea0',
  '#b4ada9',
  '#c49a6a',
  '#79c06e',
  '#4496d3',
  '#e95388',
  '#afafb0',
  '#fac559',
  '#288c66',
  '#192f60',
  '#6c272d',
  '#666c67',
  '#e5a323',
  '#00885a',
  '#192f60',
  '#da536e',
  '#626063',
  '#c4972f',
  '#006948',
  '#043c78',
  '#e95464',
  '#594e52',
  '#f2d58a',
  '#005c42',
  '#003f8e',
  '#f19ca7',
  '#4e454a',
  '#eedcb3',
  '#00533f',
  '#26499d',
  '#f5b2b2',
  '#504946',
  '#cccc33',
  '#00cc00',
  '#ccffff',
  '#9999ff',
  '#ff66ff',
  '#ff3333',
  '#cccc00',
  '#ccffcc',
  '#99ffff',
  '#6666ff',
  '#ead7a4',
  '#54917f',
  '#4753a2',
  '#f5b2ac',
  '#24140e',
  '#ffe9a9',
  '#a5c9c1',
  '#434da2',
  '#e29399',
  '#A00121',
  '#ff7f7f',
  '#ff7fbf',
  '#ff7fff',
  '#bf7fff',
  '#7f7fff',
  '#7fbfff',
  '#7fffff',
  '#7fffbf',
  '#7fff7f',
  '#bfff7f',
  '#ffff7f',
  '#ffbf7f',
  '#ff8484',
  '#ff84c1',
  '#ff84ff',
  '#c184ff',
  '#8484ff',
  '#84c1ff',
  '#84ffff',
  '#84ffc1',
  '#84ff84',
  '#c1ff84',
  '#ffff84',
  '#ffc184',
  '#ff8989',
  '#ff89c4',
  '#ff89ff',
  '#c489ff',
  '#8989ff',
  '#89c4ff',
  '#89ffff',
  '#89ffc4',
  '#89ff89',
  '#c4ff89',
  '#ffff89',
  '#ffc489',
  '#ff8e8e',
  '#ff8ec6',
  '#ff8eff',
  '#c68eff',
  '#8e8eff',
  '#8ec6ff',
  '#8effff',
  '#8effc6',
  '#8eff8e',
  '#c6ff8e',
  '#ffff8e',
  '#ffc68e',
  '#ff9393',
  '#ff93c9',
  '#ff93ff',
  '#c993ff',
  '#9393ff',
  '#93c9ff',
  '#93ffff',
  '#93ffc9',
  '#93ff93',
  '#c9ff93',
  '#ffff93',
  '#ffc993',
  '#ff9999',
  '#ff99cc',
  '#ff99ff',
  '#cc99ff',
  '#9999ff',
  '#99ccff',
  '#99ffff',
  '#99ffcc',
  '#99ff99',
  '#ccff99',
  '#ffff99',
  '#ffcc99',
  '#ff9e9e',
  '#ff9ece',
  '#ff9eff',
  '#ce9eff',
  '#9e9eff',
  '#9eceff',
  '#9effff',
  '#9effce',
  '#9eff9e',
  '#ceff9e',
  '#ffff9e',
  '#ffce9e',
  '#ffa3a3',
  '#ffa3d1',
  '#ffa3ff',
  '#d1a3ff',
  '#a3a3ff',
  '#a3d1ff',
  '#a3ffff',
  '#a3ffd1',
  '#a3ffa3',
  '#d1ffa3',
  '#ffffa3',
  '#ffd1a3',
  '#ffa8a8',
  '#ffa8d3',
  '#ffa8ff',
  '#d3a8ff',
  '#a8a8ff',
  '#a8d3ff',
  '#a8ffff',
  '#a8ffd3',
  '#a8ffa8',
  '#d3ffa8',
  '#ffffa8',
  '#ffd3a8',
  '#ffadad',
  '#ffadd6',
  '#ffadff',
  '#d6adff',
  '#adadff',
  '#add6ff',
  '#adffff',
  '#adffd6',
  '#adffad',
  '#d6ffad',
  '#ffffad',
  '#ffd6ad',
  '#ffb2b2',
  '#ffb2d8',
  '#ffb2ff',
  '#d8b2ff',
  '#b2b2ff',
  '#b2d8ff',
  '#b2ffff',
  '#b2ffd8',
  '#b2ffb2',
  '#d8ffb2',
  '#ffffb2',
  '#ffd8b2',
  '#ffb7b7',
  '#ffb7db',
  '#ffb7ff',
  '#dbb7ff',
  '#b7b7ff',
  '#b7dbff',
  '#b7ffff',
  '#b7ffdb',
  '#b7ffb7',
  '#dbffb7',
  '#ffffb7',
  '#ffdbb7',
  '#ffbcbc',
  '#ffbcdd',
  '#ffbcff',
  '#ddbcff',
  '#bcbcff',
  '#bcddff',
  '#bcffff',
  '#bcffdd',
  '#bcffbc',
  '#ddffbc',
  '#ffffbc',
  '#ffddbc',
  '#ffc1c1',
  '#ffc1e0',
  '#ffc1ff',
  '#e0c1ff',
  '#c1c1ff',
  '#c1e0ff',
  '#c1ffff',
  '#c1ffe0',
  '#c1ffc1',
  '#e0ffc1',
  '#ffffc1',
  '#ffe0c1',
  '#ffc6c6',
  '#ffc6e2',
  '#ffc6ff',
  '#e2c6ff',
  '#c6c6ff',
  '#c6e2ff',
  '#c6ffff',
  '#c6ffe2',
  '#c6ffc6',
  '#e2ffc6',
  '#ffffc6',
  '#ffe2c6',
  '#ffcccc',
  '#ffcce5',
  '#ffccff',
  '#e5ccff',
  '#ccccff',
  '#cce5ff',
  '#ccffff',
  '#ccffe5',
  '#ccffcc',
  '#e5ffcc',
  '#ffffcc',
  '#ffe5cc',
  '#ffd1d1',
  '#ffd1e8',
  '#ffd1ff',
  '#e8d1ff',
  '#d1d1ff',
  '#d1e8ff',
  '#d1ffff',
  '#d1ffe8',
  '#d1ffd1',
  '#e8ffd1',
  '#ffffd1',
  '#ffe8d1',
  '#ffd6d6',
  '#ffd6ea',
  '#ffd6ff',
  '#ead6ff',
  '#d6d6ff',
  '#d6eaff',
  '#d6ffff',
  '#d6ffea',
  '#d6ffd6',
  '#eaffd6',
  '#ffffd6',
  '#ffead6',
  '#ffdbdb',
  '#ffdbed',
  '#ffdbff',
  '#eddbff',
  '#dbdbff',
  '#dbedff',
  '#dbffff',
  '#dbffed',
  '#dbffdb',
  '#edffdb',
  '#ffffdb',
  '#ffeddb',
  '#ffe0e0',
  '#ffe0ef',
  '#ffe0ff',
  '#efe0ff',
  '#e0e0ff',
  '#e0efff',
  '#e0ffff',
  '#e0ffef',
  '#e0ffe0',
  '#efffe0',
  '#ffffe0',
  '#ffefe0',
  '#ffe5e5',
  '#ffe5f2',
  '#ffe5ff',
  '#f2e5ff',
  '#e5e5ff',
  '#e5f2ff',
  '#e5ffff',
  '#e5fff2',
  '#e5ffe5',
  '#f2ffe5',
  '#ffffe5',
  '#fff2e5',
  '#ffeaea',
  '#ffeaf4',
  '#ffeaff',
  '#f4eaff',
  '#eaeaff',
  '#eaf4ff',
  '#eaffff',
  '#eafff4',
  '#eaffea',
  '#f4ffea',
  '#ffffea',
  '#fff4ea',
  '#ffefef',
  '#ffeff7',
  '#ffefff',
  '#f7efff',
  '#efefff',
  '#eff7ff',
  '#efffff',
  '#effff7',
  '#efffef',
  '#f7ffef',
  '#ffffef',
  '#fff7ef',
  '#fff4f4',
  '#fff4f9',
  '#fff4ff',
  '#f9f4ff',
  '#f4f4ff',
  '#f4f9ff',
  '#f4ffff',
  '#f4fff9',
  '#f4fff4',
  '#f9fff4',
  '#fffff4',
  '#fff9f4',
  '#fff9f9',
  '#fff9fc',
  '#fff9ff',
  '#fcf9ff',
  '#f9f9ff',
  '#f9fcff',
  '#f9ffff',
  '#f9fffc',
  '#f9fff9',
  '#fcfff9',
  '#fffff9',
  '#fffcf9'
];
