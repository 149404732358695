import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  datas: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  style?: any;
  height?: string;
  min?: number;
  max?: number;
}

/**
 * LFCChartsScatterFittng
 * @param props
 * @returns
 */
const LFCChartsScatterFittng = (props: Props) => {
  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: [
          {
            top: '40',
            left: '60',
            right: '20%',
            bottom: '20'
          },
          {
            top: '40',
            left: '90%',
            right: '20',
            bottom: '20'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: [
          {
            min:
              props.datas[0]['difference_x'] > 10
                ? -1 * Math.ceil(props.datas[0]['difference_x'] / 5) * 5
                : -10,
            max:
              props.datas[0]['difference_x'] > 10
                ? Math.ceil(props.datas[0]['difference_x'] / 5) * 5
                : 10,
            gridIndex: 0
          },
          {
            show: false,
            min: 0,
            max: 0,
            gridIndex: 1
          }
        ],
        yAxis: [
          {
            min:
              props.datas[0]['difference_y'] > 10
                ? -1 * Math.ceil(props.datas[0]['difference_y'] / 5) * 5
                : -10,
            max:
              props.datas[0]['difference_y'] > 10
                ? Math.ceil(props.datas[0]['difference_y'] / 5) * 5
                : 10,
            gridIndex: 0
          },
          {
            min:
              props.datas[0]['difference_y'] > 10
                ? -1 * Math.ceil(props.datas[0]['difference_z'] / 5) * 5
                : -10,
            max:
              props.datas[0]['difference_y'] > 10
                ? Math.ceil(props.datas[0]['difference_z'] / 5) * 5
                : 10,
            gridIndex: 1
          }
        ],
        series: [
          {
            type: 'scatter',
            xAxisIndex: 0,
            yAxisIndex: 0,
            encode: {tooltip: [0, 1]},
            data: [[props.datas[0]['difference_x'], props.datas[0]['difference_y']]],
            label: {
              show: true,
              position: 'top',
              align: 'center',
              formatter: function () {
                return (
                  '\nX: ' +
                  Number(props.datas[0]['difference_x']).toFixed(4) +
                  '\nY: ' +
                  Number(props.datas[0]['difference_y']).toFixed(4)
                );
              }
            }
          },
          {
            type: 'scatter',
            xAxisIndex: 1,
            yAxisIndex: 1,
            encode: {tooltip: [0, 1]},
            data: [[0, props.datas[0]['difference_z']]],
            label: {
              show: true,
              position: 'top',
              align: 'center',
              formatter: function () {
                return 'Z: ' + Number(props.datas[0]['difference_z']).toFixed(4);
              }
            }
          }
        ],
        dataZoom: [
          {
            id: 'dataZoomX',
            type: 'inside',
            xAxisIndex: [0],
            filterMode: 'filter'
          },
          {
            id: 'dataZoomY',
            type: 'inside',
            yAxisIndex: [0],
            filterMode: 'filter'
          }
        ]
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
      style={props.style}
    />
  );
};
export default LFCChartsScatterFittng;
