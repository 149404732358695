import {Dialog, DialogContent, Grid, Button, Tooltip} from '@mui/material';
import {GridColDef, GridRenderCellParams} from '@mui/x-data-grid-pro';
import {useEffect, useState} from 'react';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useTranslation} from 'react-i18next';
import {green} from '@mui/material/colors';
import {adminAPIAccess, getLFCData} from '_logics/LFCUtil';
import BarComponent from '../BarComponent';
import {useSnackbar} from 'notistack';
import {fmtYYYYMMDDHHmmss} from '_logics/LFCFormatUtil';
import dayjs from 'dayjs';
import DownloadIcon from '@mui/icons-material/Download';

type Props = {
  open: boolean;
  onClose: () => void;
  data: any;
};

const TraceAbilitySearchDetailDig2 = ({open, onClose, data}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();
  const [startProcess, setStartProcess] = useState(true);
  const [weldingDetailRows, setWeldingDetailRows] = useState<any>([]);

  useEffect(() => {
    if (open) {
      setStartProcess(true);
      Promise.allSettled([
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 42025,
          parameters: {
            welding_data_number: data.welding_data_number,
            exec_time: data.exec_time
          },
          ds_state: setWeldingDetailRows,
          name: `${t('トレサビ検索詳細2')}`,
          cancelToken: source.token,
          t
        })
      ]).then(() => {
        setStartProcess(false);
      });
    }
  }, [open]);

  const [datas, setDatas] = useState<any>([]);

  useEffect(() => {
    const tmp = weldingDetailRows.map((row: any, index: number) => {
      return {
        welding_data_number: row.welding_data_number,
        update_time: row.update_date_time,
        welding_condition_number: row.welding_condition_number,
        rise_process: row.rise_process,
        wire_post_process: row.wire_post_process,
        weld_release_number: row.weld_release_number,
        weld_release: row.weld_release,
        welding_condition_slope_specification: row.welding_condition_slope_specification,
        gas_purge_time: row.gas_purge_time,
        gas_pre_flow_time: row.gas_pre_flow_time,
        gas_post_flow_time: row.gas_post_flow_time,
        as_head_execution_time: row.as_head_execution_time,
        running_crater_processing_time: row.running_crater_processing_time
      };
    });
    const uniqueObjects = Array.from(new Set(tmp.map(JSON.stringify)))
      .map((e: any) => JSON.parse(e)) // 重複を除去したオブジェクトをパースする
      .map((item, index) => {
        return {...item, id: index + 1}; // 元のデータに id プロパティを追加
      });
    setDatas(uniqueObjects);
  }, [weldingDetailRows]);

  const weldingDetailColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'welding_condition_name',
      headerName: '溶接条件名',
      description: 'condition',
      width: 100
    },
    {
      field: 'welding_condition_number',
      headerName: '溶接条件番号',
      description: 'number',
      width: 100
    },
    {field: 'voltage', headerName: '電圧', description: 'volt', width: 100},
    {field: 'current', headerName: '電流', description: 'ampare', width: 100},
    {field: 'welding_speed', headerName: '溶接速度', description: 'speed', width: 100},
    {
      field: 'processing_time',
      headerName: '処理時間',
      description: 'processing_time',
      width: 100
    }
  ];

  const weldingColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {
      field: 'welding_data_number',
      headerName: '溶接データ番号',
      description: 'welding_data_number',
      width: 100
    },
    {
      field: 'update_time',
      headerName: '更新日時',
      description: 'update_time',
      width: 100,
      renderCell: (prms: GridRenderCellParams) => fmtYYYYMMDDHHmmss(prms.value)
    },
    {
      field: 'welding_condition_number',
      headerName: '溶接条件番号',
      description: 'welding_condition_number',
      width: 100
    },
    {field: 'rise_process', headerName: '立ち上がり処理', description: 'rise_process', width: 100},
    {
      field: 'wire_post_process',
      headerName: 'ワイヤ後処理',
      description: 'wire_post_process',
      width: 100
    },
    {
      field: 'weld_release_number',
      headerName: '溶着解除番号',
      description: 'weld_release_process',
      width: 100
    },
    {field: 'weld_release', headerName: '溶着解除', description: 'weld_release', width: 100},
    {
      field: 'welding_condition_slope_specification',
      headerName: '溶接条件傾斜指定',
      description: 'welding_condition_slope_specification',
      width: 100
    },
    {
      field: 'gas_purge_time',
      headerName: 'ガスパージ時間',
      description: 'gas_purge_time',
      width: 100
    },
    {
      field: 'gas_pre_flow_time',
      headerName: 'ガスプリフロー時間',
      description: 'gas_pre_flow_time',
      width: 100
    },
    {
      field: 'gas_post_flow_time',
      headerName: 'ガスポストフロー時間',
      description: 'gas_post_flow_time',
      width: 100
    },
    {
      field: 'as_head_execution_time',
      headerName: 'AS先実行時間',
      description: 'as_head_execution_time',
      width: 100
    },
    {
      field: 'running_crater_processing_time',
      headerName: 'ランニングクレータ処理時間',
      description: 'running_crater_processing_time',
      width: 100
    }
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  let COLUMN_ORDER = [{name: 'id', order: 0}];

  const ExcelJS = require('exceljs');

  const onDownloadCSV = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {}
    })
      .then(response => {
        let bom = new Uint8Array([0xef, 0xbb, 0xbf]);
        const blob = new Blob([bom, response.data], {type: 'text/plain'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}.csv`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const onDownloadExcel = () => {
    setStartProcess(true);
    document.body.style.cursor = 'wait';

    adminAPIAccess({
      apipath: 'GetInspectionResult',
      parameters: {}
    })
      .then(async response => {
        const data: string[][] = response.data.split('\r\n').map((row: any) => row.split(','));
        const workbook = new ExcelJS.Workbook();
        workbook.addWorksheet('export_data');
        const worksheet = workbook.getWorksheet('export_data');
        data.forEach((row, indexRow) => {
          if (indexRow === 0) {
            const header_data: {header: string; key: string}[] = [];
            row.forEach((cell, indexCol) => {
              header_data.push({header: cell.slice(1).slice(0, -1), key: `c${indexCol}`});
            });
            worksheet.columns = header_data;
          } else {
            let row_data = {};
            row.forEach((cell, indexCol) => {
              if (cell.length > 0) {
                row_data = {...row_data, [`c${indexCol}`]: cell.slice(1).slice(0, -1)};
              }
            });
            worksheet.addRow(row_data);
          }
        });
        const uint8Array = await workbook.xlsx.writeBuffer();
        const blob = new Blob([uint8Array], {type: 'application/octet-binary'});
        const url = window.URL.createObjectURL(blob);

        setStartProcess(false);
        document.body.style.cursor = 'auto';

        const a = document.createElement('a');
        a.href = url;
        a.download = `${t('シリアルデータ')}_.xlsx`;
        a.click();
        a.remove();
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
      sx={{maxHeight: '80vh', marginTop: '20vh'}}
    >
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <Grid container spacing={1}>
          <Grid item xs={12} textAlign="right">
            <Tooltip title={t('CSV出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<DownloadIcon />}
                onClick={onDownloadCSV}
              >
                {t('数値データ')}(CSV)
              </Button>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title={t('Excel出力')} placement="bottom" arrow={true}>
              <Button
                variant="contained"
                style={{background: green[300]}}
                startIcon={<DownloadIcon />}
                onClick={onDownloadExcel}
              >
                {t('数値データ')}(Excel)
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={''}>
              <BarComponent
                low={data.low}
                main={data.main}
                high={data.high}
                path_split_a={data.pathsplita}
                path_split_b={data.pathsplitb}
              />
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={`${t('溶接データ')}`}>
              <LFCDataGridSimple
                columns={weldingColumns}
                rows={datas}
                rowHeight={40}
                height="25vh"
                columnOrder={COLUMN_ORDER}
                onCellClick={() => {}}
              />
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={`${t('溶接データ詳細')}`}>
              <LFCDataGridSimple
                columns={weldingDetailColumns}
                rows={weldingDetailRows.map((row: any, index: number) => {
                  return {
                    id: index,
                    welding_condition_name: row.welding_condition_name,
                    welding_condition_number: row.welding_condition_number,
                    voltage: row.voltage,
                    current: row.current,
                    welding_speed: row.welding_speed,
                    processing_time: row.processing_time
                  };
                })}
                rowHeight={44}
                height="20vh"
                columnOrder={COLUMN_ORDER}
                onCellClick={() => {}}
              />
            </LFCTitledCard>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TraceAbilitySearchDetailDig2;
