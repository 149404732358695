import {Box, Grid, Typography} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import LFCSelectFormDeviceId from '_components-with-data/inputs/LFCSelectFormDeviceId';
import LFCSelectFormWork from '_components-with-data/inputs/LFCSelectFormWork';
import LFCNumberField from '_components/inputs/LFCNumberField';
import LFCTimePicker from '_components/inputs/LFCTimePicker';
import {ALL} from '_logics/LFCConst';
import {handleChangeMultipleUtil, handleInputChange} from '_logics/LFCUtil';
import LFCSavedCondition, {
  ConditionParamer
} from '../../../_components/search-conditions/LFCSavedCondition';
import LFCDatePicker from '../../../_components/inputs/LFCDatePicker';
import {useTranslation} from 'react-i18next';

interface Cols {
  select_time: string;
  select_date: string;
  work: string[];
  deviceid: string[];
  auto_play_interval: string;
}

interface Props {
  values: Cols;
  onChange: (vals: Cols) => void;
  pageId: number;
  locationId: number;
}

/**
 * 抽出条件　進捗モニタ用
 * @param props
 * @returns
 */
const ProgressMonitorDemo = (props: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [searchValue, setSearchValue] = useState<Cols>(props.values);

  useEffect(() => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    props.onChange(searchValue);
  }, [searchValue]);

  // 保存した検索条件画面の表示状態
  const [openSavedCondition, setOpenSavedCondition] = React.useState(false);
  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  // 保存した検索条件画面を開く
  const onLoadSavedCondition = () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setCondition([
      {
        name: 'select_time',
        value: searchValue.select_time,
        valueLabel: searchValue.select_time,
        colName: t('稼働開始時刻'),
        colWidth: 100
      },
      {
        name: 'select_date',
        value: searchValue.select_date,
        valueLabel: String(searchValue.select_date),
        colName: t('日付'),
        colWidth: 120
      },
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work[0] === t('すべて') ? ALL : searchValue.work.join(','),
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'deviceid',
        value: searchValue.deviceid,
        valueLabel: searchValue.deviceid[0] === t('すべて') ? ALL : searchValue.deviceid.join(','),
        colName: t('検査装置'),
        colWidth: 300
      },
      {
        name: 'auto_play_interval',
        value: searchValue.auto_play_interval,
        valueLabel: searchValue.auto_play_interval,
        colName: '画面遷移時間',
        colWidth: 300
      }
    ]);

    setOpenSavedCondition(true);
  };
  // 保存した検索条件画面で検索条件が選択された
  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <Box m={2} sx={{width: 240}}>
      <form ref={formRef}>
        <Grid container spacing={1}>
          <Grid item xs={12} mb={1}>
            <Typography color="textSecondary" variant="subtitle1">
              {t('抽出条件')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LFCTimePicker
              name={'select_time'}
              label={t('稼働開始時刻')}
              value={searchValue.select_time}
              onChange={event => handleInputChange(event, searchValue, setSearchValue)}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCDatePicker
              name={'select_date'}
              label={t('日付')}
              value={searchValue.select_date}
              onChange={event => handleInputChange(event, searchValue, setSearchValue)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectFormWork
              name={t('work')}
              value={searchValue.work}
              onChange={event => handleChangeMultipleUtil(setSearchValue, searchValue, event, t)}
              multiple={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSelectFormDeviceId
              name={'deviceid'}
              value={searchValue.deviceid}
              onChange={event => handleChangeMultipleUtil(setSearchValue, searchValue, event, t)}
              multiple={true}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <LFCNumberField
              name={'auto_play_interval'}
              value={searchValue.auto_play_interval}
              onChange={event => {
                handleInputChange(event, searchValue, setSearchValue);
              }}
              min={10}
              max={600}
              fullWidth
              label={'画面遷移時間(秒)'}
            />
          </Grid>
          <Grid item xs={12}>
            <LFCSavedCondition
              open={openSavedCondition}
              pageId={props.pageId}
              locationNo={props.locationId}
              onLoad={onLoadSavedCondition}
              onSelect={onSelectSavedCondition}
              onClose={() => setOpenSavedCondition(false)}
              conditions={condition}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
export default ProgressMonitorDemo;
