import dayjs from 'dayjs';
import {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  height?: string;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBar = (props: Props) => {
  const renderFlgRef = useRef(false);

  type DsRow = {source?: []; transform?: any};
  const [dataset, setDataset] = useState<DsRow[]>([]);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // データソースクリア
    if (props.source === undefined) {
      setSeries([]);
      setDataset([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: any = [];
    l_ds.push({source: props.source});

    if (props.series) {
      // シリーズ指定あり
      let strSeries: string = props.series;
      const grpNames = Array.from(new Set(props.source.map((obj: any) => obj[strSeries])));

      // シリーズ設定
      grpNames.forEach((grp, i) => {
        l_ds.push({
          transform: {
            type: 'filter',
            config: {dimension: props.series, value: grp}
          }
        });
        l_series.push({
          type: 'bar',
          name: grp,
          stack: props.stacked ? 'total' : null,
          encode: {x: props.x.dsColumn, y: props.y.dsColumn},
          datasetIndex: i + 1
        });
      });
    } else {
      // シリーズ指定なし
      l_series.push({
        type: 'bar',
        encode: {x: props.x.dsColumn, y: props.y.dsColumn}
      });
    }
    setSeries(l_series);
    setDataset(l_ds);
  }, [props.source]);

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '40',
              left: '60',
              right: '10',
              bottom: '100'
            },
        ...(props.series
          ? {
              legend: {type: 'scroll', bottom: 0, itemWidth: 10}
            }
          : {
              legend: {
                show: false
              }
            }),
        tooltip: {
          trigger: 'item',
          axisPointer: {
            ...(props.x.type === 'time'
              ? {
                  label: {
                    formatter: (prm: any) => {
                      return dayjs(prm.value).format('YYYY/MM/DD');
                    }
                  }
                }
              : null)
          }
        },
        dataZoom: [
          {
            type: 'inside',
            ...(props.x.type === 'time'
              ? {
                  minValueSpan: 3600 * 24 * 1000 * 7 // 最小ZOOM 7日分
                }
              : null)
          }
          // {
          //   type: "slider",
          //   top: "30",
          //   borderColor: "transparent",
          // },
        ],
        color: props.color ? props.color : null,
        dataset: dataset,
        xAxis: [
          {
            type: props.x.type,
            axisLabel: {
              rotate: '45',
              fontSize: 11,
              width: 100,
              overflow: 'truncate',
              // rich: {
              //   boldStyle: {
              //     fontWeight: "bold",
              //   },
              // },
              ...(props.x.type === 'time'
                ? {
                    formatter: {
                      year: '{boldStyle|{yyyy}}',
                      month: '{boldStyle|{MMM}}',
                      day: '{d}',
                      hour: '{d}',
                      none: '{d}'
                    },
                    minInterval: 3600 * 24 * 1000 * 1
                  }
                : null)
            }
          }
        ],
        yAxis: [
          {
            type: props.y.type,
            nameTextStyle: {
              fontSize: 11
            },
            axisLabel: {
              fontSize: 11
            }
          }
        ],
        series: series
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={props.height}
    />
  );
};
export default LFCChartsBar;
