import {useSnackbar} from 'notistack';
import {useCallback, useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import axios from 'axios';
import {getLFCData} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import ProgressBar from '_components/ProgressBar';
import LFCChartsBarAxisGap from '_components/charts/LFCChartsBarAxisGap';
import LFCChartsLineMultiAxis from '_components/charts/LFCChartsLineMultiAxis';
import LFCChartsScatter2 from '_components/charts/LFCChartsScatter2';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  open: boolean;
  searchValue: any;
  typeKey: any;
  graphData1: any;
}

const PartsAxisComponent = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const export_axisGraph1Data_show_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    serial: t('シリアルナンバー')
  };

  const dataAxis_SP_column: any = {
    type: t('軸名'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    カウント数: t('カウント数')
  };

  //日付リストの生成
  const date_calc: any = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(props.searchValue.select_date_from);
    let to_day = new Date(props.searchValue.select_date_to);

    switch (props.searchValue.searchType) {
      case 0:
        return return_type0_byid();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  }, [startProcess]);

  const axis_xyz_columns: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    serial: t('シリアルナンバー')
  };

  const export_axisGraph1Data_show: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    x_gap: t('X座標誤差　平均'),
    y_gap: t('Y座標誤差　平均'),
    z_gap: t('Z座標誤差　平均')
  };

  const return_type0_byid = () => {
    if (props.typeKey[0] === undefined) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 28100,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge
        },
        name: 'id-28100',
        cancelToken: source.token,
        t
      }).then((data: any) => {
        switch (data[0]['tolerance_type']) {
          case 0:
          case null:
            return Array.from(new Set(props.graphData1.map((a: any) => a.lq_time))).sort();
          case 1:
        }
        return [];
      });
    } else {
      switch (props.typeKey[0]['tolerance_type']) {
        case 0:
        case null:
          return Array.from(new Set(props.graphData1.map((a: any) => a.lq_time))).sort();
      }
      return [];
    }
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');
  const zoomChartOpenUpdate = (idString: string) => {
    if (zoomChartOpen !== idString) {
      setZoomChartOpen(idString);
    } else {
      setZoomChartOpen('');
    }
  };
  const zoomCharts = (idString: string) => {
    zoomChartOpenUpdate(idString);
  };

  const zoomChartsClose = () => {
    setZoomChartOpen('');
  };

  const zoomChartsCheck = (idString: string) => {
    return zoomChartOpen === idString;
  };

  const [axisGraph1Data_show, setAxisGraph1Data_show] = useState([]);
  const [dataSPMax, setDataSPMax] = useState(0);
  const [dataSPMin, setDataSPMin] = useState(0);
  const [dataSPRenge, setDataSPRenge] = useState(0);
  const [dataSPX, setDataSPX] = useState([]);
  const [dataSPY, setDataSPY] = useState([]);
  const [dataSPZ, setDataSPZ] = useState([]);
  const [data2DXY, setData2DXY] = useState([]);
  const [data2DXZ, setData2DXZ] = useState([]);
  const [data2DYZ, setData2DYZ] = useState([]);

  const [data2DExport, setData2DExport] = useState([]);

  const [data2DXYShow, setData2DXYShow] = useState([]);
  const [data2DXZShow, setData2DXZShow] = useState([]);
  const [data2DYZShow, setData2DYZShow] = useState([]);

  useEffect(() => {
    let tmp_maps: any = [];
    props.typeKey.forEach((a: any) => {
      tmp_maps.push(a.map_array);
    });

    if (props.open) {
      setStartProcess(true);
      let tmp_list: any = [];
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          // 281032,Axis：座標誤差　推移：検査ごと
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281032,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
            },
            name: 'id-281030',
            cancelToken: source.token,
            t
          }).then((data: any) => {
            setAxisGraph1Data_show(data);
          });
          break;
        case 1: // 時間ごと
          // 281031,Axis：座標誤差　推移：時間単位
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281031,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
            },
            name: 'id-281030',
            cancelToken: source.token,
            t
          }).then((data: any) => {
            if (data.length > 0) {
              date_calc().forEach((a: string) => {
                if (data.find((b: any) => b.year_month_day_hour === a)) {
                  tmp_list.push(
                    // data.filter((b: any) => b.year_month_day_hour === a)[0]
                    {
                      work: String(data.filter((b: any) => b.year_month_day_hour === a)[0].work),
                      deviceid: String(
                        data.filter((b: any) => b.year_month_day_hour === a)[0].deviceid
                      ),
                      map: String(data.filter((b: any) => b.year_month_day_hour === a)[0].map),
                      for_export: data.filter((b: any) => b.year_month_day_hour === a)[0]
                        .year_month_day_hour,
                      x_gap: data.filter((b: any) => b.year_month_day_hour === a)[0].x_gap,
                      y_gap: data.filter((b: any) => b.year_month_day_hour === a)[0].y_gap,
                      z_gap: data.filter((b: any) => b.year_month_day_hour === a)[0].z_gap,
                      件数: data.filter((b: any) => b.year_month_day_hour === a)[0].件数,
                      year_month: a
                    }
                  );
                } else {
                  tmp_list.push({
                    map: data[0]['map'],
                    year_month_day_hour: a
                  });
                }
              });
              setAxisGraph1Data_show(tmp_list);
            }
          });
          break;
        case 2: // 日ごと
          // 281030,Axis：座標誤差　推移：日単位
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281030,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
            },
            name: 'id-281030',
            cancelToken: source.token,
            t
          }).then((data: any) => {
            if (data.length > 0) {
              date_calc().forEach((a: string) => {
                if (data.find((b: any) => b.year_month_day === a)) {
                  tmp_list.push({
                    work: String(data.filter((b: any) => b.year_month_day === a)[0].work),
                    deviceid: String(data.filter((b: any) => b.year_month_day === a)[0].deviceid),
                    map: String(data.filter((b: any) => b.year_month_day === a)[0].map),
                    for_export: data.filter((b: any) => b.year_month_day === a)[0].year_month_day,
                    x_gap: data.filter((b: any) => b.year_month_day === a)[0].x_gap,
                    y_gap: data.filter((b: any) => b.year_month_day === a)[0].y_gap,
                    z_gap: data.filter((b: any) => b.year_month_day === a)[0].z_gap,
                    件数: data.filter((b: any) => b.year_month_day === a)[0].件数,
                    year_month_day: a
                  });
                } else {
                  tmp_list.push({
                    map: data[0]['map'],
                    year_month_day: a
                  });
                }
              });
              setAxisGraph1Data_show(tmp_list);
            }
          });
          break;
        case 3: // 月ごと
          // 281033,Axis：座標誤差　推移：月単位
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 281033,
            parameters: {
              select_month: '{' + date_calc().join(',') + '}',
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
            },
            name: 'id-281030',
            cancelToken: source.token,
            t
          }).then((data: any) => {
            if (data.length > 0) {
              date_calc().forEach((a: string) => {
                if (data.find((b: any) => b.year_month === a)) {
                  tmp_list.push(
                    // data.filter((b: any) => b.year_month === a)[0],
                    {
                      work: String(data.filter((b: any) => b.year_month === a)[0].work),
                      deviceid: String(data.filter((b: any) => b.year_month === a)[0].deviceid),
                      map: String(data.filter((b: any) => b.year_month === a)[0].map),
                      for_export: data.filter((b: any) => b.year_month === a)[0].year_month,
                      x_gap: data.filter((b: any) => b.year_month === a)[0].x_gap,
                      y_gap: data.filter((b: any) => b.year_month === a)[0].y_gap,
                      z_gap: data.filter((b: any) => b.year_month === a)[0].z_gap,
                      件数: data.filter((b: any) => b.year_month === a)[0].件数,
                      year_month: a
                    }
                  );
                } else {
                  tmp_list.push({
                    map: data[0]['map'],
                    year_month: a
                  });
                }
              });
              setAxisGraph1Data_show(tmp_list);
            }
          });
          break;
      }

      // 281034,Axis：座標誤差　分布：xyz：max_min
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281034,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
        },
        name: 'id-281034',
        cancelToken: source.token,
        t
      }).then((data: any) => {
        if (data.length > 0) {
          let gap_max: number = Math.max(
            ...[data[0].x_gap_max, data[0].y_gap_max, data[0].z_gap_max]
          );
          let gap_min: number = Math.min(
            ...[data[0].x_gap_min, data[0].y_gap_min, data[0].z_gap_min]
          );

          let tmp_n: number = props.graphData1.length;
          let tmp_k: number;
          if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
            tmp_k = 7;
          } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
            tmp_k = 27;
          } else {
            tmp_k = Math.floor(Math.log2(tmp_n) + 1);
          }
          setDataSPMax(gap_max);
          setDataSPMin(gap_min);

          let x_tmp_h: number = (gap_max - gap_min) / tmp_k;

          let under_zero: number = Math.floor(1 / x_tmp_h);
          let zero: string = '1';
          for (let i = 0; i < String(under_zero).length; i++) {
            zero = zero + '0';
          }

          let x_tmp_dot: number = String(x_tmp_h).indexOf('.');
          let renge: number;
          if (x_tmp_dot > 0) {
            renge = Math.floor(x_tmp_h * (x_tmp_dot * Number(zero))) / (x_tmp_dot * Number(zero));
          } else {
            renge = Math.floor(x_tmp_h);
          }
          setDataSPRenge(renge);
        }
      });

      // 281038,Axis：座標誤差 平面分布：X-Y
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281038,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
        },
        ds_state: setData2DXY,
        name: 'id-281038',
        cancelToken: source.token,
        t
      });
      // 281039,Axis：座標誤差 平面分布：X-Z
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281039,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
        },
        ds_state: setData2DXZ,
        name: 'id-281039',
        cancelToken: source.token,
        t
      });
      // 281040,Axis：座標誤差 平面分布：Y-Z
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281040,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}'
        },
        ds_state: setData2DYZ,
        name: 'id-281040',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  }, [props.open]);

  useEffect(() => {
    let tmp_maps: any = [];
    props.typeKey.forEach((a: any) => {
      tmp_maps.push(a.map_array);
    });
    if (dataSPRenge !== 0) {
      // 281035,Axis：座標誤差 分布:x_gap:グラフデータ
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281035,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}',
          renge: dataSPRenge
        },
        ds_state: setDataSPX,
        name: 'id-281035',
        cancelToken: source.token,
        t
      });
      // 281036,Axis：座標誤差 分布:y_gap:グラフデータ
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281036,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}',
          renge: dataSPRenge
        },
        ds_state: setDataSPY,
        name: 'id-281036',
        cancelToken: source.token,
        t
      });
      // 281037,Axis：座標誤差 分布:z_gap:グラフデータ
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 281037,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: '{' + Array.from(new Set(tmp_maps.flat())).join(',') + '}',
          renge: dataSPRenge
        },
        ds_state: setDataSPZ,
        name: 'id-281037',
        cancelToken: source.token,
        t
      });
    }
  }, [dataSPRenge]);

  useEffect(() => {
    let tmp_data2DXY: any = [];
    if (data2DXY.length > 0) {
      tmp_data2DXY = data2DXY.map((a: any) => [a.x_gap, a.y_gap, a.lq_time]);
    }
    setData2DXYShow(tmp_data2DXY);
    let tmp_data2DXZ: any = [];
    if (data2DXZ.length > 0) {
      tmp_data2DXZ = data2DXZ.map((a: any) => [a.x_gap, a.z_gap, a.lq_time]);
    }
    setData2DXZShow(tmp_data2DXZ);
    let tmp_data2DYZ: any = [];
    if (data2DYZ.length > 0) {
      tmp_data2DYZ = data2DYZ.map((a: any) => [a.y_gap, a.z_gap, a.lq_time]);
    }
    setData2DYZShow(tmp_data2DYZ);
  }, [data2DXY, data2DXZ, data2DYZ]);

  useEffect(() => {
    let tmp_x: any = dataSPX.map((a: any) => Object.assign(a, {type: 'X_GAP'}));
    let tmp_y: any = dataSPY.map((a: any) => Object.assign(a, {type: 'Y_GAP'}));
    let tmp_z: any = dataSPZ.map((a: any) => Object.assign(a, {type: 'Z_GAP'}));
    let tmp_all: any;
    tmp_all = tmp_x.concat(tmp_y);
    tmp_all = tmp_all.concat(tmp_z);
    setData2DExport(tmp_all);
  }, [dataSPX, dataSPY, dataSPZ]);

  //　Axis画面
  return (
    <>
      <Grid container spacing={2} key={'graphSetType1x'}>
        <ProgressBar startProcess={startProcess} />
        <Grid item xs={12} md={7}>
          <LFCChartsLineMultiAxis
            title={`${t('座標誤差')} ${t('推移')}`}
            source={axisGraph1Data_show}
            type={props.searchValue.searchType}
            xData={
              props.searchValue.searchType !== 0
                ? date_calc()
                : axisGraph1Data_show.map((a: any, index: number) => index + 1)
            }
            color={['rgb(106,113,255)']}
            exportData={axisGraph1Data_show}
            exportFields={
              props.searchValue.searchType !== 0
                ? export_axisGraph1Data_show
                : export_axisGraph1Data_show_type0
            }
            exportFilename={`HoleCompare(${t('穴位置')})_${t('座標誤差推移')}`}
            zoomChartId={'zoom-chart-1'}
            zoomStatus={zoomChartsCheck('zoom-chart-1')}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          {/*{dataSPX.length > 0 && dataSPY.length > 0 && dataSPZ.length > 0 ?*/}
          <LFCChartsBarAxisGap
            title={`${t('座標誤差')}　${t('度数分布')}`}
            source={[dataSPX, dataSPY, dataSPZ]}
            // extraDatas={data1MaxAvgMin.data_x}
            exportData={data2DExport}
            exportFields={dataAxis_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('座標誤度数分布')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            zoomChartId={'zoom-chart-5'}
            zoomStatus={zoomChartsCheck('zoom-chart-5')}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
            xLineMin={dataSPMin}
            xLineMax={dataSPMax}
            xRenge={dataSPRenge}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} key={'graphAxis'}>
        <Grid item xs={12} md={12}>
          {`${t('座標誤差')} ${t('平面分布')}`}
        </Grid>
        <Grid item xs={12} md={4}>
          <LFCChartsScatter2
            title=""
            xaxisName={'X'}
            yaxisName={'Y'}
            datas={data2DXYShow}
            height={'300px'}
            exportData={data2DXY}
            exportFields={axis_xyz_columns}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('座標誤XY平面分布')}`}
            left={'95%'}
            zoomChartId={'zoom-chart-6'}
            zoomStatus={zoomChartsCheck('zoom-chart-6')}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LFCChartsScatter2
            title=""
            xaxisName={'X'}
            yaxisName={'Z'}
            datas={data2DXZShow}
            height={'300px'}
            exportData={data2DXZ}
            exportFields={axis_xyz_columns}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('座標誤XZ平面分布')}`}
            left={'95%'}
            zoomChartId={'zoom-chart-7'}
            zoomStatus={zoomChartsCheck('zoom-chart-7')}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <LFCChartsScatter2
            title=""
            xaxisName={'Y'}
            yaxisName={'Z'}
            datas={data2DYZShow}
            height={'300px'}
            exportData={data2DYZ}
            exportFields={axis_xyz_columns}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('座標誤YZ平面分布')}`}
            left={'95%'}
            zoomChartId={'zoom-chart-8'}
            zoomStatus={zoomChartsCheck('zoom-chart-8')}
            zoomCharts={zoomCharts}
            zoomChartsClose={zoomChartsClose}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PartsAxisComponent;
