import {Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {isEmpty, isNull, round} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCChartsBarLrobot from '_components/charts/LFCChartsBarLrobot';
import LFCChartsHeatMap from '_components/charts/LFCChartsHeatMap';
import ProgressBar from '_components/ProgressBar';
import LFCSideMenu from '_components/search-conditions/LFCSideMenu';
import ProgressMonitor from '_components/search-conditions/ProgressMonitor';
import LFCOkNgPie from '_components/surfaces/LFCOkNgPie';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {ALL, FORMAT_YYYYMMDDHHmmSS} from '_logics/LFCConst';
import {rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {getLFCData} from '_logics/LFCUtil';
import useInterval from '_reacts/useInterval';
import GenericTemplate from '_templates/GenericTemplate';
import DetailDig from '../../l-qualify/inspectionresults/components/DetailDig';

import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {PAGEID_LR_ONSITE_MONITOR, LOCATIONID_LR_ONSITE_MONITOR_MAIN} from '_logics/LFCPageId';
import {useTranslation} from 'react-i18next';

const OnsiteMonitorPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const getDefaultCondition = useGetDefaultCondition();

  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LR_ONSITE_MONITOR, LOCATIONID_LR_ONSITE_MONITOR_MAIN) ?? {
      select_time: '00:00',
      working_time: 24,
      target_number: '1000',
      work: [t('すべて')],
      deviceid: [t('すべて')],
      dongleid: [t('すべて')]
    }
  );

  const [data60351, setData60351] = useState({total_count: '0', ok_count: '0', ng_count: '0'});
  const [data60005, setData60005] = useState({});
  const [data60006, setData60006] = useState({});
  const [data60007, setData60007] = useState<any>({});
  const [data60008, setData60008] = useState({
    deviceid: '',
    dongleid: '',
    lq_time: '',
    serial: '',
    total_judge: 0,
    uuid: '',
    work: ''
  });
  const [data60010, setData60010] = useState([]);
  const [data60019, setData60019] = useState([]);
  const [dispDate, setDispDate] = useState('');
  const delay = 30000;

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    let tmp: string | null = localStorage.getItem('lfc.searchcondition.onsite-monitor');
    let getData: any = !isNull(tmp) ? JSON.parse(tmp) : undefined;
    if (!isEmpty(getData)) {
      setSearchValue({
        ...searchValue,
        select_time: getData.select_time,
        working_time: getData.working_time,
        target_number: getData.target_number
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      'lfc.searchcondition.onsite-monitor',
      JSON.stringify({
        select_time: searchValue.select_time,
        working_time: searchValue.working_time,
        target_number: searchValue.target_number
      })
    );
  }, [searchValue]);

  useEffect(() => {
    setSearchValue({
      ...searchValue,
      work: [t('すべて')],
      deviceid: [t('すべて')],
      dongleid: [t('すべて')]
    });
  }, [t]);

  const doSearch = () => {
    // 対象期間算出
    const now = dayjs();
    let fromDt = dayjs(`${now.format('YYYY-MM-DD')} ${searchValue.select_time}`);

    // 稼働開始時刻より前の場合、前日の同時刻を開始時刻とする。
    if (searchValue.select_time > now.format('HH:mm')) {
      // 稼働開始時刻
      fromDt = fromDt.add(-1, 'd');
    }
    const toDt = fromDt.add(1, 'd');

    setDispDate(fromDt.format(FORMAT_YYYYMMDDHHmmSS));
    const conditionsDate = {
      select_datetime_from: fromDt.format(),
      select_datetime_to: toDt.format()
    };
    const conditionsOther = {
      work: searchValue.work[0] !== t('すべて') ? `{${searchValue.work.join(',')}}` : null,
      deviceid:
        searchValue.deviceid[0] !== t('すべて') ? `{${searchValue.deviceid.join(',')}}` : null,
      dongleid:
        searchValue.dongleid[0] !== t('すべて') ? `{${searchValue.dongleid.join(',')}}` : null
    };

    setStartProcess(true);
    Promise.allSettled([
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60351,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('生産数と不良数')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60351(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60005,
        parameters: {
          working_time: searchValue.working_time,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('ジョブ処理時間')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60005(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60006,
        parameters: {
          working_time: searchValue.working_time,
          target_number: searchValue.target_number,
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('タクトタイム')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60006(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60007,
        parameters: {
          ...conditionsDate,
          ...conditionsOther
        },
        name: `${t('稼働率')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60007(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60008,
        parameters: {
          ...conditionsOther
        },
        name: `${t('最終実行結果')}`,
        cancelToken: source.token,
        t
      }).then(datas => {
        setData60008(datas.length > 0 ? datas[0] : []);
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60019,
        parameters: {
          ...conditionsOther
        },
        ds_state: setData60019,
        name: `${t('最終実行結果のジョブ処理時間')}`,
        cancelToken: source.token,
        t
      }),
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60010,
        parameters: {
          ...conditionsOther
        },
        ds_state: setData60010,
        name: `${t('最終実行結果の部位別結果')}`,
        cancelToken: source.token,
        t
      })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * 自動更新処理
   */
  useInterval(doSearch, delay);

  useEffect(() => {
    // マウント時処理
    doSearch();
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const [open, setOpen] = React.useState(false);
  const [openUuid, setOpenUuid] = React.useState('');
  const [openMap, setOpenMap] = React.useState('');

  /**
   * 明細選択
   * @param params
   * @param event
   * @param details
   */
  const onClickHeat = (params: {x: string; y: string}) => {
    setOpenUuid(data60008.uuid);
    setOpenMap(params.x);
    setOpen(true);
  };

  return (
    <GenericTemplate title={t('menu.page_name.生産進捗モニタ')}>
      <ProgressBar startProcess={startProcess} />
      <LFCSideMenu onClose={() => doSearch()}>
        <ProgressMonitor
          values={searchValue}
          onChange={res => setSearchValue(res)}
          pageId={PAGEID_LR_ONSITE_MONITOR}
          locationId={LOCATIONID_LR_ONSITE_MONITOR_MAIN}
        ></ProgressMonitor>
      </LFCSideMenu>
      <Grid container spacing={1}>
        <Grid item xs={12} md={5} lg={4} xl={3}>
          <LFCTitledCard title={t('本日')} titleSub={`${dispDate} ～ `}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <LFCOkNgPie source={data60351} height="300px" />
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title={`${t('ジョブ処理時間')}(${t('秒')})`} transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'minimum_ct',
                        headerName: `${t('最小')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'average_ct',
                        headerName: `${t('平均')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'maximum_ct',
                        headerName: `${t('最大')}`,
                        formatter: v => round(v, 1),
                        width: '30%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60005}
                  />
                </LFCTitledCard>
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title={`${t('タクトタイム')}(${t('秒')})`} transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'tt_plan',
                        headerName: `${t('計画')}`,
                        formatter: v => round(v, 1),
                        width: '46%',
                        valVariant: 'h6'
                      },
                      {
                        field: 'tt_results',
                        headerName: `${t('実績')}`,
                        formatter: v => round(v, 1),
                        width: '46%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60006}
                  />
                </LFCTitledCard>
              </Grid>
              <Grid item xs={12}>
                <LFCTitledCard title={`${t('稼働率')}(%)`} transparent>
                  <LFCSingleValues
                    columns={[
                      {
                        field: 'kado_per',
                        headerName: 'L-ROBOT',
                        formatter: v => round(v, 1),
                        width: '46%',
                        valVariant: 'h6'
                      }
                    ]}
                    source={data60007}
                  />
                </LFCTitledCard>
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>
        <Grid item xs={12} md={7} lg={8} xl={9}>
          <LFCTitledCard title={t('最終実行結果')} transparent>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <LFCSingleValues
                  columns={[
                    {
                      field: 'total_judge',
                      headerName: `${t('総合判定')}`,
                      formatter: rendTotalJudgeDisp
                    },
                    {field: 'lq_time', headerName: `${t('日時')}`},
                    {field: 'serial', headerName: `${t('シリアルNo')}`},
                    {field: 'work', headerName: `${t('機種')}`},
                    {field: 'deviceid', headerName: `${t('マシン')}`},
                    {field: 'dongleid', headerName: `${t('オペレータ')}`}
                    // { field: "uuid", headerName: "UUID" },
                  ]}
                  source={data60008}
                />
              </Grid>
              <Grid item xs={12}>
                <LFCChartsBarLrobot
                  title={`${t('ジョブ処理時間')}(${t('秒')})`}
                  source={data60019}
                  stacked={true}
                  x={{dsColumn: 'diff', type: 'value'}}
                  y={{dsColumn: 'series_name', type: 'category'}}
                  series={'job_type_name'}
                  color={['#e96763', '#f5cc64', '#f5f50f', '#27a375', '#15529f']}
                  exportData={data60019}
                  exportFields={{
                    job_type_name: t('ジョブ'),
                    diff: t('処理時間')
                  }}
                  exportFilename={`${t('ジョブ処理時間')}(${t('秒')})`}
                  grid={{left: 10, right: 10}}
                />
              </Grid>
              <Grid item xs={12}>
                <LFCChartsHeatMap
                  title={`${t('判定結果')}(${t('ジョブ')}×${t('部位')})`}
                  source={data60010}
                  x={{dsColumn: 'map'}}
                  y={{dsColumn: 'job_type_name'}}
                  series="judge"
                  visual_map={{
                    min: 0,
                    max: 1,
                    show: false
                  }}
                  exportData={data60010}
                  exportFields={{
                    jobname: t('ジョブ'),
                    map: t('部位'),
                    judge: t('判定')
                  }}
                  exportFilename={`${t('部位別結果')}`}
                  onClick={onClickHeat}
                />
              </Grid>
            </Grid>
          </LFCTitledCard>
        </Grid>
      </Grid>
      <DetailDig open={open} onClose={() => setOpen(false)} uuid={openUuid} map={openMap} />
    </GenericTemplate>
  );
};

export default OnsiteMonitorPage;
