import {Grid, Typography} from '@mui/material';
import {GridColumns, useGridApiRef} from '@mui/x-data-grid-pro';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import LFCDataGrid from '_components/datagrid/LFCDataGrid';
import LFCButton from '_components/inputs/LFCButton';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {renderCellExpand} from '_logics/LFCRenderUtil';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import type {rowData} from './components/CompanyMasterDetailDlg';
import CompanyMasterDetailDlg, {emptyRowData} from './components/CompanyMasterDetailDlg';
import {useTranslation} from 'react-i18next';

const CompanyMaster = () => {
  const {t} = useTranslation();
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const apiRef = useGridApiRef();
  const [values, setValues] = useState<rowData>(emptyRowData);
  const [isDlgOpen, setIsDlgOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const [source] = useState(axios.CancelToken.source());

  // 初期検索、最新の情報に更新ボタン押下時
  const onSearchClick = () => {
    // 会社マスタ一覧取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 90,
      parameters: {},
      cancelToken: source.token,
      t
    }).then(data => {
      const companyData = data.map((item: any, idx: number) => {
        return {
          id: String(idx),
          company_id: String(item['id']),
          company_name: String(item['name']),
          region: String(item['region'])
        };
      });
      setDgRows(companyData);
    });
    resetRowSelection();
    setValues(emptyRowData);
  };

  // 新規作成ボタン押下時
  const onAddNewClick = () => {
    setValues({
      mode: 'AddNew',
      id: '',
      company_id: '',
      company_name: '',
      region: ''
    });
    setIsDlgOpen(true);
  };

  // 行選択時
  const onRowClick = (selectedData: any) => {
    setValues({
      mode: 'Modify',
      id: selectedData.row.id,
      company_id: selectedData.row.company_id,
      company_name: selectedData.row.company_name,
      region: selectedData.row.region
    });
    setIsDlgOpen(true);
  };

  // 入力ダイアログクローズ時
  const onDlgClose = () => {
    setIsDlgOpen(false);
    onSearchClick();
  };

  // 行選択解除処理
  const resetRowSelection = () =>
    (values.id ?? '') !== '' && apiRef.current.selectRow(values.id, false);

  useEffect(() => {
    // マウント時処理
    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {
        field: 'company_id',
        headerName: `${t('会社ID')}`,
        description: `${t('会社ID')}`,
        width: 100,
        renderCell: renderCellExpand
      },
      {
        field: 'company_name',
        headerName: `${t('会社名')}`,
        description: `${t('会社名')}`,
        flex: 1,
        renderCell: renderCellExpand
      },
      {
        field: 'region',
        headerName: `Region`,
        description: `Region`,
        flex: 1,
        renderCell: renderCellExpand
      }
    ]);
    onSearchClick();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <GenericTemplate title={t('会社マスタ')}>
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <Grid item xs={6}>
          <Typography variant="subtitle1">
            {t('message.会社マスタの新規/登録/変更を「全DB環境」に対して行います')}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <LFCButton color="primary" onClick={onSearchClick}>
            {t('message.最新の情報に更新')}
          </LFCButton>
        </Grid>
        <Grid item xs={12}>
          <LFCButton color="primary" onClick={onAddNewClick}>
            {t('新規')}
          </LFCButton>
        </Grid>
        <Grid item xs={12}>
          <LFCTitledCard title="m_company" transparent>
            <LFCDataGrid
              height="70vh"
              apiRef={apiRef}
              columns={dgColumns}
              rows={dgRows}
              onCellClick={onRowClick}
              columnOrder={[{name: 'id', order: 0}]}
              pagination={false}
            />
          </LFCTitledCard>
        </Grid>
      </Grid>
      <CompanyMasterDetailDlg open={isDlgOpen} onClose={onDlgClose} row={values} />
    </GenericTemplate>
  );
};

export default CompanyMaster;
