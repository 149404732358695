import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography
} from '@mui/material';
import ProgressBar from '_components/ProgressBar';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {getLFCData, handleInputChange2} from '_logics/LFCUtil';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import PartsSphereComponent from './PartsSphereComponent';
import PartsCubeComponent from './PartsCubeComponent';
import PartsCylinderComponent from './PartsCylinderComponent';
import PartsAxisComponent from './PartsAxisComponent';
import {isEmpty} from 'lodash';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    typeTitle: {
      marginLeft: '-16px',
      fontWeight: 'bold',
      fontSize: '16px'
    },
    mrgLeft: {
      marginLeft: '8px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: any;
  searchValue: any;
}

const HoleComparePositionDig = (props: Props) => {
  const {t} = useTranslation();
  const export_detail_all_list: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100,
      hide: false
    },
    {
      order: 1,
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: true,
      width: 140
    },
    {
      order: 2,
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: true
    },
    {
      order: 3,
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 180,
      sortable: true
    },
    {
      order: 10,
      field: 'master_x',
      headerName: t('マスタX座標'),
      description: t('マスタX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 11,
      field: 'master_y',
      headerName: t('マスタY座標'),
      description: t('マスタY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 12,
      field: 'master_z',
      headerName: t('マスタZ座標'),
      description: t('マスタZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 13,
      field: 'work_x',
      headerName: t('ワークX座標'),
      description: t('ワークX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 14,
      field: 'work_y',
      headerName: t('ワークY座標'),
      description: t('ワークY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 15,
      field: 'work_z',
      headerName: t('ワークZ座標'),
      description: t('ワークZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 16,
      field: 'x_gap',
      headerName: t('X座標誤差'),
      description: t('X座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 17,
      field: 'y_gap',
      headerName: t('Y座標誤差'),
      description: t('Y座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 18,
      field: 'z_gap',
      headerName: t('Z座標誤差'),
      description: t('Z座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 30,
      field: 'center_range_limit',
      headerName: t('球体半径誤差閾値'),
      description: t('球体半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 31,
      field: 'center_range_gap',
      headerName: t('球体半径誤差'),
      description: t('球体半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 50,
      field: 'x_lower_limit',
      headerName: t('X座標誤差下限閾値'),
      description: t('X座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 51,
      field: 'x_upper_limit',
      headerName: t('X座標誤差上限閾値'),
      description: t('X座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 52,
      field: 'y_lower_limit',
      headerName: t('Y座標誤差下限閾値'),
      description: t('Y座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 53,
      field: 'y_upper_limit',
      headerName: t('Y座標誤差上限閾値'),
      description: t('Y座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 54,
      field: 'z_lower_limit',
      headerName: t('Z座標誤差下限閾値'),
      description: t('Z座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 55,
      field: 'z_upper_limit',
      headerName: t('Z座標誤差上限閾値'),
      description: t('Z座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 60,
      field: 'cylinder_radius_gap',
      headerName: t('円筒半径誤差'),
      description: t('円筒半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 61,
      field: 'cylinder_radius_limit',
      headerName: t('円筒半径誤差閾値'),
      description: t('円筒半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 62,
      field: 'height_gap',
      headerName: t('円筒高さ誤差'),
      description: t('円筒高さ誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 63,
      field: 'height_upper_limit',
      headerName: t('円筒高さ誤差上限閾値'),
      description: t('円筒高さ誤差上限閾値'),
      width: 180,
      sortable: true
    },
    {
      order: 64,
      field: 'height_lower_limit',
      headerName: t('円筒高さ誤差下限閾値'),
      description: t('円筒高さ誤差下限閾値'),
      width: 180,
      sortable: true
    }
  ];
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [typeKey, setTypeKey] = useState<any>([]);

  const [graphData0, setGraphData0] = useState([]);
  const [graphData1, setGraphData1] = useState([]);
  const [graphData2, setGraphData2] = useState([]);

  const [allData, setAllData] = useState([]);

  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: [],
    searchType: 2
  });
  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
  }, [props]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setTypeKey([]);
    setGraphData0([]);
    setGraphData1([]);
    setGraphData2([]);
  };

  const [searchPrams, setSearchPrams] = useState({});
  useEffect(() => {
    setSearchPrams({
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      map: !isEmpty(searchValue.map) ? '{' + searchValue.map.join(',') + '}' : null,
      judge: searchValue.judge === 9 ? null : searchValue.judge
    });
  }, [searchValue]);

  const doSearch = async () => {
    setTypeKey([]);
    setGraphData0([]);
    setGraphData1([]);
    setGraphData2([]);
    setStartProcess(true);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 28100,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        ...searchPrams
      },
      name: 'id-28100',
      cancelToken: source.token,
      t
    }).then(r => {
      setTypeKey(r);
      r.forEach((data: any) => {
        if (data.tolerance_type === 0 || data.tolerance_type === null) {
          switch (searchValue.searchType) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28107,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28107',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData0(data);
                }
              });
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28105,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28105',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData0(data);
                }
              });
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28102,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28102',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData0(data);
                }
              });
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28106,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28106',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData0(data);
                }
              });
              break;
          }
        }

        if (data.tolerance_type === 1) {
          switch (searchValue.searchType) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28110,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28110',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData1(data);
                }
              });
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28108,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28108',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData1(data);
                }
              });
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28103,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28103',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData1(data);
                }
              });
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28109,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28109',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData1(data);
                }
              });
              break;
          }
        }

        if (data.tolerance_type === 2) {
          switch (searchValue.searchType) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28113,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28113',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData2(data);
                }
              });
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28111,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28111',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData2(data);
                }
              });
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28104,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28104',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData2(data);
                }
              });
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 28112,
                parameters: {
                  select_date_from: searchValue.select_date_from,
                  select_date_to: searchValue.select_date_to,
                  ...searchPrams
                },
                name: 'id-28112',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                if (data.length === 0) {
                  return;
                } else {
                  setGraphData2(data);
                }
              });
              break;
          }
        }
      });
    });
    setStartProcess(false);
  };

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
    setTypeKey([]);
    setGraphData0([]);
    setGraphData1([]);
    setGraphData2([]);
    setAutoCompleteReset(true);
  };

  const getAllList = () => {
    setStartProcess(true);
    let tmp_maps: any = [];
    typeKey.forEach((a: any) => {
      tmp_maps.push(a.map_array);
    });

    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 28114,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        ...searchPrams
      },
      ds_state: setAllData,
      name: 'id-28114',
      cancelToken: source.token,
      t
    }).then(() => {
      setStartProcess(false);
    });
  };

  const [showAxisCompareStatus, setShowAxisCompareStatus] = useState(false);

  const showAxisCompare = () => {
    setShowAxisCompareStatus(true);
  };

  const showAxisCompareStatusClose = () => {
    setShowAxisCompareStatus(false);
  };

  const [listHeaders, setListHeaders] = useState([export_detail_all_list]);
  useEffect(() => {
    if (allData.length > 0) {
      let tmp_listHeader_have_data: any = [];
      allData.forEach((a: any) => {
        for (const header_item of export_detail_all_list) {
          if (!isEmpty(a[header_item.field]) || header_item.field === 'id') {
            tmp_listHeader_have_data.push(header_item);
          }
        }
      });
      tmp_listHeader_have_data = Array.from(new Set(tmp_listHeader_have_data)).sort(
        (a: any, b: any) => (a.order < b.order ? -1 : 1)
      );
      setListHeaders(tmp_listHeader_have_data);
      setDetailListOpen(true);
    }
  }, [allData]);

  useEffect(() => {
    if (!props.open) {
      setTypeKey([]);
      setGraphData0([]);
      setGraphData1([]);
      setGraphData2([]);
    }
  }, [props.open]);

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  useEffect(() => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42009,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42011,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42010,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .catch(e => e);
    setStartProcess(false);

    return () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
      <LFCDialogTitle onClose={props.onClose}>{props.pageName}</LFCDialogTitle>
      <DialogContent>
        <ProgressBar startProcess={startProcess} />
        <Box>
          <form className={classes.search}>
            <LFCFormRowGroup>
              <LFCDatetimePicker
                name={t('select_date_from')}
                label={t('対象期間From')}
                value={searchValue.select_date_from}
                required
                onChange={handleChange}
                disabled={startProcess}
              />
              <LFCDatetimePicker
                name={t('select_date_to')}
                label={t('対象期間To')}
                value={searchValue.select_date_to}
                required
                onChange={handleChange}
                disabled={startProcess}
              />
              <LFCAutocomplete
                name={t('work')}
                label={t('機種')}
                id={'work'}
                size="small"
                value={multiSelectData(work, searchValue.work)}
                onChange={autoOnChange}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                multiple={true}
                selectItem={work}
                disabled={startProcess}
              />
              <LFCAutocomplete
                name={t('map')}
                label={t('検査部位')}
                id={'map'}
                size="small"
                value={multiSelectData(map, searchValue.map)}
                onChange={autoOnChange}
                multiple={true}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                selectItem={map}
                disabled={startProcess}
              />
              <LFCAutocomplete
                name={'deviceid'}
                label={t('検査装置')}
                id={'deviceid'}
                size="small"
                value={multiSelectData(deviceid, searchValue.deviceid)}
                onChange={autoOnChange}
                multiple={true}
                onReset={autoCompleteReset}
                doneReset={setAutoCompleteReset}
                selectItem={deviceid}
                disabled={startProcess}
              />
              <LFCSelectFormJudge
                name={'judge'}
                value={searchValue.judge}
                onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
                multiple={false}
                disabled={startProcess}
              />

              <LFCButton color="primary" onClick={doSearch} disabled={startProcess}>
                {t('検索')}
              </LFCButton>
              <LFCButton onClick={restSearch} disabled={startProcess}>
                {t('リセット')}
              </LFCButton>
            </LFCFormRowGroup>
          </form>
          <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
          <div style={{float: 'right'}}>
            <span style={{marginRight: '8px'}}>
              <LFCButton size={'small'} color="secondary" onClick={showAxisCompare}>
                {t('座標軸別比較')}
              </LFCButton>
            </span>
            <LFCButton size={'small'} color="secondary" onClick={getAllList}>
              {t('詳細データ')}
            </LFCButton>
          </div>
          <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
            {searchType.map((item: any) => (
              <FormControlLabel
                disabled={startProcess}
                value={item.id}
                key={'search_type-' + item.id}
                control={
                  <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                }
                label={
                  <Typography display="block" key={'search_type-body-' + item.id}>
                    {item.text}
                  </Typography>
                }
              />
            ))}
          </RadioGroup>
        </Box>
        <Box mt={1}>
          {typeKey.length === 0 ? <div style={{height: '80vh'}}></div> : <></>}
          {typeKey.map((a: any, index: number) => {
            switch (true) {
              case a.tolerance_type === 0:
              case a.tolerance_type === null:
                return (
                  <Grid
                    container
                    spacing={2}
                    sx={{display: 'flex'}}
                    key={a.tolerance_type + '-Sphere'}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h1" className={classes.typeTitle}>
                        {t('検査方式(スフィア)')}
                      </Typography>
                    </Grid>
                    <PartsSphereComponent
                      open={props.open}
                      searchValue={searchValue}
                      typeKey={typeKey}
                      graphData0={graphData0}
                      startProcess={startProcess}
                      setStartProcess={setStartProcess}
                    />
                  </Grid>
                );
              case a.tolerance_type === 1:
                return (
                  <Grid
                    container
                    spacing={2}
                    sx={{display: 'flex'}}
                    key={a.tolerance_type + '-Cube'}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h1" className={classes.typeTitle}>
                        {t('検査方式(キューブ)')}
                      </Typography>
                    </Grid>
                    <PartsCubeComponent
                      open={props.open}
                      searchValue={searchValue}
                      typeKey={typeKey}
                      graphData1={graphData1}
                      startProcess={startProcess}
                      setStartProcess={setStartProcess}
                    />
                  </Grid>
                );
              case a.tolerance_type === 2:
                return (
                  <Grid
                    container
                    spacing={2}
                    sx={{display: 'flex'}}
                    key={a.tolerance_type + '-Cylinder'}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h1" className={classes.typeTitle}>
                        {t('検査方式(シリンダ)')}
                      </Typography>
                    </Grid>
                    <PartsCylinderComponent
                      open={props.open}
                      searchValue={searchValue}
                      typeKey={typeKey}
                      graphData2={graphData2}
                      startProcess={startProcess}
                      setStartProcess={setStartProcess}
                    />
                  </Grid>
                );
              default:
                return (
                  <Grid container spacing={2} sx={{display: 'flex'}} key={'tolerance_type-none'}>
                    <Grid item xs={12} style={{height: '70vh'}}>
                      {t('データなし')}
                    </Grid>
                  </Grid>
                );
            }
          })}
        </Box>
        <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
          <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Hole Compare 穴位置')}:${t(
            '詳細データ'
          )}`}</LFCDialogTitle>
          <DialogContent>
            <LFCDataGridSimple
              columns={listHeaders}
              rows={allData}
              height="80vh"
              exportFilename={`HoleCompare(${t('穴位置')})_${t('詳細データ')}`}
            />
          </DialogContent>
        </Dialog>

        <Dialog
          open={showAxisCompareStatus}
          onClose={showAxisCompareStatusClose}
          maxWidth={false}
          fullWidth
        >
          <LFCDialogTitle onClose={showAxisCompareStatusClose}>{t('座標軸別比較')}</LFCDialogTitle>
          <DialogContent>
            <PartsAxisComponent
              open={props.open}
              searchValue={searchValue}
              typeKey={typeKey}
              graphData1={graphData1}
            />
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default HoleComparePositionDig;
