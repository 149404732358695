import {Box, Grid, Typography} from '@mui/material';

interface Props {
  data: any;
}

export const ResultsHistoryDisplay = ({data}: Props) => {
  return (
    <Grid display="flex" flexDirection="row" mt="100px">
      {/* <Grid display="flex" flexDirection="column" gap="25px">
        <Box height="430px" justifyContent="center" display="flex" position={'relative'}>
          <img src={development1} width="90%" alt="" />;
          <TopBoxes data={data} setOpen={() => {}} />
        </Box>
        <Box height="430px" justifyContent="center" display="flex" position="relative">
          <img src={development2} width="90%" alt="" />;
          <BottomBoxes data={data} setOpen={() => {}} />
        </Box>
      </Grid>
      <GradientBox /> */}
    </Grid>
  );
};

const GradientBox = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Typography fontSize="20px" padding="5px" align="center">
        NG率
      </Typography>
      <Box
        sx={{
          width: 100,
          height: 1000,
          backgroundImage: 'linear-gradient(to bottom, red, green)',
          borderRadius: '10px',
          position: 'relative'
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: -30,
            transform: 'translate(50%, -50%)',
            color: 'black',
            fontSize: '18px'
          }}
        >
          100%
        </div>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            right: -30,
            transform: 'translate(50%, -50%)',
            color: 'black',
            fontSize: '18px'
          }}
        >
          0%
        </div>
      </Box>
    </Box>
  );
};
