import {useTheme} from '@mui/material';
import {green, red} from '@mui/material/colors';
import React, {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  y: {
    dsColumn: string;
  };
  series: string;
  visual_map: {
    min: number;
    max: number;
    show?: boolean;
    inRangeColor?: string[];
  };
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  onClick?: (prm: {x: string; y: string}) => void;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
  selected?: any;
  //heightは自動調整されるので追加しないこと
}

/**
 * LFCChartsHeatMap
 * @param props
 * @returns
 */
const LFCChartsHeatMap = React.memo((props: Props) => {
  const {t} = useTranslation();
  const theme = useTheme();
  const [dataSeriesX, setDataSeriesX] = useState([]);
  const [dataSeriesY, setDataSeriesY] = useState([]);
  const [dataPlot, setDataPlot] = useState([]);
  const [hhh, setHHH] = useState('');
  /**
   * sourceからXとY軸を作成
   */
  useEffect(() => {
    // シリーズ抽出：ヒートマップはXY全てCategory。
    let xds: any = [];
    let yds: any = [];
    let dp: any = [];

    if (props.source.length > 0) {
      // 軸毎のデータを取る
      xds = Array.from(new Set(props.source.map((value: any) => value[props.x.dsColumn]))).sort(
        (a, b) => Number(a) - Number(b)
      );
      yds = Array.from(new Set(props.source.map((value: any) => value[props.y.dsColumn]))).sort();

      if (xds.indexOf('None') !== -1) {
        xds.splice(xds.indexOf('None'), 1);
        xds.push('None');
      }
      // データソースからシリーズのインデックス配列(ヒートマップの座標に)変換
      props.source.forEach((item: any) => {
        dp.push([
          xds.indexOf(item[props.x.dsColumn]),
          yds.indexOf(item[props.y.dsColumn]),
          item[props.series]
        ]);
      });
    }

    setDataSeriesX(xds);
    setDataSeriesY(yds);
    setDataPlot(dp);

    setHHH(`${String(150 + yds.length * 20)}px`); //チャートの状況で高さを動的に変更
  }, [props.source]);

  const makeLineData = () => {
    if (props.selected !== undefined && props.selected.y !== '') {
      return {
        data: [
          {
            yAxis: props.selected.y
          }
        ],
        lineStyle: {
          type: 'dotted',
          width: 5,
          color: 'yellow',
          opacity: 0.8
          // curveness: 0.05
        },
        symbol: 'none',
        silent: true,
        label: {
          position: 'middle',
          formatter: t('選択中')
        }
      };
    } else {
      return undefined;
    }
  };

  return (
    <LFCBaseCharts
      option={{
        title: {
          text: props.title
        },
        grid: props.grid
          ? props.grid
          : {
              top: '40',
              left: '130',
              right: '10',
              bottom: 100
            },
        legend: {type: 'scroll', bottom: 0, itemWidth: 10},
        tooltip: {
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: dataSeriesX,
          axisLabel: {
            rotate: '45',
            fontSize: 11,
            width: 100,
            overflow: 'truncate'
          },
          splitLine: {
            lineStyle: {
              color: theme.palette.divider
            }
          }
        },
        yAxis: {
          type: 'category',
          data: dataSeriesY,
          axisLabel: {
            interval: 0,
            width: 125,
            overflow: 'truncate'
          },
          splitLine: {
            lineStyle: {
              color: theme.palette.divider
            }
          }
        },
        series: [
          {
            type: 'heatmap',
            data: dataPlot,
            selectedMode: 'single',
            markLine: makeLineData()
          }
        ],
        visualMap: {
          show: props.visual_map.show ? true : props.visual_map.show,
          min: props.visual_map.min,
          max: props.visual_map.max,
          inRange: {
            color: props.visual_map.inRangeColor
              ? props.visual_map.inRangeColor
              : [green[500], red[500]]
          },
          calculable: true,
          orient: 'horizontal',
          left: 'center',
          bottom: '0%'
        }
      }}
      onEvents={{
        click: (params: any) => {
          if (props.onClick != null) {
            props.onClick({x: dataSeriesX[params.data[0]], y: dataSeriesY[params.data[1]]});
          }
        }
      }}
      exportData={props.exportData}
      exportFields={props.exportFields}
      exportFilename={props.exportFilename}
      height={hhh}
    />
  );
});
export default LFCChartsHeatMap;
