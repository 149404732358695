import {useState} from 'react';
import ProgressBar from '_components/ProgressBar';
import WeldingDemoSearchBar from '_components/model/welding-demo/WeldingDemoSearchBar';
import {DEFAULT_WELDING_DEMO_SEARCH_FORM_VALUES} from 'constants/weldingDemo';
import WeldingDemoCard from './WeldingDemoCard';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import {LOCATIONID_LQ_INSPECTIONRESULTS_MAIN, PAGEID_LQ_INSPECTIONRESULTS} from '_logics/LFCPageId';

const WeldingDemoSearchForm = () => {
  const getDefaultCondition = useGetDefaultCondition();
  const [startProcess, setStartProcess] = useState(false);
  const [searchValue, setSearchValue] = useState(
    getDefaultCondition(PAGEID_LQ_INSPECTIONRESULTS, LOCATIONID_LQ_INSPECTIONRESULTS_MAIN) ??
      DEFAULT_WELDING_DEMO_SEARCH_FORM_VALUES
  );
  const [value, setValue] = useState<number>(0);

  return (
    <>
      <ProgressBar startProcess={startProcess} />
      <WeldingDemoSearchBar
        setStartProcess={setStartProcess}
        initialSearchValue={DEFAULT_WELDING_DEMO_SEARCH_FORM_VALUES}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <WeldingDemoCard
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        value={value}
        setValue={setValue}
        startProcess={startProcess}
      />
    </>
  );
};

export default WeldingDemoSearchForm;
