import {Dialog, DialogActions, DialogContent, Grid, Typography} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import LFCNumberField from '_components/inputs/LFCNumberField';
import LFCTextField from '_components/inputs/LFCTextField';
import {handleInputChange, setLFCDataMulti} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';

export type editMode = 'None' | 'AddNew' | 'Modify';
export type rowData = {
  mode: editMode;
  id: string;
  company_id: string;
  company_name: string;
  region: string;
};
export const emptyRowData: rowData = {
  mode: 'None',
  id: '',
  company_id: '',
  company_name: '',
  region: ''
};

interface Props {
  open: boolean;
  onClose: () => void;
  row: rowData;
}

const CompanyMasterDetailDlg = (props: Props) => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [values, setValues] = useState<rowData>(emptyRowData);
  const {enqueueSnackbar} = useSnackbar();
  const [source] = useState(axios.CancelToken.source());

  // 保存ボタン押下時
  const onSaveClick = async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }

    switch (values.mode) {
      case 'AddNew':
        await setLFCDataMulti({
          snack: enqueueSnackbar,
          sql_id: 5000,
          parameters: {
            p_company_id: values.company_id,
            company_name: values.company_name,
            region: values.region
          },
          name: `${t('会社マスタの新規登録')}`,
          cancelToken: source.token,
          t
        });
        props.onClose();
        break;
      case 'Modify':
        await setLFCDataMulti({
          snack: enqueueSnackbar,
          sql_id: 5001,
          parameters: {
            p_company_id: values.company_id,
            company_name: values.company_name,
            region: values.region
          },
          name: `${t('会社マスタの更新')}`,
          cancelToken: source.token,
          t
        });
        props.onClose();
        break;
    }
  };

  useEffect(() => {
    props.open && setValues(props.row);
  }, [props.open]);

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={'sm'}>
      <LFCDialogTitle onClose={props.onClose}>
        {values.mode === 'AddNew' ? t('新規登録') : t('変更')}
      </LFCDialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">{t('message.登録内容を入力してください')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <form ref={formRef}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <LFCNumberField
                    name="company_id"
                    label={t('会社ID')}
                    value={values?.company_id}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    fullWidth
                    disabled={values.mode === 'AddNew' ? false : true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="company_name"
                    label={t('会社名')}
                    value={values?.company_name}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="region"
                    label={'Region'}
                    value={values?.region}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LFCButton onClick={props.onClose}>{t('キャンセル')}</LFCButton>
        <LFCButton color="primary" onClick={onSaveClick}>
          {t('保存')}
        </LFCButton>
      </DialogActions>
    </Dialog>
  );
};
export default CompanyMasterDetailDlg;
