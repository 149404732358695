import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {useEffect, useState} from 'react';
import {makeStyles} from 'tss-react/mui';
import {ExportCsv, ExportExcel} from '_components/ExportExcelCsv';
import {useMySettings} from '_contexts/MySettingsProvider';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    }
  };
});

const LFCTableWorkerPerformance = (props: any) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const [tmp_days_list, setTmp_days_list] = useState([]);
  const [userIdList, setUserIdList] = useState<any>([]);

  const makeDaysList = () => {
    let tmp_day = new Date(props.select_date);
    let tmp_days_list: any = [];
    for (let i = 1; i <= 31; i++) {
      tmp_day.setDate(+i);
      if (tmp_day.getMonth() + 1 === new Date(props.select_date).getMonth() + 1) {
        tmp_days_list.push(('0' + tmp_day.getDate()).slice(-2));
      }
    }
    tmp_days_list.push('総合計');
    setTmp_days_list(tmp_days_list);
  };

  const do_listData_by_id = (id: number) => {
    return props.datas.filter((item: any) => item.user_id === id);
  };

  const display_data = (userID: number, day: string, data_type: string) => {
    let select_datas: any = do_listData_by_id(userID).filter(
      (item: any) => item.day === day && item.data_type === data_type
    );
    if (select_datas.length > 0) {
      let sums: number = 0;
      select_datas.forEach((item: any) => {
        sums = sums + Number(item.data_value);
      });
      return sums.toLocaleString();
    } else {
      return '';
    }
  };

  useEffect(() => {
    setUserIdList([]);
    setTmp_days_list([]);
    makeDaysList();
    if (props.datas.length > 0) {
      setUserIdList(Array.from(new Set(props.datas.map((item: any) => item.user_id))).sort());
    }
  }, [props.datas]);

  let fields_name = [
    {columnField: 'id', columnName: t('通し番号'), columnOrder: 0},
    {columnField: 'data_type', columnName: t('カテゴリー'), order: 1},
    {columnField: 'data_value', columnName: t('結果'), order: 2},
    {columnField: 'rfid', columnName: 'RFID', order: 5},
    {columnField: 'user_name', columnName: t('ユーザー名'), order: 7},
    {columnField: 'working_date', columnName: t('稼働日'), order: 8}
  ];

  return (
    <TableContainer component={Paper}>
      <style>
        {`
      .MuiTableCell-root {
        border-top: 1px solid;
        border-left: 1px solid;
        border-right: 1px solid;
        border-color: rgba(224, 224, 224, 1);
        padding: 8px;
      }
       `}
      </style>
      <div style={{textAlign: 'right'}}>
        <ExportCsv
          variant={'outlined'}
          data={props.datas}
          fields={fields_name}
          filename={`${t('作業者実績')}_` + String(props.select_date).replace(/\//g, '-')}
        />
        <ExportExcel
          variant={'outlined'}
          data={props.datas}
          fields={fields_name}
          filename={`${t('作業者実績')}_` + String(props.select_date).replace(/\//g, '-')}
        />
      </div>
      <Table className={classes.table}>
        <TableHead key={'head0'}>
          <TableRow>
            <TableCell align="center">-</TableCell>
            <TableCell align="center">{t('日付')}</TableCell>
            {tmp_days_list.map((day: string) => (
              <TableCell align="center" key={day}>
                {day}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center" style={{whiteSpace: 'nowrap'}}>
              {t('作業者')}
            </TableCell>
            <TableCell align="center" style={{whiteSpace: 'nowrap'}}>
              {t('カテゴリー')}
            </TableCell>
            {tmp_days_list.map((day: string) => (
              <TableCell align="center" key={day}></TableCell>
            ))}
          </TableRow>
        </TableHead>

        {/*ユーザー毎のブロック*/}

        {userIdList.map((userID: number) => (
          <TableBody key={userID}>
            <TableRow>
              <TableCell align="center" style={{whiteSpace: 'nowrap'}} rowSpan={6}>
                {/*// @ts-ignore*/}
                {do_listData_by_id(userID)[0] ? do_listData_by_id(userID)[0].user_name : null}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={
                  mySettings.isDark
                    ? {whiteSpace: 'nowrap', color: '#6cd5ff'}
                    : {whiteSpace: 'nowrap', color: '#227a9d'}
                }
              >
                {t('良品数')}
              </TableCell>
              {tmp_days_list.map((day: string) => (
                <TableCell
                  align="right"
                  key={'良品数_' + day}
                  style={
                    mySettings.isDark
                      ? {whiteSpace: 'nowrap', color: '#6cd5ff'}
                      : {whiteSpace: 'nowrap', color: '#227a9d'}
                  }
                >
                  {display_data(userID, day, '良品数')}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={
                  mySettings.isDark
                    ? {whiteSpace: 'nowrap', color: '#f68a92'}
                    : {whiteSpace: 'nowrap', color: '#9f474f'}
                }
              >
                {t('不良数')}
              </TableCell>
              {tmp_days_list.map((day: string) => (
                <TableCell
                  align="right"
                  key={'不良数_' + day}
                  style={
                    mySettings.isDark
                      ? {whiteSpace: 'nowrap', color: '#f68a92'}
                      : {whiteSpace: 'nowrap', color: '#9f474f'}
                  }
                >
                  {display_data(userID, day, '不良数')}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="center" style={{whiteSpace: 'nowrap'}}>
                {t('作業時間')}
              </TableCell>
              {tmp_days_list.map((day: string) => (
                <TableCell align="right" key={'作業時間_' + day}>
                  {display_data(userID, day, '作業時間')}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell align="center" style={{whiteSpace: 'nowrap'}}>
                {t('停止時間')}
              </TableCell>
              {tmp_days_list.map((day: string) => (
                <TableCell align="right" key={'停止時間_' + day}>
                  {display_data(userID, day, '停止時間')}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell
                align="center"
                style={
                  mySettings.isDark
                    ? {whiteSpace: 'nowrap', color: '#ffc46c'}
                    : {whiteSpace: 'nowrap', color: '#c78018'}
                }
              >
                {t('サイクルタイム')}
              </TableCell>
              {tmp_days_list.map((day: string) => (
                <TableCell
                  align="right"
                  key={'サイクルタイム_' + day}
                  style={
                    mySettings.isDark
                      ? {whiteSpace: 'nowrap', color: '#ffc46c'}
                      : {whiteSpace: 'nowrap', color: '#c78018'}
                  }
                >
                  {display_data(userID, day, 'サイクルタイム')}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        ))}
      </Table>
    </TableContainer>
  );
};

export default LFCTableWorkerPerformance;
