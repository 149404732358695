import {Box, Grid, Typography} from '@mui/material';
import {DataGridPro, GridColumns, GridValueFormatterParams} from '@mui/x-data-grid-pro';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import ProgressBar from '_components/ProgressBar';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {FORMAT_YYYYMMDDHHmmSS} from '_logics/LFCConst';
import {renderCellExpand} from '_logics/LFCRenderUtil';
import {handleInputChange, handleInputChange2, soracomAPIAccess} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

/**
 * リモートアクセスIP管理
 * @returns
 */
const RemoteAccessMaintenance = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dsPortMappingList, setPortMappingList] = useState<any>([]);
  const [values, setValues] = React.useState({
    imsi: '295050911885674',
    port: '10001',
    durationHour: '8',
    ipRanges: ''
  });

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  /**
   * PublicIpを取得します
   */
  const GetPublicIp = async () => {
    const res = await fetch('https://api.ipify.org/?format=json');
    const data = await res.json();
    return data.ip;
  };

  /**
   * IP発行済みリストを取得します
   */
  const GetPortMappingList = () => {
    setStartProcess(true);
    Promise.allSettled([
      soracomAPIAccess({
        method: 'GET',
        apipath: 'PortMapping',
        parameters: {limit: 500}
      })
        .then(res => {
          console.log(res.data);
          setPortMappingList(
            res.data.map((row: any, idx: number) => {
              row.id = idx;
              return row;
            })
          );
        })
        .catch(error => {
          console.error(error.message);
          enqueueSnackbar(t('message.発行済み情報の取得に失敗しました。'), {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  /**
   * IP発行します
   */
  const PutPortMapping = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setStartProcess(true);
    Promise.allSettled([
      soracomAPIAccess({
        method: 'POST',
        apipath: 'PortMapping',
        parameters: {
          isGlobal: true,
          destination: {imsi: values.imsi, port: Number(values.port)},
          duration: Number(values.durationHour) * 60 * 60,
          source: {ipRanges: [`${values.ipRanges}/32`]},
          tlsRequired: false
        }
      })
        .then(res => {
          console.log(res.data);
          enqueueSnackbar(
            `${t('messsage.アクセス用IPを発行しました。IPアドレス=')}${res.data.endpoint}`,
            {variant: 'success'}
          );
          GetPortMappingList();
        })
        .catch(error => {
          console.error(error.message);
          enqueueSnackbar(t('message.IP発行処理に失敗しました。'), {variant: 'error'});
        })
    ]).then(() => {
      setStartProcess(false);
    });
  };

  useEffect(() => {
    // マウント時処理
    GetPublicIp().then(ip => {
      setValues({...values, ipRanges: `${ip}`});
    });

    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {field: 'name', headerName: `${t('SIM名称')}`, width: 200, renderCell: renderCellExpand},
      {field: 'imsi', headerName: 'IMSI', width: 150, renderCell: renderCellExpand},
      {
        field: 'destination',
        headerName: `${t('接続先PORT')}`,
        width: 100,
        valueFormatter: (prms: GridValueFormatterParams) => prms.value.port
      },
      {
        field: 'source',
        headerName: `${t('接続元IPレンジ')}`,
        width: 150,
        valueFormatter: (prms: GridValueFormatterParams) => prms.value.ipRanges[0]
      },
      {
        field: 'createdTime',
        headerName: `${t('作成時間')}`,
        width: 160,
        valueFormatter: (prms: GridValueFormatterParams) =>
          dayjs(prms.value).format(FORMAT_YYYYMMDDHHmmSS)
      },
      {
        field: 'expiredTime',
        headerName: `${t('有効期限')}`,
        width: 160,
        valueFormatter: (prms: GridValueFormatterParams) =>
          dayjs(prms.value).format(FORMAT_YYYYMMDDHHmmSS)
      },
      {field: 'endpoint', headerName: `${t('払出IP')}`, width: 200, cellClassName: 'Boldn'}
    ]);
    GetPortMappingList();

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  return (
    <GenericTemplate title={t('リモートアクセスIP管理')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          <Grid item xs={6}>
            <Typography variant="subtitle1">
              {t('message.SORACOMのオンデマンドリモートアクセスの管理を行います。')}
            </Typography>
          </Grid>
          <Grid item xs={6} textAlign="right">
            <LFCButton color="primary" onClick={GetPortMappingList}>
              {t('message.最新の情報に更新')}
            </LFCButton>
          </Grid>
          <Grid item xs={12} sm={3}>
            <LFCTitledCard title={t('新規作成')}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <LFCTextField
                    name="imsi"
                    label="IMSI"
                    value={values.imsi}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    inputProps={{length: 15, pattern: '[0-9]*'}}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCSelectForm
                    name={'port'}
                    label={t('接続先ポート')}
                    value={values.port}
                    id={'port'}
                    onChange={event => {
                      handleInputChange2(event, values, setValues);
                    }}
                    selectItem={[
                      {name: 'UltraVNC(10001)', value: 10001},
                      {name: 'RemoteDesktop(20001)', value: 20001}
                    ]}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCSelectForm
                    name="durationHour"
                    label={t('有効時間(h)')}
                    value={values.durationHour}
                    id={'durationHour'}
                    onChange={event => {
                      handleInputChange2(event, values, setValues);
                    }}
                    selectItem={[...Array(9)].map((_, i) => {
                      return {name: String(i + 1), value: String(i + 1)};
                    })}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCTextField
                    name="ipRanges"
                    label={t('接続元PublicIP')}
                    value={values.ipRanges}
                    onChange={event => {
                      handleInputChange(event, values, setValues);
                    }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCButton color="primary" fullWidth onClick={PutPortMapping}>
                    {t('発行')}
                  </LFCButton>
                </Grid>
              </Grid>
            </LFCTitledCard>
          </Grid>
          <Grid item xs={12} sm={9} container spacing={2}>
            <Grid item xs={12}>
              <LFCTitledCard title={t('有効な接続情報一覧')} transparent>
                <Box sx={{height: '70vh'}}>
                  <DataGridPro
                    sx={{
                      '& .Boldn': {
                        color: 'secondary.main'
                      }
                    }}
                    columns={dgColumns}
                    rows={dsPortMappingList}
                    density={'compact'}
                    showCellRightBorder
                    showColumnRightBorder
                    disableSelectionOnClick
                    initialState={{
                      sorting: {
                        sortModel: [{field: 'createdTime', sort: 'asc'}]
                      }
                    }}
                  />
                </Box>
              </LFCTitledCard>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </GenericTemplate>
  );
};

export default RemoteAccessMaintenance;
