import {CssBaseline as CssBaselineV5} from '@mui/material';
import React from 'react';
import LFCThemeProviderV5 from '_themes/mui/LFCThemeProviderV5';
import LFCMainPage from './pages/LFCMainPage';

export interface Props {
  title: string;
  children: React.ReactNode;
}

/**
 * LFC 標準サイト用テンプレート
 * @param props
 * @returns
 */
const GenericTemplate = (props: Props) => {
  return (
    <LFCThemeProviderV5>
      <CssBaselineV5 />
      <LFCMainPage title={props.title}>{props.children}</LFCMainPage>
    </LFCThemeProviderV5>
  );
};

export default GenericTemplate;
