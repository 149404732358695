import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import GenericTemplate from '_templates/GenericTemplate';
import {makeStyles} from 'tss-react/mui';
import {useTranslation} from 'react-i18next';
import {
  Box,
  Button,
  Collapse,
  colors,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab
} from '@mui/material';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';

import eazyMenu from '_assets/data/eazy_menu.json';
import LFCFileField from '_components/inputs/LFCFileField';
import {isEmpty} from 'lodash';
import {getSessionSync} from '_logics/LFCCognito';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {getLFCSessionStorage} from '_contexts/LFCSessionStorage';

/**
 * Style
 */
const useStyles = makeStyles()(theme => {
  return {
    root: {
      flexGrow: 1,
      width: '100%',
      marginTop: 10
    },
    formcontrol: {
      display: 'flex'
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    table: {
      maxWidth: '100%'
      // Width: '50%',
      // border: '1px solid black'
    },
    table_inner: {
      maxWidth: '100%'
      // Width: '50%',
    },
    table_list: {
      '&:hover': {
        backgroundColor: 'rgba(152,151,151,0.25)'
      }
    },
    cardMenu: {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: colors.blueGrey[500],
        opacity: 0.9
      }
    },
    menuImage: {
      fill: theme.palette.primary.main
    },

    menuBoxSetting: {
      width: '250px',
      maxWidth: '250px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      }
      // height: '30px',
    },

    menuBoxSetting2nd: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '100%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen1: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '200%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen2: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '300%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen3: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '400%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen4: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '500%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen5: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '600%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen6: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '700%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen7: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '800%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen8: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '900%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen9: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1000%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen10: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1100%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen11: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1200%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen12: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1300%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen13: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1400%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen14: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1500%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen15: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '1600%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2nd_last: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '50%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting2ndOpen_last: {
      width: '400px',
      maxWidth: '400px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '238px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      position: 'relative',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: 0,
        width: 0,
        height: '50%',
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-200px',
        top: '50%',
        width: '210px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting3rd: {
      width: '770px',
      maxWidth: '770px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '468px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 0,
        height: '100%',
        left: '-188px',
        top: 0,
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-188px',
        top: '50%',
        width: '190px',
        height: 0,
        borderTop: '1px dotted #666'
      }
    },
    menuBoxSetting3rd_last: {
      width: '770px',
      maxWidth: '770px',
      paddingTop: '5px',
      paddingBottom: '5px',
      marginLeft: '468px',
      marginRight: '5px',
      svg: {
        marginRight: '20px'
      },
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        width: 0,
        height: '100%',
        left: '-188px',
        top: 0,
        borderLeft: '1px dotted #666'
      },
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-188px',
        top: '50%',
        width: '190px',
        height: 0,
        borderTop: '1px dotted #666'
      },
      '&:last-child': {
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: 0,
          height: '50%',
          left: '-188px',
          top: 0,
          borderLeft: '1px dotted #666'
        }
      }
    },
    stepperBorder: {
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: '-40%',
        top: '50%',
        width: '40%',
        height: 0,
        borderTop: '1px dotted #666'
      }
    }
  };
});

const EasyNaviPage = () => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setOpenMenuIDLv1(999);
    setOpenMenuIDLv2(999);
    setValue(newValue);
  };

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const [openMenuIDLv1, setOpenMenuIDLv1] = React.useState<number>(999);
  const [openMenuIDLv2, setOpenMenuIDLv2] = React.useState<number>(999);

  const clickMenuIdLv1 = (menuId: number) => {
    setOpenMenuIDLv1(menuId);
    setOpenMenuIDLv2(999);
    if (menuId === openMenuIDLv1 && openMenuIDLv1 < 10) {
      setOpenMenuIDLv1(999);
    }
  };
  const clickMenuIdLv2 = (menuId: number) => {
    setOpenMenuIDLv2(menuId);
    if (menuId === openMenuIDLv2 && menuId > 0 && menuId < 20) {
      setOpenMenuIDLv2(0);
    } else if (menuId === openMenuIDLv2 && menuId >= 20 && menuId < 30) {
      setOpenMenuIDLv2(1);
    } else if (menuId === openMenuIDLv2 && menuId >= 30 && menuId < 40) {
      setOpenMenuIDLv2(2);
    } else if (menuId === openMenuIDLv2 && menuId >= 40 && menuId < 50) {
      setOpenMenuIDLv2(3);
    } else if (menuId === openMenuIDLv2 && menuId >= 50 && menuId < 60) {
      setOpenMenuIDLv2(4);
    }
  };

  const calcLineHeight = (record: number) => {
    switch (record) {
      case 1:
        return classes.menuBoxSetting2ndOpen1;
      case 2:
        return classes.menuBoxSetting2ndOpen2;
      case 3:
        return classes.menuBoxSetting2ndOpen3;
      case 4:
        return classes.menuBoxSetting2ndOpen4;
      case 5:
        return classes.menuBoxSetting2ndOpen5;
      case 6:
        return classes.menuBoxSetting2ndOpen6;
      case 7:
        return classes.menuBoxSetting2ndOpen7;
      case 8:
        return classes.menuBoxSetting2ndOpen8;
      case 9:
        return classes.menuBoxSetting2ndOpen9;
      case 10:
        return classes.menuBoxSetting2ndOpen10;
      case 11:
        return classes.menuBoxSetting2ndOpen11;
      case 12:
        return classes.menuBoxSetting2ndOpen12;
      case 13:
        return classes.menuBoxSetting2ndOpen13;
      case 14:
        return classes.menuBoxSetting2ndOpen14;
      case 15:
        return classes.menuBoxSetting2ndOpen15;
    }
  };

  const [replaceMode, setReplaceMode] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState('');
  const [pageId, setPageId] = useState<null | number>();
  const showSample = (pageId: number, name: string) => {
    setPageId(pageId);
    setName(name);
    setShow(true);
  };

  // ファイルアップロード
  const [inputFile, setInputFile] = useState<any>();
  const [inputFileRow, setInputFileRow] = useState<any>();
  const [searchValues, setSearchValues] = useState({
    tmp_filename: '',
    filesize: '',
    filetype: ''
  });
  const base64Encode = (...parts: any) =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = () => {
        // @ts-ignore
        const offset = reader.result.indexOf(',') + 1;
        // @ts-ignore
        resolve(reader.result.slice(offset));
      };
      reader.readAsDataURL(new Blob(parts));
    });

  const formhandleChangeFile = (e: any) => {
    if (e.target.files[0].size > 1048576) {
      //1MB
      enqueueSnackbar(t('message.画像ファイルサイズは1MB未満でお願いします。'), {
        variant: 'error',
        persist: true
      });
      let file_object = document.getElementById('input_file_value');
      // @ts-ignore
      file_object.value = '';
      setInputFile(null);
    } else if (
      e.target.files[0].type !== 'image/png' &&
      e.target.files[0].type !== 'image/jpeg' &&
      e.target.files[0].type !== 'image/jpg'
    ) {
      enqueueSnackbar(t('message.画像ファイルタイプ:JPEGまたはPNGでお願いします。'), {
        variant: 'error',
        persist: true
      });
      let file_object = document.getElementById('input_file_value');
      // @ts-ignore
      file_object.value = '';
      setInputFile(null);
    } else {
      base64Encode(e.target.files[0]).then(response => {
        setInputFile(response);
      });
      setInputFileRow({
        File: e.target.files[0]
      });
      setSearchValues({
        ...searchValues,
        tmp_filename: pageId + '.' + e.target.files[0].type.replace('image/', ''),
        filesize: e.target.files[0].size,
        filetype: e.target.files[0].type
      });
    }
  };

  const fileUpLoad = async () => {
    const cognitoSession = await getSessionSync();
    let config = {
      headers: {Authorization: cognitoSession?.getIdToken().getJwtToken()}
    };
    await axios
      .post(
        `${getLFCSessionStorage().webapiBaseUrl}/GetUrlEasyMenu`,
        {
          FileType: searchValues.filetype,
          FileName: encodeURI(searchValues.tmp_filename)
        },
        config
      )
      .then(response => {
        let tmp_url = JSON.parse(response.data.body);
        s3DirectUpload(tmp_url.s3_url);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const s3DirectUpload = (url: string) => {
    let config = {
      headers: {
        'Content-Type': searchValues.filetype
      }
    };
    axios
      .put(url, inputFileRow.File, config)
      .then(response => {
        console.log(response);
        enqueueSnackbar(t('message.アップロードを完了しました。'), {variant: 'success'});
        closeDialog();
      })
      .catch(error => {
        console.log(error);
        enqueueSnackbar(t('message.アップロードに失敗しました。'), {variant: 'error'});
        closeDialog();
      });
  };

  const sendsearchValuesDirect = async () => {
    setStartProcess(true);
    await fileUpLoad().then(() => {
      setStartProcess(false);
    });
  };

  const doCancel = () => {
    setReplaceMode(false);
    setInputFile(null);
    setInputFileRow(null);
    setSearchValues({
      tmp_filename: '',
      filesize: '',
      filetype: ''
    });
  };

  const closeDialog = () => {
    doCancel();
    setPageId(999);
    setName('');
    setShow(false);
  };

  const makeMenuShow = (id: number) => {
    let menuGroups: any =
      id === 0
        ? eazyMenu
            .filter((a: any) => a.menu_group < 5)
            .filter((a: any) => a.menu_id === a.menu_group)
        : eazyMenu
            .filter((a: any) => a.menu_group > 4)
            .filter((a: any) => a.menu_id === a.menu_group);
    return menuGroups.map((a: any, index: number) => {
      return (
        <div key={'listBody-' + index}>
          <ListItemButton
            component={'li'}
            onClick={() => clickMenuIdLv1(a.menu_id)}
            className={classes.menuBoxSetting}
            key={'lev1_' + a.menu_id + index}
          >
            <Box sx={{display: 'flex', width: '80px'}}>
              <svg width="50px" height="50px">
                <use
                  xlinkHref={`images/easy/easy_lv1_${a.menu_id}.svg#_x32_`}
                  className={classes.menuImage}
                />
              </svg>
            </Box>
            <ListItemText primary={a.menu_name} />
            {openMenuIDLv1 === a.menu_id ? (
              <span className="material-icons">navigate_before</span>
            ) : (
              <span className="material-icons">navigate_next</span>
            )}
          </ListItemButton>
          {eazyMenu
            .filter((b: any) => b.menu_group === a.menu_id && b.menu_content.length > 0)
            .map((c: any, index: number) => {
              return (
                <Collapse
                  in={openMenuIDLv1 === c.menu_group}
                  timeout={'auto'}
                  unmountOnExit
                  key={'base_lev2_' + c.menu_id + index}
                >
                  <ListItemButton
                    component={'li'}
                    onClick={() => clickMenuIdLv2(c.menu_id)}
                    key={'lev2_' + c.menu_id}
                    className={
                      c.menu_id === Math.max(...eazyMenu.map((g: any) => g.menu_id))
                        ? classes.menuBoxSetting2ndOpen_last
                        : openMenuIDLv2 !== c.menu_id
                          ? classes.menuBoxSetting2nd
                          : calcLineHeight(c.menu_content.length)
                    }
                  >
                    <Box sx={{display: 'flex', width: '80px'}}>
                      <svg width="50px" height="50px">
                        <use
                          xlinkHref={`images/easy/easy_lv2_${c.menu_id}.svg#_x32_`}
                          className={classes.menuImage}
                        />
                      </svg>
                    </Box>
                    <ListItemText primary={c.menu_name} />
                    {openMenuIDLv2 === c.menu_id ? (
                      <span className="material-icons">navigate_before</span>
                    ) : (
                      <span className="material-icons">navigate_next</span>
                    )}
                  </ListItemButton>
                  {eazyMenu
                    .filter((b: any) => b.menu_id === c.menu_id)
                    .map((e: any) => {
                      return e.menu_content.map((f: any, index: number) => {
                        return (
                          <Collapse
                            in={e.menu_id === openMenuIDLv2}
                            timeout={'auto'}
                            unmountOnExit
                            key={'base_lev3_' + e.menu_id + index}
                          >
                            <ListItem
                              component={'li'}
                              className={
                                e.menu_content.length - 1 !== index
                                  ? classes.menuBoxSetting3rd
                                  : classes.menuBoxSetting3rd_last
                              }
                              key={'lev3_' + e.menu_id}
                            >
                              <Box sx={{display: 'flex', width: '80px'}}>
                                <svg width="50px" height="50px">
                                  <use
                                    xlinkHref={`images/menu${f.path}.svg#_x32_`}
                                    className={classes.menuImage}
                                  />
                                </svg>
                              </Box>
                              <Box
                                sx={{display: 'flex', justifyContent: 'flex-start', width: '100%'}}
                              >
                                <ListItemText primary={f.comment} />
                              </Box>
                              <Box
                                sx={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}
                              >
                                <Button variant="outlined" onClick={() => showSample(f.id, f.name)}>
                                  {f.name}
                                </Button>
                              </Box>
                            </ListItem>
                          </Collapse>
                        );
                      });
                    })}
                </Collapse>
              );
            })}
        </div>
      );
    });
  };

  return (
    <GenericTemplate title={t('menu.page_name.簡単ナビサンプル画像登録')}>
      <ProgressBar startProcess={startProcess} />

      <section className={classes.root}>
        <TabContext value={value}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label={t('easynavi.L-Qualify 簡単ナビ')} value={'1'} />
            <Tab label={t('easynavi.L-Robot 簡単ナビ')} value={'5'} />
          </TabList>

          <TabPanel value="1" style={{margin: 18}}>
            <List
              sx={{width: '100%', maxWidth: '100%', bgcolor: 'background.paper'}}
              component="ul"
              aria-labelledby="nested-list-subheader"
              key={'list_top'}
            >
              {makeMenuShow(0)}
            </List>
            {show ? (
              <Dialog open={true} maxWidth={'lg'} fullWidth>
                <DialogTitle>
                  {t('メニュー名称')}:{name}
                </DialogTitle>
                <DialogContent>
                  {isEmpty(inputFile) ? (
                    <>
                      <img
                        src={String(process.env.REACT_APP_EASYMENUIMAGE_PATH) + pageId + '.jpeg'}
                        alt=""
                        style={{width: '100%'}}
                      />
                    </>
                  ) : (
                    <img
                      src={`data:image/${searchValues.filetype}};base64,${inputFile}`}
                      style={{width: '100%'}}
                      alt=""
                    />
                  )}
                </DialogContent>
                <Grid container spacing={2} justifyContent={'flex-end'} sx={{padding: 1}}>
                  <Grid item>
                    {!replaceMode ? (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => closeDialog()}
                          color="primary"
                          sx={{marginRight: 1}}
                        >
                          {t('閉じる')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setReplaceMode(true)}
                          color="primary"
                        >
                          {t('画像変更')}
                        </Button>
                      </>
                    ) : (
                      <>
                        <LFCFileField
                          id="input_file_value"
                          name="file"
                          label="file"
                          onChange={formhandleChangeFile}
                        />
                        <Button
                          variant="outlined"
                          onClick={() => doCancel()}
                          color="primary"
                          style={{margin: '5,0'}}
                        >
                          {t('キャンセル')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => sendsearchValuesDirect()}
                          disabled={isEmpty(inputFile)}
                          style={{margin: 5}}
                        >
                          {t('アップロード')}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {' '}
                    menu_id：{pageId}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('設置ファイル名')}：{searchValues.tmp_filename}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('ファイルタイプ')}：{searchValues.filetype}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('ファイルサイズ')}：{searchValues.filesize}{' '}
                  </Grid>
                </Grid>
              </Dialog>
            ) : (
              <></>
            )}
          </TabPanel>

          <TabPanel value="5" style={{margin: 18}}>
            <List
              sx={{width: '100%', maxWidth: '100%', bgcolor: 'background.paper'}}
              component="ul"
              aria-labelledby="nested-list-subheader"
              key={'list_top'}
            >
              {makeMenuShow(1)}
            </List>
            {show ? (
              <Dialog open={true} maxWidth={'lg'} fullWidth>
                <DialogTitle>
                  {t('メニュー名称')}:{name}
                </DialogTitle>
                <DialogContent>
                  {isEmpty(inputFile) ? (
                    <>
                      <img
                        src={String(process.env.REACT_APP_EASYMENUIMAGE_PATH) + pageId + '.jpeg'}
                        alt=""
                        style={{width: '100%'}}
                      />
                    </>
                  ) : (
                    <img
                      src={`data:image/${searchValues.filetype}};base64,${inputFile}`}
                      style={{width: '100%'}}
                      alt=""
                    />
                  )}
                </DialogContent>
                <Grid container spacing={2} justifyContent={'flex-end'} sx={{padding: 1}}>
                  <Grid item>
                    {!replaceMode ? (
                      <>
                        <Button
                          variant="outlined"
                          onClick={() => closeDialog()}
                          color="primary"
                          sx={{marginRight: 1}}
                        >
                          {t('閉じる')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => setReplaceMode(true)}
                          color="primary"
                        >
                          {t('画像変更')}
                        </Button>
                      </>
                    ) : (
                      <>
                        <LFCFileField
                          id="input_file_value"
                          name="file"
                          label="file"
                          onChange={formhandleChangeFile}
                        />
                        <Button
                          variant="outlined"
                          onClick={() => doCancel()}
                          color="primary"
                          style={{margin: '5,0'}}
                        >
                          {t('キャンセル')}
                        </Button>
                        <Button
                          variant="contained"
                          onClick={() => sendsearchValuesDirect()}
                          disabled={isEmpty(inputFile)}
                          style={{margin: 5}}
                        >
                          {t('アップロード')}
                        </Button>
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    {' '}
                    menu_id：{pageId}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('設置ファイル名')}：{searchValues.tmp_filename}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('ファイルタイプ')}：{searchValues.filetype}{' '}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    {' '}
                    {t('ファイルサイズ')}：{searchValues.filesize}{' '}
                  </Grid>
                </Grid>
              </Dialog>
            ) : (
              <></>
            )}
          </TabPanel>
        </TabContext>
      </section>
    </GenericTemplate>
  );
};

export default EasyNaviPage;
