import {Box, Divider, Typography} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useAuthUser} from '_contexts/AuthUserProvider';
import {getLFCData, setLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';

const HomePage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [information, setInformation] = useState([]);
  const authUser = useAuthUser();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    if (authUser) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 20001,
        parameters: {},
        ds_state: setInformation,
        cancelToken: source.token,
        t
      });
      setLFCData({
        snack: enqueueSnackbar,
        sql_id: 20005,
        parameters: {
          information_access_timestamp: dayjs().format()
        },
        cancelToken: source.token,
        t
      });
    }
  }, [authUser]);

  return (
    <GenericTemplate title={t('ホーム')}>
      <Box mt={1}>
        <LFCTitledCard title={t('お知らせ')} transparent>
          {information.map((item: any, index: number) => {
            return (
              <Box key={'info_' + index} mb={2}>
                <Typography variant="caption" color="secondary">
                  {item.start_date}
                </Typography>
                <Typography variant="body1">{item.title}</Typography>
                <Divider />
                <Typography variant="caption" color="textSecondary">
                  <span dangerouslySetInnerHTML={{__html: item.body}} />
                </Typography>
              </Box>
            );
          })}
        </LFCTitledCard>
      </Box>
    </GenericTemplate>
  );
};

export default HomePage;
