import {getFontFamily, getColors} from '_themes/LFCCommonThemes';
import * as echarts from 'echarts/core';

/**
 * 共通 Echartsスタイル
 * @param prm
 * @returns
 */
const getEchartsThemeBase = (prm: {
  bgColor: string;
  fontColor: string;
  fontSubColor: string;
  fontLabelColor: string;
  iconColor: string;
  lineColor: string;
  splitColor: string;
  primaryColor: string;
  secondaryColor: string;
}): any => {
  const AXIS = {
    nameTextStyle: {
      // 軸名
      color: prm.fontSubColor,
      fontSize: 14
    },
    axisLabel: {
      // 軸目盛り文言
      show: true,
      color: prm.fontSubColor,
      fontSize: 14
    },
    axisLine: {
      // 軸線
      show: true,
      lineStyle: {
        color: prm.lineColor
      }
    },
    axisTick: {
      // 軸目盛り
      show: true,
      lineStyle: {
        color: prm.lineColor
      }
    },
    splitLine: {
      // 分割線
      show: true,
      lineStyle: {
        color: [prm.splitColor]
      }
    },
    splitArea: {
      // 交互色
      show: false
    },
    boundaryGap: [0, 0.1] // 軸値の下限上限範囲
  };

  const EmphasisAndSelect = {
    emphasis: {
      itemStyle: {
        borderColor: prm.lineColor,
        borderWidth: 8
      }
    },
    select: {
      itemStyle: {
        borderColor: prm.lineColor,
        borderWidth: 5
      }
    }
  };

  return {
    color: [
      '#00BFFF',
      '#FF69B4',
      '#9ACD32',
      '#48D1CC',
      '#D8BFD8',
      '#FF6347',
      '#00FA9A',
      '#FFC300',
      '#9932CC',
      '#FFA07A',
      '#ADD8E6',
      '#ADFF2F',
      '#FFDAB9',
      '#1E90FF',
      '#FF8C00',
      '#E6E6FA',
      '#FFC0CB',
      '#FFD700',
      '#BA55D3',
      '#FFA500',
      '#6A5ACD',
      '#D3D3D3',
      '#CD5C5C',
      '#FF1493',
      '#FFB6C1'
    ],
    // label: {
    //   fontSize: 14,
    // },
    backgroundColor: 'transparent',
    textStyle: {
      fontFamily: getFontFamily()
    },
    title: {
      textStyle: {
        fontSize: 16,
        fontWeight: 'normal',
        color: prm.fontColor
      },
      subtextStyle: {
        color: prm.fontSubColor
      }
    },
    line: {
      ...EmphasisAndSelect,
      label: {
        color: prm.fontSubColor
      }
    },
    // radar: {
    //   itemStyle: {
    //     borderWidth: 1,
    //   },
    //   lineStyle: {
    //     width: 2,
    //   },
    //   symbolSize: 3,
    //   symbol: "emptyCircle",
    //   smooth: true,
    // },
    bar: {
      ...EmphasisAndSelect,
      label: {
        color: prm.fontLabelColor
      }
    },
    pie: {
      ...EmphasisAndSelect
    },
    scatter: {
      symbolSize: 10,
      label: {
        color: prm.fontColor,
        fontWeight: 'bold'
      },
      itemStyle: {
        color: prm.fontColor
      }
    },
    scatter3D: {
      symbolSize: 10,
      label: {
        color: prm.fontColor,
        fontWeight: 'bold'
      },
      itemStyle: {
        color: prm.fontColor
      }
    },
    boxplot: {
      ...EmphasisAndSelect,
      itemStyle: {
        color: 'transparent'
      }
    },
    // parallel: {
    //   itemStyle: {
    //     borderWidth: 0,
    //     borderColor: "#ccc",
    //   },
    // },
    // sankey: {
    //   itemStyle: {
    //     borderWidth: 0,
    //     borderColor: "#ccc",
    //   },
    // },
    // funnel: {
    //   itemStyle: {
    //     borderWidth: 0,
    //     borderColor: "#ccc",
    //   },
    // },
    // gauge: {
    //   itemStyle: {
    //     borderWidth: 0,
    //     borderColor: "#ccc",
    //   },
    // },
    // candlestick: {
    //   itemStyle: {
    //     color: "#d87a80",
    //     color0: "#2ec7c9",
    //     borderColor: "#d87a80",
    //     borderColor0: "#2ec7c9",
    //     borderWidth: 1,
    //   },
    // },
    // graph: {
    //   itemStyle: {
    //     borderWidth: 0,
    //     borderColor: "#ccc",
    //   },
    //   lineStyle: {
    //     width: 1,
    //     color: "#aaaaaa",
    //   },
    //   symbolSize: 3,
    //   symbol: "emptyCircle",
    //   smooth: true,
    //   color: [
    //     "#2ec7c9",
    //     "#b6a2de",
    //     "#5ab1ef",
    //     "#ffb980",
    //     "#d87a80",
    //     "#8d98b3",
    //     "#e5cf0d",
    //     "#97b552",
    //     "#95706d",
    //     "#dc69aa",
    //     "#07a2a4",
    //     "#9a7fd1",
    //     "#588dd5",
    //     "#f5994e",
    //     "#c05050",
    //     "#59678c",
    //     "#c9ab00",
    //     "#7eb00a",
    //     "#6f5553",
    //     "#c14089",
    //   ],
    //   label: {
    //     color: "#eeeeee",
    //   },
    // },
    heatmap: {
      itemStyle: {
        borderColor: prm.lineColor
      },
      ...EmphasisAndSelect
    },
    // map: {
    //   itemStyle: {
    //     areaColor: "#dddddd",
    //     borderColor: "#eeeeee",
    //     borderWidth: 0.5,
    //   },
    //   label: {
    //     color: "#d87a80",
    //   },
    //   emphasis: {
    //     itemStyle: {
    //       areaColor: "rgba(254,153,78,1)",
    //       borderColor: "#444",
    //       borderWidth: 1,
    //     },
    //     label: {
    //       color: "rgb(100,0,0)",
    //     },
    //   },
    // },
    // geo: {
    //   itemStyle: {
    //     areaColor: "#dddddd",
    //     borderColor: "#eeeeee",
    //     borderWidth: 0.5,
    //   },
    //   label: {
    //     color: "#d87a80",
    //   },
    //   emphasis: {
    //     itemStyle: {
    //       areaColor: "rgba(254,153,78,1)",
    //       borderColor: "#444",
    //       borderWidth: 1,
    //     },
    //     label: {
    //       color: "rgb(100,0,0)",
    //     },
    //   },
    // },
    // 軸
    categoryAxis: AXIS,
    valueAxis: AXIS,
    timeAxis: AXIS,
    logAxis: AXIS,
    categoryAxis3D: AXIS,
    valueAxis3D: AXIS,
    timeAxis3D: AXIS,
    logAxis3D: AXIS,
    toolbox: {
      iconStyle: {
        borderColor: prm.iconColor
      },
      emphasis: {
        iconStyle: {
          borderColor: prm.fontSubColor
        }
      }
    },
    legend: {
      textStyle: {
        fontSize: 14,
        color: prm.fontSubColor
      },
      pageIconColor: prm.iconColor,
      pageIconInactiveColor: prm.splitColor,
      pageTextStyle: {
        color: prm.fontSubColor
      }
    },
    tooltip: {
      axisPointer: {
        type: 'cross'
      }
      // axisPointer: {
      //   lineStyle: {
      //     color: "#008acd",
      //     width: "1",
      //   },
      //   crossStyle: {
      //     color: "#008acd",
      //     width: "1",
      //   },
      // },
    },
    // timeline: {
    //   lineStyle: {
    //     color: "#008acd",
    //     width: 1,
    //   },
    //   itemStyle: {
    //     color: "#008acd",
    //     borderWidth: 1,
    //   },
    //   controlStyle: {
    //     color: "#008acd",
    //     borderColor: "#008acd",
    //     borderWidth: 0.5,
    //   },
    //   checkpointStyle: {
    //     color: "#2ec7c9",
    //     borderColor: "#2ec7c9",
    //   },
    //   label: {
    //     color: "#008acd",
    //   },
    //   emphasis: {
    //     itemStyle: {
    //       color: "#a9334c",
    //     },
    //     controlStyle: {
    //       color: "#008acd",
    //       borderColor: "#008acd",
    //       borderWidth: 0.5,
    //     },
    //     label: {
    //       color: "#008acd",
    //     },
    //   },
    // },
    visualMap: {
      textStyle: {color: prm.fontSubColor}
    },
    dataZoom: {
      backgroundColor: 'transparent',
      dataBackgroundColor: prm.lineColor,
      fillerColor: 'rgba(158, 158, 158,0.2)',
      handleColor: prm.fontColor,
      handleSize: '100%',
      textStyle: {
        color: prm.fontSubColor
      }
    },
    markPoint: {
      label: {
        show: true,
        color: prm.bgColor
      },
      itemStyle: {
        color: prm.secondaryColor
      }
    },
    // markLine: {
    //   label: {
    //     color: prm.fontSubColor,
    //   },
    //   emphasis: {
    //     label: {
    //       color: color.secondaryColor,
    //       opacity: 0.25,
    //     },
    //   },
    // },
    markArea: {
      silent: true,
      label: {
        color: prm.fontSubColor
      },
      itemStyle: {
        color: prm.fontSubColor,
        opacity: 0.25
      }
    }
  };
};

/**
 * 定義登録
 */
(() => {
  ['light', 'dark'].forEach((t: any) => {
    let comColors = getColors(t);
    echarts.registerTheme(
      t,
      getEchartsThemeBase({
        bgColor: comColors.backgroundColor.default,
        fontColor: comColors.textColor.primary,
        fontSubColor: comColors.textColor.secondary,
        fontLabelColor: comColors.chartsLabelColor,
        iconColor: comColors.textColor.hint,
        lineColor: comColors.chartsLineColor,
        splitColor: comColors.chartsSplitColor,
        primaryColor: comColors.primaryColor,
        secondaryColor: comColors.secondaryColor
      })
    );
  });
  // // light
  // let comColors = getColors("light");
  // echarts.registerTheme(
  //   "light",
  //   getEchartsThemeBase({
  //     bgColor: "transparent",
  //     fontColor: comColors.textColor.primary,
  //     fontSubColor: comColors.textColor.secondary,
  //     iconColor: comColors.textColor.hint,
  //     lineColor: comColors.chartsLineColor,
  //     splitColor: comColors.chartsSplitColor,
  //     primaryColor: comColors.primaryColor,
  //     secondaryColor: comColors.secondaryColor,
  //   })
  // );

  // // dark
  // comColors = getColors("dark");
  // echarts.registerTheme(
  //   "dark",
  //   getEchartsThemeBase({
  //     bgColor: "transparent",
  //     fontColor: comColors.textColor.primary,
  //     fontSubColor: comColors.textColor.secondary,
  //     iconColor: comColors.textColor.hint,
  //     lineColor: comColors.chartsLineColor,
  //     splitColor: comColors.chartsSplitColor,
  //     primaryColor: comColors.primaryColor,
  //     secondaryColor: comColors.secondaryColor,
  //   })
  // );
})();
