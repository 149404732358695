import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import dayjs from 'dayjs';

/**
 * 引数
 */
interface Props {
  title: string;
  datas: any;
  datas2?: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  style?: any;
  height?: string;
  x_middle?: number;
  y_middle?: number;
  top?: string;
  left?: string;
  xAxisName: string;
  yAxisName: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomStatus?: boolean;
}

/**
 * LFCChartsScatter
 * @param props
 * @returns
 */
const LFCChartsScatterDemo = (props: Props) => {
  return (
    <LFCBaseCharts
      exportData={props.exportData}
      exportFields={props.exportFields}
      option={{
        title: {
          text: props.title
        },
        legend: {
          show: true,
          bottom: '0px',
          type: 'scroll'
        },
        grid: [
          {
            top: '15%',
            left: '50',
            right: '5%',
            bottom: '20%'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        xAxis: {
          type: 'value',
          name: props.xAxisName,
          nameLocation: 'middle',
          nameRotate: 0,
          nameTextStyle: {
            padding: [20, 0, 0, 0],
            fontSize: 16
          },
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 16
          }
        },
        yAxis: {
          type: 'value',
          name: props.yAxisName,
          nameLocation: 'middle',
          nameRotate: 90,
          nameTextStyle: {
            padding: [0, 0, 20, 0],
            fontSize: 16
          },
          splitLine: {
            show: true
          },
          axisLabel: {
            fontSize: 16
          }
        },
        series: props.datas?.map((data: any) => {
          return {
            name: dayjs(data[0].log_timestamp).format('HH:mm:ss'),
            type: 'effectScatter',
            colorBy: 'series',
            rippleEffect: {
              number: 0,
              scale: 0,
              period: 0
            },
            data: data.map((e: any) => [e.current, e.voltage]),
            symbolSize: 15
          };
        })
      }}
      exportFilename={props.exportFilename}
      height={props.height}
      style={props.style}
      zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
      zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
      zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
      zoomStatus={props.zoomStatus ? props.zoomStatus : undefined}
    />
  );
};
export default LFCChartsScatterDemo;
