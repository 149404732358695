import {ReactComponent as LFCEasyNaviIcon} from '_assets/images/easy-navi.svg';
import {saveAsCaptureImage} from '_components/common/ScreenCapture';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCButton from '_components/inputs/LFCButton';
import {useAuthUser, useLogout} from '_contexts/AuthUserProvider';
import {MenuTabContext} from '_contexts/MenuTabProvider';
import {useMySettings} from '_contexts/MySettingsProvider';
import {getLFCData} from '_logics/LFCUtil';
import LFCMySettingPage from '_templates/pages/LFCMySettingPage';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useHistory, useLocation} from 'react-router-dom';

import {Apps, CameraAlt, Close, Notifications, Person, Settings} from '@mui/icons-material';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import {
  AppBar,
  Badge,
  Box,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Tab,
  Tabs,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import {makeStyles} from '@mui/styles';

import LFCMenu from '../components/LFCMenuContents';
import LFCMenuTab from '../components/LFCMenuTab';
import LFCEasyNavi from './LFCEasyNavi';

/**
 * Style
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100vh'
  },
  title: {
    flexGrow: 1
  },
  digTitle: {
    padding: 0,
    margin: 0
  },
  digContent: {
    // backgroundImage: `url(${BgImg})`,
    // backgroundRepeat: "round",
    height: '75vh'
  },
  main_content: {
    width: '100%'
  },
  main_hide_tool: theme.mixins.toolbar,
  main_container: {
    marginTop: '5px',
    padding: theme.spacing(2)
  },
  menuImage: {
    fill: theme.palette.primary.main
  }
}));

/**
 * MENUの展開アニメーション
 */
const Transition = React.forwardRef((props: any, ref: any) => {
  return <Slide direction="down" ref={ref} {...props} />;
});

/**
 * TabにユニークIDを振る
 * @param index
 * @returns
 */
const a11yProps = (index: any) => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`
  };
};

export interface Props {
  title: string;
  children: React.ReactNode;
}

/**
 * LFC 標準サイト
 * @param props
 * @returns
 */
const LFCMainPage = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();
  const logout = useLogout();
  const [open, setOpen] = useState(false);
  const [openMySetting, setOpenMySetting] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const authUser = useAuthUser();
  const theme = useTheme();

  const [easyNaviOpen, setEasyNaviOpen] = useState(false);

  const MenuTabContextData = useContext(MenuTabContext);

  const [menu, setMenu] = useState(
    MenuTabContextData.menuData.filter((item: any) => item.path === location.pathname)[0]
  );
  const [tabV, setTabV] = useState<string>('L-QUALIFY');

  const [myMenuUpdate, setMyMenuUpdate] = useState<boolean>(false);

  const [startRender, setStartRender] = useState(false);

  // CancelTokenをページに１つ
  const [source] = useState(axios.CancelToken.source());

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  useEffect(() => {
    if (MenuTabContextData.menuData.length > 0) {
      if (
        location.pathname !== '/' &&
        location.pathname !== '/aichat' &&
        MenuTabContextData.menuData.filter((item: any) => item['path'] === location.pathname)
          .length === 0
      ) {
        // ページを参照する権限なしの場合
        console.error(`表示権限のないページに移動しようとしたため、Topページに戻しました`);
        history.replace('/');
      } else {
        setStartRender(true);
        // history.replace(location.pathname); //２回遷移されてしまうので消した
      }
    }
    setMenu(MenuTabContextData.menuData.filter((item: any) => item.path === location.pathname)[0]);

    if (!myMenuUpdate) {
      if (MenuTabContextData.tabData[0] !== undefined) {
        setTabV(menu !== undefined ? menu['tab_name'] : MenuTabContextData.tabData[0]['tab_name']);
      }
    } else {
      setMyMenuUpdate(!myMenuUpdate);
    }
  }, [MenuTabContextData]);

  /**
   * MENU選択
   * @param isOpen
   */
  const menuClick = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  /**
   * Tab選択
   * @param event
   * @param newValue
   */
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setTabV(newValue);
  };

  /**
   * ログアウト処理
   * @returns
   */
  const signOut = () => {
    logout();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * 個人設定Openイベント
   * @param event
   */
  const openAcMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * 個人設定Closeイベント
   */
  const closeAcMenu = () => {
    setAnchorEl(null);
  };

  if (startRender) {
    return (
      <Box className={classes.root}>
        {/*    <AppBar position="fixed" style={{backgroundColor: theme.palette.background.default}}>*/}
        <AppBar position="fixed">
          <Toolbar>
            <Tooltip title={t('メニュー')}>
              <IconButton edge="start" onClick={() => menuClick(true)} color="default" sx={{mr: 1}}>
                <Apps />
              </IconButton>
            </Tooltip>
            <Typography
              component="div"
              variant="h6"
              noWrap
              className={classes.title}
              color="text.secondary"
            >
              {props.title}
            </Typography>

            <Box mr={2}>
              {process.env.REACT_APP_ENV !== 'production' ? (
                <Typography
                  component="div"
                  variant="h6"
                  noWrap
                  className={classes.title}
                  color={theme.palette.background.default}
                  sx={{backgroundColor: theme.palette.secondary.main}}
                >
                  {`　${process.env.REACT_APP_ENV}-${mySettings.region}　`}
                </Typography>
              ) : (
                <></>
              )}
            </Box>

            <Box sx={{display: 'flex'}}>
              {authUser?.companyId === 1 ? (
                <Tooltip title={'AI Chat'}>
                  <Link to="/aichat">
                    <IconButton>
                      <QuestionAnswerIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
              ) : (
                <></>
              )}
              {mySettings.locale === 'ja' ? (
                <Tooltip title={t('簡単ナビ')}>
                  <IconButton onClick={() => setEasyNaviOpen(true)}>
                    <LFCEasyNaviIcon fill={theme.palette.text.primary} width="1em" height="1em" />
                  </IconButton>
                </Tooltip>
              ) : (
                <></>
              )}
              <Tooltip title={t('お知らせ')}>
                <IconButton href="https://linkwiz.gitbook.io/lfc/" target="_blank" rel="noopener">
                  <Notifications />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('キャプチャ保存')}>
                <IconButton onClick={() => saveAsCaptureImage('bodyRoot', props.title)}>
                  <CameraAlt />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('アカウント')}>
                <IconButton onClick={openAcMenu} style={{margin: 0}}>
                  <Person />
                </IconButton>
              </Tooltip>
            </Box>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={closeAcMenu}
              variant="menu"
            >
              <MenuItem>
                <Card style={{width: '100%'}}>
                  <CardContent>
                    <Box display="flex" justifyContent="center" mb={2}>
                      <Person />
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Typography variant="subtitle2">{mySettings.companyName}</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center">
                      <Typography variant="subtitle1">{mySettings.userName}</Typography>
                    </Box>
                  </CardContent>
                </Card>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  closeAcMenu();
                  setOpenMySetting(true);
                }}
              >
                <IconButton>
                  <Settings />
                </IconButton>
                {t('個人設定')}
              </MenuItem>
              <MenuItem onClick={signOut}>
                <IconButton>
                  <Close />
                </IconButton>
                {t('ログアウト')}
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <main id="mainContent" className={classes.main_content}>
          <Box className={classes.main_hide_tool} />
          <Box className={classes.main_container}>{props.children}</Box>
        </main>
        {/* メニューダイアログ */}
        <Dialog
          fullWidth
          maxWidth={'xl'}
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => menuClick(false)}
        >
          <DialogTitle className={classes.digTitle}>
            <AppBar position="static">
              <Tabs
                value={tabV}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {MenuTabContextData.tabData.map((rmt: any, index: number) => {
                  return (
                    <Tab
                      value={rmt['tab_name']}
                      label={t(`menu.tab_name.${rmt['tab_name']}`)}
                      wrapped
                      {...a11yProps(rmt['tab_name'])}
                      key={index}
                    />
                  );
                })}
              </Tabs>
            </AppBar>
          </DialogTitle>
          <DialogContent dividers className={classes.digContent}>
            {MenuTabContextData.tabData.map((rmt: any, i: number) => {
              return (
                <LFCMenuTab value={tabV} index={rmt['tab_name']} key={i}>
                  <Grid container spacing={1}>
                    {MenuTabContextData.menuData
                      .filter((v: any) => rmt['tab_name'] === v['tab_name'])
                      .map((rmd: any, j: number) => {
                        return (
                          <LFCMenu path={rmd.path} name={rmd.name} exText={rmd.ex_text} key={j} />
                        );
                      })}
                  </Grid>
                </LFCMenuTab>
              );
            })}
          </DialogContent>
          <DialogActions>
            <LFCButton onClick={() => menuClick(false)}>Close</LFCButton>
          </DialogActions>
        </Dialog>
        {/* 個人設定ダイアログ */}
        <Dialog
          open={openMySetting}
          onClose={() => setOpenMySetting(false)}
          maxWidth={false}
          TransitionComponent={Transition}
        >
          <LFCDialogTitle onClose={() => setOpenMySetting(false)}>{t('個人設定')}</LFCDialogTitle>
          <DialogContent dividers>
            <LFCMySettingPage />
          </DialogContent>
        </Dialog>
        {/* 簡単ナビダイアログ */}
        <Dialog
          open={easyNaviOpen}
          onClose={() => setEasyNaviOpen(false)}
          fullWidth
          maxWidth={'xl'}
          TransitionComponent={Transition}
          keepMounted
        >
          <DialogContent dividers>
            <LFCEasyNavi onClose={() => setEasyNaviOpen(false)} />
          </DialogContent>
          <DialogActions>
            <LFCButton onClick={() => setEasyNaviOpen(false)}>{t('閉じる')}</LFCButton>
          </DialogActions>
        </Dialog>
      </Box>
    );
  } else {
    return <></>;
  }
};

export default LFCMainPage;
