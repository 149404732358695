import {Grid} from '@mui/material';
import {useState} from 'react';
import LFCChartsLineLRobotCompare from '../charts/LFCChartsLineLrobotCompare';
import LFCChartsScatter from '../charts/LFCChartsScatter';
import LFCDataGridSimple from '../datagrid/LFCDataGridSimple';
import {GridColDef} from '@mui/x-data-grid-pro';
import LFCChartsLineGap from '_components/charts/LFCChartsLineGap';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  show: string;
  map: string;
  xyz?: any;
  gap?: any;
}

const LRobotXYZGraph = (props: Props) => {
  const {t} = useTranslation();
  let xy_data: any;
  let xz_data: any;

  if (props.show === 'Fitting-a' || props.show === 'Fitting-b') {
    if (props.xyz !== undefined && props.xyz.length > 0) {
      xy_data = props.xyz.map((item: any) => [
        item.difference_x,
        item.difference_y,
        item['lq_time']
      ]);
      xz_data = props.xyz.map((item: any) => [
        item.difference_x,
        item.difference_z,
        item['lq_time']
      ]);
    }
  }

  if (props.show === 'PathSend-a' || props.show === 'PathSend-b') {
    if (props.xyz !== undefined && props.xyz.length > 0) {
      xy_data = props.xyz.map((item: any) => [item.node_x, item.node_y, item['lq_time']]);
      xz_data = props.xyz.map((item: any) => [item.node_x, item.node_z, item['lq_time']]);
    }
  }

  //中央値
  const median = (datas: any) => {
    if (datas.length === 0) {
      return 0;
    } else {
      datas.sort((a: number, b: number) => {
        return a - b;
      });
      const half = Math.floor(datas.length / 2);
      if (datas.length % 2) {
        return datas[half].toFixed(2);
      } else {
        return ((datas[half - 1] + datas[half]) / 2).toFixed(3);
      }
    }
  };

  //標準偏差
  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Number(Math.sqrt(Var));
    } else {
      return 0;
    }
  };

  //工程能力
  const processCapability = (a: number, b: number) => {
    let tmp_a: string = !isNaN(Number(a) - 3 * Number(b) * 1.33)
      ? (Number(a) - 3 * Number(b) * 1.33).toFixed(4)
      : '0';
    let tmp_b: string = !isNaN(Number(a) + 3 * Number(b) * 1.33)
      ? (Number(a) + 3 * Number(b) * 1.33).toFixed(4)
      : '0';
    return [tmp_a, tmp_b];
  };

  let xyz_columns = {
    lq_time: 'lq_time',
    map: 'map',
    difference_x: 'difference_x',
    difference_y: 'difference_y',
    difference_z: 'difference_z'
  };

  let PathSend_column = {
    map: 'map',
    lq_time: 'lq_time',
    branch_no: 'branch_no',
    path_address: 'path_address',
    node_x: 'node_x',
    node_y: 'node_y',
    node_z: 'node_z'
  };

  let x_max: any;
  let y_max: any;
  let z_max: any;
  let x_min: any;
  let y_min: any;
  let z_min: any;

  let x_avg: any;
  let y_avg: any;
  let z_avg: any;

  let x_stdev: any;
  let y_stdev: any;
  let z_stdev: any;

  let x_processCapability: any = [];
  let y_processCapability: any = [];
  let z_processCapability: any = [];

  let graphMiddle: any = {
    x: 0,
    y: 0,
    z: 0
  };

  if (props.xyz !== undefined) {
    let targetItem: any = {
      item_x: undefined,
      item_y: undefined,
      item_z: undefined
    };

    if (props.show === 'PathSend-a' || props.show === 'PathSend-b') {
      targetItem.item_x = 'node_x';
      targetItem.item_y = 'node_y';
      targetItem.item_z = 'node_z';
    } else {
      targetItem.item_x = 'difference_x';
      targetItem.item_y = 'difference_y';
      targetItem.item_z = 'difference_z';
    }

    //中間値
    graphMiddle.x = median(props.xyz.map((item: any) => Number(item[targetItem.item_x])));
    graphMiddle.y = median(props.xyz.map((item: any) => Number(item[targetItem.item_y])));
    graphMiddle.z = median(props.xyz.map((item: any) => Number(item[targetItem.item_z])));

    //標準偏差
    x_stdev = stdev(props.xyz.map((item: any) => Number(item[targetItem.item_x])));
    y_stdev = stdev(props.xyz.map((item: any) => Number(item[targetItem.item_y])));
    z_stdev = stdev(props.xyz.map((item: any) => Number(item[targetItem.item_z])));

    //平均値
    x_avg =
      props.xyz
        .map((item: any) => Number(item[targetItem.item_x]))
        .reduce((a: number, b: number) => a + b, 0) /
      props.xyz.map((item: any) => Number(item[targetItem.item_x])).length;
    y_avg =
      props.xyz
        .map((item: any) => Number(item[targetItem.item_y]))
        .reduce((a: number, b: number) => a + b, 0) /
      props.xyz.map((item: any) => Number(item[targetItem.item_y])).length;
    z_avg =
      props.xyz
        .map((item: any) => Number(item[targetItem.item_z]))
        .reduce((a: number, b: number) => a + b, 0) /
      props.xyz.map((item: any) => Number(item[targetItem.item_z])).length;

    //最大値
    x_max = Math.max.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_x]))
    );
    y_max = Math.max.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_y]))
    );
    z_max = Math.max.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_z]))
    );

    //最小値
    x_min = Math.min.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_x]))
    );
    y_min = Math.min.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_y]))
    );
    z_min = Math.min.apply(
      null,
      props.xyz.map((item: any) => Number(item[targetItem.item_z]))
    );

    //工程能力
    x_processCapability = processCapability(x_avg, x_stdev);
    y_processCapability = processCapability(y_avg, y_stdev);
    z_processCapability = processCapability(z_avg, z_stdev);
  }

  const show_list: any = [
    {
      id: 0,
      軸: 'X',
      最小: x_min !== Infinity ? x_min : 0,
      平均: !isNaN(x_avg) ? x_avg.toFixed(4) : 0,
      最大: x_max !== -Infinity ? x_max : 0,
      標準偏差: !isNaN(x_stdev) ? x_stdev.toFixed(4) : 0,
      推奨下限値: x_processCapability[0],
      推奨上限値: x_processCapability[1]
    },
    {
      id: 1,
      軸: 'Y',
      最小: y_min !== Infinity ? y_min : 0,
      平均: !isNaN(y_avg) ? y_avg.toFixed(4) : 0,
      最大: y_max !== -Infinity ? y_max : 0,
      標準偏差: !isNaN(y_stdev) ? y_stdev.toFixed(4) : 0,
      推奨下限値: y_processCapability[0],
      推奨上限値: y_processCapability[1]
    },
    {
      id: 2,
      軸: 'Z',
      最小: z_min !== Infinity ? z_min : 0,
      平均: !isNaN(z_avg) ? z_avg.toFixed(4) : 0,
      最大: z_max !== -Infinity ? z_max : 0,
      標準偏差: !isNaN(z_stdev) ? z_stdev.toFixed(4) : 0,
      推奨下限値: z_processCapability[0],
      推奨上限値: z_processCapability[1]
    }
  ];

  const [dsColumns, setDsColumns] = useState<GridColDef[]>([
    {
      field: '軸',
      headerName: `${t('軸')}`,
      description: `${t('軸')}`,
      width: 50,
      align: 'center',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '最小',
      headerName: `${t('最小')}`,
      description: `${t('最小')}`,
      width: 93,
      align: 'right',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '平均',
      headerName: `${t('平均')}`,
      description: `${t('平均')}`,
      width: 93,
      align: 'right',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '最大',
      headerName: `${t('最大')}`,
      description: `${t('最大')}`,
      width: 93,
      align: 'right',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '標準偏差',
      headerName: `${t('標準偏差')}`,
      description: `${t('標準偏差')}`,
      width: 93,
      align: 'right',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '推奨下限値',
      headerName: `${t('推奨下限値')}`,
      description: `${t('工程能力Cp=1.33許容範囲')}`,
      width: 100,
      align: 'right',
      headerAlign: 'center',
      hide: false
    },
    {
      field: '推奨上限値',
      headerName: `${t('推奨上限値')}`,
      description: `${t('工程能力Cp=1.33許容範囲')}`,
      width: 100,
      align: 'right',
      headerAlign: 'center',
      hide: false
    }
  ]);

  const SHOW_LIST_COLUMN_ORDER = [
    {name: t('軸'), order: 1},
    {name: t('最小'), order: 2},
    {name: t('平均'), order: 3},
    {name: t('最大'), order: 4},
    {name: t('標準偏差'), order: 5},
    {name: t('推奨下限値'), order: 6},
    {name: t('推奨上限値'), order: 6}
  ];

  let gap_column: any = {
    uuid: 'uuid',
    lq_time: 'lq_time',
    gap: 'gap',
    map: 'map',
    job_type: 'job_type',
    branch_no: 'branch_no',
    analysis_count: 'analysis_count',
    gap_limit_position_1: 'gap_limit_position_1',
    gap_limit_position_2: 'gap_limit_position_2',
    gap_limit_position_3: 'gap_limit_position_3',
    gap_limit_rotation_1: 'gap_limit_rotation_1',
    gap_limit_rotation_2: 'gap_limit_rotation_2',
    gap_limit_rotation_3: 'gap_limit_rotation_3',
    ng_profiles: 'ng_profiles',
    ng_type: 'ng_type',
    ok_profiles: 'ok_profiles',
    path_address: 'path_address',
    tack_profiles: 'tack_profiles'
  };

  switch (props.show) {
    case 'Fitting-a':
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Y"
              datas={xy_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.y}
              style={{width: '100%', height: 200}}
              height={'200px'}
              exportData={props.xyz}
              exportFields={xyz_columns}
              exportFilename={`${t('比較詳細')}_XYZ`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Z"
              datas={xz_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.z}
              style={{width: '100%', height: 200}}
              height={'200px'}
              exportData={props.xyz}
              exportFields={xyz_columns}
              exportFilename={`${t('比較詳細')}_XYZ`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineLRobotCompare
              title={''}
              source={props.xyz}
              series={props.map === 'None' ? '' : props.map}
              color={['rgb(106,113,255)']}
              exportData={props.xyz}
              exportFields={xyz_columns}
              exportFilename={'Fitting-a'}
              typeName={'Fitting-a'}
            />
          </Grid>
        </Grid>
      );
    case 'Fitting-b':
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Y"
              datas={xy_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.y}
              style={{width: '100%', height: 200}}
              height={'200px'}
              exportData={props.xyz}
              exportFields={xyz_columns}
              exportFilename={`${t('比較詳細')}_XYZ`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Z"
              datas={xz_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.z}
              style={{width: '100%', height: 200}}
              height={'200px'}
              exportData={props.xyz}
              exportFields={xyz_columns}
              exportFilename={`${t('比較詳細')}_XYZ`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineLRobotCompare
              title={''}
              source={props.xyz}
              series={props.map === 'None' ? '' : props.map}
              color={['rgb(106,113,255)']}
              exportData={props.xyz}
              exportFields={[]}
              exportFilename={'Fitting-b'}
              typeName={'Fitting-b'}
            />
          </Grid>
        </Grid>
      );
    case 'Gap-a':
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineGap
              title=""
              x={{dsColumn: 'lq_time'}}
              series={[
                {
                  name: 'gap',
                  dsColumn: 'gap'
                },
                {
                  name: 'gap_limit_position_1',
                  dsColumn: 'gap_limit_position_1'
                },
                {
                  name: 'gap_limit_position_2',
                  dsColumn: 'gap_limit_position_2'
                },
                {
                  name: 'gap_limit_position_3',
                  dsColumn: 'gap_limit_position_3'
                }
              ]}
              source={props.gap}
              height="60vh"
              exportData={props.gap}
              exportFields={gap_column}
              exportFilename={'Gap-a'}
            />
          </Grid>
        </Grid>
      );
    case 'Gap-b':
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineGap
              title=""
              x={{dsColumn: 'lq_time'}}
              series={[
                {
                  name: 'gap',
                  dsColumn: 'gap'
                },
                {
                  name: 'gap_limit_position_1',
                  dsColumn: 'gap_limit_position_1'
                },
                {
                  name: 'gap_limit_position_2',
                  dsColumn: 'gap_limit_position_2'
                },
                {
                  name: 'gap_limit_position_3',
                  dsColumn: 'gap_limit_position_3'
                }
              ]}
              source={props.gap}
              height="60vh"
              exportData={props.gap}
              exportFields={gap_column}
              exportFilename={'Gap-b'}
            />
          </Grid>
        </Grid>
      );
    case 'PathSend-a':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            ---{t('始点')}---
            <LFCChartsScatter
              title="X-Y"
              datas={xy_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.y}
              height={'200px'}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XY_a`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Z"
              datas={xz_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.z}
              height={'200px'}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XZ_a`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineLRobotCompare
              title={''}
              source={props.xyz}
              series={props.map}
              color={['rgb(106,113,255)']}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XYZ_a`}
              typeName={'PathSend-a'}
              height={'250px'}
            />
            <LFCDataGridSimple
              columns={dsColumns}
              rows={show_list}
              rowHeight={40}
              height="18vh"
              columnOrder={SHOW_LIST_COLUMN_ORDER}
              exportFilename={`${t('比較詳細')}_XYZ_${t('数値')}_${t('始点')}`}
            />
          </Grid>
        </Grid>
      );
    case 'PathSend-b':
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            ---{t('終点')}---
            <LFCChartsScatter
              title="X-Y"
              datas={xy_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.y}
              height={'200px'}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XY_b`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsScatter
              title="X-Z"
              datas={xz_data}
              x_middle={graphMiddle.x}
              y_middle={graphMiddle.z}
              height={'200px'}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XZ_b`}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <LFCChartsLineLRobotCompare
              title={''}
              source={props.xyz}
              series={props.map}
              color={['rgb(106,113,255)']}
              exportData={props.xyz}
              exportFields={PathSend_column}
              exportFilename={`${t('比較詳細')}_XYZ_b`}
              typeName={'PathSend-b'}
              height={'250px'}
            />
            <LFCDataGridSimple
              columns={dsColumns}
              rows={show_list}
              rowHeight={40}
              height="18vh"
              columnOrder={SHOW_LIST_COLUMN_ORDER}
              exportFilename={`${t('比較詳細')}_XYZ_${t('数値')}_${t('終点')}`}
            />
          </Grid>
        </Grid>
      );
    default:
      return <></>;
  }
};
export default LRobotXYZGraph;
