import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import {Dispatch, useCallback, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {useMySettings} from '_contexts/MySettingsProvider';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {distinct, getLFCData} from '_logics/LFCUtil';
import dayjs from 'dayjs';
import {isArrayLikeObject, isEmpty} from 'lodash';
import LFCChartsLineHoleCompareCube from '_components/charts/LFCChartsLineHoleCompareCube';
import LFCChartsBarXGap from '_components/charts/LFCChartsBarXGap';
import LFCChartsBarYGap from '_components/charts/LFCChartsBarYGap';
import LFCChartsBarZGap from '_components/charts/LFCChartsBarZGap';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import ProgressBar from '_components/ProgressBar';
import LFCChartsBoxWithLine4 from '../../_components/charts/LFCChartsBoxWithLine4';
import LFCChartsLineMulti4 from '../../_components/charts/LFCChartsLineMulti4';
import LFCChartsLineMultiBySearch4 from '../../_components/charts/LFCChartsLineMultiBySearch4';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    typeTitle: {
      marginLeft: '-16px',
      fontWeight: 'bold',
      fontSize: '16px'
    },
    mrgLeft: {
      marginLeft: '8px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  open: boolean;
  searchValue: any;
  typeKey: any;
  graphData1: any;
  startProcess: boolean;
  setStartProcess: Dispatch<boolean>;
}

const PartsCubeComponent = (props: Props) => {
  const {startProcess, setStartProcess} = props;
  const {t} = useTranslation();
  const dataCubeY_SP_column: any = {
    y_gap: t('Y座標誤差'),
    カウント数: t('カウント数')
  };

  const exportData3_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('標準偏差'),
    d1: t('範囲（最大最小差）'),
    d2: 'Cp',
    d3: 'Cpk'
  };

  const dataCubeExport_column_z: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  const exportData3_column_cube_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    x_gap: t('X座標誤差移動範囲（前結果差）'),
    y_gap: t('Y座標誤差移動範囲（前結果差）'),
    z_gap: t('Z座標誤差移動範囲（前結果差）'),
    serial: t('シリアルナンバー')
  };

  const dataCubeExport_column_x_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    serial: t('シリアルナンバー')
  };

  const dataCubeExport_column_x: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  const dataCubeExport_column_y: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: 'Q3',
    d2: t('中央(orQ2)'),
    d3: 'Q1',
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  const dataCubeExport_column_y_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    serial: t('シリアルナンバー')
  };

  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const export_detail_all_list: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100,
      hide: false
    },
    {
      order: 1,
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: true,
      width: 140
    },
    {
      order: 2,
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: true
    },
    {
      order: 3,
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 180,
      sortable: true
    },
    {
      order: 10,
      field: 'master_x',
      headerName: t('マスタX座標'),
      description: t('マスタX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 11,
      field: 'master_y',
      headerName: t('マスタY座標'),
      description: t('マスタY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 12,
      field: 'master_z',
      headerName: t('マスタZ座標'),
      description: t('マスタZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 13,
      field: 'work_x',
      headerName: t('ワークX座標'),
      description: t('ワークX座標'),
      width: 140,
      sortable: true
    },
    {
      order: 14,
      field: 'work_y',
      headerName: t('ワークY座標'),
      description: t('ワークY座標'),
      width: 140,
      sortable: true
    },
    {
      order: 15,
      field: 'work_z',
      headerName: t('ワークZ座標'),
      description: t('ワークZ座標'),
      width: 140,
      sortable: true
    },
    {
      order: 16,
      field: 'x_gap',
      headerName: t('X座標誤差'),
      description: t('X座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 17,
      field: 'y_gap',
      headerName: t('Y座標誤差'),
      description: t('Y座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 18,
      field: 'z_gap',
      headerName: t('Z座標誤差'),
      description: t('Z座標誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 30,
      field: 'center_range_limit',
      headerName: t('球体半径誤差閾値'),
      description: t('球体半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 31,
      field: 'center_range_gap',
      headerName: t('球体半径誤差'),
      description: t('球体半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 50,
      field: 'x_lower_limit',
      headerName: t('X座標誤差下限閾値'),
      description: t('X座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 51,
      field: 'x_upper_limit',
      headerName: t('X座標誤差上限閾値'),
      description: t('X座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 52,
      field: 'y_lower_limit',
      headerName: t('Y座標誤差下限閾値'),
      description: t('Y座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 53,
      field: 'y_upper_limit',
      headerName: t('Y座標誤差上限閾値'),
      description: t('Y座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 54,
      field: 'z_lower_limit',
      headerName: t('Z座標誤差下限閾値'),
      description: t('Z座標誤差下限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 55,
      field: 'z_upper_limit',
      headerName: t('Z座標誤差上限閾値'),
      description: t('Z座標誤差上限閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 60,
      field: 'cylinder_radius_gap',
      headerName: t('円筒半径誤差'),
      description: t('円筒半径誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 61,
      field: 'cylinder_radius_limit',
      headerName: t('円筒半径誤差閾値'),
      description: t('円筒半径誤差閾値'),
      width: 140,
      sortable: true
    },
    {
      order: 62,
      field: 'height_gap',
      headerName: t('円筒高さ誤差'),
      description: t('円筒高さ誤差'),
      width: 140,
      sortable: true
    },
    {
      order: 63,
      field: 'height_upper_limit',
      headerName: t('円筒高さ誤差上限閾値'),
      description: t('円筒高さ誤差上限閾値'),
      width: 180,
      sortable: true
    },
    {
      order: 64,
      field: 'height_lower_limit',
      headerName: t('円筒高さ誤差下限閾値'),
      description: t('円筒高さ誤差下限閾値'),
      width: 180,
      sortable: true
    }
  ];

  const dataCubeX_SP_column: {[key: string]: string} = {
    x_gap: t('X座標誤差'),
    カウント数: t('カウント数')
  };

  //平均値の取得
  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  //標準偏差の取得
  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  //中央値の取得
  const Quartile = (data: any, q: number) => {
    let data_s: any = data.sort((a: number, b: number) => a - b);
    let pos = (data_s.length - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if (data_s[base + 1] !== undefined) {
      return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
    } else {
      return data_s[base];
    }
  };

  // 表示数字を桁区切り
  const toLocalStringEx = (params: number) => {
    return !isNaN(Number(params)) ? Number(params).toLocaleString() : '';
  };

  const data1MaxAvgMin_init: any = {
    data_x: {
      上限: 0,
      目標: 0,
      下限: 0,
      最大: 0,
      平均: 0,
      中央: 0,
      最小: 0,
      標準偏差: 0,
      '平均+3σ': 0,
      '平均-3σ': 0,
      Cp: 0,
      Cpk: 0
    },
    data_y: {
      上限: 0,
      目標: 0,
      下限: 0,
      最大: 0,
      平均: 0,
      中央: 0,
      最小: 0,
      標準偏差: 0,
      '平均+3σ': 0,
      '平均-3σ': 0,
      Cp: 0,
      Cpk: 0
    },
    data_z: {
      上限: 0,
      目標: 0,
      下限: 0,
      最大: 0,
      平均: 0,
      中央: 0,
      最小: 0,
      標準偏差: 0,
      '平均+3σ': 0,
      '平均-3σ': 0,
      Cp: 0,
      Cpk: 0
    }
  };

  //キューブの計算データ
  const [data1MaxAvgMin, setData1MaxAvgMin] = useState(data1MaxAvgMin_init);

  // キューブのグラフデータ
  // const [graphData1, setGraphData1] = useState([]);

  //キューブ
  const [dataCubeX, setDataCubeX] = useState([]); // X軸
  const [dataCubeY, setDataCubeY] = useState([]); // Y軸
  const [dataCubeZ, setDataCubeZ] = useState([]); // Z軸
  const [dataCubeXR, setDataCubeXR] = useState([]); // X軸・範囲R
  const [dataCubeYR, setDataCubeYR] = useState([]); // Y軸・範囲R
  const [dataCubeZR, setDataCubeZR] = useState([]); // Z軸・範囲R
  const [dataCubeX_show, setDataCubeX_show] = useState([]); // X軸
  const [dataCubeY_show, setDataCubeY_show] = useState([]); // Y軸
  const [dataCubeZ_show, setDataCubeZ_show] = useState([]); // Z軸
  const [dataCubeXR_show, setDataCubeXR_show] = useState([]); // X軸・範囲R
  const [dataCubeYR_show, setDataCubeYR_show] = useState([]); // Y軸・範囲R
  const [dataCubeZR_show, setDataCubeZR_show] = useState([]); // Z軸・範囲R
  const [dataCubeX_XYZ, setDataCubeX_XYZ] = useState<any>([]); // X軸
  const [dataCubeY_XYZ, setDataCubeY_XYZ] = useState<any>([]); // Y軸
  const [dataCubeZ_XYZ, setDataCubeZ_XYZ] = useState<any>([]); // R軸
  const [dataX_LineMin, setDataX_LineMin] = useState(0); // X軸
  const [dataX_LineMax, setDataX_LineMax] = useState(0); // X軸
  const [dataX_Renge, setDataX_Renge] = useState(0); // X軸
  const [dataY_LineMin, setDataY_LineMin] = useState(0); // Y軸
  const [dataY_LineMax, setDataY_LineMax] = useState(0); // Y軸
  const [dataY_Renge, setDataY_Renge] = useState(0); // Y軸
  const [dataZ_LineMin, setDataZ_LineMin] = useState(0); // Z軸
  const [dataZ_LineMax, setDataZ_LineMax] = useState(0); // Z軸
  const [dataZ_Renge, setDataZ_Renge] = useState(0); // Z軸
  const [dataCubeX_SP, setDataCubeX_SP] = useState([]);
  const [dataCubeY_SP, setDataCubeY_SP] = useState([]);
  const [dataCubeZ_SP, setDataCubeZ_SP] = useState([]);
  const [graphData1XSelectDate, setGraphData1XSelectDate] = useState('');
  const [graphData1YSelectDate, setGraphData1YSelectDate] = useState('');
  const [graphData1ZSelectDate, setGraphData1ZSelectDate] = useState('');
  const [dataCubeExport, setDataCubeExport] = useState<any>({
    data_x: [],
    data_y: [],
    data_z: []
  });

  //日付リストの生成
  const date_calc: any = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(props.searchValue.select_date_from);
    let to_day = new Date(props.searchValue.select_date_to);

    switch (props.searchValue.searchType) {
      case 0:
        return return_type0_byid();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
      default:
        return null;
    }
  }, [startProcess]);

  const return_type0_byid = () => {
    if (props.typeKey[0] === undefined) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 28100,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          map: !isEmpty(props.searchValue.map) ? '{' + props.searchValue.map.join(',') + '}' : null,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge
        },
        name: 'id-28100',
        cancelToken: source.token,
        t
      }).then((data: any) => {
        switch (data[0]['tolerance_type']) {
          case 0:
          case null:
            return Array.from(new Set(props.graphData1.map((a: any) => a.lq_time))).sort();
          case 1:
        }
        return [];
      });
    } else {
      switch (props.typeKey[0]['tolerance_type']) {
        case 0:
        case null:
          return Array.from(new Set(props.graphData1.map((a: any) => a.lq_time))).sort();
      }
      return [];
    }
  };

  //推移グラフ
  //キューブ
  const [graphBaseData1, setGraphBaseData1] = useState<any>({
    data_x: {},
    data_y: {},
    data_z: {}
  });

  let tmp_data_x: any = [];
  let tmp_data_y: any = [];
  let tmp_data_z: any = [];
  let tmp_x: any = [];
  let tmp_y: any = [];
  let tmp_z: any = [];

  const checkZero = (a: string | null) => {
    if (a !== null) {
      return Number(a);
    } else {
      return null;
    }
  };

  // ---------- キューブ：tolerance_type = 1 ------------------------------------
  // キューブ：ばらつき推移のデータを取得
  useEffect(() => {
    setStartProcess(true);
    if (props.typeKey.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          //x軸
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 280043,
            parameters: {
              select_date_from: props.searchValue.select_date_from,
              select_date_to: props.searchValue.select_date_to,
              work: !isEmpty(props.searchValue.work)
                ? '{' + props.searchValue.work.join(',') + '}'
                : null,
              deviceid: !isEmpty(props.searchValue.deviceid)
                ? '{' + props.searchValue.deviceid.join(',') + '}'
                : null,
              judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
              map: !isEmpty(props.searchValue.map)
                ? '{' + props.searchValue.map.join(',') + '}'
                : '{' +
                  props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
                  '}'
            },
            name: 'id-280043',
            cancelToken: source.token,
            t
          })
            .then((data: any) => {
              setDataCubeX(data);
              // X軸の範囲Rを全権取得のため
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 280046,
                parameters: {
                  select_date_from: props.searchValue.select_date_from,
                  select_date_to: props.searchValue.select_date_to,
                  work: !isEmpty(props.searchValue.work)
                    ? '{' + props.searchValue.work.join(',') + '}'
                    : null,
                  deviceid: !isEmpty(props.searchValue.deviceid)
                    ? '{' + props.searchValue.deviceid.join(',') + '}'
                    : null,
                  judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                  map: !isEmpty(props.searchValue.map)
                    ? '{' + props.searchValue.map.join(',') + '}'
                    : '{' +
                      props.typeKey
                        .filter((a: any) => a.tolerance_type === 1)[0]
                        .map_array.join(',') +
                      '}'
                },
                name: 'id-280046',
                cancelToken: source.token,
                t
              }).then((data: any) => {
                setDataCubeXR(data);
              });
            })
            .then(() =>
              //y軸
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 280044,
                parameters: {
                  select_date_from: props.searchValue.select_date_from,
                  select_date_to: props.searchValue.select_date_to,
                  work: !isEmpty(props.searchValue.work)
                    ? '{' + props.searchValue.work.join(',') + '}'
                    : null,
                  deviceid: !isEmpty(props.searchValue.deviceid)
                    ? '{' + props.searchValue.deviceid.join(',') + '}'
                    : null,
                  judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                  map: !isEmpty(props.searchValue.map)
                    ? '{' + props.searchValue.map.join(',') + '}'
                    : '{' +
                      props.typeKey
                        .filter((a: any) => a.tolerance_type === 1)[0]
                        .map_array.join(',') +
                      '}'
                },
                name: 'id-280044',
                cancelToken: source.token,
                t
              })
                .then((data: any) => {
                  setDataCubeY(data);
                  // Y軸の範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 280047,
                    parameters: {
                      select_date_from: props.searchValue.select_date_from,
                      select_date_to: props.searchValue.select_date_to,
                      work: !isEmpty(props.searchValue.work)
                        ? '{' + props.searchValue.work.join(',') + '}'
                        : null,
                      deviceid: !isEmpty(props.searchValue.deviceid)
                        ? '{' + props.searchValue.deviceid.join(',') + '}'
                        : null,
                      judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                      map: !isEmpty(props.searchValue.map)
                        ? '{' + props.searchValue.map.join(',') + '}'
                        : '{' +
                          props.typeKey
                            .filter((a: any) => a.tolerance_type === 1)[0]
                            .map_array.join(',') +
                          '}'
                    },
                    name: 'id-280047',
                    cancelToken: source.token,
                    t
                  }).then((data: any) => {
                    setDataCubeYR(data);
                  });
                })
                .then(() =>
                  //z軸
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 280045,
                    parameters: {
                      select_date_from: props.searchValue.select_date_from,
                      select_date_to: props.searchValue.select_date_to,
                      work: !isEmpty(props.searchValue.work)
                        ? '{' + props.searchValue.work.join(',') + '}'
                        : null,
                      deviceid: !isEmpty(props.searchValue.deviceid)
                        ? '{' + props.searchValue.deviceid.join(',') + '}'
                        : null,
                      judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                      map: !isEmpty(props.searchValue.map)
                        ? '{' + props.searchValue.map.join(',') + '}'
                        : '{' +
                          props.typeKey
                            .filter((a: any) => a.tolerance_type === 1)[0]
                            .map_array.join(',') +
                          '}'
                    },
                    name: 'id-280045',
                    cancelToken: source.token,
                    t
                  }).then((data: any) => {
                    setDataCubeZ(data);
                    // Y軸の範囲Rを全権取得のため
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 280048,
                      parameters: {
                        select_date_from: props.searchValue.select_date_from,
                        select_date_to: props.searchValue.select_date_to,
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-280048',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeZR(data);
                    });
                  })
                )
            );
          break;
        case 1: // 時間ごと
          if (props.graphData1.map((a: any) => a.year_month_day_hour).length > 0) {
            //x軸
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281015,
              parameters: {
                year_month_day_hour:
                  '{' +
                  Array.from(new Set(props.graphData1.map((a: any) => a.year_month_day_hour))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 1)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281015',
              cancelToken: source.token,
              t
            })
              .then((data: any) => {
                setDataCubeX(data);
                // X軸の範囲Rを全権取得のため
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 280040,
                  parameters: {
                    select_date_from: props.searchValue.select_date_from,
                    select_date_to: props.searchValue.select_date_to,
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-280040',
                  cancelToken: source.token,
                  t
                }).then((data: any) => {
                  setDataCubeXR(data);
                });
              })
              .then(() =>
                //y軸
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 281016,
                  parameters: {
                    year_month_day_hour:
                      '{' +
                      Array.from(
                        new Set(props.graphData1.map((a: any) => a.year_month_day_hour))
                      ).join(',') +
                      '}',
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-281016',
                  cancelToken: source.token,
                  t
                })
                  .then((data: any) => {
                    setDataCubeY(data);
                    // Y軸の範囲Rを全権取得のため
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 280041,
                      parameters: {
                        select_date_from: props.searchValue.select_date_from,
                        select_date_to: props.searchValue.select_date_to,
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-280041',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeYR(data);
                    });
                  })
                  .then(() =>
                    //z軸
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 281017,
                      parameters: {
                        year_month_day_hour:
                          '{' +
                          Array.from(
                            new Set(props.graphData1.map((a: any) => a.year_month_day_hour))
                          ).join(',') +
                          '}',
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-281017',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeZ(data);
                      // Y軸の範囲Rを全権取得のため
                      getLFCData({
                        snack: enqueueSnackbar,
                        sql_id: 280042,
                        parameters: {
                          select_date_from: props.searchValue.select_date_from,
                          select_date_to: props.searchValue.select_date_to,
                          work: !isEmpty(props.searchValue.work)
                            ? '{' + props.searchValue.work.join(',') + '}'
                            : null,
                          deviceid: !isEmpty(props.searchValue.deviceid)
                            ? '{' + props.searchValue.deviceid.join(',') + '}'
                            : null,
                          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                          map: !isEmpty(props.searchValue.map)
                            ? '{' + props.searchValue.map.join(',') + '}'
                            : '{' +
                              props.typeKey
                                .filter((a: any) => a.tolerance_type === 1)[0]
                                .map_array.join(',') +
                              '}'
                        },
                        name: 'id-280042',
                        cancelToken: source.token,
                        t
                      }).then((data: any) => {
                        setDataCubeZR(data);
                      });
                    })
                  )
              );
          }
          break;
        case 2: // 日ごと
          if (props.graphData1.map((a: any) => a.year_month_day).length > 0) {
            //x軸
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281010,
              parameters: {
                year_month_day:
                  '{' +
                  Array.from(new Set(props.graphData1.map((a: any) => a.year_month_day))).join(
                    ','
                  ) +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 1)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281010',
              cancelToken: source.token,
              t
            })
              .then((data: any) => {
                setDataCubeX(data);
                // X軸の範囲Rを全権取得のため
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 280034,
                  parameters: {
                    select_date_from: props.searchValue.select_date_from,
                    select_date_to: props.searchValue.select_date_to,
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-280034',
                  cancelToken: source.token,
                  t
                }).then((data: any) => {
                  setDataCubeXR(data);
                });
              })
              .then(() =>
                //y軸
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 281011,
                  parameters: {
                    year_month_day:
                      '{' +
                      Array.from(new Set(props.graphData1.map((a: any) => a.year_month_day))).join(
                        ','
                      ) +
                      '}',
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-281011',
                  cancelToken: source.token,
                  t
                })
                  .then((data: any) => {
                    setDataCubeY(data);
                    // Y軸の範囲Rを全権取得のため
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 280035,
                      parameters: {
                        select_date_from: props.searchValue.select_date_from,
                        select_date_to: props.searchValue.select_date_to,
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-280035',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeYR(data);
                    });
                  })
                  .then(() =>
                    //z軸
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 281012,
                      parameters: {
                        year_month_day:
                          '{' +
                          Array.from(
                            new Set(props.graphData1.map((a: any) => a.year_month_day))
                          ).join(',') +
                          '}',
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-281012',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeZ(data);
                      // Z軸の範囲Rを全権取得のため
                      getLFCData({
                        snack: enqueueSnackbar,
                        sql_id: 280036,
                        parameters: {
                          select_date_from: props.searchValue.select_date_from,
                          select_date_to: props.searchValue.select_date_to,
                          work: !isEmpty(props.searchValue.work)
                            ? '{' + props.searchValue.work.join(',') + '}'
                            : null,
                          deviceid: !isEmpty(props.searchValue.deviceid)
                            ? '{' + props.searchValue.deviceid.join(',') + '}'
                            : null,
                          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                          map: !isEmpty(props.searchValue.map)
                            ? '{' + props.searchValue.map.join(',') + '}'
                            : '{' +
                              props.typeKey
                                .filter((a: any) => a.tolerance_type === 1)[0]
                                .map_array.join(',') +
                              '}'
                        },
                        name: 'id-280036',
                        cancelToken: source.token,
                        t
                      }).then((data: any) => {
                        setDataCubeZR(data);
                      });
                    })
                  )
              );
          }
          break;
        case 3:
          // 月ごと
          if (props.graphData1.map((a: any) => a.year_month).length > 0) {
            //x軸
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 281018,
              parameters: {
                year_month:
                  '{' +
                  Array.from(new Set(props.graphData1.map((a: any) => a.year_month))).join(',') +
                  '}',
                work: !isEmpty(props.searchValue.work)
                  ? '{' + props.searchValue.work.join(',') + '}'
                  : null,
                deviceid: !isEmpty(props.searchValue.deviceid)
                  ? '{' + props.searchValue.deviceid.join(',') + '}'
                  : null,
                judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                map: !isEmpty(props.searchValue.map)
                  ? '{' + props.searchValue.map.join(',') + '}'
                  : '{' +
                    props.typeKey
                      .filter((a: any) => a.tolerance_type === 1)[0]
                      .map_array.join(',') +
                    '}'
              },
              name: 'id-281018',
              cancelToken: source.token,
              t
            })
              .then((data: any) => {
                setDataCubeX(data);
                // X軸の範囲Rを全権取得のため
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 280037,
                  parameters: {
                    select_date_from: props.searchValue.select_date_from,
                    select_date_to: props.searchValue.select_date_to,
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-280037',
                  cancelToken: source.token,
                  t
                }).then((data: any) => {
                  setDataCubeXR(data);
                });
              })
              .then(() =>
                //y軸
                getLFCData({
                  snack: enqueueSnackbar,
                  sql_id: 281019,
                  parameters: {
                    year_month:
                      '{' +
                      Array.from(new Set(props.graphData1.map((a: any) => a.year_month))).join(
                        ','
                      ) +
                      '}',
                    work: !isEmpty(props.searchValue.work)
                      ? '{' + props.searchValue.work.join(',') + '}'
                      : null,
                    deviceid: !isEmpty(props.searchValue.deviceid)
                      ? '{' + props.searchValue.deviceid.join(',') + '}'
                      : null,
                    judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                    map: !isEmpty(props.searchValue.map)
                      ? '{' + props.searchValue.map.join(',') + '}'
                      : '{' +
                        props.typeKey
                          .filter((a: any) => a.tolerance_type === 1)[0]
                          .map_array.join(',') +
                        '}'
                  },
                  name: 'id-281019',
                  cancelToken: source.token,
                  t
                })
                  .then((data: any) => {
                    setDataCubeY(data);
                    // Y軸の範囲Rを全権取得のため
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 280038,
                      parameters: {
                        select_date_from: props.searchValue.select_date_from,
                        select_date_to: props.searchValue.select_date_to,
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-280038',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeYR(data);
                    });
                  })
                  .then(() =>
                    //z軸
                    getLFCData({
                      snack: enqueueSnackbar,
                      sql_id: 281020,
                      parameters: {
                        year_month:
                          '{' +
                          Array.from(new Set(props.graphData1.map((a: any) => a.year_month))).join(
                            ','
                          ) +
                          '}',
                        work: !isEmpty(props.searchValue.work)
                          ? '{' + props.searchValue.work.join(',') + '}'
                          : null,
                        deviceid: !isEmpty(props.searchValue.deviceid)
                          ? '{' + props.searchValue.deviceid.join(',') + '}'
                          : null,
                        judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                        map: !isEmpty(props.searchValue.map)
                          ? '{' + props.searchValue.map.join(',') + '}'
                          : '{' +
                            props.typeKey
                              .filter((a: any) => a.tolerance_type === 1)[0]
                              .map_array.join(',') +
                            '}'
                      },
                      name: 'id-281020',
                      cancelToken: source.token,
                      t
                    }).then((data: any) => {
                      setDataCubeZ(data);
                      // Z軸の範囲Rを全権取得のため
                      getLFCData({
                        snack: enqueueSnackbar,
                        sql_id: 280039,
                        parameters: {
                          select_date_from: props.searchValue.select_date_from,
                          select_date_to: props.searchValue.select_date_to,
                          work: !isEmpty(props.searchValue.work)
                            ? '{' + props.searchValue.work.join(',') + '}'
                            : null,
                          deviceid: !isEmpty(props.searchValue.deviceid)
                            ? '{' + props.searchValue.deviceid.join(',') + '}'
                            : null,
                          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
                          map: !isEmpty(props.searchValue.map)
                            ? '{' + props.searchValue.map.join(',') + '}'
                            : '{' +
                              props.typeKey
                                .filter((a: any) => a.tolerance_type === 1)[0]
                                .map_array.join(',') +
                              '}'
                        },
                        name: 'id-280039',
                        cancelToken: source.token,
                        t
                      }).then((data: any) => {
                        setDataCubeZR(data);
                      });
                    })
                  )
              );
          }
          break;
      }
    }

    //　キューブのエクストラ：データ計算
    if (props.graphData1.length > 0) {
      let x_upper_limit: any = distinct(props.graphData1, 'x_upper_limit').map((item: any) =>
        Number(item)
      );
      let x_lower_limit: any = distinct(props.graphData1, 'x_lower_limit').map((item: any) =>
        Number(item)
      );
      let y_upper_limit: any = distinct(props.graphData1, 'y_upper_limit').map((item: any) =>
        Number(item)
      );
      let y_lower_limit: any = distinct(props.graphData1, 'y_lower_limit').map((item: any) =>
        Number(item)
      );
      let z_upper_limit: any = distinct(props.graphData1, 'z_upper_limit').map((item: any) =>
        Number(item)
      );
      let z_lower_limit: any = distinct(props.graphData1, 'z_lower_limit').map((item: any) =>
        Number(item)
      );

      let d8_x: number = Number(average(props.graphData1.map((item: any) => item.x_gap)));
      let d8_y: number = Number(average(props.graphData1.map((item: any) => item.y_gap)));
      let d8_z: number = Number(average(props.graphData1.map((item: any) => item.z_gap)));

      let d9_x: number = Quartile(
        props.graphData1.map((a: any) => Number(a.x_gap)),
        0.5
      );
      let d9_y: number = Quartile(
        props.graphData1.map((a: any) => Number(a.y_gap)),
        0.5
      );
      let d9_z: number = Quartile(
        props.graphData1.map((a: any) => Number(a.z_gap)),
        0.5
      );

      let d14_x: number = Math.abs(
        (average(x_upper_limit) - average(x_lower_limit)) /
          (stdev(props.graphData1.map((item: any) => Number(item.x_gap))) * 6)
      );
      let d14_y: number = Math.abs(
        (average(y_upper_limit) - average(y_lower_limit)) /
          (stdev(props.graphData1.map((item: any) => Number(item.y_gap))) * 6)
      );
      let d14_z: number = Math.abs(
        (average(z_upper_limit) - average(z_lower_limit)) /
          (stdev(props.graphData1.map((item: any) => Number(item.z_gap))) * 6)
      );

      let d16_x: number = Math.min(
        Math.abs(average(x_upper_limit) - d8_x) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.x_gap)))),
        Math.abs(d8_x - average(x_lower_limit)) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.x_gap))))
      );
      let d16_y: number = Math.min(
        Math.abs(average(y_upper_limit) - d8_y) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.y_gap)))),
        Math.abs(d8_y - average(y_lower_limit)) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.y_gap))))
      );
      let d16_z: number = Math.min(
        Math.abs(average(z_upper_limit) - d8_z) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.z_gap)))),
        Math.abs(d8_z - average(z_lower_limit)) /
          (3 * stdev(props.graphData1.map((item: any) => Number(item.z_gap))))
      );

      setData1MaxAvgMin({
        data_x: {
          d3: x_upper_limit.length > 1 ? average(x_upper_limit) : x_upper_limit[0], //上限
          d4: x_upper_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: x_lower_limit.length > 1 ? average(x_lower_limit) : x_lower_limit[0], //下限
          d6: x_lower_limit.length > 1 ? t('※規格複数のため参考値') : '', //下限info
          d7: Math.max(...props.graphData1.map((item: any) => item.x_gap)), //最大
          d8: d8_x, //平均
          d9: d9_x, //中央
          d10: Math.min(...props.graphData1.map((item: any) => item.x_gap)), //最小
          d11: stdev(props.graphData1.map((item: any) => Number(item.x_gap))), //標準偏差
          d12: d8_x + 3 * stdev(props.graphData1.map((item: any) => Number(item.x_gap))), //管理限界線1 平均+3σ
          d13: d8_x - 3 * stdev(props.graphData1.map((item: any) => Number(item.x_gap))), //管理限界線2 平均-3σ
          d14: d14_x, //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16_x, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        },
        data_y: {
          d3: y_upper_limit.length > 1 ? average(y_upper_limit) : y_upper_limit[0], //上限
          d4: y_upper_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: y_lower_limit.length > 1 ? average(y_lower_limit) : y_lower_limit[0], //下限
          d6: y_lower_limit.length > 1 ? t('※規格複数のため参考値') : '', //下限info
          d7: Math.max(...props.graphData1.map((item: any) => item.y_gap)), //最大
          d8: d8_y, //平均
          d9: d9_y, //中央
          d10: Math.min(...props.graphData1.map((item: any) => item.y_gap)), //最小
          d11: stdev(props.graphData1.map((item: any) => Number(item.y_gap))), //標準偏差
          d12: d8_y + 3 * stdev(props.graphData1.map((item: any) => Number(item.y_gap))), //管理限界線1 平均+3σ
          d13: d8_y - 3 * stdev(props.graphData1.map((item: any) => Number(item.y_gap))), //管理限界線2 平均-3σ
          d14: d14_y, //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16_y, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        },
        data_z: {
          d3: z_upper_limit.length > 1 ? average(z_upper_limit) : z_upper_limit[0], //上限
          d4: z_upper_limit.length > 1 ? t('※規格複数のため参考値') : '', //上限info
          d1: 0, //目標
          d2: '', //目標info
          d5: z_lower_limit.length > 1 ? average(z_lower_limit) : z_lower_limit[0], //下限
          d6: z_lower_limit.length > 1 ? t('※規格複数のため参考値') : '', //下限info
          d7: Math.max(...props.graphData1.map((item: any) => item.z_gap)), //最大
          d8: d8_z, //平均
          d9: d9_z, //中央
          d10: Math.min(...props.graphData1.map((item: any) => item.z_gap)), //最小
          d11: stdev(props.graphData1.map((item: any) => Number(item.z_gap))), //標準偏差
          d12: d8_z + 3 * stdev(props.graphData1.map((item: any) => Number(item.z_gap))), //管理限界線1 平均+3σ
          d13: d8_z - 3 * stdev(props.graphData1.map((item: any) => Number(item.z_gap))), //管理限界線2 平均-3σ
          d14: d14_z, //Cp, 工程能力1
          d15: '', //工程能力1info
          d16: d16_z, //Cpk, 工程能力2
          d17: '', //工程能力2info
          d18: '', //仕様限界LSL：master_radius + radius_lower_limit, //仕様限界LSL
          d19: '' //仕様限界USL：master_radius + radius_upper_limit,//仕様限界USL
        }
      });
    } else {
      setData1MaxAvgMin(data1MaxAvgMin_init);
    }
  }, [props.graphData1]);

  // キューブ：ばらつき推移グラフの範囲Rと他のデータをまとめる

  // １集計単位ごとに複数のマスター値、リミット値がある場合はnullとする。
  const checkNotSingleMasterAndLimit = (
    targetName: string,
    checkType: string,
    checkData: string
  ) => {
    switch (targetName) {
      case 'x':
        let x_lower_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.x_lower_limit)
          )
        );
        let x_upper_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.x_upper_limit)
          )
        );
        return x_lower_limit_list.length === 1 && x_upper_limit_list.length === 1;
      case 'y':
        let y_lower_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.y_lower_limit)
          )
        );
        let y_upper_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.y_upper_limit)
          )
        );
        return y_lower_limit_list.length === 1 && y_upper_limit_list.length === 1;
      case 'z':
        let z_lower_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.z_lower_limit)
          )
        );
        let z_upper_limit_list: any = Array.from(
          new Set(
            props.graphData1
              .filter((a: any) => a[checkType] === checkData)
              .map((b: any) => b.z_upper_limit)
          )
        );
        return z_lower_limit_list.length === 1 && z_upper_limit_list.length === 1;
      default:
        break;
    }
  };

  // dataCubeX
  useEffect(() => {
    let tmp_list: any = [];
    setDataCubeX_show([]);
    if (dataCubeX.length > 0 && isArrayLikeObject(date_calc())) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setDataCubeX_show(dataCubeX);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeX.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month_day_hour', a)
            ) {
              tmp_list.push(dataCubeX.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeX[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeX_show(tmp_list);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeX.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month_day', a)
            ) {
              tmp_list.push(dataCubeX.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeX[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeX_show(tmp_list);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeX.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month', a)
            ) {
              tmp_list.push(dataCubeX.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeX[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeX_show(tmp_list);
          break;
      }
    }
  }, [dataCubeX]);
  // dataCubeY
  useEffect(() => {
    let tmp_list: any = [];
    setDataCubeY_show([]);
    if (dataCubeY.length > 0 && isArrayLikeObject(date_calc())) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setDataCubeY_show(dataCubeY);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeY.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month_day_hour', a)
            ) {
              tmp_list.push(dataCubeY.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeY[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeY_show(tmp_list);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeY.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month_day', a)
            ) {
              tmp_list.push(dataCubeY.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeY[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeY_show(tmp_list);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeY.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month', a)
            ) {
              tmp_list.push(dataCubeY.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeY[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeY_show(tmp_list);
          break;
      }
    }
  }, [dataCubeY]);
  // dataCubeZ
  useEffect(() => {
    let tmp_list: any = [];
    setDataCubeZ_show([]);
    if (dataCubeZ.length > 0 && isArrayLikeObject(date_calc())) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          setDataCubeZ_show(dataCubeZ);
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeZ.find((b: any) => b.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month_day_hour', a)
            ) {
              tmp_list.push(dataCubeZ.filter((b: any) => b.year_month_day_hour === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeZ[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZ_show(tmp_list);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeZ.find((b: any) => b.year_month_day === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month_day', a)
            ) {
              tmp_list.push(dataCubeZ.filter((b: any) => b.year_month_day === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeZ[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZ_show(tmp_list);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (
              dataCubeZ.find((b: any) => b.year_month === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month', a)
            ) {
              tmp_list.push(dataCubeZ.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list.push({
                map: dataCubeZ[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZ_show(tmp_list);
          break;
      }
    }
  }, [dataCubeZ]);
  // dataCubeXR
  useEffect(() => {
    let tmp_list_r: any = [];
    setDataCubeXR_show([]);
    if (dataCubeXR.length > 0 && isArrayLikeObject(date_calc())) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeXR.find((d: any) => d.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month_day_hour', a)
            ) {
              tmp_list_r.push(dataCubeXR.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeXR[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeXR_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeXR.find((d: any) => d.year_month_day === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month_day', a)
            ) {
              tmp_list_r.push(dataCubeXR.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeXR[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeXR_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeXR.find((d: any) => d.year_month === a) &&
              checkNotSingleMasterAndLimit('x', 'year_month', a)
            ) {
              tmp_list_r.push(dataCubeXR.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeXR[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeXR_show(tmp_list_r);
          break;
      }
    }
  }, [dataCubeXR]);
  // dataCubeYR
  useEffect(() => {
    let tmp_list_r: any = [];
    setDataCubeYR_show([]);
    if (dataCubeYR.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeYR.find((d: any) => d.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month_day_hour', a)
            ) {
              tmp_list_r.push(dataCubeYR.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeYR[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeYR_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeYR.find((d: any) => d.year_month_day === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month_day', a)
            ) {
              tmp_list_r.push(dataCubeYR.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeYR[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeYR_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeYR.find((d: any) => d.year_month === a) &&
              checkNotSingleMasterAndLimit('y', 'year_month', a)
            ) {
              tmp_list_r.push(dataCubeYR.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeYR[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeYR_show(tmp_list_r);
          break;
      }
    }
  }, [dataCubeYR]);
  // dataCubeZR
  useEffect(() => {
    let tmp_list_r: any = [];
    setDataCubeZR_show([]);
    if (dataCubeZR.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeZR.find((d: any) => d.year_month_day_hour === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month_day_hour', a)
            ) {
              tmp_list_r.push(dataCubeZR.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeZR[0]['map'],
                year_month_day_hour: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZR_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeZR.find((d: any) => d.year_month_day === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month_day', a)
            ) {
              tmp_list_r.push(dataCubeZR.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeZR[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZR_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            // 範囲Rを全権取得のため
            if (
              dataCubeZR.find((d: any) => d.year_month === a) &&
              checkNotSingleMasterAndLimit('z', 'year_month', a)
            ) {
              tmp_list_r.push(dataCubeZR.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: dataCubeZR[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setDataCubeZR_show(tmp_list_r);
          break;
      }
    }
  }, [dataCubeZR]);

  // キューブ：ばらつき推移のグラフ用データ生成
  // dataCubeX

  //　１集計単位ごとに10件のデータがない場合はnullとする。
  let show_limit: number = 10;

  useEffect(() => {
    if (props.searchValue.searchType !== 0) {
      setDataCubeX_XYZ([
        {
          data:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            dataCubeXR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeXR_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: '範囲R',
          map:
            dataCubeXR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeXR_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            dataCubeX_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeX_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setDataCubeX_XYZ([
        {
          data:
            dataCubeX_show.filter((a: any) => a.移動範囲rs).length > 0
              ? dataCubeX_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          name: t('移動範囲Rs'),
          lq_time: dataCubeX_show.map((a: any) => a.lq_time),
          map: dataCubeX_show.map((a: any) => a.map)
        }
      ]);
    }
  }, [dataCubeXR_show]);
  // dataCubeY
  useEffect(() => {
    if (props.searchValue.searchType !== 0) {
      setDataCubeY_XYZ([
        {
          data:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            dataCubeYR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeYR_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: '範囲R',
          map:
            dataCubeYR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeYR_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            dataCubeY_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeY_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setDataCubeY_XYZ([
        {
          data:
            dataCubeY_show.filter((a: any) => a.移動範囲rs).length > 0
              ? dataCubeY_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          name: t('移動範囲Rs'),
          lq_time: dataCubeY_show.map((a: any) => a.lq_time),
          map: dataCubeY_show.map((a: any) => a.map)
        }
      ]);
    }
  }, [dataCubeYR_show]);

  // dataCubeZ
  useEffect(() => {
    if (props.searchValue.searchType !== 0) {
      setDataCubeZ_XYZ([
        {
          data:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: '標準偏差S',
          map:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => a.map)
              : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            dataCubeZR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeZR_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: '範囲R',
          map:
            dataCubeZR_show.filter((a: any) => a.範囲r).length > 0
              ? dataCubeZR_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: '工程能力Cp',
          map:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => a.map)
              : null
        },
        {
          data:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: '工程能力Cpk',
          map:
            dataCubeZ_show.filter((a: any) => a.データ数 > show_limit).length > 0
              ? dataCubeZ_show.map((a: any) => a.map)
              : null
        }
      ]);
    } else {
      setDataCubeZ_XYZ([
        {
          data:
            dataCubeZ_show.filter((a: any) => a.移動範囲rs).length > 0
              ? dataCubeZ_show.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          name: t('移動範囲Rs'),
          lq_time: dataCubeZ_show.map((a: any) => a.lq_time),
          map: dataCubeZ_show.map((a: any) => a.map)
        }
      ]);
    }
  }, [dataCubeZR_show]);

  const [exportDataCubeX, setExportDataCubeX] = useState([]);
  useEffect(() => {
    if (graphBaseData1.data_x !== undefined) {
      setExportDataCubeX(makeExportData3(graphBaseData1.data_x.x_list, dataCubeX_XYZ, 0));
    }
  }, [dataCubeX_XYZ]);

  const [exportDataCubeY, setExportDataCubeY] = useState([]);
  useEffect(() => {
    if (graphBaseData1.data_y !== undefined) {
      setExportDataCubeY(makeExportData3(graphBaseData1.data_y.x_list, dataCubeY_XYZ, 1));
    }
  }, [dataCubeY_XYZ]);

  const [exportDataCubeZ, setExportDataCubeZ] = useState([]);
  useEffect(() => {
    if (graphBaseData1.data_y !== undefined) {
      setExportDataCubeZ(makeExportData3(graphBaseData1.data_z.x_list, dataCubeZ_XYZ, 2));
    }
  }, [dataCubeZ_XYZ]);

  // キューブの分布グラフリミット計算
  useEffect(() => {
    let tmp_n: number = props.graphData1.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }
    // 「最大値」「上限(仕様)」「管理限界線(+3σ)」
    let x_tmp_max: number = Math.max(
      data1MaxAvgMin.data_x.d7,
      data1MaxAvgMin.data_x.d3,
      data1MaxAvgMin.data_x.d12
    );
    setDataX_LineMax(x_tmp_max);
    let y_tmp_max: number = Math.max(
      data1MaxAvgMin.data_y.d7,
      data1MaxAvgMin.data_y.d3,
      data1MaxAvgMin.data_y.d12
    );
    setDataY_LineMax(y_tmp_max);
    let z_tmp_max: number = Math.max(
      data1MaxAvgMin.data_z.d7,
      data1MaxAvgMin.data_z.d3,
      data1MaxAvgMin.data_z.d12
    );
    setDataZ_LineMax(z_tmp_max);

    // 最小値」「下限(仕様)」「管理限界線(-3σ)」
    let x_tmp_min: number = Math.min(
      data1MaxAvgMin.data_x.d5,
      data1MaxAvgMin.data_x.d10,
      data1MaxAvgMin.data_x.d13
    );
    setDataX_LineMin(x_tmp_min);
    let y_tmp_min: number = Math.min(
      data1MaxAvgMin.data_y.d5,
      data1MaxAvgMin.data_y.d10,
      data1MaxAvgMin.data_y.d13
    );
    setDataY_LineMin(y_tmp_min);
    let z_tmp_min: number = Math.min(
      data1MaxAvgMin.data_z.d5,
      data1MaxAvgMin.data_z.d10,
      data1MaxAvgMin.data_z.d13
    );
    setDataZ_LineMin(z_tmp_min);
    let x_tmp_h: number = (x_tmp_max - x_tmp_min) / tmp_k;
    let under_zero: number = Math.floor(1 / x_tmp_h);
    let zero: string = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }

    let x_tmp_dot: number = String(x_tmp_h).indexOf('.');
    let x_tmp_round: number;
    if (x_tmp_dot > 0) {
      x_tmp_round = Math.floor(x_tmp_h * (x_tmp_dot * Number(zero))) / (x_tmp_dot * Number(zero));
    } else {
      x_tmp_round = Math.floor(x_tmp_h);
    }
    setDataX_Renge(x_tmp_round);
    let y_tmp_h: number = (y_tmp_max - y_tmp_min) / tmp_k;
    under_zero = Math.floor(1 / y_tmp_h);
    zero = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }

    let y_tmp_dot: number = String(y_tmp_h).indexOf('.');
    let y_tmp_round: number;
    if (y_tmp_dot > 0) {
      y_tmp_round = Math.floor(y_tmp_h * (y_tmp_dot * Number(zero))) / (y_tmp_dot * Number(zero));
    } else {
      y_tmp_round = Math.floor(y_tmp_h);
    }
    setDataY_Renge(y_tmp_round);
    let z_tmp_h: number = (z_tmp_max - z_tmp_min) / tmp_k;

    under_zero = Math.floor(1 / z_tmp_h);
    zero = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }

    let z_tmp_dot: number = String(z_tmp_h).indexOf('.');
    let z_tmp_round: number;
    if (z_tmp_dot > 0) {
      z_tmp_round = Math.floor(z_tmp_h * (z_tmp_dot * Number(zero))) / (z_tmp_dot * Number(zero));
    } else {
      z_tmp_round = Math.floor(z_tmp_h);
    }
    setDataZ_Renge(z_tmp_round);

    // キューブ：誤差　推移のグラフのデータを作成
    if (props.graphData1.length > 0) {
      // if (ttt_test.length > 0) {
      switch (props.searchValue.searchType) {
        case 0: //検査ごと
          break;
        case 1: //時間ごと
          date_calc().forEach((lq_time_string: string) => {
            tmp_x = [];
            tmp_y = [];
            tmp_z = [];
            props.graphData1.forEach((item: any) => {
              if (String(item.year_month_day_hour) === lq_time_string) {
                tmp_x.push(Number(item['x_gap']));
                tmp_y.push(Number(item['y_gap']));
                tmp_z.push(Number(item['z_gap']));
              }
            });
            tmp_data_x.push(tmp_x);
            tmp_data_y.push(tmp_y);
            tmp_data_z.push(tmp_z);
          });
          break;
        case 2: //日ごと
          date_calc().forEach((lq_time_string: string) => {
            tmp_x = [];
            tmp_y = [];
            tmp_z = [];
            props.graphData1.forEach((item: any) => {
              if (String(item.year_month_day) === lq_time_string) {
                tmp_x.push(Number(item['x_gap']));
                tmp_y.push(Number(item['y_gap']));
                tmp_z.push(Number(item['z_gap']));
              }
            });
            tmp_data_x.push(tmp_x);
            tmp_data_y.push(tmp_y);
            tmp_data_z.push(tmp_z);
          });
          break;
        case 3: //月ごと
          date_calc().forEach((lq_time_string: string) => {
            tmp_x = [];
            tmp_y = [];
            tmp_z = [];
            props.graphData1.forEach((item: any) => {
              if (String(item.year_month) === lq_time_string) {
                tmp_x.push(Number(item['x_gap']));
                tmp_y.push(Number(item['y_gap']));
                tmp_z.push(Number(item['z_gap']));
              }
            });
            tmp_data_x.push(tmp_x);
            tmp_data_y.push(tmp_y);
            tmp_data_z.push(tmp_z);
          });
          break;
      }
      if (props.searchValue.searchType !== 0) {
        setGraphBaseData1({
          data_x: {
            id: tmp_data_x.map((a: any, index: number) => index + 1),
            map: dataCubeX_XYZ.map((a: any) => a.map)[0],
            lq_time: dataCubeX_XYZ.map((a: any) => a.lq_time),
            data_value: tmp_data_x,
            data_avg_cl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d9),
            data_avg: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d8), //平均
            data_avg_mel: tmp_data_x.map((a: any) => (!isEmpty(a) ? average(a) : '')), //群平均
            data_middle_mel: tmp_data_x.map((a: any) => (!isEmpty(a) ? Quartile(a, 0.5) : '')), //群中央
            data_middle: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d9), //中央
            data_lcl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d13),
            data_ucl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d12),
            data_target: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d1),
            data_lsl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d3),
            data_usl: tmp_data_x.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d3),
            lower_limit: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d5)
          },
          data_y: {
            id: tmp_data_y.map((a: any, index: number) => index + 1),
            map: dataCubeY_XYZ.map((a: any) => a.map),
            lq_time: dataCubeY_XYZ.map((a: any) => a.lq_time),
            data_value: tmp_data_y,
            data_avg_cl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d9),
            data_avg: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d8), //平均
            data_avg_mel: tmp_data_y.map((a: any) => (!isEmpty(a) ? average(a) : '')), //群平均
            data_middle_mel: tmp_data_y.map((a: any) => (!isEmpty(a) ? Quartile(a, 0.5) : '')), //群中央
            data_middle: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d9), //中央
            data_lcl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d13),
            data_ucl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d12),
            data_target: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d1),
            data_lsl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d3),
            data_usl: tmp_data_y.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d3),
            lower_limit: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d5)
          },
          data_z: {
            id: tmp_data_z.map((a: any, index: number) => index + 1),
            map: dataCubeZ_XYZ.map((a: any) => a.map),
            lq_time: dataCubeZ_XYZ.map((a: any) => a.lq_time),
            data_value: tmp_data_z,
            data_avg_cl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d9),
            data_avg: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d8), //平均
            data_avg_mel: tmp_data_z.map((a: any) => (!isEmpty(a) ? average(a) : '')), //群平均
            data_middle_mel: tmp_data_z.map((a: any) => (!isEmpty(a) ? Quartile(a, 0.5) : '')), //群中央
            data_middle: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d9), //中央
            data_lcl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d13),
            data_ucl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d12),
            data_target: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d1),
            data_lsl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d3),
            data_usl: tmp_data_z.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d3),
            lower_limit: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d5)
          }
        });
        //キューブのエクスポートデータベースの生成
        setDataCubeExport({
          data_x: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              x_gap: a.x_gap,
              x_lower_limit: a.x_lower_limit,
              x_upper_limit: a.x_upper_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month
            };
          }),
          data_y: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              y_gap: a.y_gap,
              y_lower_limit: a.y_lower_limit,
              y_upper_limit: a.y_upper_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month
            };
          }),
          data_z: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              z_gap: a.y_gap,
              z_lower_limit: a.z_lower_limit,
              z_upper_limit: a.z_upper_limit,
              year_month_day_hour: a.year_month_day_hour,
              year_month_day: a.year_month_day,
              year_month: a.year_month
            };
          })
        });
      } else {
        setGraphBaseData1({
          data_x: {
            id: props.graphData1.map((a: any, index: number) => index + 1),
            map: props.graphData1.map((a: any) => a.map),
            lq_time: props.graphData1.map((a: any) => a.lq_time),
            serial: props.graphData1.map((a: any) => a.serial),
            data_value: props.graphData1.map((a: any) => a.x_gap),
            data_avg: tmp_data_x.map((a: any) => average(a)),
            data_middle: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d8),
            data_avg_cl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d9),
            data_middle_mel: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d8),
            data_lcl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d13),
            data_ucl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d12),
            data_target: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d1),
            data_lsl: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d3),
            data_usl: tmp_data_x.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d3),
            lower_limit: tmp_data_x.map((a: any) => data1MaxAvgMin.data_x.d5)
          },
          data_y: {
            id: props.graphData1.map((a: any, index: number) => index + 1),
            map: props.graphData1.map((a: any) => a.map),
            lq_time: props.graphData1.map((a: any) => a.lq_time),
            serial: props.graphData1.map((a: any) => a.serial),
            data_value: props.graphData1.map((a: any) => a.y_gap),
            data_avg: tmp_data_y.map((a: any) => average(a)),
            data_middle: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d8),
            data_avg_cl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d9),
            data_middle_mel: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d8),
            data_lcl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d13),
            data_ucl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d12),
            data_target: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d1),
            data_lsl: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d3),
            data_usl: tmp_data_y.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d3),
            lower_limit: tmp_data_y.map((a: any) => data1MaxAvgMin.data_y.d5)
          },
          data_z: {
            id: props.graphData1.map((a: any, index: number) => index + 1),
            map: props.graphData1.map((a: any) => a.map),
            lq_time: props.graphData1.map((a: any) => a.lq_time),
            serial: props.graphData1.map((a: any) => a.serial),
            data_value: props.graphData1.map((a: any) => a.z_gap),
            data_avg: tmp_data_z.map((a: any) => average(a)),
            data_middle: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d8),
            data_avg_cl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d9),
            data_middle_mel: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d8),
            data_lcl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d13),
            data_ucl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d12),
            data_target: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d1),
            data_lsl: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d3),
            data_usl: tmp_data_z.map((a: any) => Quartile(a, 0.5)),
            x_list: date_calc(),
            upper_limit: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d3),
            lower_limit: tmp_data_z.map((a: any) => data1MaxAvgMin.data_z.d5)
          }
        });
        //キューブのエクスポートデータベースの生成
        setDataCubeExport({
          data_x: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              serial: String(a.serial),
              x_gap: a.x_gap,
              x_lower_limit: a.x_lower_limit,
              x_upper_limit: a.x_upper_limit,
              lq_time: a.lq_time
            };
          }),
          data_y: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              serial: String(a.serial),
              y_gap: a.y_gap,
              y_lower_limit: a.y_lower_limit,
              y_upper_limit: a.y_upper_limit,
              lq_time: a.lq_time
            };
          }),
          data_z: props.graphData1.map((a: any) => {
            return {
              map: String(a.map),
              work: String(a.work),
              deviceid: String(a.deviceid),
              serial: String(a.serial),
              z_gap: a.y_gap,
              z_lower_limit: a.z_lower_limit,
              z_upper_limit: a.z_upper_limit,
              lq_time: a.lq_time
            };
          })
        });
      }
    }
  }, [data1MaxAvgMin]);

  // キューブの分布グラフデータ取得：X
  const getRengeData1X = () => {
    if (dataX_Renge !== 0 && !isNaN(dataX_Renge)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29003,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          x_renge: dataX_Renge
        },
        ds_state: setDataCubeX_SP,
        name: 'id-29003',
        cancelToken: source.token,
        t
      });
    }
  };
  useEffect(() => {
    getRengeData1X();
  }, [dataX_Renge]);

  // キューブの分布グラフデータ取得：Y
  const getRengeData1Y = () => {
    if (dataY_Renge !== 0 && !isNaN(dataY_Renge)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29004,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          y_renge: dataY_Renge
        },
        ds_state: setDataCubeY_SP,
        name: 'id-29004',
        cancelToken: source.token,
        t
      });
    }
  };
  useEffect(() => {
    getRengeData1Y();
  }, [dataY_Renge]);

  // キューブの分布グラフデータ取得：Z
  const getRengeData1Z = () => {
    if (dataZ_Renge !== 0 && !isNaN(dataZ_Renge)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29005,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          z_renge: dataZ_Renge
        },
        ds_state: setDataCubeZ_SP,
        name: 'id-29005',
        cancelToken: source.token,
        t
      });
    }
  };
  useEffect(() => {
    getRengeData1Z();
  }, [dataZ_Renge]);

  //キューブ：グラフクリック
  const getDataWithDate1x = (datas: string) => {
    setGraphData1XSelectDate(datas);
  };
  const getDataByData1x = (datas: string) => {
    setGraphData1XSelectDate(datas);
  };
  const getDataWithDate1y = (datas: string) => {
    setGraphData1YSelectDate(datas);
  };
  const getDataByData1y = (datas: string) => {
    setGraphData1YSelectDate(datas);
  };
  const getDataWithDate1z = (datas: string) => {
    setGraphData1ZSelectDate(datas);
  };
  const getDataByData1z = (datas: string) => {
    setGraphData1ZSelectDate(datas);
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');
  const zoomChartsCheck = (idString: string) => {
    return zoomChartOpen === idString;
  };

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  // ---------分布グラフ
  // キューブ：X：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData1XSelectDate === '') {
      getRengeData1X();
      return;
    }

    // 検査ごと：検索
    if (props.searchValue.searchType === 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29008,
        parameters: {
          select_serial: graphData1XSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataX_Renge
        },
        ds_state: setDataCubeX_SP,
        name: 'id-29008',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29009,
        parameters: {
          select_lq_date_hour: graphData1XSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataX_Renge
        },
        ds_state: setDataCubeX_SP,
        name: 'id-29009',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29010,
        parameters: {
          select_date: graphData1XSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataX_Renge
        },
        ds_state: setDataCubeX_SP,
        name: 'id-29010',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29011,
        parameters: {
          select_date_month: graphData1XSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataX_Renge
        },
        ds_state: setDataCubeX_SP,
        name: 'id-29011',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData1XSelectDate]);

  // キューブ：Y：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData1YSelectDate === '') {
      getRengeData1Y();
      return;
    }
    // 検査ごと：検索
    if (props.searchValue.searchType === 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29012,
        parameters: {
          select_serial: graphData1YSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataY_Renge
        },
        ds_state: setDataCubeY_SP,
        name: 'id-29012',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29013,
        parameters: {
          select_lq_date_hour: graphData1YSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataY_Renge
        },
        ds_state: setDataCubeY_SP,
        name: 'id-29013',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29014,
        parameters: {
          select_date: graphData1YSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataY_Renge
        },
        ds_state: setDataCubeY_SP,
        name: 'id-29014',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29015,
        parameters: {
          select_date_month: graphData1YSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataY_Renge
        },
        ds_state: setDataCubeY_SP,
        name: 'id-29015',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData1YSelectDate]);

  // キューブ：Z：推移グラフのクリックした時の分布グラフのデータ取得
  useEffect(() => {
    // 日付選択解除
    if (graphData1ZSelectDate === '') {
      getRengeData1Z();
      return;
    }
    // 検査ごと：検索
    if (props.searchValue.searchType === 0) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29016,
        parameters: {
          select_serial: graphData1ZSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataZ_Renge
        },
        ds_state: setDataCubeZ_SP,
        name: 'id-29016',
        cancelToken: source.token,
        t
      });
    }

    // 年月日　時：検索
    if (props.searchValue.searchType === 1) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29017,
        parameters: {
          select_lq_date_hour: graphData1ZSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataZ_Renge
        },
        ds_state: setDataCubeZ_SP,
        name: 'id-29017',
        cancelToken: source.token,
        t
      });
    }

    // 年月日：検索
    if (props.searchValue.searchType === 2) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29018,
        parameters: {
          select_date: graphData1ZSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataZ_Renge
        },
        ds_state: setDataCubeZ_SP,
        name: 'id-29018',
        cancelToken: source.token,
        t
      });
    }

    // 年月：検索
    if (props.searchValue.searchType === 3) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29019,
        parameters: {
          select_date_month: graphData1ZSelectDate,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 1)[0].map_array.join(',') +
              '}',
          renge: dataZ_Renge
        },
        ds_state: setDataCubeZ_SP,
        name: 'id-2901)',
        cancelToken: source.token,
        t
      });
    }
  }, [graphData1ZSelectDate]);

  // 穴分布のグラフクリックして、クリックした位置の一覧表を出力
  const [g2clickData, setG2clickData] = useState([]);

  // X軸
  const g2clickX = (datas: any) => {
    setStartProcess(true);
    let x_gap_min: number | null = null;
    let x_gap_max: number | null = null;
    if (datas.name !== undefined) {
      x_gap_min = datas.name.split('_')[0];
      x_gap_max = datas.name.split('_')[1];
    }

    if (graphData1XSelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29037,
          parameters: {
            select_serial: graphData1XSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : null,
            x_gap_min: x_gap_min,
            x_gap_max: x_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29037',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29039,
          parameters: {
            select_lq_date_hour: graphData1XSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : null,
            x_gap_min: x_gap_min,
            x_gap_max: x_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29039',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29040,
          parameters: {
            select_date: graphData1XSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : null,
            x_gap_min: x_gap_min,
            x_gap_max: x_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29040',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29041,
          parameters: {
            select_date_month: graphData1XSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : null,
            x_gap_min: x_gap_min,
            x_gap_max: x_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29041',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29038,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          x_gap_min: x_gap_min,
          x_gap_max: x_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29038',
        cancelToken: source.token,
        t
      })
        .then(() => setDetailListOpen(true))
        .then(() => setStartProcess(false));
    }
  };

  // Y軸
  const g2clickY = (datas: any) => {
    setStartProcess(true);
    let y_gap_min: number | null = null;
    let y_gap_max: number | null = null;
    if (datas.name !== undefined) {
      y_gap_min = datas.name.split('_')[0];
      y_gap_max = datas.name.split('_')[1];
    }

    if (graphData1YSelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29042,
          parameters: {
            select_serial: graphData1YSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            y_gap_min: y_gap_min,
            y_gap_max: y_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29038',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29044,
          parameters: {
            select_lq_date_hour: graphData1YSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            y_gap_min: y_gap_min,
            y_gap_max: y_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29044',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29045,
          parameters: {
            select_date: graphData1YSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            y_gap_min: y_gap_min,
            y_gap_max: y_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29045',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29046,
          parameters: {
            select_date_month: graphData1YSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            y_gap_min: y_gap_min,
            y_gap_max: y_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29046',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29043,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          y_gap_min: y_gap_min,
          y_gap_max: y_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29043',
        cancelToken: source.token,
        t
      })
        .then(() => setDetailListOpen(true))
        .then(() => setStartProcess(false));
    }
  };

  // Z軸
  const g2clickZ = (datas: any) => {
    setStartProcess(true);
    let z_gap_min: number | null = null;
    let z_gap_max: number | null = null;
    if (datas.name !== undefined) {
      z_gap_min = datas.name.split('_')[0];
      z_gap_max = datas.name.split('_')[1];
    }

    if (graphData1ZSelectDate !== '') {
      // 検査ごと：検索
      if (props.searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29051,
          parameters: {
            select_serial: graphData1ZSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            z_gap_min: z_gap_min,
            z_gap_max: z_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29051',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (props.searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29049,
          parameters: {
            select_lq_date_hour: graphData1ZSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            z_gap_min: z_gap_min,
            z_gap_max: z_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29049',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (props.searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29048,
          parameters: {
            select_date: graphData1ZSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            z_gap_min: z_gap_min,
            z_gap_max: z_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29048',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
      // 年月：検索
      if (props.searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 29050,
          parameters: {
            select_date_month: graphData1ZSelectDate,
            work: !isEmpty(props.searchValue.work)
              ? '{' + props.searchValue.work.join(',') + '}'
              : null,
            deviceid: !isEmpty(props.searchValue.deviceid)
              ? '{' + props.searchValue.deviceid.join(',') + '}'
              : null,
            judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
            map: !isEmpty(props.searchValue.map)
              ? '{' + props.searchValue.map.join(',') + '}'
              : '{' +
                props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
                '}',
            z_gap_min: z_gap_min,
            z_gap_max: z_gap_max
          },
          ds_state: setG2clickData,
          name: 'id-29050',
          cancelToken: source.token,
          t
        })
          .then(() => setDetailListOpen(true))
          .then(() => setStartProcess(false));
      }
    } else {
      // 日付選択なし検索
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 29047,
        parameters: {
          select_date_from: props.searchValue.select_date_from,
          select_date_to: props.searchValue.select_date_to,
          work: !isEmpty(props.searchValue.work)
            ? '{' + props.searchValue.work.join(',') + '}'
            : null,
          deviceid: !isEmpty(props.searchValue.deviceid)
            ? '{' + props.searchValue.deviceid.join(',') + '}'
            : null,
          judge: props.searchValue.judge === 9 ? null : props.searchValue.judge,
          map: !isEmpty(props.searchValue.map)
            ? '{' + props.searchValue.map.join(',') + '}'
            : '{' +
              props.typeKey.filter((a: any) => a.tolerance_type === 0)[0].map_array.join(',') +
              '}',
          z_gap_min: z_gap_min,
          z_gap_max: z_gap_max
        },
        ds_state: setG2clickData,
        name: 'id-29047',
        cancelToken: source.token,
        t
      })
        .then(() => setDetailListOpen(true))
        .then(() => setStartProcess(false));
    }
  };

  //ばらつき推移グラフのダウンロードデータ生成
  const makeExportData3 = (x_list: any, baseData: any, xyzType: number) => {
    let tmp: any = [];
    if (x_list !== undefined && baseData.length > 0) {
      if (props.searchValue.searchType !== 0) {
        x_list.forEach((a: string, index: number) => {
          tmp.push({
            date: a,
            map: baseData[0].map !== null ? baseData[0].map[index] : null,
            work: String(
              Array.from(
                new Set(
                  props.graphData1
                    .filter((b: any) => {
                      switch (props.searchValue.searchType) {
                        case 1:
                          return b['year_month_day_hour'] === a;
                        case 2:
                          return b['year_month_day'] === a;
                        case 3:
                          return b['year_month'] === a;
                      }
                    })
                    .map((c: any) => c.work)
                    .flat()
                )
              )
            ),
            deviceid: String(
              Array.from(
                new Set(
                  props.graphData1
                    .filter((b: any) => {
                      switch (props.searchValue.searchType) {
                        case 1:
                          return b['year_month_day_hour'] === a;
                        case 2:
                          return b['year_month_day'] === a;
                        case 3:
                          return b['year_month'] === a;
                      }
                    })
                    .map((c: any) => c.deviceid)
                    .flat()
                )
              )
            ),
            標準偏差S: baseData[0].data !== null ? baseData[0].data[index] : null,
            範囲R:
              baseData[1].data !== null
                ? !isNaN(baseData[1].data[index])
                  ? baseData[1].data[index]
                  : null
                : null,
            工程能力Cp: baseData[2].data !== null ? baseData[2].data[index] : null,
            工程能力Cpk: baseData[3].data !== null ? baseData[3].data[index] : null
          });
        });
      } else {
        props.graphData1.forEach((a: any, index: number) => {
          tmp.push({
            for_export: a.lq_time,
            map: a.map,
            work: a.work,
            deviceid: a.deviceid,
            x_gap: xyzType === 0 ? baseData[0].data[index] : '',
            y_gap: xyzType === 1 ? baseData[0].data[index] : '',
            z_gap: xyzType === 2 ? baseData[0].data[index] : '',
            serial: a.serial
          });
        });
      }
    }
    return tmp;
  };

  useEffect(() => {
    if (dataCubeX_SP.length > 0 && dataCubeY_SP.length > 0 && dataCubeZ_SP.length > 0) {
      setStartProcess(false);
    }
  }, [dataCubeX_SP, dataCubeY_SP, dataCubeZ_SP]);

  const [listHeaders, setListHeaders] = useState(export_detail_all_list);
  useEffect(() => {
    if (g2clickData.length > 0) {
      let tmp_listHeaders: any = Object.keys(g2clickData[0]);
      g2clickData.forEach((a: any) => {
        for (let k in a) {
          if (a[k] === '' || a[k] === null) {
            delete tmp_listHeaders[k];
          }
        }
      });
      setListHeaders(
        export_detail_all_list.filter((a: any) => tmp_listHeaders.indexOf(a.field) !== -1)
      );
      setDetailListOpen(true);
    }
  }, [g2clickData]);

  const dataCubeZ_SP_column: any = {
    z_gap: t('Z座標誤差'),
    カウント数: t('カウント数')
  };

  const dataCubeExport_column_z_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    lq_time: t('検査日時'),
    x_gap: t('X座標誤差'),
    y_gap: t('Y座標誤差'),
    z_gap: t('Z座標誤差'),
    serial: t('シリアルナンバー')
  };

  //　キューブのグラフ・リスト出力
  return (
    <>
      <Grid container spacing={2} key={'graphSetType1x'}>
        <ProgressBar startProcess={startProcess} />
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLine4
              title={`${t('X座標誤差')} ${t('推移')}`}
              source={graphBaseData1.data_x}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={dataCubeExport.data_x}
              exportFields={dataCubeExport_column_x}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブX座標')}`}
              height={'33vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={props.searchValue.map}
              onClick={getDataWithDate1x}
              xlist={date_calc()}
              zoomChartId={'zoom-chart-4'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-4')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-4')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareCube
              title={`${t('X座標誤差')} ${t('推移')}`}
              source={
                graphBaseData1.data_x.data_value !== undefined ? graphBaseData1.data_x : undefined
              }
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値X'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={dataCubeExport.data_x}
              exportFields={dataCubeExport_column_x_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブX座標')}`}
              // mapName={props.searchValue.map}
              onClick={getDataByData1x}
              zoomChartId={'zoom-chart-4'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-4')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-4')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>

        <Grid item xs={12} md={5}>
          {graphData1XSelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData1XSelectDate
                : `${t('選択シリアル')}:` + graphData1XSelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData1XSelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <></>
          )}
          <LFCChartsBarXGap
            title={`${t('X座標誤差')} ${t('分布')}`}
            source={dataCubeX_SP}
            extraDatas={data1MaxAvgMin.data_x}
            exportData={dataCubeX_SP}
            exportFields={dataCubeX_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('キューブX座標')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2clickX}
            zoomChartId={'zoom-chart-5'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-5')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-5')}
            zoomChartsClose={() => setZoomChartOpen('')}
            xLineMin={dataX_LineMin}
            xLineMax={dataX_LineMax}
            xRenge={dataX_Renge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-6'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti4
                title={t('X座標ばらつき推移')}
                source={dataCubeX_XYZ}
                xData={date_calc()}
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeX}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブX座標'
                )}`}
                zoomChartId={'zoom-chart-6'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-6')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-6')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch4
                title={t('X座標ばらつき推移')}
                source={dataCubeX_XYZ}
                xData={props.graphData1.map((a: any, index: number) => index + 1)}
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeX}
                exportFields={exportData3_column_cube_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブX座標'
                )}`}
                zoomChartId={'zoom-chart-6'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-6')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-6')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_x.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_x.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d3)} mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d7)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d12)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_x.d14)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_x.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d8)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_x.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d9)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_x.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d5)} mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d10)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_x.d13)} mm
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} key={'graphSetType1y'}>
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLine4
              title={`${t('Y座標誤差')} ${t('推移')}`}
              source={graphBaseData1.data_y}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={dataCubeExport.data_y}
              exportFields={dataCubeExport_column_y}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブY座標')}`}
              height={'35vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={props.searchValue.map}
              onClick={getDataWithDate1y}
              xlist={date_calc()}
              zoomChartId={'zoom-chart-7'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-7')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-7')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareCube
              title={`${t('Y座標誤差')} ${t('推移')}`}
              source={
                graphBaseData1.data_y.data_value !== undefined ? graphBaseData1.data_y : undefined
              }
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値Y'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={dataCubeExport.data_y}
              exportFields={dataCubeExport_column_y_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブY座標')}`}
              // mapName={props.searchValue.map}
              onClick={getDataByData1y}
              zoomChartId={'zoom-chart-7'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-7')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-7')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {graphData1YSelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData1YSelectDate
                : `${t('選択シリアル')}:` + graphData1YSelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData1YSelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <></>
          )}
          <LFCChartsBarYGap
            title={`${t('Y座標誤差')} ${t('分布')}`}
            source={dataCubeY_SP}
            extraDatas={data1MaxAvgMin.data_y}
            exportData={dataCubeY_SP}
            exportFields={dataCubeY_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('キューブY座標')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2clickY}
            zoomChartId={'zoom-chart-8'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-8')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-8')}
            zoomChartsClose={() => setZoomChartOpen('')}
            yLineMin={dataY_LineMin}
            yLineMax={dataY_LineMax}
            yRenge={dataY_Renge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-9'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti4
                title={t('Y座標ばらつき推移')}
                source={dataCubeY_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData1.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeY}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブY座標'
                )}`}
                zoomChartId={'zoom-chart-9'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-9')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-9')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch4
                title={t('Y座標ばらつき推移')}
                source={dataCubeY_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData1.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeY}
                exportFields={exportData3_column_cube_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブY座標'
                )}`}
                zoomChartId={'zoom-chart-9'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-9')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-9')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_y.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_y.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d3)} mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d7)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d12)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_y.d14)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_y.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d8)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_y.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d9)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_y.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d5)} mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d10)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_y.d13)} mm
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container spacing={2} key={'graphSetType1z'}>
        <Grid item xs={12} md={7}>
          {props.searchValue.searchType !== 0 ? (
            <LFCChartsBoxWithLine4
              title={`${t('Z座標誤差')} ${t('推移')}`}
              source={graphBaseData1.data_z}
              x={{dsColumn: '', type: 'category'}}
              y={{dsColumn: '', type: 'value'}}
              exportData={dataCubeExport.data_z}
              exportFields={dataCubeExport_column_z}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブZ座標')}`}
              height={'35vh'}
              top={'10%'}
              bottom={'30%'}
              // mapName={props.searchValue.map}
              onClick={getDataWithDate1z}
              xlist={date_calc()}
              zoomChartId={'zoom-chart-10'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-10')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-10')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          ) : (
            <LFCChartsLineHoleCompareCube
              title={`${t('Z座標誤差')} ${t('推移')}`}
              source={
                graphBaseData1.data_z.data_value !== undefined ? graphBaseData1.data_z : undefined
              }
              x={{dsColumn: 'id'}}
              yAxis={{
                type: 'line',
                name: t('計測値Z'),
                dsColumn: 'data_value'
              }}
              color={['rgb(106,113,255)']}
              exportData={dataCubeExport.data_z}
              exportFields={dataCubeExport_column_z_type0}
              exportFilename={`HoleCompare(${t('穴位置')})_${t('推移')}_${t('キューブZ座標')}`}
              // mapName={props.searchValue.map}
              onClick={getDataByData1z}
              zoomChartId={'zoom-chart-10'}
              zoomedChartId={zoomChartOpen}
              zoomStatus={zoomChartsCheck('zoom-chart-10')}
              zoomCharts={() => setZoomChartOpen('zoom-chart-10')}
              zoomChartsClose={() => setZoomChartOpen('')}
            />
          )}
        </Grid>
        <Grid item xs={12} md={5}>
          {graphData1ZSelectDate !== '' ? (
            <>
              {props.searchValue.searchType !== 0
                ? `${t('選択日')}:` + graphData1ZSelectDate
                : `${t('選択シリアル')}:` + graphData1ZSelectDate}
              <LFCButton
                size={'small'}
                className={classes.mrgLeft}
                onClick={() => setGraphData1ZSelectDate('')}
              >
                {t('選択解除')}
              </LFCButton>
            </>
          ) : (
            <div style={{height: '5px'}}></div>
          )}
          <LFCChartsBarZGap
            title={`${t('Z座標誤差')} ${t('分布')}`}
            source={dataCubeZ_SP}
            extraDatas={data1MaxAvgMin.data_z}
            exportData={dataCubeZ_SP}
            exportFields={dataCubeZ_SP_column}
            exportFilename={`HoleCompare(${t('穴位置')})_${t('分布')}_${t('キューブZ座標')}`}
            xAxisName={`(${t('個')})`}
            height={'30vh'}
            // top={"10%"}
            bottom={'35%'}
            onClick={g2clickZ}
            zoomChartId={'zoom-chart-11'}
            zoomedChartId={zoomChartOpen}
            zoomStatus={zoomChartsCheck('zoom-chart-11')}
            zoomCharts={() => setZoomChartOpen('zoom-chart-11')}
            zoomChartsClose={() => setZoomChartOpen('')}
            zLineMin={dataZ_LineMin}
            zLineMax={dataZ_LineMax}
            zRenge={dataZ_Renge}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <div id={'zoom-chart-12'}>
            {props.searchValue.searchType !== 0 ? (
              <LFCChartsLineMulti4
                title={t('Z座標ばらつき推移')}
                source={dataCubeZ_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData1.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeZ}
                exportFields={exportData3_column}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブZ座標'
                )}`}
                zoomChartId={'zoom-chart-12'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-12')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-12')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            ) : (
              <LFCChartsLineMultiBySearch4
                title={t('Z座標ばらつき推移')}
                source={dataCubeZ_XYZ}
                xData={
                  props.searchValue.searchType !== 0
                    ? date_calc()
                    : props.graphData1.map((a: any, index: number) => index + 1)
                }
                color={['rgb(106,113,255)']}
                exportData={exportDataCubeZ}
                exportFields={exportData3_column_cube_type0}
                exportFilename={`HoleCompare(${t('穴位置')})_${t('ばらつき')}_${t(
                  'キューブZ座標'
                )}`}
                zoomChartId={'zoom-chart-12'}
                zoomedChartId={zoomChartOpen}
                zoomStatus={zoomChartsCheck('zoom-chart-12')}
                zoomCharts={() => setZoomChartOpen('zoom-chart-12')}
                zoomChartsClose={() => setZoomChartOpen('')}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={5}>
          <TableContainer
            component={Paper}
            className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
          >
            <Table sx={{minWidth: 600}} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {t('規格')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_z.d2}</Box>
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('実績')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('傾向ばらつき管理')}
                  </TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('工程能力')}
                    <Box sx={{fontSize: '8px'}}>{data1MaxAvgMin.data_z.d15}</Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">{t('上限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d3)} mm
                  </TableCell>
                  <TableCell align="center">{t('最大')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d7)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均+3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d12)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cp')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_z.d14)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('目標')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_z.d1)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d8)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('標準偏差σ')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_z.d11)} mm
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('中央')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d9)} mm
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {t('Cpk')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2}>
                    {toLocalStringEx(data1MaxAvgMin.data_z.d16)}{' '}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('下限')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d5)} mm
                  </TableCell>
                  <TableCell align="center">{t('最小')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d10)} mm
                  </TableCell>
                  <TableCell align="center">{t('平均-3σ')}</TableCell>
                  <TableCell align="center">
                    {toLocalStringEx(data1MaxAvgMin.data_z.d13)} mm
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/*分布グラフクリックした際の一覧表*/}
      <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Hole Compare 穴位置')}:${t(
          '詳細データ'
        )}`}</LFCDialogTitle>
        <DialogContent>
          <LFCDataGridSimple
            columns={listHeaders}
            rows={g2clickData}
            height="80vh"
            exportFilename={`HoleCompare(${t('穴位置')})_${t('詳細データ')}`}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PartsCubeComponent;
