import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  y?: {
    type: 'time' | 'value' | 'category';
    dsColumn: string;
  };
  yAxisName?: string;
  series?: string;
  stacked?: boolean; //付与する事で積み上げになります
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  top?: string;
  onClick?: any;
  mapName?: string;
  zoomCharts?: any;
  zoomChartsClose?: any;
  zoomChartId?: string;
  zoomedChartId?: string;
  zoomStatus?: boolean;
  xlist: any;
  compareType: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBoxWithLine2 = (props: Props) => {
  const {t} = useTranslation();
  let data_target_list_first: any = [];
  let data_lsl_list_first: any = [];
  let data_usl_list_first: any = [];

  if (
    props.source !== undefined &&
    props.source.data_target !== undefined &&
    props.source.data_target.length > 0
  ) {
    props.source.data_target.forEach((a: any) => {
      if (a !== undefined && a.length > 0) {
        if (a[0] === a[a.length - 1]) {
          data_target_list_first.push(a[0]);
        } else if (a[0] > a[a.length - 1]) {
          data_target_list_first.push(a[0]);
        } else if (a[0] < a[a.length - 1]) {
          data_target_list_first.push(a[a.length - 1]);
        }
      }
    });
  }

  if (
    props.source !== undefined &&
    props.source.data_lsl !== undefined &&
    props.source.data_lsl.length > 0
  ) {
    props.source.data_lsl.forEach((a: any, index: number) => {
      if (a !== undefined && a.length > 0) {
        if (a[0] === a[a.length - 1]) {
          data_lsl_list_first.push(a[0]);
        } else if (a[0] > a[a.length - 1]) {
          data_lsl_list_first.push(a[0]);
        } else if (a[0] < a[a.length - 1]) {
          data_lsl_list_first.push(a[a.length - 1]);
        }
      }
    });
  }

  if (
    props.source !== undefined &&
    props.source.data_usl !== undefined &&
    props.source.data_usl.length > 0
  ) {
    props.source.data_usl.forEach((a: any, index: number) => {
      if (a !== undefined && a.length > 0) {
        if (a[0] === a[a.length - 1]) {
          data_usl_list_first.push(a[0]);
        } else if (a[0] > a[a.length - 1]) {
          data_usl_list_first.push(a[0]);
        } else if (a[0] < a[a.length - 1]) {
          data_usl_list_first.push(a[a.length - 1]);
        }
      }
    });
  }

  const makeBoxData = () => {
    //ボックスデータの算出
    const CalcQuartile = (data: any) => {
      let max = Math.max(...data.map((a: any) => Number(a)));
      let min = Math.min(...data.map((a: any) => Number(a)));

      const dataQ1 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.25;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ2 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.5;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };

      const dataQ3 = () => {
        let data_s: any = data.sort((a: number, b: number) => a - b);
        let pos = (data_s.length - 1) * 0.75;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data_s[base + 1] !== undefined) {
          return data_s[base] + rest * (data_s[base + 1] - data_s[base]);
        } else {
          return data_s[base];
        }
      };
      // [min,  Q1,  median (or Q2),  Q3,  max]
      return [min, dataQ1(), dataQ2(), dataQ3(), max];
    };

    let tmp: any = [];
    props.xlist.forEach((a: string) => {
      if (props.source.x_list.find((k: string) => k === a)) {
        props.source.x_list.find((b: string, index: number) => {
          if (b === a) {
            tmp.push(props.source.data_value[index]);
          }
        });
      } else {
        tmp.push([]);
      }
    });

    let tmpOutPut: any = [];
    tmp.forEach((a: any) => {
      if (!isEmpty(a)) {
        tmpOutPut.push(CalcQuartile(a));
      } else {
        tmpOutPut.push([]);
      }
    });
    setBoxData(tmpOutPut);
  };

  const [boxData, setBoxData] = useState([]);
  const [exportData, setExportData] = useState([]);
  useEffect(() => {
    if (props.source.x_list !== undefined && !isEmpty(props.source.x_list)) {
      makeBoxData();
    }
  }, [props]);

  // work: '機種',
  //   deviceid: '検査装置',
  //   map: '検査部位',
  //   ref_job_title: t("ジョブタイトル"),
  //   parameter_name: t("検査項目"),
  //   for_export: '検査日時',
  //   d0: '最大',
  //   d1: 'Q3',
  //   d2: '中央(orQ2)',
  //   d3: 'Q1',
  //   d4: '最小',
  //   d5: '群平均',
  //   d6: '群中央',
  //   d7: '目標',
  //   d8: '上限',
  //   d9: '下限',
  //   d10: '中央',
  //   d11: '平均',
  //   d12: '平均＋3σ',
  //   d13: '平均－3σ',

  useEffect(() => {
    let tmp: any = [];
    if (!isEmpty(boxData) && !isEmpty(props.xlist) && !isEmpty(props.source)) {
      props.xlist.forEach((a: any, index: number) => {
        tmp.push({
          work: String(Array.from(new Set(props.exportData.map((a: any) => a.work)))),
          deviceid: String(Array.from(new Set(props.exportData.map((a: any) => a.deviceid)))),
          map: String(Array.from(new Set(props.exportData.map((a: any) => a.map)))),
          ref_job_title: String(
            Array.from(new Set(props.exportData.map((a: any) => a.ref_job_title)))
          ),
          parameter_name: String(
            Array.from(new Set(props.exportData.map((a: any) => a.parameter_name)))
          ),
          for_export: a,
          d0: boxData[index][4] !== undefined ? boxData[index][4] : '',
          d1: boxData[index][3] !== undefined ? boxData[index][3] : '',
          d2: boxData[index][2] !== undefined ? boxData[index][2] : '',
          d3: boxData[index][1] !== undefined ? boxData[index][1] : '',
          d4: boxData[index][0] !== undefined ? boxData[index][0] : '',
          d5: props.source.data_avg_mel[index],
          d6: props.source.data_middle_mel[index],
          d7: props.source.data_target[index],
          d8: props.source.upper_limit[index],
          d9: props.source.lower_limit[index],
          d10: props.source.data_middle[index],
          d11: props.source.data_avg[index],
          d12: props.source.data_lcl[index],
          d13: props.source.data_ucl[index]
        });
      });
      setExportData(tmp);
    } else if (!isEmpty(boxData) && isEmpty(props.xlist)) {
      props.exportData.forEach((a: any, index: number) => {
        tmp.push({
          work: a.work,
          deviceid: a.deviceid,
          map: a.map,
          for_export: a.lq_time,
          work_length: a.work_length,
          serial: a.serial
        });
      });
      setExportData(tmp);
    }
  }, [boxData]);

  if (props.source !== undefined && props.source.data_value !== undefined) {
    return (
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            data: [
              '箱ひげ図',
              '群平均',
              '群中央',
              props.source.data_target[0] !== '-' ? '目標' : '',
              !props.compareType ? '上限' : '',
              props.source.lower_limit !== undefined && props.source.lower_limit[0] !== '-'
                ? '下限'
                : '',
              '中央',
              '平均',
              !props.compareType ? '平均+3σ' : '',
              props.source.data_lsl[0] !== '-' ? '平均-3σ' : ''
            ],
            selected: {
              箱ひげ図: true,
              群平均: true,
              群中央: false,
              目標: true,
              上限: true,
              下限: true,
              中央: false,
              平均: false,
              '平均+3σ': false,
              '平均-3σ': false
            },
            formatter: (prm: any) => {
              return t(prm);
            },
            itemWidth: 11,
            textStyle: {
              fontSize: 10
            },
            icon: 'circle'
          },
          tooltip: {
            trigger: 'axis',
            position: (point: any, params: any, dom: any, rect: any, size: any) => {
              // fixed at top
              return [point[0] + 20, '10%'];
            },
            formatter: (params: any) => {
              let showData: any = [];
              if (params[0].seriesType === 'boxplot') {
                // [min,  Q1,  median (or Q2),  Q3,  max]
                showData +=
                  params[0].marker +
                  params[0].name +
                  '<br/>' +
                  t('最大') +
                  ':' +
                  (!isNaN(params[0].value[5]) ? params[0].value[5].toFixed(3) : t('なし')) +
                  '<br/>' +
                  'Q3：' +
                  (!isNaN(params[0].value[4]) ? params[0].value[4].toFixed(3) : t('なし')) +
                  '<br/>' +
                  t('中央') +
                  ' (or Q2)：' +
                  (!isNaN(params[0].value[3]) ? params[0].value[3].toFixed(3) : t('なし')) +
                  '<br/>' +
                  'Q1：' +
                  (!isNaN(params[0].value[2]) ? params[0].value[2].toFixed(3) : t('なし')) +
                  '<br/>' +
                  t('最小') +
                  ':' +
                  (!isNaN(params[0].value[1]) ? params[0].value[1].toFixed(3) : t('なし')) +
                  '<br/>-----<br/>';
              }

              params.forEach((a: any, index: number) => {
                if (a.componentSubType === 'line') {
                  showData += !isNaN(a.value)
                    ? a.marker + t(a.seriesName) + ':' + Number(a.value).toFixed(3) + '<br/>'
                    : '';
                }
              });
              return showData;
            }
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: props.bottom,
            top: props.top
          },
          xAxis: {
            type: 'category',
            // boundaryGap: true,
            // nameGap: 10,
            data: props.source ? props.source.x_list : false,
            splitLine: {
              show: true
            },
            axisLabel: {
              // interval: 0,
              rotate: '15',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName !== undefined ? props.yAxisName : '',
            max: (value: any) => {
              return Math.ceil(value.max);
            },
            min: (value: any) => {
              return Math.floor(value.min);
            },
            // interval: 1,
            // minInterval: 1,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              fontSize: 10
            }
          },
          series: [
            {
              name: '箱ひげ図',
              type: 'boxplot',
              data: boxData,
              barWidth: '100%'
            },
            {
              name: '目標',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              // showAllSymbol: true,
              data: props.source ? props.source.data_target : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[0]
            },
            {
              name: '群平均',
              type: 'line',
              legendHoverLink: true,
              showSymbol: true,
              showAllSymbol: true,
              data: props.source ? props.source.data_avg_mel : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[1]
            },
            {
              name: '中央',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              data: props.source ? props.source.data_middle : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[2]
            },
            {
              name: '群中央',
              type: 'line',
              legendHoverLink: true,
              showSymbol: true,
              showAllSymbol: true,
              data: props.source ? props.source.data_middle_mel : [],
              lineStyle: {
                width: 1
              },
              color: colorPallet[3]
            },
            {
              name: '平均',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              data: props.source ? props.source.data_avg : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[4]
            },
            {
              name: '平均+3σ',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              data: props.source ? props.source.data_lcl : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[5]
            },
            {
              name: '平均-3σ',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              data: props.source ? props.source.data_ucl : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[6]
            },
            {
              name: '上限',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              showAllSymbol: true,
              data: props.source ? props.source.upper_limit : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[7]
            },
            {
              name: '下限',
              type: 'line',
              legendHoverLink: true,
              showSymbol: false,
              showAllSymbol: true,
              data: props.source ? props.source.lower_limit : false,
              lineStyle: {
                width: 1
              },
              color: colorPallet[8]
            }
          ]
        }}
        exportData={exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        onEvents={{
          click: (params: any) => {
            if (params !== undefined && props.onClick != null) {
              params.mapName = props.mapName;
              props.onClick(params.name);
            }
          }
        }}
        zoomCharts={props.zoomCharts ? props.zoomCharts : undefined}
        zoomChartsClose={props.zoomChartsClose ? props.zoomChartsClose : undefined}
        zoomChartId={props.zoomChartId ? props.zoomChartId : undefined}
        zoomStatus={props.zoomChartId === props.zoomedChartId}
      />
    );
  } else {
    return <></>;
  }
};
export default LFCChartsBoxWithLine2;
