import {DatePicker, DateView} from '@mui/x-date-pickers';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useMySettings} from '_contexts/MySettingsProvider';
import {ChangeEvent} from 'react';

interface Props {
  label: string;
  value: any;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  format: string;
  views: DateView[];
}

const DateTimePicker = (props: Props) => {
  const handleInputChange = (target: ChangeEvent<HTMLInputElement>) => {
    props.onChange(target);
  };
  const mySettings = useMySettings();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={mySettings.locale}>
      <DatePicker
        value={props.value}
        label={props.label}
        format={props.format}
        onChange={target => handleInputChange(target)}
        views={props.views}
        slotProps={{textField: {size: 'small'}}}
      />
    </LocalizationProvider>
  );
};
export default DateTimePicker;
