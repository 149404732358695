import {Grid} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCChartsLineLRobotCompare from '_components/charts/LFCChartsLineLrobotCompare';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import {distinct, getLFCData, handleInputChange} from '_logics/LFCUtil';
import FittingDig from './FittingDig';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {
  PAGEID_LR_JOBTRENDANALYSIS,
  LOCATIONID_LR_JOBTRENDANALYSIS_FITTING
} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import {useTranslation} from 'react-i18next';

const JobTrendAnalysisFittingPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  const [data60022, setData60022] = useState([]);
  const [mapList, setMapList] = useState<any>([]);

  const [open, setOpen] = React.useState(false);
  const [openData, setOpenData] = React.useState({
    x: '',
    y: 'Fitting',
    searchValue: {
      select_datetime_from: '',
      select_datetime_to: ''
    }
  });
  const handleClose = () => {
    setOpenData({
      x: '',
      y: 'Fitting',
      searchValue: {
        select_datetime_from: '',
        select_datetime_to: ''
      }
    });
    setOpen(false);
  };

  const initialSearchValue = {
    map: '',
    page_type: '',
    selectType: 0,
    select_datetime_from: dayjs().add(-7, 'd').format('YYYY-MM-DDT00:00'),
    select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm')
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LR_JOBTRENDANALYSIS,
    LOCATIONID_LR_JOBTRENDANALYSIS_FITTING
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, selectType: event.target.value});
  };

  let selectType: any = [
    {name: t('ALL'), value: 0},
    {name: t('月毎'), value: 1},
    {name: t('日毎'), value: 2},
    {name: t('時間毎'), value: 3}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const doSearch = async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    await setStartProcess(true);
    switch (searchValue.selectType) {
      case 0:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60022,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60022,
          name: `LROBOT_${t('ジョブ傾向分析')}_Fitting:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 1:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60122,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60022,
          name: `LROBOT_${t('ジョブ傾向分析')}_Fitting:${t('月単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 2:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60222,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60022,
          name: `LROBOT_${t('ジョブ傾向分析')}_Fitting:${t('日単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 3:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60322,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60022,
          name: `LROBOT_${t('ジョブ傾向分析')}_Fitting:${t('時間単位')}`,
          cancelToken: source.token,
          t
        });
        break;
      default:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60022,
          parameters: {
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to
          },
          ds_state: setData60022,
          name: `LROBOT_${t('ジョブ傾向分析')}_Fitting:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
    }
    await setStartProcess(false);
  };

  useEffect(() => {
    setMapList(distinct(data60022, 'map').sort());
  }, [data60022]);

  let data60022_column = {
    map: t('マップ'),
    lq_time: t('日付'),
    difference_x: 'difference_x',
    difference_y: 'difference_y',
    difference_z: 'difference_z'
  };

  const gClick = (data: any) => {
    setOpenData({
      x: data.mapname,
      y: 'Fitting',
      searchValue: {
        select_datetime_from: searchValue.select_datetime_from,
        select_datetime_to: searchValue.select_datetime_to
      }
    });
    setOpen(true);
  };

  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    setCondition([
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'selectType',
        value: searchValue.selectType,
        valueLabel:
          String(searchValue.selectType) === '0'
            ? ALL
            : String(searchValue.selectType) === '1'
              ? t('月毎')
              : String(searchValue.selectType) === '2'
                ? t('日毎')
                : String(searchValue.selectType) === '3'
                  ? t('時間毎')
                  : '',
        colName: t('集計単位'),
        colWidth: 150
      }
    ]);

    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
  };

  return (
    <GenericTemplate title={`${t('menu.tab_name.L-Rジョブ傾向分析')}:Fitting`}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form ref={formRef}>
            <LFCFormRowGroup>
              <LFCDatetimePicker
                name={'select_datetime_from'}
                label={t('対象期間From')}
                value={searchValue.select_datetime_from}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCDatetimePicker
                name={'select_datetime_to'}
                label={t('対象期間To')}
                value={searchValue.select_datetime_to}
                onChange={event => {
                  handleInputChange(event, searchValue, setSearchValue);
                }}
                required
              />
              <LFCSelectForm
                name={'selectType'}
                label={t('集計単位')}
                value={searchValue.selectType}
                id={'sl1'}
                onChange={handleChange}
                selectItem={selectType}
              />
              <LFCButton color="primary" onClick={() => doSearch()}>
                {t('検索')}
              </LFCButton>
              <LFCSavedCondition
                open={openSavedCondition}
                pageId={PAGEID_LR_JOBTRENDANALYSIS}
                locationNo={LOCATIONID_LR_JOBTRENDANALYSIS_FITTING}
                onLoad={onLoadSavedCondition}
                onSelect={onSelectSavedCondition}
                onClose={() => setOpenSavedCondition(false)}
                conditions={condition}
              />
            </LFCFormRowGroup>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {mapList.map((map_name: string, index: number) => {
              return (
                <Grid item xs={12} sm={6} key={index}>
                  <LFCChartsLineLRobotCompare
                    title={map_name}
                    source={data60022}
                    series={map_name}
                    color={['rgb(106,113,255)']}
                    exportData={data60022}
                    exportFields={data60022_column}
                    exportFilename={map_name}
                    onClick={gClick}
                    typeName={'Fitting-a'}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <FittingDig open={open} onClose={handleClose} datas={openData} />
    </GenericTemplate>
  );
};

export default JobTrendAnalysisFittingPage;
