import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {fmtRoundOffZero} from '_logics/LFCFormatUtil';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  x: {
    dsColumn: string;
  };
  y: {
    type: string;
    name: any;
    dsColumn: any;
    series: any;
    max?: number | string;
  };
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: any | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  graphic?: any;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLineMonthlyResults = (props: Props) => {
  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: [
            {
              text: props.title,
              bottom: 0,
              top: 233,
              left: 10,
              textStyle: {
                lineHeight: 20,
                fontSize: 10,
                fontWeight: 'normal',
                formatter: function (value: any) {
                  return '{table|' + value + '}';
                },
                rich: {
                  table: {
                    align: 'center'
                  }
                }
              }
            }
          ],
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          // legend: {
          //   type: "scroll",
          //   bottom: 0,
          //   itemWidth: 10,
          // },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow',
              label: {
                formatter: (prm: any) => {
                  if (prm.seriesData[0].value) {
                    return (
                      new Date(prm.seriesData[0].value.working_date).getFullYear() +
                      '/' +
                      ('0' + (new Date(prm.seriesData[0].value.working_date).getMonth() + 1)).slice(
                        -2
                      ) +
                      '/' +
                      ('0' + new Date(prm.seriesData[0].value.working_date).getDate()).slice(-2)
                    );
                  }
                }
              }
            }
          },
          dataZoom: [
            {
              type: 'inside'
              // minValueSpan: 3600 * 24 * 1000 * 7, // 最小ZOOM 7日分
            }
          ],
          color: props.color ? props.color : null,
          dataset: {
            source: props.source
          },
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                interval: 0,
                formatter: function (value: any, index: number) {
                  let this_year_month_source = props.source.filter(
                    (item: any) => item.day === value
                  );
                  let aim_line_productivity: string = '';
                  let line_productivity: string = '';
                  let aim_pph: string = '';
                  let pph: string = '';
                  let stop_time: string = '';
                  if (this_year_month_source !== undefined) {
                    aim_line_productivity = '';
                    line_productivity = '';
                    aim_pph = '';
                    pph = '';
                    stop_time = '';
                  }
                  if (
                    this_year_month_source[0] !== undefined &&
                    this_year_month_source[0].aim_line_productivity === null
                  ) {
                    aim_line_productivity = '';
                    line_productivity = '';
                    aim_pph = '';
                    pph = '';
                    stop_time = '';
                  }
                  if (
                    this_year_month_source[0] !== undefined &&
                    this_year_month_source[0].aim_line_productivity !== null
                  ) {
                    aim_line_productivity = fmtRoundOffZero(
                      this_year_month_source[0].aim_line_productivity
                    );
                    line_productivity = fmtRoundOffZero(
                      this_year_month_source[0].line_productivity
                    );
                    aim_pph = fmtRoundOffZero(this_year_month_source[0].aim_pph);
                    pph = fmtRoundOffZero(this_year_month_source[0].pph);
                    stop_time = fmtRoundOffZero(this_year_month_source[0].stop_time);
                  }

                  if (props.y.dsColumn[0] === 'line_productivity') {
                    return (
                      '{table|' +
                      value +
                      '}\n{table|' +
                      aim_line_productivity +
                      '}\n{table|' +
                      line_productivity +
                      '}'
                    );
                  }
                  if (props.y.dsColumn[0] === 'pph') {
                    return '{table|' + value + '}\n{table|' + aim_pph + '}\n{table|' + pph + '}';
                  }

                  if (props.y.dsColumn[0] === 'stop_time') {
                    return '{table|' + value + '}\n{table|' + stop_time + '}';
                  }
                },
                rich: {
                  table: {
                    top: 0,

                    lineHeight: 22,
                    align: 'center'
                  }
                }
              }
            }
          ],
          yAxis: [
            {
              type: 'value',
              name: props.y.name,
              axisLine: {
                show: false
              },
              splitLine: {
                show: true,
                color: '#778560'
              },
              max: props.y.max ? props.y.max : null
            }
          ],
          series: [
            {
              type: props.y.type,
              name: props.y.series[0],
              encode: {
                x: props.x.dsColumn,
                y: props.y.dsColumn[0]
              },
              color: props.color[0]
            },
            props.y.series[1]
              ? {
                  type: props.y.type,
                  name: props.y.series[1] ? props.y.series[1] : null,
                  encode: {
                    x: props.x.dsColumn ? props.x.dsColumn : null,
                    y: props.y.dsColumn[1] ? props.y.dsColumn[1] : null
                  },
                  color: props.color[1] ? props.color[1] : null
                }
              : false
          ]
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        graphic={props.graphic}
      />
    </div>
  );
};
export default LFCChartsLineMonthlyResults;
