import {
  createTheme as createThemeV5,
  CssBaseline as CssBaselineV5,
  ThemeProvider as ThemeProviderV5
} from '@mui/material';
import React from 'react';
import {getFontFamily} from '_themes/LFCCommonThemes';

const myThemeV5 = createThemeV5({
  typography: {
    fontFamily: getFontFamily()
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFFDE7'
    }
  }
});

export interface Props {
  children: React.ReactNode;
}

/**
 * LFC Openサイト用テンプレート
 * @param props
 * @returns
 */
const OpenSiteTemplate = (props: Props) => {
  return (
    <ThemeProviderV5 theme={myThemeV5}>
      <CssBaselineV5 />
      <>{props.children}</>
    </ThemeProviderV5>
  );
};

export default OpenSiteTemplate;
