// このオプションコードは、サービスワーカーの登録に使用されます。
// register() is not called by default.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA

/**
 * localhost判定
 */
const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * サービスワーカー登録
 * @param {*} config
 * @returns
 */
export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // URLコンストラクタは、SWをサポートするすべてのブラウザで利用できます。
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      // PUBLIC_URLが、ページが提供されるオリジンとは異なるオリジンにある場合、サービスワーカーは動作しません。
      // これは、アセットの提供にCDNを使用している場合に起こります。
      // see https://github.com/facebook/create-react-app/issues/2374
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        // これは localhost で実行されています。サービスワーカーがまだ存在しているかどうかを確認してみましょう。
        checkValidServiceWorker(swUrl, config);

        // localhostにいくつかの追加ロギングを行い、開発者にサービスワーカー/PWAのドキュメントを示す。
        navigator.serviceWorker.ready.then(() => {
          console.log(
            'このWebアプリは、サービスワーカーによってキャッシュファーストで提供されています。To learn more, visit https://cra.link/PWA'
          );
        });
      } else {
        // localhostではありません。サービスワーカーを登録するだけ
        registerValidSW(swUrl, config);
      }
    });
  }
}

/**
 * サービスワーカーが見つかったかどうかを確認します。見つからない場合は、ページをリロードします。
 * @param {*} swUrl
 * @param {*} config
 */
function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, {
    headers: {'Service-Worker': 'script'}
  })
    .then(response => {
      // サービスワーカーが存在すること、そして本当にJSファイルを取得していることを確認します。
      const contentType = response.headers.get('content-type');
      if (
        response.status === 404 ||
        (contentType != null && contentType.indexOf('javascript') === -1)
      ) {
        // サービスワーカーが見つかりません。おそらく別のアプリだと思います。ページを再読み込みしてください。
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload(true);
            window.location.reload();
          });
        });
      } else {
        // サービスワーカーが見つかりました。通常通りに操作してください。
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('インターネットに接続されていません。アプリはオフラインモードで動作しています。');
    });
}

/**
 * サービスワーカーを登録します。
 * SWの状態遷移：installing(installing) -> [install event] -> installed(waiting) -> (.skipWaiting()) -> activating -> activatated(active)
 * @param {} swUrl
 * @param {*} config
 */
function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      // SWがwaiting状態の場合は、Updateありと判断する。1回キャンセルして戻ってきたときに必要
      if (registration.waiting && config && config.onUpdate) {
        config.onUpdate(registration);
      }

      registration.onupdatefound = () => {
        // アップデートが見つかった時の処理
        const installingWorker = registration.installing; // アップデートのインストール待ちSW取得
        if (installingWorker == null) {
          return;
        }
        installingWorker.onstatechange = () => {
          // アップデートのインストール待ちSWのステータスチェンジ時の処理
          if (installingWorker.state === 'installed') {
            // アップデートのインストール完了時
            if (navigator.serviceWorker.controller) {
              // アップデートは済んでいるが、リフレッシュ or ブラウザクローズで適用待ち。
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // アップデートしたSWが正しく適用されている。
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch(error => {
      console.error('Error during service worker registration:', error);
    });
}

/**
 * サービスワーカー削除
 */
export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister();
      })
      .catch(error => {
        console.error(error.message);
      });
  }
}
