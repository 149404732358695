import {Grid} from '@mui/material';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import LFCChartsLineMultiOnsiteMonitor from '_components/charts/LFCChartsLineMultiOnsiteMonitor';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import ProgressBar from '_components/ProgressBar';
import LFCSingleValuePie from '_components/surfaces/LFCSingleValuePie';
import {getLFCData} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import LFCChartsGanttChartOnsiteMonitor from './components/LFCChartsGanttChartOnsiteMonitor';
import PieChartOnsiteMonitor from './components/PieChartOnsiteMonitor';
import {useTranslation} from 'react-i18next';

const OnsiteMonitor = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(true);
  const [listData_50034, setListData_50034] = useState([]);
  const [listData_50035, setListData_50035] = useState([]);
  const [listData_50036, setListData_50036] = useState([]);
  const [listData_50037, setListData_50037] = useState([]);
  const [pie_data10, setPie_data10] = useState<any>([]);
  const [pie_data10_export, setPie_data10_export] = useState<any>([]);
  const [pie_data15, setPie_data15] = useState<any>([]);
  const [pie_data15_export, setPie_data15_export] = useState<any>([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    setStartProcess(true);
    apiFetch().then(() => setStartProcess(false));
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const apiFetch = async () => {
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50034,
      parameters: {},
      ds_state: setListData_50034,
      name: `POC_${t('現場モニタ')}_${t('稼働状況')}_${t('進捗状況PPH')}_${t('生産管理板')}}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50035,
      parameters: {},
      ds_state: setListData_50035,
      name: `POC_${t('現場モニタ')}_${t('サイクルタイム')}`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50036,
      parameters: {},
      ds_state: setListData_50036,
      name: `POC_${t('設備稼働状況')}10`,
      cancelToken: source.token,
      t
    });
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 50037,
      parameters: {},
      ds_state: setListData_50037,
      name: `POC_${t('設備稼働状況')}15`,
      cancelToken: source.token,
      t
    });
  };

  const start_date: string =
    new Date().getFullYear() +
    '-' +
    (new Date().getMonth() + 1) +
    '-' +
    new Date().getDate() +
    ' 00:00:00';

  const sums_pie = (datas: any) => {
    let Working: number = 0;
    let Caution: number = 0;
    let Waiting: number = 0;
    datas.forEach((item: any) => {
      switch (item['machine_status']) {
        case 'Working':
          Working = Working + item['time_diff'];
          break;
        case 'Caution':
          Caution = Caution + item['time_diff'];
          break;
        case 'Waiting':
          Waiting = Waiting + item['time_diff'];
          break;
      }
    });
    return [
      {name: 'Working', value: Working * -1},
      {name: 'Caution', value: Caution * -1},
      {name: 'Waiting', value: Waiting * -1}
    ];
  };

  useEffect(() => {
    setPie_data10(sums_pie(listData_50036));
  }, [listData_50036]);

  useEffect(() => {
    if (pie_data10[0] !== undefined) {
      setPie_data10_export([
        {
          Working: String(pie_data10.filter((item: any) => item.name === 'Working')[0].value * -1),
          Caution: String(pie_data10.filter((item: any) => item.name === 'Caution')[0].value * -1),
          Waiting: String(pie_data10.filter((item: any) => item.name === 'Waiting')[0].value * -1)
        }
      ]);
    }
  }, [pie_data10]);

  useEffect(() => {
    setPie_data15(sums_pie(listData_50037));
  }, [listData_50037]);

  useEffect(() => {
    if (pie_data15[0] !== undefined) {
      setPie_data15_export([
        {
          Working: String(pie_data15.filter((item: any) => item.name === 'Working')[0].value * -1),
          Caution: String(pie_data15.filter((item: any) => item.name === 'Caution')[0].value * -1),
          Waiting: String(pie_data15.filter((item: any) => item.name === 'Waiting')[0].value * -1)
        }
      ]);
    }
  }, [pie_data15]);

  let listData_50034_column = {
    shift_id: 'shift_id',
    begin_time: 'begin_time',
    end_time: 'end_time',
    shift_time: 'shift_time',
    plan_count: 'plan_count',
    plan_total: 'plan_total',
    act_count: 'act_count',
    act_total: 'act_total'
  };

  let listData_50034_view_column: any = ['plan_total', 'act_total'];
  let listData_50034_view_column_name: any = [t('予定'), t('実績')];
  let days_list: any = listData_50034.map((item: any) => item['begin_time']);

  let listData_50034_list_columns: any = [
    {
      field: 'shift_time',
      headerName: t('時間帯'),
      description: t('時間帯'),
      width: 150,
      sortable: false,
      hide: false
    },
    {
      field: 'plan_count',
      headerName: t('計画'),
      description: t('計画'),
      width: 80,
      sortable: false,
      hide: false,
      headerClassName: 'header--aim_pph',
      cellClassName: 'cell--aim_pph',
      align: 'right'
    },
    {
      field: 'act_count',
      headerName: t('実績'),
      description: t('実績'),
      width: 80,
      sortable: false,
      hide: false,
      headerClassName: 'header--line_productivity',
      cellClassName: 'cell--line_productivity',
      align: 'right'
    },
    {
      field: 'act_total',
      headerName: t('累計'),
      description: t('累計'),
      width: 80,
      sortable: false,
      hide: false,
      align: 'right'
    }
  ];

  let listData_50036_column = {
    detection_time_from: `${t('検出時間')}:${t('開始')}`,
    detection_time_to: `${t('検出時間')}:${t('終了')}`,
    machine_id: t('マシンID'),
    machine_status: t('マシンステータス'),
    time_diff: t('継続時間')
  };

  let pie_data_column = {
    Working: 'Working',
    Caution: 'Caution',
    Waiting: 'Waiting'
  };

  return (
    <GenericTemplate title={t('menu.page_name.現場モニタ')}>
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <LFCChartsLineMultiOnsiteMonitor
            title={t('稼働状況')}
            source={listData_50034}
            x={{dsColumn: 'begin_time', data: days_list}}
            y={{
              type: 'line',
              name: listData_50034_view_column_name,
              series: listData_50034_view_column,
              dsColumn: listData_50034_view_column
            }}
            color={['rgb(34,141,226)', 'rgb(234,175,65)']}
            exportData={listData_50034}
            exportFields={listData_50034_column}
            exportFilename={`${t('現場モニタ')}・${t('稼働状況')}`}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          {listData_50034.length > 0 ? (
            <LFCSingleValuePie
              title={t('進捗状況PPH')}
              source={listData_50034.slice(-1)}
              valueKey={'act_total'}
              targetValue={listData_50034.slice(-1)[0]['plan_total']}
              targetComparisonSign="="
            />
          ) : null}
        </Grid>
        <Grid item xs={12} md={4}>
          {t('サイクルタイム')}
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={4}>
              <LFCSingleValuePie
                title={t('最小')}
                source={listData_50035}
                valueKey={'min_cycletime'}
              />
            </Grid>
            <Grid item xs={4}>
              <LFCSingleValuePie
                title={t('平均')}
                source={listData_50035}
                valueKey={'avg_cycletime'}
              />
            </Grid>
            <Grid item xs={4}>
              <LFCSingleValuePie
                title={t('最大')}
                source={listData_50035}
                valueKey={'max_cycletime'}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          {t('生産管理板')}
          <LFCDataGridSimple
            columns={listData_50034_list_columns}
            rows={listData_50034}
            height="55vh"
            rowCount={24}
            rowHeight={34}
            exportFilename={`${t('生産管理板')}`}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9}>
              <LFCChartsGanttChartOnsiteMonitor
                datas={listData_50036}
                deviceIndex={['YW5-5TH-10']}
                exportData={listData_50036}
                exportFields={listData_50036_column}
                exportFilename={`${t('現場モニタ')}_${t('稼働状況')}_10`}
                startDay={start_date}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <PieChartOnsiteMonitor
                data={pie_data10}
                name={'YW5-5TH-10'}
                exportData={pie_data10_export}
                exportFields={pie_data_column}
                exportFilename={`${t('現場モニタ')}_${t('稼働状況')}_${t('比率')}`}
              />
            </Grid>

            <Grid item xs={12} md={9}>
              <LFCChartsGanttChartOnsiteMonitor
                datas={listData_50037}
                deviceIndex={['YW5-5TH-15']}
                exportData={listData_50037}
                exportFields={listData_50036_column}
                exportFilename={`${t('現場モニタ')}_${t('稼働状況')}_15`}
                startDay={start_date}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <PieChartOnsiteMonitor
                data={pie_data15}
                name={'YW5-5TH-15'}
                exportData={pie_data15_export}
                exportFields={pie_data_column}
                exportFilename={`${t('現場モニタ')}_${t('稼働状況')}_15_${t('比率')}`}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default OnsiteMonitor;
