import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {useMySettings} from '_contexts/MySettingsProvider';
import ProgressBar from '_components/ProgressBar';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {distinct, getLFCData, handleInputChange2} from '_logics/LFCUtil';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCChartsBarParameterValue from '_components/charts/LFCChartsBarParameterValue';
import dayjs from 'dayjs';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import {isEmpty} from 'lodash';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCChartsBoxWithLine3 from '_components/charts/LFCChartsBoxWithLine3';
import LFCChartsLineHoleCompare3 from '_components/charts/LFCChartsLineHoleCompare3';
import LFCChartsLineMulti2 from '_components/charts/LFCChartsLineMulti2';
import LFCChartsLineMultiBySearch2 from '_components/charts/LFCChartsLineMultiBySearch2';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    mrgLeft: {
      marginLeft: '16px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  datas: any;
  searchValue: any;
}

const ResultCompareDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [data226, setData226] = useState([]);
  const [data227, setData227] = useState([]);
  const [data228, setData228] = useState([]);
  const [data229, setData229] = useState([]);
  const [data230, setData230] = useState([]);
  const [data208XYZ, setData208XYZ] = useState({});
  const dataMaxAvgMin_init = {
    d1: '', //目標
    d2: '', //目標info
    d3: '', //上限
    d4: '', //上限info
    d5: '', //下限
    d6: '', //下限info
    d7: '', //最大
    d8: '', //平均
    d9: '', //中央
    d10: '', //最小
    d11: '', //標準偏差
    d12: '', //管理限界線1 平均+3a
    d13: '', //管理限界線2　平均-3a
    d14: '', //工程能力1 Cp
    d15: '', //工程能力1info
    d16: '', //工程能力2 Cpk
    d17: '' //工程能力2info
  };
  const export_detail_ResultCompareDetail: any = [
    {
      order: 0,
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: true,
      width: 100
    },
    {
      order: 1,
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('シリアルNo.'),
      sortable: true,
      width: 200
    },
    {
      order: 2,
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('検査日時'),
      width: 200,
      sortable: true
    },
    {
      order: 3,
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位'),
      width: 150,
      sortable: true
    },
    {
      order: 4,
      field: 'ref_job_title',
      headerName: t('ジョブタイトル'),
      description: t('ジョブタイトル'),
      width: 150,
      sortable: true
    },
    {
      order: 5,
      field: 'parameter_name',
      headerName: t('検査項目'),
      description: t('検査項目'),
      width: 150,
      sortable: true
    },
    {
      order: 6,
      field: 'parameter_value',
      headerName: t('計測値'),
      description: t('計測値'),
      width: 150,
      sortable: true
    },
    {
      order: 8,
      field: 'greater_value_limit',
      headerName: t('下限（限度指定）'),
      description: t('下限（限度指定）'),
      width: 150,
      sortable: true,
      align: 'right'
    },
    {
      order: 9,
      field: 'less_value_limit',
      headerName: t('上限　(限度指定)'),
      description: t('上限　(限度指定)'),
      width: 150,
      sortable: true,
      align: 'right'
    },
    {
      order: 10,
      field: 'equal_value',
      headerName: t('目標（±指定）'),
      description: t('目標（±指定）'),
      width: 150,
      sortable: true,
      align: 'right'
    },
    {
      order: 11,
      field: 'equal_threshold',
      headerName: t('目標誤差閾値（±指定）'),
      description: t('目標誤差閾値（±指定）'),
      width: 150,
      sortable: true,
      align: 'right'
    }
  ];
  const [dataMaxAvgMin_type0, setDataMaxAvgMin_type0] = useState(dataMaxAvgMin_init);
  const [dataMaxAvgMin_type1, setDataMaxAvgMin_type1] = useState(dataMaxAvgMin_init);
  const [dataMaxAvgMin_type2, setDataMaxAvgMin_type2] = useState(dataMaxAvgMin_init);
  const [dataMaxAvgMin_type3, setDataMaxAvgMin_type3] = useState(dataMaxAvgMin_init);
  const [dataMaxAvgMin, setDataMaxAvgMin] = useState(dataMaxAvgMin_init);

  const [dataShowList, setDataShowList] = useState([]);

  const [data209SelectDate, setData209SelectDate] = useState('');
  const [graphBaseData0, setGraphBaseData0] = useState({});
  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: [],
    searchType: 2,
    job_title: [],
    parameter_name: []
  });
  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const Quartile = (data: any, q: number) => {
    data = Array_Sort_Numbers(data);
    let pos = (data.length - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if (data[base + 1] !== undefined) {
      return data[base] + rest * (data[base + 1] - data[base]);
    } else {
      return data[base];
    }
  };

  const Array_Sort_Numbers = (inputarray: any) => {
    return inputarray.sort((a: number, b: number) => {
      return a - b;
    });
  };

  useEffect(() => {
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType,
      job_title: props.searchValue.job_title,
      parameter_name: props.searchValue.parameter_name
    });
  }, [props]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setData226([]);
    setDataMaxAvgMin(dataMaxAvgMin_init);
    setGraphBaseData0([]);
    setData227([]);

    setDataMaxAvgMin_type0(dataMaxAvgMin_init);
    setDataMaxAvgMin_type1(dataMaxAvgMin_init);
    setDataMaxAvgMin_type2(dataMaxAvgMin_init);
    setDataMaxAvgMin_type3(dataMaxAvgMin_init);
    setData208XYZ([]);
    setData228([]);
    setData_show([]);
    setData_r_show([]);

    setSearchValue({...searchValue, searchType: Number(e.target.value)});
  };

  const [searchPrams, setSearchPrams] = useState({});
  useEffect(() => {
    setSearchPrams({
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      judge: searchValue.judge === 9 ? null : searchValue.judge,
      map: !isEmpty(searchValue.map) ? '{' + searchValue.map.join(',') + '}' : null,
      job_title: !isEmpty(searchValue.job_title)
        ? '{' + searchValue.job_title.join(',') + '}'
        : null,
      parameter_name: !isEmpty(searchValue.parameter_name)
        ? '{' + searchValue.parameter_name.join(',') + '}'
        : null
    });
  }, [searchValue]);

  const doSearch = async () => {
    setStartProcess(true);
    setData226([]);
    setGraphBaseData0([]);
    setData209SelectDate('');

    setDataMaxAvgMin_type0(dataMaxAvgMin_init);
    setDataMaxAvgMin_type1(dataMaxAvgMin_init);
    setDataMaxAvgMin_type2(dataMaxAvgMin_init);
    setDataMaxAvgMin_type3(dataMaxAvgMin_init);
    setData208XYZ([]);
    setData228([]);
    setData_show([]);
    setData_r_show([]);

    // 推移グラフのデータ、分布グラフの基準値算出用データ
    switch (searchValue.searchType) {
      case 0: // 検査ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 234,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            ...searchPrams
          },
          ds_state: setData226,
          name: 'id-232',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 1: // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 232,
          parameters: {
            select_date_time: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData226,
          name: 'id-232',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 2: // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 226,
          parameters: {
            select_date: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData226,
          name: 'id-226',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 3: // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 233,
          parameters: {
            year_month: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData226,
          name: 'id-233',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
    }

    // データの判定実行：データ件数10件以上、リミット値が単一である
    switch (searchValue.searchType) {
      case 0: // 検査ごと
        setData229([]);
        break;
      case 1: // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 240,
          parameters: {
            year_month_day_hour: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData229,
          name: 'id-240',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 2: // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 229,
          parameters: {
            year_month_day: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData229,
          name: 'id-229',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 3: // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 239,
          parameters: {
            year_month: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData229,
          name: 'id-239',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
    }
  };

  useEffect(() => {
    switch (searchValue.searchType) {
      case 0: // 検査ごと
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 243,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            ...searchPrams
          },
          ds_state: setData228,
          name: 'id-243',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 1: // 時間ごと
        if (data229.length > 0) {
          switch (Array.from(new Set(data229.map((a: any) => a.compare_type)))[0]) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 256,
                parameters: {
                  year_month_day_hour:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day_hour))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-256',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 242,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 257,
                parameters: {
                  year_month_day_hour:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day_hour))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-257',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 242,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 258,
                parameters: {
                  year_month_day_hour:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day_hour))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-258',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 242,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 259,
                parameters: {
                  year_month_day_hour:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day_hour))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-259',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 242,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
          }
        }
        break;
      case 2: // 日ごと
        if (data229.length > 0) {
          switch (Array.from(new Set(data229.map((a: any) => a.compare_type)))[0]) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 255,
                parameters: {
                  year_month_day:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-255',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 230,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 254,
                parameters: {
                  year_month_day:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-254',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 230,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 253,
                parameters: {
                  year_month_day:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-253',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 230,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 228,
                parameters: {
                  year_month_day:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month_day))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-228',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 230,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
          }
        }
        break;
      case 3: // 月ごと
        if (data229.length > 0) {
          switch (Array.from(new Set(data229.map((a: any) => a.compare_type)))[0]) {
            case 0:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 260,
                parameters: {
                  year_month:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-260',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 237,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 1:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 261,
                parameters: {
                  year_month:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-261',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 237,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 2:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 262,
                parameters: {
                  year_month:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-262',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 237,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
            case 3:
              getLFCData({
                snack: enqueueSnackbar,
                sql_id: 263,
                parameters: {
                  year_month:
                    '{' +
                    Array.from(new Set(data229.map((a: any) => a.year_month))).join(',') +
                    '}',
                  ...searchPrams
                },
                ds_state: setData228,
                name: 'id-263',
                cancelToken: source.token,
                t
              })
                .then(() => {
                  // 範囲Rを全権取得のため
                  getLFCData({
                    snack: enqueueSnackbar,
                    sql_id: 237,
                    parameters: {
                      select_date_from: searchValue.select_date_from,
                      select_date_to: searchValue.select_date_to,
                      ...searchPrams
                    },
                    ds_state: setData230,
                    name: 'id-242',
                    cancelToken: source.token,
                    t
                  });
                })
                .then(() => setStartProcess(false));
              break;
          }
        }
        break;
    }
  }, [data229]);

  const [data_show, setData_show] = useState([]);
  const [data_r_show, setData_r_show] = useState([]);
  useEffect(() => {
    let tmp_list: any = [];
    let tmp_list_r: any = [];
    setData_show([]);
    setData_r_show([]);

    if (data228.length > 0) {
      switch (searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (data228.find((b: any) => b.year_month_day_hour === a)) {
              if (data228.filter((b: any) => b.year_month_day_hour === a)[0]['データ数'] >= 10) {
                tmp_list.push(data228.filter((b: any) => b.year_month_day_hour === a)[0]);
              } else {
                tmp_list.push({
                  map: data228[0]['map'],
                  year_month_day: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data228[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data230.find((d: any) => d.year_month_day_hour === a)) {
              tmp_list_r.push(data230.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: data230[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData_show(tmp_list);
          setData_r_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (data228.find((b: any) => b.year_month_day === a)) {
              if (data228.filter((b: any) => b.year_month_day === a)[0]['データ数'] >= 10) {
                tmp_list.push(data228.filter((b: any) => b.year_month_day === a)[0]);
              } else {
                tmp_list.push({
                  map: data228[0]['map'],
                  year_month_day: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data228[0]['map'],
                year_month_day: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data230.find((d: any) => d.year_month_day === a)) {
              if (data230.filter((b: any) => b.year_month_day === a)[0]['データ数'] >= 10) {
                tmp_list_r.push(data230.filter((b: any) => b.year_month_day === a)[0]);
              } else {
                tmp_list_r.push({
                  map: data230[0]['map'],
                  year_month_day: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list_r.push({
                map: data228[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData_show(tmp_list);
          setData_r_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (data228.find((b: any) => b.year_month === a)) {
              if (data228.filter((b: any) => b.year_month === a)[0]['データ数'] >= 10) {
                tmp_list.push(data228.filter((b: any) => b.year_month === a)[0]);
              } else {
                tmp_list.push({
                  map: data228[0]['map'],
                  year_month_day: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data228[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (
              data230.filter((b: any) => b.year_month === a).length > 0 &&
              data230.filter((b: any) => b.year_month === a)[0]['データ数'] >= 10
            ) {
              tmp_list_r.push(data230.filter((b: any) => b.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: data230[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData_show(tmp_list);
          setData_r_show(tmp_list_r);
          break;
      }
    }
  }, [data228, data230]);

  useEffect(() => {
    const checkZero = (a: string | null) => {
      if (a !== null && !isNaN(Number(a))) {
        return Number(a);
      } else {
        return null;
      }
    };

    if (searchValue.searchType !== 0) {
      setData208XYZ([
        {
          data:
            data_show.filter((a: any) => a.標準偏差s).length > 0
              ? data_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: data_show.filter((a: any) => a.標準偏差s).length > 0 ? '標準偏差S' : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            data_r_show.filter((a: any) => a.範囲r).length > 0
              ? data_r_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: data_r_show.filter((a: any) => a.範囲r).length > 0 ? '範囲R' : null
        },
        {
          data:
            data_show.filter((a: any) => a.工程能力cp).length > 0
              ? data_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: data_show.filter((a: any) => a.工程能力cp).length > 0 ? '工程能力Cp' : null
        },
        {
          data:
            data_show.filter((a: any) => a.工程能力cpk).length > 0
              ? data_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: data_show.filter((a: any) => a.工程能力cpk).length > 0 ? '工程能力Cpk' : null
        }
      ]);
    } else {
      setData208XYZ([
        {
          data:
            data228.filter((a: any) => a.移動範囲rs).length > 0
              ? data228.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          map: data228.map((a: any) => a.map),
          lq_time: data228.map((a: any) => a.lq_time),
          name: t('移動範囲Rm')
        }
      ]);
    }
  }, [data_show, data_r_show]);

  const date_calc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return Array.from(new Set(data226.map(({lq_time}) => lq_time))).sort();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (data226.length > 0) {
      // データをcompare_type」別に分ける
      let compareType0_data: any = data226.filter((a: any) => a.compare_type === 0);
      let compareType1_data: any = data226.filter((a: any) => a.compare_type === 1);
      let compareType2_data: any = data226.filter((a: any) => a.compare_type === 2);
      let compareType3_data: any = data226.filter((a: any) => a.compare_type === 3);

      // compareType0_data：データ生成
      if (compareType0_data.length > 0) {
        setDataMaxAvgMin_type0({
          // d1: 0, //目標
          d1: '-',
          //   d2: '', //目標info
          d2: '',
          //   d3: 0, //上限
          d3: '-',
          //   d4: '', //上限info
          d4:
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d5: 0, //下限
          d5: String(
            average(
              distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
            )
          ),
          //   d6: '', //下限info
          d6:
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d7: 0, //最大
          d7: String(
            Math.max(...compareType0_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d8: 0, //平均
          d8: String(average(compareType0_data.map((item: any) => item.parameter_value))),
          //   d9: 0, //中央
          d9: String(
            Quartile(
              compareType0_data.map((a: any) => Number(a.parameter_value)),
              0.5
            )
          ),
          //   d10: 0, //最小
          d10: String(
            Math.min(...compareType0_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d11: 0, //標準偏差
          d11: String(stdev(compareType0_data.map((item: any) => Number(item.parameter_value)))),
          //   d12: 0, //管理限界線1 平均+3σ
          d12: '-',
          //   d13: 0, //管理限界線2 平均-3σ
          d13: String(
            average(compareType0_data.map((item: any) => item.parameter_value)) -
              3 * stdev(compareType0_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d14: 0, //工程能力1 Cp
          d14: '-',
          //   d15: '', //工程能力1info
          d15:
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d16: 0, //工程能力2 Cpk
          d16: String(
            Math.abs(
              average(compareType0_data.map((item: any) => item.greater_value_limit)) -
                average(compareType0_data.map((item: any) => item.parameter_value))
            ) /
              (stdev(compareType0_data.map((item: any) => Number(item.parameter_value))) * 3)
          ),
          //   d17: '', //工程能力2info
          d17:
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType0_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : ''
        });
      } else {
        setDataMaxAvgMin_type0(dataMaxAvgMin_init);
      }

      // compareType1_data：データ生成
      if (compareType1_data.length > 0) {
        setDataMaxAvgMin_type1({
          // d1: 0, //目標
          d1: '-',
          //   d2: '', //目標info
          d2: '',
          //   d3: 0, //上限
          d3: String(average(compareType1_data.map((item: any) => Number(item.less_value_limit)))),
          //   d4: '', //上限info
          d4:
            compareType1_data.map((item: any) => item.less_value_limit).length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d5: 0, //下限
          d5: '-',
          //   d6: '', //下限info
          d6:
            distinct(compareType1_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d7: 0, //最大
          d7: String(
            Math.max(...compareType1_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d8: 0, //平均
          d8: String(average(compareType1_data.map((item: any) => Number(item.parameter_value)))),
          //   d9: 0, //中央
          d9: String(
            Quartile(
              compareType1_data.map((a: any) => Number(a.parameter_value)),
              0.5
            )
          ),
          //   d10: 0, //最小
          d10: String(
            Math.min(...compareType1_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d11: 0, //標準偏差
          d11: String(stdev(compareType1_data.map((item: any) => Number(item.parameter_value)))),
          //   d12: 0, //管理限界線1 平均+3σ
          d12: String(
            average(compareType1_data.map((item: any) => Number(item.parameter_value))) +
              3 * stdev(compareType1_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d13: 0, //管理限界線2 平均-3σ
          d13: '-',
          //   d14: 0, //工程能力1 Cp
          d14: '-',
          //   d15: '', //工程能力1info
          d15: '',
          //   d16: 0, //工程能力2 Cpk
          d16: String(
            Math.abs(
              average(compareType1_data.map((item: any) => Number(item.less_value_limit))) -
                average(compareType1_data.map((item: any) => Number(item.parameter_value)))
            ) /
              (stdev(compareType1_data.map((item: any) => Number(item.parameter_value))) * 3)
          ),
          //   d17: '', //工程能力2info
          d17:
            distinct(compareType1_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType1_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : ''
        });
      } else {
        setDataMaxAvgMin_type1(dataMaxAvgMin_init);
      }

      // compareType2_data：データ生成
      if (compareType2_data.length > 0) {
        setDataMaxAvgMin_type2({
          // d1: 0, //目標
          d1: '-',
          //   d2: '', //目標info
          d2: '',
          //   d3: 0, //上限
          d3: String(average(compareType2_data.map((item: any) => item.less_value_limit))),
          //   d4: '', //上限info
          d4:
            compareType2_data.map((item: any) => item.greater_value_limit).length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d5: 0, //下限
          d5: String(average(compareType2_data.map((item: any) => item.greater_value_limit))),
          //   d6: '', //下限info
          d6:
            distinct(compareType2_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d7: 0, //最大
          d7: String(Math.max(...compareType2_data.map((item: any) => item.parameter_value))),
          //   d8: 0, //平均
          d8: String(average(compareType2_data.map((item: any) => item.parameter_value))),
          //   d9: 0, //中央
          d9: String(
            Quartile(
              compareType2_data.map((a: any) => Number(a.parameter_value)),
              0.5
            )
          ),
          //   d10: 0, //最小
          d10: String(Math.min(...compareType2_data.map((item: any) => item.parameter_value))),
          //   d11: 0, //標準偏差
          d11: String(stdev(compareType2_data.map((item: any) => Number(item.parameter_value)))),
          //   d12: 0, //管理限界線1 平均+3σ
          d12: String(
            average(compareType2_data.map((item: any) => item.parameter_value)) +
              3 * stdev(compareType2_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d13: 0, //管理限界線2 平均-3σ
          d13: String(
            average(compareType2_data.map((item: any) => item.parameter_value)) -
              3 * stdev(compareType2_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d14: 0, //工程能力1 Cp
          d14: String(
            (average(compareType2_data.map((item: any) => item.less_value_limit)) -
              average(compareType2_data.map((item: any) => item.greater_value_limit))) /
              (6 * stdev(compareType2_data.map((item: any) => Number(item.parameter_value))))
          ),
          //   d15: '', //工程能力1info
          d15:
            distinct(compareType2_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType2_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d16: 0, //工程能力2 Cpk
          d16: String(
            Math.min(
              Math.abs(
                average(compareType2_data.map((item: any) => Number(item.less_value_limit))) -
                  average(compareType2_data.map((item: any) => item.parameter_value))
              ) /
                (3 * stdev(compareType2_data.map((item: any) => Number(item.parameter_value)))),
              // |平均 - 下限| / (3 * 標準偏差)
              Math.abs(
                average(compareType2_data.map((item: any) => item.parameter_value)) -
                  average(compareType2_data.map((item: any) => item.greater_value_limit))
              ) /
                (3 * stdev(compareType2_data.map((item: any) => Number(item.parameter_value))))
            )
          ),
          //   d17: '', //工程能力2info
          d17:
            distinct(compareType2_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType2_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : ''
        });
      } else {
        setDataMaxAvgMin_type2(dataMaxAvgMin_init);
      }

      // compareType3_data：データ生成
      if (compareType3_data.length > 0) {
        setDataMaxAvgMin_type3({
          // d1: 0, //目標
          d1: String(average(compareType3_data.map((item: any) => item.equal_value))),
          //   d2: '', //目標info
          d2:
            distinct(compareType3_data, 'equal_value').map((item: any) => Number(item)).length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d3: 0, //上限
          d3: String(
            average(compareType3_data.map((item: any) => Number(item.equal_value))) +
              average(compareType3_data.map((item: any) => Number(item.equal_threshold)))
          ),
          //   d4: '', //上限info
          d4:
            compareType3_data.map((item: any) => item.less_value_limit).length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d5: 0, //下限
          d5: String(
            average(compareType3_data.map((item: any) => Number(item.equal_value))) -
              average(compareType3_data.map((item: any) => Number(item.equal_threshold)))
          ),
          //   d6: '', //下限info
          d6:
            distinct(compareType3_data, 'equal_threshold').map((item: any) => Number(item)).length >
            1
              ? t('※規格複数のため参考値')
              : '',
          //   d7: 0, //最大
          d7: String(Math.max(...compareType3_data.map((item: any) => item.parameter_value))),
          //   d8: 0, //平均
          d8: String(average(compareType3_data.map((item: any) => item.parameter_value))),
          //   d9: 0, //中央
          d9: String(
            Quartile(
              compareType3_data.map((a: any) => Number(a.parameter_value)),
              0.5
            )
          ),
          //   d10: 0, //最小
          d10: String(Math.min(...compareType3_data.map((item: any) => item.parameter_value))),
          //   d11: 0, //標準偏差
          d11: String(stdev(compareType3_data.map((item: any) => Number(item.parameter_value)))),
          //   d12: 0, //管理限界線1 平均+3σ
          d12: String(
            average(compareType3_data.map((item: any) => item.parameter_value)) +
              3 * stdev(compareType3_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d13: 0, //管理限界線2 平均-3σ
          d13: String(
            average(compareType3_data.map((item: any) => item.parameter_value)) -
              3 * stdev(compareType3_data.map((item: any) => Number(item.parameter_value)))
          ),
          //   d14: 0, //工程能力1 Cp
          d14: String(
            (average(compareType3_data.map((item: any) => Number(item.equal_value))) +
              average(compareType3_data.map((item: any) => Number(item.equal_threshold))) -
              (average(compareType3_data.map((item: any) => Number(item.equal_value))) -
                average(compareType3_data.map((item: any) => Number(item.equal_threshold))))) /
              (6 * stdev(compareType3_data.map((item: any) => Number(item.parameter_value))))
          ),
          //   d15: '', //工程能力1info
          d15:
            distinct(compareType3_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType3_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : '',
          //   d16: 0, //工程能力2 Cpk
          d16: String(
            Math.min(
              Math.abs(
                average(compareType3_data.map((item: any) => Number(item.equal_value))) +
                  average(compareType3_data.map((item: any) => Number(item.equal_threshold))) -
                  average(compareType3_data.map((item: any) => item.parameter_value))
              ) /
                (3 * stdev(compareType3_data.map((item: any) => Number(item.parameter_value)))),
              // |平均 - 下限| / (3 * 標準偏差)
              Math.abs(
                average(compareType3_data.map((item: any) => item.parameter_value)) -
                  (average(compareType3_data.map((item: any) => Number(item.equal_value))) -
                    average(compareType3_data.map((item: any) => Number(item.equal_threshold))))
              ) /
                (3 * stdev(compareType3_data.map((item: any) => Number(item.parameter_value))))
            )
          ),
          //   d17: '', //工程能力2info
          d17:
            distinct(compareType3_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1 ||
            distinct(compareType3_data, 'greater_value_limit').map((item: any) => Number(item))
              .length > 1
              ? t('※規格複数のため参考値')
              : ''
        });
      } else {
        setDataMaxAvgMin_type3(dataMaxAvgMin_init);
      }
    }
  }, [data226]);

  const checkNotAnyData = (data0: any, data1: any, data2: any, data3: any) => {
    return data0.length + data1.length + data2.length + data3.length;
  };

  const checkD3D12 = (data0: any, data1: any, data2: any, data3: any) => {
    if (data1 === '' && data2 === '' && data3 === '') {
      return data0;
    } else if (data1 !== '' && data2 === '' && data3 === '') {
      return data1;
    } else if (data1 === '' && data2 !== '' && data3 === '') {
      return data2;
    } else if (data1 === '' && data2 === '' && data3 !== '') {
      return data3;
    } else if (data1 !== '' && data2 !== '' && data3 === '') {
      return String(average([data1, data2]));
    } else if (data1 !== '' && data2 !== '' && data3 !== '') {
      return String(average([data1, data2, data3]));
    } else if (data1 !== '' && data2 === '' && data3 !== '') {
      return String(average([data1, data3]));
    } else if (data1 === '' && data2 !== '' && data3 !== '') {
      return String(average([data2, data3]));
    }
  };

  const checkD5D13 = (data0: any, data1: any, data2: any, data3: any) => {
    if (data0 === '' && data2 === '' && data3 === '') {
      return data1;
    } else if (data0 !== '' && data2 === '' && data3 === '') {
      return data0;
    } else if (data0 === '' && data2 !== '' && data3 === '') {
      return data2;
    } else if (data0 === '' && data2 === '' && data3 !== '') {
      return data3;
    } else if (data0 !== '' && data2 !== '' && data3 === '') {
      return String(average([data0, data2]));
    } else if (data0 !== '' && data2 !== '' && data3 !== '') {
      return String(average([data0, data2, data3]));
    } else if (data0 !== '' && data2 === '' && data3 !== '') {
      return String(average([data0, data3]));
    } else if (data0 === '' && data2 !== '' && data3 !== '') {
      return String(average([data2, data3]));
    }
  };

  const checkD14 = (data0: any, data1: any, data2: any, data3: any) => {
    if (isEmpty(data0) && isEmpty(data1) && isEmpty(data2) && isEmpty(data3)) {
      return '-';
    } else if (isEmpty(data0) && isEmpty(data1) && isEmpty(data2) && !isEmpty(data3)) {
      return data3;
    } else if (isEmpty(data0) && isEmpty(data1) && !isEmpty(data2) && !isEmpty(data3)) {
      return String(average([data2, data3]));
    } else if (isEmpty(data0) && !isEmpty(data1) && !isEmpty(data2) && !isEmpty(data3)) {
      return String(average([data2, data3]));
    } else if (!isEmpty(data0) && !isEmpty(data1) && !isEmpty(data2) && !isEmpty(data3)) {
      return String(average([data2, data3]));
    } else if (!isEmpty(data0) && !isEmpty(data1) && !isEmpty(data2) && isEmpty(data3)) {
      return data2;
    } else if (!isEmpty(data0) && !isEmpty(data1) && isEmpty(data2) && isEmpty(data3)) {
      return '-';
    } else if (!isEmpty(data0) && isEmpty(data1) && isEmpty(data2) && isEmpty(data3)) {
      return '-';
    } else if (isEmpty(data0) && !isEmpty(data1) && isEmpty(data2) && isEmpty(data3)) {
      return '-';
    } else if (isEmpty(data0) && !isEmpty(data1) && !isEmpty(data2) && isEmpty(data3)) {
      return data2;
    } else if (isEmpty(data0) && isEmpty(data1) && !isEmpty(data2) && isEmpty(data3)) {
      return data2;
    } else if (!isEmpty(data0) && isEmpty(data1) && isEmpty(data2) && !isEmpty(data3)) {
      return data3;
    }
  };

  const makeAverage = (data0: any, data1: any, data2: any, data3: any) => {
    let tmp: any = [data0, data1, data2, data3].filter((a: string) => !isEmpty(a));
    return String(average(tmp));
  };

  useEffect(() => {
    if (
      !isEmpty(dataMaxAvgMin_type0) &&
      !isEmpty(dataMaxAvgMin_type1) &&
      !isEmpty(dataMaxAvgMin_type2) &&
      !isEmpty(dataMaxAvgMin_type3)
    ) {
      setDataMaxAvgMin({
        // d1: 0, //目標
        d1: dataMaxAvgMin_type3.d1 !== '' ? dataMaxAvgMin_type3.d1 : '-',
        //   d2: '', //目標info
        d2:
          checkNotAnyData(
            dataMaxAvgMin_type0.d2,
            dataMaxAvgMin_type1.d2,
            dataMaxAvgMin_type2.d2,
            dataMaxAvgMin_type3.d2
          ) > 1
            ? t('※規格複数のため参考値')
            : '',
        //   d3: 0, //上限
        d3: checkD3D12(
          dataMaxAvgMin_type0.d3,
          dataMaxAvgMin_type1.d3,
          dataMaxAvgMin_type2.d3,
          dataMaxAvgMin_type3.d3
        ),
        //   d4: '', //上限info
        d4:
          checkNotAnyData(
            dataMaxAvgMin_type0.d4,
            dataMaxAvgMin_type1.d4,
            dataMaxAvgMin_type2.d4,
            dataMaxAvgMin_type3.d4
          ) > 1
            ? t('※規格複数のため参考値')
            : '',
        //   d5: 0, //下限
        d5: checkD5D13(
          dataMaxAvgMin_type0.d5,
          dataMaxAvgMin_type1.d5,
          dataMaxAvgMin_type2.d5,
          dataMaxAvgMin_type3.d5
        ),
        //   d6: '', //下限info
        d6:
          checkNotAnyData(dataMaxAvgMin_type0.d6, dataMaxAvgMin_type1.d6, '', '') > 1
            ? t('※規格複数のため参考値')
            : '',
        //   d7: 0, //最大
        d7: makeAverage(
          dataMaxAvgMin_type0.d7,
          dataMaxAvgMin_type1.d7,
          dataMaxAvgMin_type2.d7,
          dataMaxAvgMin_type3.d7
        ),
        //   d8: 0, //平均
        d8: makeAverage(
          dataMaxAvgMin_type0.d8,
          dataMaxAvgMin_type1.d8,
          dataMaxAvgMin_type2.d8,
          dataMaxAvgMin_type3.d8
        ),
        //   d9: 0, //中央
        d9: makeAverage(
          dataMaxAvgMin_type0.d9,
          dataMaxAvgMin_type1.d9,
          dataMaxAvgMin_type2.d9,
          dataMaxAvgMin_type3.d9
        ),
        //   d10: 0, //最小
        d10: makeAverage(
          dataMaxAvgMin_type0.d10,
          dataMaxAvgMin_type1.d10,
          dataMaxAvgMin_type2.d10,
          dataMaxAvgMin_type3.d10
        ),
        //   d11: 0, //標準偏差
        d11: makeAverage(
          dataMaxAvgMin_type0.d11,
          dataMaxAvgMin_type1.d11,
          dataMaxAvgMin_type2.d11,
          dataMaxAvgMin_type3.d11
        ),
        //   d12: 0, //管理限界線1
        d12: checkD3D12(
          dataMaxAvgMin_type0.d12,
          dataMaxAvgMin_type1.d12,
          dataMaxAvgMin_type2.d12,
          dataMaxAvgMin_type3.d12
        ),
        //   d13: 0, //管理限界線2
        d13: checkD5D13(
          dataMaxAvgMin_type0.d13,
          dataMaxAvgMin_type1.d13,
          dataMaxAvgMin_type2.d13,
          dataMaxAvgMin_type3.d13
        ),
        //   d14: 0, //工程能力1 Cp
        d14: checkD14(
          dataMaxAvgMin_type0.d14,
          dataMaxAvgMin_type1.d14,
          dataMaxAvgMin_type2.d14,
          dataMaxAvgMin_type3.d14
        ),
        //   d15: '', //工程能力1info
        d15:
          checkNotAnyData(
            dataMaxAvgMin_type0.d15,
            dataMaxAvgMin_type1.d15,
            dataMaxAvgMin_type2.d15,
            dataMaxAvgMin_type3.d15
          ) > 1
            ? t('※規格複数のため参考値')
            : '',
        //   d16: 0, //工程能力2 Cpk
        d16: makeAverage(
          dataMaxAvgMin_type0.d16,
          dataMaxAvgMin_type1.d16,
          dataMaxAvgMin_type2.d16,
          dataMaxAvgMin_type3.d16
        ),
        //   d17: '', //工程能力2info
        d17:
          checkNotAnyData(
            dataMaxAvgMin_type0.d17,
            dataMaxAvgMin_type1.d17,
            dataMaxAvgMin_type2.d17,
            dataMaxAvgMin_type3.d17
          ) > 1
            ? t('※規格複数のため参考値')
            : ''
      });
    }
  }, [dataMaxAvgMin_type0, dataMaxAvgMin_type1, dataMaxAvgMin_type2, dataMaxAvgMin_type3]);

  const [xLineMin, setXLineMin] = useState(0);
  const [xLineMax, setXLineMax] = useState(0);
  const [xRenge, setXRenge] = useState(0);

  useEffect(() => {
    let tmp_n: number = data226.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }

    // ギャップの小数点以下
    let gap_limit_round: number = 10;

    // 「最大値」d7, 「上限(仕様)d3」 「管理限界線(+3σ) d12」
    let tmp_max: number = 0;
    switch (true) {
      case !isNaN(Number(dataMaxAvgMin.d3)) &&
        !isNaN(Number(dataMaxAvgMin.d7)) &&
        !isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max =
          Math.ceil(
            Math.max(
              Number(dataMaxAvgMin.d3),
              Number(dataMaxAvgMin.d7),
              Number(dataMaxAvgMin.d12)
            ) * gap_limit_round
          ) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d3)) &&
        !isNaN(Number(dataMaxAvgMin.d7)) &&
        isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max =
          Math.ceil(
            Math.max(Number(dataMaxAvgMin.d3), Number(dataMaxAvgMin.d7)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d3)) &&
        isNaN(Number(dataMaxAvgMin.d7)) &&
        isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max = Math.ceil(Math.max(Number(dataMaxAvgMin.d3)) * gap_limit_round) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d3)) &&
        !isNaN(Number(dataMaxAvgMin.d7)) &&
        !isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max =
          Math.ceil(
            Math.max(Number(dataMaxAvgMin.d7), Number(dataMaxAvgMin.d12)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d3)) &&
        !isNaN(Number(dataMaxAvgMin.d7)) &&
        isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max = Math.ceil(Math.max(Number(dataMaxAvgMin.d7)) * gap_limit_round) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d3)) &&
        isNaN(Number(dataMaxAvgMin.d7)) &&
        !isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max =
          Math.ceil(Math.max(Number(dataMaxAvgMin.d12)) * gap_limit_round) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d3)) &&
        isNaN(Number(dataMaxAvgMin.d7)) &&
        !isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max =
          Math.ceil(
            Math.max(Number(dataMaxAvgMin.d3), Number(dataMaxAvgMin.d12)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d3)) &&
        isNaN(Number(dataMaxAvgMin.d7)) &&
        isNaN(Number(dataMaxAvgMin.d12)):
        tmp_max = 0;
        break;
    }
    setXLineMax(tmp_max);

    // 最小値 d10 」「下限(仕様)d5」「管理限界線(-3σ) d13」
    let tmp_min: number = 0;
    switch (true) {
      case !isNaN(Number(dataMaxAvgMin.d5)) &&
        !isNaN(Number(dataMaxAvgMin.d10)) &&
        !isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(
            Math.min(
              Number(dataMaxAvgMin.d10),
              Number(dataMaxAvgMin.d5),
              Number(dataMaxAvgMin.d13)
            ) * gap_limit_round
          ) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d5)) &&
        !isNaN(Number(dataMaxAvgMin.d10)) &&
        isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(
            Math.min(Number(dataMaxAvgMin.d5), Number(dataMaxAvgMin.d10)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d5)) &&
        isNaN(Number(dataMaxAvgMin.d10)) &&
        isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(Math.min(Number(dataMaxAvgMin.d5)) * gap_limit_round) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d5)) &&
        !isNaN(Number(dataMaxAvgMin.d10)) &&
        isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(Math.min(Number(dataMaxAvgMin.d10)) * gap_limit_round) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d5)) &&
        isNaN(Number(dataMaxAvgMin.d10)) &&
        !isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(Math.min(Number(dataMaxAvgMin.d13)) * gap_limit_round) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d5)) &&
        !isNaN(Number(dataMaxAvgMin.d10)) &&
        !isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(
            Math.min(Number(dataMaxAvgMin.d10), Number(dataMaxAvgMin.d13)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case !isNaN(Number(dataMaxAvgMin.d5)) &&
        isNaN(Number(dataMaxAvgMin.d10)) &&
        !isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min =
          Math.floor(
            Math.min(Number(dataMaxAvgMin.d10), Number(dataMaxAvgMin.d13)) * gap_limit_round
          ) / gap_limit_round;
        break;
      case isNaN(Number(dataMaxAvgMin.d5)) &&
        isNaN(Number(dataMaxAvgMin.d10)) &&
        isNaN(Number(dataMaxAvgMin.d13)):
        tmp_min = 0;
        break;
    }
    setXLineMin(tmp_min);

    let tmp_h: number = (tmp_max - tmp_min) / tmp_k;
    let under_zero: number = Math.floor(1 / tmp_h);
    let zero: string = '1';
    for (let i = 0; i < String(under_zero).length; i++) {
      zero = zero + '0';
    }

    let tmp_dot: number = String(tmp_h).indexOf('.');
    let tmp_round: number;
    if (tmp_dot > 0) {
      tmp_round = Math.floor(tmp_h * (tmp_dot * Number(zero))) / (tmp_dot * Number(zero));
    } else {
      tmp_round = Math.floor(tmp_h);
    }
    setXRenge(tmp_round);
  }, [dataMaxAvgMin]);

  useEffect(() => {
    if (xRenge !== 0) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 227,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
          deviceid: !isEmpty(searchValue.deviceid)
            ? '{' + searchValue.deviceid.join(',') + '}'
            : null,
          judge: searchValue.judge === 9 ? null : searchValue.judge,
          map: !isEmpty(searchValue.map) ? '{' + searchValue.map.join(',') + '}' : null,
          x_renge: xRenge,
          job_title: !isEmpty(searchValue.job_title)
            ? '{' + searchValue.job_title.join(',') + '}'
            : null,
          parameter_name: !isEmpty(searchValue.parameter_name)
            ? '{' + searchValue.parameter_name.join(',') + '}'
            : null
        },
        ds_state: setData227,
        name: 'id-227',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  }, [xRenge]);

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType,
      job_title: [],
      parameter_name: []
    });
    setData226([]);
    setData227([]);
    setData208XYZ([]);
    setGraphBaseData0([]);
    setData209SelectDate('');

    setDataMaxAvgMin_type0(dataMaxAvgMin_init);
    setDataMaxAvgMin_type1(dataMaxAvgMin_init);
    setDataMaxAvgMin_type2(dataMaxAvgMin_init);
    setDataMaxAvgMin_type3(dataMaxAvgMin_init);
    setData208XYZ([]);
    setData228([]);
    setData_show([]);
    setData_r_show([]);

    setDataMaxAvgMin(dataMaxAvgMin_init);
    setAutoCompleteReset(true);
  };

  const getDataWithDate = (datas: any) => {
    setData209SelectDate(datas);
  };

  const getDataByData = (datas: any) => {
    setData209SelectDate(datas.data.lq_time);
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  // グラフクリック時
  useEffect(() => {
    let fromDate: any = dayjs(data209SelectDate).format('YYYY-MM-DD');
    let toDate: any = dayjs(data209SelectDate).add(1, 'd').format('YYYY-MM-DD');

    // 検査ごと：検索
    if (data209SelectDate !== '' && searchValue.searchType === 0) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 247,
        parameters: {
          select_lq_date: data209SelectDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData227,
        name: 'id-247',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 年月日　時：検索
    if (data209SelectDate !== '' && searchValue.searchType === 1) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 245,
        parameters: {
          select_lq_date_hour: dayjs(data209SelectDate).format('YYYY-MM-DD HH'),
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData227,
        name: 'id-245',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 年月日：検索
    if (data209SelectDate !== '' && searchValue.searchType === 2) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 231,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData227,
        name: 'id-231',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 年月：検索
    if (data209SelectDate !== '' && searchValue.searchType === 3) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 246,
        parameters: {
          select_date_month: data209SelectDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData227,
        name: 'id-246',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    if (isEmpty(data209SelectDate) && !isEmpty(data226)) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 227,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData227,
        name: 'id-227',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  }, [data209SelectDate]);

  // 穴分布のグラフクリックして、クリックした位置の一覧表を出力
  const g2click = (datas: any) => {
    setStartProcess(true);

    let data_min: number | null = null;
    let data_max: number | null = null;

    if (datas.name !== undefined) {
      data_min = datas.name.split('_')[0];
      data_max = datas.name.split('_')[1];
    }

    let fromDate: any = searchValue.select_date_from;
    let toDate: any = searchValue.select_date_to;
    if (data209SelectDate !== '' && searchValue.searchType === 1) {
      fromDate = dayjs(data209SelectDate).format('YYYY-MM-DD HH');
      toDate = dayjs(data209SelectDate).add(1, 'h').format('YYYY-MM-DD HH');
    }

    if (data209SelectDate !== '' && searchValue.searchType === 2) {
      fromDate = dayjs(data209SelectDate).format('YYYY-MM-DD');
      toDate = dayjs(data209SelectDate).add(1, 'd').format('YYYY-MM-DD');
    }

    if (data209SelectDate === '') {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 250,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          x_renge: xRenge,
          data_index: datas.idx,
          ...searchPrams
        },
        ds_state: setDataShowList,
        name: 'id-250',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    } else {
      // 検査ごと：検索
      if (searchValue.searchType === 0) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 248,
          parameters: {
            select_lq_date: data209SelectDate,
            min_data: data_min,
            max_data: data_max,
            ...searchPrams
          },
          ds_state: setDataShowList,
          name: 'id-248',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 年月日　時：検索
      if (searchValue.searchType === 1) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 251,
          parameters: {
            select_lq_date_hour: data209SelectDate,
            x_renge: xRenge,
            data_index: datas.idx,
            ...searchPrams
          },
          ds_state: setDataShowList,
          name: 'id-251',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 年月日：検索
      if (searchValue.searchType === 2) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 250,
          parameters: {
            select_date_from: fromDate,
            select_date_to: toDate,
            x_renge: xRenge,
            data_index: datas.idx,
            ...searchPrams
          },
          ds_state: setDataShowList,
          name: 'id-250',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
      // 年月：検索
      if (searchValue.searchType === 3) {
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 252,
          parameters: {
            select_date_month: data209SelectDate,
            x_renge: xRenge,
            data_index: datas.idx,
            ...searchPrams
          },
          ds_state: setDataShowList,
          name: 'id-252',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
      }
    }
  };

  const getAllList = () => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 235,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        ...searchPrams
      },
      ds_state: setDataShowList,
      name: 'id-235',
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  };

  useEffect(() => {
    if (dataShowList.length > 0) {
      setDetailListOpen(true);
    }
  }, [dataShowList]);

  useEffect(() => {
    if (!props.open) {
      setData226([]);
      setData227([]);
      setData208XYZ([]);
      setGraphBaseData0([]);
      setData209SelectDate('');
      setDataMaxAvgMin(dataMaxAvgMin_init);
    }
  }, [props.open]);

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  const toLocalStringEx = (params: number | string) => {
    if (isNaN(Number(params))) {
      return params;
    } else {
      return Number(params).toLocaleString() === '-0' ? '0' : Number(params).toLocaleString();
    }
  };

  let data208_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    ref_job_title: t('ジョブタイトル'),
    parameter_name: t('検査項目'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: t('Q3'),
    d2: t('中央(orQ2)'),
    d3: t('Q1'),
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  let data208_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    ref_job_title: t('ジョブタイトル'),
    parameter_name: t('検査項目'),
    for_export: t('検査日時'),
    parameter_value: t('計測結果'),
    serial: t('シリアルナンバー')
  };

  let data208XYZ_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    ref_job_title: t('ジョブタイトル'),
    parameter_name: t('検査項目'),
    for_export: t('検査日時'),
    d0: t('標準偏差'),
    d1: t('範囲(最大最小差)'),
    d2: t('Cp'),
    d3: t('Cpk')
  };

  let data208XYZ_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    ref_job_title: t('ジョブタイトル'),
    parameter_name: t('検査項目'),
    for_export: t('検査日時'),
    d0: t('移動範囲(前結果差)'),
    serial: t('シリアルナンバー')
  };

  let data209_column: any = {
    parameter_value: t('計測値'),
    カウント数: t('カウント数')
  };

  let tmp_data: any = [];
  let tmp_rs: any = [];
  let tmp: any = [];

  useEffect(() => {
    if (data226.length > 0) {
      date_calc().forEach((lq_time_string: string) => {
        switch (searchValue.searchType) {
          case 0: //検査ごと
            tmp = [];
            data226.forEach((item: any) => {
              if (String(item.lq_time) === lq_time_string) {
                tmp.push(Number(item['parameter_value']));
              }
            });
            tmp_data.push(tmp);

            if (tmp_data[0].length > 0) {
              tmp_data[0].forEach((a: any, idx: number, array: any) => {
                if (idx - 1 >= 0) {
                  tmp_rs.push(Math.abs(Number(a) - Number(array[idx - 1])));
                } else {
                  tmp_rs.push(null);
                }
              });
            } else {
              tmp_rs = [];
            }
            break;
          case 1: //時間ごと
            tmp = [];
            data226.forEach((item: any) => {
              if (String(item.year_month_day_hour) === lq_time_string) {
                tmp.push(Number(item['parameter_value']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 2: //日ごと
            tmp = [];
            data226.forEach((item: any) => {
              if (String(item.year_month_day) === lq_time_string) {
                tmp.push(Number(item['parameter_value']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 3: //月ごと
            tmp = [];
            data226.forEach((item: any) => {
              if (String(item.year_month) === lq_time_string) {
                tmp.push(Number(item['parameter_value']));
              }
            });
            tmp_data.push(tmp);
            break;
        }
      });

      let tmp_data_avg: any = [];
      let tmp_data_avg_mel: any = [];
      let tmp_data_middle_mel: any = [];
      let tmp_data_middle: any = [];
      let tmp_data_lcl: any = [];
      let tmp_data_ucl: any = [];
      let tmp_data_target: any = [];
      let tmp_data_lsl: any = [];
      let tmp_data_usl: any = [];

      let tmp_lower_limit: any = [];
      let tmp_upper_limit: any = [];

      tmp_data.forEach((a: any, index: number) => {
        if (a.length > 0) {
          tmp_data_avg.push(dataMaxAvgMin.d9);
          tmp_data_avg_mel.push(average(a));
          tmp_data_middle_mel.push(Quartile(a, 0.5));
          tmp_data_middle.push(dataMaxAvgMin.d9);
          tmp_data_lcl.push(dataMaxAvgMin.d12);
          tmp_data_ucl.push(dataMaxAvgMin.d13);
          tmp_data_target.push(dataMaxAvgMin.d1);
          tmp_data_lsl.push(dataMaxAvgMin.d5);
          tmp_data_usl.push(dataMaxAvgMin.d3);
          tmp_lower_limit.push(dataMaxAvgMin.d5);
          tmp_upper_limit.push(dataMaxAvgMin.d3);
        } else {
          tmp_data_avg.push(dataMaxAvgMin.d8);
          tmp_data_avg_mel.push('');
          tmp_data_middle_mel.push('');
          tmp_data_middle.push(dataMaxAvgMin.d9);
          tmp_data_lcl.push(dataMaxAvgMin.d12);
          tmp_data_ucl.push(dataMaxAvgMin.d13);
          tmp_data_target.push(dataMaxAvgMin.d1);
          tmp_data_lsl.push(dataMaxAvgMin.d5);
          tmp_data_usl.push(dataMaxAvgMin.d3);
          tmp_lower_limit.push(dataMaxAvgMin.d5);
          tmp_upper_limit.push(dataMaxAvgMin.d3);
        }
      });

      setGraphBaseData0({
        id: data226.map((a: any, index: number) => index + 1),
        map: data226.map((a: any) => a.map),
        lq_time: data226.map((a: any) => a.lq_time),
        data_value: tmp_data,
        data_avg: tmp_data_avg,
        data_avg_mel: tmp_data_avg_mel, //群平均
        data_middle_mel: tmp_data_middle_mel, //群中央
        data_middle: tmp_data_middle, //中央
        data_lcl: tmp_data_lcl,
        data_ucl: tmp_data_ucl,
        data_target: tmp_data_target,
        data_lsl: tmp_data_lsl,
        data_usl: tmp_data_usl,
        x_list: date_calc(),
        lower_limit: tmp_lower_limit,
        upper_limit: tmp_upper_limit
      });
    }
  }, [dataMaxAvgMin]);

  // AutoComplete:データ取得：保存：リセットステート
  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  const [job_title, setJob_title] = useState<{label: string}[]>([]);
  const [parameter_name, setParameter_name] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 情報取得： LFCAutocomplete：work：t_lqresult_result_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42001,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：map：t_lqresult_result_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42005,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：job_title：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42002,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['ref_job_title']};
        });
        setJob_title(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：ParameterName：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42004,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['parameter_name']};
        });
        setParameter_name(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：deviceid：
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42003,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .catch(e => e);

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={props.onClose}>{`${t('menu.page_name.Result Compare')}:${t(
          '詳細'
        )}`}</LFCDialogTitle>
        <DialogContent>
          <ProgressBar startProcess={startProcess} />
          <Box>
            <form className={classes.search}>
              <LFCFormRowGroup>
                <LFCDatetimePicker
                  name={t('select_date_from')}
                  label={t('対象期間From')}
                  value={searchValue.select_date_from}
                  required
                  onChange={handleChange}
                  disabled={startProcess}
                />
                <LFCDatetimePicker
                  name={t('select_date_to')}
                  label={t('対象期間To')}
                  value={searchValue.select_date_to}
                  required
                  onChange={handleChange}
                  disabled={startProcess}
                />
                <LFCAutocomplete
                  name={t('work')}
                  label={t('機種')}
                  id={'work'}
                  size="small"
                  value={multiSelectData(work, props.searchValue.work)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={work}
                  disabled={startProcess}
                />

                <LFCAutocomplete
                  name={t('map')}
                  label={t('検査部位')}
                  id={'map'}
                  size="small"
                  value={multiSelectData(map, [props.datas.mapName])}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={map}
                  disabled={startProcess}
                />

                <LFCAutocomplete
                  name={'job_title'}
                  label={t('ジョブタイトル')}
                  id={'job_title'}
                  size="small"
                  value={multiSelectData(job_title, props.searchValue.job_title)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={job_title}
                  disabled={startProcess}
                />

                <LFCAutocomplete
                  name={'parameter_name'}
                  label={t('検査項目')}
                  id={'parameter_name'}
                  size="small"
                  value={multiSelectData(parameter_name, props.searchValue.parameter_name)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={parameter_name}
                  disabled={startProcess}
                />

                <LFCAutocomplete
                  name={'deviceid'}
                  label={t('検査装置')}
                  id={'deviceid'}
                  size="small"
                  value={multiSelectData(deviceid, props.searchValue.deviceid)}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={deviceid}
                  disabled={startProcess}
                />

                <LFCSelectFormJudge
                  name={'judge'}
                  value={searchValue.judge}
                  onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
                  multiple={false}
                  disabled={startProcess}
                />

                <LFCButton color="primary" onClick={doSearch} disabled={startProcess}>
                  {t('検索')}
                </LFCButton>
                <LFCButton onClick={restSearch} disabled={startProcess}>
                  {t('リセット')}
                </LFCButton>
              </LFCFormRowGroup>
            </form>
            <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
            <div style={{float: 'right'}}>
              <LFCButton size={'small'} color="secondary" onClick={getAllList}>
                {t('詳細データ')}
              </LFCButton>
            </div>
            <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
              {searchType.map((item: any) => (
                <FormControlLabel
                  disabled={startProcess}
                  value={item.id}
                  key={'search_type-' + item.id}
                  control={
                    <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                  }
                  label={
                    <Typography display="block" key={'search_type-body-' + item.id}>
                      {item.text}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
          <Box mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                {searchValue.searchType !== 0 ? (
                  <LFCChartsBoxWithLine3
                    title={t('結果推移')}
                    source={graphBaseData0}
                    x={{dsColumn: '', type: 'category'}}
                    y={{dsColumn: '', type: 'value'}}
                    exportData={data226}
                    exportFields={data208_column}
                    exportFilename={`ResultCompare_${t('推移')}`}
                    compareType={
                      Array.from(new Set(data229.map((a: any) => a.compare_type))).toString() ===
                        '0' && true
                    }
                    height={'35vh'}
                    top={'10%'}
                    bottom={'20%'}
                    // mapName={searchValue.map}
                    onClick={getDataWithDate}
                    xlist={date_calc()}
                    zoomChartId={'zoom-chart-1'}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
                    zoomChartsClose={() => setZoomChartOpen('')}
                  />
                ) : (
                  <LFCChartsLineHoleCompare3
                    title={t('結果推移')}
                    source={data226}
                    x={{dsColumn: 'idx'}}
                    yAxis={{
                      type: 'line',
                      name: `${t('測定値')}X`,
                      dsColumn: 'parameter_value'
                    }}
                    color={['rgb(106,113,255)']}
                    exportData={data226}
                    exportFields={data208_column_type0}
                    exportFilename={`ResultCompare_${t('推移')}`}
                    // mapName={searchValue.map}
                    onClick={getDataByData}
                    zoomChartId={'zoom-chart-1'}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
                    zoomChartsClose={() => setZoomChartOpen('')}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                {data209SelectDate !== '' ? (
                  <>
                    {props.searchValue.searchType !== 0
                      ? `${t('選択日')}:` + data209SelectDate
                      : `${t('選択シリアル')}:` + data209SelectDate}
                    <LFCButton
                      size={'small'}
                      className={classes.mrgLeft}
                      onClick={() => setData209SelectDate('')}
                    >
                      {t('選択解除')}
                    </LFCButton>
                  </>
                ) : (
                  <></>
                )}
                <LFCChartsBarParameterValue
                  title={t('結果分布')}
                  source={data227}
                  extraDatas={dataMaxAvgMin}
                  exportData={data227}
                  exportFields={data209_column}
                  exportFilename={`ResultCompare_${t('分布')}`}
                  compareType={
                    Array.from(new Set(data229.map((a: any) => a.compare_type))).toString() ===
                      '0' && true
                  }
                  xAxisName={`(${t('個')})`}
                  height={'25vh'}
                  // top={"10%"}
                  bottom={'35%'}
                  onClick={g2click}
                  xLineMin={xLineMin}
                  xLineMax={xLineMax}
                  xRenge={xRenge}
                  zoomChartId={'zoom-chart-2'}
                  zoomedChartId={zoomChartOpen}
                  zoomCharts={() => setZoomChartOpen('zoom-chart-2')}
                  zoomChartsClose={() => setZoomChartOpen('')}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <div id={'zoom-chart-3'}>
                  {searchValue.searchType !== 0 ? (
                    <LFCChartsLineMulti2
                      title={`${t('ばらつき推移')}`}
                      source={data208XYZ}
                      xData={
                        searchValue.searchType !== 0 ? date_calc() : data226.map((a: any) => a.idx)
                      }
                      color={['rgb(106,113,255)']}
                      exportData={data226}
                      exportFields={data208XYZ_column}
                      exportFilename={`ResultCompare_${t('ばらつき')}`}
                      showCP={dataMaxAvgMin.d14}
                      zoomChartId={'zoom-chart-3'}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  ) : (
                    <LFCChartsLineMultiBySearch2
                      title={`${t('ばらつき推移')}`}
                      source={data208XYZ}
                      xData={
                        searchValue.searchType !== 0 ? date_calc() : data226.map((a: any) => a.idx)
                      }
                      color={['rgb(106,113,255)']}
                      exportData={data226}
                      exportFields={data208XYZ_column_type0}
                      exportFilename={`ResultCompare_${t('ばらつき')}`}
                      zoomChartId={'zoom-chart-3'}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <TableContainer
                  component={Paper}
                  className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
                >
                  <Table sx={{minWidth: 600}} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          {t('規格')}
                          <Box sx={{fontSize: '8px'}}>
                            {dataMaxAvgMin.d2 !== '' ? t('※規格複数のため参考値') : ''}
                          </Box>
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('実績')}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('傾向ばらつき管理')}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('工程能力')}
                          <Box sx={{fontSize: '8px'}}>
                            {dataMaxAvgMin.d2 !== '' || dataMaxAvgMin.d15 !== ''
                              ? t('※規格複数のため参考値')
                              : ''}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{t('上限')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d3)}</TableCell>
                        <TableCell align="center">{t('最大')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d7)}</TableCell>
                        <TableCell align="center">{t('平均+3σ')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d12)}</TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('Cp')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(dataMaxAvgMin.d14)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={2}>
                          {t('目標')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(dataMaxAvgMin.d1)}
                        </TableCell>
                        <TableCell align="center">{t('平均')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d8)}</TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('標準偏差σ')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(dataMaxAvgMin.d11)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">{t('中央')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d9)}</TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('Cpk')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(dataMaxAvgMin.d16)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">{t('下限')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d5)}</TableCell>
                        <TableCell align="center">{t('最小')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d10)}</TableCell>
                        <TableCell align="center">{t('平均-3σ')}</TableCell>
                        <TableCell align="center">{toLocalStringEx(dataMaxAvgMin.d13)}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>

          <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
            <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Result Compare')}:${t(
              '詳細'
            )}`}</LFCDialogTitle>
            <DialogContent>
              <LFCDataGridSimple
                columns={export_detail_ResultCompareDetail}
                rows={dataShowList}
                height="80vh"
                exportFilename={`ResultCompare_${t('詳細データ')}`}
              />
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ResultCompareDig;
