import {Grid, List, ListItem, ListItemText} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import React, {useEffect, useRef, useState} from 'react';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LRobotLYZGraph from '_components/lrobot/LRobotXYZGraph';
import ProgressBar from '_components/ProgressBar';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useMySettings} from '_contexts/MySettingsProvider';
import {getLFCData, handleInputChange} from '_logics/LFCUtil';
import {ALL} from '_logics/LFCConst';

import LFCSavedCondition from '_components/search-conditions/LFCSavedCondition';
import {useGetDefaultCondition} from '_contexts/SavedConditionProvider';
import type {ConditionParamer} from '_components/search-conditions/LFCSavedCondition';
import {
  PAGEID_LR_JOBTRENDANALYSIS,
  LOCATIONID_LR_JOBTRENDANALYSIS_PATHSEND
} from '_logics/LFCPageId';
import GenericTemplate from '_templates/GenericTemplate';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import {isEmpty} from 'lodash';
import {useTranslation} from 'react-i18next';

const WeldingPathPage = () => {
  const {t} = useTranslation();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const [startProcess, setStartProcess] = useState(false);

  const [data60027, setData60027] = useState<any>([]);
  const [data60028, setData60028] = useState<any>([]);
  const [data60029, setData60029] = useState<any>([]);
  const [data70001, setData70001] = useState<any>([]);

  const initialSearchValue = {
    map: '',
    selectType: '0',
    select_datetime_from: dayjs().add(-7, 'd').format('YYYY-MM-DDT00:00'),
    select_datetime_to: dayjs().format('YYYY-MM-DDTHH:mm'),
    work: []
  };
  const getDefaultCondition = useGetDefaultCondition();
  const [openSavedCondition, setOpenSavedCondition] = useState(false);
  const defaultCondition = getDefaultCondition(
    PAGEID_LR_JOBTRENDANALYSIS,
    LOCATIONID_LR_JOBTRENDANALYSIS_PATHSEND
  );
  const [searchValue, setSearchValue] = useState<any>(
    defaultCondition ? {...initialSearchValue, ...defaultCondition} : initialSearchValue
  );

  const [graphSendValue, setGraphSendValue] = useState<any>({
    map: ''
  });

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, selectType: event.target.value});
  };

  let selectType: any = [
    {name: t('ALL'), value: 0},
    {name: t('月毎'), value: 1},
    {name: t('日毎'), value: 2},
    {name: t('時間毎'), value: 3}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const mapSelect = (mapString: any) => {
    if (mapString !== searchValue.map) {
      setSearchValue({...searchValue, map: mapString});
    } else {
      setSearchValue({...searchValue, map: ''});
    }
  };

  const doSearch = async () => {
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setGraphSendValue({map: searchValue.map});
    setData60028([]);
    setData60029([]);
    await setStartProcess(true);
    switch (searchValue.selectType) {
      case 0:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60028,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60028,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_A_XYZ:${t('始点')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60029,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60029,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_B_XYZ:${t('始点')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 1:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60128,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60028,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_A_XYZ:${t('年月単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60129,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60029,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_B_XYZ:${t('年月単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 2:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60228,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60028,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_A_XYZ:${t('日単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60229,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60029,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_B_XYZ:${t('日単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      case 3:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60328,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60028,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_A_XYZ:${t('時間単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60329,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60029,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_B_XYZ:${t('時間単位')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
      default:
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60028,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60028,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_A_XYZ:${t('始点')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 60029,
          parameters: {
            map: searchValue.map !== '' ? searchValue.map : null,
            select_datetime_from: searchValue.select_datetime_from,
            select_datetime_to: searchValue.select_datetime_to,
            work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
          },
          ds_state: setData60029,
          name: `LROBOT_${t('ジョブ比較分析')}_PathSend_B_XYZ:${t('始点')}:${t('ALL')}`,
          cancelToken: source.token,
          t
        });
        break;
    }
    await setStartProcess(false);
  };

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue(initialSearchValue);
    setAutoCompleteReset(true);
    setData60027([]);
    setData60028([]);
    setData60029([]);
  };

  const mapImageGetter = (map_string: string) => {
    let tmpData: any = data70001.filter((item: any) => item.mapname === map_string);
    if (tmpData.length > 0) {
      return (
        <img
          src={`${process.env.REACT_APP_MAPIMAGE_PATH + tmpData[0]['filename']}`}
          alt=""
          style={{height: '80px', margin: '5px'}}
        />
      );
    } else {
      return (
        <div style={{height: '80px', width: '100px', backgroundColor: 'silver', margin: '5px'}} />
      );
    }
  };

  useEffect(() => {
    // setGraphSendValue({map: ''});
    // setSearchValue({...searchValue, map: ''});
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 70001,
      parameters: {},
      ds_state: setData70001,
      name: `${t('map画像一覧')}`,
      cancelToken: source.token,
      t
    });
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60027,
      parameters: {
        work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null
      },
      ds_state: setData60027,
      name: `LROBOT_${t('ジョブ比較分析')}_PathSend_${t('部位リスト')}`,
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  }, [searchValue.work]);

  const [condition, setCondition] = React.useState<ConditionParamer[]>([]);
  const onLoadSavedCondition = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }
    setCondition([
      {
        name: 'work',
        value: searchValue.work,
        valueLabel: searchValue.work,
        colName: t('機種'),
        colWidth: 300
      },
      {
        name: 'map',
        value: searchValue.map,
        valueLabel: searchValue.map,
        colName: t('部位'),
        colWidth: 300
      },
      {
        name: 'select_datetime_from',
        value: searchValue.select_datetime_from,
        valueLabel:
          searchValue.select_datetime_from === ''
            ? ''
            : dayjs(searchValue.select_datetime_from).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間From'),
        colWidth: 150
      },
      {
        name: 'select_datetime_to',
        value: searchValue.select_datetime_to,
        valueLabel:
          searchValue.select_datetime_to === ''
            ? ''
            : dayjs(searchValue.select_datetime_to).format('YYYY-MM-DD HH:mm'),
        colName: t('対象期間To'),
        colWidth: 150
      },
      {
        name: 'selectType',
        value: searchValue.selectType,
        valueLabel:
          String(searchValue.selectType) === '0'
            ? ALL
            : String(searchValue.selectType) === '1'
              ? t('月毎')
              : String(searchValue.selectType) === '2'
                ? t('日毎')
                : String(searchValue.selectType) === '3'
                  ? t('時間毎')
                  : '',
        colName: t('集計単位'),
        colWidth: 150
      }
    ]);
    setOpenSavedCondition(true);
  };

  const onSelectSavedCondition = (conditionValues: any) => {
    setSearchValue({...searchValue, ...conditionValues});
    setOpenSavedCondition(false);
    setAutoCompleteReset(true);
  };

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  //検索値の初期値をオートコンプリートに反映
  useEffect(() => {
    setAutoCompleteReset(true);
  }, [work]);

  return (
    <GenericTemplate
      title={`${t('menu.tab_name.L-Rジョブ傾向分析')}:${t('menu.page_name.溶接パス')}`}
    >
      <ProgressBar startProcess={startProcess} />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={3}>
          <form ref={formRef}>
            <LFCTitledCard title={t('検索条件')}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <LFCAutocomplete
                    name={t('work')}
                    label={t('機種')}
                    id={'work'}
                    size="small" // small/medium
                    value={multiSelectData(work, searchValue.work)}
                    // style={{width: 500}}
                    onChange={autoOnChange}
                    onReset={autoCompleteReset}
                    doneReset={setAutoCompleteReset}
                    multiple={true}
                    selectItem={work}
                  />
                  <LFCTitledCard title={t('部位')} transparent>
                    <List component="nav" style={{height: '40vh', overflow: 'auto'}}>
                      {data60027.map((map_string: any, index: number) => {
                        return (
                          <ListItem
                            button
                            divider
                            sx={{
                              padding: '0 !important',
                              backgroundColor:
                                map_string['map'] === searchValue.map
                                  ? mySettings.isDark
                                    ? '#2d454f !important'
                                    : '#83c8e5 !important'
                                  : '',
                              '&:hover': {
                                backgroundColor:
                                  map_string['map'] === searchValue.map
                                    ? mySettings.isDark
                                      ? '#4b7586 !important'
                                      : '#bddce8 !important'
                                    : ''
                              }
                            }}
                            key={index}
                            onClick={() => mapSelect(data60027[index].map)}
                          >
                            {mapImageGetter(data60027[index].map)}
                            <ListItemText primary={map_string['map']} />
                          </ListItem>
                        );
                      })}
                    </List>
                  </LFCTitledCard>
                  <LFCTitledCard title={t('対象期間')} transparent>
                    <LFCFormRowGroup>
                      <LFCDatetimePicker
                        name={'select_datetime_from'}
                        label={t('対象期間From')}
                        value={searchValue.select_datetime_from}
                        onChange={event => {
                          handleInputChange(event, searchValue, setSearchValue);
                        }}
                        required
                      />
                      <LFCDatetimePicker
                        name={'select_datetime_to'}
                        label={t('対象期間To')}
                        value={searchValue.select_datetime_to}
                        onChange={event => {
                          handleInputChange(event, searchValue, setSearchValue);
                        }}
                        required
                      />
                    </LFCFormRowGroup>
                  </LFCTitledCard>
                </Grid>
                <Grid item xs={6}>
                  <LFCSelectForm
                    name={'selectType'}
                    label={t('集計単位')}
                    value={searchValue.selectType}
                    id={'sl1'}
                    onChange={handleChange}
                    selectItem={selectType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LFCButton color="primary" onClick={() => doSearch()} fullWidth>
                    {t('検索')}
                  </LFCButton>
                  <LFCButton onClick={restSearch} fullWidth>
                    {t('リセット')}
                  </LFCButton>
                  <LFCSavedCondition
                    open={openSavedCondition}
                    pageId={PAGEID_LR_JOBTRENDANALYSIS}
                    locationNo={LOCATIONID_LR_JOBTRENDANALYSIS_PATHSEND}
                    onLoad={onLoadSavedCondition}
                    onSelect={onSelectSavedCondition}
                    onClose={() => setOpenSavedCondition(false)}
                    conditions={condition}
                  />
                </Grid>
              </Grid>
            </LFCTitledCard>
          </form>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <LRobotLYZGraph show={'PathSend-a'} map={graphSendValue.map} xyz={data60028} />
            </Grid>
            <Grid item xs={12} md={6}>
              <LRobotLYZGraph show={'PathSend-b'} map={graphSendValue.map} xyz={data60029} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default WeldingPathPage;
