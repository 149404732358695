import {Theme, useTheme} from '@mui/material';
import React, {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  source: {
    min_time: number;
    average_time: number;
    max_time: number;
    work_rate: number;
  };
  height?: string;
  targetWorkingRate?: number;
}

const COLOR_OK = '#4CAF50';
const COLOR_NG = '#F44336';

const getRich = (theme: Theme) => {
  return {
    center_title: {
      fontSize: '0.875rem',
      color: theme.palette.text.primary,
      padding: [0, 0, 5, 0]
    },
    center_hr: {
      borderColor: theme.palette.divider,
      width: '100%',
      align: 'left',
      borderWidth: 1,
      height: 0
    },
    center_val: {
      fontSize: '2rem',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      padding: [7, 0, 0, 0]
    },
    title_ok: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.secondary,
      padding: [3, 5],
      align: 'center'
    },
    title_ng: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.secondary,
      padding: [3, 5],
      align: 'center'
    },
    val: {
      fontSize: '1.75rem',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      verticalAlign: 'bottom',
      padding: [2, 0, 2, 0],
      align: 'center'
    },
    val_sub: {
      fontSize: '0.875rem',
      color: theme.palette.background.default,
      backgroundColor: theme.palette.text.secondary,
      padding: [0, 5],
      align: 'center'
    }
  };
};

/**
 * LFCOkNgPie4
 * @param props
 * @returns
 */
const LFCOkNgPie4 = React.memo((props: Props) => {
  const theme = useTheme();
  const [dsPop, setDsPop] = useState([{}]);
  const [dsCenter, setDsCenter] = useState([{}]);

  useEffect(() => {
    setDsPop([{name: '未達成', value: 100}]);
    setDsCenter([{name: '稼働率', value: 100 * Number(props.source.work_rate)}]);
  }, [props.source, props.targetWorkingRate]);

  return (
    <LFCBaseCharts
      option={{
        legend: {show: false},
        toolbox: {show: false},
        tooltip: {show: false},
        series: [
          {
            name: 'pop',
            type: 'pie',
            radius: ['60%', '82%'],
            data: dsPop,
            itemStyle: {
              color: (data: any) => {
                if (100 * Number(props.source.work_rate) - Number(props.targetWorkingRate) >= 0) {
                  return COLOR_OK;
                } else {
                  return COLOR_NG;
                }
              }
            },
            label: {
              show: false
            }
          },
          {
            z: 10,
            name: 'total',
            type: 'pie',
            radius: 0,
            data: dsCenter,
            itemStyle: {
              color: 'transparent'
            },
            label: {
              show: true,
              position: 'center',
              rich: getRich(theme),
              formatter: (data: any) => {
                const strP = {
                  val: data['value'].toLocaleString() + '%'
                };
                return `{center_val|${strP.val}} `;
              }
            }
          }
        ]
      }}
      height={props.height}
    />
  );
});
export default LFCOkNgPie4;
