import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import React, {useState} from 'react';
import OpenSiteTemplate from '_templates/OpenSiteTemplate';
import {useTranslation} from 'react-i18next';

interface SWUpdateDialogProps {
  registration: ServiceWorkerRegistration;
}

/**
 * SWアップデート通知ダイアログ
 * @param props
 * @returns
 */
export const SWUpdateDialog = (props: SWUpdateDialogProps) => {
  const {t} = useTranslation();
  const [show, setShow] = useState(!!props.registration.waiting);
  /**
   * アップデート開始
   */
  const handleUpdate = () => {
    // Service Worker 更新時はデータの不整合等を防ぐために waiting 状態に移行し安全な状態になるのを待つようになっている
    // すぐに active 状態にするため、skipWaiting()を呼ぶことで active 状態にする
    props.registration.waiting?.postMessage({type: 'SKIP_WAITING'});

    // 新バージョンウィンドウ消す
    setShow(false);

    // Service Workerのキャッシュ消す
    window.navigator.serviceWorker.getRegistrations().then(registrations => {
      for (let registration of registrations) {
        registration.unregister().then(() => {
          // ブラウザスーパーリロード
          // eslint-disable-next-line no-self-assign
          // window.location.reload(true);
          window.location.reload();
        });
      }
    });
  };

  return show ? (
    <OpenSiteTemplate>
      <Dialog open={true} fullWidth>
        <DialogTitle>Software Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('LINKWIZ FACTORY CLOUDの新しいバージョンがあります。')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleUpdate} color="primary">
            {t('インストール')}
          </Button>
        </DialogActions>
      </Dialog>
    </OpenSiteTemplate>
  ) : (
    <></>
  );
};
