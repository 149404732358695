import {Box, Card, CardContent, Tooltip, Typography} from '@mui/material';
import {Variant} from '@mui/material/styles/createTypography';
import {makeStyles} from '@mui/styles';
import {useTranslation} from 'react-i18next';

/**
 * Style
 */
const useStyles = makeStyles({
  root: {
    '& div': {
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    }
  }
});

/**
 * パラメータ
 */
interface Props {
  title: string;
  val: any;
  align?: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  fontColor?: string;
  width?: string;
  titleVariant?: Variant | 'inherit';
  valVariant?: Variant | 'inherit';
  formatter?: (value: any) => any;
}

/**
 * LFCSingleValue
 */
const LFCSingleValueYearlyResults = (props: Props) => {
  const {t} = useTranslation();
  const classes = useStyles();

  // PoC：年間実績：オブジェクトのval受け取り
  return (
    <Card
      className={classes.root}
      style={{maxWidth: props.width, margin: '1px'}}
      variant="outlined"
    >
      <CardContent style={{padding: '5px'}}>
        {/* title */}
        <Box textAlign="center">
          <Typography variant={props.titleVariant ? props.titleVariant : 'subtitle1'}>
            {props.title}
          </Typography>
        </Box>
        {/* body */}
        <Tooltip
          title={
            props.val !== undefined && props.val !== null
              ? props.val.data1 + props.val.data2 + props.val.data3
              : ''
          }
          arrow
        >
          <Box
            textAlign={props.align ? props.align : 'center'}
            style={{
              margin: '2px',
              whiteSpace: 'nowrap',
              overflowX: 'auto',
              overflowY: 'hidden'
            }}
          >
            <Typography
              variant={props.valVariant ? props.valVariant : 'h4'}
              style={{color: props.fontColor}}
            >
              {/*{props.formatter ? props.formatter(props.val) : props.val}*/}
              <span style={{fontSize: 'small'}}>{t('今期平均')}：</span> {props.val.data3}
              {props.val.data2}
              <span style={{fontSize: 'small'}}>{t('前期平均')}：</span>{' '}
              {!isNaN(props.val.data1) ? props.val.data1 : 0}
            </Typography>
          </Box>
        </Tooltip>
      </CardContent>
    </Card>
  );
};

export default LFCSingleValueYearlyResults;
