import {Box, Dialog, DialogContent} from '@mui/material';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import {useState} from 'react';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  imgPath: string;
}

/**
 * 画像表示ダイアログ
 * @param props
 * @returns
 */
const ImageDialog = (props: Props) => {
  const [isImageLoaded, setIsImageLoaded] = useState(true);

  const handleImageError = () => {
    setIsImageLoaded(false);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} maxWidth={false}>
      {isImageLoaded && <LFCDialogTitle onClose={props.onClose}>{props.title}</LFCDialogTitle>}
      <DialogContent>
        <Box>
          <img
            src={props.imgPath}
            alt=""
            style={{
              objectFit: 'contain',
              width: '65vw'
            }}
            draggable
            onError={handleImageError}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ImageDialog;
