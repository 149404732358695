import {Box, Grid, Typography} from '@mui/material';
import LRobotLYZGraph from '_components/lrobot/LRobotXYZGraph';
import TabPanel from './WeldingDemoTabPanel';
import {Dispatch, useEffect, useState} from 'react';
import WeldingDemoRadioGroup from './WeldingDemoRadioGroup';
import {date_calc, extractAndFormatWeldingWorks} from 'utils/weldingDemo';
import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import LFCChartsScatterWeldingMonitor1 from '_components/charts/LFCChartsScatterWeldingMonitor1';
import LFCChartsLineWeldingDemo1 from '_components/charts/LFCChartsLineWeldingDemo1';
import LFCChartsBarWeldingProductionStatus from 'pages/welding/_charts/LFCChartsBarWeldingProductionStatus';
import {useTranslation} from 'react-i18next';

type Props = {
  searchValue: any;
  setSearchValue: Dispatch<any>;
  value: number;
  startProcess: boolean;
};

const WeldingDemoTabPanels = (props: Props) => {
  const {t} = useTranslation();
  const {searchValue, setSearchValue, value, startProcess} = props;
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [data301, setData301] = useState<string[]>([]);
  useEffect(() => {
    if (startProcess) {
      Promise.allSettled([
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 301,
          parameters: {
            date01: searchValue.date01,
            date02: searchValue.date02
          },
          name: 'WeldingDemo/AllData',
          cancelToken: source.token,
          t
        })
          .then(datas => {
            /* データをWorkごとに整形する */
            if (datas.length > 0) {
              const works = extractAndFormatWeldingWorks(datas);
              setData301(works);
            } else {
              setData301([]);
            }
          })
          .catch(e => e)
      ]);
    }
  }, [startProcess]);

  /* 画面が完成するまでの定数 */
  const data60028: [] = [];
  const data60029: [] = [];
  const graphSendValue = {
    map: ''
  };
  const displacementAmountX: [] = [];
  const displacementAmountY: [] = [];
  const displacementAmountZ: [] = [];

  return (
    <>
      <TabPanel value={value} index={0}>
        <WeldingDemoRadioGroup searchValue={searchValue} setSearchValue={setSearchValue} />
        <Box mt={1}>
          <Grid container spacing={1}>
            <Grid item xs={2.5} md={2.5}>
              <LFCChartsBarWeldingProductionStatus
                title="Production Count"
                source={displacementAmountX}
                xList={date_calc(searchValue)}
                yAxisName={''}
                xAxisName={'Displacement Amount (X-AXIS)'}
                height={'30vh'}
              />
              <LFCChartsBarWeldingProductionStatus
                title="Production Count"
                source={displacementAmountY}
                xList={date_calc(searchValue)}
                yAxisName={''}
                xAxisName={'Displacement Amount (Y-AXIS)'}
                height={'30vh'}
              />
              <LFCChartsBarWeldingProductionStatus
                title="Production Count"
                source={displacementAmountZ}
                xList={date_calc(searchValue)}
                yAxisName={''}
                xAxisName={'Displacement Amount (Z-AXIS)'}
                height={'30vh'}
              />
            </Grid>
            <Grid item xs={9.5} md={9.5}>
              <Grid container spacing={1} justifyContent="center" alignItems="center">
                <Grid item xs={5} md={5} p={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'white',
                            backgroundColor: 'blue',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Result OK</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Box
                            textAlign={'center'}
                            style={{
                              whiteSpace: 'nowrap',
                              overflowX: 'auto',
                              overflowY: 'hidden'
                            }}
                          >
                            <Typography
                              variant={'h4'}
                              style={{
                                color: 'black',
                                backgroundColor: 'white',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: '700'
                              }}
                            >
                              <span style={{fontSize: 16}}>Welding Start Point</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <LRobotLYZGraph
                            show={'PathSend-a'}
                            map={graphSendValue.map}
                            xyz={data60028}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Box
                            textAlign={'center'}
                            style={{
                              whiteSpace: 'nowrap',
                              overflowX: 'auto',
                              overflowY: 'hidden'
                            }}
                          >
                            <Typography
                              variant={'h4'}
                              style={{
                                color: 'black',
                                backgroundColor: 'white',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: '700'
                              }}
                            >
                              <span style={{fontSize: 16}}>Welding End Point</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <LRobotLYZGraph
                            show={'PathSend-b'}
                            map={graphSendValue.map}
                            xyz={data60029}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid xs={1} md={1}></Grid>
                <Grid item xs={5} md={5} p={2}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'black',
                            backgroundColor: 'red',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Result NG</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Box
                            textAlign={'center'}
                            style={{
                              whiteSpace: 'nowrap',
                              overflowX: 'auto',
                              overflowY: 'hidden'
                            }}
                          >
                            <Typography
                              variant={'h4'}
                              style={{
                                color: 'black',
                                backgroundColor: 'white',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: '700'
                              }}
                            >
                              <span style={{fontSize: 16}}>Welding Start Point</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <LRobotLYZGraph
                            show={'PathSend-a'}
                            map={graphSendValue.map}
                            xyz={data60028}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <Grid container>
                        <Grid item xs={12} md={12}>
                          <Box
                            textAlign={'center'}
                            style={{
                              whiteSpace: 'nowrap',
                              overflowX: 'auto',
                              overflowY: 'hidden'
                            }}
                          >
                            <Typography
                              variant={'h4'}
                              style={{
                                color: 'black',
                                backgroundColor: 'white',
                                height: '50px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                fontWeight: '700'
                              }}
                            >
                              <span style={{fontSize: 16}}>Welding End Point</span>
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <LRobotLYZGraph
                            show={'PathSend-b'}
                            map={graphSendValue.map}
                            xyz={data60029}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <WeldingDemoRadioGroup searchValue={searchValue} setSearchValue={setSearchValue} />
        <Box mt={1}>
          <Grid container spacing={1} justifyContent="center" alignItems="center">
            <Grid item xs={5} md={5} p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Box
                    textAlign={'center'}
                    style={{
                      whiteSpace: 'nowrap',
                      overflowX: 'auto',
                      overflowY: 'hidden'
                    }}
                  >
                    <Typography
                      variant={'h4'}
                      style={{
                        color: 'white',
                        backgroundColor: 'blue',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        fontWeight: '700'
                      }}
                    >
                      <span style={{fontSize: 16}}>Result OK</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'black',
                            backgroundColor: 'white',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Welding Start Point</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LRobotLYZGraph
                        show={'PathSend-a'}
                        map={graphSendValue.map}
                        xyz={data60028}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'black',
                            backgroundColor: 'white',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Welding End Point</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LRobotLYZGraph
                        show={'PathSend-b'}
                        map={graphSendValue.map}
                        xyz={data60029}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={1} md={1}></Grid>
            <Grid item xs={5} md={5} p={2}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                  <Box
                    textAlign={'center'}
                    style={{
                      whiteSpace: 'nowrap',
                      overflowX: 'auto',
                      overflowY: 'hidden'
                    }}
                  >
                    <Typography
                      variant={'h4'}
                      style={{
                        color: 'black',
                        backgroundColor: 'red',
                        height: '50px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        fontWeight: '700'
                      }}
                    >
                      <span style={{fontSize: 16}}>Result NG</span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'black',
                            backgroundColor: 'white',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Welding Start Point</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LRobotLYZGraph
                        show={'PathSend-a'}
                        map={graphSendValue.map}
                        xyz={data60028}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Grid container>
                    <Grid item xs={12} md={12}>
                      <Box
                        textAlign={'center'}
                        style={{
                          whiteSpace: 'nowrap',
                          overflowX: 'auto',
                          overflowY: 'hidden'
                        }}
                      >
                        <Typography
                          variant={'h4'}
                          style={{
                            color: 'black',
                            backgroundColor: 'white',
                            height: '50px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            fontWeight: '700'
                          }}
                        >
                          <span style={{fontSize: 16}}>Welding End Point</span>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <LRobotLYZGraph
                        show={'PathSend-b'}
                        map={graphSendValue.map}
                        xyz={data60029}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box mt={4}>
          <Grid container sx={{padding: 1}}>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsScatterWeldingMonitor1
                title="Current / Voltage"
                xAxisName={`${t('電流 [A]')}`}
                yAxisName={`${t('電圧 [V]')}`}
                datas={data301}
                height={'500px'}
                left={'90%'}
              />
            </Grid>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsLineWeldingDemo1
                title="Voltage Trend"
                source={data301}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={`${t('電圧 [V]')}`}
                height={'500px'}
                target="voltage"
              />
            </Grid>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsLineWeldingDemo1
                title="Current Trend"
                source={data301}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={`${t('電流 [A]')}`}
                height={'500px'}
                target="current"
              />
            </Grid>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsLineWeldingDemo1
                title="Wire Feed Speed Trend"
                source={data301}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={'Wire Feed Speed'}
                height={'500px'}
                target="wire_feed_speed"
              />
            </Grid>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsLineWeldingDemo1
                title="True Energy"
                source={data301}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={'True Energy'}
                height={'500px'}
                target="true_energy"
              />
            </Grid>
            <Grid xs={4} md={4} sx={{padding: 1}}>
              <LFCChartsLineWeldingDemo1
                title="Resistance Trend"
                source={data301}
                xAxisName={`${t('溶接開始からの時間')}`}
                yAxisName={'Resistance'}
                height={'500px'}
                target="resistance"
              />
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <p>{t('表示なし')}</p>
      </TabPanel>
    </>
  );
};

export default WeldingDemoTabPanels;
