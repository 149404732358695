import {useTheme} from '@mui/material';
import React, {useEffect, useRef, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';

/**
 * 引数
 */
interface Props {
  title: string;
  x: {dsColumn: string};
  series: {name: string; dsColumn: string}[];
  source: {}[];

  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
}

/**
 * LFCChartsLineGap
 * @param props
 * @returns
 */
const LFCChartsLineGap = React.memo((props: Props) => {
  const theme = useTheme();

  const renderFlgRef = useRef(false);
  type DsRow = {source?: {}[]; transform?: {}};
  const [datasetAfter, setDatasetAfter] = useState<DsRow[]>([]);
  const [seriesAfter, setSeriesAfter] = useState<{}[]>([]);

  useEffect(() => {
    if (!renderFlgRef.current) {
      // 初期描画時のみの処理
      renderFlgRef.current = true;
      return;
    }

    // setSeriesAfter(l_series);
    // データソースクリア
    if (props.source == null || props.source.length === 0) {
      setSeriesAfter([]);
      setDatasetAfter([]);
      return;
    }

    // データソース生成
    const l_ds: DsRow[] = [];
    const l_series: {}[] = [];

    l_ds.push({source: props.source});

    // シリーズ設定
    props.series.forEach((row, i) => {
      l_series.push({
        type: 'line',
        name: row['name'],
        encode: {x: props.x.dsColumn, y: row['dsColumn']},
        datasetIndex: 0,
        symbolSize: i === 0 ? 2 : 0,
        connectNulls: true,
        lineStyle: i === 0 ? {} : {type: 'dotted'}
        // sampling: "lttb",
      });
    });

    setSeriesAfter(l_series);
    setDatasetAfter(l_ds);
  }, [props.source]);

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          grid: {
            top: '40',
            left: '60',
            right: '10',
            bottom: '15',
            containLabel: true
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10
          },
          tooltip: {
            trigger: 'item'
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          dataset: datasetAfter,
          color: [theme.palette.text.primary, '#2196F3', '#FFEB3B', '#F44336'],
          xAxis: [
            {
              type: 'category',
              axisLabel: {
                rotate: '45',
                fontSize: 10
              }
            }
          ],
          yAxis: [
            {
              type: 'value'
            }
          ],
          series: seriesAfter
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height !== undefined ? props.height : '300px'}
      />
    </div>
  );
});
export default LFCChartsLineGap;
