import {makeStyles} from 'tss-react/mui';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {useMySettings} from '_contexts/MySettingsProvider';
import ProgressBar from '_components/ProgressBar';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import LFCButton from '_components/inputs/LFCButton';
import axios from 'axios';
import {distinct, getLFCData, handleInputChange2} from '_logics/LFCUtil';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import LFCChartsBarWorkRadius from '_components/charts/LFCChartsBarWorkRadius';
import dayjs from 'dayjs';
import LFCSelectFormJudge from '_components-with-data/inputs/LFCSelectFormJudge';
import LFCDatetimePicker from '_components/inputs/LFCDatetimePicker';
import {isEmpty} from 'lodash';
import LFCAutocomplete, {multiSelectData} from '_components/inputs/LFCAutocomplete';
import LFCChartsBoxWithLine2 from '_components/charts/LFCChartsBoxWithLine2';
import LFCChartsLineHoleCompare2 from '_components/charts/LFCChartsLineHoleCompare2';
import LFCChartsLineMulti1 from '_components/charts/LFCChartsLineMulti1';
import LFCChartsLineMultiBySearch1 from '_components/charts/LFCChartsLineMultiBySearch1';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles()(theme => {
  return {
    root: {
      display: 'flex'
    },
    formControl: {
      margin: theme.spacing(2)
    },
    table: {
      minWidth: 650
    },
    extendedIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2)
    },
    select: {
      minWidth: 100
    },
    imgStyle: {
      width: '100%',
      height: '100%'
    },
    search: {
      display: 'flex',
      alignItems: 'start',
      // margin: "10px 0px 0px",
      '& > *': {
        margin: theme.spacing(1)
        // width: "20ch",
      }
    },
    tableLine: {
      '& th': {
        border: 'solid 1px silver'
      },
      '& tr': {
        border: 'solid 1px silver'
      },
      '& td': {
        border: 'solid 1px silver'
      }
    },
    tableLineDark: {
      '& th': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& tr': {
        border: 'solid 1px rgb(40,100,100)'
      },
      '& td': {
        border: 'solid 1px rgb(40,100,100)'
      }
    },
    mrgLeft: {
      marginLeft: '16px'
    }
  };
});

/**
 * 引数
 */
interface Props {
  pageName: string;
  open: boolean;
  onClose: () => void;
  datas: any;
  searchValue: any;
}

const HoleCompareDiameterDig = (props: Props) => {
  const {t} = useTranslation();
  const {classes} = useStyles();
  const mySettings = useMySettings();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(false);
  const [data208, setData208] = useState([]);
  const [data28011, setData28011] = useState([]);
  const [data28003, setData28003] = useState([]);
  const [data280013, setData280013] = useState([]);
  const [data208XYZ, setData208XYZ] = useState({});
  const data208MaxAvgMin_init = {
    d1: 0, //目標
    d2: '', //目標info
    d3: 0, //上限
    d4: '', //上限info
    d5: 0, //下限
    d6: '', //下限info
    d7: 0, //最大
    d8: 0, //平均
    d9: 0, //中央
    d10: 0, //最小
    d11: 0, //標準偏差
    d12: 0, //管理限界線1 平均+3a
    d13: 0, //管理限界線2　平均-3a
    d14: 0, //工程能力1
    d15: '', //工程能力1info
    d16: 0, //工程能力2
    d17: '', //工程能力2info
    d18: 0, //仕様限界LSL
    d19: 0 //仕様限界USL
  };
  const export_detail_HoleDiameterDetail: any = [
    {
      field: 'id',
      headerName: t('連番'),
      description: t('連番'),
      sortable: false,
      width: 100
    },
    {
      field: 'serial',
      headerName: t('シリアルNo.'),
      description: t('ワークごとの識別子'),
      sortable: false,
      width: 200
    },
    {
      field: 'map',
      headerName: t('検査部位'),
      description: t('検査部位の識別子'),
      width: 100,
      sortable: false
    },
    {
      field: 'lq_time',
      headerName: t('検査日時'),
      description: t('LQを実施した日時'),
      width: 200,
      sortable: false
    },
    {
      field: 'radius_lower_limit',
      headerName: t('下限閾値'),
      description: t('半径の最小許容値'),
      width: 200,
      sortable: false
    },
    {
      field: 'radius_upper_limit',
      headerName: t('上限閾値'),
      description: t('半径の最大許容値'),
      width: 200,
      sortable: false
    },
    {
      field: 'master_radius',
      headerName: t('マスタ半径'),
      description: t('誤差なしの理想半径'),
      width: 200,
      sortable: false
    },
    {
      field: 'work_radius',
      headerName: t('ワーク半径'),
      description: t('検査されたワークの実寸'),
      width: 200,
      sortable: false
    },
    {
      field: 'radius_gap',
      headerName: t('半径誤差'),
      description: t('マスタとワークの寸法誤差'),
      width: 200,
      sortable: false
    }
  ];
  const [data208MaxAvgMin, setData208MaxAvgMin] = useState(data208MaxAvgMin_init);
  const [data209, setData209] = useState([]);
  const [data209SelectDate, setData209SelectDate] = useState('');
  const [data210, setData210] = useState([]);
  const [graphBaseData0, setGraphBaseData0] = useState({});
  const [searchValue, setSearchValue] = useState<any>({
    select_date_from: props.searchValue.select_date_from,
    select_date_to: props.searchValue.select_date_to,
    work: [],
    deviceid: [],
    judge: 9,
    map: [],
    searchType: 2
  });
  const searchType: any = [
    {id: 0, text: t('検査ごと')},
    {id: 1, text: t('1時間ごと')},
    {id: 2, text: t('1日ごと')},
    {id: 3, text: t('1ヶ月ごと')}
  ];

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const Quartile = (data: any, q: number) => {
    data = Array_Sort_Numbers(data);
    let pos = (data.length - 1) * q;
    let base = Math.floor(pos);
    let rest = pos - base;
    if (data[base + 1] !== undefined) {
      return data[base] + rest * (data[base + 1] - data[base]);
    } else {
      return data[base];
    }
  };

  const Array_Sort_Numbers = (inputarray: any) => {
    return inputarray.sort((a: number, b: number) => {
      return a - b;
    });
  };

  useEffect(() => {
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
  }, [props]);

  const handleChange = (event: any) => {
    setSearchValue({...searchValue, [event.target.name]: event.target.value});
  };

  const onChangeRadio = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setData208([]);
    setData208MaxAvgMin(data208MaxAvgMin_init);
    setData28003_show([]);
    setData28003_r_show([]);
    setData208XYZ([]);
    setGraphBaseData0([]);
    setData208([]);
    setData209([]);
    setSearchValue({...searchValue, searchType: Number(e.target.value)});
    setData209SelectDate('');
  };

  const [searchPrams, setSearchPrams] = useState({});
  useEffect(() => {
    setSearchPrams({
      work: !isEmpty(searchValue.work) ? '{' + searchValue.work.join(',') + '}' : null,
      deviceid: !isEmpty(searchValue.deviceid) ? '{' + searchValue.deviceid.join(',') + '}' : null,
      judge: searchValue.judge === 9 ? null : searchValue.judge,
      map: !isEmpty(searchValue.map) ? '{' + searchValue.map.join(',') + '}' : null
    });
  }, [searchValue]);

  const doSearch = async () => {
    setStartProcess(true);
    setData208([]);
    setData208XYZ([]);
    setGraphBaseData0([]);
    setData209SelectDate('');
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 208,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        ...searchPrams
      },
      ds_state: setData208,
      name: 'id-208',
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));

    // データの判定実行：データ件数10件以上、リミット値が単一である
    switch (searchValue.searchType) {
      case 0: // 検査ごと
        setData28011([]);
        break;
      case 1: // 時間ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 28013,
          parameters: {
            year_month_day_hour: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData28011,
          name: 'id-28013',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 2: // 日ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 28011,
          parameters: {
            year_month_day: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData28011,
          name: 'id-28012',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 3: // 月ごと
        await getLFCData({
          snack: enqueueSnackbar,
          sql_id: 28012,
          parameters: {
            year_month: '{' + date_calc().join(',') + '}',
            ...searchPrams
          },
          ds_state: setData28011,
          name: 'id-28012',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
    }
  };

  useEffect(() => {
    switch (searchValue.searchType) {
      case 0: // 検査ごと
        getLFCData({
          snack: enqueueSnackbar,
          sql_id: 28000,
          parameters: {
            select_date_from: searchValue.select_date_from,
            select_date_to: searchValue.select_date_to,
            ...searchPrams
          },
          ds_state: setData28003,
          name: 'id-28003',
          cancelToken: source.token,
          t
        }).then(() => setStartProcess(false));
        break;
      case 1: // 時間ごと
        if (data28011.length > 0) {
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 28003,
            parameters: {
              year_month_day_hour:
                '{' +
                Array.from(new Set(data28011.map((a: any) => a.year_month_day_hour))).join(',') +
                '}',
              ...searchPrams
            },
            ds_state: setData28003,
            name: 'id-28003',
            cancelToken: source.token,
            t
          }).then(() => {
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280033,
              parameters: {
                select_date_from: searchValue.select_date_from,
                select_date_to: searchValue.select_date_to,
                ...searchPrams
              },
              ds_state: setData280013,
              name: 'id-280033',
              cancelToken: source.token,
              t
            }).then(() => setStartProcess(false));
          });
        }
        break;
      case 2: // 日ごと
        if (data28011.length > 0) {
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 28001,
            parameters: {
              year_month_day:
                '{' +
                Array.from(new Set(data28011.map((a: any) => a.year_month_day))).join(',') +
                '}',
              ...searchPrams
            },
            ds_state: setData28003,
            name: 'id-28003',
            cancelToken: source.token,
            t
          }).then(() => {
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280014,
              parameters: {
                select_date_from: searchValue.select_date_from,
                select_date_to: searchValue.select_date_to,
                ...searchPrams
              },
              ds_state: setData280013,
              name: 'id-280014',
              cancelToken: source.token,
              t
            }).then(() => setStartProcess(false));
          });
        }
        break;
      case 3: // 月ごと
        if (data28011.length > 0) {
          getLFCData({
            snack: enqueueSnackbar,
            sql_id: 28002,
            parameters: {
              year_month:
                '{' + Array.from(new Set(data28011.map((a: any) => a.year_month))).join(',') + '}',
              ...searchPrams
            },
            ds_state: setData28003,
            name: 'id-28003',
            cancelToken: source.token,
            t
          }).then(() => {
            // 範囲Rを全権取得のため
            getLFCData({
              snack: enqueueSnackbar,
              sql_id: 280013,
              parameters: {
                select_date_from: searchValue.select_date_from,
                select_date_to: searchValue.select_date_to,
                ...searchPrams
              },
              ds_state: setData280013,
              name: 'id-280013',
              cancelToken: source.token,
              t
            }).then(() => setStartProcess(false));
          });
        }
        break;
    }
  }, [data28011]);

  const [data28003_show, setData28003_show] = useState([]);
  const [data28003_r_show, setData28003_r_show] = useState([]);
  useEffect(() => {
    let tmp_list: any = [];
    let tmp_list_r: any = [];
    setData28003_show([]);
    setData28003_r_show([]);

    if (data28003.length > 0) {
      switch (searchValue.searchType) {
        case 0: // 検査ごと
          break;
        case 1: // 時間ごと
          date_calc().forEach((a: string) => {
            if (data28003.find((b: any) => b.year_month_day_hour === a)) {
              if (data28003.filter((b: any) => b.year_month_day_hour === a)[0]['データ数'] >= 10) {
                tmp_list.push(data28003.filter((b: any) => b.year_month_day_hour === a)[0]);
              } else {
                tmp_list.push({
                  map: data28003[0]['map'],
                  year_month: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data280013.find((d: any) => d.year_month_day_hour === a)) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month_day_hour === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
        case 2: // 日ごと
          date_calc().forEach((a: string) => {
            if (data28003.find((b: any) => b.year_month_day === a)) {
              if (data28003.filter((b: any) => b.year_month_day === a)[0]['データ数'] >= 10) {
                tmp_list.push(data28003.filter((b: any) => b.year_month_day === a)[0]);
              } else {
                tmp_list.push({
                  map: data28003[0]['map'],
                  year_month: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data280013.find((d: any) => d.year_month_day === a)) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month_day === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
        case 3: // 月ごと
          date_calc().forEach((a: string) => {
            if (data28003.find((b: any) => b.year_month === a)) {
              if (data28003.filter((b: any) => b.year_month === a)[0]['データ数'] >= 10) {
                tmp_list.push(data28003.filter((b: any) => b.year_month === a)[0]);
              } else {
                tmp_list.push({
                  map: data28003[0]['map'],
                  year_month: a,
                  標準偏差s: null,
                  範囲r: null,
                  工程能力cp: null,
                  工程能力cpk: null
                });
              }
            } else {
              tmp_list.push({
                map: data28003[0]['map'],
                year_month: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }

            // 範囲Rを全権取得のため
            if (data280013.find((d: any) => d.year_month === a)) {
              tmp_list_r.push(data280013.filter((c: any) => c.year_month === a)[0]);
            } else {
              tmp_list_r.push({
                map: data28003[0]['map'],
                lq_time: a,
                標準偏差s: null,
                範囲r: null,
                工程能力cp: null,
                工程能力cpk: null
              });
            }
          });
          setData28003_show(tmp_list);
          setData28003_r_show(tmp_list_r);
          break;
      }
    }
  }, [data28003, data280013]);

  useEffect(() => {
    const checkZero = (a: string | null) => {
      if (a !== null) {
        return Number(a);
      } else {
        return null;
      }
    };

    if (searchValue.searchType !== 0) {
      setData208XYZ([
        {
          data:
            data28003_show.filter((a: any) => a.標準偏差s).length > 0
              ? data28003_show.map((a: any) => checkZero(a.標準偏差s))
              : null,
          name: data28003_show.filter((a: any) => a.標準偏差s).length > 0 ? '標準偏差S' : null
        },
        {
          // 範囲Rを全権取得のため
          data:
            data28003_r_show.filter((a: any) => a.範囲r).length > 0
              ? data28003_r_show.map((a: any) => checkZero(a.範囲r))
              : null,
          name: data28003_r_show.filter((a: any) => a.範囲r).length > 0 ? '範囲R' : null
        },
        {
          data:
            data28003_show.filter((a: any) => a.工程能力cp).length > 0
              ? data28003_show.map((a: any) => checkZero(a.工程能力cp))
              : null,
          name: data28003_show.filter((a: any) => a.工程能力cp).length > 0 ? '工程能力Cp' : null
        },
        {
          data:
            data28003_show.filter((a: any) => a.工程能力cpk).length > 0
              ? data28003_show.map((a: any) => checkZero(a.工程能力cpk))
              : null,
          name: data28003_show.filter((a: any) => a.工程能力cpk).length > 0 ? '工程能力Cpk' : null
        }
      ]);
    } else {
      setData208XYZ([
        {
          data:
            data28003.filter((a: any) => a.移動範囲rs).length > 0
              ? data28003.map((a: any) => checkZero(a.移動範囲rs))
              : null,
          map: data28003.map((a: any) => a.map),
          lq_time: data28003.map((a: any) => a.lq_time),
          name: t('移動範囲Rm')
        }
      ]);
    }
  }, [data28003_show, data28003_r_show]);

  const date_calc = useCallback(() => {
    let datelist: any = [];
    let from_day = new Date(searchValue.select_date_from);
    let to_day = new Date(searchValue.select_date_to);

    switch (searchValue.searchType) {
      case 0:
        return Array.from(new Set(data208.map(({lq_time}) => lq_time))).sort();
      case 1:
        for (let d = from_day; d <= to_day; d.setHours(d.getHours() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD HH'));
        }
        return datelist.sort();
      case 2:
        for (let d = from_day; d <= to_day; d.setDate(d.getDate() + 1)) {
          datelist.push(dayjs(d).format('YYYY-MM-DD'));
        }
        return datelist.sort();
      case 3:
        //月またぎを考慮して、選択月の月初を基準に月を加算する
        let start_month_1day: any = new Date(from_day.setDate(1));
        let end_month_1day: any = new Date(to_day.setDate(1));
        for (
          start_month_1day;
          start_month_1day <= end_month_1day;
          start_month_1day.setMonth(start_month_1day.getMonth() + 1)
        ) {
          datelist.push(dayjs(start_month_1day).format('YYYY-MM'));
        }
        return datelist.sort();
    }
  }, [startProcess]);

  const average = (numbers: number[]) => {
    const reducer = (
      accumulator: number,
      currentValue: number,
      _: number,
      {length}: {length: number}
    ) => accumulator + currentValue / length;
    return numbers.reduce(reducer, 0);
  };

  const stdev = (datas: any) => {
    if (datas.length > 0) {
      const avr = datas.reduce((a: number, b: number) => a + b) / datas.length;
      const Var = datas.reduce((a: number, b: number) => a + (b - avr) ** 2, 0) / datas.length;
      return Math.sqrt(Var);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    if (data208.length > 0) {
      let master_radius: any = distinct(data208, 'master_radius').map((item: any) => Number(item));
      let radius_upper_limit: any = distinct(data208, 'radius_upper_limit').map((item: any) =>
        Number(item)
      );
      let radius_lower_limit: any = distinct(data208, 'radius_lower_limit').map((item: any) =>
        Number(item)
      );

      let d3: number =
        Number(master_radius.length > 1 ? average(master_radius) : master_radius) +
        Number(radius_upper_limit.length > 1 ? average(radius_upper_limit) : radius_upper_limit);

      let d5: number =
        Number(master_radius.length > 1 ? average(master_radius) : master_radius) +
        Number(radius_lower_limit.length > 1 ? average(radius_lower_limit) : radius_lower_limit);

      let d8: number = Number(average(data208.map((item: any) => item.work_radius)));

      let d9: number = Quartile(
        data208.map((a: any) => Number(a.work_radius)),
        0.5
      );

      let d14: number =
        (d3 - d5) / (stdev(data208.map((item: any) => Number(item.work_radius))) * 6);

      let d16_tmp1: number =
        Math.abs(d3 - d8) / (stdev(data208.map((item: any) => Number(item.work_radius))) * 3);

      let d16_tmp2: number =
        Math.abs(d8 - d5) / (stdev(data208.map((item: any) => Number(item.work_radius))) * 3);

      let d16: number;
      d16 = d16_tmp1 <= d16_tmp2 ? d16_tmp1 : d16_tmp2;

      // d1: 0, //目標
      //   d2: '', //目標info
      //   d3: 0, //上限
      //   d4: '', //上限info
      //   d5: 0, //下限
      //   d6: '', //下限info
      //   d7: 0, //最大
      //   d8: 0, //平均
      //   d9: 0, //中央
      //   d10: 0, //最小
      //   d11: 0, //標準偏差
      //   d12: 0, //管理限界線1
      //   d13: 0, //管理限界線2
      //   d14: 0, //工程能力1
      //   d15: '', //工程能力1info
      //   d16: 0, //工程能力2
      //   d17: '', //工程能力2info
      //   d18: 0, //仕様限界LSL
      //   d19: 0,//仕様限界USL

      setData208MaxAvgMin({
        d1: master_radius.length > 1 ? average(master_radius) : master_radius[0],
        d2: master_radius.length > 1 ? t('※規格複数のため参考値') : '',
        d3: d3,
        d4: radius_upper_limit.length > 1 ? t('※規格複数のため参考値') : '',
        d5: d5,
        d6: radius_lower_limit.length > 1 ? t('※規格複数のため参考値') : '',
        d7: Math.max(...data208.map((item: any) => item.work_radius)),
        d8: d8,
        d9: d9,
        d10: Math.min(...data208.map((item: any) => item.work_radius)),
        d11: stdev(data208.map((item: any) => Number(item.work_radius))),
        d12: d8 + 3 * stdev(data208.map((item: any) => Number(item.work_radius))),
        d13: d8 - 3 * stdev(data208.map((item: any) => Number(item.work_radius))),
        d14: d14,
        d15:
          radius_upper_limit.length > 1 || radius_lower_limit.length > 1
            ? t('※規格複数のため参考値')
            : '',
        d16: d16,
        d17:
          radius_upper_limit.length > 1 || radius_lower_limit.length > 1
            ? t('※規格複数のため参考値')
            : '',
        d18: master_radius + radius_lower_limit[0], //仕様限界LSL：master_radius + radius_lower_limit
        d19: master_radius + radius_upper_limit[0] //仕様限界USL：master_radius + radius_upper_limit
      });
    } else {
      setData208MaxAvgMin(data208MaxAvgMin_init);
    }
  }, [data208]);

  const [xLineMin, setXLineMin] = useState(0);
  const [xLineMax, setXLineMax] = useState(0);
  const [xRenge, setXRenge] = useState(0);

  useEffect(() => {
    let tmp_n: number = data208.length;
    let tmp_k: number;
    if (Math.floor(Math.log2(tmp_n) + 1) <= 7) {
      tmp_k = 7;
    } else if (Math.floor(Math.log2(tmp_n) + 1) >= 27) {
      tmp_k = 27;
    } else {
      tmp_k = Math.floor(Math.log2(tmp_n) + 1);
    }
    // 「最大値」「上限(仕様)」「管理限界線(+3σ)」
    let tmp_max: number = Math.max(data208MaxAvgMin.d7, data208MaxAvgMin.d3, data208MaxAvgMin.d12);
    setXLineMax(tmp_max);
    // 最小値」「下限(仕様)」「管理限界線(ー3σ)」
    let tmp_min: number =
      Math.floor(Math.min(data208MaxAvgMin.d10, data208MaxAvgMin.d5, data208MaxAvgMin.d13) * 100) /
      100;

    setXLineMin(tmp_min);
    let tmp_h: number = (tmp_max - tmp_min) / tmp_k;
    let tmp_dot: number = String(tmp_h).indexOf('.');
    let tmp_round: number;
    if (tmp_dot > 0) {
      tmp_round = Math.floor(tmp_h * (tmp_dot * 100)) / (tmp_dot * 100);
    } else {
      tmp_round = Math.floor(tmp_h);
    }
    setXRenge(tmp_round);
  }, [data208MaxAvgMin]);

  useEffect(() => {
    if (xRenge !== 0) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2091,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2091',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  }, [xRenge]);

  const restSearch = () => {
    setStartProcess(false);
    setSearchValue({
      ...searchValue,
      select_date_from: props.searchValue.select_date_from,
      select_date_to: props.searchValue.select_date_to,
      work: props.searchValue.work,
      deviceid: props.searchValue.deviceid,
      map: [props.datas.mapName],
      judge: props.searchValue.judge,
      searchType: props.searchValue.searchType
    });
    setData208([]);
    setData209([]);
    setData208XYZ([]);
    setGraphBaseData0([]);
    setData209SelectDate('');
    setData208MaxAvgMin(data208MaxAvgMin_init);
    setAutoCompleteReset(true);
  };

  const getDataWithDate = (datas: any) => {
    setData209SelectDate(datas);
  };

  const getDataByData = (datas: any) => {
    if (searchValue.searchType !== 0) {
      setData209SelectDate(datas.data.lq_time);
    } else {
      setData209SelectDate(datas.data.serial);
    }
  };

  const [zoomChartOpen, setZoomChartOpen] = useState<string>('');

  // グラフクリック時
  useEffect(() => {
    // 検査ごと：検索
    if (data209SelectDate !== '' && searchValue.searchType === 0) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2095,
        parameters: {
          select_serial: data209SelectDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2095',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 時：検索
    if (data209SelectDate !== '' && searchValue.searchType === 1) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2094,
        parameters: {
          select_lq_date_hour: dayjs(data209SelectDate).format('YYYY-MM-DD HH'),
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2094',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 日：検索
    if (data209SelectDate !== '' && searchValue.searchType === 2) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2092,
        parameters: {
          select_lq_date: data209SelectDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2092',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 月：検索
    if (data209SelectDate !== '' && searchValue.searchType === 3) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2093,
        parameters: {
          select_date_month: data209SelectDate,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2093',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 選択解除
    if (isEmpty(data209SelectDate) && xRenge !== 0) {
      setStartProcess(true);
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2091,
        parameters: {
          select_date_from: searchValue.select_date_from,
          select_date_to: searchValue.select_date_to,
          x_renge: xRenge,
          ...searchPrams
        },
        ds_state: setData209,
        name: 'id-2091',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  }, [data209SelectDate]);

  // 穴分布のグラフクリックして、クリックした位置の一覧表を出力
  const g2click = (datas: any) => {
    setStartProcess(true);

    let work_radius_min: number | null = null;
    let work_radius_max: number | null = null;

    if (datas.name !== undefined) {
      work_radius_min = datas.name.split('_')[0];
      work_radius_max = datas.name.split('_')[1];
    }

    let fromDate: any = searchValue.select_date_from;
    let toDate: any = searchValue.select_date_to;
    if (data209SelectDate !== '') {
      fromDate = dayjs(data209SelectDate).format('YYYY-MM-DD HH:mm:ss');
      toDate = dayjs(data209SelectDate).add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    }

    // 検査ごと：検索、シリアル選択時
    if (searchValue.searchType === 0 && !isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2096,
        parameters: {
          select_serial: data209SelectDate,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-2096',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
    // 検査ごと：検索、シリアル選択なし
    if (searchValue.searchType === 0 && isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2097,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-2097',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 時：検索：時間選択あり
    if (searchValue.searchType === 1 && !isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 214,
        parameters: {
          select_lq_date_hour: dayjs(data209SelectDate).format('YYYY-MM-DD HH'),
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-214',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 時：検索　時間選択なり
    if (searchValue.searchType === 1 && isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2097,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-2097',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 日　：検索
    if (searchValue.searchType === 2) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 210,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-210',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }

    // 年月：検索　時間選択あり
    if (searchValue.searchType === 3 && !isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 2099,
        parameters: {
          select_month: data209SelectDate,
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-2099',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
    // 年月：検索　時間選択なし
    if (searchValue.searchType === 3 && isEmpty(data209SelectDate)) {
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 210,
        parameters: {
          select_date_from: fromDate,
          select_date_to: toDate,
          work_radius_min: work_radius_min,
          work_radius_max: work_radius_max,
          ...searchPrams
        },
        ds_state: setData210,
        name: 'id-216',
        cancelToken: source.token,
        t
      }).then(() => setStartProcess(false));
    }
  };

  const getAllList = () => {
    setStartProcess(true);
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 210,
      parameters: {
        select_date_from: searchValue.select_date_from,
        select_date_to: searchValue.select_date_to,
        work_radius_min: null,
        work_radius_max: null,
        ...searchPrams
      },
      ds_state: setData210,
      name: 'id-210',
      cancelToken: source.token,
      t
    }).then(() => setStartProcess(false));
  };

  useEffect(() => {
    if (data210.length > 0) {
      setDetailListOpen(true);
    }
  }, [data210]);

  useEffect(() => {
    if (!props.open) {
      setData208([]);
      setData209([]);
      setData208XYZ([]);
      setGraphBaseData0([]);
      setData209SelectDate('');
      setData208MaxAvgMin(data208MaxAvgMin_init);
    }
  }, [props.open]);

  useEffect(
    () => () => {
      source.cancel('リクエストをキャンセルしてページ移動');
    },
    []
  );

  const [detailListOpen, setDetailListOpen] = useState(false);

  const listDigClose = () => {
    setDetailListOpen(false);
  };

  const toLocalStringEx = (params: number) => {
    return Number(params).toLocaleString();
  };

  let data208_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('最大'),
    d1: t('Q3'),
    d2: t('中央(orQ2)'),
    d3: t('Q1'),
    d4: t('最小'),
    d5: t('群平均'),
    d6: t('群中央'),
    d7: t('目標'),
    d8: t('上限'),
    d9: t('下限'),
    d10: t('中央'),
    d11: t('平均'),
    d12: t('平均＋3σ'),
    d13: t('平均－3σ')
  };

  let data208_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    work_radius: t('穴径'),
    serial: t('シリアルナンバー')
  };

  let data208XYZ_column: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('標準偏差'),
    d1: t('範囲(最大最小差)'),
    d2: t('Cp'),
    d3: t('Cpk')
  };

  let data208XYZ_column_type0: any = {
    work: t('機種'),
    deviceid: t('検査装置'),
    map: t('検査部位'),
    for_export: t('検査日時'),
    d0: t('移動範囲(前結果差)'),
    serial: t('シリアルナンバー')
  };

  let data209_column: any = {
    work_radius: t('穴径'),
    カウント数: t('カウント数')
  };

  let tmp_data: any = [];
  let tmp_rs: any = [];
  let tmp: any = [];

  useEffect(() => {
    if (data208.length > 0) {
      date_calc().forEach((lq_time_string: string) => {
        switch (searchValue.searchType) {
          case 0: //検査ごと
            tmp = [];
            data208.forEach((item: any) => {
              if (String(item.lq_time) === lq_time_string) {
                tmp.push(Number(item['work_radius']));
              }
            });
            tmp_data.push(tmp);

            if (tmp_data[0].length > 0) {
              tmp_data[0].forEach((a: any, idx: number, array: any) => {
                if (idx - 1 >= 0) {
                  tmp_rs.push(Math.abs(Number(a) - Number(array[idx - 1])));
                } else {
                  tmp_rs.push(null);
                }
              });
            } else {
              tmp_rs = [];
            }
            break;
          case 1: //時間ごと
            tmp = [];
            data208.forEach((item: any) => {
              if (String(item.year_month_day_hour) === lq_time_string) {
                tmp.push(Number(item['work_radius']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 2: //日ごと
            tmp = [];
            data208.forEach((item: any) => {
              if (String(item.year_month_day) === lq_time_string) {
                tmp.push(Number(item['work_radius']));
              }
            });
            tmp_data.push(tmp);
            break;
          case 3: //月ごと
            tmp = [];
            data208.forEach((item: any) => {
              if (String(item.year_month) === lq_time_string) {
                tmp.push(Number(item['work_radius']));
              }
            });
            tmp_data.push(tmp);
            break;
        }
      });

      let tmp_data_avg: any = [];
      let tmp_data_avg_mel: any = [];
      let tmp_data_middle_mel: any = [];
      let tmp_data_middle: any = [];
      let tmp_data_lcl: any = [];
      let tmp_data_ucl: any = [];
      let tmp_data_target: any = [];
      let tmp_data_lsl: any = [];
      let tmp_data_usl: any = [];

      let tmp_lower_limit: any = [];
      let tmp_upper_limit: any = [];

      tmp_data.forEach((a: any, index: number) => {
        if (a.length > 0) {
          tmp_data_avg.push(data208MaxAvgMin.d8);
          tmp_data_avg_mel.push(average(a));
          tmp_data_middle_mel.push(Quartile(a, 0.5));
          tmp_data_middle.push(data208MaxAvgMin.d9);
          tmp_data_lcl.push(data208MaxAvgMin.d12);
          tmp_data_ucl.push(data208MaxAvgMin.d13);
          tmp_data_target.push(data208MaxAvgMin.d1);
          tmp_data_lsl.push(data208MaxAvgMin.d5);
          tmp_data_usl.push(data208MaxAvgMin.d3);
          tmp_lower_limit.push(data208MaxAvgMin.d5);
          tmp_upper_limit.push(data208MaxAvgMin.d3);
        } else {
          tmp_data_avg.push(data208MaxAvgMin.d8);
          tmp_data_avg_mel.push('');
          tmp_data_middle_mel.push('');
          tmp_data_middle.push(data208MaxAvgMin.d9);
          tmp_data_lcl.push(data208MaxAvgMin.d12);
          tmp_data_ucl.push(data208MaxAvgMin.d13);
          tmp_data_target.push(data208MaxAvgMin.d1);
          tmp_data_lsl.push(data208MaxAvgMin.d5);
          tmp_data_usl.push(data208MaxAvgMin.d3);
          tmp_lower_limit.push(data208MaxAvgMin.d5);
          tmp_upper_limit.push(data208MaxAvgMin.d3);
        }
      });

      setGraphBaseData0({
        id: data208.map((a: any, index: number) => index + 1),
        map: data208.map((a: any) => a.map),
        lq_time: data208.map((a: any) => a.lq_time),
        data_value: tmp_data,
        data_avg: tmp_data_avg, //平均
        data_avg_mel: tmp_data_avg_mel, //群平均
        data_middle_mel: tmp_data_middle_mel, //群中央
        data_middle: tmp_data_middle, //中央
        data_lcl: tmp_data_lcl,
        data_ucl: tmp_data_ucl,
        data_target: tmp_data_target,
        data_lsl: tmp_data_lsl,
        data_usl: tmp_data_usl,
        x_list: date_calc(),
        lower_limit: tmp_lower_limit,
        upper_limit: tmp_upper_limit
      });
    }
  }, [data208MaxAvgMin]);

  const [autoCompleteReset, setAutoCompleteReset] = useState(false);
  const [work, setWork] = useState<{label: string}[]>([]);
  const [deviceid, setDeviceid] = useState<{label: string}[]>([]);
  const [map, setMap] = useState<{label: string}[]>([]);
  useEffect(() => {
    // マウント時処理
    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42009,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['work']};
        });
        setWork(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：map：t_lqresult_hole_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42011,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['map']};
        });
        setMap(tmp);
      })
      .catch(e => e);

    // 情報取得： LFCAutocomplete：deviceid：t_lqresult_hole_compare
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 42010,
      parameters: {},
      cancelToken: source.token,
      t
    })
      .then(ds => {
        const tmp: {label: string}[] = ds.map((item: any) => {
          return {label: item['deviceid']};
        });
        setDeviceid(tmp);
      })
      .then(() => setStartProcess(false))
      .catch(e => e);

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  const autoOnChange = (relayDatas: any) => {
    setSearchValue({...searchValue, [relayDatas.name]: relayDatas.data});
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={props.onClose}>{props.pageName}</LFCDialogTitle>
        <DialogContent>
          <ProgressBar startProcess={startProcess} />
          <Box>
            <form className={classes.search}>
              <LFCFormRowGroup>
                <LFCDatetimePicker
                  name={t('select_date_from')}
                  label={t('対象期間From')}
                  value={searchValue.select_date_from}
                  required
                  onChange={handleChange}
                  disabled={startProcess}
                />
                <LFCDatetimePicker
                  name={t('select_date_to')}
                  label={t('対象期間To')}
                  value={searchValue.select_date_to}
                  required
                  onChange={handleChange}
                  disabled={startProcess}
                />
                <LFCAutocomplete
                  name={t('work')}
                  label={t('機種')}
                  id={'work'}
                  size="small" // small/medium
                  value={multiSelectData(work, searchValue.work)}
                  // style={{width: 500}}
                  onChange={autoOnChange}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  multiple={true}
                  selectItem={work}
                  disabled={startProcess}
                />
                <LFCAutocomplete
                  name={t('map')}
                  label={t('検査部位')}
                  id={'map'}
                  size="small" // small/medium
                  value={[map[map.findIndex((a: any) => a.label === props.datas.mapName)]]}
                  // style={{width: 500}}
                  onChange={autoOnChange}
                  multiple={true}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  selectItem={map}
                  disabled={startProcess}
                />
                <LFCAutocomplete
                  name={'deviceid'}
                  label={t('検査装置')}
                  id={'deviceid'}
                  size="small" // small/medium
                  value={multiSelectData(deviceid, props.searchValue.deviceid)}
                  // style={{width: 500}}
                  onChange={autoOnChange}
                  multiple={true}
                  onReset={autoCompleteReset}
                  doneReset={setAutoCompleteReset}
                  selectItem={deviceid}
                  disabled={startProcess}
                />
                <LFCSelectFormJudge
                  name={'judge'}
                  value={searchValue.judge}
                  onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
                  multiple={false}
                  disabled={startProcess}
                />

                <LFCButton color="primary" onClick={doSearch} disabled={startProcess}>
                  {t('検索')}
                </LFCButton>
                <LFCButton onClick={restSearch} disabled={startProcess}>
                  {t('リセット')}
                </LFCButton>
              </LFCFormRowGroup>
            </form>
            <div style={{float: 'left', margin: 10, paddingRight: 10}}>{t('集計単位')}:</div>
            <div style={{float: 'right'}}>
              <LFCButton size={'small'} color="secondary" onClick={getAllList}>
                {t('詳細データ')}
              </LFCButton>
            </div>
            <RadioGroup row aria-label="search_type" name="row-radio-buttons-group">
              {searchType.map((item: any) => (
                <FormControlLabel
                  disabled={startProcess}
                  value={item.id}
                  key={'search_type-' + item.id}
                  control={
                    <Radio onChange={onChangeRadio} checked={item.id === searchValue.searchType} />
                  }
                  label={
                    <Typography display="block" key={'search_type-body-' + item.id}>
                      {item.text}
                    </Typography>
                  }
                />
              ))}
            </RadioGroup>
          </Box>
          <Box mt={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                {searchValue.searchType !== 0 ? (
                  <LFCChartsBoxWithLine2
                    title={t('穴径推移')}
                    source={graphBaseData0}
                    x={{dsColumn: '', type: 'category'}}
                    y={{dsColumn: '', type: 'value'}}
                    exportData={data208}
                    exportFields={data208_column}
                    exportFilename={`HoleCompare(${t('穴径')})_${t('推移')}`}
                    height={'35vh'}
                    top={'10%'}
                    bottom={'20%'}
                    // mapName={searchValue.map}
                    onClick={getDataWithDate}
                    zoomChartId={'zoom-chart-1'}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
                    zoomChartsClose={() => setZoomChartOpen('')}
                    xlist={date_calc()}
                  />
                ) : (
                  <LFCChartsLineHoleCompare2
                    title={t('穴径推移')}
                    source={data208}
                    x={{dsColumn: 'idx'}}
                    yAxis={{
                      type: 'line',
                      name: `${t('測定値')}X`,
                      dsColumn: 'work_radius'
                    }}
                    color={['rgb(106,113,255)']}
                    exportData={data208}
                    exportFields={data208_column_type0}
                    exportFilename={`HoleCompare(${t('穴径')})_${t('推移')}`}
                    // mapName={searchValue.map}
                    onClick={getDataByData}
                    zoomChartId={'zoom-chart-1'}
                    zoomedChartId={zoomChartOpen}
                    zoomCharts={() => setZoomChartOpen('zoom-chart-1')}
                    zoomChartsClose={() => setZoomChartOpen('')}
                  />
                )}
              </Grid>
              <Grid item xs={12} md={5}>
                {data209SelectDate !== '' ? (
                  <>
                    {searchValue.searchType !== 0
                      ? `${t('選択日')}:` + data209SelectDate
                      : `${t('選択シリアル')}:` + data209SelectDate}
                    <LFCButton
                      size={'small'}
                      className={classes.mrgLeft}
                      onClick={() => setData209SelectDate('')}
                    >
                      {t('選択解除')}
                    </LFCButton>
                  </>
                ) : (
                  <></>
                )}
                <LFCChartsBarWorkRadius
                  title={t('穴径分布')}
                  source={data209}
                  extraDatas={data208MaxAvgMin}
                  exportData={data209}
                  exportFields={data209_column}
                  exportFilename={`HoleCompare(${t('穴径')})_${t('分布')}`}
                  xAxisName={`(${t('個')})`}
                  height={'25vh'}
                  // top={"10%"}
                  bottom={'35%'}
                  onClick={g2click}
                  zoomChartId={'zoom-chart-2'}
                  zoomedChartId={zoomChartOpen}
                  zoomCharts={() => setZoomChartOpen('zoom-chart-2')}
                  zoomChartsClose={() => setZoomChartOpen('')}
                  xLineMin={xLineMin}
                  xLineMax={xLineMax}
                  xRenge={xRenge}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <div id={'zoom-chart-3'}>
                  {searchValue.searchType !== 0 ? (
                    <LFCChartsLineMulti1
                      title={`${t('ばらつき推移')}`}
                      source={data208XYZ}
                      xData={
                        searchValue.searchType !== 0 ? date_calc() : data208.map((a: any) => a.idx)
                      }
                      color={['rgb(106,113,255)']}
                      exportData={data208}
                      exportFields={data208XYZ_column}
                      exportFilename={`HoleCompare(${t('穴径')})_${t('ばらつき')}`}
                      zoomChartId={'zoom-chart-3'}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  ) : (
                    <LFCChartsLineMultiBySearch1
                      title={`${t('ばらつき推移')}`}
                      source={data208XYZ}
                      xData={
                        searchValue.searchType !== 0 ? date_calc() : data208.map((a: any) => a.idx)
                      }
                      color={['rgb(106,113,255)']}
                      exportData={data208}
                      exportFields={data208XYZ_column_type0}
                      exportFilename={`HoleCompare(${t('穴径')})_${t('ばらつき')}`}
                      zoomChartId={'zoom-chart-3'}
                      zoomedChartId={zoomChartOpen}
                      zoomCharts={() => setZoomChartOpen('zoom-chart-3')}
                      zoomChartsClose={() => setZoomChartOpen('')}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} md={5}>
                <TableContainer
                  component={Paper}
                  className={mySettings.isDark ? classes.tableLineDark : classes.tableLine}
                >
                  <Table sx={{minWidth: 600}} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center" colSpan={2}>
                          {t('規格')}
                          <Box sx={{fontSize: '8px'}}>{data208MaxAvgMin.d2}</Box>
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('実績')}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('傾向ばらつき管理')}
                        </TableCell>
                        <TableCell align="center" colSpan={2}>
                          {t('工程能力')}
                          <Box sx={{fontSize: '8px'}}>
                            {data208MaxAvgMin.d2 !== '' || data208MaxAvgMin.d15 !== ''
                              ? t('※規格複数のため参考値')
                              : ''}
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{t('上限')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d3)} mm
                        </TableCell>
                        <TableCell align="center">{t('最大')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d7)} mm
                        </TableCell>
                        <TableCell align="center">{t('平均+3σ')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d12)} mm
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('Cp')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(data208MaxAvgMin.d14)}{' '}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" rowSpan={2}>
                          {t('目標')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(data208MaxAvgMin.d1)} mm
                        </TableCell>
                        <TableCell align="center">{t('平均')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d8)} mm
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('標準偏差σ')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(data208MaxAvgMin.d11)} mm
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">{t('中央')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d9)} mm
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {t('Cpk')}
                        </TableCell>
                        <TableCell align="center" rowSpan={2}>
                          {toLocalStringEx(data208MaxAvgMin.d16)}{' '}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">{t('下限')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d5)} mm
                        </TableCell>
                        <TableCell align="center">{t('最小')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d10)} mm
                        </TableCell>
                        <TableCell align="center">{t('平均-3σ')}</TableCell>
                        <TableCell align="center">
                          {toLocalStringEx(data208MaxAvgMin.d13)} mm
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>

          <Dialog open={detailListOpen} onClose={listDigClose} maxWidth={false} fullWidth>
            <LFCDialogTitle onClose={listDigClose}>{`${t('menu.page_name.Hole Compare 穴径')}：${t(
              '詳細データ'
            )}`}</LFCDialogTitle>
            <DialogContent>
              <LFCDataGridSimple
                columns={export_detail_HoleDiameterDetail}
                rows={data210}
                height="80vh"
                exportFilename={`HoleCompare(${t('穴径')})_${t('詳細データ')}`}
              />
            </DialogContent>
          </Dialog>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default HoleCompareDiameterDig;
