import {useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import LFCChartsInputScaleDialog, {
  initialScaleValue,
  ScaleValue
} from './components/LFCChartsInputScale';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  bottom?: string;
  yAxisName: string;
  inputScale?: boolean;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsBarSearchDay01 = (props: Props) => {
  const {t} = useTranslation();
  let x_list: any = [...Array(24)].map((_, i) => i);

  const [scaleValue, setScaleValue] = useState<ScaleValue>(initialScaleValue);
  const [inputScaleOpen, setInputScaleOpen] = useState(false);

  if (!props.source.find((item: any) => item['時刻'] === '00')) {
    props.source.unshift({
      検査数累計: 0,
      時刻: '00'
    });
  }

  let y_list: any = [];
  x_list.forEach((datatime: string) => {
    props.source.forEach((item: any) => {
      if (Number(datatime) === Number(item['時刻'])) {
        if (
          y_list.find((y_data: any) => y_data['時刻'] !== '00') &&
          y_list.find((y_data: any) => y_data['検査数累計'] !== 0)
        ) {
          y_list.pop();
        }
        y_list.push({
          検査数累計: item['検査数累計'],
          時刻: item['時刻']
        });
      } else {
        if (!y_list.find((data: any) => Number(data['時刻']) === Number(datatime))) {
          y_list.push({
            検査数累計: '',
            時刻: datatime
          });
        }
      }
    });
  });

  // スケール変更
  const onApply = (scaleValue: ScaleValue) => {
    setScaleValue(scaleValue);
  };

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'category',
            name: t('時刻帯(時)'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            data: x_list,
            axisLabel: {
              interval: 0,
              rotate: '45',
              fontSize: 10
            }
          },
          yAxis: {
            type: 'value',
            name: props.yAxisName,
            nameTextStyle: {
              fontSize: 10
            },
            axisLabel: {
              interval: 0,
              fontSize: 10
            },
            min: scaleValue.y1AxisMin,
            max: scaleValue.y1AxisMax,
            interval: scaleValue.y1AxisInterval
          },
          series: [
            {
              data: y_list.map((item: any) => item['検査数累計']),
              type: 'bar'
            }
          ],
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return prm.name + '<br>' + t('検査数累計') + ':' + prm.value + t('個');
            }
            // position: "inside",
          },
          dataZoom: [
            {
              type: 'inside'
            }
          ],
          grid: {
            left: '10%',
            right: '5%',
            bottom: '18%'
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height ? props.height : ''}
        inputScaleStatus={props.inputScale !== undefined ? props.inputScale : false}
        inputScaleOpen={() => setInputScaleOpen(true)}
      />
      <LFCChartsInputScaleDialog
        open={inputScaleOpen}
        onClose={() => setInputScaleOpen(false)}
        onApply={onApply}
        y1AxisName={props.yAxisName}
        inputY1Axis={true}
      />
    </div>
  );
};
export default LFCChartsBarSearchDay01;
