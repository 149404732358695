import {Box, Grid, Typography} from '@mui/material';
import {
  DataGridPro,
  GridColumns,
  GridRowId,
  GridRowParams,
  useGridApiRef
} from '@mui/x-data-grid-pro';
import awsConfiguration from 'awsConfiguration';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import LFCSelectFormCompany from '_components-with-data/inputs/LFCSelectFormCompany';
import LFCConfirmDialog from '_components/feedback/LFCConfirmDialog';
import LFCButton from '_components/inputs/LFCButton';
import LFCTextField from '_components/inputs/LFCTextField';
import LFCFormRowGroup from '_components/layout/LFCFormRowGroup';
import ProgressBar from '_components/ProgressBar';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {ALL} from '_logics/LFCConst';
import {adminAPIAccess, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';

const UserMaster = () => {
  const {t} = useTranslation();
  const formRef = useRef<HTMLFormElement>(null!);
  const [dgColumns, setDgColumns] = useState<GridColumns>([]);
  const [dgRows, setDgRows] = useState<any>([]);
  const apiRef = useGridApiRef();
  const {enqueueSnackbar} = useSnackbar();
  const initialSearchValue = {
    company_id: ALL,
    user_id: '',
    email: ''
  };
  const [searchValue, setSearchValue] = useState(initialSearchValue);
  const [startProcess, setStartProcess] = useState(false);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [openDeleteConfirmDlg, setOpenDeleteConfirmDlg] = useState(false);

  // 検索ボタン押下時
  const onSearchClick = () => {
    setSelectionModel([]);
    setStartProcess(true);
    adminAPIAccess({
      apipath: 'GetUserList',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        company_id: searchValue.company_id === t('すべて') ? '' : searchValue.company_id,
        user_id: searchValue.user_id,
        email: searchValue.email
      }
    })
      .then(response => {
        enqueueSnackbar(t('message.ユーザを検索しました。'), {variant: 'success'});

        const userData: any[] = response.data.map((item: any, idx: any) => {
          return {
            id: String(idx),
            enabled: item['Enabled'] ? t('はい') : t('いいえ'),
            user_id: String(item['Username']),
            email: String(item['email']),
            company_id: String(item['company_id']),
            company_name: String(item['company_name'])
          };
        });
        setDgRows(userData);
        setStartProcess(false);
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      });
  };

  // リセットボタン押下時
  const onResetClick = () => {
    setSelectionModel([]);
    setDgRows([]);
    setStartProcess(false);
    setSearchValue(initialSearchValue);
  };

  // 無効化メッセージ作成
  const getConfirmMessgae = () => {
    const user_id_list: string[] = selectionModel.map((id, idx) => {
      return dgRows.filter((v: any) => v.id === id)[0].user_id;
    });
    return `${t('message.ユーザを無効化します、本当によろしいですか？')}[ ${user_id_list.join(
      ', '
    )} ]`;
  };

  // 無効化確認ダイアログでOKボタン押下時
  const deleteCognitoUser = () => {
    const delete_users: {user_id: string}[] = selectionModel.map((id, idx) => {
      return {user_id: dgRows.filter((v: any) => v.id === id)[0].user_id};
    });

    adminAPIAccess({
      apipath: 'DisableUser',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        disable_users: delete_users
      }
    })
      .then(response => {
        enqueueSnackbar(t('message.ユーザの無効化が完了しました。'), {variant: 'success'});
        onSearchClick();
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      });
  };

  useEffect(() => {
    // マウント時処理
    setDgColumns([
      {field: 'id', headerName: 'No', description: '', hide: true},
      {field: 'enabled', headerName: `${t('有効')}`, description: `${t('有効')}`, width: 100},
      {
        field: 'user_id',
        headerName: `${t('ユーザID')}`,
        description: `${t('ユーザID')}`,
        width: 200
      },
      {
        field: 'email',
        headerName: `${t('メールアドレス')}`,
        description: `${t('メールアドレス')}`,
        width: 500
      },
      {
        field: 'company_id',
        headerName: `${t('会社ID')}`,
        description: `${t('会社ID')}`,
        width: 100
      },
      {
        field: 'company_name',
        headerName: `${t('会社名')}`,
        description: `${t('会社名')}`,
        width: 300
      }
    ]);

    return () => {
      // アンマウント処理
    };
  }, []);
  return (
    <GenericTemplate title={t('ユーザマスタ')}>
      <ProgressBar startProcess={startProcess} />
      <form ref={formRef}>
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          alignItems="flex-start"
          justifyContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              {t(
                'message.Cognitoユーザの無効化を行います、各DB環境のm_userレコードは削除されません。'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LFCFormRowGroup>
                  <LFCSelectFormCompany
                    name={'company_id'}
                    value={searchValue.company_id}
                    onChange={event => handleInputChange2(event, searchValue, setSearchValue)}
                    required
                    allowAll
                  />
                  <LFCTextField
                    name="user_id"
                    label={t('ユーザID')}
                    value={searchValue.user_id}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                  />
                  <LFCTextField
                    name="email"
                    label={t('メールアドレス')}
                    value={searchValue.email}
                    onChange={event => {
                      handleInputChange(event, searchValue, setSearchValue);
                    }}
                  />
                  <LFCButton color="primary" onClick={onSearchClick}>
                    {t('検索')}
                  </LFCButton>
                  <LFCButton onClick={onResetClick}>{t('リセット')}</LFCButton>
                </LFCFormRowGroup>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <LFCButton
              color="secondary"
              onClick={() => {
                setOpenDeleteConfirmDlg(true);
              }}
              disabled={selectionModel.length === 0}
            >
              {t('message.ユーザを無効化する')}
            </LFCButton>
            <LFCConfirmDialog
              open={openDeleteConfirmDlg}
              message={getConfirmMessgae()}
              onClose={isOK => {
                setOpenDeleteConfirmDlg(false);
                isOK && deleteCognitoUser();
              }}
            ></LFCConfirmDialog>
          </Grid>
          <Grid item xs={12}>
            <LFCTitledCard title={t('Cognitoユーザープール')}>
              <Box style={{height: '60vh'}}>
                <DataGridPro
                  apiRef={apiRef}
                  columns={dgColumns}
                  rows={dgRows}
                  density={'compact'}
                  onSelectionModelChange={setSelectionModel}
                  selectionModel={selectionModel}
                  showCellRightBorder
                  showColumnRightBorder
                  checkboxSelection
                  disableSelectionOnClick
                  isRowSelectable={(params: GridRowParams) => params.row.enabled === 'はい'}
                />
              </Box>
            </LFCTitledCard>
          </Grid>
        </Grid>
      </form>
    </GenericTemplate>
  );
};
export default UserMaster;
