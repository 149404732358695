import React from 'react';
import * as echarts from 'echarts';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {round} from 'lodash';
import {Grid} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface Props {
  datas: any;
  deviceIndex?: any;
  onEvents?: any;
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  startDay?: string;
  infoData?: any;
  zoomLink?: any;
  zoomData?: any;
}

const LFCChartsGanttChartPersons: React.VFC<Props> = (props: Props) => {
  const {t} = useTranslation();
  const yellow = 'rgb(255,211,115)';
  const blue = 'rgb(0,187,255)';

  if (props.datas === undefined) {
    return <></>;
  } else {
    let height_calc: number = Number(props.deviceIndex.length) * 36;
    let start_day = new Date(String(props.startDay + ' 00:00'));
    let end_day = new Date(String(props.startDay + ' 23:59'));
    let start_ =
      start_day.toLocaleDateString().replaceAll('/', '-') +
      ' ' +
      start_day.toLocaleTimeString().padStart(2, '0');
    let end_ =
      end_day.toLocaleDateString().replaceAll('/', '-') +
      ' ' +
      end_day.toLocaleTimeString().padStart(2, '0');

    const seriesData: any = [];

    //machine_status
    let status_key = [0, 1];
    props.datas.forEach((item: any, index: number) => {
      let bgColor;
      switch (status_key.findIndex((machine_status: number) => machine_status === item.judge)) {
        case 0:
          bgColor = yellow;
          break;
        case 1:
          bgColor = blue;
          break;
      }

      let startTime = new Date(item.detection_time_from).getTime();
      let endTime = new Date(item.detection_time_to).getTime();
      seriesData.push({
        name: item.machine,
        value: [
          props.deviceIndex.findIndex((device_name: string) => device_name === item.machine),
          startTime,
          endTime,
          item.judge
        ],
        itemStyle: {
          color: bgColor
        }
      });
    });

    const option = {
      title: {
        text: `{a| ${t('カメラ名')} }{b| ${t('枠')} }{c| ${t('作業時間')}(${t('秒')}) }`,
        textStyle: {
          rich: {
            a: {
              fontSize: 16,
              width: 70,
              align: 'left'
            },
            b: {
              fontSize: 16,
              width: 50,
              align: 'right'
            },
            c: {
              fontSize: 16,
              width: 200,
              align: 'right'
            }
          }
        },
        subtext: `{a|  }{a|  }{b| ${t('最小')} }{b| ${t('平均')} }{c| ${t('最大')} }{d| ${t(
          '標準偏差'
        )} }{e| ${t('総作業時間')} }`,
        subtextStyle: {
          rich: {
            a: {
              fontSize: 14,
              height: 32,
              width: 90,
              padding: [0, 0],
              align: 'left',
              fontWeight: 'bold'
            },
            b: {
              fontSize: 12,
              // fontWeight: 'bold',
              height: 32,
              width: 50,
              align: 'right',
              fontWeight: 'bold'
            },
            c: {
              fontSize: 12,
              // fontWeight: 'bold',
              height: 32,
              width: 70,
              align: 'right',
              fontWeight: 'bold'
            },
            d: {
              fontSize: 12,
              height: 32,
              width: 100,
              align: 'right',
              fontWeight: 'bold'
            },
            e: {
              fontSize: 12,
              // fontWeight: 'bold',
              height: 32,
              width: 110,
              align: 'right',
              fontWeight: 'bold'
            }
          }
        },
        left: 'top'
      },
      tooltip: {
        formatter: (params: any) => {
          // let judge = '<br/>判定：' + (params.value[3] === 0 ? 'OK' : 'NG')
          let date1 = new Date(params.value[1]);
          let start_timestamp =
            [date1.getFullYear(), date1.getMonth() + 1, date1.getDate()].join('/') +
            ' ' +
            [date1.getHours(), date1.getMinutes(), date1.getSeconds()].join(':');
          let date2 = new Date(params.value[2]);
          let end_timestamp =
            [date2.getFullYear(), date2.getMonth() + 1, date2.getDate()].join('/') +
            ' ' +
            [date2.getHours(), date2.getMinutes(), date2.getSeconds()].join(':');
          let timeshow =
            '<div><span>Start Time : ' +
            start_timestamp +
            '</span><br/><span>End Time : ' +
            end_timestamp +
            '</span></div>';
          return params.marker + params.name + ':' + timeshow;
        }
      },
      grid: {
        top: 60,
        left: 570,
        right: 10,
        bottom: 20
        // height: 500,
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          filterMode: 'none',
          realtime: false,
          height: 12,
          bottom: 10,
          position: 'bottom',
          start: props.zoomData.start !== undefined ? props.zoomData.start : 0,
          end: props.zoomData.end !== undefined ? props.zoomData.end : 100,
          startValue: new Date(start_).getTime(),
          endValue: new Date(end_).getTime(),
          minValueSpan: 10,
          handleIcon: 'path://path://M100, 100m -75, 0a75,75 0 1,0 150,0a75,75 0 1,0 -150,0',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowColor: 'rgba(0, 0, 0, 0.5)',
            shadowBlur: 4
          },
          textStyle: {
            color: 'transparent'
          },
          dataBackground: {
            lineStyle: {
              width: 1
            },
            areaStyle: {
              color: 'transparent'
            }
          },
          fillerColor: 'rgba(126, 252, 246, 0.5)',
          borderColor: 'rgba(50, 50, 50, 0.3)',
          labelFormatter: ''
        }
      ],
      xAxis: {
        type: 'time',
        min: new Date(start_day).getTime(),
        max: new Date(end_day).getTime(),
        scale: true,
        position: 'top',
        minInterval: 3600,
        interval: 1,
        axisLabel: {
          show: true,
          margin: 15,
          fontSize: 10,
          rotate: '-20',
          formatter: (value: any, index: number) => {
            let date = new Date(value);
            return (
              ('0' + (date.getMonth() + 1)).slice(-2) +
              '/' +
              ('0' + date.getDate()).slice(-2) +
              ' ' +
              ('0' + date.getHours()).slice(-2) +
              ':' +
              ('0' + date.getMinutes()).slice(-2)
            );
          }
        },
        axisLine: {show: false},
        splitLine: {
          show: true
        },
        triggerEvent: (params: any, index: number) => {
          console.log('triggerEvent---params');
          console.log(params);
        }
      },
      yAxis: {
        axisLine: {
          onZero: false,
          show: false
        },
        axisLabel: {
          show: true,
          formatter: (data: any) => {
            let showName: any = props.infoData.filter((a: any) => a.key === data);
            if (showName[0] !== undefined) {
              let showNameArray: any = showName[0]['key'].split('..');
              return (
                '{a| ' +
                showNameArray[0] +
                ' }' +
                '{a| ' +
                showNameArray[1] +
                ' }' +
                '{b| ' +
                round(showName[0].data_min, 1).toLocaleString() +
                ' }' +
                '{c| ' +
                round(showName[0].data_avg, 1).toLocaleString() +
                ' }' +
                '{c| ' +
                round(showName[0].data_max, 1).toLocaleString() +
                ' }' +
                '{d| ' +
                round(showName[0].data_stddev, 1).toLocaleString() +
                ' }' +
                '{d| ' +
                round(showName[0].data_total, 1).toLocaleString() +
                ' }'
              );
            }
          },
          rich: {
            a: {
              fontSize: 14,
              fontWeight: 'bold',
              width: 80,
              align: 'left'
            },
            b: {
              fontSize: 14,
              fontWeight: 'bold',
              height: 32,
              width: 50,
              align: 'right'
            },
            c: {
              fontSize: 14,
              fontWeight: 'bold',
              height: 32,
              width: 80,
              align: 'right'
            },
            d: {
              fontSize: 14,
              fontWeight: 'bold',
              borderColor: '#449933',
              height: 32,
              width: 90,
              align: 'right'
            }
          }
        },
        splitLine: {
          show: true
        },
        inverse: true,
        data: props.deviceIndex
      },
      series: [
        {
          type: 'custom',
          renderItem: (params: any, api: any) => {
            let categoryIndex = api.value(0);
            let start = api.coord([api.value(1), categoryIndex]);
            let end = api.coord([api.value(2), categoryIndex]);
            let height = api.size([0, 1])[1] * 0.8;
            let rectShape = echarts.graphic.clipRectByRect(
              {
                x: start[0],
                y: start[1] - height / 2,
                width: end[0] - start[0],
                height: height
              },
              {
                x: params.coordSys.x,
                y: params.coordSys.y,
                width: params.coordSys.width,
                height: params.coordSys.height
              }
            );
            return (
              rectShape && {
                type: 'rect',
                shape: rectShape,
                style: api.style()
              }
            );
          },
          encode: {
            x: [1, 2],
            y: 0
          },
          data: seriesData
        }
      ]
    };
    return (
      <>
        <LFCBaseCharts
          option={option}
          onEvents={{
            datazoom: (params: any) => {
              if (props.zoomLink !== undefined) {
                props.zoomLink(params);
              }
            },
            click: (params: any) => {
              // props.onEvents(params)
              // console.log(params);
              // return params;
              // // if (props.onClick != null) {
              // //   console.log(params);
              // // }
            }
          }}
          exportData={props.exportData}
          exportFields={props.exportFields}
          exportFilename={props.exportFilename}
          height={height_calc !== 0 ? String(height_calc + 60) + 'px' : '80px'}
        />
        <Grid container justifyContent="center" spacing={2} style={{cursor: 'default'}}>
          <Grid item>
            <span style={{color: blue}}>&#9632;</span>
            <span style={{fontSize: 'smaller'}}>{t('作業中')}</span>
          </Grid>
          <Grid item>
            <span style={{color: yellow}}>&#9632;</span>
            <span style={{fontSize: 'smaller'}}>{t('作業範囲に不在')}</span>
          </Grid>
        </Grid>
      </>
    );
  }
};

export default LFCChartsGanttChartPersons;
