import {Box} from '@mui/material';

/**
 * 引数
 */
interface Props {
  children?: React.ReactNode;
  alignCenter?: boolean;
}

/**
 * LFCFormRowGroup
 */
const LFCFormRowGroup = (props: Props) => {
  return (
    <Box
      alignItems={props.alignCenter ? 'center' : undefined}
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      my={1}
      mx={0}
      columnGap={1}
      rowGap={2}
    >
      {props.children}
    </Box>
  );
};
export default LFCFormRowGroup;
