import {Dialog, DialogContent, Grid} from '@mui/material';
import {
  GridCallbackDetails,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  MuiEvent
} from '@mui/x-data-grid-pro';
import {useSnackbar} from 'notistack';
import React, {useEffect, useState} from 'react';
import LFCDialogTitle from '_components/feedback/LFCDialogTitle';
import LFCSingleValues from '_components/surfaces/LFCSingleValues';
import {fmtYMD} from '_logics/LFCFormatUtil';
import {rendJudgeDisp, rendTotalJudgeDisp} from '_logics/LFCRenderUtil';
import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import ProgressBar from '_components/ProgressBar';
import LFCDataGridSimple from '_components/datagrid/LFCDataGridSimple';
import SearchDetailGraph from './SearchDetailGraph';
import LFCTitledCard from '_components/surfaces/LFCTitledCard';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  open: boolean;
  onClose: () => void;
  datas: any;
}

const SearchDetailDig = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [startProcess, setStartProcess] = useState(true);

  const dsColumns: GridColDef[] = [
    {field: 'id', headerName: 'id', description: 'id', width: 100, hide: true},
    {field: 'map', headerName: 'map', description: 'map', width: 100, hide: false},
    {field: 'judge', headerName: 'judge', description: 'judge', width: 100, hide: true},
    {
      field: 'fitting',
      headerName: 'fitting',
      description: 'fitting',
      width: 90,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value),
      align: 'center'
    },
    {
      field: 'gap',
      headerName: 'gap',
      description: 'gap',
      width: 90,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value),
      align: 'center'
    },
    {
      field: 'pathsend',
      headerName: 'pathsend',
      description: 'pathsend',
      width: 90,
      hide: false,
      renderCell: (prms: GridRenderCellParams) => rendJudgeDisp(prms.value),
      align: 'center'
    }
  ];
  const [dsRows, setDsRows] = useState<any>([]);
  const [selectedDetail, setSelectedDetail] = useState<any>([]);
  const [selectedDetail_XYZ, setSelectedDetail_XYZ] = useState<any>([]);
  const [selectedDetail_GapHeader, setSelectedDetail_GapHeader] = useState<any>([]);
  const [selectedDetail_Gap, setSelectedDetail_Gap] = useState<any>([]);
  const [selectedDetail_Gap_Threshold, setSelectedDetail_Gap_Threshold] = useState<any>([]);

  const [imagePath, setImagePath] = useState<any>([]);

  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  useEffect(() => {
    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  /**
   * 検索処理
   */
  const doSearch = async () => {
    setSelectedDetail([]);
    setSelectedDetail_XYZ([]);
    setSelectedDetail_GapHeader([]);
    setSelectedDetail_Gap([]);
    setSelectedDetail_Gap_Threshold([]);
    await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 60013,
      parameters: {
        uuid: props.datas.uuid,
        limit: 5000,
        offset: 0
      },
      name: `LROBOT_${t('検索')}_${t('詳細')}`,
      cancelToken: source.token,
      t
    }).then((datas: any) => {
      if (datas.length > 0) {
        setDsRows(datas);
      }
    });
  };

  useEffect(() => {
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 131,
      parameters: {},
      ds_state: setImagePath,
      cancelToken: source.token,
      t
    });

    setStartProcess(true);
    if (props.open) {
      doSearch();
    } else {
      setDsRows([]);
    }
    setStartProcess(false);
  }, [props.open]);

  const [showFlag, setShowFlag] = React.useState('');

  useEffect(() => {
    setShowFlag('');
  }, [props.onClose]);

  let COLUMN_ORDER = [{name: 'id', order: 0}];

  const imageFileUrl = (params: any) => {
    if (params !== undefined) {
      return (
        <img src={imagePath[0].svalue + params} width="100%" style={{cursor: 'pointer'}} alt="" />
      );
    } else {
      return <></>;
    }
  };

  const onCellClick = async (
    params: GridCellParams,
    event: MuiEvent<React.MouseEvent>,
    details: GridCallbackDetails
  ) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (params.value == null) {
      setShowFlag('');
      return;
    }
    setStartProcess(true);
    Promise.allSettled([
      //LROBOT_検索_詳細
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60014,
        parameters: {
          uuid: params.row.uuid,
          map: params.row.map,
          limit: 1,
          offset: 0
        },
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setSelectedDetail({
            field: params.field,
            uuid: params.row.uuid,
            map: params.row.map,
            img: datas[0]['img_filename']
          });
        } else {
          setSelectedDetail({
            field: params.field,
            uuid: params.row.uuid,
            map: params.row.map,
            img: ''
          });
        }
      }),
      //LROBOT_検索_詳細_pop_fitting
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60015,
        parameters: {
          uuid: params.row.uuid,
          map: params.row.map,
          limit: 1,
          offset: 0
        },
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setSelectedDetail_XYZ(datas[0]);
        } else {
          setSelectedDetail_XYZ([]);
        }
      }),
      //LROBOT_検索_詳細_pop_gapheader
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60016,
        parameters: {
          uuid: params.row.uuid,
          map: params.row.map,
          limit: 1,
          offset: 0
        },
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setSelectedDetail_GapHeader(datas[0]);
        } else {
          setSelectedDetail_GapHeader([]);
        }
      }),
      //LROBOT_検索_詳細_pop_gap
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60017,
        parameters: {
          uuid: params.row.uuid,
          map: params.row.map,
          limit: 5000,
          offset: 0
        },
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setSelectedDetail_Gap(datas);
        } else {
          setSelectedDetail_Gap([]);
        }
      }),
      //LROBOT_検索_詳細_gap閾値
      getLFCData({
        snack: enqueueSnackbar,
        sql_id: 60018,
        parameters: {
          uuid: params.row.uuid,
          map: params.row.map,
          limit: 1,
          offset: 0
        },
        cancelToken: source.token,
        t
      }).then((datas: any) => {
        if (datas.length > 0) {
          setSelectedDetail_Gap_Threshold(datas[0]);
        } else {
          setSelectedDetail_Gap_Threshold([]);
        }
      })
    ]).then(() => {
      setStartProcess(false);
      setShowFlag(params.field);
    });
  };

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} maxWidth={false} fullWidth>
        <LFCDialogTitle onClose={props.onClose}>{`${t('検索')}：${t('詳細')}`}</LFCDialogTitle>
        <DialogContent>
          <ProgressBar startProcess={startProcess} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LFCSingleValues
                columns={[
                  {field: 'lq_time', headerName: t('検査日時'), formatter: fmtYMD},
                  {field: 'serial', headerName: `${t('シリアルNo')}`},
                  {
                    field: 'total_judge',
                    headerName: `${t('総合判定')}`,
                    formatter: rendTotalJudgeDisp
                  }
                ]}
                source={props.datas}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={5} xl={4}>
              <LFCTitledCard title={`${t('結果')}`}>
                <LFCDataGridSimple
                  columns={dsColumns}
                  rows={dsRows}
                  rowHeight={44}
                  height="60vh"
                  columnOrder={COLUMN_ORDER}
                  onCellClick={onCellClick}
                  exportFilename={`L-ROBOT_${t('検索')}_${t('詳細')}`}
                />
              </LFCTitledCard>
            </Grid>
            <Grid item xs={12} md={6} lg={7} xl={8}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={9}>
                  {showFlag.length > 0 ? (
                    <LFCSingleValues
                      columns={[{field: 'map', headerName: t('部位'), width: '300px'}]}
                      source={selectedDetail}
                    />
                  ) : (
                    false
                  )}
                  {showFlag === 'fitting' ? (
                    <LFCSingleValues
                      columns={[
                        {field: 'judge', headerName: `${t('判定')}`, formatter: rendTotalJudgeDisp},
                        {field: 'difference_x', headerName: 'X', width: '25%'},
                        {field: 'difference_y', headerName: 'Y', width: '25%'},
                        {field: 'difference_z', headerName: 'Z', width: '25%'}
                      ]}
                      source={selectedDetail_XYZ}
                    />
                  ) : (
                    false
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  {showFlag.length > 0 ? (
                    <LFCSingleValues
                      columns={[
                        {
                          field: 'img',
                          headerName: t('凡例'),
                          width: '100%',
                          formatter: imageFileUrl
                        }
                      ]}
                      source={selectedDetail}
                    />
                  ) : (
                    false
                  )}
                </Grid>
                <Grid item xs={12}>
                  <SearchDetailGraph
                    xyz={[selectedDetail_XYZ]}
                    gapheader={[selectedDetail_GapHeader]}
                    gap={[selectedDetail_Gap]}
                    gapthreshold={[selectedDetail_Gap_Threshold]}
                    show={showFlag}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SearchDetailDig;
