import {Grid} from '@mui/material';
import LFCSelectFormCompany from '_components-with-data/inputs/LFCSelectFormCompany';
import LFCButton from '_components/inputs/LFCButton';
import LFCDatePicker from '_components/inputs/LFCDatePicker';
import LFCSelectForm from '_components/inputs/LFCSelectForm';
import LFCTextField from '_components/inputs/LFCTextField';
import {adminAPIAccess, handleInputChange, handleInputChange2} from '_logics/LFCUtil';
import GenericTemplate from '_templates/GenericTemplate';
import {CognitoUserAttribute} from 'amazon-cognito-identity-js';
import awsConfiguration from 'awsConfiguration';
import {useSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

/**
 * LFCユーザ作成
 * @returns
 */
const SignUpPage = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const formRef = useRef<HTMLFormElement>(null!);
  const initVal = {
    email: '',
    user_id: '',
    user_name: '',
    user_type: '10',
    effective_date: '9999-12-31',
    company_id: '1',
    locale: 'ja'
  };
  const [values, setValues] = useState(initVal);
  const userTypeItems: {name: string; value: string}[] = [
    {name: '開発メンバー用(全環境)', value: '10'},
    {name: '社内メンバー用(検証、本番)', value: '20'},
    {name: 'エンドユーザ用(本番)', value: '50'}
  ];
  const localeTypes: {name: string; value: string}[] = [
    {name: '日本語', value: 'ja'},
    {name: '英語', value: 'en'}
  ];

  /**
   * ユーザを作成する。
   * 終了後は、招待メールが飛ぶ。
   */
  const onSignUp = () => {
    // input check
    if (!formRef.current?.reportValidity()) {
      return;
    }

    // Cognitoユーザ作成時、同時にm_userにレコード作成する環境を決定する
    type env_name = 'develop' | 'staging' | 'production';
    const target_env: env_name[] = [];
    switch (values.user_type) {
      case '10': // 開発メンバー用
        target_env.push('develop', 'staging', 'production');
        break;
      case '20': // 社内メンバー用
        target_env.push('staging', 'production');
        break;
      case '50': // エンドユーザ用(本番)
        target_env.push('production');
        break;
    }

    adminAPIAccess({
      apipath: 'CreateUser',
      parameters: {
        user_pool_id: awsConfiguration.UserPoolId,
        user_pool_name: values.user_id,
        user_attributes: [
          new CognitoUserAttribute({
            Name: 'email',
            Value: values.email
          }),
          new CognitoUserAttribute({
            Name: 'name',
            Value: values.user_id
          }),
          new CognitoUserAttribute({
            Name: 'custom:company_id',
            Value: values.company_id
          }),
          new CognitoUserAttribute({
            Name: 'email_verified',
            Value: 'true'
          })
        ],
        user_name: values.user_name,
        is_dark: false,
        locale: values.locale,
        user_type: values.user_type,
        effective_date: values.effective_date,
        target_env: target_env
      }
    })
      .then(response => {
        enqueueSnackbar(t('message.Cognitoに登録完了しました。'), {variant: 'success'});

        console.log('response:' + JSON.stringify(response.data));

        for (const dr of response.data) {
          if (dr['result'] === 0) {
            enqueueSnackbar(`[ ${dr['target_env']} ]${t('message.のm_userに登録完了しました。')}`, {
              variant: 'success'
            });
          } else {
            enqueueSnackbar(`[ ${dr['target_env']} ]${t('message.のm_userに登録失敗しました。')}`, {
              variant: 'error'
            });
          }
        }

        setValues(initVal); //初期化
      })
      .catch(error => {
        console.log(error);
        if (error.response.status === 401) {
          // 認証エラー
          enqueueSnackbar(
            t('message.セッションの有効期限が切れました。再度ログインしてください。'),
            {variant: 'error'}
          );
        } else {
          enqueueSnackbar(t(`aws.cognito.${error.message}`), {variant: 'error'});
        }
      });
  };

  useEffect(() => {
    // マウント時処理
  }, []);

  return (
    <GenericTemplate title={t('LFCユーザ作成')}>
      <form ref={formRef}>
        <Grid container>
          <Grid item xs={12} md={7} container spacing={2}>
            <Grid item xs={12} md={7}>
              <LFCSelectFormCompany
                name={'company_id'}
                value={values.company_id}
                onChange={event => handleInputChange2(event, values, setValues)}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCSelectForm
                name="user_type"
                label={t('ユーザ種別')}
                value={values.user_type}
                onChange={event => handleInputChange2(event, values, setValues)}
                required
                fullWidth
                selectItem={userTypeItems}
                id={'user_type'}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCTextField
                name="email"
                label={t('メールアドレス')}
                value={values.email}
                onChange={event => {
                  handleInputChange(event, values, setValues);
                }}
                other={{
                  required: true,
                  inputProps: {pattern: '^[a-z0-9_.+\\-]+@([a-z0-9][a-z0-9\\-]*\\.)+[a-z]{2,}$'}
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCTextField
                name="user_id"
                label={t('ユーザID')}
                value={values.user_id}
                onChange={event => {
                  handleInputChange(event, values, setValues);
                }}
                other={{
                  required: true,
                  inputProps: {minLength: 3, maxLength: 20, pattern: '^[a-z0-9_.\\-]*$'}
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCTextField
                name="user_name"
                label={t('ユーザ名')}
                value={values.user_name}
                onChange={event => {
                  handleInputChange(event, values, setValues);
                }}
                required
                fullWidth
                inputProps={{maxLength: 30}}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCSelectForm
                name="locale"
                label={'表示言語'}
                value={values.locale}
                onChange={event => handleInputChange2(event, values, setValues)}
                required
                fullWidth
                selectItem={localeTypes}
                id={'locale'}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCDatePicker
                name={'effective_date'}
                label={t('有効期限')}
                value={values.effective_date}
                onChange={event => {
                  handleInputChange(event, values, setValues);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <LFCButton color="primary" onClick={onSignUp} fullWidth>
                {t('登録')}
              </LFCButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </GenericTemplate>
  );
};

export default SignUpPage;
