import dayjs from 'dayjs';

/**
 * 年月表示
 * @param value
 * @returns
 */
export const fmtYYYYMM = (value: any) => {
  if (!value) {
    return;
  }
  return dayjs(String(value)).format('YYYY-MM');
};

/**
 * 年月日表示
 * @param value
 * @returns
 */
export const fmtYYYYMMDD = (value: any) => {
  if (!value) {
    return;
  }
  return dayjs(String(value)).format('YYYY-MM-DD');
};

/**
 * 年月日時表示
 * @param value
 * @returns
 */
export const fmtYYYYMMDDHH = (value: any) => {
  if (!value) {
    return;
  }
  return dayjs(String(value)).format('YYYY-MM-DD HH');
};

/**
 * 年月日時分秒表示
 * @param value
 * @returns
 */
export const fmtYYYYMMDDHHmmss = (value: any) => {
  if (!value) {
    return;
  }
  return dayjs(String(value)).format('YYYY-MM-DD HH:mm:ss');
};

/**
 * 日付省略表示
 * @param value
 * @returns
 */
export const fmtYMD = (value?: any) => {
  if (!value) {
    return;
  }
  return dayjs(String(value)).format('YY-MM-DD HH:mm:ss');
};

/**
 * 時間省略表示
 * @param epoch
 * @returns
 */
export const fmtmmss = (epoch: any) => {
  if (!epoch) {
    return;
  }
  return dayjs.unix(Number(epoch)).format('mm:ss');
};

/**
 * 四捨五入、下１桁
 * @param prms
 * @returns
 */
export const fmtRoundOffZero = (value: any) => {
  if (!value) {
    return '0.0';
  }
  return (Math.round(value * 10) / 10).toFixed(1);
};

/**
 * %マーク追加
 * @param prms
 * @returns
 */
export const addPercentMark = (value: any) => {
  if (!value || value === '0') {
    return '0.0%';
  }
  return value + '%';
};

/**
 * 秒追加
 * @param prms
 * @returns
 */
export const addSecondMark = (value: any) => {
  // const formatter = new Intl.NumberFormat('ja-JP');
  if (!value || value === 'null') {
    return '0.0秒';
  }
  return Number(value).toFixed(1) + '秒';
};
