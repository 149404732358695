import dayjs from 'dayjs';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {WorkGap, WorkGaps} from 'pages/weldingShow/types';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
  xAxisName?: string;
  yAxisName?: string;
  target: string;
}

export const LfcChartsLineWorkGap = (props: Props) => {
  let seriesData: any = [];
  Array.isArray(props?.source) &&
    props?.source?.forEach((workGaps: WorkGaps) => {
      let tmpData: any = [];
      if (!isEmpty(workGaps)) {
        workGaps.gaps.forEach((gap: WorkGap['gap'], index: number) => {
          tmpData.push([workGaps.path_addresses[index], gap]);
        });
        seriesData.push({
          name: dayjs(workGaps.lq_time).format('HH:mm'),
          type: 'line',
          data: tmpData
        });
      }
    });

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          legend: {
            show: true,
            bottom: '0px',
            type: 'scroll'
          },
          xAxis: {
            name: props.xAxisName,
            nameLocation: 'middle',
            nameRotate: 0,
            nameTextStyle: {
              padding: [20, 0, 0, 0],
              fontSize: 16
            },
            splitLine: {
              show: true
            },
            axisLabel: {
              fontSize: 16
            },
            type: 'value',
            min: 0
          },
          yAxis: {
            name: props.yAxisName,
            nameLocation: 'middle',
            nameRotate: 90,
            nameTextStyle: {
              padding: [0, 0, 20, 0],
              fontSize: 16
            },
            splitLine: {
              show: true
            },
            axisLabel: {
              fontSize: 16,
              formatter: (val: number) => val.toFixed(1)
            }
          },
          series: seriesData,
          grid: {
            left: 50,
            right: 5,
            bottom: 80,
            top: 60
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height}
      />
    </div>
  );
};
