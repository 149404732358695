import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import LFCBaseCharts from '_components/charts/base/LFCBaseCharts';
import {isEmpty} from 'lodash';
import {colorPallet} from '_themes/color-pallet/ColorPallet';
import {getLFCData} from '_logics/LFCUtil';
import axios from 'axios';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';

/**
 * 引数
 */
interface Props {
  title: string;
  source: any;
  yAxisName: string;
  series?: string;
  grid?: {
    top?: number | string;
    bottom?: number | string;
    left?: number | string;
    right?: number | string;
  };
  color?: string | {};
  exportData?: any;
  exportFields?: any;
  exportFilename?: string;
  height?: string;
}

/**
 * LFCChartsBar
 * @param props
 * @returns
 */
const LFCChartsLinePerson01 = (props: Props) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const [item, setItems] = useState([]);

  let workList: any = Array.from(new Set(props.source.map((a: any) => a.work))).sort();
  let seriesData: any = [];

  useEffect(() => {
    // マウント時処理

    // 機種情報取得
    getLFCData({
      snack: enqueueSnackbar,
      sql_id: 40001,
      parameters: {},
      cancelToken: source.token,
      ds_state: setItems,
      t
    });

    return () => {
      // アンマウント処理
      source.cancel('リクエストをキャンセルしてページ移動');
    };
  }, []);

  workList.forEach((a: string) => {
    let worked_data: any = props.source.filter((b: any) => b.work === a);
    let data_tmp: any = [];
    if (!isEmpty(worked_data)) {
      worked_data.forEach((a: any, a_index: number) => {
        data_tmp.push([worked_data[a_index].時刻, worked_data[a_index].cycle_time]);
      });
    }
    seriesData.push({
      name: a,
      type: 'line',
      data: data_tmp,
      encode: {
        x: 'timestamp',
        y: a
      },
      color: colorPallet[item.findIndex((b: any) => b.work === a) + 35]
    });
  });

  let first_timestamp: string = !isEmpty(props.source)
    ? dayjs(props.source[0].時刻).startOf('day').toString()
    : '';
  let lase_timestamp: string = !isEmpty(props.source)
    ? dayjs(props.source[0].時刻).endOf('day').toString()
    : '';

  return (
    <div style={{width: '100%'}}>
      <LFCBaseCharts
        option={{
          title: {
            text: props.title
          },
          xAxis: {
            type: 'time',
            name: t('時刻'),
            nameTextStyle: {
              fontSize: 10
            },
            nameGap: 40,
            nameLocation: 'middle',
            min: new Date(first_timestamp),
            max: new Date(lase_timestamp),
            scale: false,
            position: 'bottom',
            axisLabel: {
              show: true,
              fontSize: 10,
              rotate: '20',
              formatter: (a: any) => {
                return dayjs(a).format('H') + t('時');
              }
            }
          },
          yAxis: {
            axisLabel: {
              show: true,
              fontSize: 10
            }
          },
          series: seriesData,
          dataZoom: [
            {
              type: 'slider',
              height: 15,
              top: 250,
              left: 48
            }
          ],
          grid: {
            left: 50,
            right: 5,
            bottom: 80,
            top: 60
          },
          legend: {
            type: 'scroll',
            bottom: 0,
            itemWidth: 10,
            textStyle: {
              fontSize: 10
            }
          },
          tooltip: {
            trigger: 'item',
            formatter: (prm: any) => {
              return (
                t('機種') +
                ':' +
                prm.seriesName +
                '<br>' +
                t('時刻') +
                ':' +
                prm.value[0] +
                '<br>' +
                t('長さ') +
                ':' +
                prm.value[1] +
                t('秒')
              );
            }
          }
        }}
        exportData={props.exportData}
        exportFields={props.exportFields}
        exportFilename={props.exportFilename}
        height={props.height}
      />
    </div>
  );
};
export default LFCChartsLinePerson01;
