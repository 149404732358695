import React, {useEffect, useState} from 'react';
import ProgressBar from '_components/ProgressBar';
import {FormControlLabel, Switch} from '@mui/material';
import GenericTemplate from '_templates/GenericTemplate';
import WeldingData from './WeldingData';
import ExecutionProcess from './ExecutionProcess';
import RecommendedConditions from './RecommendedConditions';

export type IndexPageProps = {};

const IndexPage = ({}: IndexPageProps) => {
  const [recommendedConditionsFlag, setRecommendedConditionsFlag] = useState(true);
  const [executionProcessFlag, setExecutionProcessFlag] = useState(false);
  const [weldingDataFlag, setWeldingDataFlag] = useState(false);
  const [pageName, setPageName] = useState('展示会デモ：Recommended Conditions');

  const [searchValue, setSearchValue] = useState({
    auto_play: true,
    auto_play_interval: 10,
    welding_method: 'Tig Welding',
    material_properties: 'Stainless Steel',
    boardthickness: '1.6'
  });

  const switchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue({...searchValue, auto_play: event.target.checked});
  };

  useEffect(() => {
    recommendedConditionsFlag && setPageName('展示会デモ：Recommended Conditions');
    executionProcessFlag && setPageName('展示会デモ：Execution Process');
    weldingDataFlag && setPageName('展示会デモ：Welding Data');
  }, [recommendedConditionsFlag, executionProcessFlag, weldingDataFlag]);

  return (
    <GenericTemplate title={pageName}>
      <ProgressBar startProcess={false} />

      <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: 10}}>
        <FormControlLabel
          label={`Auto Play: ${searchValue.auto_play_interval} sec`}
          labelPlacement="start"
          control={
            <Switch
              checked={searchValue.auto_play}
              onChange={switchChange}
              inputProps={{'aria-label': 'controlled'}}
              disabled={executionProcessFlag}
            />
          }
        />
      </div>

      {recommendedConditionsFlag ? (
        <RecommendedConditions
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setRecommendedConditionsFlag={setRecommendedConditionsFlag}
          setExecutionProcessFlag={setExecutionProcessFlag}
          setWeldingDataFlag={setWeldingDataFlag}
        />
      ) : (
        <></>
      )}
      {executionProcessFlag ? (
        <ExecutionProcess
          searchValue={searchValue}
          setRecommendedConditionsFlag={setRecommendedConditionsFlag}
          setExecutionProcessFlag={setExecutionProcessFlag}
          setWeldingDataFlag={setWeldingDataFlag}
        />
      ) : (
        <></>
      )}
      {weldingDataFlag ? (
        <WeldingData
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setRecommendedConditionsFlag={setRecommendedConditionsFlag}
          setExecutionProcessFlag={setExecutionProcessFlag}
          setWeldingDataFlag={setWeldingDataFlag}
        />
      ) : (
        <></>
      )}
    </GenericTemplate>
  );
};

export default IndexPage;
