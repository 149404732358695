import {useSnackbar} from 'notistack';
import React, {useContext, useEffect, useState} from 'react';
import {SystemSettings, SystemSettingsInitVal} from '_contexts/types/SystemSettings';
import {getLFCData} from '_logics/LFCUtil';
import {useTranslation} from 'react-i18next';
import {useMySettings} from './MySettingsProvider';

type OperationType = {
  regetSystemSetting: () => void;
  saveSystemSetting: (sys: SystemSettings) => void;
};

/**
 * OperationContext
 */
const SystemSettingsOperationContext = React.createContext<OperationType>({
  regetSystemSetting: () => console.error('Providerが設定されていません'),
  saveSystemSetting: (sysp: SystemSettings) => console.error('Providerが設定されていません')
});

/**
 * Context
 */
const SystemSettingsContext = React.createContext<SystemSettings>({} as SystemSettings);

/**
 * System設定プロバイダ
 */
const SystemSettingsProvider: React.FC = ({children}) => {
  const {enqueueSnackbar} = useSnackbar();
  const mySettings = useMySettings();
  const [systemSettings, setSystemSettings] = useState<SystemSettings>({} as SystemSettings);
  const {t} = useTranslation();

  /**
   * 認証情報変更時の処理
   */
  useEffect(() => {
    if (0 === Object.keys(mySettings).length) {
      //未認証だったら初期化
      setSystemSettings({} as SystemSettings);
      return;
    }
    regetSystemSetting();
  }, [mySettings]);

  /**
   * SystemSettingを再読み込みします
   */
  const regetSystemSetting = async () => {
    const data = await getLFCData({
      snack: enqueueSnackbar,
      sql_id: 30001,
      parameters: {},
      t
    });

    const creSysStg = {
      ...SystemSettingsInitVal,
      ...data
        .filter((r: {[key: string]: any}) => r.skey in SystemSettingsInitVal)
        .reduce((acc: {[key: string]: any}, r: {[key: string]: any}) => {
          acc[r.skey] = r.svalue;
          return acc;
        }, {})
    };

    setSystemSettings(creSysStg);
  };

  /**
   * DB保存処理
   * @param Systems
   */
  const saveSystemSetting = async (sysp: SystemSettings) => {
    // 管理画面作る時に実装する
  };

  return (
    <SystemSettingsOperationContext.Provider value={{regetSystemSetting, saveSystemSetting}}>
      <SystemSettingsContext.Provider value={systemSettings}>
        {children}
      </SystemSettingsContext.Provider>
    </SystemSettingsOperationContext.Provider>
  );
};

export const useSystemSettings = () => useContext(SystemSettingsContext);
export const useRegetSystemSetting = () =>
  useContext(SystemSettingsOperationContext).regetSystemSetting;
export const useSaveSetSystemSetting = () =>
  useContext(SystemSettingsOperationContext).saveSystemSetting;

export default SystemSettingsProvider;
